import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faEdit } from '@fortawesome/free-solid-svg-icons';
import store from 'client/store';
import { Button, Input } from '@smartplatform/ui';
import debounce from 'lodash/debounce';
import t from 'i18n';
import DeleteIcon from 'img/icons/shared/delete.svg';
import {shortenAddressName} from "client/tools";
import './style.scss'

const ADDRESS_MODELS = ['Address', 'ViewTechFireAddress'];

@observer
export default class AddressInfo extends React.Component {
	static propTypes = {
		address: function (props) {
			if (!props.address) {
				return new Error(`AddressInfo: missing "address" prop`);
			}
			if (!ADDRESS_MODELS.includes(props.address.constructor.name)) {
				return new Error(`AddressInfo: "address" prop must be one of types: ${ADDRESS_MODELS.join(', ')}`);
			}
		},
		value: PropTypes.object,
		onChange: PropTypes.func,
	};

	@observable isDeleting = false;
	@observable isEdit = false;
	@observable nearestAddress;

	constructor(props) {
		super(props);
		this.saveName = debounce(this.saveName, 500);
		this.init();
	}

	init = async () => {
		const { address } = this.props;

		const [lon, lat] = address.geo.coordinates;
		const nearest = await store.model.Address.findAddressesByGeo({
			lon,
			lat,
			count: 1,
		});
		this.nearestAddress = nearest[0]?.name;

		if (address.name === '') {
			this.onEdit();
		}
	};

	onChange = async (name) => {
		const { address } = this.props;
		address.name = name;
		this.saveName();
	};

	saveName = async () => {
		const { address } = this.props;
		await address.save();
	};

	onDelete = async () => {
		const { address, onClose } = this.props;
		await address.delete();
		if (onClose) {
			onClose();
		} else {
			store.route.history.goBack();
		}
	};

	startDelete = () => (this.isDeleting = true);
	cancelDelete = () => (this.isDeleting = false);

	onEdit = () => (this.isEdit = !this.isEdit);

	render() {
		const { address } = this.props;
		if (!address) return null;

		return (
			<div className='address-info'>
				<div className='hdr'>
					<div className='name'>
						<FontAwesomeIcon icon={faHome} />
						{this.isEdit ? <Input value={address.name} onChange={this.onChange} /> : <p className='permafrost-address-name'>{shortenAddressName(address.name)}</p>}
						<FontAwesomeIcon icon={faEdit} onClick={this.onEdit} className='cursor-pointer pl-2' />
						<div className='delete-address'>
							{this.isDeleting ? (
								<>
									{t('confirmDelete')}
									<Button size='sm' variant='danger' onClick={this.onDelete}>
										{t('yes')}
									</Button>
									<Button size='sm' onClick={this.cancelDelete}>
										{t('no')}
									</Button>
								</>
							) : (
								<DeleteIcon onClick={this.startDelete} />
							)}
						</div>
					</div>
				</div>
				{!address.name && this.nearestAddress && (
					<div className='hdr'>
						<b>{t('nearestAddress')}:&nbsp;</b> {this.nearestAddress}
					</div>
				)}
			</div>
		);
	}
}
