import React from 'react';
import './timeLine.scss';
import { DatePicker } from '@smartplatform/ui';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { endOfDay, startOfDay, subMonths } from 'date-fns';
import { PlayButton, PauseButton } from 'components';
import { formatDate } from 'client/tools';
import t from 'i18n';
@observer
export class TimeLine extends React.Component {
	@observable startDate;
	@observable endDate;
	@observable selectedDate = new Date();
	@observable selectedIndex = 0;
	@observable isPlaying = false;

	constructor(props) {
		super(props);
		this.startDate = subMonths(new Date(), 1);
		this.endDate = new Date();
		this.selectedIndex = props.dates.length - 1;
	}

	componentWillUnmount() {
		this.stopPlaying();
	}

	onDateChange = (prop) => (value) => {
		if (prop === 'endDate') {
			this.endDate = endOfDay(value);
		} else if (prop === 'startDate') {
			this.startDate = startOfDay(value);
		}

		this.props.onDatesChange({ startDate: this.startDate, endDate: this.endDate });
	};

	handleChange = (event) => {
		this.selectedIndex = Number(event.target.value);
		// передаем наверх индекс, чтобы понять какой трек выбран
		this.props.onSelectedDateChange && this.props.onSelectedDateChange(this.selectedIndex);
		if (this.isPlaying) {
			this.stopPlaying();
		}
	};

	get position() {
		return (this.selectedIndex / (this.props.dates.length - 1)) * 100; // Позиция в процентах
	}

	stopPlaying = () => {
		clearInterval(this.playIntervalId);
		this.isPlaying = false;
	};

	onPlay = () => {
		if (!this.isPlaying) {
			this.playIntervalId = setInterval(() => {
				const notLastTrack = this.selectedIndex < this.props.dates.length - 1;
				if (notLastTrack) {
					this.selectedIndex++;
					this.props.onSelectedDateChange && this.props.onSelectedDateChange(this.selectedIndex);
				} else {
					this.stopPlaying();
				}
			}, 1000); // 1000 миллисекунд = 1 секунда
			this.isPlaying = true;
		}
	};

	render() {
		const { startDate, endDate } = this;
		const { dates } = this.props;

		return (
			<div className='track-time-line'>
				{dates.length > 1 ? (
					<div className='track-slider'>
						{this.isPlaying ? (
							<PauseButton onClick={this.stopPlaying} />
						) : (
							<PlayButton disabled={dates.length === this.selectedIndex + 1} onClick={this.onPlay} />
						)}
						<div className='slider'>
							{/* Ползунок */}
							<input
								type='range'
								min='0'
								max={dates.length - 1}
								value={this.selectedIndex}
								onChange={this.handleChange}
								style={{ width: '100%', position: 'absolute', top: '50%', transform: 'translateY(-50%)' }}
							/>

							{/* Отображение выбранной даты */}
							<div
								style={{
									position: 'absolute',
									width: 'max-content',
									top: '3px',
									left: `${(this.selectedIndex / (this.props.dates.length - 1)) * 100}%`,
									transform: 'translateX(-50%)',
								}}
							>
								{formatDate(dates[this.selectedIndex], 'dd.MM.yy HH:mm')}
							</div>
						</div>
					</div>
				) : (
					<div className='track-slider no-tracks'>{t('shipTrack.noTracks')}</div>
				)}
				<DatePicker value={startDate} onChange={this.onDateChange('startDate')} showClearButton={false} maxDate={new Date()} />
				<DatePicker value={endDate} onChange={this.onDateChange('endDate')} showClearButton={false} maxDate={new Date()} />
			</div>
		);
	}
}
