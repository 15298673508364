import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PersonnelEdit } from './edit/PersonnelEdit';
import { PersonnelList } from './list/PersonnelList';

export default ({ match }) => {
	const { path } = match;

	return (
		<Switch>
			<Route path={path} exact render={(routeProps) => <PersonnelList {...routeProps} />} />
			<Route path={path + '/:id'} render={(routeProps) => <PersonnelEdit {...routeProps} path={path} />} />
		</Switch>
	);
};
