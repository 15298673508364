import { observable } from "mobx";
import {endOfYear, startOfYear} from "date-fns";

export default class formByUlusStore {
	@observable year = null;

	onYearChange = (year) => this.year = year;

	get storeParams() {
		const { year } = this;
		const date = new Date(year, 1, 1);
		const startDate = startOfYear(date);
		const endDate = endOfYear(date);

		return {
			reportCode: 'formByUlus',
			reportParams: { startDate, endDate, year },
			reportModel: 'DeliveryReport'
		};
	}
}
