import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import FilterLayout from '../FilterLayout';
import t from 'i18n';
import { Checkbox, RecordSelect } from '@smartplatform/ui';
import { observable } from 'mobx';
import store from 'client/store';
import { LabelField } from 'components';

@observer
export class ShipsFilter extends React.Component {
	@observable type = null;
	static propTypes = {
		layer: PropTypes.object,
		onToggle: PropTypes.func,
	};

	constructor(props) {
		super(props);
		this.settings = props.settings;
	}

	render() {
		const { layer } = this.props;
		const { type, onChange, isFavourite } = layer;

		return (
			<FilterLayout layer={layer} title={t('ship.plural')} className='ship-filter'>
				<LabelField label={t('shipType.title')}>
					<RecordSelect
						noTotal
						maxItems={10}
						itemsPerPage={200}
						model={store.model.ShipType}
						property='name'
						value={type}
						onChange={onChange('type')}
						disabled={!this.settings.show}
					/>
				</LabelField>
				<LabelField label={t('ship.isFavourite')}>
					<Checkbox value={isFavourite} onChange={onChange('isFavourite')} />
				</LabelField>
			</FilterLayout>
		);
	}
}
