import React from 'react';
import { isObservableArray, observable } from 'mobx';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Fill, Stroke, Style, Icon, Circle as CircleStyle, Text } from 'ol/style';
import { asArray } from 'ol/color';
import { ColorUtils } from '@smartplatform/ui';
import { geoJSON, drawGeoMarker } from '@smartplatform/map/client';
import icons from 'img/icons/fires/map';
import store from 'client/store';
import { getStatusColor } from '../../../modules/fires/helpers';
import { getFireIconName } from './getFireIconName';
import FirePopup from './FirePopup';
import t from 'i18n';
import { startOfDay, endOfDay } from 'date-fns';

export class FiresLayer {
	@observable fires = [];

	constructor(mapStore, settings = {}) {
		this.mapStore = mapStore;
		this.settings = settings;
	}

	init = async () => {
		this.layer = new VectorLayer({
			format: geoJSON,
			source: new VectorSource(),
		});
		this.layer.setZIndex(15);
		this.setVisible();
		this.mapStore.addLayer(this.layer);

		this.load();
	};

	load = async () => {
		let fires = [];
		const statuseCodes = Object.keys(this.settings.status).filter((statusCode) => !!this.settings.status[statusCode]);
		const { startDate, endDate, ids } = this.settings;
		const { serviced, unattended } = this.settings.service;

		const start = startDate ? { gte: startOfDay(new Date(startDate)) } : undefined;
		const end = endDate ? { lte: endOfDay(new Date(endDate)) } : undefined;

		if (this.settings.show && statuseCodes.length > 0 && (serviced || unattended)) {
			const where = {
				and: [
					{ geo: { neq: null } },
					{ statusCode: { inq: statuseCodes } },
					//* только пожары с датой обнаружения не больше конца периода
					{ detectDate: end },
					//* начало периода устанавливаем только если нет конца периода(чтобы запросить все пожары от начала периода).
					//* если установлены обе даты , то ориентируемся на фильтрацию по or
					{ detectDate: end ? undefined : start },
					// Обслуживаемый или нет
					{ or: [{ isServiced: serviced }, { isServiced: !unattended }] },
					//* все активные или обновленные(ликвидированные) за период
					{ or: [{ and: [{ statusCode: { nin: ['extinguished'] } }] }, { and: [{ date: start }, { date: end }] }] },
				],
			};

			if (isObservableArray(ids)) {
				if (this.settings.ids.length === 0) {
					this.layer.getSource().clear();
					return;
				}
				where.and.push({ id: { inq: ids } });
			}

			fires = await store.model.ViewFireMap.find({
				where,
				fields: ['id', 'geo', 'statusId', 'zoneId', 'forestryFireNumber'],
				include: [
					{ relation: 'status', scope: { fields: ['id', 'name', 'code', 'color'] } },
					{ relation: 'zone', scope: { fields: ['id', 'code', 'shortName', 'color'] } },
				],
			});
		}

		const features = [];
		for (let fire of fires) {
			const { geo, zone, forestryFireNumber } = fire;
			const zoneName = zone?.shortName;
			const color = getStatusColor(fire);
			const iconSrc = icons[getFireIconName(fire)].src || icons.default;
			const { r, g, b } = ColorUtils.hexToRgb(color);
			let styleParams = {
				fill: new Fill({ color: asArray([r, g, b, 0.4]) }),
				stroke: new Stroke({ color, width: 1 }),
			};
			let pointStyle;
			if (geo.type === 'Point') pointStyle = new Style({ image: new CircleStyle({ radius: 10, ...styleParams }) });

			const marker = drawGeoMarker(geo, {
				data: {
					title: t('fire.title'),
					record: fire,
					render: this.renderPopup,
				},
				style: new Style({
					...styleParams,
					image: new Icon({
						src: iconSrc,
					}),
					text: new Text({
						textAlign: 'left',
						font: '7px/14px sans-serif',
						text: this.settings.customText ? this.settings.customText(fire) : `${forestryFireNumber || ''}${zoneName || ''}`,
						stroke: new Stroke({
							color: '#FFFF',
							width: 2,
						}),
						offsetX: 6,
						offsetY: -12,
						scale: 1.5,
					}),
				}),
				onlyMarker: store.isPublic,
			});

			features.push(...marker);
		}

		this.layer.getSource().clear();
		this.layer.getSource().addFeatures(features);
	};

	setVisible = (value) => {
		value = typeof value === 'boolean' ? value : this.settings.show;
		this.layer.setVisible(value);
	};

	renderPopup = (record) => {
		return <FirePopup record={record} noPopupLink={this.settings.noPopupLink} />;
	};
}
