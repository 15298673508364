import React from 'react';
import { Provider, observer } from 'mobx-react';
import { Form, LabelField, RecordNotFound, PopconfirmButton } from 'components';
import { RecordSelect, Row, Field, DatePicker, Button } from '@smartplatform/ui';
import store from 'client/store';
import { ForestFireMoveStore } from './store/store';
import { renderFireName } from 'client/tools';
import t from 'i18n';
import './forestFireMoveEdit.scss';
import { ForestFireCard } from './ForestFireCard';
import { FireDeps } from './FireDeps';
import { PersonnelLogs } from './PersonnelLogs';
@observer
export class ForestFireMoveEdit extends React.Component {
	constructor(props) {
		super(props);
		this.store = new ForestFireMoveStore(props);
	}

	render() {
		const { record, fire, onFireChange, isLoading, beforeSave, onClean, goBack, onReturn, onSave, isNew, personnelIdSet, beforeDelete } = this.store;
		if (isLoading) return;
		if (!record) return <RecordNotFound />;
		const countyId = store.model.user.countyId;
		const disableSave = !fire || !record.garrisonId || !record.departureDate;

		const isClosed = !!record.returnDate;

		const controls = !isNew && personnelIdSet.size > 0 && !isClosed && (
			<PopconfirmButton variant='default' text={t('forestFireMovement.return')} content={t('forestFireMovement.returnMessage')} onConfirm={onReturn} />
		);
		return (
			<Provider store={this.store}>
				<div className='forest-fire-movement-edit'>
					<Form
						record={record}
						disableSave={disableSave}
						beforeSave={beforeSave}
						onClean={onClean}
						onCancel={goBack}
						onDelete={goBack}
						className='fire-movement-form'
						beforeDelete={beforeDelete}
						noSave={!isNew}
						onSave={onSave}
						controls={controls}
					>
						<Row>
							<Field relation='garrison' property='name' label={t('garrison')} isRequired disabled={!store.isAdmin || isClosed}>
								<RecordSelect isRequired filter={{ where: { isGarrison: true } }} />
							</Field>
							<LabelField label={t('fire.title')} isRequired>
								<RecordSelect
									isRequired
									model={store.model.ViewFire}
									value={fire}
									computed={renderFireName}
									disabled={!isNew}
									onChange={onFireChange}
									filter={{
										where: { statusCode: { nin: ['extinguished'], countyId } },
										fields: ['id', 'fireNumber', 'statusColor', 'countyName'],
									}}
								/>
							</LabelField>
							<Field label={t('departureDate')} property='departureDate' disabled={!isNew}>
								<DatePicker showClearButton={false} />
							</Field>
							{!isNew && record.returnDate ? (
								<Field label={t('returnDate')} property='returnDate' disabled={isClosed}>
									<DatePicker showClearButton={false} />
								</Field>
							) : (
								<div />
							)}
						</Row>

						{!isNew && (isClosed ? <PersonnelLogs /> : <FireDeps />)}
					</Form>
					<ForestFireCard />
				</div>
			</Provider>
		);
	}
}
