import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Loader, Form, Field, Row } from '@smartplatform/ui';
import store from 'client/store';
import { TechFireAddressSearch } from 'components';
import t from 'i18n';
import { renderStatus } from 'client/tools';
import { ObjectLog } from 'techfires/registry-object-adpi-log/ObjectLog';
import { getPrefixByCountyName } from 'client/tools/getPrefixByCountyName';

@observer
export default class Edit extends React.Component {
	@observable record = null;

	constructor(props) {
		super(props);
		this.init();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.match.params.id !== this.props.match.params.id) {
			this.init();
		}
	}

	init = async () => {
		const id = parseInt(this.props.match.params.id);
		if (id) {
			this.record = await store.model.AdpiDevice.findById(id);
		} else {
			this.record = new store.model.AdpiDevice();
		}
	};

	redirectBack = () => store.route.push({ path: this.props.path });

	render() {
		if (!this.record) return <Loader />;

		return (
			<>
				<Form
					record={this.record}
					path={this.props.path}
					stay
					onSave={this.redirectBack}
					onDelete={this.redirectBack}
					disableSave={!this.record.status}
					className='adpi-form'
				>
					<div className='adpiTitle'>
						{t('adpiDevice.title')} #{this.props.match.params.id !== 'create' && this.props.match.params.id}
					</div>
					<h2>{t('adpiDevice.basic')}</h2>
					<Row>
						<Field property='name' label={t('county.name')} />
						<div />
						<div />
					</Row>
					<Row>
						<Field relation='model' property='name' label={t('adpiDevice.model')} />
						<Field relation='type' property='name' label={t('adpiDevice.type')} />
						<Field relation='status' computed={renderStatus} label={t('adpiDevice.status')} isRequired />
					</Row>
					<h2>{t('adpiDevice.contacts')}</h2>
					<Row>
						<Field relation='organization' property='name' label={t('adpiDevice.organization')} />
						<Field property='phoneNumber' label={t('adpiDevice.phoneNumber')} />
						<Field property='contacts' label={t('adpiDevice.contacts')} />
					</Row>
					<Row>
						<Field relation='address' property='name' label={t('adpiDevice.address')}>
							<TechFireAddressSearch prefix={getPrefixByCountyName(store.userCounty?.name)} />
						</Field>
					</Row>
					<h2>{t('adpiDevice.dateAndTime')}</h2>
					<Row>
						<Field property='createdAt' label={t('createdAt')} disabled />
						<Field property='updatedAt' label={t('updatedAt')} disabled />
						<div />
					</Row>
					<ObjectLog id={this.record.id} />
				</Form>
			</>
		);
	}
}
