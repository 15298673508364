import { observable } from 'mobx';
import store from 'client/store';
import { PermafrostObjectLayer } from './filters/layers/permafrostObject';
import { PermafrostTemperatureTubeLayer } from './filters/layers/permafrostTemperatureTube';
import { PermafrostLevelingMarkLayer } from './filters/layers/permafrostLevelingMark';

class PermafrostMap {
	@observable mapInitialized = false;

	layers = {};

	onMapInit = async (mapStore) => {
		this.mapStore = mapStore;
		this.map = mapStore.map;
		this.layersSettings = store.local.permafrost.layers;

		this.layers.permafrostObjectLayer = new PermafrostObjectLayer(mapStore, this.layersSettings.permafrostObject);
		this.layers.permafrostTemperatureTubeLayer = new PermafrostTemperatureTubeLayer(mapStore, this.layersSettings.permafrostTemperatureTube);
		this.layers.permafrostLevelingMarkLayerLayer = new PermafrostLevelingMarkLayer(mapStore, this.layersSettings.permafrostLevelingMark);

		this.layers.permafrostObjectLayer.init(mapStore);
		this.layers.permafrostTemperatureTubeLayer.init(mapStore);
		this.layers.permafrostLevelingMarkLayerLayer.init(mapStore);

		this.map.on('pointermove', this.onPointerMove);

		mapStore.on('cleanup', this.destroy);
		this.mapInitialized = true;
	};

	onTileSourceChange = (tileSource) => {
		store.local.permafrost.tileSource = tileSource;
		store.local.save();
	};

	onLayerToggle = (layer, localPath) => (isVisible) => {
		localPath.show = isVisible;
		store.local.save();
		layer.setVisible(isVisible);
	};

	onSettingsChange = (layer, localPath) => (settings) => {
		console.log('onSettingsChange', settings);
		Object.keys(settings).forEach((key) => {
			localPath.$mobx.write(localPath, key, settings[key]);
		});
		store.local.save();
		layer.updateSettings && layer.updateSettings(settings);
		layer.setVisible && layer.setVisible(settings.show);
	};

	destroy = () => {
		this.mapStore.off('cleanup', this.destroy);
		this.mapInitialized = false;
	};

	switchFilters = (maximize) => {
		store.local.permafrost.filtersMaximized = maximize !== undefined ? maximize : !store.local.permafrost.filtersMaximized;
		store.local.save();
	};
}

export default new PermafrostMap();
