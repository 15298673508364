import React from 'react';
import { AddButton, LabelField } from 'components';
import { Button, Input, Loader } from '@smartplatform/ui';
import { observer } from 'mobx-react';
import t from 'i18n';
import GarrisonSelect from 'techfires/components/garrison-select/GarrisonSelect';
import './style.scss';
import SettingStore from 'techfires/note/template-setting/settingStore';
import { DeleteBtn } from 'components';

@observer
export default class SettingForm extends React.Component {
	constructor(props) {
		super(props);
		this.store = new SettingStore();
	}

	renderFields = () => {
		const { noteSettings, onChange, propsField, addArrayItem, onArrayItemChange, deleteArrayItem } = this.store;
		let fields = [];
		for (const field in noteSettings) {
			switch (propsField?.[field].type) {
				case 'string':
					fields.push(
						<LabelField label={t(`noteSettings.${field}`)} key={field} id={field}>
							<textarea value={noteSettings?.[field]} onChange={(e) => onChange(field)(e.target.value)} />
						</LabelField>
					);
					break;
				case 'arrayObjects':
					fields.push(
						<LabelField label={t(`noteSettings.${field}.title`)} className='wrapper-array-items' key={field} id={field}>
							{!propsField[field].props.oneObject && <AddButton className='add-btn' onClick={() => addArrayItem(field)} />}
							{!!noteSettings[field].length && (
								<div className='wrapper-array-item'>
									{Object.keys(propsField[field].props.emptyItem).map((key) => (
										<p key={key}>{t(`noteSettings.${field}.${key}`)}</p>
									))}
								</div>
							)}
							{noteSettings[field]?.map((record, index) => (
								<div className='wrapper-array-item'>
									{Object.keys(record).map((key) => (
										<Input value={record[key]} onChange={(value) => onArrayItemChange({ prop: field, key, value, index })} />
									))}
									{!propsField[field].props.oneObject && <DeleteBtn onConfirm={() => deleteArrayItem(field, index)} />}
								</div>
							))}
						</LabelField>
					);
					break;
				default:
					fields.push(
						<LabelField key={field} id={field} label={t(`noteSettings.${field}`)}>
							<Input value={noteSettings[field]} onChange={onChange(field)} {...propsField?.[field].props} />
						</LabelField>
					);
					break;
			}
		}
		return fields;
	};

	render() {
		const { onSave, updatedSettingsKeys, isLoading, onGarrisonChange, garrison } = this.store;
		if (isLoading) return <Loader size={20} />;
		const content = this.renderFields();
		return (
			<div className='wrapper-setting-note'>
				<GarrisonSelect value={garrison} onChange={onGarrisonChange} setDefault={true} isRequired />
				<div className='separator'></div>
				<div className='setting-form'>
					{garrison && content}
					<Button variant='primary' onClick={onSave} className='mt-2' disabled={updatedSettingsKeys.length === 0}>
						{t('save')}
					</Button>
				</div>
			</div>
		);
	}
}
