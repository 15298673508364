import React from 'react';
import { observer } from 'mobx-react';
import { Button, Input, Checkbox, NumberInput, RecordSelect, Row, Field, DatePicker } from '@smartplatform/ui';
import t from 'i18n';
import { guardHeadFireDepItem } from '../../../helpers';
import { formatDate } from 'client/tools';
import { PopconfirmFaTrashIcon, LabelField } from 'components';
import './style.scss';

@observer
export default class EntitySingle extends React.Component {
	delete = async () => {
		await this.props.record.delete();
		this.props.onUpdate && this.props.onUpdate();
	};

	renderField = (field) => {
		const { type, label, property, options, disabled } = field;
		const { record } = this.props;
		const filter = {
			where: {
				rtp: true,
				endDate: { gt: new Date() },
			},
		};
		if (record?.length) filter.where.id = { nin: record?.map((chief) => chief.chiefId) };
		switch (type) {
			case 'input':
				return (
					<div className='form-field' key={property}>
						<label>{t(label)}</label>
						<Input value={record[property]} disabled />
					</div>
				);
			case 'number':
				return (
					<div className='form-field'>
						<label>{t(label)}</label>
						<NumberInput value={record[property]} disabled={disabled} />
					</div>
				);
			case 'checkbox':
				return (
					<div className='form-field' key={property}>
						<label>{t(label)}</label>
						<Checkbox checked={record[property]} disabled />
					</div>
				);
			case 'select':
				const selectProps = property === 'chief' ? { computed: guardHeadFireDepItem } : { property: options.property };
				return (
					<div className='form-field' key={property}>
						<label>{t(label)}</label>
						<RecordSelect model={options.model} value={record[property]} property={options.property} {...selectProps} disabled />
					</div>
				);
			case 'date':
				return (
					<div className='form-field' key={property}>
						<label>{t(label)}</label>
						{formatDate(record[property], options.format || 'dd.MM.yyyy HH:mm')}
					</div>
				);
			default:
				return null;
		}
	};

	render() {
		const { record, fields, onEdit, modelName } = this.props;
		return (
			<div className='entity-single'>
				{modelName !== 'TechFireCasualty' ? (
					<Row>
						{Array.isArray(fields) && fields.map((field, index) => <div key={index}>{this.renderField(field)}</div>)}
						<div className='entity-button'>
							<Button onClick={() => onEdit(record)} variant='primary'>
								{t('edit')}
							</Button>
							{record.id && <PopconfirmFaTrashIcon onConfirm={this.delete} />}
						</div>
					</Row>
				) : (
					<div className='casualty-card'>
						<div className='casualty-card-info'>
							<Row>
								<div className='detecttime-birth'>
									<Field property='detectTime' label={t('techFireCasualty.detectTime')} disabled>
										<DatePicker showTimeSelect />
									</Field>
									<LabelField label={t('techFireCasualty.birth')}>
										<Input value={record.yearOfBirth} disabled />
									</LabelField>
								</div>
								<LabelField label={t('user.fullName')}>
									<Input value={`${record.lastName || ''} ${record.firstName || ''} ${record.middleName || ''}`} disabled />
								</LabelField>
							</Row>
							<Row>
								<LabelField label={t('techFireCasualty.furtherAction')}>
									<Input value={record.furtherAction} disabled />
								</LabelField>
								<Row>
									<LabelField label={t('techFireCasualty.deceased')}>
										<Checkbox checked={record.deceased} disabled />
									</LabelField>
									<LabelField label={t('techFireCasualty.isChild')}>
										<Checkbox checked={record.isChild} disabled />
									</LabelField>
									<LabelField label={t('techFireCasualty.isInjured')}>
										<Checkbox checked={record.isInjured} disabled />
									</LabelField>
								</Row>
							</Row>
						</div>
						<div className='main-actions'>
							<label>&nbsp;</label>
							<Button onClick={() => onEdit(record)} variant='primary'>
								{t('edit')}
							</Button>
							{record.id && <PopconfirmFaTrashIcon onConfirm={this.delete} />}
						</div>
					</div>
				)}
			</div>
		);
	}
}
