import { VEHICLES_SCOPE } from '../../constants';

export const PERSONNEL_LIST_INCLUDE = {
	include: [
		{
			relation: 'vehicle',
			scope: VEHICLES_SCOPE,
		},
		{ relation: 'gasMask', scope: { fields: ['id', 'name'] } },
		{ relation: 'position', scope: { fields: ['id', 'name'] } },
		{ relation: 'rank', scope: { fields: ['id', 'name'] } },
		{ relation: 'status', scope: { fields: ['id', 'name'] } },
	],
};
