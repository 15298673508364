import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import ObjectCard from './ObjectCard';
import ObjectEdit from './ObjectEdit';

@observer
export default class Object extends React.Component {
	@observable editing = false;

	constructor(props) {
		super(props);
		this.isNew = !this.props.object.id;
	}

	onEdit = () => {
		this.editing = true;
		this.props.onEdit && this.props.onEdit(this.props.object);
	};

	onSave = (record) => {
		this.editing = false;
		this.props.onSave && this.props.onSave(record, this.isNew);
	};

	onCancel = () => {
		this.editing = false;
		this.props.onCancel && this.props.onCancel(this.isNew);
	};

	render() {
		const { value, object, category, onObjectSelect, onDelete, onCreateFire, fireDep } = this.props;

		return this.editing || !object.id ? (
			<ObjectEdit object={object} onSave={this.onSave} value={value} onCancel={this.onCancel} onDelete={onDelete} fireDep={fireDep} />
		) : (
			<ObjectCard object={object} category={category} onEdit={this.onEdit} value={value} onObjectSelect={onObjectSelect} onCreateFire={onCreateFire} />
		);
	}
}
