import classNames from 'classnames';
import t from 'i18n';
import { UploadButton } from 'components';
import { useDropzone } from 'react-dropzone';
import { bytesToSize } from 'client/tools';
import './dragAndDropField.scss';

export function DragAndDropField(props) {
	let { disabled, className, accept, multiple, maxSize, onDrop, height } = props;

	const { getRootProps, getInputProps, isDragActive, isDragReject, rejectedFiles, fileRejections, open } = useDropzone({ onDrop, accept, multiple, maxSize });

	const _className = classNames('drag-and-drop-field', className, { disabled: disabled }, { active: isDragActive });
	const plural = { count: multiple ? 2 : 1 };

	const acceptFormats = !accept || typeof accept === 'string' ? accept : Object.values(accept).join('').replaceAll('.', ' ').toUpperCase();
	return (
		<div {...getRootProps()} className={_className} style={{ height }}>
			{!isDragActive && <div className='placeholder'>{t('dragAndDropField.placeholder', plural)}</div>}
			{!isDragActive && acceptFormats && (
				<div className='accept'>
					{t('dragAndDropField.accept')} <span className='format'>{acceptFormats}</span>
				</div>
			)}
			{!isDragActive && maxSize && <div>{t('dragAndDropField.maxSize', { size: bytesToSize(maxSize) })}</div>}
			{!isDragActive && <UploadButton text={t('dragAndDropField.chooseFile', plural)} size='sm' onClick={open} />}
			{isDragActive && <div className='dragOver'>{t('dragAndDropField.dragOver')}</div>}
			{fileRejections.some((i) => i.errors.some((i) => i.code === 'file-invalid-type')) && <div className='dragReject'>{t('dragAndDropField.dragReject')}</div>}
			{fileRejections.some((i) => i.errors.some((i) => i.code === 'file-too-large')) && <div className='dragReject'>{t('dragAndDropField.fileTooLarge')}</div>}
			{fileRejections.some((i) => i.errors.some((i) => i.code === 'too-many-files')) && <div className='dragReject'>{t('dragAndDropField.tooManyFiles')}</div>}
			<input {...getInputProps()} />
		</div>
	);
}
