import React from 'react';
import { Tab, Tabs } from '@smartplatform/ui';
import t from 'i18n';
import { Redirect, Route } from 'react-router-dom';
import Dashboard from './main/Dashboard';
import Statistics from './statistics/Statistics';

export default ({ match }) => {
	const { path } = match;
	return (
		<>
			<Route path={path} exact>
				<Redirect to={`${path}/main`} />
			</Route>
			<Tabs>
				<Tab path={`${path}/main`} title={t('techFire.dashboard.title')} render={(props) => <Dashboard {...props} />} />
				<Tab path={`${path}/statistics`} title={t('techFire.statistics.title')} render={(props) => <Statistics {...props} />} />
			</Tabs>
		</>
	);
};