import { Fill, Stroke, Style } from 'ol/style';
import { asArray } from 'ol/color';
import VectorLayer from 'ol/layer/Vector';
import { Vector as VectorSource } from 'ol/source';

import { ColorUtils } from '@smartplatform/ui';
import { drawGeoMarker, geoJSON } from '@smartplatform/map/client';
import store from 'client/store';
import t from 'i18n';
import BlockedRoadPopup from './BlockedRoadPopup';
import React from 'react';
import { blockedRoadLineColor } from '../../../../helper';

export default class BlockedRoadLayer {
	constructor(mapStore, settings = {}) {
		this.mapStore = mapStore;
		this.settings = settings;
		this.enable = store.model?.TechFireBlockedRoad?.INFO.READ;
	}

	init = async () => {
		this.layer = new VectorLayer({
			format: geoJSON,
			source: new VectorSource(),
		});

		this.layer.setZIndex(3);
		this.setVisible();
		this.mapStore.addLayer(this.layer);
		this.load();
	};

	get where() {
		const filter = { where: {} };
		filter.where.or = [];
		const { close, willClose } = this.settings.filter;

		if (close) {
			filter.where.or.push({ dateOpen: null, dateClose: { lt: new Date() } });
			filter.where.or.push({ dateOpen: { gt: new Date() }, dateClose: { lt: new Date() } });
		}
		if (willClose) {
			filter.where.or.push({ dateClose: { gt: new Date() } });
		}

		return filter;
	}

	load = async () => {
		const records = await store.model.TechFireBlockedRoad.find(this.where);

		const features = [];
		for (let record of records) {
			const { geo } = record;

			const { r, g, b } = ColorUtils.hexToRgb('#0af');

			let styleParams = {
				fill: new Fill({ color: asArray([r, g, b, 0.4]) }),
				stroke: new Stroke({ color: blockedRoadLineColor(record), width: 6 }),
			};

			const marker = drawGeoMarker(geo, {
				data: {
					title: t('techFireBlockedRoad.plural'),
					record,
					render: this.renderPopup,
				},
				style: new Style({
					...styleParams,
				}),
			});

			features.push(...marker);
		}

		this.layer.getSource().clear();
		this.layer.getSource().addFeatures(features);
	};

	setVisible = () => {
		this.layer.setVisible(this.settings.show);
	};

	onLocalStoreChange = (field, record) => {
		this.settings.filter[field] = record;
		store.local.save();
		this.load();
	};

	renderPopup = (record) => {
		return <BlockedRoadPopup record={record} noPopupLink={this.settings.noPopupLink} />;
	};
}
