import React from 'react';
import t from 'i18n';
import { Button } from '@smartplatform/ui';
import { ControlsContext } from './Controls';

export const SaveButton = (props) => {
	const context = { ...React.useContext(ControlsContext), ...props };
	const { form, saveTitle, onSave, noSave, disableSave } = context;

	if (noSave) return null;

	const _onSave = async () => {
		form?.save();
		onSave?.();
	};

	return (
		<Button onClick={_onSave} variant='primary' disabled={disableSave}>
			{saveTitle || t('save')}
		</Button>
	);
};
