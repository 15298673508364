import React from 'react';
import store from 'client/store';
import t from 'i18n';
import { RecordSelect } from '@smartplatform/ui';
import PropTypes from 'prop-types';
import { observable, reaction } from 'mobx';
import { observer } from 'mobx-react';
import techFiresStore from 'techfires/techFiresStore';

@observer
export default class GarrisonSelect extends React.Component {
	@observable garrison = null;

	static propTypes = {
		value: PropTypes.object.isRequired,
		onChange: PropTypes.func.isRequired,
		where: PropTypes.object,
		setDefault: PropTypes.bool,
	};

	constructor(props) {
		super(props);
		this.setDefaultGarrison();
	}

	setDefaultGarrison = async () => {
		if ((this.props.setDefault && techFiresStore.userGarrisons.length > 1) || techFiresStore.userGarrisons.length === 1) {
			this.garrison = techFiresStore.userGarrisons[0];
			if (this.props.onChange) this.props.onChange(this.garrison);
		}
	};

	onChange = (value) => {
		console.log('🚀  ~ GarrisonSelect ~ value:', value);
		if (this.props.onChange) this.props.onChange(value);
	};

	render() {
		const { onChange } = this;
		const { value, where, ..._props } = this.props;
		const _value = this.props.setDefault && !value ? this.garrison : value;
		return (
			<RecordSelect
				model={store.model.TechFireOrganization}
				onChange={onChange}
				value={_value}
				placeholder={t('garrison')}
				computed={(r) => r.name}
				filter={{ where: { and: [{ ...where }, { id: { inq: techFiresStore.userGarrisons.map((r) => r.id) } }] } }}
				disabled={techFiresStore.userGarrisons.length === 1}
				{..._props}
			/>
		);
	}
}
