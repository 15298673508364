import React from 'react';
import { observer } from 'mobx-react';
import { SaveButton, ReportToolbar } from 'components';
import t from '@smartplatform/map/i18n';
import { Input } from '@smartplatform/ui';
import { ReportStore } from './store';
import { observable } from 'mobx';

const CODE = 'SummaryFirePlan';

@observer
export class SummaryFirePlan extends React.Component {
	@observable fields = FIELDS.reduce((acc, value) => {
		acc[value] = undefined;
		return acc;
	}, {});

	constructor(props) {
		super(props);
		this.store = new ReportStore(CODE);
		this.init();
	}

	init = async () => {
		await this.store.init();
	};

	render() {
		return (
			<div>
				<div>
					<ReportToolbar {...this.store.storeParams} />
				</div>
			</div>
		);
	}
}
