import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { ListStore, Toolbar, Form, DateRangePicker } from 'components';
import { Column, Pager, Table, Popup, Field, Input } from '@smartplatform/ui';

import store from 'client/store';
import t from 'i18n';

const MODEL_NAME = 'EmergencyReserves';

@observer
export class ReservesList extends React.Component {
	@observable selectedRecord = null;
	@observable dateRangeValues = {
		period: 'year',
		periodDate: new Date(),
		startDate: null,
		endDate: null,
	};

	constructor(props) {
		super(props);
		this.cityId = props.store.record.id;
		this.listStore = new ListStore({
			model: store.model[MODEL_NAME],
			path: props.path,
			filters: { where: { cityId: this.cityId, and: [] } },
		});
	}

	componentDidMount() {
		this.listStore.setPerPage();
	}

	create = () => {
		const { cityId } = this;
		this.selectedRecord = new store.model[MODEL_NAME]({ cityId });
	};

	onRowClick = (record) => {
		this.selectedRecord = record;
	};

	onClosePopup = () => {
		this.listStore.reload();
		this.selectedRecord = null;
	};

	reloadFilters = () => {
		this.listStore.query.where.and = [];
		if (this.dateRangeValues.startDate) this.listStore.query.where.and.push({ date: { gte: this.dateRangeValues.startDate } });
		if (this.dateRangeValues.endDate) this.listStore.query.where.and.push({ date: { lte: this.dateRangeValues.endDate } });
		this.listStore.reload();
	};

	render() {
		const { onChange, perPage, totalCount, page, tableProps, search, onSearch } = this.listStore;
		const { create, onClosePopup, onRowClick, dateRangeValues, reloadFilters } = this;
		const { disabled } = this.props;

		return (
			<>
				<div className='reserves-list'>
					<Toolbar>
						{!disabled && <Toolbar.AddButton onClick={create} />}
						<Toolbar.SearchIconInput value={search} onChange={onSearch} />
					</Toolbar>
					<div className='reserves-filters'>
						<DateRangePicker dateValues={dateRangeValues} onChange={reloadFilters} />
					</div>
					<Pager current={page} onChange={onChange('page')} totalCount={totalCount || 0} itemsPerPage={perPage} />
					<div id='table'>
						<Table {...tableProps} onRowClick={onRowClick}>
							<Column property='name' label={t('emergencyReserves.name')} />
							<Column relation='nomenclature' property='name' label={t('emergencyReserves.nomenclature')} />
							<Column property='fact' label={t('emergencyReserves.fact')} />
							<Column property='norma' label={t('emergencyReserves.norma')} />
							<Column property='date' label={t('emergencyReserves.date')} />
						</Table>
					</div>
				</div>
				{this.selectedRecord && (
					<Popup onClose={onClosePopup} className='reserve-popup'>
						<h2>{this.selectedRecord.id ? t('editRecord') : t('addRecord')}</h2>
						<Form record={this.selectedRecord} onSave={onClosePopup} onDelete={onClosePopup} onCancel={onClosePopup} stay disabled={disabled}>
							<Field property='name' label={t('emergencyReserves.name')} autoFocus />
							<Field relation='nomenclature' property='name' label={t('emergencyReserves.nomenclature')} />
							{this.selectedRecord?.nomenclature?.unit && (
                                <div className='form-field'>
                                    <label>{t('emergencyReserves.unit')}</label>
                                    <Input value={this.selectedRecord?.nomenclature?.unit?.name} className={'unit-input'} disabled/>
                                </div>
                            )}
                            <Field property='fact' label={t('emergencyReserves.fact')} />
                            <Field property='norma' label={t('emergencyReserves.norma')} />
                            <Field property='date' label={t('emergencyReserves.date')} />
                        </Form>
                    </Popup>
                )}
            </>
        );
    }
}
