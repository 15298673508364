import i18next from 'i18next';

import en from './en';
import ru from './ru';

let currentLanguage = localStorage['smartplatform:language'] || '"ru"';

currentLanguage = currentLanguage.substring(1, currentLanguage.length - 1);

const namespace = 'translation';
!i18next.isInitialized && i18next.init({ lng: currentLanguage || 'ru', resources: {} });
i18next.addResourceBundle('ru', namespace, ru[namespace] || ru, true, true);
i18next.addResourceBundle('en', namespace, en[namespace] || en, true, true);

export default function (key, opts) {
	return i18next.t(key, opts);
}
