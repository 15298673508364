import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { ReservesList } from './ReservesList';
import { MODEL_NAME } from './constants';
import { SinglePage } from "./single";

export const Reserves = ({ match }) => {
	const { path } = match;
	return (
		<Switch>
			<Route path={path} exact children={({ match }) => <ReservesList match={match} modelName={MODEL_NAME} path={path} />} />
			<Route path={`${path}/:id`} children={({ match }) => <SinglePage match={match} path={path} />} />
		</Switch>
	);
};
