import { observable } from "mobx";

export default class JobDetailsAircraftForTheProtectionOfForestsStore {
	@observable year = new Date().getFullYear();

	onYearChange = (year) => (this.year = year);

	get storeParams() {
		const { year } = this;
		return {
			reportCode: 'JobDetailsAircraftForTheProtectionOfForests',
			reportParams: { year, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone },
			isHTML: true,
		};
	}
}
