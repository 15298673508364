import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';

import { geoJSON, drawGeoMarker } from '@smartplatform/map/client';
import { Fill, Icon, Stroke, Style } from 'ol/style';
import { asArray } from 'ol/color';
import iconSrc from './mining.svg?url';
import store from 'client/store';
import { ColorUtils } from '@smartplatform/ui';
import t from 'i18n';
import React from 'react';
import { MineralsPopup } from 'components';

export default class MineralsLayer {

	constructor(mapStore, settings = {}) {
		this.mapStore = mapStore;
		this.settings = settings;
		this.layer = new VectorLayer({
			format: geoJSON,
			source: new VectorSource(),
		});
		this.layer.setZIndex(10);
		this.mapStore.addLayer(this.layer);
	}

	init = async () => {
		this.setVisible();
		this.load();
	};

	load = async () => {
		const records = await store.model.MineralMiningObject.find({
			include: [
				{
					relation: 'mining',
					scope: {
						include: [
							{
								relation: 'mineral',
								scope: {
									include: ['category'],
								},
							},
							'object',
						],
					},
				},
			],
		});
		const features = [];

		for (let record of records) {
			const { geo } = record;

			const { r, g, b } = ColorUtils.hexToRgb('#0af');

			let styleParams = {
				fill: new Fill({ color: asArray([r, g, b, 0.4]) }),
				stroke: new Stroke({ color: asArray([r, g, b, 0.9]), width: 1 }),
			};

			const marker = drawGeoMarker(geo, {
				data: {
					title: t('mineral.mining'),
					record,
					render: this.renderPopup,
				},
				style: new Style({
					...styleParams,
					image: new Icon({
						src: iconSrc,
					}),
				}),
			});

			features.push(...marker);
		}
		// console.log(features)
		this.layer.getSource().clear();
		this.layer.getSource().addFeatures(features);
	};

	setVisible = () => {
		this.layer.setVisible(this.settings.show);
	};

	renderPopup = (data) => {
		return <MineralsPopup record={data} />;
	}
}
