import { observable } from 'mobx';
import { endOfDay, endOfMonth, endOfToday, endOfYear, startOfDay, startOfMonth } from 'date-fns';
import store from 'client/store';
import { exportReport } from 'client/tools';

export class ReportStore {
	@observable startDate = startOfMonth(new Date());
	@observable endDate = endOfToday();
	@observable error = null;
	@observable report = null;
	@observable isLoading = true;
	@observable headOrg = null;
	@observable headOrgIds = [];
	@observable fireDeps = [];

	constructor(code, name, withInit = true) {
		this.code = code;
		this.name = name;
		withInit && this.init();
	}

	init = async () => {
		this.garrisonId = store.model.user.garrisonId;
		if (this.garrisonId) {
			this.headOrg = await store.model.TechFireOrganization.findById(this.garrisonId);
			if (this.headOrg) this.getHeadOrgIds();
			this.clearFireDeps();
		}
		if (this.code) {
			const report = await store.model.TechFireReport.find({ where: { code: this.code } });
			this.report = report[0] ?? (await store.model.TechFireReport.create({ code: this.code, name: this.name }));
			if (!this.report.name && this.code) {
				this.report.name = this.name;
				this.report.save();
			}
		}
	};

	getFireDepIds = (record) => {
		if (!record) return [];
		const ids = [];
		const getIds = async (record) => {
			ids.push(record.id);
			(await record.children()).forEach(getIds);
		};
		getIds(record);
		return ids;
	};

	getFireDepsByGarrisonId = async (id) => {
		this.fireDeps = await store.model.ViewFireDep.find({ where: { garrisonId: id } });
		this.isLoading = false;
	};

	getHeadOrgIds = async () => {
		const record = await store.model.TechFireOrganization.findById(this.headOrg.id, { include: ['children'] });
		this.headOrgIds = [];
		if (record) {
			const getIds = async (record) => {
				this.headOrgIds.push(record.id);
				(await record.children()).forEach(getIds);
			};
			getIds(record);
		}
		this.isLoading = false;
	};

	getSortedFireDeps = async (ids) => {
		if (!ids) return null;
		const fireDeps = await store.model.FireDep.find({ where: { id: { inq: ids } } });
		const fireDepMap = new Map();
		fireDeps.forEach((fireDep) => fireDepMap.set(fireDep.id, fireDep));
		const sortedFireDeps = ids.map((id) => fireDepMap.get(id)).filter((fireDep) => fireDep);
		this.isLoading = false;
		return sortedFireDeps;
	};

	onFireDepToggle = (fireDeps) => {
		this.fireDeps = fireDeps;
	};

	clearFireDeps = () => (this.fireDeps = []);

	onChange = (prop) => (value) => (this[prop] = value);

	onStartDateChange = (value) => (this.startDate = value && startOfDay(value));
	onEndDateChange = (value) => (this.endDate = value && endOfDay(value));

	onStartDateChangeWithinYear = (value) => {
		this.startDate = value && startOfDay(value);
		this.endDate = this.endDate && this.endDate.getFullYear() !== this.startDate.getFullYear() ? endOfYear(this.startDate) : this.endDate;
	}

	onFireDepChange = (value) => (this.fireDep = value);
	onHeadOrgChange = (value) => (this.headOrg = value);

	get storeParams() {
		const params = { reportCode: this.code, showPreview: false };
		const reportParams = {};
		if (this.startDate) {
			reportParams.startDate = this.startDate;
			reportParams.startOfMonth = startOfMonth(this.startDate);
		}
		if (this.endDate) {
			reportParams.endDate = this.endDate;
			reportParams.endOfMonth = endOfMonth(this.endDate);
		}
		if (this.headOrg) {
			reportParams.headOrg = this.headOrg;
			reportParams.headOrgIds = this.headOrgIds;
		}
		if (this.fireDep) {
			reportParams.fireDep = this.fireDep;
		}
		if (this.fireDeps) {
			reportParams.fireDeps = this.fireDeps.map((item) => item.id);
		}
		if (this.code === 'List112') {
			reportParams.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
		}
		if (Object.keys(reportParams).length) params.reportParams = reportParams;
		if (this.fireDep) {
			reportParams.fireDep = this.fireDep;
		}
		return params;
	}

	onExport = async (params) => {
		try {
			if (params.constructor.name !== 'Object') params = this.storeParams;
			this.isLoading = true;
			this.error = null;

			const { content, filename } = await store.model.TechFireReport.getReport(params);
			exportReport({ filename, content });
		} catch (e) {
			this.error = e.message;
		}
		this.isLoading = false;
	};
}
