import React from 'react';
import { inject, observer } from 'mobx-react';
import t from 'i18n';
import MenuIcon from 'img/icons/fires/card/menu.svg';
import { Card, LabelField } from 'components';
import { Input, NumberInput, RecordSelect, Row } from '@smartplatform/ui';
import store from 'client/store';
import { startOfYear } from 'date-fns';

@inject('card')
@observer
export class Numbering extends React.Component {
	render() {
		const { card } = this.props;
		const { onChange } = card;

		return (
			<Card title={t('fire.numbers')} icon={<MenuIcon />}>
				<Row className='card-row'>
					{['fireNumber', 'forestryFireNumber', 'aviaDepFireNumber'].map((label) => (
						<LabelField label={t('fire.' + label)} key={label} text={card.fire[label] || t('field.empty')} />
					))}
				</Row>
				<Row className='card-row'>
					{['bigFireNumber', 'fireId'].map((label) => (
						<LabelField label={t('fire.' + label)} key={label} text={card.fire[label] || t('field.empty')} />
					))}
					<div />
				</Row>
				<Row>
					<LabelField label={t(`fire.isdmFireNumberManual`)}>
						<NumberInput value={card.fire.isdmFireNumber} onChange={onChange('isdmFireNumber')} positiveOnly />
					</LabelField>
					<LabelField label={t(`fire.isdmFireNumber`)}>
						<RecordSelect
							model={store.model.IsdmFire}
							value={card.fire.isdmFire}
							property='fireNumber'
							onChange={onChange('isdmFire')}
							filter={{
								where: {
									and: [
										{ reportDate: { gte: startOfYear(new Date()) } },
										{ comment: { neq: 'Закрыт решением КЧС (в зоне контроля)' } },
										{ status: `действует` },
									],
								},
							}}
						/>
					</LabelField>
					<div />
				</Row>
			</Card>
		);
	}
}
