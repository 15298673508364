export const TECH_FIRES_LIST_FILTER = {
	include: [
		{
			relation: 'object',
			scope: {
				include: [
					{ relation: 'rank', scope: { fields: ['id', 'name'] } },
					{ relation: 'fireDep', scope: { fields: ['id', 'name'] } },
				],
			},
		},
		{ relation: 'status' },
		{ relation: 'rank' },
		{ relation: 'source' },
		{ relation: 'fireDep', scope: { fields: ['id', 'name'] } },
	],
};
