import React from 'react';
import { inject, observer } from 'mobx-react';
import store from 'client/store';
import t from 'i18n';
import { ExportButton, Controls, LastEditField } from 'components';
import { exportReport } from 'client/tools';
import { WARNING } from 'client/constants';
import './actionbar.scss';

@inject('card')
@observer
export class Actionbar extends React.Component {
	constructor(props) {
		super(props);
		this.fireLog = props.card.fire;
		this.fireId = this.fireLog.fireId;
	}
	onDelete = async () => {
		const fire = new store.model.Fire({ id: this.fireId });
		await fire.delete();
		store.route.push({ path: `/fires/registry` });
		store.ui.setNotification({ message: t('record.deleted'), type: WARNING });
	};

	downloadFireAct = async () => {
		const { content, filename } = await store.model.FireReport.getReport({ reportCode: 'FireAct', reportParams: { id: this.fireId } });
		exportReport({ filename, content });
	};

	get lastUpdatedLog() {
		return this.props.card.fireLogs.reduce((a, b) => (a?.updatedAt > b.updatedAt ? a : b), null);
	}

	render() {
		const { onSave, changed, disabledEdit, fire, dropChanges } = this.props.card;
		const { onDelete, downloadFireAct, lastUpdatedLog } = this;
		const isRequiredFieldsFilled = [
			'aviaDepId',
			'forestryId',
			'zoneId',
			'geo',
			'forestOwnershipId',
			'passedBreed',
			'cover',
			'forestCategory',
			'cause',
			'landCategory',
			'determiningMethod',
			'county',
		].every((field) => !!fire[field]);
		const disableSave = disabledEdit || !changed || !isRequiredFieldsFilled;
		const fullAccess = store.model.roles.find((role) => ['admin'].includes(role.name));

		return (
			<div className='fire-actionbar'>
				<Controls>
					<Controls.SaveButton disableSave={disableSave} onSave={onSave} />

					{this.fireId && (
						<>
							{changed && <Controls.CancelButton onCancel={dropChanges} cancelTitle={t('cancelEdit')} />}
							<ExportButton text={t('fire.fireAct')} onClick={downloadFireAct} />
							{fullAccess && <Controls.DeleteButton onDelete={onDelete} deleteTitle={t('fire.delete')} />}
							<LastEditField record={lastUpdatedLog} user={lastUpdatedLog.updateUser || lastUpdatedLog.owner} className='last-edit' />
						</>
					)}
				</Controls>
			</div>
		);
	}
}
