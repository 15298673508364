import React from 'react';
import { inject, observer } from 'mobx-react';
import t from 'i18n';
import { Column, RelationSelect, Table } from '@smartplatform/ui';
import { fio } from 'client/tools';
import { fireDepVehicleItem } from 'techfires/helpers';

@inject('store')
@observer
export class PersonnelLogs extends React.Component {
	render() {
		const { personnelLogs } = this.props.store;
		return (
			<div>
				<h2>{t('forestFireMovement.extinguishingParticipants')}</h2>
				<Table className='personnelLogs' rows={personnelLogs}>
					<Column label={t('fireDep.title')} relation='fireDep' property='name' />
					<Column label={t('fio')} computed={fio} />
					<Column label={t('fireDepPosition.title')} relation='position' property='name' />
					<Column label={t('fireDepVehicle.title')}>
						<RelationSelect relation='vehicle' disabled computed={fireDepVehicleItem} />
					</Column>
				</Table>
			</div>
		);
	}
}
