import { Column, Pager, Table } from '@smartplatform/ui';
import React from 'react';
import t from 'i18n';
import store from 'client/store';
import { ListStore, Toolbar } from 'components';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

@observer
export default class List extends React.Component {
	@observable search = '';

	constructor(props) {
		super(props);
		this.store = new ListStore({ path: props.path, filters: { include: ['attachments'] }, model: store.model[props.modelName] });
	}

	componentDidMount() {
		this.store.setPerPage();
	}

	render() {
		const { tableProps, totalCount, perPage, page, onChange, search, onSearch, create } = this.store;

		return (
			<>
				<Toolbar>
					<Toolbar.AddButton onClick={create} />
					<Toolbar.SearchIconInput value={search} onChange={onSearch} />
				</Toolbar>
				<Pager current={page} onChange={onChange('page')} totalCount={totalCount || 0} itemsPerPage={perPage} />
				<Table {...tableProps}>
					<Column property='name' label={t('name')} />
					<Column property='contacts' label={t('contacts')} />
					<Column property='approval' label={t('permafrostOrganization.approval')} />
					<Column computed={(r) => r.attachments().length} label={t('attachments')} />
				</Table>
			</>
		);
	}
}
