import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { ReportStore } from '../store';
import t from 'i18n';
import './style.scss';
import { SaveButton, ReportToolbar } from 'components';

const CODE = 'Appg';
const FIELDS = ['sign1', 'sign2'];

@observer
export class Appg extends React.Component {
	@observable fields = {
		sign1: '',
		sign2: '',
	};
	@observable preview = null;

	constructor(props) {
		super(props);
		this.store = new ReportStore(CODE, t('techFireReport.appg'));
		this.init();
	}

	init = async () => {
		await this.store.init();
		FIELDS.forEach((field) => (this.fields[field] = this.store.report[field] ?? ''));
	};

	onChange = (name) => (e) => (this.fields[name] = e.target.value);

	renderInput = (name) => <textarea autoComplete={name} value={this.fields[name]} onChange={this.onChange(name)} />;

	saveReport = async () => {
		FIELDS.forEach((field) => (this.store.report[field] = this.fields[field]));
		await this.store.report.save();
	};

	render() {
		const { name } = this.store;

		return (
			<form>
				<h2>{name}</h2>
				{['sign1', 'sign2'].map((field) => (
					<div className='form-field' key={field}>
						<label>{t('techFireReport.' + field)}</label>
						{this.renderInput(field)}
					</div>
				))}
				<div>
					<SaveButton text={t('techFireReport.saveTemplate')} onClick={this.saveReport} className='mr-2' />
					<ReportToolbar reportCode={CODE} reportParams={this.fields} showPreview={false} />
				</div>
			</form>
		);
	}
}
