import React from 'react';
import { inject, observer } from 'mobx-react';
import { DatePicker, Input, RecordSelect, Row } from '@smartplatform/ui';
import t from 'i18n';
import FileCheck from 'img/icons/fires/card/file-check.svg';
import { Card, LabelField } from 'components';
import store from 'client/store';
import { renderStatus, fioShort, relative } from 'client/tools';

@inject('card')
@observer
export class MainInfo extends React.Component {
	constructor(props) {
		super(props);
		this.card = this.props.card;
		this.fireLogs = this.card.fireLogs;
	}

	onDateChange = (date) => {
		this.card.onChange('date')(date);
		//* триггернем ререндер сайдбара новой ссылкой
		this.fireLogs.replace(this.fireLogs);
	};

	render() {
		const { card, disabled } = this.props;
		const { fire, onChange } = card;
		const selectProps = { noTotal: true, maxItems: 20, itemsPerPage: 1000, filter: { fields: ['id', 'name', 'color'] }, property: 'name', disabled };
		const { model } = store;
		return (
			<Card title={t('fire.mainInfo')} icon={<FileCheck />}>
				<Row className='card-row'>
					<LabelField label={t('fire.dynamicDate')} isRequired>
						<DatePicker value={fire.date} onChange={this.onDateChange} showTimeSelect showClearButton={false} disabled={disabled} />
					</LabelField>
					<LabelField label={t('fire.status')}>
						<RecordSelect {...selectProps} model={model.FireStatus} value={fire.status} onChange={onChange('status')} isRequired computed={renderStatus} />
					</LabelField>
				</Row>
			</Card>
		);
	}
}
