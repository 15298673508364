import React from 'react';
import { observer } from 'mobx-react';
import { TextField } from 'fires/components';
import t from 'i18n';
import { observable } from 'mobx';
import store from 'client/store';

@observer
export class IncidentPopup extends React.Component {
	@observable incident;

	componentDidMount = async () => {
		const object = await store.model.RequestIncidentObject.findById(this.props.record.id, {
			include: [{ relation: 'incident', scope: { include: ['type', 'status'] } }],
		});
		this.incident = object.incident;
	};

	go = () => store.route.push({ path: `/delivery/incidents/${this.incident?.id}` });

	render() {
		const { noPopupLink } = this.props;
		if (!this.incident) return;
		return (
			<div>
				<div className='title'>
					<h2>
						{t(`requestIncident.title`)}:
						{noPopupLink ? (
							<span>#{this.incident.id}</span>
						) : (
							<a style={{ cursor: 'pointer' }} onClick={this.go}>
								#{this.incident.id}
							</a>
						)}
					</h2>
				</div>

				<TextField label={t('name')} value={this.incident.name} />
				<TextField label={t('type')} value={this.incident.type?.name} />
				<TextField label={t('status')} value={this.incident.status?.name} />
			</div>
		);
	}
}
