import { defaultsDeep } from 'lodash';
import { toJS } from 'mobx';
import store from 'client/store';
import { startOfMonth } from 'date-fns';
import { modelsConfigAll as modelsConfig } from 'components/common-layers/models/models-config/index';

const layers = {
	counties: {
		show: false,
	},
	genShtab: {
		show: false,
	},
	ships: {
		show: false,
	},
	weather: {
		show: false,
		minimized: false,
		layer: 'pm25',
	},
	wind: {
		show: false,
		minimized: false,
		fadeOpacity: 0.995,
		speedFactor: 5,
		particleLife: 128,
		opacity: 0.8,
		webgl: true,
	},
	fireDep: {
		show: false,
	},
	incidents: {
		show: false,
	},
	tracking: {
		show: false,
		status: {
			SHIPPED: true,
			DELIVERED: true,
		},
	},
	fires: {
		noPopupLink: true, // линк на карточку в попапе
		show: true,
		minimized: false,
		startDate: startOfMonth(new Date()),
		endDate: new Date(),
		status: {
			intensifies: true, // Усиливается
			continues: true, // Продолжается
			weakens: true, // Ослабевает
			notspreading: true, // Не распространяется
			localized: true, // Локализован
			resumed: true, // Возобновился
			extinguished: false, // Ликвидирован
			abandoned: false, // Прекращено тушение по решению КЧС
		},
		service: {
			serviced: true, // Обслуживаемые
			unattended: true, // Необслуживаемые
		},
	},
};

const defaultConfig = {
	tileSource: '2gis',
	filtersMaximized: false,
	layers,
	dynamicLayers: [],
	incidentDymanicLayers: [],
	contragents: {},
	requests: {},
	tracking: {},
	trackingShips: {},
	reserves: {},
	incidents: {},
};

export default function initLocal() {
	layers.models = {};
	Object.values(modelsConfig)
		.filter((modelConfig) => !!modelConfig.showInFilters)
		.forEach((modelConfig) => {
			layers.models[modelConfig.modelName] = { show: false };
		});

	store.local.delivery = defaultsDeep(toJS(store.local.delivery), defaultConfig);
	store.local.save();
}
