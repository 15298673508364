import { observer, inject } from 'mobx-react';
import React from 'react';
import t from 'i18n';
import { Column, Input, Pager, RecordSelect, RelationSelect, Select, Table } from '@smartplatform/ui';
import { Toolbar } from 'components';
import store from 'client/store';
import { fireDepSpecialTypeItem } from 'techfires/helpers';
import './vehicleList.scss';
import VehicleListStore from './store';

const SELECT_WIDTH = 220;
@inject('fireDepStore')
@observer
export class VehicleList extends React.Component {
	constructor(props) {
		super(props);
		this.store = new VehicleListStore(props.match.path, this.props.fireDepStore.fireDep.id);
	}

	componentDidMount() {
		this.store.setPerPage();
	}

	renderSentryName = (record) => {
		if (record.name) return record.name;
		if (record.globalSentry) return record.globalSentry.name;
		return '-';
	};

	render() {
		const { error, tableProps, create, onChange, totalCount, page, perPage } = this.store;
		if (error) return <div className='error'>{error}</div>;
		const { onChangeFilters, year, years, type, brand, statuses, onStatusSelect, callSigns, onCallSignSelect } = this.store;
		const fireDepId = this.props.fireDepStore.fireDep.id;

		return (
			<div className='vehicle-list'>
				<div className='filters'>
					<Toolbar className='d-flex'>
						<Toolbar.AddButton onClick={create} />
					</Toolbar>
					<RecordSelect
						model={store.model.FireDepVehicleBrand}
						value={brand}
						onChange={onChangeFilters('brand')}
						property='name'
						placeholder={t('fireDepVehicleBrand.chooseBrand')}
						width={SELECT_WIDTH}
					/>
					<RecordSelect
						model={store.model.FireDepVehicleType}
						value={type}
						onChange={onChangeFilters('type')}
						property='name'
						placeholder={t('fireDepVehicleType.chooseType')}
						width={SELECT_WIDTH}
					/>
					<Select items={years} value={year} onChange={onChangeFilters('year')} placeholder={t('fireDepVehicle.chooseType')} width={SELECT_WIDTH} />
				</div>
				<Pager current={page} onChange={onChange('page')} totalCount={totalCount || 0} itemsPerPage={perPage} />
				<div className='mt-2' id='table'>
					<Table {...tableProps}>
						<Column relation='status' label={t('fireDepVehicleStatus.title')} width={250}>
							{({ record }) => (
								<Select
									items={statuses}
									value={record.status}
									computed={(r) => r.name}
									onChange={(status) => onStatusSelect(status, record)}
									disabled={record.vehicleStatusId === this.props.fireDepStore.vehicleFFStatus?.id}
								/>
							)}
						</Column>
						<Column relation='callSign' label={t('vehicle.callSign')} width={250}>
							{({ record }) => (
								<Select items={callSigns} value={record.callSign} computed={(r) => r.name} onChange={(callSign) => onCallSignSelect(callSign, record)} />
							)}
						</Column>

						<Column property='stateNumber' label={t('vehicle.stateNumber')} />
						<Column relation='brand' property='name' label={t('fireDepVehicle.brand')} />
						<Column relation='type' property='name' label={t('fireDepVehicle.type')} />
						<Column relation='type' computed={fireDepSpecialTypeItem} label={t('fireDepVehicle.specialType')} />
						<Column relation='cisternBulk' property='volume' label={t('vehicle.capacity')} />
						<Column property='actualFoamValue' label={t('vehicle.actualFoamValue')} />
						<Column relation='pump' property='capacity' label={t('pump.title')} />
						<Column property='year' label={t('vehicle.year')} computed={(r) => r.year} />
					</Table>
				</div>
			</div>
		);
	}
}
