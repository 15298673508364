import React, { Component } from 'react';
import { RecordSelect, Input, Checkbox, Button, DatePicker } from '@smartplatform/ui';
import store from 'client/store';
import { inject, observer } from 'mobx-react';
import t from 'i18n';
import './filters.scss';
import GarrisonSelect from 'techfires/components/garrison-select/GarrisonSelect';

@observer
export class Filters extends Component {
	render() {
		const {
			addressSearch,
			ktp,
			ptp,
			overnightStay,
			overduePTPCTP,
			workingOutPTPCTP,
			date0,
			date1,
			date2,
			date3,
			descriptionSearch,
			nameSearch,
			rank,
			onChange,
			resetFilters,
			onSearch,
			objectsCountSearch,
			garrison,
		} = this.props.store;
		const { noAdditionalFilters, noObjectCount } = this.props;
		return (
			<div className='address-filters'>
				<div>
					<Input value={nameSearch} type='search' onChange={onSearch('nameSearch', 'name')} placeholder={t('enter.name')} className='input-name' />
					<GarrisonSelect value={garrison} onChange={onChange('garrison')} width={200} />
					{!noObjectCount && (
						<Input
							value={objectsCountSearch}
							type='search'
							onChange={onSearch('objectsCountSearch', 'objectsCount')}
							placeholder={t('enter.objectCount')}
							className='input-name'
						/>
					)}
					{!noAdditionalFilters && (
						<>
							<Input
								value={addressSearch}
								type='search'
								onChange={onSearch('addressSearch', 'addressName')}
								placeholder={t('enter.addressName')}
								className='input-name'
							/>
							<Input
								value={descriptionSearch}
								type='search'
								onChange={onSearch('descriptionSearch', 'description')}
								placeholder={t('enter.description')}
								className='input-name'
							/>
							<RecordSelect model={store.model.TechFireRank} property='name' value={rank} onChange={onChange('rank')} empty={t('techFireAddress.allRanks')} />
						</>
					)}
					<Button variant='primary' onClick={() => store.route.push({ path: '/techfires/addresses/create' })}>
						{t('add')}
					</Button>
					<Button onClick={resetFilters}>{t('reset')}</Button>
				</div>
				{!noAdditionalFilters && (
					<>
						<div>
							<Checkbox value={ptp} label={t('techFireAddress.ptp')} onChange={onChange('ptp')} />
							<Checkbox value={ktp} label={t('techFireAddress.ktp')} onChange={onChange('ktp')} />
							<Checkbox value={overnightStay} label={t('techFireAddress.overnightStayPeople')} onChange={onChange('overnightStay')} />
						</div>
						<div className='ptpktp'>
							<div className='units-ptpktp'>
								<Checkbox value={overduePTPCTP} label={t('techFireAddress.overduePTPCTP')} onChange={onChange('overduePTPCTP')} />
								<div className='date-ptpktp'>
									<DatePicker
										value={date0}
										onChange={onChange('date0')}
										showTimeSelect={false}
										placeholder={t('techFireAddress.inspectionDateFrom')}
										className='input-name'
									/>
									<DatePicker
										value={date1}
										onChange={onChange('date1')}
										showTimeSelect={false}
										placeholder={t('techFireAddress.inspectionDateBefore')}
										className='input-name'
									/>
								</div>
							</div>
							<div className='units-ptpktp'>
								<Checkbox value={workingOutPTPCTP} label={t('techFireAddress.workingOutPTPCTP')} onChange={onChange('workingOutPTPCTP')} />
								<div className='date-ptpktp'>
									<DatePicker
										value={date2}
										onChange={onChange('date2')}
										showTimeSelect={false}
										placeholder={t('techFireAddress.inspectionDateFromCorrect')}
										className='input-name'
									/>
									<DatePicker
										value={date3}
										onChange={onChange('date3')}
										showTimeSelect={false}
										placeholder={t('techFireAddress.inspectionDateBeforeCorrect')}
										className='input-name'
									/>
								</div>
							</div>
						</div>
					</>
				)}
			</div>
		);
	}
}
