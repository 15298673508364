import React from 'react';
import { observable, action } from 'mobx';
import { startOfDay, endOfDay, addDays, subDays } from 'date-fns';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { transform, fromLonLat } from 'ol/proj';
import Feature from 'ol/Feature';
import { Fill, Stroke, Style, Icon, Circle as CircleStyle, Text } from 'ol/style';
import { asArray } from 'ol/color';
import circleToPolygon from 'circle-to-polygon';
import { ColorUtils } from '@smartplatform/ui';
import icons from 'img/icons/fires/map';
import { marker as markerIcon } from '@smartplatform/map/client';
import airfieldIcon from 'client/img/icons/airfield.svg?url';
import originIcon from 'client/img/icons/origin.svg?url';
import { geoJSON, drawGeoMarker, getFeatureCenter, getAllPoints } from '@smartplatform/map/client';
import { hexToGeoJson } from 'client/tools';
import store from 'client/store';
import { heatPointsLayerCustomMVT } from 'components/common-layers/heat-points';
import { modelsConfigAll as modelsConfig } from 'components/common-layers/models/models-config';
import { CountiesLayer, GenShtabLayer, WeatherLayer, WeatherTip, WindLayer, DzzLayer, ModelsLayer, getFireIconName } from 'components';
import ForestriesLayer from 'fires/map/forestries';
import ForestryQuartersLayer from '../map/forestry-quarters';
import { getStatusColor } from '../../helpers';
import { getCenter } from 'ol/extent';
import { Point } from 'ol/geom';

export default class FireCardMap {
	@observable mapStore = null; // инстанция mapStore

	@observable date = new Date(); // текущая дата/время (слайдер)
	@observable map = null; // инстанция openlayers
	@observable cityMarker = null;
	@observable heatPointsStartDate;
	@observable heatPointsEndDate;
	@observable weatherImage = null;
	@observable centroidGeo = null;
	@observable selectMode = null;
	@observable manualMode = false;
	@observable isInitialized = false;
	@observable popup = null;

	silamMin = 0;
	silamMax = 0;

	countiesLayer;
	forestryQuartersLayer;
	weatherLayer;
	heatPointsLayer;
	windLayer;
	genShtabLayer;

	constructor(card) {
		this.card = card;
		this.layersSettings = store.local.fires.card.layers;
	}

	init = async (mapStore) => {
		this.mapStore = mapStore;
		this.map = mapStore.map;

		// this.map.on('click', this.onClick);
		this.map.on('pointermove', this.onPointerMove);

		mapStore.on('modifyend', this.onDrawAction);
		mapStore.on('drawend', this.onDrawAction);
		mapStore.on('modifystart', this.onDrawAction);
		mapStore.on('drawstart', this.onDrawAction);
		mapStore.on('mouseleave', this.onMouseLeave);
		mapStore.on('popupclose', this.onPopupClose);

		this.countiesLayer = new CountiesLayer(mapStore, this.layersSettings.counties);
		this.dzzLayer = new DzzLayer(mapStore, this.layersSettings.dzz);
		this.genShtabLayer = new GenShtabLayer(mapStore, this.layersSettings.genShtab);
		this.forestriesLayer = new ForestriesLayer(mapStore, this.layersSettings.forestries);
		this.forestryQuartersLayer = new ForestryQuartersLayer(mapStore, this.layersSettings.forestryQuarters, this.card);
		this.weatherLayer = new WeatherLayer(mapStore, this.layersSettings.weather);
		this.windLayer = new WindLayer(mapStore, this.layersSettings.wind);
		this.modelsLayer = new ModelsLayer(mapStore, this.layersSettings.models);
		this.heatPointsLayer = new heatPointsLayerCustomMVT(mapStore, this.layersSettings.heatPoints);

		this.source = new VectorSource();
		this.layer = new VectorLayer({
			format: geoJSON,
			source: this.source,
		});
		mapStore.addLayer(this.layer);
		this.layer.setZIndex(10);
		this.setDatesForLayers();

		this.isInitialized = true;
	};

	setDatesForLayers = () => {
		if (!this.card.fire) return;
		const daysCount = this.card.fireLogs.length === 0 ? 0 : 2;
		this.date = this.card.fire.date ? new Date(this.card.fire.date) : new Date();
		if (this.card.fireLogs.length > 0 && this.card.fire.date) {
			this.heatPointsStartDate = this.card.fireLogs[0].date
				? startOfDay(new Date(this.card.fireLogs[0].date))
				: startOfDay(subDays(new Date(this.card.fire.date), daysCount));
			this.heatPointsEndDate = endOfDay(new Date(this.card.fire.date));
		} else {
			this.heatPointsEndDate = endOfDay(this.date);
			this.heatPointsStartDate = startOfDay(subDays(this.heatPointsEndDate, daysCount));
		}
	};

	initLayers = async () => {
		this.countiesLayer.init();
		this.heatPointsLayer.init();
		this.weatherLayer.init();
		this.weatherTip = new WeatherTip(this);
		this.dzzLayer.init();
		this.genShtabLayer.init();
		this.forestriesLayer.init();
		this.forestryQuartersLayer.init();
		this.windLayer.init();
		this.modelsLayer.init();
		await this.addGeo();
		this.fitGeo();

		// const container = this.layer.getRenderer().container;
		// if (container) container.style.zIndex = 99;
	};

	setFireLog = () => {
		if (this.mapStore?.drawing) this.mapStore.stopDrawing();
		this.toggleSelectMode(null);
		this.setDatesForLayers();
		this.addGeo();
		this.heatPointsLayer.update();
		this.weatherLayer.update();
		this.windLayer.update();
	};

	/**
	 * Внутренний обработчик изменений в карточке пожара
	 */

	onChange = (property, value) => {
		if (property === 'status' || property === 'forestOwnership') this.setStyle();
		if (property === 'detectDate') {
			console.log('> 1', this.card.fire.date, this.card.fireLogs.length);
			if (!this.card.fire.date || this.card.fireLogs.length === 0) {
				this.card.onChange('date')(this.card.fire.detectDate);
			}
			console.log('> 2', this.card.fire.date);
		}
		if (property === 'date') {
			this.setDatesForLayers();
			this.heatPointsLayer.update();
		}
	};

	addGeo = async () => {
		if (this.map) {
			this.source.clear();
			await this.addFireGeo();
			this.setStyle();
			await this.addOriginMarker();
			await this.addCityMarker();
			await this.addAirfieldMarker();
			await this.updateLayers();
		}
	};

	updateLayers = async () => {
		// await this.countiesLayer.load(this.card.fire.geo);
		await this.forestryQuartersLayer.load(this.card.fire.geo);
	};

	fitGeo = () => {
		const fireLogs = [...this.card.fireLogs].filter((log) => !!log.date).sort((a, b) => new Date(a.date) - new Date(b.date));
		const lastFireLog = fireLogs[fireLogs.length - 1];
		if (lastFireLog && lastFireLog.geo) {
			this.setStyle();
			let geo = {
				type: 'GeometryCollection',
				geometries: [lastFireLog.geo],
			};

			if (this.city) geo.geometries.push(this.city.geo);
			if (this.airfield) geo.geometries.push(this.airfield.geo);

			this.mapStore.fitGeo(geo, 14);
		}
	};

	addFireGeo = () => {
		if (!this.card.fire.geo) {
			return;
		}
		const { geo, id } = this.card.fire;

		if (this.fireGeo) {
			this.source.removeFeature(this.fireGeo);
		}

		const featureObj = { id, geometry: geoJSON.readGeometry(geo) };
		const center = getCenter(featureObj.geometry.getExtent());
		featureObj.geometry = new Point(center);
		this.fireGeo = new Feature(featureObj);
		const color = getStatusColor(this.card.fire) || '#FF0000';
		const { r, g, b } = ColorUtils.hexToRgb(color);

		this.fireGeo.setStyle([
			new Style({
				image: new CircleStyle({
					radius: (store.local.fires.card.layers.heatPoints.selectRadius || 375) / 100,
					fill: new Fill({ color: asArray([r, g, b, 0.2]) }),
					stroke: new Stroke({ color: asArray([r, g, b, 1]), width: 2 }),
				}),
				text: new Text({
					textAlign: 'left',
					font: '7px/14px sans-serif',
					text: `${this.card.fire.forestryFireNumber || ''}${this.card.fire?.zone?.shortName || ''}`,
					stroke: new Stroke({
						color: '#FFFF',
						width: 2,
					}),
					offsetX: 6,
					offsetY: -12,
					scale: 1.5,
				}),
			}),
		]);
		this.source.addFeature(this.fireGeo);
	};

	addOriginMarker = () => {
		if (this.originMarker) {
			this.source.removeFeature(this.originMarker);
		}

		if (this.card.fire.hqGeo) {
			const features = drawGeoMarker(this.card.fire.hqGeo, {
				icon: new Icon({
					src: originIcon,
					anchor: [9, 31],
					anchorXUnits: 'pixels',
					anchorYUnits: 'pixels',
				}),
			});
			this.originMarker = features[0];
			this.source.addFeature(this.originMarker);
		}
	};

	addCityMarker = async () => {
		if (this.cityMarker) {
			this.source.removeFeature(this.cityMarker);
		}

		if (!this.card.fire.cityId) {
			this.city = null;
			return;
		}

		this.city = await store.model.City.findById(this.card.fire.cityId, { fields: ['id', 'geo'] });
		const { geo } = this.city;
		// console.log('>>> addCityMarker', geo);

		const featureObj = {
			isMarker: true,
			geometry: geoJSON.readGeometry(geo),
		};

		this.cityMarker = new Feature(featureObj);
		this.cityMarker.setStyle(
			new Style({
				image: new Icon({
					src: markerIcon,
					anchor: [9, 31],
					anchorXUnits: 'pixels',
					anchorYUnits: 'pixels',
				}),
			})
		);
		this.source.addFeature(this.cityMarker);
	};

	addAirfieldMarker = async () => {
		if (this.airfieldMarker) {
			this.source.removeFeature(this.airfieldMarker);
		}

		if (!this.card.fire.airfieldId) {
			this.airfield = null;
			return;
		}

		this.airfield = await store.model.Airfield.findById(this.card.fire.airfieldId, { fields: ['id', 'geo'] });
		const { geo } = this.airfield;
		// console.log('>>> addAirfieldMarker', geo);

		if (!geo) {
			this.airfield = null;
			return;
		}

		const hex = await this.getCentroid(geo);
		const point = hexToGeoJson(hex);

		const featureObj = {
			isMarker: true,
			geometry: geoJSON.readGeometry(point),
			title: modelsConfig.Airfield.title,
			record: this.airfield,
			render: modelsConfig.Airfield.renderPopup,
		};

		this.airfieldMarker = new Feature(featureObj);
		this.airfieldMarker.setStyle(
			new Style({
				image: new Icon({
					src: airfieldIcon,
					anchor: [9, 31],
					anchorXUnits: 'pixels',
					anchorYUnits: 'pixels',
				}),
			})
		);
		this.source.addFeature(this.airfieldMarker);
	};

	renderAirfieldPopup = (record) => <AirfieldPopup record={record} />;

	setStyle = () => {
		this.layer.setStyle(this.getStyle());
		if (this.marker) {
			const iconSrc = icons[getFireIconName(fire)].src || icons.default;
			this.marker.setStyle(
				new Style({
					image: new Icon({
						src: iconSrc,
						anchor: [9, 31],
						anchorXUnits: 'pixels',
						anchorYUnits: 'pixels',
					}),
				})
			);
		}
	};

	getStyle = () => {
		const color = getStatusColor(this.card.fire) || '#FF0000';
		const { r, g, b } = ColorUtils.hexToRgb(color);
		const styleParams = {
			fill: new Fill({ color: asArray([r, g, b, 0.2]) }),
			stroke: new Stroke({ color: asArray([r, g, b, 1]), width: 2 }),
		};

		return [
			new Style({
				icon: new Icon({
					src: icons.default,
					anchor: [9, 31],
					anchorXUnits: 'pixels',
					anchorYUnits: 'pixels',
				}),
			}),
			new Style(styleParams),
			/*
						new Style({
							image: new CircleStyle({
								radius: 5,
								fill: new Fill({ color: asArray([r, g, b, 1]) }),
							}),
							geometry: function (feature) {
								const geometry = feature.getGeometry();
								const points = getAllPoints(geometry);
								return new MultiPoint(points);
							},
						}),
			*/
		];
	};

	handleDrawing = (e) => {
		console.log('handleDrawing', e.type, e.layer, e.layers);
	};

	startDrawing = () => {
		this.origGeo = this.card.fire.geo;
		this.origCentroidGeo = this.centroidGeo;
		if (this.marker) this.source.removeFeature(this.marker);
		this.mapStore.startDrawing(this.layer);
	};

	getCentroid = async (geo) => {
		if (geo) {
			try {
				return await store.model.Fire.getCentroidGeo(geo);
			} catch (e) {
				console.error(e);
			}
		}
		return null;
	};

	finishDrawing = async () => {
		const geo = this.mapStore.finishDrawing();
		this.card.onChange('geo')(geo);
		this.card.onChange('area')(this.area || null);
		this.card.onChange('edge')(this.edge || null);
		this.addGeo();
		try {
			this.centroidGeo = await this.getCentroid(geo);
		} catch (e) {
			console.error(e);
		}
		console.log('! finishDrawing', geo);
	};

	onDrawAction = async () => {
		setTimeout(async () => {
			const geo = await this.mapStore.getGeoFromDrawing();
			this.centroidGeo = await this.getCentroid(geo);
			this.countiesLayer.load(geo);
			this.forestryQuartersLayer.load(geo);
		}, 0);
	};

	cancelDrawing = () => {
		this.card.fire.geo = this.origGeo;
		this.centroidGeo = this.origCentroidGeo;
		this.addGeo();
		this.countiesLayer.load(this.card.fire.geo);
		this.forestryQuartersLayer.load(this.card.fire.geo);
		this.mapStore.stopDrawing();
	};

	/** Этот метод больше не нужен, т.к. все вычисления делаются при выборе термоточек, а ручное рисование убрано */
	/*
		getDataFromGeo = async (geo) => {
			if (!geo) {
				this.card.onChange('area')(0);
				this.card.onChange('edge')(0);
				return;
			}
			try {
				const { area, edge } = await store.model.Fire.getDataFromGeo(geo);
				this.card.onChange('area')(area);
				this.card.onChange('edge')(edge);
			}
			catch (e) {
				console.log('getDataFromGeo error', e);
			}
		};
	*/

	onWebglImageLoad = (image, colorRamp) => {
		// console.log('onWebglImageLoad (card)', image);
		this.weatherImage = image;
		this.colorRamp = colorRamp;
	};

	onMouseLeave = () => {
		if (this.weatherTip) this.weatherTip.hide();
	};

	onPointerMove = (e) => {
		if (e.dragging || this.mapStore.drawing) {
			if (this.weatherTip) this.weatherTip.hide();
			return;
		}

		if (store.local.fires.layers.weather.show) {
			if (this.weatherImage && this.weatherLayerConfig) {
				const coords = this.map.getCoordinateFromPixel([e.pixel[0], e.pixel[1] - 20]);
				this.weatherTip.update(coords, this.weatherImage, this.weatherLayerConfig, this.colorRamp);
			}
		} else {
			if (this.weatherTip) this.weatherTip.hide();
		}
	};

	changeDate = (date) => {
		this.date = startOfDay(date);
		this.popup = null;
		this.weatherImage = null;
		this.heatPointsLayer.update();
	};

	@action prevDate = async (e) => {
		e.stopPropagation();
		const date = subDays(this.date, 1);
		this.changeDate(date);
	};

	@action nextDate = async (e) => {
		e.stopPropagation();
		const date = addDays(this.date, 1);
		this.changeDate(date);
	};

	switchFilters = (maximize) => {
		store.local.fires.card.filtersMaximized = maximize !== undefined ? maximize : !store.local.fires.card.filtersMaximized;
		store.local.save();
	};

	onScaleMount = (el) => {
		this.scaleElement = el;
	};

	setWeatherLayer = async (weatherLayerConfig) => {
		this.weatherLayerConfig = weatherLayerConfig;
		let { type, code, colors, min, max, measure, convert } = weatherLayerConfig;

		if (type === 'silam') {
			const meatUrl = `/api/silammeta?date=${this.date.toISOString()}&layer=${code}`;
			console.log('meatUrl', meatUrl);
			try {
				const res = await fetch(meatUrl);
				const json = await res.json();
				console.log('json', json);
				this.silamMin = min = json.min;
				this.silamMax = max = json.max;
			} catch (e) {
				console.warn('/api/silammeta', e);
			}
		}
		if (colors && this.scaleElement) {
			this.scaleElement.innerHTML = '';
			this.scaleElement.appendChild(this.getColorRamp(colors));
			if (min !== undefined && max !== undefined) {
				const minDiv = document.createElement('div');
				minDiv.className = 'min-value';
				minDiv.innerHTML = Math.floor(convert ? convert(min) : min) + (measure ? ' ' + measure : '');
				this.scaleElement.appendChild(minDiv);

				const maxDiv = document.createElement('div');
				maxDiv.className = 'max-value';
				maxDiv.innerHTML = Math.floor(convert ? convert(max) : max) + (measure ? ' ' + measure : '');
				this.scaleElement.appendChild(maxDiv);
			}
		}
	};

	toggleWeatherLayer = (value) => {
		const weather = store.local.fires.card.layers.weather;
		weather.show = value;
		store.local.save();
		this.weatherLayer.setVisible();
		if (value) this.weatherLayer.update();
	};

	getColorRamp = (colors) => {
		const canvas = document.createElement('canvas');
		const ctx = canvas.getContext('2d');

		const width = 300;
		const gradient = ctx.createLinearGradient(0, 0, width, 20);

		canvas.width = width;
		canvas.height = 20;

		if (Array.isArray(colors)) {
			for (let i = 0; i < colors.length; i++) {
				gradient.addColorStop(i / colors.length, colors[i]);
				ctx.fillStyle = gradient;
				ctx.fillRect(0, 0, width, 20);
			}
		} else {
			for (const breakpoint of colors.scale) {
				const [value, pos, [r, g, b, a]] = breakpoint;
				gradient.addColorStop(pos, `rgba(${r},${g},${b},${a / 255})`);
			}
			ctx.fillStyle = gradient;
			ctx.fillRect(0, 0, width, 20);

			/*
						ctx.font = '13px Roboto, sans-serif';
						ctx.fillStyle = '#fff';
						ctx.textBaseline = 'middle';
						for (let i = 0; i < colors.scale.length; i++) {
							const breakpoint = colors.scale[i];
							const [ value, pos ] = breakpoint;
							ctx.textAlign = 'center';
							if (i === 0) ctx.textAlign = 'left';
							if (i === colors.scale.length - 1) ctx.textAlign = 'right';
							let offset = 0;
							if (i === 0) offset = 5;
							if (i === colors.scale.length - 1) offset = -5;
							console.log('value', value);
							ctx.fillText(value, Math.round(pos * width) + offset, 11);
						}
			*/
		}

		return canvas;
	};

	toggleSelectMode = (value) => {
		this.selectMode = this.selectMode === value ? null : value;
		if (value) store.local.fires.card.filtersMaximized = false;
		store.local.save();

		this.mapStore.interactions.forEach((interaction) => {
			interaction.setActive(!this.selectMode);
		});

		if (this.selectMode) {
			// this.mapStore.element.style = 'cursor: crosshair';
			this.mapStore.element.classList.add('is-picking');
			this.mapStore.tools.ruler.toggle(false);
			this.mapStore.closePopup();
		} else {
			// this.mapStore.element.style = 'cursor: grab';
			this.mapStore.element.classList.remove('is-picking');
		}
	};

	onHPLoadStart = () => {
		this.toggleSelectMode(null);
		this.mapStore.element.classList.remove('is-picking');
		// this.mapStore.element.style = 'cursor: grab';
	};

	startPickingHqGeo = () => {
		this.toggleSelectMode(null);
		this.mapStore.element.classList.remove('is-picking');
		this.mapStore.pickPoint(this.onHqGeoPick);
	};

	cancelPickingHqGeo = () => {
		this.mapStore.cancelPickPoint();
	};

	onHqGeoPick = async (e, lonLat) => {
		this.checkCoords(lonLat[0], lonLat[1]);
	};

	onClick = async (e) => {
		if (e.originalEvent.ctrlKey || this.mapStore.picking) return;

		const coords = this.map.getCoordinateFromPixel(e.pixel);
		const [lon, lat] = transform(coords, 'EPSG:3857', 'EPSG:4326');

		const features = await this.map.getFeaturesAtPixel(e.pixel);

		if (features.length === 0) {
			this.popup = null;
			return;
		}

		const info = { lonLat: [lon, lat], offset: 0, records: [] };

		for (let feature of features) {
			const data = feature.getProperties();
			if (data.onFeatureClick && data.id) {
				const record = await data.onFeatureClick(data.id);
				if (record) info.records.push(record);
			}
		}

		this.popup = info.records.length > 0 ? info : null;
	};

	onPopupClose = () => {
		console.log('onPopupClose');
		this.popup = null;
	};

	get mapView() {
		return this.map.getView();
	}

	onHpRadiusChange = async (radius) => {
		console.log('onHpRadiusChange', radius);
		if (this.card.fire && this.card.fire.hqGeo) {
			const [lon, lat] = this.card.fire.hqGeo.coordinates;
			this.checkCoords(lon, lat);
		}
	};

	checkCoords = async (lon, lat) => {
		if (lon && lat) {
			this.card.changed = true;
			this.manualMode = true;

			const hqGeo = { type: 'Point', coordinates: [lon, lat] };
			this.card.onChange('hqGeo')(hqGeo);
			const geo = circleToPolygon([lon, lat], store.local.fires.card.layers.heatPoints.selectRadius || 375, { numberOfEdges: 64, title: 'sdfsdgsdg' });
			this.card.onChange('geo')(geo);
			await this.addGeo();

			if (this.card.location) this.card.location.updateLocationFields();
			const aviaDepGeo = this.card.fire.aviaDep ? this.card.fire.aviaDep.geo : null;
			const { city, airfield, cityAzimuth, cityDistance, airfieldAzimuth, airfieldDistance, aviaDepAzimuth, aviaDepDistance } =
				await store.model.Fire.getDataFromGeo(hqGeo, aviaDepGeo);
			this.card.onChange('city')(city);
			this.card.onChange('cityDistance')(cityDistance);
			this.card.onChange('cityAzimuth')(cityAzimuth);
			this.card.onChange('airfield')(airfield);
			this.card.onChange('airfieldAzimuth')(airfieldAzimuth);
			this.card.onChange('airfieldDistance')(airfieldDistance);
			await this.card.updateMainInfo();
			this.setDatesForLayers();
		}

		const [_lon, _lat] = fromLonLat([lon, lat]);
		this.mapView.setZoom(10);
		this.mapView.setCenter([_lon, _lat]);
	};
}
