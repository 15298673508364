import React from 'react';
import { inject, observer } from 'mobx-react';
import t from 'i18n';
import EditIcon from 'img/icons/fires/card/edit.svg';
import { Card } from 'components';
import { Row } from '@smartplatform/ui';

@inject('card')
@observer
export class Description extends React.Component {
	render() {
		const { card, disabled } = this.props;
		return (
			<Card title={t('fire.description')} icon={<EditIcon />}>
				<Row className='card-row'>
					<textarea value={card.fire.fireDescription || ''} onChange={card.onChange('fireDescription')} disabled={disabled} />
				</Row>
			</Card>
		);
	}
}
