import React from 'react';
import { inject, observer } from 'mobx-react';
import { RecordSelect, Row } from '@smartplatform/ui';
import MapIcon from 'img/icons/fires/card/map.svg';
import { Card, LabelField } from 'components';
import store from 'client/store';
import t from 'i18n';

@inject('card')
@observer
export class Characteristics extends React.Component {
	render() {
		const { card, disabled } = this.props;
		const { onChange, fire, getRequiredClassName } = card;
		const selectProps = { noTotal: true, maxItems: 20, itemsPerPage: 1000, filter: { fields: ['id', 'name'] }, property: 'name', disabled };
		const shortNameProps = { property: 'shortName', filter: { fields: ['id', 'shortName'] } };
		return (
			<Card title={t('FireCharacteristics.location')} icon={<MapIcon />}>
				<Row className='card-row'>
					<LabelField label={t('breed.title')} isRequired>
						<RecordSelect
							{...selectProps}
							model={store.model.Breed}
							value={fire.passedBreed}
							onChange={onChange('passedBreed')}
							isRequired
							{...getRequiredClassName(fire.passedBreed)}
						/>
					</LabelField>
					<LabelField label={t('cover.title')} isRequired>
						<RecordSelect
							{...selectProps}
							model={store.model.Cover}
							value={fire.cover}
							onChange={onChange('cover')}
							isRequired
							{...getRequiredClassName(fire.cover)}
						/>
					</LabelField>
					<LabelField label={t('forestCategory.title')} isRequired>
						<RecordSelect
							{...selectProps}
							model={store.model.ForestCategory}
							value={fire.forestCategory}
							onChange={onChange('forestCategory')}
							isRequired
							{...getRequiredClassName(fire.forestCategory)}
						/>
					</LabelField>
					<LabelField label={t('fireCause.title')} isRequired>
						<RecordSelect
							{...selectProps}
							{...shortNameProps}
							model={store.model.FireCause}
							value={fire.cause}
							onChange={onChange('cause')}
							isRequired
							{...getRequiredClassName(fire.cause)}
						/>
					</LabelField>
				</Row>
				<Row className='card-row'>
					<LabelField label={t('landCategory.plural')} isRequired>
						<RecordSelect
							{...selectProps}
							model={store.model.LandCategory}
							value={fire.landCategory}
							onChange={onChange('landCategory')}
							isRequired
							{...getRequiredClassName(fire.landCategory)}
						/>
					</LabelField>
					<LabelField label={t('determiningMethod.title')} isRequired>
						<RecordSelect
							{...selectProps}
							model={store.model.DeterminingMethod}
							value={fire.determiningMethod}
							onChange={onChange('determiningMethod')}
							isRequired
							{...getRequiredClassName(fire.determiningMethod)}
						/>
					</LabelField>

					<LabelField label={t('tenant.title')}>
						<RecordSelect {...selectProps} model={store.model.Tenant} value={fire.tenant} onChange={onChange('tenant')} />
					</LabelField>
					<div />
				</Row>
			</Card>
		);
	}
}
