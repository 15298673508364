import React from 'react';
import UsersIcon from 'img/icons/techfires/card/users.svg';
import { EntityManager } from '../../../../components';
import { FieldBasedCard } from '../../../../components';
import { inject } from 'mobx-react';

@inject('fireStore')
export default class Casualties extends React.Component {
	deleteCasualty = async (id) => {
		const casualtyNames = await this.props.casualty.TechFire.findById(id);
		await casualtyNames.delete();
		this.props.fireStore.init();
	};

	render() {
		const { fire } = this.props.fireStore;
		const fields = [
			{ type: 'date', label: 'techFireCasualty.detectTime', property: 'detectTime', options: { format: 'dd.MM.yyyy HH:mm', showTimeSelect: true } },
			{ type: 'input', label: 'techFireCasualty.lastName', property: 'lastName' },
			{ type: 'input', label: 'techFireCasualty.firstName', property: 'firstName' },
			{ type: 'input', label: 'techFireCasualty.middleName', property: 'middleName' },
			{ type: 'number', label: 'techFireCasualty.birth', property: 'yearOfBirth', options: { positiveOnly: true, integerOnly: true } },
			{ type: 'checkbox', label: 'techFireCasualty.deceased', property: 'deceased' },
			{ type: 'checkbox', label: 'techFireCasualty.isChild', property: 'isChild' },
			{ type: 'checkbox', label: 'techFireCasualty.isInjured', property: 'isInjured' },
			{ type: 'input', label: 'techFireCasualty.furtherAction', property: 'furtherAction' },
		];

		const cardContent = (
			<EntityManager
				modelName='TechFireCasualty'
				buttonLabel='techFireCasualty.add'
				deleteCasualty={this.deleteCasualty}
				fire={fire}
				fields={fields}
				fireStore={this.props.fireStore}
			/>
		);

		return (
			<FieldBasedCard title='techFire.infoAffected' icon={UsersIcon} fields={[(fire) => fire?.casualties().length > 0]} statusKey='infoAffected' isCard={false}>
				{cardContent}
			</FieldBasedCard>
		);
	}
}
