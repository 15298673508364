export const FIREDEP_PERSONNEL_LOCAL_STORAGE_FIELDS = [
	'search',
	'fireDep',
	'headOrg',
	'position',
	'rank',
	'vehicle',
  'status',
  'section',
  'gasMask',
  'garrison',
];