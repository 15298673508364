import { formatDate } from 'client/tools';

export const getReport = (html, settings, shiftData) => {
	const style = `
			<style>
				#techfires-note-table table {
					border-collapse: collapse;
				}
				#techfires-note-table th, #techfires-note-table td {
					border: thin solid;
					padding: 5px;
				}
			    #techfires-note-table tr.division-total {
					background: #fff000;
			    }
				#techfires-note-table tr.head-org {
				    background: #0ad;
				    text-align: center;
				    font-weight: bold;
				}
 				#techfires-note-table .number {
					background: #0f0;
				}
				#techfires-note-table .empty-number {
					background: #d00;
					color: #fff;
				}
			</style>
		`;
	const header = `<table>
			 <thead>
			  <th colspan="67" style="text-align: center">
				  СТРОЕВАЯ ЗАПИСКА<br/>
				  ${settings.headNote || ''}<br/>
				  на 08.00 час ${formatDate(new Date(), 'dd MMMM yyyy')}
			  </th>
			 </thead>
			 <tbody>
			  <tr>
			   <td colspan="67" style="text-align: center; font-weight: bold">
			   	   РАЗДЕЛ I. НАЛИЧИЕ ЛИЧНОГО СОСТАВА, ПОЖАРНОЙ И АВАРИЙНО-СПАСАТЕЛЬНОЙ ТЕХНИКИ И ОГНЕТУШАЩИХ ВЕЩЕСТВ
			   </td>
			  </tr>
			 </tbody>
			</table>`;

	// Функция для генерации строки по данным из shiftData
	function generateVehiclesString(shiftData, colspan, title, vehicleTypes) {
		let resultString = `<td colspan='${colspan}'>${title} - `;
		const groupedData = {};

		Object.values(shiftData).forEach((item) => {
			vehicleTypes.forEach((vehicleType) => {
				if (item[vehicleType]) {
					if (!groupedData[item.name]) {
						groupedData[item.name] = [];
					}

					const vehicles = typeof item[vehicleType] === 'string' ? item[vehicleType].split(', ') : item[vehicleType];
					vehicles.forEach((vehicle) => {
						groupedData[item.name].push(vehicle);
					});
				}
			});
		});

		const parts = [];
		for (const name in groupedData) {
			const allTypes = groupedData[name];
			parts.push(`${name} (${allTypes.join(', ')})`);
		}

		resultString += parts.length > 0 ? parts.join('; ') + '.' : '';
		resultString += '</td>';
		return resultString;
	}

	let basicVehiclesString = generateVehiclesString(shiftData, 9, '11 Прочие', ['basicvehicles']);
	let specialVehiclesString = generateVehiclesString(shiftData, 15, '18 Прочие', ['specialvehicles']);
	let reserveVehiclesString = generateVehiclesString(shiftData, 20, '27 Прочие', ['reserv27vehicles']);
	let supportVehiclesString = generateVehiclesString(shiftData, 37, '35 Прочие', ['supportvehicles']);

	// Функция для формирования строки по данным из shiftData
	function generateShiftDataString(shiftData, key, colspan, title, suffix = '') {
		let resultString = `<td colspan='${colspan}'>${title} - `;

		const parts = Object.values(shiftData)
			.filter((item) => item[key] !== null)
			.map((item) => `${item.name}:(${item[key]}${suffix})`);

		resultString += parts.length > 0 ? parts.join('; ') + '.' : '';
		resultString += '</td>';
		return resultString;
	}

	let protectiveSuitsString = generateShiftDataString(shiftData, 'col40', 37, '40 Прочие', ' ОЗК');
	let shortageFireDepsString = generateShiftDataString(shiftData, 'col60', 37, '60 Некомплект', ' Некомплект');

	const footer = `<table>
            <tr>
              ${basicVehiclesString}
              ${supportVehiclesString}
            </tr>
            <tr>
              ${specialVehiclesString}
              ${protectiveSuitsString}
            </tr>
            <tr>
              ${reserveVehiclesString}
              ${shortageFireDepsString}
            </tr>
        <tr>
            <td colspan='67' style='text-align: center; font-weight: bold'>
               РАЗДЕЛ 2. СВЕДЕНИЯ ОБ ОПЕРАТИВНЫХ ДЕЖУРНЫХ ПОЖАРНО-СПАСАТЕЛЬНОГО ГАРНИЗОНА
            </td>
        </tr>   
        <tr></tr>  
        <tr>
            <td colspan='30'>
            </td>        
            <td colspan='10'>
 				${settings.responsiblePerson?.[0]?.position || ''}       
 			</td>            
 			<td colspan='10'></td>            
 			<td colspan='17'>
 				${settings.responsiblePerson?.[0]?.fio || ''}         
 			</td>
        </tr>        
        <tr>
            <td colspan='30'>
            </td>        
            <td colspan='37'>
 				"___"______________________________${formatDate(new Date(), 'yyyy')}г.       
 			</td>
        </tr>        
        <tr></tr>
        <tr>
            <td colspan='3'>
            </td>        
            <td colspan='10' style='text-align: center;'>
 				Дежурная смена  
 			</td>
        </tr>         
        ${settings.dutyShift
					.map(
						(record) =>
							`<tr>
								<td></td>
								<td colSpan='2'>${record.position}</td>
								<td colSpan='10'></td>
								<td colSpan='10'>${record.fio}</td>
							</tr>`
					)
					.join('<tr></tr>')}            
    </table>`;

	return {
		filename: `Строевая записка ${formatDate(new Date(), 'dd.MM.yyyy')}.xls`,
		content: `${style}${header}${html}${footer}`,
	};
};
