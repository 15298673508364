import { observable } from 'mobx';
import store from 'client/store';
import { exportReport } from 'client/tools';

export default class FireReportStore {
	@observable isLoading = false;
	@observable forestry = null;
	@observable ownership = null;
	@observable year = new Date().getFullYear();
	@observable fire = null;
	@observable content;
	@observable filename;

	getReport = async () => {
		this.isLoading = true;
		const { content, filename } = await store.model.FireReport.getReport({
			reportCode: 'FireAct',
			reportParams: { id: this.fire.id, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone },
		});

		exportReport({ filename, content });

		this.isLoading = false;
	};

	onYearChange = (year) => {
		this.fire = null;
		this.year = year;
	};
	onForestryChange = (forestry) => {
		this.fire = null;
		this.forestry = forestry;
	};
	onOwnershipChange = (ownership) => {
		this.fire = null;
		this.ownership = ownership;
	};
	onFireChange = (fire) => (this.fire = fire);

	get storeParams() {
		return {
			reportCode: 'FireAct',
			reportParams: { id: this.fire?.id },
		};
	}

}
