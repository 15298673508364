import React from 'react';
import { inject, observer } from 'mobx-react';

import { Map, Layer, Popup, MapControlGroup, MapControlButton } from '@smartplatform/map/client';
import Timeline from '../../map/timeline';
import Scale from '../../map/Scale';
import MainPopup from '../../map/MainPopup';
import Filters from './filters';
import { SelectControls, Selection, RadiusSelector } from './heat-points-select';
import fireStore from '../../fireStore';
import store from 'client/store';
import classNames from 'classnames';

const MAP_PARAMS = {
	minZoom: 4,
	maxZoom: 18,
	zoom: 10,
};

@inject('card')
@observer
export default class FireMap extends React.Component {
	render() {
		const params = {
			...store.config.map,
			...MAP_PARAMS,
			defaultTileSource: store.local.fires.tileSource,
			onTileSourceChange: fireStore.onTileSourceChange,
			fitPadding: 50,
		};

		const { card } = this.props;
		const map = card.map;

		const rightControls = <SelectControls />;
		const _className = classNames('fire-card-map-container', { 'map-maximized': card.mapMaximized }, this.props.className);

		return (
			<div className={_className}>
				<Map onInit={card.onMapInit} params={params} className='fire-card-map' rightControls={rightControls}>
					{map.popup && (
						<Popup lonLat={map.popup.lonLat} width={550}>
							<MainPopup {...map.popup} />
						</Popup>
					)}
					{map.isInitialized && <Filters />}
					<Timeline fireStore={map} />
					<Scale store={map} hidden={!store.local.fires.card.layers.weather.show} className='in-card' />
					{map.selectMode && <Selection />}
					{(map.selectMode || map.manualMode) && <RadiusSelector onChange={map.onHpRadiusChange} />}
				</Map>
			</div>
		);
	}
}
