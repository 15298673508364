import React from 'react';
import { inject, observer } from 'mobx-react';
import { DatePicker, RecordSelect } from "@smartplatform/ui";

import store from 'client/store';
import t from 'i18n';

@inject('report')
@observer
export class Filters extends React.Component {
	render() {
		const { report } = this.props;

		return (
			<div className='filters'>
				<div className='row'>
					<div className='col-3'>
						<div className='form-field'>
							<label>{t('date')}</label>
							<DatePicker
								showTimeSelect={false}
								showClearButton={false}
								value={report.date}
								onChange={report.onDateChange}
							/>
						</div>
					</div>

					<div className='col-3'>
						<div className='form-field'>
							<label>{t('forestOwnership.title')}</label>
							<RecordSelect
								noTotal
								maxItems={10}
								itemsPerPage={1000}
								model={store.model.County}
								value={report.county}
								onChange={report.onCountyChange}
								property='name'
								className='drop-down-md'
								filter={{ fields: ['id', 'name'] }}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
