import React from 'react';
import { observer } from 'mobx-react';

import { FilterLayout } from 'components';
import store from 'client/store';

@observer
export default class ModelsFilter extends React.Component {

	onToggle = (value, props) => {
		const { subKey } = props;
		this.props.layer.settings[subKey].show = value;
		store.local.save();
		console.log('custom onToggle', value, subKey, {...this.props.layer.settings[subKey]});
		this.props.layer.load();
	};

	render() {
		const { modelsConfig = {} } = this.props;
		return Object.values(modelsConfig).filter(modelConfig => !!modelConfig.showInFilters).map(modelConfig => {
			const { modelName, filterTitle } = modelConfig;
			return <FilterLayout
				key={modelName}
				layer={this.props.layer}
				subKey={modelName}
				title={filterTitle}
				onToggle={this.onToggle}
			/>;
		});
	}

}
