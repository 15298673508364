import React from 'react';
import t from 'i18n';
import { observable } from 'mobx';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Fill, Stroke, Style, Icon, Circle as CircleStyle, Text } from 'ol/style';
import { asArray } from 'ol/color';
import { ColorUtils } from '@smartplatform/ui';
import { geoJSON, drawGeoMarker } from '@smartplatform/map/client';
import icons from 'img/icons/techfires/map/visits';
import store from 'client/store';
import { getDeparturesIconName } from './getDeparturesIconName';
import { getTypeColor } from '../../../modules/techfires/helpers/getColor';
import DeparturesPopup from './departuresPopup';
import techFiresStore from 'techfires/techFiresStore';

export default class DeparturesLayer {
	@observable techFire = [];
	@observable garrisonIds = [];
	@observable fireDepIds = [];
	@observable isLoading = true;

	constructor(mapStore, settings = {}) {
		this.mapStore = mapStore;
		this.settings = settings;
	}

	init = async () => {
		this.layer = new VectorLayer({
			format: geoJSON,
			source: new VectorSource(),
		});
		this.layer.setZIndex(15);
		this.setVisible();
		this.mapStore.addLayer(this.layer);
		this.load();
	};

	load = async () => {
		const { settings } = this;
		const { filters } = settings;
		const { garrisons, fireDeps, visitTypes, dateOfDepartureStart, dateOfDepartureEnd } = filters;
		const userGarrisonsLength = techFiresStore.userGarrisons.length;

		let techFireVisits = [];

		const extractIds = (array) => {
			return array && array.map((item) => item.id);
		};

		if (garrisons.length > 0 || fireDeps.length > 0) {
			if (userGarrisonsLength > 1) {
				this.garrisonIds = extractIds(garrisons);
			}
			this.fireDepIds = extractIds(fireDeps);
		}

		const visitTypesIds = extractIds(visitTypes);

		if (settings.show) {
			const choiceWhereByUserGarrissonsLength =
				userGarrisonsLength > 1
					? { or: this.garrisonIds.map((garrisonId) => ({ garrisonId: { inq: [garrisonId] } })) }
					: { garrisonId: garrisons[0] && garrisons[0].id };

			const where = {
				and: [
					{ dateOfDeparture: { between: [dateOfDepartureStart, dateOfDepartureEnd] } },
					{ geo: { neq: null } },
					{ fireDepId: { neq: null } },
					{ garrisonId: { neq: null } },
					{
						or: visitTypesIds.map((typeId) => ({ visitTypeId: { inq: [typeId] } })),
					},
					choiceWhereByUserGarrissonsLength,
					{ or: this.fireDepIds.map((fireDepId) => ({ fireDepId: { inq: [fireDepId] } })) },
				],
			};

			techFireVisits = await store.model.ViewTechFireVisitLayer.find({
				where,
				fields: ['id', 'geo', 'visitCode', 'techFireId', 'dateOfDeparture', 'visitTypeId', 'garrisonId', 'fireDepId'],
				include: [{ relation: 'visitType', scope: { fields: ['id', 'name', 'code'] } }],
			});
		}

		const features = [];
		for (let techFireVisit of techFireVisits) {
			const { geo, id } = techFireVisit;
			const color = getTypeColor(techFireVisit);
			const visitCode = techFireVisit.visitCode;
			const iconSrc = icons[getDeparturesIconName(visitCode)]?.src || icons.default;
			const { r, g, b } = ColorUtils.hexToRgb(color);
			let styleParams = {
				fill: new Fill({ color: asArray([r, g, b, 0.4]) }),
				stroke: new Stroke({ color, width: 1 }),
			};
			let pointStyle;
			if (geo.type === 'Point') pointStyle = new Style({ image: new CircleStyle({ radius: 10, ...styleParams }) });

			const marker = drawGeoMarker(geo, {
				data: {
					title: t('techFire.firesPlanVisits.title'),
					record: techFireVisit,
					render: this.renderPopup,
				},
				style: new Style({
					...styleParams,
					image: new Icon({
						src: iconSrc,
					}),
					text: new Text({
						textAlign: 'left',
						font: '7px/14px sans-serif',
						text: this.settings.customText ? this.settings.customText(techFireVisit) : `${id || ''}`,
						stroke: new Stroke({
							color: '#FFFF',
							width: 2,
						}),
						offsetX: 6,
						offsetY: -12,
						scale: 1.5,
					}),
				}),
				onlyMarker: store.isPublic,
			});

			features.push(...marker);
		}

		this.layer.getSource().clear();
		this.layer.getSource().addFeatures(features);
		this.isLoading = false;
	};

	checkAndClearFireDeps = () => {
		const { filters } = this.settings;
		const fireDeps = filters.fireDeps;
		const garrisons = filters.garrisons;

		if (fireDeps.length > 0) {
			const userFireDepId = store.model.user.fireDepId;
			const allSameGarrison = fireDeps.every((fireDep) => garrisons.some((garrison) => garrison.id === fireDep.garrisonId));

			const allMatchUserFireDep = fireDeps.every((fireDep) => fireDep.id === userFireDepId);

			if (!allSameGarrison || !allMatchUserFireDep) {
				filters.fireDeps = [];
				store.local.save();
			}
		}
		this.load();
	};

	onToggle = async (value, type) => {
		const { filters } = this.settings;
		filters[type] = value;

		if (!store.isAdmin && type === 'garrisons' && techFiresStore.userGarrisons.length === 1) {
			filters.garrisons = [value];
		}

		if (filters.garrisons.length === 0) {
			this.clearFilters();
		}

		store.local.save();
		this.load();
	};

	clearFilters = async () => {
		const { filters } = this.settings;
		this.garrisonIds = [];
		this.fireDepIds = [];
		filters.fireDeps = [];
		filters.garrisons = [];
	};

	setVisible = () => {
		this.layer.setVisible(this.settings.show);
		this.load();
	};

	renderPopup = (record) => <DeparturesPopup record={record} />;
}
