import React from 'react';
import { inject, observer } from 'mobx-react';
import { RecordSelect, Select } from '@smartplatform/ui';
import { getYearArray } from 'client/tools';

import store from 'client/store';
import t from 'i18n';

@inject('report')
@observer
export class Filters extends React.Component {
	render() {
		const { report } = this.props;

		return (
			<div className='filters'>
				<div className='row'>
					<div className='col-3'>
						<div className='form-field'>
							<label>{t('fireReport.year')}</label>
							<Select noTotal isRequired items={getYearArray()} value={report.year} onChange={report.onYearChange} />
						</div>
					</div>
					<div className='col-3'>
						<div className='form-field'>
							<label>{t('forestry.title')}</label>
							<RecordSelect
								noTotal
								maxItems={10}
								itemsPerPage={1000}
								model={store.model.Forestry}
								value={report.forestry}
								onChange={report.onForestryChange}
								property='name'
								className='drop-down-md'
								filter={{ fields: ['id', 'name'] }}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
