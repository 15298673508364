import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Tab, Tabs } from '@smartplatform/ui';
import { HasManyList } from 'components';
import t from 'i18n';

@observer
export class Warehouse extends Component {
	render() {
		const { fireDep, id, match: { params } = match } = this.props;

		const { warehouseProps, sizodProps } = getPropsForTabs(fireDep);

		return (
			<Tabs>
				<Tab
					exact
					path={`/techfires/firedep/${params.fireDepId}/warehouse`}
					title={t('fireDepFoam.title')}
					render={() => <HasManyList {...warehouseProps} />}
				/>

				<Tab path={`/techfires/firedep/${params.fireDepId}/warehouse/sizod`} title={t('fireDepGasMask.title')} render={() => <HasManyList {...sizodProps} />} />
			</Tabs>
		);
	}
}

const getPropsForTabs = (fireDep) => ({
	warehouseProps: {
		relation: 'foams',
		record: fireDep,
		exact: true,
		properties: ['capacity'],
		include: [{ relation: 'foam', property: 'name' }],
	},
	sizodProps: {
		relation: 'gasMasks',
		record: fireDep,
		exact: true,
		properties: ['quantity'],
		include: [{ relation: 'gasMask', property: 'name' }],
	},
});
