import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Loader, Form, Field, Row, Checkbox } from '@smartplatform/ui';
import { fireDepVehicleItem } from 'techfires/helpers';
import { fio } from 'client/tools';
import store from 'client/store';
import t from 'i18n';

@observer
export default class Edit extends React.Component {
	@observable record = null;
	@observable responsibleForCalling = null;
	@observable rtp = null;

	constructor(props) {
		super(props);
		this.init();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.match.params.id !== this.props.match.params.id) {
			this.init();
		}
	}

	init = async () => {
		const id = parseInt(this.props.match.params.id);
		if (id) {
			this.record = await store.model.FireDepPersonnel.findById(id, {
				include: [
					{ relation: 'section', scope: { fields: ['id', 'name'] } },
					{ relation: 'position', scope: { fields: ['id', 'name'] } },
					{ relation: 'rank', scope: { fields: ['id', 'name'] } },
					{
						relation: 'vehicle',
						scope: {
							include: [
								{
									relation: 'type',
									scope: {
										include: ['vehicleCode'],
									},
								},
								'callSign',
							],
						},
					},
					{ relation: 'gasMask', scope: { fields: ['id', 'name'] } },
					{ relation: 'status', scope: { fields: ['id', 'name'] } },
					{ relation: 'chief', scope: { fields: ['id', 'orderNumber', 'startDate', 'endDate'] } },
				],
			});
			this.responsibleForCalling = this.record.responsibleForCalling;
			this.rtp = this.record.rtp;
		} else {
			this.record = new store.model.FireDepPersonnel();
			this.responsibleForCalling = this.record.responsibleForCalling;
		}
	};

	redirectBack = () => store.route.push({ path: this.props.path });

	onChangeCheckbox = (prop) => (value) => {
		this[prop] = value;
	};

	checkboxField = (property, label) => {
		return (
			<Field property={property} label={label}>
				<Checkbox {...{ value: this[property], onChange: this.onChangeCheckbox(property) }} />
			</Field>
		);
	};

	render() {
		if (!this.record) return <Loader />;

		return (
			<Form record={this.record} stay onSave={this.redirectBack} onDelete={this.redirectBack}>
				<Row>
					<Field property='lastName' label={t('lastName')} />
					<Field property='firstName' label={t('firstName')} />
					<Field property='middleName' label={t('middleName')} />
					<div />
				</Row>
				<Row>
					<Field relation='section' property='name' label={t('fireDepSection.title')} />
					<Field relation='position' property='name' label={t('fireDepPosition.title')} />
					<Field relation='rank' property='name' label={t('fireDepRank.title')} />
					<Field relation='status' property='name' label={t('personnelStatus.title')} />
				</Row>
				<Row>
					<Field property='dayShift' label={t('fireDepPersonnel.dayShift')} />
					{this.checkboxField('responsibleForCalling', t('fireDepPersonnel.responsibleForCalling'))}
					{(this.responsibleForCalling && <Field relation='techFireRank' property='name' label={t('techFire.rank')} />) || <div />}
					<div />
				</Row>
				<Row>
					<Field
						relation='vehicle'
						computed={fireDepVehicleItem}
						filter={{ where: { fireDepId: { neq: null }, fireDepShiftId: null } }}
						label={t('fireDepVehicle.title')}
					/>
					<Field relation='gasMask' property='name' label={t('gasMask.title')} />
					<div />
					<div />
				</Row>
				<Row>
					<Field property='phone' label={t('phone')} />
					<Field property='address' label={t('address.title')} />
					<div />
					<div />
				</Row>
				<Row>
					<Field relation='headOrg' property='name' label={t('fireDepPersonnel.headOrg')} />
					<Field relation='fireDep' property='name' label={t('fireDep.title')} />
					<Field relation='user' computed={fio} label={t('user.title')} />
					<div />
				</Row>
				{this.checkboxField('rtp', t('fireDep.chief'))}
				{this.rtp && (
					<Row>
						<Field property='orderNumber' label={t('techFireChief.orderNumber')} />
						<Field property='startDate' label={t('techFireChief.startDate')} />
						<Field property='endDate' label={t('techFireChief.endDate')} />
						<div />
					</Row>
				)}
			</Form>
		);
	}
}
