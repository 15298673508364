import React from 'react';
import { observer } from 'mobx-react';
import { PermafrostObjectFilter } from './layers/permafrostObject';
import { PermafrostTemperatureTubeFilter } from './layers/permafrostTemperatureTube';
import { PermafrostLevelingMarkFilter } from './layers/permafrostLevelingMark';

import store from 'client/store';

@observer
export default class Filters extends React.Component {
	layers = {};

	constructor(props) {
		super(props);
		this.layers = props.layers;
	}

	render() {
		const localSettings = store.local.permafrost;
		const settings = localSettings.layers;

		return (
			<div className='permafrost-filters-wrapper'>
				{localSettings.filtersMaximized && (
					<div className='permafrost-filters'>
						<PermafrostObjectFilter layer={this.layers.permafrostObjectLayer} settings={settings.permafrostObject} />
						<PermafrostTemperatureTubeFilter layer={this.layers.permafrostTemperatureTubeLayer} settings={settings.permafrostTemperatureTube} />
						<PermafrostLevelingMarkFilter layer={this.layers.permafrostLevelingMarkLayerLayer} settings={settings.permafrostLevelingMarkLayer} />
					</div>
				)}
			</div>
		);
	}
}
