import React from 'react';
import { inject, observer } from 'mobx-react';
import { Card, LabelField, AutoAnimate } from 'components';
import { renderStatus, distance, area } from 'client/tools';
import FireIcon from 'img/icons/shared/fire.svg';
import t from 'i18n';
import './forestFireCard.scss';
import { Row } from '@smartplatform/ui';
import { format } from 'date-fns';
import { Map } from '@smartplatform/map/client';

@inject('store')
@observer
export class ForestFireCard extends React.Component {
	renderAirJobs = (records) =>
		records.length > 0
			? records
					.map((airJob) => (airJob.duration ? `(${airJob.raid?.aircraft?.type?.name || ''} - ${airJob.duration})` : null))
					.filter(Boolean)
					.join(', ')
			: null;

	renderPeople = (records) =>
		records.length > 0
			? records
					.map((p) => (p.current ? `(${p.crewTypeShortName} - ${p.current})` : null))
					.filter(Boolean)
					.join(', ')
			: null;
	renderVehicles = (records) =>
		records.length > 0
			? records
					.map((v) => (v.current ? `(${v.typeShortName} - ${v.current})` : null))
					.filter(Boolean)
					.join(', ')
			: null;

	renderField = (value) => (value ? value : t('field.empty'));

	render() {
		const { fire, onMapInit } = this.props.store;
		if (!fire) return <AutoAnimate className='fire-card-wrapper' />;
		const { renderField } = this;
		const formatDate = (value) => (value ? format(new Date(value), 'dd.MM.yyyy HH:mm') : t('field.empty'));
		const status = renderStatus({ color: fire.statusColor, name: fire.statusName });
		const fireLogs = fire.logs();
		const firstLog = fireLogs[0];
		const geo = fire.hqGeo;
		const localizationLog = fireLogs.reverse().find((i) => i.status?.code === 'localized');

		return (
			<AutoAnimate className='fire-card-wrapper'>
				{fire && (
					<Card icon={<FireIcon />} title={t('forestFireCard')}>
						<Map onInit={onMapInit} />
						<Row>
							<LabelField label={t('fire.status')} text={renderField(status)} />
							<LabelField label={t('latitude')} text={renderField(geo?.coordinates[1]?.toFixed(2))} />
							<LabelField label={t('longitude')} text={renderField(geo?.coordinates[0]?.toFixed(2))} />
						</Row>

						<Row>
							<LabelField label={t('closestCityName')} text={renderField(fire.cityName)} />
							<LabelField label={t('closestCityDistance')} text={renderField(distance(fire.cityDistance, true))} />
							<LabelField label={t('forestry.title')} text={renderField(fire.forestryName)} />
						</Row>
						<Row>
							<LabelField label={t('combustionType.title')} text={renderField(fire.combustionTypeName)} />
							<LabelField label={t('fire.fireManager')} text={renderField(fire.fireManager)} />
							<div />
						</Row>
						<Row>
							<LabelField label={t('fire.fireNumber')} text={renderField(fire.fireNumber)} />
							<LabelField label={t('fire.forestryFireNumber')} text={renderField(fire.forestryFireNumber)} />
							<LabelField label={t('fire.fireId')} text={renderField(fire.id)} />
						</Row>
						<Row>
							<LabelField label={t('fire.detectDate')} text={formatDate(fire.detectDate)} />
							<LabelField label={t('fire.localizationDate')} text={formatDate(fire.localizationDate)} />
							<LabelField label={t('fire.startDate')} text={formatDate(fire.startDate)} />
						</Row>
						<Row>
							<LabelField label={t('fire.detectArea')} text={area(firstLog?.area, true)} />
							<LabelField label={t('fire.localizedArea')} text={area(localizationLog?.area, true)} />
							<LabelField label={t('fire.extinguishedDate')} text={formatDate(fire.extinguishedDate)} />
						</Row>
						<Row>
							<LabelField label={t('fireFightingPeople.plural')} text={renderField(this.renderPeople(fire.peopleSum))} />
							<LabelField label={t('fireFightingVehicle.plural')} text={renderField(this.renderVehicles(fire.vehiclesSum))} />
							<LabelField label={t('airRaid.pluralLong')} text={renderField(this.renderAirJobs(fire.jobsSum))} />
						</Row>
					</Card>
				)}
			</AutoAnimate>
		);
	}
}
