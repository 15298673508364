import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Map, Layer } from '@smartplatform/map/client';
import store from 'client/store';
import './style.scss';

@observer
export default class CovidMap extends React.Component {
	constructor(props) {
		super(props);
	}

	onInit = (mapStore) => {
		store.covid.onMapInit(mapStore);
		// store.covid.map = mapStore;
		// console.log('onInit', store.covid.map);
	};

	render() {
		console.log('CovidMap render');
		console.log(store.covid.county, '123');
		return (
			<Map className='full-map covid-map' onInit={this.onInit} params={{ ...store.config.map }}>
				{store.covid.county && <Layer name='county' type='geo' geo={store.covid.county.geo} />}
			</Map>
		);
	}
}
