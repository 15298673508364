export const MODEL_NAME = 'Request';
export const REQUEST_PATH = '/delivery/requests';
export const PLAN_PATH = '/delivery/plans';
export const IMPORT_REQUEST_PATH = `${PLAN_PATH}/import-request`;
export const PERIODS = ['day', 'week', 'month', 'quarter', 'year'];
export const LOCAL_STORAGE_FIELDS = ['mode', 'statuses', 'type', 'period', 'startDate', 'endDate', 'showFilters', 'page', 'search'];

// include list
export const REQUESTS_INCLUDE = {
	include: [
		{ relation: 'organization', scope: { fields: ['id', 'name'] } },
		{ relation: 'type', scope: { fields: ['id', 'name'] } },
		{ relation: 'level', scope: { fields: ['id', 'name'] } },
		{ relation: 'status', scope: { fields: ['id', 'name', 'color'] } },
		{ relation: 'owner', scope: { fields: ['id', 'lastName', 'firstName', 'middleName', 'username', 'avatar'] } },
		{ relation: 'direction' },
		{ relation: 'county', scope: { fields: ['id', 'name'] } },
		{
			relation: 'members',
			scope: {
				fields: ['id', 'userId'],
				include: [
					{ relation: 'user', scope: { fields: ['id', 'lastName', 'firstName', 'middleName', 'username', 'avatar'] } },
					{ relation: 'organization', scope: { fields: ['id', 'name'] } },
					{ relation: 'status', scope: { fields: ['id', 'color', 'name'] } },
				],
			},
		},
		{ relation: 'contractor', scope: { fields: ['id', 'name'] } },
	],
	fields: [
		'id',
		'date',
		'typeId',
		'ownerId',
		'organizationId',
		'statusId',
		'levelId',
		'name',
		'categoryNames',
		'districtNames',
		'directionId',
		'planCount',
		'nomenclatureNames',
		'countyId',
		'contractorId',
	],
};
