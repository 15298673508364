import React from 'react';
import { Toolbar, ListStore, Form, RowAddButton, DateRangePicker } from 'components';
import { getPerPage, getLabelName } from 'client/tools';
import { Column, Field, Pager, Popup, Table } from '@smartplatform/ui';
import store from 'client/store';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import t from 'i18n';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './style.scss';
import { startOfYear } from 'date-fns';

@observer
export class HasManyList extends React.Component {
	@observable selectedRecord = null;
	@observable dateRangeValues = {
		period: 'year',
		periodDate: new Date(),
		startDate: startOfYear(new Date()),
		endDate: new Date(),
	};

	static propTypes = {
		record: PropTypes.object.isRequired,
		relation: PropTypes.string.isRequired,
		properties: PropTypes.array, // список пропети
		listProperties: PropTypes.array, // список пропети для листа
		include: PropTypes.array, // инклюд belongsTo реляций вида [{relation:relation_name , fields: ['field_name']}]
		showAddButton: PropTypes.bool,
		showSearchInput: PropTypes.bool,
		onRowClick: PropTypes.func,
		filters: PropTypes.object,
		title: PropTypes.string,
		rowAddButton: PropTypes.bool,
		beforeSave: PropTypes.func,
		disabled: PropTypes.bool,
		periodFilters: PropTypes.bool,
	};
	static defaultProps = {
		showAddButton: true,
		rowAddButton: false,
		showSearchInput: true,
		properties: ['name'],
		include: [],
		filters: {},
	};

	constructor(props) {
		super(props);
		const { record, relation, properties, include, filters } = props;
		const relationInfo = record.MODEL.RELATIONS[relation];
		const foreignKeyValue = record.id;
		this.modelName = relationInfo.model;
		this.model = store.model[this.modelName];
		const RELATIONS = this.model.RELATIONS;
		const foreignKeys = (include || []).map(({ relation }) => RELATIONS[relation].foreignKey);
		// ключ на основную запись
		const { where = {}, ...restFilters } = filters;
		this.where = { [relationInfo.foreignKey]: foreignKeyValue, ...where };

		this.store = new ListStore({
			path: props.path,
			filters: {
				where: this.where,
				include: Array.isArray(include)
					? include.map(({ relation, property, fields = [] }) => ({ relation, scope: { fields: ['id', property, ...fields] } }))
					: undefined,
				fields: ['id', ...properties, ...foreignKeys],
				...restFilters,
			},
		});
	}
	componentDidMount() {
		if (!this.error) this.store.setPerPage(getPerPage());
	}

	reloadFilters = () => {
		this.store.query.where.and = [];
		if (this.dateRangeValues.startDate) this.store.query.where.and.push({ date: { gte: this.dateRangeValues.startDate } });
		if (this.dateRangeValues.endDate) this.store.query.where.and.push({ date: { lte: this.dateRangeValues.endDate } });
		this.store.reload();
	};

	add = () => (this.selectedRecord = new this.model(this.where));
	onClose = () => {
		this.store.reload();
		this.selectedRecord = null;
	};

	onRowClick = async (record) => {
		this.props.onRowClick ? this.props.onRowClick(record) : (this.selectedRecord = record);
	};

	onDelete = () => {
		this.store.reload();
		this.onClose();
	};

	checkDisableSave = () => {
		const { PROPERTIES } = this.selectedRecord.MODEL;
		const requiredFields = Object.keys(PROPERTIES).reduce((acc, key) => {
			if (PROPERTIES[key].required) acc.push(key);
			return acc;
		}, []);

		return !!requiredFields.find((field) => !this.selectedRecord[field]);
	};

	render() {
		const { properties, listProperties, include, showAddButton, showSearchInput, title, rowAddButton, beforeSave, disabled, periodFilters } = this.props;
		const { onQueryUpdate, onChange, totalCount, page, query, perPage, search, onSearch, getInstance, isPerPageSetted } = this.store;
		const { onClose, onDelete, onRowClick, dateRangeValues, reloadFilters } = this;
		const tableProps = isPerPageSetted ? { model: this.model } : { rows: [] };
		const className = classNames(this.modelName, 'hasmany-relation-list');

		return (
			<div className={className}>
				<Toolbar>
					{showAddButton && !rowAddButton && !disabled && <Toolbar.AddButton onClick={this.add} />}
					{showSearchInput && <Toolbar.SearchIconInput value={search} onChange={onSearch} />}
				</Toolbar>
				{periodFilters && (
					<div className='range-filters'>
						<DateRangePicker dateValues={dateRangeValues} onChange={reloadFilters} />
					</div>
				)}
				<div className='pager'>
					{title && <h3>{title}</h3>}
					<Pager current={page} onChange={onChange('page')} totalCount={totalCount || 0} itemsPerPage={perPage} />
				</div>
				<div id='table'>
					<Table
						query={query}
						onQueryUpdate={onQueryUpdate}
						{...tableProps}
						key={tableProps.model}
						onRowClick={!disabled ? onRowClick : undefined}
						getInstance={getInstance}
					>
						{include.map((props) => {
							return <Column {...props} key={props.relation + props.property} label={props.label || getLabelName(props.relation, this.modelName)}></Column>;
						})}
						{(listProperties ? listProperties : properties).map((property) => (
							<Column property={property} label={getLabelName(property, this.modelName)} key={property} />
						))}
					</Table>
					{showAddButton && rowAddButton && <RowAddButton onClick={this.add} />}
				</div>
				{this.selectedRecord && (
					<Popup onClose={onClose} className='delivery-popup'>
						<h2>{this.selectedRecord.id ? t('editRecord') : t('addRecord')}</h2>
						<Form
							record={this.selectedRecord}
							stay
							beforeSave={beforeSave}
							onSave={onClose}
							onDelete={onDelete}
							onCancel={onClose}
							disableSave={this.checkDisableSave()}
						>
							{include.map((props) => {
								return <Field {...props} key={props.relation + props.property} label={props.label || getLabelName(props.relation, this.modelName)} />;
							})}
							{properties.map((property, i) => {
								const autoFocus = i === 0 ? true : undefined;
								return <Field property={property} label={getLabelName(property, this.modelName)} key={property} autoFocus={autoFocus} />;
							})}
						</Form>
					</Popup>
				)}
			</div>
		);
	}
}
