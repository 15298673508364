import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import FilterLayout from '../FilterLayout';
import t from 'i18n';
import { Checkbox } from "@smartplatform/ui";
import store from "client/store";

@observer
export class TrackingFilter extends React.Component {
	static propTypes = {
		layer: PropTypes.object,
		onToggle: PropTypes.func,
	};

	onChange = async (statusCode, value) => {
		const { settings } = this.props;
		settings.status[statusCode] = value;
		this.reload();
	};

	reload = async () => {
		store.local.save();
		await this.props.layer.load();
	};

	render() {
		const { layer, settings } = this.props;

		return (
			<FilterLayout layer={layer} title={t('tracking.titleShort')}>
				{['SHIPPED', 'DELIVERED'].map((status, i) => (
					<div key={i}>
						<Checkbox
							value={settings.status[status]}
							onChange={(value) => this.onChange(status, value)}
							label={t('trackingStatus.' + status)}
							disabled={!settings.show}
						/>
					</div>
				))}
			</FilterLayout>
		);
	}
}
