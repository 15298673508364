import { observable } from 'mobx';
import { startOfDay, endOfDay } from 'date-fns';
import { exportReport } from 'client/tools';
import store from 'client/store';
import t from 'i18n';
import { getDateForShift } from 'techfires/helper';
import { RECORD_INCLUDE, VEHICLES_NO_CONSID_SHIFT_INCLUDE } from './constants';

export default class WayBillStore {
	@observable record = null;
	@observable addedFireDep = null;
	@observable isSaving = false;
	@observable selectedCallSignId = [];
	@observable rank = null;

	// карта ПЧ, ключ - id ПЧ, свойство - объект вида { fireDep, vehicles, selectedVehicles }
	// vehicles - редактируемый массив
	@observable fireDeps = {};
	@observable errors = [];
	@observable vehiclesNoConsidShift = {};
	@observable isLoading = true;

	constructor(props) {
		this.props = props;
		this.init();
	}

	init = async () => {
		this.id = this.props.match.params.wayBillId;
		this.fireDepId = this.props.fireId;

		if (this.id) {
			this.record = await store.model.TechFireWayBill.findById(this.id, RECORD_INCLUDE);

			const vehicles = this.record.vehicles();
			const fireDeps = {};

			this.record.fireDeps_thr().forEach((record) => {
				const { fireDep, acceptDate, acceptUser } = record;

				const acceptInfo = { user: acceptUser, date: acceptDate };

				const fireDepVehicles = vehicles.filter((vehicle) => vehicle.fireDepId === fireDep.id);
				fireDeps[fireDep.id] = {
					acceptInfo,
					fireDep,
					vehicles: fireDepVehicles,
					errors: [],
					selectedVehicles: fireDepVehicles.map((v) => v.id),
				};
			});

			this.fireDeps = fireDeps;
			this.vehiclesNoConsidShift = vehicles;
		} else {
			this.record = new store.model.TechFireWayBill();
			this.record.detectTime = new Date();
			this.record.reportTime = new Date();

			if (this.fireDepId) {
				const fire = await store.model.TechFire.findById(this.fireDepId, { include: ['fireDep'] });
				this.record.fire = fire;
			}
		}

		this.isLoading = false;
		if (this.props.onInit) this.props.onInit(this);
	};

	save = async () => {
		this.isSaving = true;

		this.record.rank = this.rank;
		await this.record.save();
		for (let fireDepObj of Object.values(this.fireDeps)) {
			const { fireDep, selectedVehicles } = fireDepObj;
			await this.record.fireDeps.add(fireDep.id);
			for (let vehicleId of selectedVehicles) {
				await this.record.vehicles.add(vehicleId);
			}
		}

		this.isSaving = false;
		if (this.props.onSave) this.props.onSave(this.record);
	};

	exportWayBill = async () => {
		const { content, filename } = await store.model.TechFireReport.getReport({ reportCode: 'Waybill', reportParams: { id: this.fireDepId } });
		exportReport({ filename, content });
	};

	selectFireDepToAdd = async (fireDep) => {
		this.addedFireDep = fireDep;
	};

	removeFireDep = (fireDep) => {
		const fireDeps = { ...this.fireDeps };
		delete fireDeps[fireDep.id];
		this.fireDeps = fireDeps;

		delete this.vehiclesNoConsidShift[fireDep.id];
	};

	clearFireDeps = () => {
		this.fireDeps = {};
	};

	addFireDep = async (fireDep) => {
		console.log('addFireDep', fireDep);
		if (!fireDep.id) {
			console.warn('fireDep is a promise?', fireDep);
			return;
		}

		if (this.fireDeps[fireDep.id]) {
			return;
		}

		this.errors = [];

		// назначена ли объекту пожарная часть
		if (!fireDep) {
			this.errors.push(t('techFireError.noFireDep'));
			return;
		}

		// задана ли дата/время обнаружения или сообщения
		if (!this.record.detectTime && !this.record.reportTime) {
			this.errors.push(t('techFireError.noDateAndTime'));
			return;
		}

		const date = this.record.detectTime || this.record.reportTime;
		const { start, end } = getDateForShift(date);

		const globalShifts = await store.model.TechFireShift.find({
			where: {
				and: [{ date: { gte: start } }, { date: { lte: end } }],
			},
			order: 'date desc',
		});
		const globalShift = (globalShifts && globalShifts[0]) || null;

		let fireDepShifts = [];
		if (!globalShift) {
			this.addVehiclesNoConsidShift(fireDep);
		} else {
			const fireDepWithShifts = await store.model.FireDepShift.find({
				where: {
					and: [{ fireDepId: fireDep.id }, { shiftId: globalShift.id }],
				},
				include: [
					{
						relation: 'vehicles',
						scope: {
							include: [
								{ relation: 'brand', scope: { fields: ['id', 'name'] } },
								{
									relation: 'type',
									scope: {
										fields: ['id', 'name', 'specialTypeId'],
										include: [{ relation: 'specialType', scope: { fields: ['id', 'name'] } }],
									},
								},
								{ relation: 'pump', scope: { fields: ['id', 'capacity'] } },
								{ relation: 'cisternBulk', scope: { fields: ['id', 'volume'] } },
								{
									relation: 'foam',
									scope: {
										fields: ['id', 'volume', 'date'],
										where: {
											and: [{ date: { gte: startOfDay(date) } }, { date: { lte: endOfDay(date) } }],
										},
										order: 'date desc',
										limit: 1,
									},
								},
								{ relation: 'callSign', scope: { fields: ['id', 'name'] } },
							],
						},
					},
				],
			});

			fireDepShifts = fireDepWithShifts;
		}

		let fireDepErrors = [];
		let vehicles = [];
		if (!fireDepShifts.length) {
			fireDepErrors.push(t('techFireError.noFireDepShift'));
		} else {
			vehicles = [];
			for (const fd of fireDepShifts) {
				vehicles.push(...fd.vehicles());
			}
			if (vehicles.length === 0) fireDepErrors.push(t('techFireError.shiftWithNoVehicles'));
		}

		let selectedVehicles = [];

		for (const vehicle of vehicles) {
			if (this.selectedCallSignId.includes(vehicle.callSignId)) {
				selectedVehicles.push(vehicle.id);
			}
		}

		this.fireDeps = {
			...this.fireDeps,
			[fireDep.id]: {
				fireDep,
				vehicles,
				errors: fireDepErrors,
				selectedVehicles: selectedVehicles,
			},
		};
		await this.addVehiclesNoConsidShift(fireDep, false);
		this.addedFireDep = null;
	};

	addVehiclesNoConsidShift = async (fireDep, noConsidShift) => {
		const fireDepId = fireDep.id;
		if (noConsidShift) {
			const vehiclesNoConsidShift = await store.model.ViewFireDepVehicle.find(
				{ where: { fireDepId: fireDep.id, statusCode: { inq: ['CC4', 'RESERV27'] } } },
				VEHICLES_NO_CONSID_SHIFT_INCLUDE
			);

			this.vehiclesNoConsidShift[fireDepId] = vehiclesNoConsidShift;
		} else {
			delete this.vehiclesNoConsidShift[fireDepId];
		}
	};

	addFireDeps = async (fireDep, rank) => {
		this.fireDeps = {};
		this.selectedCallSignId = [];
		try {
			const schedule = await APP_STORE.model.FireDepScheduleDeparture.findOne({
				where: {
					fireDepId: fireDep.id,
					rankId: rank.id,
				},
				include: [{ relation: 'callSign', scope: { include: ['fireDep'] } }],
			});
			const scheduleCallSign = schedule.callSign();
			this.selectedCallSignId = scheduleCallSign.map((v) => v.id);

			const callSignByDeps = [...new Map(scheduleCallSign.map((item) => [item['fireDepId'], item])).values()];
			for (const callSign of callSignByDeps) {
				await this.addFireDep(callSign.fireDep);
			}
		} catch (e) {}
		await this.addFireDep(fireDep);
	};

	onChange = (field) => async (value) => {
		this[field] = value;
		if (field === 'rank' && this.record.fire.fireDep) {
			await this.addFireDeps(this.record.fire.fireDep, value);
		}
	};
}
