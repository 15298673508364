import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { NumberValue } from '@smartplatform/ui';
import ClockPlusIcon from 'img/icons/fires/dashboard/clock-plus.svg';
import { Card } from 'components';
import t from 'i18n';

const LOADING = <>&nbsp;</>;

const OWNERSHIP = ['forest', 'oopt', 'other'];

@inject('dashboardStore')
@observer
export default class Season extends React.Component {
	render() {
		const { dashboardStore } = this.props;
		const { data } = dashboardStore || {};
		const { forestOwnership } = data || {};
		const { seasonCountCurrent, seasonAreaCurrent } = data.season || {};
		const { seasonCountLastYear, seasonAreaLastYear } = data.prevSeason || {};

		return (
			<Card icon={<ClockPlusIcon />} title={t('fireDashboard.seasonFires')} className='season'>
				<table className='dashboard-table'>
					<thead>
						<tr>
							<th />
							<th width='33%'>{t('fireDashboard.currentYear')}</th>
							<th width='33%'>{t('fireDashboard.lastYear')}</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td className='category'>{t('fireDashboard.seasonTotalCount')}</td>
							<td className='number'>{seasonCountCurrent !== undefined ? <NumberValue value={seasonCountCurrent} /> : LOADING}</td>
							<td className='number'>{seasonCountLastYear !== undefined ? <NumberValue value={seasonCountLastYear} /> : LOADING}</td>
						</tr>
						<tr>
							<td className='category'>{t('fireDashboard.seasonTotalArea')}</td>
							<td className='number'>{seasonAreaCurrent !== undefined ? <NumberValue value={seasonAreaCurrent} fraction={1} /> : LOADING}</td>
							<td className='number'>{seasonAreaLastYear !== undefined ? <NumberValue value={seasonAreaLastYear} fraction={1} /> : LOADING}</td>
						</tr>
						<tr className='m-1' />
						<tr>
							<td className='category'>В том числе:</td>
						</tr>
						{OWNERSHIP.map((owner) => (
							<Fragment key={owner}>
								<tr>
									<td className='category pl-3'>{t(`fireDashboard.forestOwnershipType.${owner}`)}</td>
									<td className='number'>
										{forestOwnership && forestOwnership[`${owner}Season`]?.countcurrent !== undefined ? (
											<NumberValue value={forestOwnership[`${owner}Season`]?.countcurrent} />
										) : (
											LOADING
										)}
									</td>
									<td className='number'>
										{forestOwnership && forestOwnership[`${owner}PrevSeason`]?.countlastyear !== undefined ? (
											<NumberValue value={forestOwnership[`${owner}PrevSeason`]?.countlastyear} />
										) : (
											LOADING
										)}
									</td>
								</tr>
								<tr>
									<td className='category pl-3'>{t('fireDashboard.seasonTotalArea')}</td>
									<td className='number'>
										{forestOwnership && forestOwnership[`${owner}Season`]?.areacurrent !== undefined ? (
											<NumberValue value={forestOwnership[`${owner}Season`]?.areacurrent} fraction={1} />
										) : (
											LOADING
										)}
									</td>
									<td className='number'>
										{forestOwnership && forestOwnership[`${owner}PrevSeason`]?.arealastyear !== undefined ? (
											<NumberValue value={forestOwnership[`${owner}PrevSeason`]?.arealastyear} fraction={1} />
										) : (
											LOADING
										)}
									</td>
								</tr>
								<tr className='m-1' />
							</Fragment>
						))}
					</tbody>
				</table>
			</Card>
		);
	}
}
