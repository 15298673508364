import React from 'react';
import { inject, observer } from 'mobx-react';
import t from 'i18n';
import { Field, Form, Row } from '@smartplatform/ui';
import store from 'client/store';

@inject('card')
@observer
export default class FireDepVehicle extends React.Component {
	constructor(props) {
		super(props);
		this.path = this.props.path;
		this.id = this.props.card.vehicle.id;
	}

	redirectBack = () => {
		store.route.push({ path: this.path });
	};

	render() {
		const { card } = this.props;
		return (
			<div className='personnel'>
				<Form record={card.vehicle} path={this.path} stay onDelete={this.redirectBack} onSave={this.redirectBack} disableSave={!card.vehicle.fireDepId}>
					<Row>
						<Field relation='callSign' property='name' label={t('vehicle.callSign')} />
						<Field relation='type' property='name' label={t('fireDepVehicle.type')} />
						<Field relation='status' property='name' label={t('fireDepVehicleStatus.title')} />
					</Row>
					<Row>
						<Field relation='brand' property='name' label={t('fireDepVehicleBrand.title')} />
						<Field property='stateNumber' label={t('vehicle.stateNumber')} />
						<Field property='year' label={t('vehicle.year')} />
					</Row>
					<Row>
						<Field relation='pump' property='capacity' label={t('pump.title')} />
						<Field property='passport' label={t('vehicle.passport')} />
						<Field relation='cisternBulk' property='volume' label={t('vehicle.capacity')} />
					</Row>
					<Row>
						<Field property='description' label={t('vehicle.description')} />
						<Field property='endDate' label={t('vehicle.endDate')} />
						<Field relation='fuelValue' property='value' label={t('fireDepVehicleFuel.fuelValue')} />
					</Row>
					<Row>
						<Field property='actualFuelValue' label={t('vehicle.actualFuelValue')} />
						<Field property='actualFoamValue' label={t('vehicle.faxFoamValue')} />
						<Field relation='foamCapacity' property='capacity' label={t('vehicle.foamCapacity')} />
					</Row>
					<Row>
						<Field property='actualPowderCapacity' label={t('vehicle.actualPowderCapacity')} />
						<Field relation='powderCapacity' property='capacity' label={t('fireDepPowderCapacity.title')} />
						<Field relation='fireDep' property='name' label={t('fireDep.title')} isRequired />
					</Row>
				</Form>
			</div>
		);
	}
}
