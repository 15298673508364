import {observable} from "mobx";

export default class ReportActiveForestFiresStore {
	@observable date = null;

	onDateChange = (date) => this.date = date;
	get storeParams() {
		const { date } = this;
		return {
			reportCode: 'ReportActiveForestFires',
			reportParams: { date, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone },
			isHTML: true,
		};
	}
}
