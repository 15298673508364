import { Fill, Icon, Stroke, Style } from 'ol/style';
import { asArray } from 'ol/color';
import VectorLayer from 'ol/layer/Vector';
import { Vector as VectorSource } from 'ol/source';

import { ColorUtils } from '@smartplatform/ui';
import { drawGeoMarker, geoJSON } from '@smartplatform/map/client';
import store from 'client/store';
import t from 'i18n';
import PermafrostLevelingMarkPopup from './PermafrostLevelingMarkPopup';
import React from 'react';
import iconSrc from 'img/icons/permafrost/markMap.svg?url';

export default class PermafrostLevelingMarkLayer {
	constructor(mapStore, settings = {}) {
		this.mapStore = mapStore;
		this.settings = settings;
	}

	init = async () => {
		this.layer = new VectorLayer({
			format: geoJSON,
			source: new VectorSource(),
		});

		this.layer.setZIndex(3);
		this.setVisible();
		this.mapStore.addLayer(this.layer);
		this.load();
	};

	load = async () => {
		const { id, number, startDate, endDate } = this.settings.filter;
		const records = await store.model.PermafrostLevelingMark.getRecords({ id, number, startDate, endDate });

		const features = [];
		for (let record of records) {
			const { geo } = record;

			const { r, g, b } = ColorUtils.hexToRgb('#0af');

			let styleParams = {
				fill: new Fill({ color: asArray([r, g, b, 0.4]) }),
				stroke: new Stroke({ color: asArray([r, g, b, 0.9]), width: 1 }),
			};

			const marker = drawGeoMarker(geo, {
				data: {
					title: t('permafrost.marksMenu'),
					record,
					render: this.renderPopup,
				},
				style: new Style({
					...styleParams,
					image: new Icon({
						src: iconSrc,
						scale: 1.6,
						anchor: [5, 10],
						anchorXUnits: 'pixels',
						anchorYUnits: 'pixels',
					}),
				}),
				onlyGeo: true,
			});

			features.push(...marker);
		}

		this.layer.getSource().clear();
		this.layer.getSource().addFeatures(features);
	};

	setVisible = () => {
		this.layer.setVisible(this.settings.show);
	};

	onLocalStoreChange = (field, record) => {
		this.settings.filter[field] = record;
		store.local.save();
		this.load();
	};

	renderPopup = (record) => {
		const { startDate, endDate } = this.settings.filter;
		return <PermafrostLevelingMarkPopup record={record} isDate={startDate || endDate} noPopupLink={this.settings.noPopupLink} />;
	};
}
