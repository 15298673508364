import { observable } from "mobx";
import { endOfYear, startOfYear } from "date-fns";

export default class dailyInformationFormForStore {
	@observable year = null;
	@observable day = null;

	onYearChange = (year) => this.year = year;

	onDateChange = (day) => this.day = day;

	get storeParams() {
		const { year, day } = this;
		const date = new Date(year, 1, 1);
		const date_1 = new Date(year-1, 1, 1);
		const startDate = startOfYear(date);
		const endDate = endOfYear(date);

		const startDate_1 = startOfYear(date_1);
		const endDate_1 = endOfYear(date_1);

		return {
			reportCode: 'dailyInformationFormFor',
			reportParams: { year, endDate, startDate, startDate_1, endDate_1, day },
			reportModel: 'DeliveryReport'
		};
	}
}
