import React from 'react';
import { action, observable, toJS } from 'mobx';
import debounce from 'lodash/debounce';
import store from "client/store";
import { shortenAddressName } from 'client/tools';

export default class AddressSearchStore {

	@observable search = '';
	@observable prevSearch = '';
	@observable actualSearch = '';
	@observable isLoading = false;
	@observable suggestions = [];
	@observable active = false;
	@observable focus = false;
	@observable selected = null;
	
	triggerInstance = null;
	
	constructor(props) {
		this.props = props;
		this.doSearch = debounce(this.doSearch, 500, { leading: true, trailing: true });
		if (this.props.getInstance) this.props.getInstance({
			setSuggestions: this.setSuggestions,
		});
	}
	
	getInputRef = (el) => this.inputEl = el;
	
	toggle = (activate) => {
		if (this.triggerInstance) {
			const isActive = this.triggerInstance.isActive();
			if (activate && !isActive) this.activate();
			if (!activate && isActive) this.close();
		}
	};
	
	activate = () => {
		if (this.triggerInstance) this.triggerInstance.activate();
	};
	
	close = () => {
		if (this.triggerInstance) this.triggerInstance.close();
	};
	
	onValueClick = () => {
		console.log('onValueClick', this.props.value);
		this.selected = this.props.value;
		this.search = this.prevSearch = shortenAddressName(this.props.value.name);
		if (this.props.onValueClick) this.props.onValueClick(this.props.value);
		if (!this.props.noImmediateSearch) {
			this.activate();
			this.doSearch();
		}
	};
	
	onActivate = () => {
		this.active = true;
		console.log('onActivate')
	};
	
	onClose = () => {
		this.active = false;
		this.prevSearch = this.search;
		this.search = this.selected ? shortenAddressName(this.selected.name) : '';
	};
	
	onSelect = (obj) => async () => {
		console.log('select', toJS(obj));
		this.isLoading = true;

		let address, existing;
		if (obj['name'] === '') {
			existing = await store.model.Address.find({
				where: { name: obj['name'] }
			});
		} else {
			existing = await store.model.Address.find({
				where: { name: { like: `%${obj['name']}%` }}
			});
		}
		if (!obj.hashouse && !obj.isNew) {
			this.prevSearch = this.search = shortenAddressName(obj.name) + ' ';
			await this.doSearch();
			setTimeout(() => {
				if (this.inputEl) {
					const length = this.inputEl.value.length;
					this.inputEl.setSelectionRange(length, length);
					this.inputEl.focus();
				}
			}, 100);
		} else {
			if (existing.length === 0) {
				console.log('🏠 create Address', obj['name'], obj.geo, {...obj});
				address = new store.model.Address({
					name: obj['name']
				});
				if (typeof obj.geo === 'string') {
					address.geo = JSON.parse(obj.geo);
				} else {
					address.geo = obj.geo;
				}
				await address.save();
			} else {
				address = existing[0];
				console.log('🏠 existing Address', existing[0], existing.length > 1 ? 'more than one!' : 'ok', address.geo)
				if (!address.geo || address.name === '') {
					address.geo = obj.geo;
					await address.save();
					console.log('...saved geo', obj.geo)
				}
			}
			this.props.onChange && this.props.onChange(address);
			this.selected = address;
			this.close();
		}
		this.isLoading = false;
	};
	
	@action onChange = e => {
		this.search = e.target.value;
		this.selected = null;
		this.checkActive();
		this.doSearch();
	}
	
	checkActive = () => {
		const active = this.search.trim().length > 0;
		this.toggle(active);
	};
	
	onFocus = () => {
		this.focus = true;
		if (this.prevSearch) this.search = this.prevSearch;
		this.props.onFocus && this.props.onFocus();
		this.checkActive();
	};
	
	onBlur = () => this.focus = false;
	
	doSearch = async () => {
		const trimmed = this.search.trim();
		if (trimmed.length === 0) return;

		this.isLoading = true;
		this.suggestions = await this.searchDadata(trimmed);
		this.isLoading = false;
	};
	
	setSuggestions = (suggestions) => {
		this.activate();
		this.suggestions = suggestions;
	};
	
	searchDadata = async (query) => {
		const res = await store.model.Address.findAddressesByName({
			prefix: this.props.prefix,
			query,
			count: 9
		});
		return res;
	};
	
}