import {observable} from "mobx";

export default class KpoMonthlyReportStore {
	@observable month = new Date().getMonth();
	@observable year = new Date().getFullYear();

	onMonthChange = (month) => (this.month = month);

	onYearChange = (year) => (this.year = year);

	get storeParams() {
		const { month, year } = this
		return {
			reportCode: 'KpoMonthlyReport',
			reportParams: { month, year, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone },
			isHTML: true,
		};
	}
}
