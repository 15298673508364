import React from 'react';
import { observer } from 'mobx-react';

import Filters from './filters';
import { Map, Popup } from '@smartplatform/map/client';
import fireStore, { PARAMS } from '../fireStore';
import Timeline from './timeline';
import MainPopup from './MainPopup';
import Scale from './Scale';
import store from 'client/store';
import './style.scss';
import ExtraLayers from './ExtraLayers';

@observer
export default class FiresMap extends React.Component {
	onLayerToggle = (layers) => {
		store.local.fires.dynamicLayers = layers;
		store.local.save();
	};

	render() {
		const { popup, onMapInit, onTileSourceChange } = fireStore;

		//! карта выключается, если мы ушли с роута,проверка по инициализации карты нужна,если мы инициализуем приложение с другого роута
		const isMapRoute = store.route.path === '/fires/map';
		const isMapInitialized = fireStore.mapInitialized;
		const mapStyle = !isMapRoute && isMapInitialized ? ' map-disabled' : '';

		const visibleLayers = store.local.fires.dynamicLayers;

		const mapParams = {
			...store.config.map,
			...PARAMS,
			onTileSourceChange,
			defaultTileSource: store.local.fires.tileSource,
		};

		const mapProps = {
			className: 'fires-map full-map',
			onInit: onMapInit,
			params: mapParams,
			modelStore: store.model,
			visibleLayers,
			onLayerToggle: this.onLayerToggle,
			showBearing: true,
		};

		return (
			<>
				<Filters />
				<Map {...mapProps} extraLayers={<ExtraLayers />} genShtab={true}>
					{popup && (
						<Popup {...popup} width={popup.width || 550}>
							<MainPopup {...popup} />
						</Popup>
					)}
					<Timeline fireStore={fireStore} />
					<Scale store={fireStore} hidden={!store.local.fires.layers.weather.show} />
				</Map>
			</>
		);
	}
}
