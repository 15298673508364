import React from 'react';
import { inject, observer } from 'mobx-react';
import SubtractIcon from 'img/icons/techfires/card/subtract.svg';
import { FieldBasedCard } from '../../../../components';

@inject('fireStore')
@observer
class WorkFireDep extends React.Component {
	render() {
		const { fireStore } = this.props;
		const { fire, vehicles } = fireStore;
		const rows = [
			[
				{ property: 'ond', label: 'techFire.ond', type: 'Input' },
				{ property: 'rescueTeamsCount', label: 'techFire.rescueTeamsCount', type: 'NumberInput' },
				{ property: 'barrelsCount', label: 'techFire.barrelsCount', type: 'NumberInput', disabled: true },
				{ property: 'waterSupplyMounts', label: 'techFire.waterSupplyMounts', type: 'NumberInput' },
			],
			[
				{
					label: 'techFire.vehiclesCount',
					type: 'LabelField',
					props: { value: vehicles.length, disabled: true },
				},
				{
					label: 'techFire.specialVehicle',
					type: 'LabelField',
					props: { value: vehicles.filter((v) => v.specialTypeCode === 'SPECIAL').length, disabled: true },
				},
				{ property: 'empty', type: 'default' },
				{ property: 'empty', type: 'default' },
			],
		];

		return (
			<FieldBasedCard
				title='techFire.workFireDep'
				icon={SubtractIcon}
				fields={[fire.ond, fire.rescueTeamsCount, fire.waterSupplyMounts]}
				rows={rows}
				statusKey='workFireDep'
				fireStore={fireStore}
				isCard={true}
			/>
		);
	}
}

export default WorkFireDep;
