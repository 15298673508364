import React from 'react';
import { observer } from 'mobx-react';
import { Column, DateValue, Pager, Table } from '@smartplatform/ui';
import { ListStore, Toolbar } from 'components';
import store from 'client/store';
import './forestFireMoveList.scss';
import t from 'i18n';
import { renderFireName } from 'client/tools';
import { observable } from 'mobx';
import GarrisonSelect from 'techfires/components/garrison-select/GarrisonSelect';

const FILTER = {
	include: [
		{
			relation: 'fire',
			scope: {
				include: [
					{ relation: 'status', field: ['id', 'color'] },
					{ relation: 'county', field: ['id', 'name'] },
				],
			},
		},
	],
};

@observer
export class ForestFireMoveList extends React.Component {
	@observable garrison = null;
	constructor(props) {
		super(props);
		this.store = new ListStore({ path: props.path, model: store.model.ForestFireMovement, filters: FILTER });
	}

	componentDidMount() {
		this.store.setPerPage();
	}

	onGarrisonChange = async (value) => {
		this.garrison = value;
		const where = {};
		if (value) where.garrisonId = value.id;
		this.store.updateFilters({ ...FILTER, where: { ...where } });
	};

	render() {
		const { tableProps, totalCount, perPage, page, onChange, search, onSearch, create } = this.store;

		return (
			<div className='forest-fire-movement-list'>
				<Toolbar>
					<Toolbar.AddButton onClick={create} />
					<GarrisonSelect value={this.garrison} onChange={this.onGarrisonChange} width={200} />
					{/* <Toolbar.SearchIconInput value={search} onChange={onSearch} /> */}
				</Toolbar>
				<Pager current={page} onChange={onChange('page')} totalCount={totalCount || 0} itemsPerPage={perPage} />
				<div id='table'>
					<Table {...tableProps}>
						<Column relation='fire' computed={renderFireName} label={t('fire.title')} />
						<Column relation='garrison' property='name' label={t('garrison')} />
						<Column relation='fire' computed={(r) => r.status?.name} label={t('fireStatus.title')} />
						<Column property='departureDate' label={t('departureDate')}>
							<DateValue format='dd.MM.yyyy' />
						</Column>
						<Column property='returnDate' label={t('returnDate')}>
							<DateValue format='dd.MM.yyyy' />
						</Column>
					</Table>
				</div>
			</div>
		);
	}
}
