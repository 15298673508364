import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Button, Field, FileInput, Form, FormContext, Row } from '@smartplatform/ui';
import t from 'i18n';
import ObjectInspector from './ObjectInspector';
import ObjectOvernightStay from './ObjectOvernightStay';
import ObjectInspectorCorrection from './ObjectInspectorCorrection';

@observer
export default class ObjectEdit extends React.Component {
	static contextType = FormContext;
	@observable isDeleting = false;
	@observable totalPeople = null;
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.checkFireDep();
	}

	save = async (context) => {
		await context.form.save();
		const object = this.props.object;
		object.totalPeople = this.totalPeople;
		if (!object.manualFill) {
			object.nameInspector = null;
			object.positionInspector = null;
			object.organizationInspector = null;
		}
		object.save();
		this.props.onSave && this.props.onSave(this.props.object);
	};

	cancel = async (context) => {
		context.form.reset();
		this.props.onCancel();
	};

	startDelete = () => (this.isDeleting = true);
	cancelDelete = () => (this.isDeleting = false);

	delete = async () => {
		this.isDeleting = false;
		await this.props.object.delete();
		this.props.onDelete && this.props.onDelete();
	};

	totalPeopleOvernightStay = () => {
		const { adults, children, unableMoveIndependently, courtship, personnel } = this.props.object;
		return (this.totalPeople = adults + children + unableMoveIndependently + courtship + personnel);
	};

	checkFireDep = async () => {
		const { fireDep, object } = this.props;
		if (fireDep?.id !== object?.fireDepId) {
			object.fireDepId = fireDep?.id ?? null;
			await object.save();
		}
		this.isLoading = false;
	};

	render() {
		if (this.isLoading) return null;
		const { object } = this.props;
		const canSave = !!object.name && object.name.trim().length > 0;
		console.log(this.isDeleting); // без этого не работает удаление объекта O_o
		const rankName = object.rank && object.rank.name;
		const rankNameCompare = rankName === '3 РАНГ ПОЖАРА' || rankName === '2 РАНГ ПОЖАРА';
		return (
			<div className='address-object-edit'>
				<Form record={object} writeAllowed={canSave} stay noSave noDelete>
					<FormContext.Consumer>
						{(context) => (
							<div className='object-controls'>
								<Button variant='primary' onClick={() => this.save(context)} size='sm' disabled={!object.name}>
									{t('save')}
								</Button>
								<Button onClick={() => this.cancel(context)} size='sm'>
									{t('cancel')}
								</Button>
								{!!object.id && (
									<div className='right'>
										{this.isDeleting ? (
											<>
												{t('confirmDelete')}
												<Button size='sm' variant='danger' onClick={this.delete}>
													{t('yes')}
												</Button>
												<Button size='sm' onClick={this.cancelDelete}>
													{t('no')}
												</Button>
											</>
										) : (
											<Button size='sm' variant='danger' onClick={this.startDelete}>
												{t('delete')}
											</Button>
										)}
									</div>
								)}
							</div>
						)}
					</FormContext.Consumer>
					<div className='address-object-edit-container'>
						<div className='address-object-fields'>
							<Field property='name' label={t('techFireAddress.name')} isRequired />
							<Field property='description' label={t('techFireAddress.description')}>
								<textarea rows={3} />
							</Field>
							<Row>
								<Field relation='rank' property='name' label={t('techFireRank.title')} />
								<Field relation='fireDep' property='name' label={t('fireDep.title')} disabled />
							</Row>
							<Row>
								<Field property='ptp' label={t('techFireAddress.ptp')} />
								<Field property='ptpFile' label={t('techFireAddress.ptp')}>
									<FileInput
										accept={{
											'image/jpeg': ['.jpg', '.jpeg'],
											'image/png': ['.png'],
											'application/pdf': ['.pdf'],
										}}
										className='address-object-fileinput'
									/>
								</Field>
							</Row>
							<Row>
								<Field property='ktp' label={t('techFireAddress.ktp')} />
								<Field property='ktpFile' label={t('techFireAddress.ktp')}>
									<FileInput
										accept={{
											'image/jpeg': ['.jpg', '.jpeg'],
											'image/png': ['.png'],
											'application/pdf': ['.pdf'],
										}}
										className='address-object-fileinput'
									/>
								</Field>
							</Row>
							<Row>
								<Field relation='category' property='name' itemTag={(f) => f.name} label={t('techFireCategory.title')} />
							</Row>
							<Field property='overnightStay' label={t('techFireAddress.overnightStayPeople')} className='overnight-stay-checkbox' />
							{rankNameCompare && (
								<>
									<ObjectInspector object={object} inspectorEdit /> <ObjectInspectorCorrection object={object} inspectorEdit />
								</>
							)}
						</div>
						{object.overnightStay && <ObjectOvernightStay overnightEdit object={object} totalPeopleOvernightStay={this.totalPeopleOvernightStay} />}
					</div>
				</Form>
			</div>
		);
	}
}
