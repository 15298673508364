import React from 'react';
import { observable } from 'mobx';
import techfiresMap from '../store';
import store from 'client/store';

export default class AddressToolStore {
	
	@observable address = null;
	@observable showPopup = false;
	
	constructor() {
		// this.test();
	}
	
	test = async () => {
		const address = await store.model.Address.findById(1715);
		this.onAddressSelect(address)
	};
	
	onAddressSelect = async (address) => {
		this.address = address;
		if (address.geo) {
			techfiresMap.fitGeoWithMarker(address.geo, { maxZoom: 18, padding: [50, 150, 250, 0] });
		}
		this.showPopup = true;
	};
	
	onPopupClose = () => {
		this.showPopup = false;
		this.address = null;
	}
	
	pickPoint = () => {
		techfiresMap.mapStore.pickPoint(this.onPickPoint);
	};
	
	onPickPoint = async (e, lonLat) => {
		console.log('pickPoint', e, lonLat)
		const suggestions = await this.searchAddress(lonLat);
		console.log('suggestions', suggestions.map(obj => obj['name']))
		if (this.searchInstance) {
			this.searchInstance.setSuggestions(suggestions);
		}
	};

	getSearchInstance = (instance) => this.searchInstance = instance;
	
	searchAddress = async (lonLat) => {
		const geoObj = {
			type: 'Point',
			coordinates: [parseFloat(lonLat[0]), parseFloat(lonLat[1])],
		}

		let res = await store.model.Address.findAddressesByGeo({
			lon: lonLat[0],
			lat: lonLat[1],
			count: 9
		});
		res.push({name: '', geo: geoObj, isNew: true})

		return res;
	};
}
