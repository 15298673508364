import React from 'react';
import { AreaTenant } from './AreaTenant';
import { Requisites } from './Requisites';
import { FlameProperties } from './FlameProperties';
import { Area } from './Area';
import { MainInfo } from './MainInfo';
import './style.scss';
export class Dynamic extends React.Component {
	render() {
		return (
			<div className='dynamic'>
				<div className='left'>
					<Area />
					<AreaTenant />
				</div>
				<div className='right'>
					<MainInfo />
					<FlameProperties />
					<Requisites />
				</div>
			</div>
		);
	}
}
