import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from '@smartplatform/ui';
import initYoutubePlugin from './plugins/youtube';
import initFilesUploadPlugin from './plugins/files-upload';
import contentCss from '!!file-loader?outputPath=css/tinymce!./content.css';
import gridCss from '!!file-loader?outputPath=css/tinymce!./grid.css';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

@observer
export default class Editor extends React.Component {
	static propTypes = {
		value: PropTypes.string,
		filesUploadData: PropTypes.object,
		onChange: PropTypes.func,
		disabled: PropTypes.bool,
		height: PropTypes.number,
		textareaName: PropTypes.string,
		mediaModel: PropTypes.any,
		mediaProperty: PropTypes.string,
		documentBaseUrl: PropTypes.string,
		acceptFiles: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
		onInit: PropTypes.func,
		menubar: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
		toolbar: PropTypes.string,
		beforeSave: PropTypes.func,
	};

	static defaultProps = {
		disabled: false,
		height: 600,
		acceptFiles: ['image/jpeg', 'image/png'],
		mediaProperty: 'filename',
	};

	@observable isLoading = false;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.props.beforeSave && this.props.beforeSave(this.beforeSave);
		initYoutubePlugin(); // вызывать в конструкторе, до загрузки tinymce
		if (this.props.filesUploadData) initFilesUploadPlugin(props.filesUploadData);

		this.isLoading = true;
		const module = await import('@smartplatform/ui/src/components/editor/tinymce/Editor');
		const Editor = module.default;
		console.log({ Editor });
		this.tinyMceEditor = Editor;
		this.isLoading = false;
	};

	handleProgress = (event) => {};

	handleEnd = (error, result, media, success, failure) => {
		if (error) {
			console.warn('upload error:', error);
			failure(error.message);
		} else {
			const url = media.downloadFile(this.props.mediaProperty) + '?image=[{"resize":{"width":885}}]';
			console.log('handleEnd', result, url);
			success(url);
		}
	};

	uploadHandler = (blobInfo, success, failure) => {
		const file = blobInfo.blob();
		const filename = blobInfo.filename();
		console.log('uploadHandler', filename, file);
		const media = new this.props.mediaModel();
		media[this.props.mediaProperty] = filename;
		media.inline = true;
		media.save().then(() => {
			media
				.uploadFile(this.props.mediaProperty, file)
				.on('progress', (event) => this.handleProgress(event))
				.end((error, result) => this.handleEnd(error, result, media, success, failure));
		});
	};

	beforeSave = async (record) => {
		if (this.editor) {
			await this.uploadImages(this.editor);
		}
	};

	onInit = (editor) => (this.editor = editor);

	uploadImages = (editor) =>
		new Promise((resolve) => {
			editor.uploadImages((success) => {
				resolve(success);
			});
		});

	setup = (editor) => {};

	render() {
		if (this.isLoading) return <Loader />;

		const TinyMCEEditor = this.tinyMceEditor;
		// return <Editor {...this.props} />;

		return (
			<TinyMCEEditor
				plugins={this.props.filesUploadData ? 'sp-files' : undefined}
				contentCss={[contentCss, gridCss]}
				setup={this.setup}
				automaticUploads={true}
				imagesUploadHandler={this.props.mediaModel ? this.uploadHandler : undefined}
				{...this.props}
			/>
		);
	}
}
