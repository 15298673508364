import React from 'react';
import ClockIcon from 'img/icons/techfires/card/clock.svg';
import { inject, observer } from 'mobx-react';
import { FieldBasedCard } from '../../../../components';

@inject('fireStore')
@observer
class TimeFirstSupply extends React.Component {
	render() {
		const { fireStore } = this.props;
		const { fire } = fireStore;

		const rows = [
			[
				{
					property: 'firstSupplyTime',
					label: 'techFire.firstSupplyTime',
					type: 'DatePicker',
					props: { showTimeSelect: true },
				},
				{
					property: 'localizationTime',
					label: 'techFire.localizationTime',
					type: 'DatePicker',
					props: { showTimeSelect: true },
				},
				{
					property: 'terminationTime',
					label: 'techFire.terminationTime',
					type: 'DatePicker',
					props: { showTimeSelect: true },
				},
				{
					property: 'terminationFireTime',
					label: 'techFire.terminationFireTime',
					type: 'DatePicker',
					props: { showTimeSelect: true },
				},
			],
		];

		return (
			<FieldBasedCard
				title='techFire.timeFirstSupplyLocalizationTermination'
				icon={ClockIcon}
				fields={[fire.firstSupplyTime, fire.localizationTime, fire.terminationTime, fire.terminationFireTime]}
				rows={rows}
				statusKey='timeFirstSupplyLocalizationTermination'
				fireStore={fireStore}
				isCard={true}
			/>
		);
	}
}

export default TimeFirstSupply;
