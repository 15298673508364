import { observable } from 'mobx';

export default class FireReportStore {
	@observable date = new Date();

	get storeParams() {
		return {
			reportCode: 'LO1Section4',
			reportParams: { date: this.date, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone },
		};
	}

	onDateChange = (date) => (this.date = date);
}
