import { faL } from '@fortawesome/free-solid-svg-icons';
import store from 'client/store';
import { observable } from 'mobx';

export default class PlanStore {
	@observable isLoading = true;
	@observable subTypes = [];
	@observable type = null;
	@observable total = null;
	@observable garrison = null;

	constructor(id) {
		this.id = id;
		this.init();
	}

	init = async () => {
		this.isLoading = true;
		if (this.id) {
			this.plan = await store.model.TechFirePlan.findById(this.id, {
				include: [{ relation: 'planVisitType' }, { relation: 'garrison' }],
			});
			this.details = await store.model.TechFirePlanDetail.find({ where: { planId: this.id} });
			let subTypes = await this.getSubTypes(this.plan.typeId);
			this.subTypes = subTypes.map((item)=>({subTypeId: item.id, subTypeCode: item.code, subTypeName: item.name, subTypeAmount: 0}));
			for (const item of this.subTypes) {
				const detail = this.details.find((d) => d.subTypeId === item.subTypeId);
				item.subTypeAmount = detail ? detail.amount : 0;
			}
			this.total = this.plan.amount;
			const planType = await store.model.TechFireVisitType.findById(this.plan.typeId);
			this.type = planType;
			this.garrisonId = this.plan.garrisonId;
			if (this.garrisonId) {
				this.garrison = await store.model.TechFireOrganization.findById(this.garrisonId);
			}
		} else {
			this.plan = new store.model.TechFirePlan();
			this.plan.garrisonId = store.model.user.garrisonId;
			this.garrisonId = this.plan.garrisonId;
			if (this.garrisonId) {
				this.garrison = await store.model.TechFireOrganization.findById(this.garrisonId);
			}
		}
		this.isLoading = false;
	};

	getTotal(subTypesList) {
		return subTypesList.reduce((accumulator, item) => {
			return accumulator + parseInt(item.subTypeAmount);
		}, 0);
	}

	getSubTypes = async (typeId) => {
		const subTypes = await store.model.TechFireVisitSubType.find({where: {parentId: typeId}});
		return subTypes;
	}

	onChangeType = (plan) => async (value) => {
		this.type = value;
		let subTypes = await this.getSubTypes(this.type.id);
		this.subTypes = subTypes.map((item)=>({subTypeId: item.id, subTypeCode: item.code, subTypeName: item.name, subTypeAmount: 0}));
	}

	onChangeGarrison = (plan) => async (value) => {
		this.plan.garrisonId = value.id;
		this.garrisonId = value.id;
		this.garrison = value;

	}

	onChangeSubAmount = (subType) => (amount) => {
		const idx = this.subTypes.indexOf(subType);
		this.subTypes[idx].subTypeAmount=amount;
		this.total = this.getTotal(this.subTypes);
	}

  savePlan = async () => {
		this.plan.typeId = this.type.id;
		this.plan.amount = this.total;
		this.plan.garrisonId = this.garrisonId;
		await this.plan.save();
		for (const item of this.subTypes) {
			const data = {
				amount: item.subTypeAmount,
				planId: this.plan.id,
				subTypeId: item.subTypeId,
			};
	
			const details = await store.model.TechFirePlanDetail.find({ where: { planId: this.plan.id, subTypeId: item.subTypeId } });
			if (details.length>0) {
				await store.model.TechFirePlanDetail.updateAll({ and: [{planId: this.plan.id}, {subTypeId: item.subTypeId}] }, { amount: item.subTypeAmount });
			} else {
				await store.model.TechFirePlanDetail.create(data);
			}
		}
		store.route.push({ path: this.path });
	};

  cancelPlan = () => {
		store.route.push({ path: this.path });
	};

	deletePlan = async () => {
		//await store.model.TechFirePlanDetail.destroyAll({ planId: this.plan.id });
		for (let detail of this.details) {
			await detail.delete();
		}
		await this.plan.delete();
		store.route.push({ path: this.path });	
	}

	onChange = (prop) => (value) => {
		this.plan[prop] = value;
	};
}