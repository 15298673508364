import React from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';

import { Loader, Form, Row, Field, Button, Input, DatePicker, RecordSelect, Popconfirm, Checkbox } from '@smartplatform/ui';
import { fio, getRoleNames } from 'client/tools';
import EditPersonnelAndVehicles from './sentry/EditPersonnelAndVehicles';
import ViewPersonnelAndVehicles from './sentry/ViewPersonnelAndVehicles';
import store from 'client/store';
import t from 'i18n';
import { INCLUDE_PERSONNEL, INCLUDE_VEHICLES, INCLUDE_VIEW_PERSONNEL, isRadiocallpch } from '../constants';
import { guardHeadFireDepItem } from '../../helpers';
import { SUCCESS } from '../../../../constants';

@inject('store')
@observer
export default class FireDepShift extends React.Component {
	@observable fireDep = null;
	@observable fireDepShift = null;
	@observable dispatcher = null;
	@observable fireDepSentry = null;
	@observable reserve = null;
	@observable isLoading = true;
	@observable error = null;

	constructor(props) {
		super(props);
		this.store = props.store;
		const { pathname } = this.props.location;
		this.reserve = pathname.split('/').includes('reserve');
		store.route.params = 'reserve';
		this.init();
	}

	init = async () => {
		const { id, fireDepId } = this.props.match.params;

		await this.store.fetchFireDep(fireDepId);
		await this.store.fetchFireDepPersonnel();
		this.store.isReserve = this.reserve;

		const shifts = await store.model.FireDepShift.find({
			where: { shiftId: id, fireDepId, isReserve: this.reserve || null },
			include: [
				{
					relation: 'dispatcher',
					scope: {
						include: ['section', 'position'],
					},
				},
				{
					relation: 'substituteDispatcher',
					scope: {
						include: ['section', 'position'],
					},
				},
				INCLUDE_PERSONNEL,
				INCLUDE_VEHICLES,
				{
					relation: 'guardHead',
				},
				{
					relation: 'fireDep',
				},
			],
		});

		if (shifts.length > 0) {
			this.fireDepShift = shifts[0];
			this.store.recordForFetchShift = this.fireDepShift;
			this.store.initShift();
			this.dispatcher = this.fireDepShift.dispatcher;
		} else {
			const personnel = await store.model.FireDepPersonnel.find({
				where: { userId: store.model.user.id },
				include: ['section', 'position'],
			});
			this.dispatcher = personnel[0] || null;
			const roleNames = await getRoleNames();

			const fireDepShift = new store.model.FireDepShift();
			fireDepShift.shiftId = id;
			fireDepShift.fireDepId = fireDepId;
			fireDepShift.mainDispatcher = roleNames.includes('tfires_main');
			fireDepShift.dispatcher = this.dispatcher;
			fireDepShift.date = new Date();
			this.fireDepShift = fireDepShift;
			if (this.reserve) {
				fireDepShift.isReserve = true;
			}
		}
		this.isLoading = false;
	};

	resetShift = async () => {
		await this.fireDepShift.personnel.destroy();
		await this.fireDepShift.vehicles.destroy();
		await this.fireDepShift.delete();
		this.fireDepSentry = null;
		store.route.push({ path: `/techfires/shifts/${this.props.match.params.id}` });
	};

	renderSentryName = (record) => {
		if (record.name) return record.name;
		if (record.globalSentry) return record.globalSentry.name;
		return '-';
	};

	onFireDepSentryChange = (record) => {
		this.fireDepSentry = record;
		this.fireDepShift.sentry = this.fireDepSentry;
		this.store.recordForFetchShift = record;
		this.store.initShift();
	};

	onStandInChange = () => {
		const value = this.fireDepShift.standIn;
		console.log('onStandInChange', value);
		if (value) {
			this.fireDepShift.dispatcher = null;
		} else {
			this.fireDepShift.substituteDispatcher = null;
		}
	};

	submit = async () => {
		await this.fireDepShift.save();
		await this.store.updateVehicles();
		await this.store.savePersonnelAndVehicles(this.fireDepShift);
		store.route.push({ path: `/techfires/shifts/${this.props.match.params.id}` });
		if (this.reserve) {
			store.ui.setNotification({
				message: t('fireDepShift.readeToLeaveNotification') + this.store.fireDep.name,
				type: SUCCESS,
				isAlarm: true,
				onConfirm: () => store.route.push({ path: `/techfires/shifts/${this.props.match.params.id}/firedep/${this.props.match.params.fireDepId}/reserve` }),
			});
		}
	};

	renderSection = (record) => {
		return <Input value={record.section?.name || '-'} disabled />;
	};

	renderPosition = (record) => {
		return <Input value={record.position?.name || '-'} disabled />;
	};

	onGuardHeadChange = (guard) => {
		this.fireDepShift.guardHead = guard;
	};

	render() {
		if (this.isLoading) return <Loader />;
		const { fireDepId } = this.props.match.params;
		const disabled = !!this.fireDepShift.id;
		const choiceVisible = this.reserve || !this.fireDepShift.id;
		return (
			<div className='list'>
				<Form record={this.fireDepShift} disabled={disabled} noDelete noSave stay className='firedepshift-form'>
					{this.reserve && (
						<Row>
							<Field property='date' label={t('fireDepShift.date')}>
								<DatePicker showTimeSelect isRequired />
							</Field>
							<div />
							<div />
						</Row>
					)}
					{!this.reserve && (
						<>
							<Row>
								<Field property='date' label={t('fireDepShift.date')}>
									<DatePicker showTimeSelect isRequired />
								</Field>
								{/*При создании смены создается копия FireDepPersonnel с id смены. Чтобы показывать только личный состав пожарной части показываем запись без id смены*/}
								<Field
									relation='dispatcher'
									computed={fio}
									filter={{ where: { fireDepShiftId: null } }}
									label={t('fireDepShift.dispatcher')}
									disabled={!!this.fireDepShift.standIn}
								/>
								<Field relation='dispatcher' label={t('fireDepSection.title')}>
									{this.renderSection}
								</Field>
								<Field relation='dispatcher' label={t('fireDepPosition.title')}>
									{this.renderPosition}
								</Field>
							</Row>
							<Field property='standIn' label={t('fireDepShift.standIn')}>
								<Checkbox onChange={this.onStandInChange} />
							</Field>
							<Row>
								<Field label={t('fireDepShift.guardHead')}>
									<RecordSelect
										model={store.model.ViewFireDepPersonnel}
										computed={guardHeadFireDepItem}
										// При создании смены создается копия FireDepPersonnel с id смены. Чтобы показывать только личный состав пожарной части показываем запись без id смены
										filter={{ where: { fireDepShiftId: null, fireDepId: { neq: null } } }}
										showValue={this.fireDepShift.guardHead ? guardHeadFireDepItem(this.fireDepShift.guardHead) : '-'}
										onChange={this.onGuardHeadChange}
									/>
								</Field>
								<Field
									relation='substituteDispatcher'
									computed={fio}
									filter={{ where: { fireDepShiftId: null } }}
									label={t('fireDepShift.substituteDispatcher')}
									disabled={!this.fireDepShift.standIn}
								/>
								<Field relation='substituteDispatcher' label={t('fireDepSection.title')}>
									{this.renderSection}
								</Field>
								<Field relation='substituteDispatcher' label={t('fireDepPosition.title')}>
									{this.renderPosition}
								</Field>
							</Row>
						</>
					)}
				</Form>
				<div className='mt-2'>
					{choiceVisible && (
						<Row>
							<div className='form-field'>
								<label className='is-required'>{t('fireDepSentry.title')}</label>
								<RecordSelect
									model={store.model.FireDepSentry}
									value={this.fireDepSentry}
									filter={{
										where: { fireDepId },
										include: ['globalSentry', INCLUDE_PERSONNEL, INCLUDE_VEHICLES],
									}}
									computed={this.renderSentryName}
									onChange={this.onFireDepSentryChange}
									placeholder={this.store.fireDep ? t('fireDepSentry.select') : t('fireDep.select')}
									disabled={!this.store.fireDep || !choiceVisible}
									isRequired
								/>
							</div>
							{choiceVisible && (
								<div className='mt-4'>
									<Button variant='primary' onClick={this.submit} disabled={!this.fireDepSentry}>
										{!this.reserve ? <>{t('fireDepShift.submit')}</> : <>{t('fireDepShift.readyToLeave')}</>}
									</Button>
								</div>
							)}
							<div />
						</Row>
					)}
					{this.fireDepSentry && <EditPersonnelAndVehicles sentry={this.fireDepSentry} reserve={this.reserve} />}
					{this.fireDepShift.id && <ViewPersonnelAndVehicles shift={this.fireDepShift} />}
				</div>
				{this.fireDepShift.id && this.props.isMain && !isRadiocallpch() && (
					<div className='mt-4'>
						<Popconfirm onConfirm={this.resetShift} content={t('fireDepShift.confirmReset')}>
							<Button variant='danger'>{t('fireDepShift.reset')}</Button>
						</Popconfirm>
					</div>
				)}
			</div>
		);
	}
}
