import { observable } from "mobx";

export default class yearStore {
	@observable startDate = null;
	@observable endDate = null;

	onStartDateChange = (startDate) => this.startDate = startDate;

	onEndDateChange = (endDate) => this.endDate = endDate;

	get storeParams() {
		const { startDate, endDate } = this;
		return {
			reportCode: 'year',
			reportParams: { startDate, endDate },
			reportModel: 'DeliveryReport'
		};
	}
}
