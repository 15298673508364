import { observable, runInAction } from 'mobx';
import { API } from './api';
import store from 'client/store';
import { FiresLayer } from 'components';

export class ForestFireMoveStore {
	@observable isLoading = true;
	@observable record = null;
	@observable fire = null;
	@observable fireDeps = [];
	@observable addFireDep = null;
	@observable editFireDepId = null;
	@observable personnelIdSet = new Set();
	@observable personnelLogs = [];

	@observable fireLayerSettings = {
		noPopupLink: true, // линк на карточку в попапе
		show: true,
		ids: [],
		status: {
			intensifies: true, // Усиливается
			continues: true, // Продолжается
			weakens: true, // Ослабевает
			notspreading: true, // Не распространяется
			localized: true, // Локализован
			resumed: true, // Возобновился
			extinguished: true, // Ликвидирован
			abandoned: true, // Прекращено тушение по решению КЧС
		},
		service: { serviced: true, unattended: true },
	};

	constructor(props) {
		this.path = props.path;
		this.id = parseInt(props.match.params.id);
		this.isNew = Number.isNaN(this.id);
		this.init();
	}

	init = async () => {
		try {
			this.record = this.isNew ? API.getNewRecord() : await API.getRecordById(this.id);
			if (!this.isNew) {
				await this.onFireChange(this.record.fire);
				this.fireDeps = !this.isNew ? this.record.fireDeps() : [];
				const personnel = this.record.personnel();
				this.personnelLogs = this.record.personnelLogs();
				if (personnel.length) {
					this.personnelIdSet = new Set(personnel.map((i) => i.id));
				}
			}

			const [personnelFFstatus, vehicleFFstatus] = await API.getFFStatuses();
			this.personnelFFstatus = personnelFFstatus;
			this.vehicleFFstatus = vehicleFFstatus;
		} catch (e) {}
		this.isLoading = false;
	};

	onMapInit = (mapStore) => {
		this.mapStore = mapStore;
		this.map = mapStore.map;
		this.firesLayer = new FiresLayer(mapStore, this.fireLayerSettings);
		this.firesLayer.init();
		this.fitGeo();
	};

	fitGeo = (geo) => {
		geo = geo || this.fire.hqGeo;
		if (geo) {
			this.mapStore.fitGeo(geo, 12);
		}
	};

	onChange = (prop) => (value) => (this[prop] = value);
	onEditFireDep = (id) => (this.editFireDepId = this.editFireDepId === id ? null : id);
	beforeSave = () => (this.record.fireId = this.fire ? this.fire.id : null);
	onClean = () => (this.fire = null);

	onSave = ({ id }) => {
		if (this.isNew) {
			this.isNew = false;
			store.route.push({ path: this.path + `/${id}` });
		}
	};

	goBack = () => store.route.push({ path: this.path });

	beforeDelete = async () => {
		if (!this.record.returnDate) {
			const personnel = await this.record.personnel();
			for (const { id } of personnel) {
				this.record.personnel.remove(id);
			}
		}
	};

	onReturn = async () => {
		this.record.returnDate = new Date();
		await this.changeStatuses();
		await this.record.save();
		const personnel = await this.record.personnel();
		const promises = [];
		const personnelLogs = [];

		for (const person of personnel) {
			const { id, ...rest } = person;
			const personLog = new store.model.ForestFireMovementPersonnelLog({ ...rest, forestFireMovementId: this.record.id });
			promises.push(personLog.save());
			personnelLogs.push(personLog);
		}
		await Promise.all(promises);
		this.personnelLogs.replace(personnelLogs);
	};

	// firedeps

	onFireDepDelete = async (fireDep, index) => {
		const promises = [];
		//! надо будет переделетать на хук
		for (const personnel of fireDep.personnel()) {
			promises.push(this.record.personnel.remove(personnel.id));
		}
		promises.push(this.record.fireDeps.remove(fireDep.id));
		await Promise.all(promises);
		this.personnelIdSet.clear();
		this.fireDeps.splice(index, 1);
	};

	onAddFireDep = async () => {
		await this.record.fireDeps.add(this.addFireDep.id);
		const fireDep = await API.getFireDepById(this.addFireDep.id);
		this.fireDeps.push(fireDep);
		this.editFireDepId = this.addFireDep.id;
		this.addFireDep = null;
	};

	onFireChange = async (fire) => {
		if (!fire) return;
		this.fire = await API.getFireById(fire.id);
		this.fireLayerSettings.ids = [fire.id];
		this.firesLayer && this.firesLayer.load();
		this.mapStore && this.fitGeo(fire.hqGeo);
	};

	// personnel
	changeStatuses = async (personnelId = null, vehicleStatus = null) => {
		const promises = [];
		for (const id of this.personnelIdSet) {
			promises.push(this.changePersonnelStatus({ id, statusId: personnelId }));
		}
		for (const fireDep of this.fireDeps) {
			const personnel = fireDep.personnel();
			for (let record of personnel) {
				if (this.personnelIdSet.has(record.id) && record.vehicleId) {
					promises.push(this.changeVehicleStatus({ id: record.vehicleId, vehicleStatusId: vehicleStatus }));
				}
			}
		}
		await Promise.all(promises);
	};

	changePersonnelStatus = async ({ id, statusId }) => {
		const record = new store.model.FireDepPersonnel({ id, statusId });
		return record.save();
	};

	changeVehicleStatus = async ({ id, vehicleStatusId }) => {
		const record = new store.model.FireDepVehicle({ id, vehicleStatusId });
		return record.save();
	};

	onPersonnelChange = async (personnel, fireDep) => {
		const { id, vehicleId } = personnel;

		if (this.personnelIdSet.has(id)) {
			this.record.personnel.remove(id);
			if (vehicleId && !fireDep.personnel().find((i) => i.vehicleId === vehicleId && this.personnelIdSet.has(i.id) && i.id !== id)) {
				personnel.vehicle.status = null;
			}

			personnel.status = null;

			this.personnelIdSet.delete(id);
		} else {
			this.record.personnel.add(id);

			if (this.personnelFFstatus) {
				personnel.status = this.personnelFFstatus;
			}

			if (this.vehicleFFstatus && vehicleId) {
				personnel.vehicle.status = this.vehicleFFstatus;
			}

			this.personnelIdSet.add(id);
		}
	};
}
