import CumulativeSummaryForestFiresStore from './store';
import React from 'react';
import { observer, Provider } from 'mobx-react';
import { Filters } from './Filters';
import { ReportToolbar } from 'components';

@observer
export class CumulativeSummaryForestFires extends React.Component {
	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.report = new CumulativeSummaryForestFiresStore();
		this.report.isLoading = true;
	};

	render() {
		return (
			<Provider report={this.report}>
				<>
					<Filters />
					<ReportToolbar {...this.report.storeParams} disableGenerate={!this.report.date} />
				</>
			</Provider>
		);
	}
}
