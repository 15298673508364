import { observable } from 'mobx';
import store from 'client/store';
import { INCLUDE_PERSONNEL, INCLUDE_VIEW_PERSONNEL } from 'techfires/shifts/constants';

export default class ShiftStore {
	@observable fireDepPersonnel = [];
	@observable personnel = [];
	@observable vehicles = [];
	@observable isReserve;
	@observable order = 'fio asc';
	@observable fireDep = null;
	@observable recordForFetchShift = null;

	updateVehicles = async () => {
		const personVehicleIds = [...new Set(this.personnel.filter((p) => p.selected === true).map((p) => p.vehicleId))];

		const remainVehicles = this.vehicles.filter((vehicle) => personVehicleIds.includes(vehicle.id));

		const id = [];
		for (const personVehicleId of personVehicleIds) {
			if (personVehicleId && !remainVehicles.some((x) => x.id === personVehicleId)) {
				id.push(personVehicleId);
			}
		}
		const promises = [...id.map((x) => store.model.FireDepVehicle.findById(x))];
		const addVehicles = await Promise.all(promises);
		this.vehicles = [...remainVehicles, ...addVehicles];
	};

	onChange = (newData, row, field) => {
		const { record } = row;
		console.log('onChange: ', newData, row, field);
		record[field] = newData;
		if (field === 'callSign') {
			record.newCallSignName = newData?.name ?? null;
			record.newCallSignId = newData?.id ?? null;
		}
		if (field === 'vehicle') {
			record.vehicle.newCallSignName = newData?.callSign?.name ?? null;
			record.vehicle.newCallSignId = newData?.callSignId ?? null;
		}
	};

	initShift = async (record) => {
		const statusCode = this.isReserve ? 'RESERV27' : 'CC4';
		this.personnel = this.fireDepPersonnel
			.filter((p) => p.status?.code !== 'FF')
			.map((p) => {
				let { id, firstName, lastName, middleName, positionId, statusId, fireDepId, gasMaskId, vehicleId, vehicle, fio, fireDepShiftId } = p;
				if (vehicle?.status?.code !== statusCode && !fireDepShiftId) {
					vehicleId = null;
				}
				return new store.model.ViewFireDepPersonnel({
					id,
					firstName,
					lastName,
					middleName,
					positionId,
					statusId,
					fireDepId,
					gasMaskId,
					vehicleId,
					fio,
					fireDepShiftId,
				});
			});
		const shiftPersonnel = this.recordForFetchShift.personnel();
		for (const p of this.personnel) {
			if (shiftPersonnel.map((x) => x.id).includes(p.id)) {
				p.selected = true;
			}
		}
		this.vehicles = this.recordForFetchShift.vehicles().map((v) => {
			v.newCallSignId = v.callSignId;
			return v;
		});
	};

	fetchFireDep = async (fireDepId) => {
		this.fireDep = await store.model.FireDep.findById(fireDepId, {
			include: ['sentries', INCLUDE_PERSONNEL],
		});
	};

	fetchFireDepPersonnel = async () => {
		this.fireDepPersonnel = await store.model.ViewFireDepPersonnel.find({
			where: { fireDepId: this.fireDep.id },
			include: INCLUDE_VIEW_PERSONNEL,
			order: this.order,
		});
	};

	onQueryUpdate = async (query) => {
		const { order } = query;
		this.order = order;
		await this.fetchFireDepPersonnel();
		this.initShift();
	};

	savePersonnelAndVehicles = async (fireDepShift) => {
		let vehicles = [];
		for (const vehicle of this.vehicles) {
			const {
				id,
				stateNumber,
				brandId,
				statusId,
				typeId,
				cisternBulkId,
				pumpId,
				year,
				passport,
				actualFoamValue,
				newCallSignId,
				callSignId,
				fireDepId,
				protectiveSuits,
			} = vehicle;
			let newVehicle = new store.model.FireDepVehicle({
				stateNumber,
				brandId,
				statusId,
				typeId,
				cisternBulkId,
				year,
				passport,
				actualFoamValue,
				pumpId,
				callSignId: newCallSignId || callSignId,
				fireDepId,
				fireDepShiftId: fireDepShift.id,
			});
			await newVehicle.save();
			if (protectiveSuits().totalCount) {
				for (const suit of protectiveSuits()) {
					await newVehicle.protectiveSuits.create({ vehicleId: newVehicle.id, amount: suit.amount, suitId: suit.suitId });
				}
			}
			newVehicle.oldId = id;
			vehicles.push(newVehicle);
		}

		for (let p of this.personnel.filter((p) => p.selected === true)) {
			const { firstName, lastName, middleName, positionId, statusId, vehicleId, fireDepId, gasMaskId, id } = p;
			const newVehicleId = vehicles.find((v) => v.oldId === vehicleId)?.id;
			let newPersonnel = new store.model.FireDepPersonnel({
				firstName,
				lastName,
				middleName,
				positionId,
				statusId,
				fireDepId,
				gasMaskId,
				vehicleId: newVehicleId ?? vehicleId,
				fireDepShiftId: fireDepShift.id,
				originalId: id,
			});
			await newPersonnel.save();
		}
	};
}
