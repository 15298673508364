import React from 'react';
import { observer } from 'mobx-react';
import { ReportToolbar } from 'components';
import t from '@smartplatform/map/i18n';
import { Select } from '@smartplatform/ui';
import { ReportStore } from './store';
import { getYearArray } from 'client/tools';

const CODE = 'Avia2_1';

@observer
export class Avia2_1 extends React.Component {
	constructor(props) {
		super(props);
		this.store = new ReportStore(CODE);
	}

	render() {
		const { year, onChange } = this.store;

		return (
			<div>
				<div className='filters'>
					<div className='row'>
						<div className='col-3'>
							<div className='form-field'>
								<label>{t('fireReport.year')}</label>
								<Select noTotal isRequired items={getYearArray()} value={year} onChange={onChange('year')} />
							</div>
						</div>
					</div>
				</div>
				<div>
					<ReportToolbar {...this.store.storeParams} isHTML={true} disableGenerate={!year} />
				</div>
			</div>
		);
	}
}
