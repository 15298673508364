import React from 'react';
import { observer } from 'mobx-react';
import { Button, Popup, Select, Input } from '@smartplatform/ui';
import { editModeStore } from './store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { DOWN, UP } from 'client/constants';
import loadable from '@loadable/component';
import store from 'client/store';
import t from 'i18n';
import './style.scss';

const DictionariesPage = loadable(() => import('components/dictionaries/dictionaries-page'));

@observer
export class DictionariesWrapper extends React.Component {
	constructor(props) {
		super(props);
		this.init();
	}

	init = () => {
		const { defaultModels } = this.props;
		this.store = new editModeStore({ defaultModels });
	};

	render() {
		const { props } = this.props;
		const {
			editModeChange,
			isEditMode,
			resultModels,
			onEditModel,
			popup,
			onClosePopup,
			structureModels,
			onChangeGroup,
			save,
			isNewGroup,
			addGroup,
			onChangeGroupName,
			saveNewGroup,
			newGroupName,
			changeFieldPosition,
		} = this.store;

		return (
			structureModels && (
				<>
					<div className='dictionaries-header'>
						{store.model.ACL && store.model.ACL.INFO && store.model.ACL.INFO.WRITE && (
							<Button onClick={editModeChange} variant={isEditMode ? 'primary' : 'default'} className='dictionaries-header-button'>
								{t('dictionaries.editMode')}
							</Button>
						)}
					</div>
					{isEditMode ? (
						<div className='dictionaries-edit'>
							<h2 className='dictionaries-edit-head'>{t('edited')}</h2>
							<div className='dictionaries-new-group'>
								{!isNewGroup ? (
									<Button onClick={addGroup} variant='primary'>
										{t('dictionaries.addGroup')}
									</Button>
								) : (
									<>
										<Input placeholder={t('name')} onChange={onChangeGroupName} value={newGroupName} />
										<Button onClick={saveNewGroup} variant='default' disabled={!newGroupName}>
											{t('ok')}
										</Button>
									</>
								)}
							</div>

							<div className='dictionaries-edit-body'>
								<div className='dictionaries-edit-body-items'>
									{structureModels.groups.map((group) => {
										return (
											<div className='dictionaries-edit-body-wrapper' key={group.id}>
												<h3>{group.title}</h3>
												{structureModels.models
													.filter((model) => model.id === group.id)
													.sort((a, b) => (a.priority || 0) - (b.priority || 0))
													.map((model, index) => {
														return (
															<div className='dictionaries-edit-body-item' onClick={onEditModel(model)} key={index}>
																<span>{t(`${model.modelName[0].toLowerCase() + model.modelName.slice(1)}.plural`)}</span>

																<div className='dictionaries-edit-body-filter'>
																	<div className='order'>
																		<FontAwesomeIcon
																			icon={faChevronUp}
																			className={`order-btn ${index === 0 && 'order-btn-disabled'}`}
																			onClick={(e) => {
																				e.stopPropagation();
																				changeFieldPosition(model, UP);
																			}}
																		/>
																		<FontAwesomeIcon
																			icon={faChevronDown}
																			className={`order-btn ${
																				index === structureModels.models.filter((model) => model.id === group.id).length - 1 && 'order-btn-disabled'
																			}`}
																			onClick={(e) => {
																				e.stopPropagation();
																				changeFieldPosition(model, DOWN);
																			}}
																		/>
																	</div>
																</div>
															</div>
														);
													})}
											</div>
										);
									})}
								</div>
							</div>
						</div>
					) : (
						<DictionariesPage models={resultModels} {...props} search sortedFunc={(a, b) => (a.priority || 0) - (b.priority || 0)} isRangeFiltering />
					)}

					{popup && (
						<Popup onClose={onClosePopup} className='dictionaries-popup'>
							<h2>{t(`${popup.modelName[0].toLowerCase() + popup.modelName.slice(1)}.plural`)}</h2>
							<div className='dictionaries-popup-wrapper'>
								<Select
									items={structureModels.groups.map((group) => {
										return { label: group.title, value: group.id };
									})}
									value={popup.id}
									onChange={onChangeGroup(popup)}
									className='drop-down'
									isRequired
								/>
								<Button onClick={save} variant='primary' className=''>
									{t('save')}
								</Button>
							</div>
						</Popup>
					)}
				</>
			)
		);
	}
}
