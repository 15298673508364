import React from 'react';
import { observable } from 'mobx';

import store from 'client/store';

export default class FirePlanVisitStore {
	@observable id;
	@observable firePlanVisit = null;
	@observable vehicles = [];
	@observable isLoading = true;
	@observable changed = false;

	constructor(id) {
		this.id = id;
		this.init();
	}

	init = async () => {
		if (this.id) {
			this.firePlanVisit = await store.model.TechFireVisit.findById(this.id, {
				include: [{ relation: 'visitType' }, { relation: 'fireDep' }],
			});
			await this.updateVehicles();
		} else {
			this.firePlanVisit = new store.model.TechFireVisit();
		}
	};

	requiredFieldsFilled = (requiredFields) => {
		return requiredFields.every((item) => this.firePlanVisit[item] != null);
	};

	updateVehicles = async () => {
		const wayBills = await store.model.TechFireWayBill.find({
			where: { planVisitId: this.id },
			include: [{ relation: 'vehicles', scope: { fields: ['id'] } }],
		});
		const vehicles = [];
		for (let wayBill of wayBills) {
			vehicles.push(...wayBill.vehicles());
		}
		const allVehicles = await store.model.ViewFireDepVehicle.find({ where: { id: { inq: vehicles.map((v) => v.id) } } });
		this.vehicles = allVehicles;
	};

	saveFire = async () => {
		// try {
		// 	const obj = await store.model.TechFireAddress.findById(this.firePlanVisit.objectId);
		// 	if (obj) {
		// 		this.firePlanVisit.fireDepId = obj.fireDepId;
		// 	} else {
		// 		this.firePlanVisit.fireDepId = null;
		// 	}
		// } catch {

		// };
		await this.firePlanVisit.save();
		this.goBack();
	};

	saveUnitMovement = async () => {
		await this.firePlanVisit.save();
		this.goBack();
	};

	deleteFire = async () => {
		await this.firePlanVisit.delete();
		this.goBack();
	};

	goBack = () => store.route.push({ path: this.path });

	onChange = (prop) => (value) => {
		this.firePlanVisit[prop] = value;
	};

	onObjectChange = async (object) => {
		this.errors = [];
		this.firePlanVisit.object = object;
		this.firePlanVisit.fireDep = null;
		this.fireDeps = {};
		if (object?.address) {
			const fireDep = (await object.address.getFireDep())[0];
			if (fireDep) this.firePlanVisit.fireDep = fireDep;
		}
	};

	onObjectReset = () => {
		this.firePlanVisit.object = null;
	};
}
