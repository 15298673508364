import React from 'react';
import { inject, observer } from 'mobx-react';
import { NumberValue } from '@smartplatform/ui';
import AlertTriangleIcon from 'img/icons/fires/dashboard/alert-triangle.svg';
import { Card } from 'components';
import t from 'i18n';

const LOADING = <>&nbsp;</>;

@inject('dashboardStore')
@observer
export default class Totals extends React.Component {
	render() {
		const { dashboardStore } = this.props;

		const {
			totalCount,
			totalArea,
			totalIncrease,
			underwayCount,
			underwayArea,
			underwayIncrease,
			suspendedCount,
			suspendedArea,
			suspendedIncrease,
			unattendedCount,
			unattendedArea,
			unattendedIncrease,
		} = dashboardStore.data ? dashboardStore.data.totals || {} : {};

		return (
			<Card icon={<AlertTriangleIcon />} title={t('fireDashboard.active')} className='totals'>
				<div className='numbers-row mb-3'>
					<div className='number-block'>
						<label>{t('fireDashboard.total')}</label>
						{totalCount !== undefined ? <NumberValue value={totalCount} /> : LOADING}
					</div>
					<div className='number-block'>
						<label>{t('fireDashboard.totalArea')}</label>
						{totalArea !== undefined ? <NumberValue value={totalArea} fraction={1} /> : LOADING}
					</div>
					<div className='number-block'>
						<label>{t('fireDashboard.dailyIncrease')}</label>
						{totalIncrease !== undefined ? <NumberValue value={Math.max(totalIncrease, 0)} showSign fraction={1} /> : LOADING}
					</div>
				</div>
				<table className='dashboard-table'>
					<thead>
						<tr>
							<th />
							<th>{t('fireDashboard.fires')}</th>
							<th>{t('fireDashboard.area')}</th>
							<th>{t('fireDashboard.dailyIncrease')}</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td className='category'>{t('fireDashboard.underway')}</td>
							<td className='number'>{underwayCount !== undefined ? <NumberValue value={underwayCount} /> : LOADING}</td>
							<td className='number'>{underwayArea !== undefined ? <NumberValue value={underwayArea} fraction={1} /> : LOADING}</td>
							<td className='number'>
								{underwayIncrease !== undefined ? (
									<NumberValue
										value={Math.max(underwayIncrease, 0)}
										showSign
										fraction={underwayIncrease > 0 ? 1 : 0}
										className={underwayIncrease > 0 ? 'red' : null}
									/>
								) : (
									LOADING
								)}
							</td>
						</tr>
						<tr>
							<td className='category'>{t('fireDashboard.suspended')}</td>
							<td className='number'>{suspendedCount !== undefined ? <NumberValue value={suspendedCount} /> : LOADING}</td>
							<td className='number'>{suspendedArea !== undefined ? <NumberValue value={suspendedArea} fraction={1} /> : LOADING}</td>
							<td className='number'>
								{suspendedIncrease !== undefined ? (
									<NumberValue
										value={Math.max(suspendedIncrease, 0)}
										showSign
										fraction={suspendedIncrease > 0 ? 1 : 0}
										className={suspendedIncrease > 0 ? 'red' : null}
									/>
								) : (
									LOADING
								)}
							</td>
						</tr>
						<tr>
							<td className='category'>{t('fireDashboard.unattended')}</td>
							<td className='number'>{unattendedCount !== undefined ? <NumberValue value={unattendedCount} /> : LOADING}</td>
							<td className='number'>{unattendedArea !== undefined ? <NumberValue value={unattendedArea} fraction={1} /> : LOADING}</td>
							<td className='number'>
								{unattendedIncrease !== undefined ? (
									<NumberValue
										value={Math.max(unattendedIncrease, 0)}
										showSign
										fraction={unattendedIncrease > 0 ? 1 : 0}
										className={unattendedIncrease > 0 ? 'red' : null}
									/>
								) : (
									LOADING
								)}
							</td>
						</tr>
					</tbody>
				</table>
			</Card>
		);
	}
}
