export const MODEL_NAME = 'FireDep';

export const PERSONNEL_SCOPE = {
	include: [
		{ relation: 'section', scope: { fields: ['id', 'name'] } },
		{ relation: 'position', scope: { fields: ['id', 'name'] } },
		{ relation: 'rank', scope: { fields: ['id', 'name'] } },
		{
			relation: 'vehicle',
			scope: { ...VEHICLES_SCOPE },
		},
		{ relation: 'gasMask', scope: { fields: ['id', 'name'] } },
		{ relation: 'status', scope: { fields: ['id', 'name'] } },
		{ relation: 'chief', scope: { fields: ['id', 'orderNumber', 'endDate'] } },
	],
	order: 'lastName asc, firstName asc, middleName asc',
};

export const VEHICLES_SCOPE = {
	include: [
		{ relation: 'callSign', scope: { fields: ['id', 'name'] } },
		{ relation: 'brand', scope: { fields: ['id', 'name'] } },
		{ relation: 'type', scope: { fields: ['id', 'name'] } },
		{ relation: 'foamCapacity' },
		{ relation: 'cisternBulk' },
		{ relation: 'pump' },
		{ relation: 'status' },
		{ relation: 'hydraulicTools' },
	],
	order: 'stateNumber asc, passport asc',
};

export const FIRE_DEP_SCOPE = {
	include: [
		{ relation: 'county', scope: { scope: { fields: ['id', 'name'] } } },
		{ relation: 'city', scope: { scope: { fields: ['id', 'name'] } } },
		{ relation: 'sentries', scope: { scope: { fields: ['id', 'name'] } } },
	],
};
