import React from 'react';
import Root from './Root';
import store from 'client/store';
import ErrandsIcon from 'img/icons/menu/errands.svg';
import DashboardIcon from 'img/icons/sidebar/shared/dashboard.svg';
import AdminIcon from 'img/icons/sidebar/shared/admin.svg';
import t from 'i18n';

export const path = '/errands';

export default {
	name: t('module.errands'),
	code: 'errands',
	icon: <ErrandsIcon />,
	path: '/errands',
	component: Root,
	access: () => store.model.Errand && store.model.Errand.INFO.READ,
	menu: [
		{ title: t('dashboard'), path, icon: <DashboardIcon />, exact: true },
		{ title: t('admin.titleShort'), path: `${path}/admin`, icon: <AdminIcon /> },
		// { title: 'Синхро-низация', path: '/errands/sync', icon: <DashboardIcon />, exact: true },
	],
};
