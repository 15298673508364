import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationArrow, faHome } from '@fortawesome/free-solid-svg-icons';

import { Button, Popover } from '@smartplatform/ui';
import { AddressSearch, TechFireAddressSearch } from 'components';
import AddressTip from './AddressTip';
import { shortenAddressName } from 'client/tools';
import AddressToolStore from './store';
import t from 'i18n';
import './style.scss';
import store from 'client/store';
import { getPrefixByCountyName } from 'client/tools/getPrefixByCountyName';

@observer
export default class AddressTools extends React.Component {
	constructor(props) {
		super(props);
		this.store = new AddressToolStore();
	}

	searchOtherAddress = (e) => {
		e.stopPropagation();
		console.log('searchOtherAddress');
		this.store.address = null;
		this.store.showPopup = false;
	};

	renderValue = (address) => {
		const otherAddressBtn = (
			<div className='other-address-btn' onClick={this.searchOtherAddress}>
				{t('techFireAddress.searchOtherAddress')}
			</div>
		);

		return (
			<>
				<FontAwesomeIcon icon={faHome} />
				<div>{shortenAddressName(address.name)}</div>
				<div className='techfire-address-value'>{otherAddressBtn}</div>
			</>
		);
	};

	render() {
		const { address, showPopup, onAddressSelect, onPopupClose, pickPoint, getSearchInstance } = this.store;
		return (
			<div className='address-map-tools'>
				<AddressSearch
					value={address}
					onChange={onAddressSelect}
					position='topLeft'
					getInstance={getSearchInstance}
					noImmediateSearch={!!address}
					onValueClick={onAddressSelect}
					renderValue={this.renderValue}
					matchWidth
					prefix={getPrefixByCountyName(store.userCounty?.name)}
				/>
				<Popover content={t('techFireAddress.pickPoint')}>
					<Button variant='primary' onClick={pickPoint}>
						<FontAwesomeIcon icon={faLocationArrow} />
					</Button>
				</Popover>
				{address && showPopup && <AddressTip address={address} onClose={onPopupClose} />}
			</div>
		);
	}
}
