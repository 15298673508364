import React from 'react';
import { observer, inject } from 'mobx-react';
import TruckIcon from 'img/icons/techfires/card/truck.svg';
import { FieldBasedCard } from '../../../../components';

@inject('fireStore')
@observer
class Evacuated extends React.Component {
	handleStatusChange = (areFieldsFilled) => {
		this.props.fireStore.updateFieldStatuses('evacuated', areFieldsFilled);
	};

	render() {
		const { fireStore } = this.props;
		const { fire } = fireStore;

		const rows = [
			[
				{ property: 'savedPeople', label: 'techFire.savedPeople', type: 'NumberInput' },
				{ property: 'savedAdult', label: 'techFire.savedAdult', type: 'NumberInput' },
				{ property: 'savedChildren', label: 'techFire.savedChildren', type: 'NumberInput' },
			],
		];

		return (
			<FieldBasedCard
				title='techFire.evacuated'
				icon={TruckIcon}
				fields={[fire.savedPeople, fire.savedAdult, fire.savedChildren]}
				rows={rows}
				statusKey='evacuated'
				fireStore={fireStore}
				isCard={true}
			/>
		);
	}
}

export default Evacuated;
