import React from 'react';
import { MembersAvatars } from 'delivery/components';
import { AVATAR_SIZE } from 'client/constants';
import { replaceDecimal } from 'client/tools';

export const renderStatus = (status) =>
	status && (
		<>
			<span className='status-color' style={{ background: status?.color || '#808080' }} /> {status.name}
		</>
	);

export const renderUser = ({ user, full = false, size = AVATAR_SIZE }) => {
	return <MembersAvatars members={[user]} size={size} avatarsCount={1} full={full} />;
};
export const getTopLevelCategory = (category, field = 'name') => {
	return category.parent ? getTopLevelCategory(category.parent, field) : category[field];
};

export const renderCategories = (supplies) => {
	const categories = new Set(supplies.filter(({ category }) => category).map(({ category }) => getTopLevelCategory(category, 'shortName')));
	return Array.from(categories).map((category, i) => (
		<div key={i} style={{ display: 'inline-block', backgroundColor: '#E7E7ED', borderRadius: 16, padding: '4px 8px', marginRight: 4 }}>
			{category}
		</div>
	));
};
