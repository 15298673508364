import React from 'react';
import { observer, Provider } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Tabs, Tab } from '@smartplatform/ui';
import { Main } from './main';
import { Actionbar } from './actionbar';
import { Raids } from './raids';
import { Sidebar } from './sidebar';
import { Dynamic } from './dynamic';
import { Resources } from './resources';
import FireCardStore from './store';
import t from 'i18n';
import './style.scss';
import classNames from 'classnames';

@withRouter
@observer
export default class FireCard extends React.Component {
	constructor(props) {
		super(props);
		this.card = new FireCardStore(this.props.match.params.id);
	}

	componentDidMount() {
		if (!this.card.id) this.card.checkParams();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.match.params.id !== this.props.match.params.id) {
			this.card.id = this.props.match.params.id;
			this.card.loadFire();
		}
	}

	render() {
		const { card } = this;
		const { isLoading, error } = card;
		const path = this.props.match.url;
		if (isLoading) return null;
		if (error) return <div className='error'>{error}</div>;

		const tabs = [
			{ title: t('fire.mainInfo'), path, render: () => <Main />, exact: true },
			{ title: t('fire.dynamic'), path: `${path}/dynamic`, render: () => <Dynamic /> },
			{ title: t('fire.resources'), path: `${path}/resources`, render: () => <Resources /> },
			{ title: t('airRaid.plural'), path: `${path}/raids`, render: () => <Raids /> },
		];

		const tabsClassName = classNames({ hidden: card.mapMaximized });
		return (
			<Provider card={card}>
				<div className='fire-card-page'>
					{card.fire.id ? (
						<>
							<Sidebar />
							<Tabs before={<Actionbar />} className={tabsClassName}>
								{tabs.map((tab, index) => (
									<Tab {...tab} key={index} />
								))}
							</Tabs>
						</>
					) : (
						<div className='new-fire'>
							<h2>{t('fire.new')}</h2>
							<Actionbar />
							<Main />
						</div>
					)}
				</div>
			</Provider>
		);
	}
}
