import React from 'react';
import { inject, observer } from 'mobx-react';
import { FireAct } from './FireAct';
import { Dates } from './Dates';
import { Meteo } from './Meteo';
import { Numbering } from './Numbering';
import { Description } from './Description';
import { MainInfo } from './MainInfo';
import { Characteristics } from './Characteristics';
import { Costs } from './Costs';
import { Location } from './Location';
import './style.scss';
@inject('card')
@observer
export class Main extends React.Component {
	render() {
		return (
			<div className='card-form'>
				<div className='left'>
					<Dates />
					<MainInfo />
					<Characteristics />
					<Costs />
				</div>
				<div className='right'>
					<Location />
					{this.props.card.fire.fireId && <Numbering />}
					<FireAct />
					<Meteo />
					<Description />
				</div>
			</div>
		);
	}
}
