import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { FilterLayout } from '..';
import { DatePicker } from "@smartplatform/ui";

import store from 'client/store';
import t from 'i18n';

@observer
export default class IsdmFilter extends React.Component {

	static propTypes = {
		layer: PropTypes.object.isRequired,
		onChange: PropTypes.func,
		isLoading: PropTypes.bool,
		error: PropTypes.any,
	};

	constructor(props) {
		super(props);
		this.settings = props.settings;
	}
	onStartDateChange = (date) => {
		const { store: storeName } = this.props;
		store.local[storeName].layers.isdm.startDate = date;
		store.local.save();
		this.props.layer.load();
	};

	onEndDateChange = (date) => {
		const { store: storeName } = this.props;
		store.local[storeName].layers.isdm.endDate = date;
		store.local.save();
		this.props.layer.load();
	};

	render() {
		const { layer, isLoading, error, store: storeName } = this.props;
		const layers = store.local[storeName].layers;

		return <FilterLayout title={t('isdm.title')} {...{ layer, isLoading, error }}>
			<div className="mb-2 heat-points-period">
				<label>{t('heatPoint.period')}</label>
				<DatePicker onChange={this.onStartDateChange} value={layers.isdm.startDate} className="start"/>
				<DatePicker onChange={this.onEndDateChange} value={layers.isdm.endDate} className="start"/>
			</div>
		</FilterLayout>;
	}

}
