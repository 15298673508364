import React from 'react';
import { ReportStore } from '../store';
import t from 'i18n';
import { ReportToolbar } from '../../../../components';
import { observer } from 'mobx-react';

const CODE = 'JournalOvernightStay';

@observer
export class JournalOvernightStay extends React.Component {
	constructor(props) {
		super(props);
		this.store = new ReportStore(CODE, t('techFireReport.journalOvernightStayPeople'));
	}
	render() {
		const { name, error } = this.store;
		return (
			<div className='journal-overnight-stay'>
				<h2 className='title'>{name}</h2>
				<ReportToolbar {...this.store.storeParams} />
				{error && <span className='error'>{error}</span>}
			</div>
		);
	}
}
