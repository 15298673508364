import { observable } from 'mobx';
import { endOfDay, endOfMonth, endOfToday, startOfDay, startOfMonth } from 'date-fns';
import store from 'client/store';

export class ReportStore {
	@observable startDate = startOfMonth(new Date());
	@observable endDate = endOfToday();
	@observable fireAreaAction = null;
	@observable error = null;
	@observable report = null;
	@observable isLoading;

	constructor(code, name, withInit = true) {
		this.code = code;
		this.name = name;
		withInit && this.init();
	}

	init = async () => {
		if (this.code) {
			const report = await store.model.FireReport.find({ where: { code: this.code } });
			this.report = report[0] ?? (await store.model.FireReport.create({ code: this.code, name: this.name }));
			this.report.params = this.report.params || {};
			if (!this.report.name && this.code) {
				this.report.name = this.name;
				this.report.save();
			}
		}
	};

	onStartDateChange = (value) => (this.startDate = value && startOfDay(value));
	onEndDateChange = (value) => (this.endDate = value && endOfDay(value));

	onChange = (prop) => (value) => (this[prop] = value);

	get storeParams() {
		const params = { reportCode: this.code };
		const reportParams = {};

		reportParams.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

		if (this.report?.params) {
			Object.entries(this.report.params).forEach(([key, value]) => {
				reportParams[key] = value;
			});
		}
		if (this.startDate) {
			reportParams.startDate = this.startDate;
			reportParams.startOfMonth = startOfMonth(this.startDate);
		}
		if (this.endDate) {
			reportParams.endDate = this.endDate;
			reportParams.endOfMonth = endOfMonth(this.endDate);
		}
		if (this.fireAreaAction) {
			reportParams.fireAreaAction = this.fireAreaAction;
		}
		if (Object.keys(reportParams).length) params.reportParams = reportParams;
		params.reportModel = 'FireReport';
		return params;
	}
}
