import React from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';

import { MapControlGroup, MapControlButton } from '@smartplatform/map/client';
import selectPlusIcon from 'client/img/select-plus.svg?url';
import selectMinusIcon from 'client/img/select-minus.svg?url';
import t from 'i18n';
import store from 'client/store';

@inject('card') @observer
export default class SelectControls extends React.Component {

	get disabled() {
		const { map } = this.props.card || {};
		if (!map || !map.isInitialized) return true;
		return !store.local.fires.card?.layers?.heatPoints?.show ||
			map.heatPointsLayer.isLoading || (map.mapStore && map.mapStore.picking);
	}

	onSelectAdd = () => {
		this.props.card.map.toggleSelectMode('add');
	};

	onSelectSubtract = () => {
		this.props.card.map.toggleSelectMode('subtract');
	};

	render() {
		if (!this.props.card.map.isInitialized) return null;
		const mode = this.props.card?.map?.selectMode;

		return <>
			<MapControlGroup>
				<MapControlButton onClick={this.onSelectAdd} hint={t('monMap.hpSelectAdd')} active={mode === 'add'} disabled={this.disabled}>
					<img src={selectPlusIcon} alt="" />
				</MapControlButton>
				<MapControlButton onClick={this.onSelectSubtract} hint={t('monMap.hpSelectSubtract')} active={mode === 'subtract'} disabled={this.disabled}>
					<img src={selectMinusIcon} alt="" />
				</MapControlButton>
			</MapControlGroup>
		</>;
	}

}
