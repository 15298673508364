import React from 'react';
import SearchIcon from 'img/icons/search-input.svg';
import t from 'i18n';

export const SearchIconInput = ({ onChange, value, style, placeholder = t('search') }) => (
	<div style={style} className='search-icon-input'>
		<input value={value} onChange={onChange} placeholder={placeholder} />
		<SearchIcon className='icon' />
	</div>
);
