import React from 'react';
import { Button, Popup } from '@smartplatform/ui';
import t from 'i18n';
import { SaveButton, CancelButton } from 'components';
import PropTypes from 'prop-types';
import { FormContext } from '@smartplatform/ui';
import store from 'client/store';
import './style.scss';

export class ChangedPopup extends React.Component {
	static contextType = FormContext;

	static propTypes = {
		onClose: PropTypes.func,
		onSave: PropTypes.func,
		onRevert: PropTypes.func,
		title: PropTypes.string,
	};

	onSaveChanges = async () => {
		if (this.context) {
			await this.context.form.save();
		}

		if (this.props.onSave) {
			await this.props.onSave();
		}

		this.props.onClose();
		this.changeRoute();
	};
	revertChanges = async () => {
		if (this.props.onRevert) {
			await this.props.onRevert();
		}

		this.props.onClose();
		this.changeRoute();
	};

	changeRoute = () => store.route.push({ path: this.props.newPath });

	render() {
		const { onSaveChanges, revertChanges, props } = this;
		const { title, onClose } = props;
		return (
			<Popup onClose={onClose} className='changed-popup'>
				<h2>{title}</h2>
				<div className='action'>
					<SaveButton onClick={onSaveChanges} />
					<Button variant='danger' onClick={revertChanges} children={t('notSave')} />
					<CancelButton onClick={onClose} />
				</div>
			</Popup>
		);
	}
}
