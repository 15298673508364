import {observable} from "mobx";

export default class ReportOnForestFiresInTheTerritoryStore {
	@observable date = null;
	@observable ownership = null;

	onDateChange = (date) => this.date = date;

	onOwnershipChange = (ownership) => this.ownership = ownership;

	get storeParams() {
		const { date, ownership } = this;
		return {
			reportCode: 'ReportOnForestFiresInTheTerritory',
			reportParams: { date, ownership, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone },
			isHTML: true,
		};
	}
}
