import React from 'react';
import classNames from 'classnames';
import { ALARM, SUCCESS } from './constants';
import { Button } from '@smartplatform/ui';
import store from 'client/store';
import PropTypes from 'prop-types';
import t from 'i18n';

export class Notification extends React.Component {
	static propTypes = {
		message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
		id: PropTypes.string.isRequired,
		type: PropTypes.string.isRequired,
		onClick: PropTypes.func,
		onConfirm: PropTypes.func,
		onReject: PropTypes.func,
		isAlarm: PropTypes.bool,
	};

	static defaultProps = {
		type: SUCCESS,
		isAlarm: false,
	};

	removeNotification = (callback) => {
		if (this.props.onClick) {
			this.props.onClick();
		} else {
			store.ui.notifications = store.ui.notifications.filter((i) => i.id !== this.props.id);
		}
		if (typeof callback === 'function') {
			callback();
		}
	};

	render() {
		const { message, type, onConfirm, onReject } = this.props;
		const { removeNotification } = this;
		const isAlarm = type === ALARM;
		const hasControls = !!onConfirm || !!onReject;

		const notificationProps = {
			className: classNames('notification', `notification-${type?.toLowerCase()}`, `notification-animation-${!hasControls ? 'basic' : 'no-fade-out'}`, {
				'default-cursor': hasControls,
			}),
			onClick: removeNotification,
		};

		if (!hasControls) {
			notificationProps.onAnimationEnd = removeNotification;
		}

		return (
			<div {...notificationProps}>
				<div className='notification-message-text'>{message}</div>
				{hasControls && (
					<div className='controls'>
						<Button className='open' onClick={() => removeNotification(onConfirm)}>
							{t('open')}
						</Button>
						<Button className='reject' onClick={() => removeNotification(onReject)}>
							{t('reject')}
						</Button>
					</div>
				)}
			</div>
		);
	}
}
