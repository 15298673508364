import React from 'react';
import { inject, observer } from 'mobx-react';
import { DatePicker, RecordSelect } from '@smartplatform/ui';

import store from 'client/store';
import t from 'i18n';

@inject('report')
@observer
export class Filters extends React.Component {
	render() {
		return (
			<div className='filters'>
				<div className='row'>
					<div className='col-3'>
						<div className='form-field'>
							<label>{t('aviaDep.title')}</label>
							<RecordSelect
								noTotal
								maxItems={10}
								itemsPerPage={200}
								model={store.model.AviaDep}
								value={this.props.report.aviaDep}
								onChange={this.props.report.onAviaDepChange}
								property='name'
								className='drop-down-md'
								filter={{ fields: ['id', 'name'] }}
							/>
						</div>
					</div>

					<div className='col-3'>
						<div className='form-field'>
							<label>{t('date1')}</label>
							<DatePicker showTimeSelect={false} showClearButton={false} value={this.props.report.startDate} onChange={this.props.report.onStartDateChange} />
						</div>
					</div>

					<div className='col-3'>
						<div className='form-field'>
							<label>{t('date2')}</label>
							<DatePicker showTimeSelect={false} showClearButton={false} value={this.props.report.endDate} onChange={this.props.report.onEndDateChange} />
						</div>
					</div>
				</div>
			</div>
		);
	}
}
