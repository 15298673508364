import React from 'react';
import { observer } from 'mobx-react';
import { Loader, Row } from '@smartplatform/ui';
import { TextField } from 'fires/components';
import t from 'i18n';
import store from 'client/store';
import { observable } from 'mobx';
import { renderStatus } from 'client/tools';
import './firePopup.scss';

@observer
export default class FirePopup extends React.Component {
	@observable fire;
	go = () => store.route.push({ path: `/fires/registry/${this.props.record.id}` });

	componentDidMount = async () => {
		this.fire = await store.model.Fire.findById(this.props.record.id, {
			include: [
				{ relation: 'status', scope: { fields: ['id', 'name', 'color'] } },
				{ relation: 'zone', scope: { fields: ['id', 'name', 'color'] } },
				{ relation: 'forestOwnership', scope: { fields: ['id', 'name'] } },
				{ relation: 'forestCategory', scope: { fields: ['id', 'name'] } },
				{ relation: 'county', scope: { fields: ['id', 'name'] } },
			],
			fields: ['id', 'statusId', 'forestryFireNumber', 'zoneId', 'forestCategoryId', 'ownershipId', 'countyId', 'forestOwnershipId'],
		});
	};

	renderRelationName = (rel) => this.fire?.[rel]?.name || '-';

	render() {
		const { noPopupLink, record } = this.props;
		const { fire, renderRelationName } = this;

		return (
			<div className='map-fire-alarm-object-popup'>
				<div className='title'>
					<h2>
						{t(`fire.title`)}:{' '}
						{noPopupLink ? (
							<span>#{record.id}</span>
						) : (
							<a style={{ cursor: 'pointer' }} onClick={this.go}>
								#{record.id}
							</a>
						)}
						{!this.fire && <Loader size={14} />}
					</h2>
				</div>
				<Row>
					<TextField label={t('fire.status')} value={renderStatus(fire?.status)} />
					<TextField label={t('fire.forestryFireNumber')} value={fire?.forestryFireNumber || '-'} />
				</Row>
				<Row>
					<TextField label={t('fire.zone')} value={renderRelationName('zone')} />
					<TextField label={t('fire.forestOwnership')} value={renderRelationName('forestOwnership')} />
				</Row>
				<Row>
					<TextField label={t('forestCategory.title')} value={renderRelationName('forestCategory')} />
					<TextField label={t('county.title')} value={renderRelationName('county')} />
				</Row>
			</div>
		);
	}
}
