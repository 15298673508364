import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { getStatusColor } from 'fires/helpers/';
import { Loader, Row } from '@smartplatform/ui';
import { formatDate } from 'client/tools';
import store from 'client/store';
import t from 'i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { TextField } from 'fires/components';
import { getPeopleValues, getVehiclesValues, calcSameRecordsValues } from 'fires/helpers';
import { PEOPLE_INCLUDE, VEHICLE_INCLUDE, AIR_JOBS_INCLUDE, VEHICLES_GROUPS } from 'fires/fire-card/constants';

const NAME_RELATIONS = ['zone', 'combustionType', 'combustionIntensity', 'combustionPattern', 'forestryPrecinct', 'aviaDep', 'forestry'].map((relation) => ({
	relation,
	scope: { fields: ['id', 'name'] },
}));
const INCLUDED = [
	...NAME_RELATIONS,
	{ relation: 'forestOwnership', scope: { fields: ['id', 'name', 'code'] } },
	{ relation: 'status', scope: { fields: ['id', 'name', 'color'] } },
	{ relation: 'cause', scope: { fields: ['id', 'shortName'] } },
	{ relation: 'logs', scope: { include: [PEOPLE_INCLUDE, VEHICLE_INCLUDE], fields: ['id', 'people', 'vehicles'] } },
	...AIR_JOBS_INCLUDE,
];

@observer
export default class FirePopup extends React.Component {
	@observable fire = null;
	@observable isLoading = true;
	@observable isShowMore = false;
	@observable airJobs = [];

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		if (this.props.record.id) {
			// const modelName = store.isPublic ? 'ViewFirePublic' : 'Fire';
			const modelName = 'ViewFire';
			this.fire = await store.model[modelName].findById(this.props.record.id, { include: INCLUDED });
		} else {
			this.fire = this.props.record;
		}

		const fireLogs = this.fire.logs();
		const people = getPeopleValues(fireLogs);
		const vehicles = getVehiclesValues(fireLogs);
		this.people = calcSameRecordsValues(people, 'crewTypeId', 'current');
		this.vehicles = calcSameRecordsValues(vehicles, 'typeId', 'current').filter((i) => VEHICLES_GROUPS.includes(i.typeCode));
		this.airJobs = this.fire.jobs();
		this.isLoading = false;
	};

	toggleIsShowMore = () => (this.isShowMore = !this.isShowMore);

	gotoFire = (e) => {
		e.preventDefault();
		store.route.push({ path: `/fires/registry/${this.fire.id}` });
	};

	renderStatus = (fire) => {
		const statusColor = getStatusColor(fire);
		return (
			<div>
				<span className='status-color' style={{ background: statusColor || '#808080' }} />
				<span>{fire.status?.code && t('fireStatus.codes.' + fire.status?.code)}</span>
			</div>
		);
	};

	renderAirJobs = () =>
		this.airJobs.length > 0
			? this.airJobs
					.map((airJob) => (airJob.duration ? `(${airJob.raid?.aircraft?.type?.name || ''} - ${airJob.duration})` : null))
					.filter(Boolean)
					.join(', ')
			: null;
	renderPeople = () =>
		this.people.length > 0
			? this.people
					.map((p) => (p.current ? `(${p.crewTypeShortName} - ${p.current})` : null))
					.filter(Boolean)
					.join(', ')
			: null;
	renderVehicles = () =>
		this.vehicles.length > 0
			? this.vehicles
					.map((v) => (v.current ? `(${v.typeShortName} - ${v.current})` : null))
					.filter(Boolean)
					.join(', ')
			: null;

	render() {
		if (this.isLoading)
			return (
				<div className='loading'>
					<Loader size={18} />
				</div>
			);

		const format = 'dd.MM.yyyy HH:mm';
		const [lat, lon] = this.fire.textGeo?.split(' ') || [null, null];

		return (
			<div className='fire'>
				<div className='main-info'>
					<div className='title'>
						<h2>
							{t('fire.short')}:{' '}
							<span className='id' onClick={this.gotoFire}>
								#{this.fire.fireNumber || ''}
							</span>
						</h2>
						{this.renderStatus(this.fire)}
					</div>
					<Row>
						<TextField label={t('latitude')} value={lat} />
						<TextField label={t('longitude')} value={lon} />
						<TextField label={t('fire.area')} value={this.fire.area?.toFixed(1)} />
						<TextField label={t('fire.edge')} value={this.fire.edge?.toFixed(1)} />
					</Row>
					<Row>
						<TextField label={t('aviaDep.title')} value={this.fire.aviaDep && this.fire.aviaDep.name.replace('авиаотделение', '')} />
						<TextField label={t('forestry.title')} value={this.fire.forestry?.name} />
						<TextField label={t('forestryPrecinct.short')} value={this.fire.forestryPrecinct?.name} />
						<TextField label={t('forestOwnership.short')} value={this.fire.forestOwnership?.name} />
					</Row>
					<Row>
						<TextField label={t('fire.zone')} value={this.fire.zone?.shortName} />
						<TextField label={t('fire.detectDate')} value={formatDate(this.fire.detectDate, format)} />
						<TextField label={t('fire.startDate')} value={formatDate(this.fire.startDate, format)} />
					</Row>
					{!this.isShowMore && <div className='underline'></div>}
				</div>
				{this.isShowMore && (
					<div className=''>
						<h2>{t('fire.popup.activity')}:</h2>
						<Row>
							<TextField label={t('fireFightingPeople.plural')} value={this.renderPeople()} />
							<TextField label={t('fireFightingVehicle.plural')} value={this.renderVehicles()} />
							<TextField label={t('airRaid.pluralLong')} value={this.renderAirJobs()} />
						</Row>
					</div>
				)}
				<div className='show-more'>
					<FontAwesomeIcon icon={this.isShowMore ? faAngleUp : faAngleDown} onClick={this.toggleIsShowMore} />
				</div>
			</div>
		);
	}
}
