import React from 'react';
import { Tab, Tabs } from '@smartplatform/ui';
import t from 'i18n';
import { TechFires } from './tech-fires';
import FiresPlanVisits from './plan-visits';
import Plans from './plans';
import ForestFiresMove from './forest-fires';
import { Redirect, Route } from 'react-router-dom';

export default ({ match }) => {
	const { path } = match;
	return (
		<>
			<Route path={path} exact>
				<Redirect to={`${path}/tech-fires`} />
			</Route>
			<Tabs>
				<Tab path={`${path}/tech-fires`} title={t('techFire.fires.title')} render={(props) => <TechFires {...props} />} />
				<Tab path={`${path}/forest-fires`} title={t('fire.plural')} render={(props) => <ForestFiresMove {...props} />} />
				<Tab path={`${path}/plan-visits`} title={t('techFire.firesPlanVisits.plural')} render={(props) => <FiresPlanVisits {...props} />} />
				<Tab path={`${path}/plans`} title={t('techFire.plans.plural')} render={(props) => <Plans {...props} />} />
			</Tabs>
		</>
	);
};
