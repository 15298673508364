import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import store from 'client/store';
import t from 'i18n';
import { Loader } from '@smartplatform/ui';
import { TextField } from 'fires/components';
import {formatDate} from "client/tools";

const MODEL_NAME = 'ViewPermafrostLevelingMark';

@observer
export default class PermafrostLevelingMarkPopup extends React.Component {
	@observable fetchedData = null;
	@observable isApproved = null;
	@observable mark = null;
	@observable date = null;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.fetchedData = await store.model[MODEL_NAME].findById(this.props.record.levelingmarkid);
		if(this.props.isDate && this.props.record.measurementsid) {
			const measurements = await store.model.PermafrostLevelingMarkMeasurements.findById(this.props.record.measurementsid)
			this.isApproved = measurements.isApproved
			this.mark = measurements.mark
			this.date = measurements.date
		} else {
			this.isApproved = this.fetchedData.isApproved
			this.mark = this.fetchedData.mark
			this.date = this.fetchedData.date
		}
	};

	go = (e) => {
		e.preventDefault();
		store.route.push({ path: `/permafrost/leveling-marks/${this.props.record.levelingmarkid}` });
	};

	render() {
		const { record, noPopupLink } = this.props;

		return (
			<div>
				<div>
					{!noPopupLink && (
						<h1>
							{t('permafrost.levelingMarks.title')}:{' '}
							<a style={{ cursor: 'pointer' }} onClick={this.go}>
								#{record.levelingmarkid}
							</a>
						</h1>
					)}
				</div>
				<div>
					{!this.fetchedData && <Loader size={18} />}
					{this.fetchedData && (
						<div className='d-flex'>
							<div>
								<TextField label={t('permafrost.number')} value={this.fetchedData.number || '-'} />
								<TextField label={t('permafrost.objects.name')} value={this.fetchedData.addressName || '-'} />
							</div>
							<div>
								<TextField label={t('permafrost.approved')} value={this.isApproved || '-'} />
								<TextField label={t('permafrost.mark')} value={this.mark || '-'} />
								<TextField label={t('date')} value={formatDate(this.date)} />
							</div>
						</div>
					)}
				</div>
			</div>
		);
	}
}
