import { observable } from "mobx";

export default class OperationalSituationInTerritoryStore {
	@observable date = null;
	@observable ownership = null;

	onDateChange = (date) => this.date = date;

	onOwnershipChange = (ownership) => this.ownership = ownership;

	get storeParams() {
		const { date, ownership } = this;
		return {
			reportCode: 'OperationalSituationInTerritory',
			reportParams: { date, ownership, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone },
			isHTML: true,
		};
	}
}
