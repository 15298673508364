import { Button, Checkbox, Input, Row, Select } from '@smartplatform/ui';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import store from 'client/store';
import { ERROR, SUCCESS, WARNING, DANGER } from 'client/constants';
import { is } from 'date-fns/locale';

const ITEMS = [ERROR, SUCCESS, WARNING, DANGER];
@observer
export class Notification extends React.Component {
	@observable status = 'ERROR';
	@observable text = 'Тестовый текст';
	@observable isAlarm = false;
	@observable isConfirm = false;

	onChange = (prop) => (value) => (this[prop] = value);
	render() {
		return (
			<div style={{ marginBottom: 20 }}>
				<h2>Оповещения</h2>
				<div className='d-flex'>
					<div className='form-field mr-2'>
						<label>Статус оповещения</label>
						<Select items={ITEMS.map((i) => ({ value: i, label: i }))} onChange={this.onChange('status')} value={this.status} width={200} />
					</div>
					<div className='form-field mr-2'>
						<label>Текст оповещения</label>
						<Input value={this.text} onChange={this.onChange('text')} width={200} />
					</div>
					<div className='form-field mr-2'>
						<label>Тревога</label>
						<Checkbox value={this.isAlarm} onChange={this.onChange('isAlarm')} width={200} />
					</div>
					<div className='form-field mr-2'>
						<label>С подтверждением</label>
						<Checkbox value={this.isConfirm} onChange={this.onChange('isConfirm')} width={200} />
					</div>
					<div className='form-field'>
						<label>/</label>
						<Button
							style={{ marginLeft: 20 }}
							variant='primary'
							onClick={() =>
								store.ui.setNotification({
									message: this.text,
									type: this.status,
									isAlarm: this.isAlarm,
									onConfirm: this.isConfirm ? () => alert('тест') : undefined,
								})
							}
							disabled={!this.text.length || !this.status}
						>
							Тест
						</Button>
					</div>
				</div>
			</div>
		);
	}
}
