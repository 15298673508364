import { observable } from 'mobx';

export default class RegisterEliminatedForestFiresStore {
	@observable aviaDep = null;
	@observable startDate = null;
	@observable endDate = null;

	onAviaDepChange = (aviaDep) => this.aviaDep = aviaDep;
	onStartDateChange = (startDate) => this.startDate = startDate;
	onEndDateChange = (endDate) => this.endDate = endDate;

	get storeParams() {
		return {
			reportCode: 'RegisterEliminatedForestFires',
			reportParams: {
				aviaDep: this.aviaDep,
				startDate: this.startDate,
				endDate: this.endDate, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
			},
			isHTML: true,
		};
	}
}
