import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import FilterLayout from '../FilterLayout';
import t from 'i18n';
import { Input } from '@smartplatform/ui';
import './IncidentFilter.scss';
@observer
export class IncidentFilter extends React.Component {
	static propTypes = {
		layer: PropTypes.object,
		onToggle: PropTypes.func,
	};

	render() {
		const { layer, isLoading, error } = this.props;
		const { search, onChangeSearch } = layer;

		return (
			<FilterLayout layer={layer} title={t('requestIncident.plural')} error={error} isLoading={isLoading} className='incident-filter'>
				<Input placeholder={t('requestIncident.searchPlaceholderMap')} value={search} onChange={onChangeSearch} />
			</FilterLayout>
		);
	}
}
