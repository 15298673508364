import { observable } from 'mobx';
import store from 'client/store';
import { LOCAL_STORAGE_FIELDS, SEARCH_FIELDS } from './constants';
import debounce from 'lodash/debounce';
import { LIST_INCLUDE } from './constants';

export default class MunicipalitiesStore {
	@observable search = '';
	@observable querySearch = '';
	@observable showFilters = false;
	@observable isLoading = true;
	@observable municipalityStatuses = [];
	@observable riskCategories = [];

	//list
	@observable order = 'id desc';
	@observable page = 1;
	@observable totalCount = 0;
	@observable perPage;

	path;

	constructor() {
		this.localStorageData = store.local.municipalities;
		this.doSearch = debounce(this.doSearch, 500, { leading: false, trailing: true });
	}

	init = async () => {
		this.loadLocalStorage();
		this.municipalityStatuses = await store.model.MunicipalSufficiencyStatus.find();
		this.riskCategories = await store.model.EmergencyRiskCategory.find({ fields: ['id', 'shortName', 'name'] });
		this.isLoading = false;
	};

	doSearch = () => (this.querySearch = this.search);

	onSearch = (e) => {
		this.page = 1;
		this.search = e.target.value;
		this.doSearch();
		this.saveToLocalStorage();
	};
	onChange = (prop) => (value) => {
		this.page = 1;
		this[prop] = value;
		this.saveToLocalStorage();
	};

	create = () => store.route.push({ path: `${this.path}/create` });

	saveToLocalStorage = () => {
		LOCAL_STORAGE_FIELDS.forEach((field) => (store.local.delivery.reserves[field] = this[field]));
		store.local.save();
	};

	loadLocalStorage = async () => {
		const keys = Object.keys(this.localStorageData) || [];
		keys.forEach((key) => {
			if (this.localStorageData[key]) {
				this[key] = this.localStorageData[key];
			}
		});
	};

	onQueryUpdate = async (query) => {
		if (query._totalCount) this.totalCount = query._totalCount;
		this.order = query.order;
	};

	get filter() {
		const { querySearch } = this;
		const filter = {
			where: { and: [] },
			order: this.order,
			limit: this.perPage,
			skip: (this.page - 1) * this.perPage,
			...LIST_INCLUDE,
		};
		// TODO searchFields
		if (querySearch.length) {
			const search = { or: [] };
			SEARCH_FIELDS.forEach((field) => search.or.push({ [field]: { ilike: `%${querySearch}%` } }));
			filter.where.and.push(search);
		}
		if (!filter.where.and.length) delete filter.where;
		return filter;
	}

	onRowClick = (record) => store.route.push({ path: `${this.path}/${record.id}` });
	onShowFiltersChange = () => {
		this.showFilters = !this.showFilters;
		this.saveToLocalStorage();
	};
}
