import React from 'react';
import {observer, Provider} from 'mobx-react';
import { observable } from 'mobx';
import {Column, Pager, Table} from '@smartplatform/ui';
import { fio } from 'client/tools';
import {ListStore, Toolbar} from 'components';
import store from 'client/store';

import t from 'i18n';

@observer
export default class Calls extends React.Component {
	constructor(props) {
		super(props);
    this.store = new ListStore({ path: props.path, fields: {}, model: store.model['FireDepPersonnel']});
		this.updateFilter();
	}

  componentDidMount() {
		this.store.setPerPage();
    this.updateFilter();
	}

	updateFilter = () => {
		const filter = {};
		filter.where = { and: [] };
		filter.where.and.push({ responsibleForCalling: true });
		this.filter = filter;
		this.store.updateFilters(this.filter);
	};

  editPerson = (record) => {
		const { params } = this.props.match;
		store.route.push({ path: `/techfires/calls/${record.id}` });
	};

	render() {
		const { tableProps, totalCount, perPage, page, onChange, search, onSearch } = this.store;

		return (
      <>
        <h1>{t('techFire.calls.title')}</h1>
        <Toolbar>
          <Toolbar.SearchIconInput value={search} onChange={onSearch} />
        </Toolbar>
        <Pager current={page} onChange={onChange('page')} totalCount={totalCount || 0} itemsPerPage={perPage} />
				<Table {...tableProps} onRowClick={this.editPerson}>
					<Column property='id' label={t('id')} width={80} />
					<Column property='responsibleForCalling' label={t('fireDepPersonnel.responsibleForCalling')} width={80} />
					<Column computed={fio} label={t('person.fullName')} width={200} />
					<Column relation='position' property='name' label={t('fireDepPosition.title')} width={130} />
					<Column property='phone' label={t('person.phone')} width={120} />
					<Column relation='techFireRank' property='name' label={t('techFire.calls.rank')} width={120} />
				</Table>
      </>
		);
	}
}