import React from 'react';
import { observer } from 'mobx-react';
import { Toolbar, ListStore } from 'components';
import store from 'client/store';
import t from 'i18n';
import { Column, Table, Pager } from '@smartplatform/ui';
import { observable } from 'mobx';
import GarrisonSelect from 'techfires/components/garrison-select/GarrisonSelect';

@observer
export class PlanList extends React.Component {
	@observable garrison = null;
	constructor(props) {
		super(props);
		this.store = new ListStore({ path: props.path, model: store.model.TechFirePlan, filters: {} });
	}

	componentDidMount() {
		this.store.setPerPage();
	}

	onGarrisonChange = async (value) => {
		this.garrison = value;
		const where = {};
		if (value) where.garrisonId = value.id;
		this.store.updateFilters({ where: { ...where } });
	};

	render() {
		const { tableProps, totalCount, perPage, page, onChange, create } = this.store;

		return (
			<>
				<Toolbar>
					<Toolbar.AddButton onClick={create} />
					<GarrisonSelect value={this.garrison} onChange={this.onGarrisonChange} width={200} />
				</Toolbar>
				<Pager current={page} onChange={onChange('page')} totalCount={totalCount || 0} itemsPerPage={perPage} />
				<Table {...tableProps}>
					<Column property='id' label={t('id')} width={80} />
					<Column property='year' label={t('techFire.plans.year')} />
					<Column relation='garrison' property='name' label={t('garrison')} />
					<Column relation='planVisitType' property='name' label={t('techFire.plans.visitType')} />
					{/* <Column relation='planVisitSubType' property='name' label={t('techFire.plans.visitSubType')} /> */}
					<Column property='amount' label={t('techFire.plans.amount')} />
				</Table>
			</>
		);
	}
}
