import covid from './covid';
import appeals from './appeals';
import errands from './errands';
import fires from './fires';
import delivery from './delivery';
import emergency from './emergency';
import techfires from './techfires';
import permafrost from './permafrost';

const modules = {
	covid,
	appeals,
	errands,
	fires,
	delivery,
	emergency,
	techfires,
	permafrost
};

export default modules;
