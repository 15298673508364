import React from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Column, Table } from '@smartplatform/ui';
import t from 'i18n';
import { RowAddButton } from 'fires/components';
import store from 'client/store';
import EditPeoplePopup from './EditPeoplePopup';
import { getPeopleValues } from 'fires/helpers';
import { PEOPLE_INCLUDE } from '../../constants';
import ShieldTickIcon from 'img/icons/fires/card/shield-tick.svg';
import { Card } from 'components';
import { PopconfirmFaTimesIcon } from 'components';
import './style.scss';

@inject('card')
@observer
export class People extends React.Component {
	@observable showPeoplePopup = false;
	@observable records = [];

	constructor(props) {
		super(props);
		this.card = this.props.card;
		this.fire = this.card.fire;
		this.fireLogId = this.fire.id;
		this.prevLogs = this.card.fireLogs.filter((i) => new Date(i.date) < new Date(this.fire.date));
		this.currentPeople = this.fire.people();
		this.records = this.getRecords();
	}

	fetchRecords = async () => {
		//* сфетчим снова людей и поменяем пожару кэш в реляции
		this.currentPeople = await store.model.FireFightingPeople.find({ where: { fireLogId: this.fireLogId }, include: PEOPLE_INCLUDE.scope.include });
		this.fire._people._cache = this.currentPeople;
		this.records = this.getRecords();
	};

	onClosePopup = () => this.togglePopup(false);

	togglePopup = async (boolean) => {
		this.showPeoplePopup = boolean;
		if (!boolean) {
			this.selectedRecord = null;
			this.fetchRecords();
		}
	};

	onRowClick = (row) => {
		row.fireLogId = this.fireLogId;
		this.selectedRecord = row;
		this.togglePopup(true);
	};

	showEditPopup = async () => {
		this.selectedRecord = new store.model.FireFightingPeople({ fireLogId: this.fireLogId });
		this.togglePopup(true);
	};

	renderFormation = (record) => record.formationShortName || record.formation?.shortName;
	renderCrewType = (record) => record.crewTypeShortName || record.crewType?.shortName;
	renderCurrent = (record) => (+record.current || 0) + (+record.delivered || 0);
	renderAction = (record) => (record.id && !this.card.disabled ? <PopconfirmFaTimesIcon onConfirm={() => this.onDelete(record)} /> : ' ');

	onDelete = async (record) => {
		await record.delete();
		this.fetchRecords();
	};

	getRecords() {
		const prev = getPeopleValues(this.prevLogs);
		//* найдем записи, которые есть в предыдущих логах, изменим их, добавим записи из текущей, если совпадения нет
		this.currentPeople.forEach((currentLog) => {
			const sameLog = prev.find((prev) => prev.crewTypeId === currentLog.crewTypeId && prev.formationId === currentLog.formationId);
			if (sameLog) {
				const { crewTypeId, formationId, delivered, ...properties } = store.model.FireFightingPeople.PROPERTIES;
				sameLog.delivered = (+sameLog.delivered || 0) + currentLog.delivered;
				Object.keys(properties).forEach((prop) => (sameLog[prop] = currentLog[prop]));
			} else {
				prev.push(currentLog);
			}
		});

		return prev.map((i) => new store.model.FireFightingPeople(i));
	}

	renderStylesProp = (prop, className) => (record) => !!record[prop] ? <span className={className}>{record[prop]}</span> : '-';

	render() {
		const { disabled } = this.card;
		const {
			records,
			renderCrewType,
			renderFormation,
			onRowClick,
			renderStylesProp,
			renderAction,
			showEditPopup,
			onClosePopup,
			renderCurrent,
			selectedRecord,
			showPeoplePopup,
		} = this;
		return (
			<Card title={t('fireFightingPeople.plural')} icon={<ShieldTickIcon />} key={this.props.card.fire.id}>
				{showPeoplePopup && <EditPeoplePopup record={selectedRecord} onClose={onClosePopup} records={records} />}
				{!!records.length && (
					<Table rows={records} onRowClick={onRowClick} clickable={!disabled}>
						<Column computed={renderCrewType} label={t('crewType.title_short')} className='text-center' />
						<Column computed={renderFormation} label={t('fireFightingFormation.title_short')} className='text-center' />
						<Column width={70} computed={renderStylesProp('delivered', 'increment')} label={t('fireFightingPeople.delivered')} className='text-center' />
						<Column width={70} computed={renderStylesProp('air', 'increment')} label={t('fireFightingPeople.air_short')} className='text-center' />
						<Column width={70} computed={renderCurrent} label={t('fireFightingPeople.current_short')} className='text-center' />
						<Column width={70} property='required' label={t('fireFightingPeople.required')} className='text-center' />
						<Column width={70} property='plan' label={t('fireFightingPeople.plan')} className='text-center' />
						<Column
							width={70}
							computed={this.renderStylesProp('evacuated', 'increment')}
							label={t('fireFightingPeople.evacuated_short')}
							className='text-center'
						/>
						<Column width={25} computed={renderAction} clickable={false} />
					</Table>
				)}
				<RowAddButton onClick={showEditPopup} disabled={disabled} />
			</Card>
		);
	}
}
