import React from 'react';
import { observer } from 'mobx-react';

import { AddressInfo } from 'components';

@observer
export default class AddressPopup extends React.Component {
	
	constructor(props) {
		super(props);
	}
	
	componentDidMount() {
		document.addEventListener('mousedown', this.onMouseDown);
	}
	
	componentWillUnmount() {
		document.removeEventListener('mousedown', this.onMouseDown);
	}
	
	onMount = (el) => this.el = el;
	
	onMouseDown = (e) => {
		if (this.el && !this.el.contains(e.target)) {
			this.props.onClose && this.props.onClose();
		}
	};
	
	render() {
		const { address, onClose } = this.props;

		return <div className="address-map-tip" ref={this.onMount}>
			<AddressInfo address={address} onClose={onClose}/>
		</div>;
	}
}
