import React from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';

import { Tabs, Tab } from '@smartplatform/ui';
import store from 'client/store';
import Personnel from './tabs/Personnel';
import Vehicles from './tabs/Vehicles';
import t from 'i18n';
import { INCLUDE_PERSONNEL, INCLUDE_VEHICLES } from '../../constants';

@inject('store')
@observer
export default class EditPersonnelAndVehicles extends React.Component {
	@observable sentry = null;
	@observable tab = 'personnel';

	constructor(props) {
		super(props);
		this.store = props.store;
		this.init();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.sentry.id !== prevProps.sentry.id) {
			this.sentry = null;
			this.init();
		}
	}

	init = async () => {
		this.sentry = await store.model.FireDepSentry.findById(this.props.sentry.id, {
			include: [
				{ relation: 'globalSentry', scope: { fields: ['id', 'name'] } },
				{ relation: 'fireDep', scope: { include: [INCLUDE_PERSONNEL] } },
				INCLUDE_PERSONNEL,
				INCLUDE_VEHICLES,
			],
		});
	};

	setTab = (tab) => {
		if (tab === 'vehicles') {
			this.store.updateVehicles();
		}
		this.tab = tab;
	};

	render() {
		if (!this.sentry) return null;
		const { reserve } = this.props;

		return (
			<div>
				<Tabs type='manual'>
					<Tab title={t('fireDep.personnel')} initial onClick={() => this.setTab('personnel')} />
					<Tab title={t('fireDep.vehicles')} onClick={() => this.setTab('vehicles')} />
				</Tabs>
				{this.tab === 'vehicles' ? <Vehicles record={this.sentry} edit={true} /> : <Personnel record={this.sentry} edit={true} reserve={reserve} />}
			</div>
		);
	}
}
