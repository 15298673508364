import t from 'i18n';

export const DICTIONARIES_MODELS = [
	{
		title: t('requestIncident.plural'),
		type: 'submenu',
		items: [{ modelName: 'RequestIncidentType' }, { modelName: 'RequestIncidentStatus' }, { modelName: 'RequestIncidentLevel' }],
	},
	{ modelName: 'RequestMemberStatus' },
	{ modelName: 'RequestTrackStatus' },
	{
		modelName: 'Storehouse',
		listRelations: [
			{ relation: 'county', property: 'name' },
			{ relation: 'measure', property: 'name' },
		],
	},
	{ modelName: 'RequestVehicleType' },
	{ modelName: 'RequestType' },
	{ modelName: 'RegionLevel' },
	{ modelName: 'RequestStatus' },
	{ modelName: 'SupplyMeasure' },
	{
		modelName: 'SupplyCategory',
		listRelations: [{ relation: 'parent', property: 'name' }],
		editRelations: [{ relation: 'parent', property: 'name', filter: { where: { parentId: null } } }],
	},
	{ modelName: 'SupplyNomenclature' },
	{ modelName: 'RequestPeriod' },
	{ modelName: 'River' },
	{ modelName: 'RequestPlan', listRelations: [{ relation: 'supplies', property: 'name' }] },
	{ modelName: 'Direction' },
	{ modelName: 'RequestAvailablePeriod' },
];

export const REQUEST_CODE = 'BASIC';
export const PLAN_CODE = 'SCHEDULE';

export const TRACK_STATUSES = {
	SHIPPED: 'SHIPPED',
	DELIVERED: 'DELIVERED',
};
