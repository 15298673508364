import { observable } from "mobx";

export default class InformationAboutForestFiresForDateStore {
	@observable date = null;
	@observable county = null;

	onDateChange = (date) => this.date = date;

	onCountyChange = (county) => this.county = county;

	get storeParams() {
		const { date, county } = this;
		return {
			reportCode: 'InformationAboutForestFiresForDate',
			reportParams: { date, county, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone },
			isHTML: true,
		};
	}
}
