import React from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { Column, Table, Pager } from '@smartplatform/ui';
import store from 'client/store';
import { Filters } from '../filters';
import { shortenAddressName } from 'client/tools';
import t from 'i18n';
import addressStore from '../store';

@inject('addressStore')
@observer
export class AddressList extends React.Component {
	constructor(props) {
		super(props);
		this.store = new addressStore();
		this.store.init();
		this.store.order = 'objectsCount desc';
	}

	shortAddress = (record) => shortenAddressName(record.name);

	onRowClick = (record) => store.route.push({ path: `/techfires/addresses/${record.id}` });

	render() {
		const { getFilters, onQueryUpdate, totalCount, onChange, page } = this.store;
		const filters = getFilters();

		return (
			<div className='address-list'>
				<Filters noAdditionalFilters store={this.store} />
				<Pager current={page} onChange={onChange('page')} totalCount={totalCount || 0} />
				<Table query={filters} onQueryUpdate={onQueryUpdate} model={store.model.ViewTechFireAddress} onRowClick={this.onRowClick}>
					<Column property='name' computed={this.shortAddress} label={t('name')} />
					<Column property='objectsCount' label={t('techFireAddress.objects')} />
				</Table>
			</div>
		);
	}
}
