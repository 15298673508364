import React from 'react';
import CustomTechFireCard from '../../../../components/custom-techfire-card/CustomTechFireCard';
import HomeIcon from 'img/icons/techfires/card/home.svg';

export default class AddInfo extends React.Component {
	render() {
		const rows = [
			[
				{ property: 'name', label: 'techFireObjectType.title', type: 'relation', relation: 'techFireObjectType', props: { empty: 'Прочие' } },
				{ property: 'empty', type: 'default' },
			],
			[
				{ property: 'destroyedBuildingsCompletely', label: 'techFire.destroyedBuildingsCompletely', type: 'NumberInput' },
				{ property: 'area', label: 'techFire.area', type: 'NumberInput' },
				{ property: 'destroyedVehicles', label: 'techFire.destroyVeh', type: 'NumberInput' },
				{ property: 'destroyedLivestock', label: 'techFire.destroyAnimals', type: 'NumberInput' },
			],
			[
				{ property: 'destroyedBuildings', label: 'techFire.destroyedBuildings', type: 'NumberInput' },
				{ property: 'damaged', label: 'techFire.damaged', type: 'NumberInput' },
				{ property: 'empty', type: 'default' },
				{ property: 'empty', type: 'default' },
			],
			[
				{ property: 'savedVehicles', label: 'techFire.savedVehicles', type: 'NumberInput' },
				{ property: 'savedMaterials', label: 'techFire.savedMaterials', type: 'NumberInput' },
				{ property: 'savedValues', label: 'techFire.savedValues', type: 'NumberInput' },
				{ property: 'savedBuildings', label: 'techFire.savedBuildings', type: 'NumberInput' },
			],
		];
		return <CustomTechFireCard title='techFire.addInfo' icon={HomeIcon} rows={rows} />;
	}
}
