import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { observer } from 'mobx-react';
import List from '../List';
import Shift from '../Shift';
import FireDepShift from '../../single/FireDepShift';
import { isRadiocallpch } from 'techfires/shifts/constants';

@observer
export default class FireDepShifts extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const isRadiocallpchRole = isRadiocallpch();
		return (
			<div>
				<Switch>
					<Route path='/techfires/shifts' exact render={(props) => <List {...props} />} />
					<Route path='/techfires/shifts/:id' exact render={(props) => <Shift isMain={isRadiocallpchRole} {...props} />} />
					<Route path='/techfires/shifts/:id/firedep/:fireDepId' render={(props) => <FireDepShift {...props} />} />
				</Switch>
				{/*<pre>{JSON.stringify(this.props, null, 2)}</pre>*/}
			</div>
		);
	}
}
