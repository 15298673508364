import { observable } from "mobx";
import { endOfMonth, startOfMonth } from 'date-fns';
import t from 'i18n';

export const REPORT_TYPES = [
	{ label: t('report.summary'), value: 'summary' },
	{ label: t('report.detailed'), value: 'detailed' },
];


export default class EliminatedFiresFoundInTheTotalAreaStore {
	@observable reportType = REPORT_TYPES[0].value;
	@observable year = new Date().getFullYear();
	@observable month = new Date().getMonth() + 1;
	@observable ownership = null;


	onMonthChange = (month) => (this.month = month);
	onYearChange = (year) => (this.year = year);
	onReportTypeChange = (type) => (this.reportType = type);
	onOwnershipChange = (ownership) => (this.ownership = ownership);

	get storeParams() {

		const { year, month, reportType, ownership  } = this;
		const date = new Date(year, month - 1, 1);

		const startDate = startOfMonth(date);
		const endDate = endOfMonth(date);
		return {
			reportCode: 'EliminatedFiresFoundInTheTotalArea',
			reportParams: { year, month, reportType, startDate, endDate, ownership, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone },
			isHTML: true,
		};
	}
}
