export const MENU_ITEMS = [
	{
		title: 'Формы',
		type: 'submenu',
		items: [
			'pivotTableForm',
			'depositnew',
			'formByUlus',
			'riverInformationForm',
			'formForTheHeadAndGovernment',
			'dailyInformationFormFor',
			'districtsEnterprisesRivers',
			'arcticUlusForm',

			// 'indicators',
			// 'ratioTo',
			// 'forReference',
			// 'codeForAnalysis',
			// 'rivers',
			// 'year',
			// 'yana',
			// 'depositnew',
			// 'stsgRivers',
			// 'oil',
			// 'terCustomers',
		],
	},
];
