import React from 'react';
import { observer } from 'mobx-react';
import { Button, Checkbox, Column, DatePicker, Input, Popconfirm, Row, Table } from '@smartplatform/ui';
import store from 'client/store';
import { observable } from 'mobx';
import t from 'i18n';
import AddIcon from 'img/add-btn.svg';
import EditIcon from 'img/icons/shared/edit.svg';
import DeleteIcon from 'img/icons/shared/delete.svg';
import { TUBES_INCLUDE } from '../../constants';
import { fio, formatDate } from 'client/tools';
import {Attachments, LabelField, ReportToolbar} from 'components';

const FIRST_DEPTH_COLUMN = 2;

@observer
export default class Edit extends React.Component {
	@observable record = null;
	@observable isLoading = true;
	@observable depths = [];
	@observable measurements = [];
	@observable editRows = [];

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.id = parseInt(this.props.match.params.id);
		if (this.id) {
			this.record = await store.model.PermafrostTemperatureTube.findById(this.id, {
				include: [...TUBES_INCLUDE.include, 'owner', { relation: 'measurements', scope: { include: ['depths'] } }],
			});
			this.depths = this.record.depths();
			this.measurements = this.record.measurements();
		}
		this.isLoading = false;
	};

	onCreate = async () => {
		const measurement = new store.model.PermafrostTemperatureTubeMeasurements({ temperatureTubeId: this.record.id });
		await measurement.save();
		const newMeasurement = await store.model.PermafrostTemperatureTubeMeasurements.findById(measurement.id, { include: ['depths'] });
		this.measurements.push(newMeasurement);
	};

	onEdit = ({ record, row }) => {
		if (!this.editRows.includes(row)) {
			this.editRows.push(row);
		} else {
			this.onSave(record);
			this.editRows.splice(this.editRows.indexOf(row), 1);
		}
	};

	onSave = async (record) => {
		const promises = [];
		for (const depth of record.depths()) {
			promises.push(depth.save());
		}
		promises.push(record.save());
		await Promise.all(promises);
	};

	onDelete = async ({ record, row }) => {
		await record.depths.destroy();
		await record.delete();
		this.measurements.splice(row, 1);
	};

	onChangeDepthValue = (rec) => (value) => {
		this.measurements[rec.row].depths()[rec.column - FIRST_DEPTH_COLUMN].depthValue = value;
	};

	onChangeDate = (rec) => (value) => {
		this.measurements[rec.row].date = value;
	};

	onChangeWithSave = (rec, field) => async (value) => {
		this.measurements[rec.row][field] = value;
		await this.measurements[rec.row].save();
	};

	back = () => {
		const { path } = this.props;
		store.route.push({ path });
	};

	paintRedColor = (rec, index, rowDepth) => {
		if (!this.record.object || rowDepth < 3) {
			return '';
		}
		const { minTemperature, maxTemperature } = this.record.object;
		if (minTemperature == null || maxTemperature == null || !rec.record.depths()[index]?.depthValue) {
			return '';
		}
		const depth = rec.record.depths()[index]?.depthValue;
		return depth < maxTemperature || depth > minTemperature ? 'color-red font-weight-bold' : '';
	};


	get reportParams() {
		const { id } = this.record;
		return {
			reportCode: 'TemperatureTubesMeasurements',
			reportParams: { temperatureTubeId: id },
			reportModel: 'PermafrostReport'
		};
	}

	render() {
		if (this.isLoading) return null;
		const { owner, object, maxDepth, number } = this.record;

		const disableForUser = store.model.roles.find((role) => ['user_permafrost'].includes(role.name));

		return (
			<div className='measurements-edit'>
				<div className='tubes-header'>
					<h2>{t('permafrost.edit')}</h2>
					<Button variant='primary' onClick={this.back}>
						{t('backToList')}
					</Button>
				</div>
				<ReportToolbar {...this.reportParams} showPreview={false} showPrint={false} />
				<div className='tubes-info'>
					<LabelField label={t('permafrost.buildings')}>{object?.address?.name || 'Без адреса'}</LabelField>
					<Row>
						<LabelField label={t('permafrost.temperatureTube.title')}>{number || '-'}</LabelField>
						<LabelField label={t('permafrost.temperatureTube.maxDepth')}>{maxDepth?.name || '-'}</LabelField>
						<LabelField label={t('user.title')}>{fio(owner)}</LabelField>
						<div></div>
					</Row>
				</div>

				<Table rows={this.measurements} className='overflow-auto'>
					<Column label={t('permafrost.approved')} property='isApproved' width={80}>
						{(rec) =>
							this.editRows.includes(rec.row) ? null : (
								<Checkbox value={rec.record.isApproved} onChange={this.onChangeWithSave(rec, 'isApproved')} disabled={disableForUser} />
							)
						}
					</Column>
					<Column label={t('date')} property='date' width={200}>
						{(rec) =>
							this.editRows.includes(rec.row) ? <DatePicker value={rec.record.date} onChange={this.onChangeDate(rec)} /> : formatDate(rec.record.date)
						}
					</Column>
					{this.depths.map((x, index) => (
						<Column label={`${x.depth} м`} width={100}>
							{(rec) =>
								this.editRows.includes(rec.row) ? (
									<Input type='number' value={rec.record.depths()[index]?.depthValue} onChange={this.onChangeDepthValue(rec)} className='w-100' />
								) : (
									<span className={`${this.paintRedColor(rec, index, x.depth)}`}>{rec.record.depths()[index]?.depthValue}</span>
								)
							}
						</Column>
					))}
					<Column label={t('file.title')} className='measurements-attachments' width={150}>
						{(rec) =>
							this.editRows.includes(rec.row) ? (
								<Attachments multiple={false} record={rec.record} canUpload={true} />
							) : (
								<Attachments multiple={false} record={rec.record} canUpload={false} noDelete={true} />
							)
						}
					</Column>
					<Column width={40}>{(rec) => (rec.record.isApproved ? null : <EditIcon className='cursor-pointer' onClick={() => this.onEdit(rec)} />)}</Column>
					<Column width={40}>
						{(rec) =>
							rec.record.isApproved ? null : (
								<Popconfirm onConfirm={() => this.onDelete(rec)} content={t('delete') + '?'} portalClassName='confirm-delete'>
									<DeleteIcon className='cursor-pointer' />
								</Popconfirm>
							)
						}
					</Column>
				</Table>
				<div className='create-menu'>
					<AddIcon className='cursor-pointer' onClick={this.onCreate} />
				</div>
			</div>
		);
	}
}
