import React from 'react';
import { observer } from 'mobx-react';
import { Button } from '@smartplatform/ui';
import t from 'i18n';
import ObjectOvernightStay from './ObjectOvernightStay';
import ObjectInspector from './ObjectInspector';
import { LabelField } from '../fields';
import ObjectInspectorCorrection from './ObjectInspectorCorrection';

@observer
export default class ObjectCard extends React.Component {
	render() {
		const { object, category, value, onEdit, onObjectSelect, onCreateFire } = this.props;
		const rankName = object.rank && object.rank.name;
		const rankNameCompare = rankName === '3 РАНГ ПОЖАРА' || rankName === '2 РАНГ ПОЖАРА';
		return (
			<div className='address-object-card'>
				<div className='object-controls'>
					{onObjectSelect && (
						<Button variant='primary' size='sm' disabled={value && value.id === object.id} onClick={() => onObjectSelect(object)}>
							{t('techFireAddress.select')}
						</Button>
					)}
					<Button variant='primary' size='sm' onClick={() => onEdit(object)}>
						{t('edit')}
					</Button>
					<Button variant='warning' size='sm' onClick={() => onCreateFire(object)}>
						{t('fireAlarmMessage.newFire')}
					</Button>
				</div>
				<div className='form-fields-container'>
					<div className='form-fields'>
						<LabelField label={t('techFireAddress.name')} children={object.name} />
						<LabelField label={t('techFireAddress.description')} children={object.description || '-'} />
						<LabelField label={t('techFireAddress.title')} children={object.rank ? object.rank.name : '-'} />
						<LabelField label={t('techFireAddress.ptp')} children={object.ptp || '-'} />
						{object.ptpFile && (
							<div className='form-field'>
								<a target='_blank' href={object.downloadFile('ptpFile')}>
									{object.ptpFile}
								</a>
							</div>
						)}
						<LabelField label={t('techFireAddress.ktp')} children={object.ktp || '-'} />
						{object.ktpFile && (
							<div className='form-field'>
								<a target='_blank' href={object.downloadFile('ktpFile')}>
									{object.ktpFile}
								</a>
							</div>
						)}
						<LabelField label={t('techFireCategory.title')} children={category?.length ? category.map((c) => c.name).join(', ') : '-'} />
						{rankNameCompare && (
							<>
								<ObjectInspector object={object} inspectorCard />
								<ObjectInspectorCorrection object={object} inspectorCard />
							</>
						)}
					</div>
					{object.overnightStay && <ObjectOvernightStay overnightCard object={object} />}
				</div>
			</div>
		);
	}
}
