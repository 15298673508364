import React from 'react';
import t from 'i18n';
import { ReportToolbar } from 'components';
import { ReportStore } from '../store';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { getYearArray } from 'client/tools';
import { Select } from '@smartplatform/ui';
import { endOfMonth } from 'date-fns';

const CODE = 'InfoWorkFireProtectionUnits';
const MONTH = [
	{value: 0, label: t('month.january')},
	{value: 1, label: t('month.february')},
	{value: 2, label: t('month.march')},
	{value: 3, label: t('month.april')},
	{value: 4, label: t('month.may')},
	{value: 5, label: t('month.june')},
	{value: 6, label: t('month.july')},
	{value: 7, label: t('month.august')},
	{value: 8, label: t('month.september')},
	{value: 9, label: t('month.october')},
	{value: 10, label: t('month.november')},
	{value: 11, label: t('month.december')},
];
const TYPES_REPORT = [
	{
		value: 'SUMMARY',
		label: 'Сводный'
	},
	{
		value: 'ANALYSIS',
		label: 'Анализ'
	}
];

@observer
export class InfoWorkFireProtectionUnits extends React.Component {

	@observable year = new Date().getFullYear();
	@observable month = new Date().getMonth();

	constructor(props) {
		super(props);
		this.store = new ReportStore(CODE, t('techFireReport.infoWorkFireProtectionUnits'));
		this.init();
	}

	init = () => {
		this.onChange();
	}

	onChange = async (prop, value) => {
		this[prop] = value;
		const startDate = new Date(this.year,this.month, 1, 0,0,0);
		const endDate = endOfMonth(new Date(this.year,this.month, 1, 0,0,0));
		this.store.startDate = startDate;
		this.store.endDate = endDate;

	}


	render(){

		const { error, name } = this.store;

		return(
			<div className='info-work-fire-protection-units'>
				<h2 className='title'>{name}</h2>
				<div className='filter'>
					<div className='form-field'>
						<label>{t('year')}</label>
						<Select
							noTotal
							maxItems={10}
							itemsPerPage={1000}
							noSearch
							isRequired
							items={getYearArray()}
							value={this.year}
							onChange={(value) => this.onChange('year', value)}
						/>
					</div>
					<div className='form-field'>
						<label>{t('period.month')}</label>
						<Select
							noTotal
							maxItems={10}
							itemsPerPage={1000}
							noSearch
							isRequired
							items={MONTH}
							value={this.month}
							onChange={(value) => this.onChange('month', value)}
						/>
					</div>
				</div>
				<ReportToolbar {...this.store.storeParams} />
				{error && <span className='error'>{error}</span>}
			</div>
		);
	}
}