import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import { Loader, Form, Field, Row, DatePicker, Button, Tabs, Tab } from '@smartplatform/ui';
import FireDepList from './FireDepList';
import t from 'i18n';
import store from 'client/store';
import { isRadiotelephonist, isRadiocallpch } from 'techfires/shifts/constants';
import ShiftsReserve from '../shifts-reserve/ShiftsReserve';

@observer
export default class Edit extends React.Component {
	@observable globalShift = null;
	@observable error = null;
	@observable isLoading = true;
	@observable tab = 'fireDepList';

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		try {
			this.globalShift = await store.model.TechFireShift.findById(this.props.match.params.id);
		} catch (e) {
			this.error = e.message;
		}
		this.isLoading = false;
	};

	edit = () => store.route.push({ path: `/techfires/shifts/${this.props.match.params.id}/edit` });

	setTab = (tab) => (this.tab = tab);

	render() {
		if (this.isLoading) return <Loader />;
		if (this.error) return <div className='error'>{this.error}</div>;

		const controls =
			this.props.isMain && !(isRadiotelephonist() || isRadiocallpch()) ? (
				<div className='actions'>
					<Button onClick={this.edit} variant='primary'>
						{t('edit')}
					</Button>
				</div>
			) : null;

		const _props = { ...this.props, shift: this.globalShift };

		return (
			<div>
				{controls}
				<Form record={this.globalShift} disabled noSave noDelete>
					<Row>
						<Field property='name' label={t('name')} />
						<Field property='date' label={t('date')}>
							<DatePicker />
						</Field>
						<Field relation='garrison' property='name' label={t('garrison')} />
						<div />
					</Row>
				</Form>
				<Tabs type='manual'>
					<Tab title={t('fireDepShift.plural')} onClick={() => this.setTab('fireDepList')} />
					<Tab title={t('fireDepShift.reserve')} onClick={() => this.setTab('shiftsReserve')} />
				</Tabs>
				{this.tab === 'fireDepList' ? <FireDepList {..._props} /> : <ShiftsReserve {..._props} />}
			</div>
		);
	}
}
