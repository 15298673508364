import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import FilterLayout from '../FilterLayout';
import t from 'i18n';
import { Input } from '@smartplatform/ui';
import './operationalManagementFilter.scss';

@observer
export class OperationalManagementFilter extends React.Component {
	static propTypes = {
		layer: PropTypes.object,
		onToggle: PropTypes.func,
	};

	render() {
		const { layer, isLoading, error } = this.props;
		const { search, onChangeSearch } = layer;

		return (
			<FilterLayout layer={layer} title={t('operationalManagementObject.plural')} error={error} isLoading={isLoading} className='operational-management-filter'>
				<Input placeholder={t('operationalManagement.searchPlaceholderMap')} value={search} onChange={onChangeSearch} />
			</FilterLayout>
		);
	}
}
