import React from 'react';
import store from 'client/store';
import { Form, HasManyList } from 'components';
import { observer } from 'mobx-react';
import { Field, Row, Tab, Tabs } from '@smartplatform/ui';
import { FileInput, LabelField, Card, DegreeInput } from 'components';
import { renderStatus } from 'client/tools/helpers/renders';
import { Map } from '@smartplatform/map/client';
import { CityStore } from './store';
import { ReservesList } from './ReservesList';
import MarkerPinIcon from 'img/icons/fires/card/marker-pin-04.svg';
import t from 'i18n';
import './style.scss';

@observer
export class CityEdit extends React.Component {
	constructor(props) {
		super(props);
		const id = props.cityId || props.match.params.id;
		this.store = new CityStore({ id, path: props.path });
		this.currentPath = props.match.url;
	}

	render() {
		const { isLoading, model, userCityId, userCountyId, id, record, onAction, onMapInit, onLayerToggle, lat, lon, onChangeCoords } = this.store;

		if (isLoading) return null;

		let disabled = !model.INFO.WRITE;

		if (userCityId) {
			disabled = id !== userCityId;
		}

		if (userCountyId) {
			disabled = record.countyId != userCountyId;
		}

		const MainInfo = () => (
			<Form record={record} disabled={disabled} noSave={disabled} noDelete={disabled} onCancel={onAction} onSave={onAction} onDelete={onAction}>
				<Row>
					<Field property='name' label={t('name')} />
					<Field relation='county' property='name' label={t('county.title')} />
					<Field relation='municipality' property='name' label={t('municipality.title')} />
				</Row>
				<Row>
					<Field property='population' label={t('city.population')} />
					<Field property='childrenPopulation' label={t('city.childrenPopulation')} />
				</Row>
				<Field property='mainData' label={t('city.mainData')}>
					<textarea />
				</Field>
				<Field property='forcesAndResources' label={t('city.forcesAndResources')}>
					<textarea />
				</Field>
				<Field property='importantObjects' label={t('city.importantObjects')} />
				<Field property='participatingEnterprises' label={t('city.participatingEnterprises')} />
				<div className='form-field'>
					<label>{t('city.planFile')}</label>
					<FileInput property='planFile' record={record} disabled={disabled} />
				</div>
				<Field property='planExplanation' label={t('city.planExplanation')}>
					<textarea />
				</Field>
				<Field relation='sufficiencyStatus' computed={renderStatus} label={t('city.sufficiencyStatus')} />

				<Row>
					<Field property='createdAt' label={t('createdAt')} disabled />
					<Field property='updatedAt' label={t('updatedAt')} disabled />
				</Row>
			</Form>
		);

		return (
			<div className='city-edit-page'>
				<div className='left'>
					<Tabs>
						<Tab title={t('mainInfo')} path={this.currentPath} exact render={() => <MainInfo />} />
						<Tab
							title={t('risks')}
							path={this.currentPath + '/risks'}
							render={() => (
								<HasManyList
									record={record}
									relation={'risks'}
									properties={['peopleCount', 'teenagersCount', 'date']}
									include={[{ relation: 'category', property: 'name' }]}
									disabled={disabled}
									periodFilters
								/>
							)}
						/>
						<Tab
							title={t('emergencyReserves.title')}
							path={this.currentPath + '/reserves'}
							render={() => <ReservesList store={this.store} disabled={disabled} />}
						/>
					</Tabs>
				</div>
				<div className='right'>
					<Card title={t('geo')} icon={<MarkerPinIcon />} className='city-map-card'>
						<Map
							onInit={onMapInit}
							className='full-map'
							modelStore={store.model}
							visibleLayers={store.local.emergency.cityLayers}
							onLayerToggle={onLayerToggle}
						/>

						<Row className='mt-2'>
							<LabelField label={t('latitude')} isRequired>
								<DegreeInput type='latitude' value={lat} onChange={onChangeCoords('lat')} className={!lat && 'field-empty'} />
							</LabelField>
							<LabelField label={t('longitude')} isRequired>
								<DegreeInput type='longitude' value={lon} onChange={onChangeCoords('lon')} className={!lon && 'field-empty'} />
							</LabelField>
						</Row>
					</Card>
				</div>
			</div>
		);
	}
}
