export const colors = [
	'#f00',
	'#f08',
	'#ff00e5',
	'#7f00ff',
	'#20f',
	'#06f',
	'#00b3ff',
	'#0ff',
	'#00ff84',
	'#2f0',
	'#bf0',
	'#ffc800',
	'#ff6a00',
];