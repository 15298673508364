import fire_yellow from './fire_yellow.svg?url';
import fire_red from './fire_red.svg?url';
import fire_grey from './fire_grey.svg?url';
import fire_black from './fire_black.svg?url';
import t from 'i18n';

export default {
	grey: { src: fire_grey, label: t('techFireRank.codes.1') },
	yellow: { src: fire_yellow, label: t('techFireRank.codes.2') },
	black: { src: fire_black, label: t('techFireRank.codes.3') },
	red: { src: fire_red, label: t('techFireRank.codes.4') },
	default: fire_grey,
};
