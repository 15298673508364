import React from 'react';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { Panel, HeatPointsFilter, WeatherFilter, IsdmFilter, WindFilter, ModelsFilter, modelsConfig, QuartersFilter, FiresFilter } from 'components';
import store from 'client/store';
import fireStore from '../../fireStore';
import './style.scss';
import NewHeatPointsFilter from 'components/common-layers/heat-points/new-version/filter/NewHeatPointsFilter';

@observer
export default class Filters extends React.Component {
	render() {
		if (!fireStore.mapInitialized || fireStore.mapStore.isExporting) return null;
		const { isPublic } = store;

		const localSettings = store.local.fires;
		const settings = localSettings.layers;

		return localSettings.filtersMaximized ? (
			<Panel
				left={-5}
				top={5}
				width={290}
				bottom={70}
				height='auto'
				scrollable={false}
				wrapperClassName={'fires-filters-wrapper' + (!isPublic ? ' private' : '')}
				className='fires-filters'
			>
				<div className='minimize-btn' onClick={() => fireStore.switchFilters(false)}>
					<FontAwesomeIcon icon={faFilter} />
				</div>
				<FiresFilter layer={fireStore.firesLayer} store='fires' settings={settings.fires} />
				<HeatPointsFilter layer={fireStore.heatPointsLayer} settings={settings.heatPoints} />
				<NewHeatPointsFilter layer={fireStore.newHeatPointsLayer} settings={settings.newHeatPoints} />
				{!isPublic && <IsdmFilter layer={fireStore.isdmLayer} store='fires' settings={settings.isdm} />}
				{!isPublic && <ModelsFilter layer={fireStore.modelsLayer} settings={settings.models} modelsConfig={modelsConfig} />}
				{!isPublic && <QuartersFilter layer={fireStore.quartersLayer} settings={settings.forestryQuarters} />}
				<WeatherFilter layer={fireStore.weatherLayer} settings={settings.weather} />
				{!isPublic && <WindFilter layer={fireStore.windLayer} settings={settings.wind} />}
			</Panel>
		) : (
			<div className={'fires-filters-minimized' + (!isPublic ? ' private' : '')} onClick={() => fireStore.switchFilters(true)}>
				<FontAwesomeIcon icon={faFilter} />
			</div>
		);
	}
}
