import { Column, Pager, RecordSelect, Table } from '@smartplatform/ui';
import React from 'react';
import t from 'i18n';
import store from 'client/store';
import { ListStore, Toolbar } from 'components';
import { observer } from 'mobx-react';
import { autorun, observable } from 'mobx';
import './style.scss';
import GarrisonSelect from 'techfires/components/garrison-select/GarrisonSelect';
import { FIREDEP_VEHICLE_LOCAL_STORAGE_FIELDS } from './constants';

const FILTER_INCLUDE = ['brand', 'type', 'pump', 'callSign', 'cisternBulk', 'fuelValue', 'powderCapacity', 'status', 'foam', 'fireDep'];

@observer
export default class List extends React.Component {
	@observable brand = null;
	@observable type = null;
	@observable pump = null;
	@observable callSign = null;
	@observable cisternBulk = null;
	@observable status = null;
	@observable fuelValue = null;
	@observable powderCapacity = null;
	@observable fireDep = null;
	@observable foamCapacity = null;
	@observable garrison = null;
	@observable filter = {};

	constructor(props) {
		super(props);
		this.store = new ListStore({ path: props.path, fields: { include: FILTER_INCLUDE }, model: store.model[props.modelName] });
		this.loadLocalStorage();

		autorun(() => {
			this.saveToLocalStorage();
		});
		this.updateFilter();
	}

	componentDidMount() {
		this.store.setPerPage();
	}

	onChange = (prop) => (value) => {
		this[prop] = value;
		this.updateFilter();
		this.saveToLocalStorage();
	};

	updateFilter = async () => {
		const filter = { where: {}, ...this.props.filter };
		filter.where.and = [];
		if (this.brand) {
			filter.where.and.push({ brandId: this.brand.id });
		}
		if (this.type) {
			filter.where.and.push({ typeId: this.type.id });
		}
		if (this.callSign) {
			filter.where.and.push({ callSignId: this.callSign.id });
		}
		if (this.status) {
			filter.where.and.push({ vehicleStatusId: this.status.id });
		}
		if (this.fireDep) {
			filter.where.and.push({ fireDepId: this.fireDep.id });
		}
		if (this.garrison) {
			const fireDepIds = (await store.model.ViewFireDep.find({ where: { garrisonId: this.garrison.id }, fields: ['id'] })).map((r) => r.id);
			filter.where.and.push({ fireDepId: { inq: fireDepIds } });
		}
		filter.include = FILTER_INCLUDE;
		this.filter = filter;
		this.store.updateFilters(this.filter);
	};

		//localstorage;
		localStorage = store.local.fireDepVehicle;
		saveToLocalStorage = () => {
			FIREDEP_VEHICLE_LOCAL_STORAGE_FIELDS.forEach((field) => (this.localStorage[field] = this[field]));
			store.local.save();
		};
	
		loadLocalStorage = () => {
			const storageKeys = Object.keys(this.localStorage);
			if (storageKeys.length)
				storageKeys.forEach((key) => {
					this[key] = this.localStorage[key];
				});
		};

	render() {
		const { tableProps, totalCount, perPage, page, onChange, search, onSearch, create } = this.store;

		return (
			<>
				<Toolbar>
					<Toolbar.AddButton onClick={create} />
					<Toolbar.SearchIconInput value={search} onChange={onSearch} />
				</Toolbar>
				<div className='registry-vehicle-filters'>
					<RecordSelect
						model={store.model.FireDepVehicleType}
						property='name'
						value={this.type}
						onChange={this.onChange('type')}
						width={150}
						placeholder={t('fireDepVehicle.type')}
					/>
					<RecordSelect
						model={store.model.FireDepVehicleStatus}
						property='name'
						value={this.status}
						onChange={this.onChange('status')}
						width={150}
						placeholder={t('fireDepVehicleStatus.title')}
					/>
					<RecordSelect
						model={store.model.FireDepVehicleCallSign}
						property='name'
						value={this.callSign}
						onChange={this.onChange('callSign')}
						width={150}
						placeholder={t('vehicle.callSign')}
					/>
					<RecordSelect
						model={store.model.FireDep}
						property='name'
						value={this.fireDep}
						onChange={this.onChange('fireDep')}
						width={150}
						placeholder={t('fireDepVehicle.fireDep')}
					/>
					<GarrisonSelect value={this.garrison} onChange={this.onChange('garrison')} width={200} />
				</div>
				<Pager current={page} onChange={onChange('page')} totalCount={totalCount || 0} itemsPerPage={perPage} />
				<Table {...tableProps}>
					<Column property='id' label={t('id')} width={80} />
					<Column relation='type' property='name' label={t('fireDepVehicle.type')} />
					<Column relation='fireDep' property='name' label={t('fireDep.title')} width={120} />
					<Column relation='status' property='name' label={t('fireDepVehicleStatus.title')} />
					<Column relation='callSign' property='name' label={t('vehicle.callSign')} />
					<Column property='stateNumber' label={t('vehicle.stateNumber')} />
					<Column property='actualFoamValue' label={t('vehicle.actualFoamValue')} />
					<Column property='year' label={t('vehicle.year')} />
					<Column property='passport' label={t('vehicle.passport')} />
					<Column relation='cisternBulk' property='volume' label={t('vehicle.capacity')} />
					<Column relation='foamCapacity' property='capacity' label={t('vehicle.foamCapacity')} />
					<Column relation='pump' property='capacity' label={t('pump.title')} />
				</Table>
			</>
		);
	}
}
