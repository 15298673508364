export const MODEL_NAME = 'ViewEmergencyReserve';

export const RESERVES_INCLUDE = {
	include: [
		{ relation: 'category', scope: { fields: ['id', 'name'] } },
		{ relation: 'city', scope: { fields: ['id', 'name'] } },
		{ relation: 'unit', scope: { fields: ['id', 'name'] } },
		{ relation: 'county', scope: { fields: ['id', 'name'] } },
		{ relation: 'nomenclature', scope: { fields: ['id', 'name'] } },
	],
};

export const LOCAL_STORAGE_FIELDS = ['showFilters', 'city', 'county', 'openedCategories', 'selectedCategories', 'selectedSubCategories', 'page', 'search'];
