import { observable } from 'mobx';
import store from 'client/store';
import Polygon from 'ol/geom/Polygon.js';

export default class waterSupplyStore {
	@observable waterSupply = null;
	@observable fireDepName = '-';
	@observable isLoading = true;
	@observable error = null;

	init = async () => {
		this.error = null;
		this.isLoading = true;
		if (this.id) {
			try {
				this.waterSupply = await store.model.WaterSupply.findById(this.id, {
					include: [
						{ relation: 'type' },
						{
							relation: 'fireDepPersonnel',
							scope: {
								include: [
									{ relation: 'position', scope: { fields: ['id', 'name'] } },
									{ relation: 'headOrg', scope: { fields: ['id', 'name'] } },
								],
							},
						},
					],
				});
				await this.searchFireDep(this.waterSupply.geo);
			} catch (e) {
				this.error = e.message;
			}
		} else {
			this.waterSupply = new store.model.WaterSupply();
		}
		this.isLoading = false;
	};

	searchFireDep = async (geo) => {
		if (geo?.coordinates) {
			const records = await store.model.FireDep.find();

			for (const record of records) {
				const isPolygon = record.responsibilityArea && record.responsibilityArea.type === 'Polygon';

				if (isPolygon) {
					const polygon = new Polygon(record.responsibilityArea.coordinates);
					if (polygon.intersectsCoordinate(geo.coordinates)) {
						this.fireDepName = record.name;
						break;
					}
				}
			}
		}
	};
}
