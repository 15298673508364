import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Button, Checkbox, DatePicker, RecordSelect, Select } from '@smartplatform/ui';
import store from 'client/store';
import { ExportButton } from 'components';
import t from 'i18n';
import { MENU_ITEMS, GROUP_MODE_ITEMS } from './constants';
import { formatDate } from 'client/tools';
import './style.scss';

@observer
export class Report extends React.Component {
	@observable record = null;
	@observable date = new Date();
	@observable isLoading = false;
	@observable reportName = MENU_ITEMS[1];
	@observable reportHtml = '';
	@observable nonforest = true;
	@observable forestry = null;
	@observable stn_groupmode = '';

	constructor(props) {
		super(props);
		this.loadLocalStorage();
		this.init();
	}
	saveLocalStorage = () => {
		store.local.fires.isdm = { reportName: this.reportName };
		store.local.save();
	};

	loadLocalStorage = () => {
		this.reportName = store.local.fires.isdm.reportName;
	};

	onGenerate = async () => {
		this.isLoading = true;
		this.record = null;
		const reportParams = { date: this.date };

		if (['ISDM1', 'ISDM1T'].includes(this.reportName)) {
			reportParams.nonforest = Number(this.nonforest);
		}

		if ((this.reportName === 'ISDM1' || this.reportName === 'ISDM1T') && this.forestry) {
			reportParams.lx_id = this.forestry.isdmCode;
		}

		if (this.reportName === 'METEO' && this.stn_groupmode) {
			reportParams.stn_groupmode = this.stn_groupmode;
		}

		await store.model.IsdmReport.import({
			reportName: this.reportName,
			saveToStorage: true,
			reportParams,
		});
		await this.init();
		this.isLoading = false;
	};

	loadReport = async (report = 'METEO', filetype = 'html') => {
		const response = await fetch(`/api/IsdmReports/load?report="${report}"&filetype="${filetype}"`);
		return await response.text();
	};

	init = async () => {
		this.record = (await store.model.IsdmReport.find({ where: { report: this.reportName }, order: 'id desc', limit: 1 }))[0];
		if (this.record) {
			this.reportHtml = await this.loadReport(this.reportName, 'html');
			if (['ISDM8', 'ISDM9'].includes(this.reportName)) this.filterReports(this.reportHtml);
		}

	};

	filterReports = (html) => {
		// фильтрация по субьекту (Республика Саха (Якутия)) таблицы форм исдм 8 и исдм 9
		// по возможности, убрать

		const htmlWrapper = document.createElement('div');
		htmlWrapper.innerHTML = html;
		const table = htmlWrapper.querySelector('table');

		const reportNodeList = table.querySelector('tbody')?.querySelectorAll('tr');
		let result 	= '',
			counter = 0;

		if (reportNodeList) {
			for (const node of reportNodeList) {
				counter++;
				if (this.reportName === 'ISDM8' && ((counter >= 0 && counter <= 13) || counter === 112) ) result += node.outerHTML;
				if (this.reportName === 'ISDM9' && ((counter >= 0 && counter <= 11) || counter === 110) ) result += node.outerHTML;
			}
			this.reportHtml = `<table><tbody>${result}</tbody></table>`;
		}

	}

	onExport = () => {
		const element = document.createElement('a');
		element.href = `/api/IsdmReports/load?report="${this.reportName}"&filetype="xlsx"`;
		element.rel = 'noopener noreferrer';
		element.download = this.props.name;
		element.click();
		element.remove();
	};

	onChange = (prop) => (value) => (this[prop] = value);
	onReportChange = (value) => {
		this.reportName = value;
		this.saveLocalStorage();
		this.init();
	};

	render() {
		const { reportName, onChange, onExport, record, onGenerate, isLoading, onReportChange, nonforest, forestry, stn_groupmode } = this;
		const content = isLoading ? t('loading') : <div className='isdm-report' dangerouslySetInnerHTML={{ __html: this.reportHtml }} />;

		return (
			<div className='isdm-page'>
				<h2>{t('isdm.titleLong')}</h2>
				<div className='filters'>
					<Select items={MENU_ITEMS.map((i) => ({ label: t('isdm.' + i), value: i }))} value={reportName} onChange={onReportChange} width={250} />
					<DatePicker value={this.date} onChange={onChange('date')} showClearButton={false} />
					{['METEO'].includes(reportName) && (
						<Select
							value={stn_groupmode}
							items={GROUP_MODE_ITEMS}
							onChange={onChange('stn_groupmode')}
							width={300}
							itemsPerPage={1000}
							empty={t('isdm.noGroup')}
						/>
					)}
					{['ISDM1', 'ISDM1T'].includes(reportName) && (
						<>
							<RecordSelect
								value={forestry}
								model={store.model.Forestry}
								onChange={onChange('forestry')}
								property='name'
								width={300}
								empty={t('forestry.all')}
								filter={{ where: { isdmCode: { neq: null } } }}
								itemsPerPage={1000}
							/>
							<Checkbox value={nonforest} onChange={onChange('nonforest')} label={t(`isdm.nonforest`)} className='mt-2' />
						</>
					)}
					<Button onClick={onGenerate} variant='primary' disabled={isLoading}>
						{t('update')}
					</Button>
					{!['ISDM9', 'ISDM8'].includes(reportName) && (
						<ExportButton text={t('report.download')} disabled={!record} onClick={onExport} />
					)}
				</div>
				{record && (
					<b className='mb-1 mt-1'>
						{t('report.downloadDate')} {formatDate(record.createdAt)}
					</b>
				)}
				{content}
			</div>
		);
	}
}
