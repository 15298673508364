import React from 'react';
import PropTypes from 'prop-types';
import Cleave from 'cleave.js/react';
import classNames from 'classnames';

export const NumberInput = (props) => {
	const {
		value,
		positiveOnly = false,
		integerOnly,
		decimalScale = 16,
		decimalMark = '.',
		delimiter = '',
		onChange,
		autoFocus,
		getRef,
		className,
		...rest
	} = props;

	const _value = value === undefined || value === null ? '' : String(value);

	const _onChange = (e) => {
		let value = e.target.value;
		if (!value && value !== 0) {
			value = null;
		}
		onChange && onChange(value, e);
	};

	const _className = classNames('ui-input number-input', className);

	return (
		<Cleave
			options={{
				numeral: true,
				numeralPositiveOnly: positiveOnly,
				numeralDecimalScale: integerOnly ? 0 : decimalScale,
				numeralDecimalMark: decimalMark,
				delimiter: delimiter,
			}}
			value={_value}
			htmlRef={getRef}
			onChange={_onChange}
			className={_className}
			{...rest}
		/>
	);
};

NumberInput.propTypes = {
	value: PropTypes.any,
	placeholder: PropTypes.string,
	onChange: PropTypes.func,
	positiveOnly: PropTypes.bool,
	integerOnly: PropTypes.bool,
	decimalScale: PropTypes.number,
	className: PropTypes.string,
	delimiter: PropTypes.string,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
	onInit: PropTypes.func,
	getRef: PropTypes.func,
	disabled: PropTypes.bool,
};

export default NumberInput;
