import React from 'react';
import { inject, observer } from 'mobx-react';
import { Input, Row } from '@smartplatform/ui';
import t from 'i18n';
import CheckCircleIcon from 'img/icons/fires/card/check-circle.svg';
import { Card, LabelField } from 'components';

@inject('card')
@observer
export class FireAct extends React.Component {
	render() {
		const { card, disabled } = this.props;
		return (
			<Card title={t('fire.forestFireAct')} icon={<CheckCircleIcon />}>
				<Row className='card-row'>
					{['actDate', 'actPercent', 'compiler'].map((label) => (
						<LabelField label={t('fire.' + label)} key={label}>
							<Input value={card.fire[label]} onChange={card.onChange(label)} disabled={disabled} />
						</LabelField>
					))}
				</Row>
			</Card>
		);
	}
}
