import React from 'react';
import { inject, observer } from 'mobx-react';
import store from 'client/store';
import Totals from './Totals';
import Dynamics from './Dynamics';
import Danger from './Danger';
import Daily from './Daily';
import Season from './Season';
import Extinguishing from './Extinguishing';
import EmergencySituation from './EmergencySituation';
import './style.scss';
import FirePlanExecution from './FirePlanExecution';

@inject('dashboardStore')
@observer
export default class OperationalReport extends React.Component {
	constructor(props) {
		super(props);
		this.store = this.props.dashboardStore;
		this.init();
	}

	init = async () => {
		this.store.getDashboardData();
		this.store.getDashboardCitiesData();
	};

	componentWillUnmount() {
		if (this.store.chart) this.store.chart.destroy();
	}

	toggleTest = async () => {
		store.local.fires.citiesTest = !store.local.fires.citiesTest;
		store.local.save();
		this.store.citiesData = {};
		this.store.getDashboardCitiesData();
	};

	render() {
		return (
			<div className='operational-report-by-types'>
				<div className='main'>
					<div className='left'>
						<Totals />
						<Season />
						<Danger />
						<EmergencySituation />
						<Extinguishing />
						<FirePlanExecution />
					</div>
					<div className='right'>
						{['forest', 'oopt', 'other'].map((name) => (
							<Dynamics type={name} key={name}>
								<Daily type={name} />
							</Dynamics>
						))}
					</div>
				</div>
			</div>
		);
	}
}
