import qs from 'qs';
import { createBrowserHistory } from 'history';
import { observable, toJS } from 'mobx';
import i18next from 'i18next';
import { Rest, ModelStore } from '@smartplatform/model-store';
import { RouteStore } from '@smartplatform/route-store';
import { LocalStore } from '@smartplatform/local-store';
import CovidStore from './modules/covid/store';
import SearchStore from './components/search/store';
import UIStore from './uiStore';
import { getCurrentModule, getRoleNames } from 'client/tools';
// import defaultMapConfig from 'components/map/localSettings';
import { SocketStore } from './socketStore';
import techFiresStore from 'techfires/techFiresStore';

/**
 * Глобальный контейнер сторов
 */
export class AppStore {
	local; // Локальное хранилище
	model; // Хранилище моделей
	route; // Хранилище маршрута браузера
	api; // API обмена с сервером
	admin; // Хранилище @admin
	ui; // ui
	config; // Конфигурация региона
	@observable isLogged = false;

	// массив заполнится именами ролей, которые пришли с сервера, кроме системных
	#userRoles = [];

	subscribers = [];

	fiasApiKey = '842709bce84734ee40403e8ce6ef9bb80e722fa5';

	@observable module = null;
	@observable isPublic = false;
	@observable appKey = 0; // key для обновления приложения
	@observable userCounty;
	@observable userCity;
	@observable currentModule = null;

	get roles() {
		return [...this.#userRoles];
	}

	get isAdmin() {
		const roleNames = getRoleNames();
		return !!roleNames.find((role) => role.indexOf('admin') !== -1);
	}

	constructor() {
		this.history = createBrowserHistory();
		this.ui = new UIStore();
		this.module = getCurrentModule();
		this.local = new LocalStore();
		this.local.extend({
			covid: {
				clusterMode: false,
			},
			contragents: {},
			municipalities: {},
			language: 'ru',
			fireDepPersonnel: {},
			fireDepVehicle: {},
			waterSupply: {},
			techFireBlockedRoad: {},
			adpiDevice: {},
		});
		// this.local.mapTools = defaultsDeep(toJS(this.local.mapTools || {}), defaultMapConfig);
		this.local.save();

		this.rest = new Rest(document.location.hostname === 'stopcovid19.sakha.gov.ru' ? '/covid19/api/' : undefined);
		// this.rest = new Rest();
		this.model = new ModelStore({
			transport: this.rest,
			cache: false,
			autoConnect: false,
			onLogin: this.onLogin,
		});

		this.covid = new CovidStore();
		this.search = new SearchStore();
		// this.map = new MapStore(this);

		this.route = new RouteStore({
			history: this.history,
			decode: qs.parse,
			encode: qs.stringify,
		});

		this.socketStore = new SocketStore(this);
	}

	onLogin = (user) => {
		this.socketStore.socket.disconnect().connect();
		this.getAfterLoginData(user);
	};

	init = async (options) => {
		i18next.changeLanguage(this.local.language || 'ru');
		this.config = APP_CONFIG;
		const { isPublic } = options || {};
		this.isPublic = isPublic;
		if (isPublic) {
			this.ui.topBarHeight = 0;
			this.ui.sideBarWidth = 0;
		}

		await this.model.connect();
		this.model.user && this.getAfterLoginData();
		this.patchDownloadFile('ViewShip', 'Ship');
	};

	getAfterLoginData = async () => {
		this.#userRoles = [];
		this.userCounty = null;
		this.userCity = null;
		if (this.model.roles?.length) {
			this.#userRoles = this.model.roles.reduce((arr, role) => {
				role.id && arr.push(role.name);
				return arr;
			}, []);
		}

		const { countyMapId, cityMapId } = this.model.user;
		if (countyMapId) {
			this.userCounty = await this.model.County.findById(countyMapId);
		}

		if (cityMapId) {
			this.userCity = await this.model.City.findById(cityMapId);
		}

		techFiresStore.fetchUserGarrison();

		this.isLogged = true;
	};

	patchDownloadFile = (viewName, modelName, id = 'id') => {
		const modelStore = this.model;
		if (modelStore[viewName] && modelStore[modelName]) {
			modelStore[viewName].prototype.downloadFile = function (property) {
				return modelStore.downloadFile(modelStore[modelName], this[id], property);
			};
		}
	};
}

window.APP_STORE = new AppStore();

export default window.APP_STORE;
