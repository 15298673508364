import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import { Map } from '@smartplatform/map/client';
import { Button } from '@smartplatform/ui';
import GeoEditStore from './editStore';
import store from 'client/store';
import Filters from './filters';
import t from '@smartplatform/map/i18n';
import './style.scss';

const MAP_PARAMS = {
	zoom: 4,
	maxZoom: 18,
};

@observer
export default class GeoEdit extends React.Component {
	@observable editStore = {};

	static propTypes = {
		value: PropTypes.object,
		onChange: PropTypes.func,
		previewJSON: PropTypes.bool,
		disabled: PropTypes.bool,
		params: PropTypes.object,
		otherGeometry: PropTypes.object,
		fitGeo: PropTypes.object,
		defaultStyle: PropTypes.object,
		isFiltering: PropTypes.bool,
	};

	static defaultProps = {
		previewJSON: false,
		disabled: false,
		isFiltering: false,
		params: {},
	};

	constructor(props) {
		super(props);
		this.editStore = new GeoEditStore(props);
		this.isFiltering = props.isFiltering;
	}

	componentDidUpdate(prevProps, prevState) {
		if (JSON.stringify(prevProps.fitGeo?.coordinates) !== JSON.stringify(this.props.fitGeo?.coordinates)) {
			this.editStore.mapStore.fitGeo(this.props.fitGeo);
		}
		if (JSON.stringify(prevProps.otherGeometry?.coordinates) !== JSON.stringify(this.props.otherGeometry?.coordinates)) {
			this.editStore.otherGeometry = this.props.otherGeometry;
			this.editStore.addGeo();
		}
		if (JSON.stringify(prevProps.defaultStyle) !== JSON.stringify(this.props.defaultStyle)) {
			this.editStore.defaultStyle = this.props.defaultStyle;
			this.editStore.layer.setStyle(this.props.defaultStyle);
		}
	}

	render() {
		const { previewJSON, disabled, params } = this.props;

		const _params = {
			...(store.config.map || MAP_PARAMS),
			...params,
			fitPadding: 50,
		};

		const btnProps = { variant: 'primary', size: 'sm' };

		const drawControls =
			this.editStore.mapInitialized &&
			(!this.editStore.mapStore.drawing ? (
				<Button onClick={this.editStore.startDrawing} disabled={this.editStore.mapStore.drawing} {...btnProps}>
					{t(this.editStore.geo ? 'map.startEditing' : 'map.startDrawing')}
				</Button>
			) : (
				<>
					<Button onClick={this.editStore.finishDrawing} disabled={!this.editStore.changed} {...btnProps}>
						{t('map.finishDrawing')}
					</Button>
					<Button onClick={this.editStore.cancelDrawing} {...btnProps}>
						{t('map.cancelDrawing')}
					</Button>
				</>
			));

		return (
			<div className={'geo-edit' + (this.editStore.mapMaximized ? ' maximized' : '')}>
				{!disabled && <div className='draw-controls'>{drawControls}</div>}
				<div className='edit-area'>
					<Map onInit={this.editStore.onMapInit} params={_params} className='geo-edit-map' noPopups>
						{this.isFiltering &&
							this.editStore.layers.permafrostObjectLayer &&
							this.editStore.layers.permafrostTemperatureTubeLayer &&
							this.editStore.layers.permafrostLevelingMarkLayerLayer && <Filters layers={this.editStore.layers} />}
					</Map>
					{previewJSON && (
						<div className='json-preview'>
							<pre>{JSON.stringify(this.editStore.geo, null, 2)}</pre>
						</div>
					)}
				</div>
			</div>
		);
	}
}
