import React from 'react';
import { observable } from 'mobx';

import store from 'client/store';
import { FIRE_DEP_SCOPE, MODEL_NAME } from './constants';

export default class FireDepStore {
	@observable fireDep = null;
	@observable isLoading = true;

	constructor(id) {
		this.id = parseInt(id);
		this.model = store.model[MODEL_NAME];
		this.init();
	}

	get isNew() {
		return Number.isNaN(this.id);
	}

	get canEdit() {
		return this.model?.INFO.WRITE;
	}

	init = async () => {
		this.isLoading = true;

		this.vehicleFFStatus = (await store.model.FireDepVehicleStatus.find({ where: { code: 'FF' } }))[0];
		this.personnelFFStatus = (await store.model.FireDepPersonnelStatus.find({ where: { code: 'FF' } }))[0];

		this.fireDep = this.isNew ? new this.model() : await this.model.findById(this.id, { ...FIRE_DEP_SCOPE });
		const where = this.isNew ? undefined : { id: { neq: this.id } };
		const otherFireDeps = await this.model.find({
			where,
			scope: { fields: ['id', 'geo'] },
		});
		this.otherGeometry =
			otherFireDeps.length > 0
				? {
						type: 'GeometryCollection',
						geometries: otherFireDeps.filter((fireDep) => !!fireDep.responsibilityArea).map((fireDep) => fireDep.responsibilityArea),
				  }
				: undefined;
		this.isLoading = false;
	};
}
