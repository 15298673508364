import React from 'react';
import { observer } from 'mobx-react';
import { Field, Popconfirm, RelationSelect, ColorUtils, Select, Row, Input } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';
import { ModelEdit } from 'components';
import { Map, drawGeoMarker, Popup, geoJSON } from '@smartplatform/map/client';
import './style.scss';
import { renderStatus } from 'client/tools';
import MunicipalFormationIcon from 'emergency/img/icons/municipalFormation.svg';
import { observable } from 'mobx';
import CrossIcon from 'img/icons/cross.svg';
import RecycleIcon from 'img/icons/shared/delete.svg';
import PlusIcon from 'img/icons/plus.svg';
import markerIconGeo from 'img/icons/shared/cityMarker.svg?url';
import { Fill, Icon, Stroke, Style } from 'ol/style';
import MainPopup from 'fires/map/MainPopup';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { asArray } from 'ol/color';
import FormationPopup from './FormationPopup';
import { MO_STATUS_NOT_READY, renderMunicipalityStatus, renderPopulation } from '../../helpers';

import { Cities } from './Cities';

@observer
export class Edit extends React.Component {
	@observable record = {};
	@observable cities = null;
	@observable municipalityStatuses = null;
	@observable statusNotReady = null;
	@observable mapStore = null; // ставим на null, чтобы в рендере проверять факт инициализации карты
	@observable risks = [];

	constructor(props) {
		super(props);
		this.init();
		this.path = props.path;
	}

	init = async () => {
		this.municipalityStatuses = await store.model.MunicipalSufficiencyStatus.find();
		this.statusNotReady = this.municipalityStatuses.find((status) => status.name.toLowerCase().includes(MO_STATUS_NOT_READY));
	};

	onMapInit = (mapStore) => {
		this.mapStore = mapStore;
		this.map = mapStore.map;

		// создаем свой векторный слой с источником VectorSource
		this.layer = new VectorLayer({
			format: geoJSON,
			source: new VectorSource(),
		});
		// добавляем слой на карту
		this.mapStore.addLayer(this.layer);
	};

	getRecord = async (record) => {
		const { match } = this.props;
		const id = match.params.id;
		this.record = record;

		if (id !== 'create') {
			this.cities = record.cities();
			this.updateMap();
		}
	};

	updateMap = () => {
		let features = [];

		// drawGeoMarker перестал принимать icon в качестве параметра,
		// теперь он принимает готовый Style, это было сделано для гибкости в некоторых местах

		for (let city of this.cities) {
			const marker = drawGeoMarker(city.geo, {
				data: { record: city },
				style: new Style({
					fill: new Fill({ color: asArray([...ColorUtils.hexToArray('#0af'), 0.2]) }),
					stroke: new Stroke({ color: asArray([...ColorUtils.hexToArray('#024'), 0.8]), width: 1, lineDash: [1, 4] }),
					image: new Icon({
						src: markerIconGeo,
						anchor: [9, 31],
						anchorXUnits: 'pixels',
						anchorYUnits: 'pixels',
					}),
				}),
			});

			features.push(...marker);
		}

		this.layer.getSource().clear();
		this.layer.getSource().addFeatures(features);

		// можно переместить карту на какую-то геометрию
		if (this.cities.length > 0) this.mapStore.fitGeo(this.cities[0].geo, 14);
	};

	moveToCity = (i) => {
		if (this.cities[i]) this.mapStore.fitGeo(this.cities[i].geo, 14);
	};

	onSave = async () => {
		let isNew = !!this.record.id;
		for (const city of this.cities) {
			await city.save();
		}

		await this.record.save();
		for (const risk of this.risks) {
			if (!risk.municipalityId) {
				risk.municipalityId = this.record.id;
			}
			await risk.save();
		}
		if (!isNew) {
			store.route.push({ path: `${this.path}/${this.record.id}` });
		} else {
			this.back();
		}
	};

	onDelete = async () => {
		await this.record.delete();
		this.back();
	};

	back = () => {
		store.route.push({ path: this.path });
	};

	get municipalityStatusesSelect() {
		return this.municipalityStatuses.map((status) => {
			return { label: renderStatus(status), value: status.id };
		});
	}

	render() {
		const { match, path } = this.props;

		// во время первого рендера this.mapStore будет null, поэтому сначала проверяем
		const popup = this.mapStore ? this.mapStore.popup : null;

		const { name } = this.record;
		const id = parseInt(match.params.id);

		return (
			<div className='municipality-edit'>
				<div className='left-panel'>
					<div className='left-panel-header'>
						<MunicipalFormationIcon />
						{name && <span>{name}</span>}
					</div>
					<h2>{t('mainInfo')}</h2>
					{this.mapStore && (
						<ModelEdit
							filter={{ include: [{ relation: 'cities', scope: { include: ['sufficiencyStatus', { risks: ['category'] }, 'attachments'] } }] }}
							model={store.model.Municipality}
							path={path}
							id={id}
							className='edit-form'
							noSave
							noDelete
							getRecord={this.getRecord}
						>
							<Row>
								<Field property='name' label={t('municipalFormation.name')} />
								<Field property='area' label={t('municipalFormation.area')}>
									<Input />
								</Field>
								<Field disabled computed={() => this.cities && renderPopulation(this.cities)} label={t('municipalFormation.population')}>
									<Input />
								</Field>
							</Row>
							<Row>
								<Field disabled label={t('municipalFormation.sufficiencyStatusFull')}>
									<Select items={[]} showValue={this.cities && this.municipalityStatuses && renderMunicipalityStatus(this.cities, this.municipalityStatuses)} />
								</Field>
								<Field relation='status' computed={renderStatus} itemTag={renderStatus} label={t('status')}>
									<RelationSelect maxItems={10} itemsPerPage={1000} noTotal isRequired noSearch />
								</Field>
								<div />
							</Row>

							<hr />
							<Cities cities={this.cities} moveToCity={this.moveToCity} statusNotReady={this.statusNotReady} />
						</ModelEdit>
					)}

					<div className='edit-form-buttons'>
						<button onClick={this.onSave} className='save-button'>
							<PlusIcon />
							{id ? 'Сохранить' : 'Создать'}
						</button>
						{!!id && (
							<Popconfirm content={t('delete')} onConfirm={this.onDelete}>
								<button className='delete-button'>
									<RecycleIcon />
									Удалить
								</button>
							</Popconfirm>
						)}
						<button onClick={this.back} className='close-button'>
							<CrossIcon fill='#fff' stroke='#fff' />
							Закрыть
						</button>
					</div>
				</div>
				<Map className='full-map' onInit={this.onMapInit}>
					{popup && (
						<Popup {...popup} width={popup.width || 550}>
							<MainPopup {...popup} renderPopupComponent={(record) => <FormationPopup key={record.id} record={record} />} />
						</Popup>
					)}
				</Map>
			</div>
		);
	}
}
