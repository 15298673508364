import React from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import { Field, Form, Row, Checkbox, DatePicker } from '@smartplatform/ui';
import { fio } from 'client/tools';
import { PERSONNEL_SCOPE, VEHICLES_SCOPE } from '../../constants';
import { fireDepVehicleItem } from 'techfires/helpers';
import store from 'client/store';
import t from 'i18n';

@inject('fireDepStore')
@observer
export class PersonnelEdit extends React.Component {
	@observable person = null;
	@observable responsibleForCalling = null;
	@observable rtp = null;

	constructor(props) {
		super(props);
		this.fireDepStore = props.fireDepStore;
		this.init();
	}

	init = async () => {
		const id = parseInt(this.props.match.params.id);
		this.fireDepId = this.props.fireDepStore.fireDep.id;
		if (!Number.isNaN(id)) {
			this.person = await store.model.FireDepPersonnel.findById(id, { ...PERSONNEL_SCOPE });
			this.responsibleForCalling = this.person.responsibleForCalling;
			this.rtp = this.person.rtp;
		} else {
			this.person = new store.model.FireDepPersonnel({ fireDepId: this.fireDepId });
			this.responsibleForCalling = this.person.responsibleForCalling;
		}
	};

	onChangeCheckbox = (prop) => (value) => {
		this[prop] = value;
	};

	checkboxField = (property, label) => {
		return (
			<Field property={property} label={label}>
				<Checkbox {...{ value: this[property], onChange: this.onChangeCheckbox(property) }} />
			</Field>
		);
	};

	get vehicleStatusFilter() {
		const vehicleFFstatus = this.props.fireDepStore.vehicleFFStatus;
		if (vehicleFFstatus) {
			return { or: [{ vehicleStatusId: { neq: vehicleFFstatus.id } }, { vehicleStatusId: null }] };
		}

		return {};
	}

	render() {
		if (!this.person) return '...';
		const isFFstatus = this.person.statusId === this.props.fireDepStore.personnelFFStatus?.id;

		return (
			<div className='personnel'>
				<Form record={this.person} returnTo={this.props.path}>
					<div className='personnel-basic'>
						<h1>{t('fireDepPersonnel.basic')}</h1>
						<Row>
							<Field property='lastName' label={t('lastName')} />
							<Field property='firstName' label={t('firstName')} />
							<Field property='middleName' label={t('middleName')} />
						</Row>
						<Row>
							<Field relation='section' property='name' label={t('fireDepSection.title')} />
							<Field relation='position' property='name' label={t('fireDepPosition.title')} />
							<Field relation='rank' property='name' label={t('fireDepRank.title')} />
						</Row>
						<Row>
							<Field relation='status' property='name' label={t('personnelStatus.title')} disabled={isFFstatus} filter={{ where: { code: { neq: 'FF' } } }} />
							<Field property='dayShift' label={t('fireDepPersonnel.dayShift')} />
						</Row>
						<Row>
							{(this.responsibleForCalling && <Field relation='techFireRank' property='name' label={t('techFire.rank')} />) || <div />}
							{this.checkboxField('responsibleForCalling', t('fireDepPersonnel.responsibleForCalling'))}
						</Row>
						<Row>
							{(this.rtp && (
								<Row>
									<Field property='orderNumber' label={t('techFireChief.orderNumber')} />
									<Field property='startDate' label={t('techFireChief.startDate')}>
										<DatePicker showClearButton={false} />
									</Field>
									<Field property='endDate' label={t('techFireChief.endDate')}>
										<DatePicker showClearButton={false} />
									</Field>
								</Row>
							)) || <div />}
							{this.checkboxField('rtp', t('fireDep.chief'))}
						</Row>
					</div>
					<div className='personnel-vehicle'>
						<h1>{t('fireDepVehicle.plural')}</h1>
						<Row>
							<Field
								relation='vehicle'
								filter={{ where: { fireDepId: this.fireDepId, fireDepShiftId: null, ...this.vehicleStatusFilter }, ...VEHICLES_SCOPE }}
								computed={fireDepVehicleItem}
								label={t('fireDepVehicle.title')}
							/>
							<Field relation='gasMask' property='name' label={t('gasMask.title')} />
							<div />
						</Row>
					</div>
					<h1>{t('fireDepVehicle.contacts')}</h1>
					<Row>
						<Field property='phone' label={t('phone')} />
						<Field property='address' label={t('address.title')} />
						<Field relation='user' computed={fio} label={t('user.title')} />
					</Row>
				</Form>
			</div>
		);
	}
}
