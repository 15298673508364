import React from 'react';
import { AddButton, ExportButton, FilterButton, SearchIconInput } from 'components';
import store from 'client/store';
import t from 'i18n';
import './style.scss';
import classNames from 'classnames';

export class Toolbar extends React.Component {
	render() {
		const className = classNames('composite-toolbar', this.props.className);
		return <div className={className}>{this.props.children}</div>;
	}
}

Toolbar.AddButton = ({ onClick, ...props }) => {
	if (!onClick) {
		onClick = () => store.route.push({ path: store.route.path + '/create' });
	}
	return <AddButton onClick={onClick} {...props} />;
};

Toolbar.ExportButton = ({ exportUrl, onClick, text = t('export'), disabled }) => {
	const Component = <ExportButton onClick={onClick} text={text} disabled={disabled} />;
	if (!exportUrl || disabled) return Component;
	return (
		<a href={exportUrl} rel='noopener noreferrer' download>
			{Component}
		</a>
	);
};

Toolbar.FilterButton = ({ onClick, showFilters }) => <FilterButton onClick={onClick} className={showFilters ? 'filter-btn active' : 'filter-btn'} />;

Toolbar.SearchIconInput = (props) => <SearchIconInput {...props} />;
