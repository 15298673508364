import React from 'react';
import { observer } from 'mobx-react';

import {FilterLayout, ModelsFilter} from 'components';
import store from 'client/store';
import t from 'i18n';
import fireStore from 'fires/fireStore';
import {modelsConfigFireMap} from "components/common-layers/models/models-config";

@observer
export default class ExtraLayers extends React.Component {
	render() {
		const localSettings = store.local.fires;
		const settings = localSettings.layers;

		return (
			<div className='fires-extra-layers'>
				<FilterLayout title={t('county.plural')} layer={fireStore.countiesLayer} />
				<FilterLayout title={t('forestry.plural')} layer={fireStore.forestriesLayer} />
				<FilterLayout title={t('dzz.title')} layer={fireStore.dzzLayer} />
				<ModelsFilter layer={fireStore.modelsLayer} settings={settings.models} modelsConfig={modelsConfigFireMap} />
			</div>
		);
	}
}
