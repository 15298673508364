import React from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Column, Table } from '@smartplatform/ui';
import t from 'i18n';
import { RowAddButton } from 'fires/components';
import store from 'client/store';
import EditVehiclePopup from './EditVehiclePopup';
import { getVehiclesValues } from 'fires/helpers';
import { Card } from 'components';
import { VEHICLE_INCLUDE } from '../../constants';
import { PopconfirmFaTimesIcon } from 'components';
import ShieldTickIcon from 'img/icons/fires/card/shield-tick.svg';

@inject('card')
@observer
export class Vehicles extends React.Component {
	@observable showPeoplePopup = false;
	@observable currentVehicles = [];
	@observable records = [];

	constructor(props) {
		super(props);
		this.card = this.props.card;
		this.fire = this.card.fire;
		this.fireLogId = this.fire.id;
		this.prevLogs = this.card.fireLogs.filter((i) => new Date(i.date) < new Date(this.fire.date));
		this.currentVehicles = this.fire.vehicles();
		this.records = this.getRecords();
	}

	fetchRecords = async () => {
		//* сфетчим снова людей и поменяем пожару кэш в реляции
		this.currentVehicles = await store.model.FireFightingVehicle.find({ where: { fireLogId: this.fireLogId, include: VEHICLE_INCLUDE.scope.include } });
		this.fire._vehicles._cache = this.currentVehicles;
		this.records = this.getRecords();
	};

	onClosePopup = () => this.togglePopup(false);

	togglePopup = async (boolean) => {
		this.showPeoplePopup = boolean;
		if (!boolean) {
			this.selectedRecord = null;
			this.fetchRecords();
		}
	};

	onRowClick = (row) => {
		row.fireLogId = this.fireLogId;
		this.selectedRecord = row;
		this.togglePopup(true);
	};

	showEditPopup = async () => {
		this.selectedRecord = new store.model.FireFightingVehicle({ fireLogId: this.fireLogId });
		this.togglePopup(true);
	};

	renderFormation = (record) => record.formationShortName || record.formation?.shortName;
	renderCrewType = (record) => record.crewTypeShortName || record.crewType?.shortName;
	renderType = (record) => record.typeShortName || record.type?.shortName;
	renderAction = (record) => (record.id && !this.card.disabled ? <PopconfirmFaTimesIcon onConfirm={() => this.onDelete(record)} /> : ' ');

	onDelete = async (record) => {
		await record.delete();
		this.fetchRecords();
	};

	getRecords() {
		const prev = getVehiclesValues(this.prevLogs);
		//* найдем записи, которые есть в предыдущих логах, изменим их, добавим записи из текущей, если совпадения нет
		this.currentVehicles.forEach((currentLog) => {
			const sameLog = prev.find(
				(prev) => prev.crewTypeId === currentLog.crewTypeId && prev.formationId === currentLog.formationId && prev.typeId === currentLog.typeId
			);
			if (sameLog) {
				const { crewTypeId, formationId, typeId, ...properties } = store.model.FireFightingVehicle.PROPERTIES;
				Object.keys(properties).forEach((prop) => (sameLog[prop] = currentLog[prop]));
			} else {
				prev.push(currentLog);
			}
		});

		return prev.map((i) => new store.model.FireFightingVehicle(i));
	}

	render() {
		const { disabled } = this.card;
		const { records, renderType, renderCrewType, renderFormation, onRowClick, renderAction, showEditPopup, onClosePopup, selectedRecord, showPeoplePopup } =
			this;
		return (
			<Card title={t('fireFightingVehicle.plural')} icon={<ShieldTickIcon />} key={this.props.card.fire.id}>
				{showPeoplePopup && <EditVehiclePopup record={selectedRecord} onClose={onClosePopup} records={records} />}
				{!!records.length && (
					<Table rows={records} onRowClick={onRowClick} clickable={!disabled}>
						<Column computed={renderType} label={t('extinguishingAgentsType.title_short')} className='text-center ' />
						<Column computed={renderCrewType} label={t('crewType.title_short')} className='text-center ' />
						<Column computed={renderFormation} label={t('fireFightingFormation.title_short')} className='text-center' />
						<Column width={90} property='current' label={t('fireFightingVehicle.current_short')} className='text-center' />
						<Column width={90} property='required' label={t('fireFightingVehicle.required')} className='text-center' />
						<Column width={90} property='plan' label={t('fireFightingVehicle.plan')} className='text-center' />
						<Column width={25} computed={renderAction} clickable={false} />
					</Table>
				)}
				<RowAddButton onClick={showEditPopup} disabled={disabled} />
			</Card>
		);
	}
}
