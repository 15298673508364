import { observable } from 'mobx';
import { endOfDay, endOfMonth, endOfToday, startOfDay, startOfMonth } from 'date-fns';

export class ReportStore {
	@observable startDate = startOfMonth(new Date());
	@observable endDate = endOfToday();
	@observable year = null;
	@observable fireAreaAction = null;

	constructor(code) {
		this.code = code;
	}

	onStartDateChange = (value) => (this.startDate = value && startOfDay(value));
	onEndDateChange = (value) => (this.endDate = value && endOfDay(value));

	onChange = (prop) => (value) => (this[prop] = value);

	get storeParams() {
		const params = { reportCode: this.code };
		const reportParams = {};

		reportParams.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
		if (this.startDate) {
			reportParams.startDate = this.startDate;
			reportParams.startOfMonth = startOfMonth(this.startDate);
		}
		if (this.endDate) {
			reportParams.endDate = this.endDate;
			reportParams.endOfMonth = endOfMonth(this.endDate);
		}
		if (this.year) {
			reportParams.year = this.year;
		}
		if (Object.keys(reportParams).length) params.reportParams = reportParams;
		params.reportModel = 'FireReport';
		return params;
	}
}
