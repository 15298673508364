import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { RecordSelect, DatePicker } from '@smartplatform/ui';
import { FilterLayout } from 'components';
import icons from 'img/icons/techfires/map/visits';
import store from 'client/store';
import t from 'i18n';
import GarrisonSelect from '../../../modules/techfires/components/garrison-select/GarrisonSelect';
import techFiresStore from 'techfires/techFiresStore';

@observer
export default class DeparturesFilter extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		if (!store.isAdmin) {
			this.props.layer.checkAndClearFireDeps();
		}
	}

	onChange = async (rankCode, value) => {
		const { settings } = this.props.layer;
		settings.ranks[rankCode] = value;
		this.reload();
	};

	reload = async () => {
		this.isLoading = true;
		store.local.save();
		await this.props.layer.load();
		this.isLoading = false;
	};

	render() {
		const { layer } = this.props;
		const { onToggle, isLoading, settings } = layer;
		if (isLoading) return null;

		const legend = (
			<div className='legend'>
				{Object.keys(icons).map(
					(key) =>
						typeof icons[key] === 'object' && (
							<div className='d-flex' key={key}>
								<span>
									<img src={icons[key]?.src} alt='' height={16} />
								</span>
								<span>{icons[key].label}</span>
							</div>
						)
				)}
			</div>
		);

		const { fireDeps, visitTypes, garrisons, dateOfDepartureStart, dateOfDepartureEnd } = layer.settings.filters;

		const showFireDep = fireDeps.length > 0 ? t('fireDep.selected') + ': ' + fireDeps.length : <span className='placeholder'>{t('fireDep.select')}</span>;
		const showGarrison =
			garrisons.length > 0 ? t('techFire.selectedGarrisons') + ': ' + garrisons.length : <span className='placeholder'>{t('techFire.selectGarrison')}</span>;
		const showVisitType =
			visitTypes.length > 0 ? (
				t('techFire.firesPlanVisits.selected') + ': ' + visitTypes.length
			) : (
				<span className='placeholder'>{t('techFire.firesPlanVisits.select')}</span>
			);

		const userGarrisonsLength = techFiresStore.userGarrisons.length;

		const fireDepWhere =
			userGarrisonsLength > 1 ? { where: { garrisonId: { inq: garrisons?.map((garrison) => garrison.id) } } } : { where: { garrisonId: garrisons.id } };

		return (
			<FilterLayout layer={layer} title={t('techFire.firesPlanVisits.plural')} isLoading={this.isLoading} legend={legend} className='techfire-filters'>
				<div>
					<label>{t('garrison')}</label>
					<GarrisonSelect
						value={userGarrisonsLength === 1 ? null : garrisons}
						showValue={userGarrisonsLength === 1 ? null : showGarrison}
						onChange={(garrison) => onToggle(garrison, 'garrisons')}
						setDefault={userGarrisonsLength === 1}
						disabled={userGarrisonsLength === 1 || !settings.show}
						width={250}
					/>
				</div>
				<div>
					<label>{t('techFire.fireDep')}</label>
					<RecordSelect
						model={store.model.ViewFireDep}
						computed={(fireDep) => fireDep.name}
						value={fireDeps}
						onChange={(fireDep) => onToggle(fireDep, 'fireDeps')}
						showValue={showFireDep}
						filter={fireDepWhere}
						disabled={!settings.show}
						width={250}
					/>
				</div>
				<div>
					<label>{t('techFire.firesPlanVisits.visitType')}</label>
					<RecordSelect
						model={store.model.TechFireVisitType}
						computed={(visit) => visit.name}
						value={visitTypes}
						onChange={(visitTypes) => onToggle(visitTypes, 'visitTypes')}
						showValue={showVisitType}
						disabled={!settings.show}
						width={250}
					/>
				</div>
				<div>
					<label>{t('techFire.firesPlanVisits.dateOfDeparture')}</label>
					<DatePicker
						onChange={(dateOfDepartureStart) => onToggle(dateOfDepartureStart, 'dateOfDepartureStart')}
						value={dateOfDepartureStart}
						showTimeSelect
						showClearButton={false}
						disabled={!settings.show}
					/>
					<DatePicker
						onChange={(dateOfDepartureEnd) => onToggle(dateOfDepartureEnd, 'dateOfDepartureEnd')}
						value={dateOfDepartureEnd}
						showTimeSelect
						showClearButton={false}
						disabled={!settings.show}
						maxDate={new Date()}
					/>
				</div>
			</FilterLayout>
		);
	}
}
