import React from 'react';
import { observer, Provider } from 'mobx-react';
import { Panel } from 'components';
import './style.scss';
import { formatDate } from 'client/tools';
import ActionsPanel from './ActionsPanel';
import DashboardStore from './store';
import { DASHBOARD_SECTIONS } from './sections';

@observer
export default class Dashboard extends React.Component {
	constructor(props) {
		super(props);
		this.store = new DashboardStore();
	}

	render() {
		const { section, date, exportDate } = this.store;
		const isOperational = section.value === DASHBOARD_SECTIONS[0].value;

		const getPrintSpan = (span) => <span className='print-only'>{span} </span>;
		let label = (
			<h2 className='label'>
				<span>
					{section.label} {getPrintSpan(`за ${formatDate(date)}`)}
				</span>
				{getPrintSpan(`Сгенерировано в ${formatDate(exportDate, 'dd.MM.yyyy HH:mm:ss')}`)}
			</h2>
		);

		if (!isOperational) label = <h2>{section.label}</h2>;

		return (
			<Provider dashboardStore={this.store}>
				<div className='fires-dashboard-page'>
					{label}
					<ActionsPanel />
					{section?.component}
				</div>
			</Provider>
		);
	}
}
