import { observable } from 'mobx';

export default class FireReportStore {
	@observable year = new Date().getFullYear();
	@observable month = new Date().getMonth() + 1;

	get storeParams() {
		return {
			reportCode: 'LO2',
			reportParams: { month: this.month, year: this.year, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone },
			isHTML: true,
		};
	}

	onMonthChange = (m) => (this.month = m);
	onYearChange = (y) => (this.year = y);
}
