import { observable } from 'mobx';
import { ListStore } from 'components';
import store from 'client/store';
import { VEHICLE_INCLUDE } from './constants';

export default class VehicleListStore extends ListStore {
	@observable statuses = [];
	@observable callSigns = [];
	@observable brand = null;
	@observable type = null;
	@observable years = [];

	constructor(path, fireDepId) {
		super({ path, filters: { ...VEHICLE_INCLUDE, where: { fireDepShiftId: null, fireDepId } }, model: store.model.FireDepVehicle });
		this.fireDepId = fireDepId;
		this.init();
	}

	init = async () => {
		let [statuses, callSigns, years] = await Promise.all([
			store.model.FireDepVehicleStatus.find({ where: { code: { neq: 'FF' } }, fields: ['id', 'name', 'code'] }),
			store.model.FireDepVehicleCallSign.find({ where: { fireDepId: this.fireDepId }, fields: ['id', 'name', 'code'] }),
			store.model.FireDepVehicle.find({ where: { fireDepShiftId: null, fireDepId: this.fireDepId, fields: ['id', 'year'] } }),
		]);
		this.statuses = statuses;
		this.callSigns = callSigns;
		years = new Set(years.map((i) => i.year));
		years.delete(null);
		this.years = [...years].map((i) => ({ label: i, value: i }));
	};

	onChangeFilters = (prop) => (value) => {
		this[prop] = value;
		this.changeFilter();
	};

	changeFilter = () => {
		let where = { and: [] };

		if (this.year) where.and.push({ year: this.year });
		if (this.brand) where.and.push({ brandId: this.brand.id });
		if (this.type) where.and.push({ typeId: this.type.id });

		where = { ...this.filters.where, ...where };
		this.updateFilters({ ...this.filters, where });
	};

	onStatusSelect = async (status, record) => {
		record.status = status || null;
		await record.save();
	};

	onCallSignSelect = async (callSign, record) => {
		record.callSign = callSign || null;
		await record.save();
	};
}
