import React from 'react';
import { inject, observer } from 'mobx-react';
import { Card } from 'components';
import FireDepIcon from 'img/icons/sidebar/techfires/fireDep.svg';
import t from 'i18n';
import { Button, Checkbox, Column, RelationSelect, Table } from '@smartplatform/ui';
import { fioShort } from 'client/tools';
import { fireDepVehicleItem } from 'techfires/helpers';
import './fireDepCard.scss';
@inject('store')
@observer
export class FireDepCard extends React.Component {
	renderFio = (record) => {
		const { firstName, lastName, middleName, id } = record;
		const { onPersonnelChange, personnelIdSet } = this.props.store;
		const fio = fioShort({ firstName, lastName, middleName });
		if (!this.props.isEditable) return fio;
		return (
			<div>
				<Checkbox checked={personnelIdSet.has(id)} onChange={() => onPersonnelChange(record, this.props.fireDep)} />
				{fioShort({ firstName, lastName, middleName })}
			</div>
		);
	};

	renderPersonnelStatus = (record) => {
		return record.status?.name;
	};
	onVehicleChange = async (vehicle, row) => {
		const { record, row: rowIndex } = row;
		const { personnelIdSet } = this.props.store;
		const personnel = this.props.fireDep.personnel();

		// сбросить статус техники, если выбрана другая,а текущей больше нет на ЛП
		const prevValue = personnel[rowIndex];
		const hasSameVehicles = personnel.find((p, i) => i !== rowIndex && p.vehicleId === prevValue.vehicleId && personnelIdSet.has(p.id));
		if (prevValue.vehicleId && !hasSameVehicles) {
			prevValue.vehicle.vehicleStatusId = null;
			await prevValue.vehicle.save();
		}

		// сама смена техники
		record.vehicleId = vehicle?.id || null;
		record.vehicle = vehicle;
		await record.save();

		// поменять статус техники на ЛП
		const vehicleFFstatus = this.props.store.vehicleFFstatus;
		if (vehicle && vehicleFFstatus && this.props.store.personnelIdSet.has(record.id)) {
			record.vehicle.vehicleStatusId = vehicleFFstatus.id;
			await vehicle.save();
			record.vehicle.status = vehicleFFstatus;
		}
	};

	render() {
		const { fireDep, index, store, isEditable } = this.props;
		const { onFireDepDelete, record, onEditFireDep, personnelIdSet } = store;
		let fireDepPersonnel = fireDep.personnel();

		const isClosed = !!record.returnDate;

		if (!isEditable) {
			fireDepPersonnel = fireDepPersonnel.filter(({ id }) => personnelIdSet.has(id));
		}

		const title = (
			<div className='title'>
				<div>{fireDep.name}</div>
				{!isClosed && (
					<Button variant='primary' className='btn-small' onClick={() => onEditFireDep(fireDep.id)}>
						{isEditable ? t('forestFireMovement.cancelEdit') : t('forestFireMovement.edit')}
					</Button>
				)}
			</div>
		);
		const columns = [
			{ label: t('lastName'), computed: this.renderFio },
			{ label: t('fireDepPosition.title'), relation: 'position', property: 'name' },
			!isClosed && { label: t('fireDepPersonnel.status'), computed: this.renderPersonnelStatus },
			{
				label: t('fireDepVehicle.title'),
				relation: 'vehicle',
				width: 260,
				children: (row) => (
					<RelationSelect
						record={row.record}
						relation='vehicle'
						disabled={!isEditable || !this.props.store.personnelIdSet.has(row.record.id)}
						computed={fireDepVehicleItem}
						onChange={(vehicle) => this.onVehicleChange(vehicle, row)}
						filter={{ where: { fireDepId: fireDep.id, fireDepShiftId: null } }}
					/>
				),
			},
			!isClosed && { label: t('fireDepVehicleStatus.title'), relation: 'vehicle', computed: (v) => this.renderPersonnelStatus(v) },
		].filter(Boolean);
		return (
			<Card className='firedep-card' title={title} icon={<FireDepIcon />} fireDep={fireDep} onDelete={!isClosed && (() => onFireDepDelete(fireDep, index))}>
				<Table rows={fireDepPersonnel}>
					{columns.map((col) => (
						<Column {...col} key={col.label} />
					))}
				</Table>
			</Card>
		);
	}
}
