import React from 'react';
import { observer, inject } from 'mobx-react';
import ExtinguisherIcon from 'img/icons/techfires/card/extinguisher.svg';
import store from 'client/store';
import { FieldBasedCard, EntityManager } from '../../../../components';

@inject('fireStore')
@observer
class Trunks extends React.Component {
	render() {
		const { fireStore } = this.props;
		const { fire } = fireStore;

		const fields = [
			{ type: 'select', property: 'type', options: { model: store.model.TechFireTrunkType, property: 'name' } },
			{
				type: 'number',
				property: 'county',
				disabled: true,
				options: { model: store.model.TechFireTrunk, property: 'county' },
			},
		];

		const cardContent = <EntityManager modelName='TechFireTrunk' buttonLabel='techFireTrunk.add' fields={fields} fireStore={fireStore} />;

		return (
			<FieldBasedCard title='trunk.plural' icon={ExtinguisherIcon} fields={[fire?.trunks().length > 0]} rows={[]} statusKey='trunks' fireStore={fireStore}>
				{cardContent}
			</FieldBasedCard>
		);
	}
}

export default Trunks;
