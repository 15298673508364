import React from 'react';
import { FormContext } from '@smartplatform/ui';
import { DeleteButton } from './DeleteButton';
import { SaveButton } from './SaveButton';
import { CancelButton } from './CancelButton';
import { CleanButton } from './CleanButton';
import { LastEditField } from 'components/fields';
import './controls.scss';

export const ControlsContext = React.createContext({});

export class Controls extends React.Component {
	static contextType = FormContext;

	render() {
		let { children, controls, isNew, ...restProps } = this.props;
		const context = { ...this.context, ...restProps };
		isNew = typeof isNew === 'boolean' ? isNew : !context.record?.id;
		const hasEditorMixin = !!context.record?.MODEL.RELATIONS.editor;

		if (!children) {
			children = (
				<>
					<Controls.SaveButton />
					<Controls.CancelButton />
					{isNew ? <Controls.CleanButton /> : <Controls.DeleteButton />}
					{!isNew && hasEditorMixin && <LastEditField record={context.record} />}
				</>
			);
		}
		return (
			<ControlsContext.Provider value={context}>
				<div className='custom-controls'>
					{children} {controls}
				</div>
			</ControlsContext.Provider>
		);
	}
}

Controls.SaveButton = SaveButton;
Controls.CancelButton = CancelButton;
Controls.DeleteButton = DeleteButton;
Controls.CleanButton = CleanButton;
