import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Form, Field, Checkbox } from '@smartplatform/ui';
import t from 'i18n';
import { RenderData } from './RenderData';

import './style.scss';

@inject('fireDepStore')
@observer
export default class MainInfo extends Component {
	constructor(props) {
		super(props);
		this.fireDepStore = props.fireDepStore;
	}

	render() {
		const { fireDep } = this.fireDepStore;

		return (
			<Form record={fireDep} noSave noDelete>
				<Field label={t('fireDep.name')}>{fireDep.name || '-'}</Field>
				<Field label={t('fireDep.nameDepartment')}>{fireDep.nameDepartment || '-'}</Field>
				<Field label={t('fireDep.isSPT')} property='spt' disabled />
				<Field label={t('city.title')}>{fireDep.city?.name || '-'}</Field>
				<Field label={t('county.title')}>{fireDep.county?.name || '-'}</Field>
				<Field label={t('fireDep.address')}>{fireDep.address || '-'}</Field>
				<Field label={t('fireDep.phone')}>
					<RenderData property='phone' />
				</Field>
				<Field label={t('fireDep.staffUnits')}>{<RenderData property='staffUnits' />}</Field>
				<Field label={t('fireDep.officerUnits')}>{<RenderData property='officerUnits' />}</Field>
			</Form>
		);
	}
}
