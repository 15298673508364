import React from 'react';
import { observer } from 'mobx-react';

import { ModelsFilter } from 'components';
import store from 'client/store';
import { modelsConfigDelivery } from "components/common-layers/models/models-config";

@observer
export default class ExtraLayers extends React.Component {
	render() {
		const localSettings = store.local.delivery;
		const settings = localSettings.layers;

		return (
			<div className='fires-extra-layers'>
				<ModelsFilter layer={this.props.modelsLayer} settings={settings.models} modelsConfig={modelsConfigDelivery} />
			</div>
		);
	}
}
