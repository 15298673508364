import { observable } from 'mobx';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import MultiPoint from 'ol/geom/MultiPoint';
import Feature from 'ol/Feature';
import { Fill, Stroke, Style, Icon, Circle as CircleStyle } from 'ol/style';

import { geoJSON, getAllPoints } from '@smartplatform/map/client';
import marker from 'img/icons/marker.svg?url';
import { PermafrostObjectLayer } from "./filters/layers/permafrostObject";
import { PermafrostTemperatureTubeLayer } from "./filters/layers/permafrostTemperatureTube";
import { PermafrostLevelingMarkLayer } from "./filters/layers/permafrostLevelingMark";

import store from "client/store";

const fill = new Fill({ color: 'rgba(0, 80, 255, 0.3)' });
const stroke = new Stroke({ color: 'rgba(0, 80, 255, 0.6)', width: 3 });

const DEFAULT_STYLE = new Style({ fill, stroke, image: new Icon({ src: marker, anchor: [10, 27], anchorXUnits: 'pixels', anchorYUnits: 'pixels' }) });
const OTHER_STYLE = new Style({
	fill: new Fill({ color: 'rgba(80, 80, 80, 0.2)' }),
	stroke: new Stroke({ color: 'rgba(80, 80, 80, 0.6)', width: 2, lineDash: [2, 4] }),
	image: new Icon({ src: marker, anchor: [10, 27], anchorXUnits: 'pixels', anchorYUnits: 'pixels' }),
});

const DRAW_STYLE = [
	new Style({ fill, stroke }),
	new Style({
		image: new CircleStyle({
			radius: 5,
			fill: new Fill({ color: 'rgba(40, 120, 255, 1)' }),
			stroke: new Stroke({ color: 'rgba(0, 80, 255, 1)', width: 1 }),
		}),
		geometry: function (feature) {
			const coordinates = getAllPoints(feature.getGeometry());
			return new MultiPoint(coordinates);
		},
	}),
];

export default class GeoEditStore {
	@observable mapInitialized = false;
	@observable mapMaximized = false;
	@observable changed = false;

	layers = {};

	constructor(props) {
		this.props = props;
		this.geo = props.value;
		this.otherGeometry = props.otherGeometry;
		this.defaultStyle = props.defaultStyle ?? DEFAULT_STYLE;
		console.log('geo', this.geo);
	}

	onMapInit = async (mapStore) => {
		this.mapStore = mapStore;
		this.map = mapStore.map;

		if(store.local.permafrost) {
			this.layersSettings = store.local.permafrost?.layers;

			this.layers.permafrostObjectLayer = new PermafrostObjectLayer(mapStore, this.layersSettings.permafrostObject);
			this.layers.permafrostTemperatureTubeLayer = new PermafrostTemperatureTubeLayer(mapStore, this.layersSettings.permafrostTemperatureTube);
			this.layers.permafrostLevelingMarkLayerLayer = new PermafrostLevelingMarkLayer(mapStore, this.layersSettings.permafrostLevelingMark);

			await this.layers.permafrostObjectLayer.init(mapStore);
			await this.layers.permafrostTemperatureTubeLayer.init(mapStore);
			await this.layers.permafrostLevelingMarkLayerLayer.init(mapStore);

			mapStore.on('cleanup', this.destroy);
			this.mapInitialized = true;
		}

		mapStore.on('modifyend', this.onDrawAction);
		mapStore.on('drawend', this.onDrawAction);
		mapStore.on('modifystart', this.onDrawAction);
		mapStore.on('drawstart', this.onDrawAction);

		this.source = new VectorSource();
		this.layer = new VectorLayer({
			format: geoJSON,
			source: this.source,
			style: this.defaultStyle,
		});

		this.otherSource = new VectorSource();
		this.otherLayer = new VectorLayer({
			format: geoJSON,
			source: this.otherSource,
			style: OTHER_STYLE,
		});

		this.mapStore.addLayer(this.otherLayer);
		this.otherLayer.setZIndex(1);
		this.mapStore.addLayer(this.layer);
		this.layer.setZIndex(10);

		this.addGeo();
		this.mapInitialized = true;

		if (this.props.fitGeo) {
			this.mapStore.fitGeo(this.props.fitGeo);
		}

		// this.startDrawing();
	};

	startDrawing = () => {
		this.origGeo = this.geo;
		this.layer.setStyle(DRAW_STYLE);
		this.mapStore.startDrawing(this.layer);
	};

	onDrawAction = async () => {
		this.geo = await this.mapStore.getGeoFromDrawing();
		if (this.props.onChange) this.props.onChange(this.geo);
		this.changed = true;
	};

	finishDrawing = async () => {
		this.geo = await this.mapStore.getGeoFromDrawing();
		// console.log('geo', this.geo);
		if (this.props.onChange) this.props.onChange(this.geo);
		this.layer.setStyle(this.defaultStyle);
		this.mapStore.stopDrawing();
		this.changed = false;
	};

	cancelDrawing = () => {
		this.geo = this.origGeo;
		this.changed = false;
		this.addGeo();
		this.layer.setStyle(this.defaultStyle);
		this.mapStore.stopDrawing();
	};

	destroy = () => {
		this.mapStore.off('cleanup', this.destroy);
		this.mapInitialized = false;
	};

	addGeo = () => {
		this.source.clear();
		this.otherSource.clear();
		if (this.otherGeometry && (this.otherGeometry.geometries || !!this.otherGeometry.coordinates.length)) {
			const otherGeometry = geoJSON.readGeometry(JSON.stringify(this.otherGeometry));
			this.feature = new Feature({ geometry: otherGeometry });
			this.otherSource.addFeature(this.feature);
			this.mapStore.fitGeo(JSON.stringify(this.otherGeometry));
			console.log('otherGeometry', this.otherGeometry);
		}
		if (!this.geo) return;
		const geometry = geoJSON.readGeometry(this.geo);
		this.feature = new Feature({ geometry });
		this.source.addFeature(this.feature);
		try {
			this.mapStore.fitGeo(this.geo, this.props.params.zoom || 14);
		} catch (e) {
			console.error(e);
		}
	};
}
