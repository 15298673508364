import { observable } from 'mobx';
import {drawGeoMarker, geoJSON} from "@smartplatform/map/client";
import IsdmFire from 'components/common-layers/models/models-config/isdm';
import {endOfDay, startOfDay} from "date-fns";
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';

import store from 'client/store';

export default class IsdmLayer {
	@observable layer = null;
	@observable isLoading = false;

	constructor(mapStore, settings = {}, params = {}) {
		this.mapStore = mapStore;
		this.settings = settings;
	}

	init = async () => {
		this.layer = new VectorLayer({
			format: geoJSON,
			source: new VectorSource(),
		});
		this.mapStore.addLayer(this.layer);
		this.load();
	};

	load = async () => {
		let isdms = [];
		const { startDate, endDate } = this.settings;
		const start = startDate ? { gte: startOfDay(new Date(startDate)) } : undefined;
		const end = endDate ? { lte: endOfDay(new Date(endDate)) } : undefined;

		if (this.settings.show) {
			isdms = await store.model.IsdmFire.find({
				where: {
					and: [
						{ geo: { neq: null } },
						{ reportDate: end },
						{ reportDate: end ? undefined : start },
						{ or: [ { and: [{ reportDate: start }, { reportDate: end }] }] },
					],
				},
				fields: ['id', 'geo', 'fireNumber'],
			});
		}

		const features = [];
		for (let isdm of isdms) {
			const { geo } = isdm;
			const data = {
				title: IsdmFire.title,
				record: isdm,
				render: IsdmFire.renderPopup,
			};
			const style = IsdmFire.style;

			const marker = drawGeoMarker(geo, {
				data,
				style,
			});
			features.push(...marker);
		}

		this.layer.getSource().clear();
		this.layer.getSource().addFeatures(features);
	};

	setVisible = () => {
		this.layer.setVisible(this.settings.show);
		this.load();
	};
};