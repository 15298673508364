import Airfield from './airfield';
import Fire from './fire';
import Camera from './camera';
import FireData from './heat-points';
import ForestryQuarter from './forestry-quarter';
import TechnoZone from './techno-zone';
import AviaDep from './aviadep';
import City from './city';

export const modelsConfig = {
	Camera,
};

export const modelsConfigFireMap = {
	Airfield,
	TechnoZone,
	AviaDep,
	City,
};

export const modelsConfigDelivery = {
	City,
};

export const modelsConfigAll = {
	Airfield,
	Camera,
	Fire,
	ViewFirePublic: Fire,
	FireData,
	ForestryQuarter,
	TechnoZone,
	AviaDep,
	City,
};
