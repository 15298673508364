import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { fio } from 'client/tools';
import t from 'i18n';
import Language from './Language';
import ModuleSelect from './module-select';
import logo from 'client/img/logo.svg?url';
import signOut from 'client/img/icons/shared/signOut.svg?url';
import './style.scss';
import store from 'client/store';
import { Avatar } from '@smartplatform/ui';

@observer
export default class Header extends React.Component {
	@observable isAdmin = false;

	logout = (e) => {
		e.preventDefault();
		store.model.logout();
		store.isLogged = false;
	};

	render() {
		return (
			<header className='main-header'>
				<div className='left'>
					<div className='site-title'>
						<Link to='/'>
							<img className='logo' src={logo} width={28} height={50} alt='' />
							<div className='title'>{t('title')}</div>
						</Link>
					</div>
					<ModuleSelect />
				</div>
				<div className='right'>
					{store.currentModule === 'delivery' && <Link to='/delivery/npa'>{t('requestNPA.list')}</Link>}
					{store.model.user && (
						<div className='user-link'>
							{store.model.user.avatar && <Avatar user={store.model.user} className='user-avatar' size={40} />}
							<Link to={store.module?.path + '/profile'}>{fio(store.model.user)}</Link>
						</div>
					)}
					<Language />
					<a href='#' onClick={this.logout}>
						<img className='signOut' src={signOut} width={28} height={28} alt='signOut' />
					</a>
				</div>
			</header>
		);
	}
}
