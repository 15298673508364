import React from 'react';
import { ColorPicker, DatePicker, Field, Tab, Tabs } from '@smartplatform/ui';
import { StyleEdit } from '@smartplatform/map/client';
import t from 'i18n';
import store from 'client/store';
import { ModelEdit, HasManyList } from 'components';
import GeoEdit from 'components/geo-edit/GeoEdit';
import { EXCLUDED_FIELDS } from './constants';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { getLabelName } from 'client/tools';
import PropTypes from 'prop-types';
import './universalEdit.scss';
@observer
export class UniversalEdit extends React.Component {
	@observable record;
	filter = {};

	static propTypes = {
		editProperties: PropTypes.array,
		excludeProperties: PropTypes.array,
		excludeRelations: PropTypes.array,
	};

	static defaultProps = {
		editProperties: [],
		excludeProperties: [],
		excludeRelations: [],
	};

	@observable error = null;
	constructor(props) {
		super(props);
		this.modelName = props.modelName;
		this.path = props.path;
		this.model = store.model[this.modelName];
		this.id = parseInt(props.match.params.id);
		this.isNew = !Number.isInteger(this.id);
		if (!this.model) {
			this.error = 'Invalid model/no read access';
		} else {
			let { editProperties, editRelations, excludeProperties } = props;
			editProperties = editProperties.map((i) => (i instanceof Object ? i.name : i));
			const excluded = [...EXCLUDED_FIELDS, ...excludeProperties];
			const { PROPERTIES, RELATIONS } = this.model;
			//properties убираются исключения , ключи реляций ИЛИ editProperties
			const modelForeignKeys = Object.values(RELATIONS).map(({ foreignKey }) => foreignKey);
			this.properties = Object.entries(PROPERTIES).reduce((acc, [key, value]) => {
				if (![...modelForeignKeys, ...excluded].includes(key)) {
					if (editProperties.length && !editProperties.includes(key)) {
						return acc;
					}
					acc.push({ ...value, name: key });
				}
				return acc;
			}, []);

			//rels belongsTo реляции без owner ИЛИ editRelations
			if (!editRelations) {
				this.relations = Object.keys(RELATIONS)
					.filter((relation) => RELATIONS[relation].name !== 'owner' && RELATIONS[relation].type === 'belongsTo')
					.map((relation) => ({ ...RELATIONS[relation], property: 'name' }));
			} else {
				this.relations = editRelations?.slice(0).map((relation) => ({ ...relation, ...RELATIONS[relation.relation] })) || [];
			}

			//filter - в инклюде реляции с филдами, в филдах поля проперти и ключи реляций
			this.filter.include = this.relations.map(({ name, properties = [], property }) => ({
				relation: name,
				scope: { fields: property || properties },
			}));
			this.filter.fields = [
				// айдишка чтобы подтягивать реляции hasMany/manyToMany
				'id',
				// филды пропов
				...this.properties.map(({ name }) => name),
				// ключи реляций belongsto
				...this.relations.filter(({ type }) => type === 'belongsTo').map(({ foreignKey }) => foreignKey),
			];
		}
	}

	getRecord = (record) => {
		this.record = record;
		this.props.onEdit && this.props.onEdit(record);
	};

	render() {
		const {
			belongsTo = [],
			hasMany = [],
			hasAndBelongsToMany = [],
		} = this.relations.reduce((acc, relation) => {
			if (!acc[relation.type]) acc[relation.type] = [];
			acc[relation.type].push(relation);
			return acc;
		}, {});

		const { onEdit, Component, disabled = !this.model.INFO.WRITE, excludeProperties, excludeRelations } = this.props;

		const mainInfoForm = (
			<>
				<ModelEdit
					model={this.model}
					getRecord={this.getRecord}
					id={this.id}
					path={this.path}
					filter={this.filter}
					disabled={disabled}
					noSave={disabled}
					noDelete={disabled}
					className='universal-edit'
				>
					{this.properties
						.filter((property) => !excludeProperties.includes(property.name))
						.map(({ type, name }) => {
							const isTimeStamp = ['updatedAt', 'createdAt'].includes(name);
							if (isTimeStamp && this.isNew) return null;

							const label = getLabelName(name, this.modelName);
							let content;
							if (name === 'color') {
								content = <ColorPicker />;
							} else if (type === 'Geography') {
								content = <GeoEdit />;
							} else if (type === 'Object' && name) {
								content = <StyleEdit noIcon />;
							} else if (type === 'Date') {
								content = <DatePicker format='dd.MM.yyyy' showClearButton={false} />;
							}

							// если проп переделаи как объект и у него есть ключ children
							const prop = this.props.editProperties.find((prop) => prop instanceof Object && prop.name === name);

							if (prop && prop.component) {
								content = prop.component;
							}

							return (
								<Field key={name} property={name} label={label} disabled={isTimeStamp}>
									{content}
								</Field>
							);
						})}
					{belongsTo
						.filter((relation) => !excludeRelations.includes(relation.name))
						.map(({ name, property, computed, children, filter = {}, disabled, excludeRecordId }) => {
							const label = getLabelName(name, this.modelName);
							if (excludeRecordId && this.record?.id) {
								filter.where = { ...filter.where, id: { neq: this.record?.id } };
							}

							return (
								<Field
									key={'relation_' + name + property}
									relation={name}
									property={property}
									computed={computed}
									label={label}
									filter={filter}
									disabled={disabled}
								>
									{children}
								</Field>
							);
						})}
					{hasAndBelongsToMany
						.filter((relation) => !excludeRelations.includes(relation.name))
						.map(({ name, property, computed, children, filter, disabled }) => {
							const label = getLabelName(name, this.modelName);
							return (
								<Field
									key={'relation_' + name + property}
									relation={name}
									property={property}
									computed={computed}
									label={label}
									filter={filter}
									disabled={disabled}
									itemTag={(f) => f[property]}
								></Field>
							);
						})}
				</ModelEdit>
				{!!this.id && Component && <Component id={this.id} />}
			</>
		);

		if (hasMany.length) {
			const currentPath = this.props.match.url;
			const record = new this.model({ id: this.id });
			return (
				<Tabs>
					<Tab title={t('mainInfo')} path={currentPath} exact render={() => mainInfoForm} />
					{hasMany.map((relation, index) => (
						<Tab
							title={getLabelName(relation.name, this.modelName)}
							exact
							key={index}
							path={`${currentPath}/${relation.name}`}
							render={() => <HasManyList record={record} relation={relation.name} properties={relation.properties} include={relation.include} />}
						/>
					))}
				</Tabs>
			);
		}

		return mainInfoForm;
	}
}
