import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import store from 'client/store';
import { LabelField } from 'components';
import t from 'i18n';
import { format } from 'date-fns';
import './shipPopup.scss';
import { Row } from '@smartplatform/ui';
@observer
export class ShipTrackPointPopup extends React.Component {
	@observable record = null;

	componentDidMount = async () => {
		this.init();
	};

	init = async () => (this.record = await store.model.ShipTrack.findById(this.props.record.id));

	render() {
		if (!this.record) return null;

		return (
			<div className='ship-popup'>
				<Row>
					<LabelField label={t('speed')} children={this.record.speed ?? '-'} />
					<LabelField label={t('course')} children={this.record.course ?? '-'} />
				</Row>
				<Row>
					<LabelField label={t('ship.receiveDate')} children={this.record.receiveDate ? format(new Date(this.record.receiveDate), 'dd.MM.yyyy HH:mm') : '-'} />
					<div />
				</Row>
			</div>
		);
	}
}
