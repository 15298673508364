import React from 'react';
import Root from './Root';
import MapIcon from 'img/icons/sidebar/shared/map.svg';
import AdminIcon from 'img/icons/sidebar/shared/admin.svg';
import DictionaryIcon from 'img/icons/sidebar/shared/dictionary.svg';
import TemperatureIcon from 'img/icons/sidebar/permafrost/temperature.svg';
import LevelingMarkIcon from 'img/icons/sidebar/permafrost/leveling-mark.svg';
import ObjectsIcon from 'img/icons/sidebar/permafrost/objects.svg';
import MeasurementsIcon from 'img/icons/sidebar/permafrost/measurements.svg';
import Permafrost from 'img/icons/menu/permafrost.svg';
import t from 'i18n';
import tMap from '@smartplatform/map/i18n';
import store from 'client/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import JourneyIcon from 'img/icons/sidebar/shared/journey.svg';

export const path = '/permafrost';

export default {
	name: t('module.permafrost'),
	code: 'permafrost',
	icon: <Permafrost />,
	path,
	component: Root,
	access: () => store.model.PermafrostObject && store.model.PermafrostObject.INFO.READ,
	menu: () => [
		{ title: t('permafrost.map'), path: `${path}/map`, icon: <MapIcon />, exact: true },
		{ title: t('permafrost.measurementsMenu'), path: `${path}/measurements`, icon: <MeasurementsIcon /> },
		{ title: t('permafrost.objectsMenu'), path: `${path}/objects`, icon: <ObjectsIcon /> },
		{ title: t('permafrost.tubeMenu'), path: `${path}/temperature-tubes`, icon: <TemperatureIcon /> },
		{ title: t('permafrost.marksMenu'), path: `${path}/leveling-marks`, icon: <LevelingMarkIcon /> },
		{ title: t('dictionaries.title'), path: `${path}/dictionaries`, icon: <DictionaryIcon /> },
		!store.model.roles.find((role) => ['user_permafrost'].includes(role.name))
			? { title: tMap('map.layer.plural'), path: `/layers`, icon: <FontAwesomeIcon icon={faLayerGroup} style={{ fontSize: 26 }} /> }
			: undefined,
		{ title: t('audit.title'), path: `${path}/journal`, icon: <JourneyIcon /> },
		{ title: t('admin.titleShort'), path: `${path}/admin`, icon: <AdminIcon /> },
	],
};
