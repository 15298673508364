import {observable} from "mobx";

export default class DepartmentWorkRecordCardStore {
	@observable month = new Date().getMonth();
	@observable year = new Date().getFullYear();
	@observable aviaDep = null;

	onMonthChange = (month) => (this.month = month);

	onYearChange = (year) => (this.year = year);

	onAviaDepChange = (aviaDep) => (this.aviaDep = aviaDep);

	get storeParams() {
		const { month, year, aviaDep } = this
		return {
			reportCode: 'DepartmentWorkRecordCard',
			reportParams: { month, year, aviaDep, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone },
			isHTML: true,
		};
	}
}
