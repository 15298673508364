import classNames from 'classnames';
import React from 'react';
import { PopconfirmFaTrashIcon, AutoAnimate } from 'components';
import './card.scss';
import ArrowIcon from 'img/icons/techfires/card/arrow.svg';

export const Card = ({ children, title, icon, className, onDelete, collapsible, initialCollapsed = false }) => {
	const [showContent, setShowContent] = React.useState(!initialCollapsed);
	const onCollapseToggle = () => setShowContent(!showContent);

	const _className = classNames(className, 'card');

	return (
		<AutoAnimate className={_className}>
			<div className='card-title'>
				<div className='icon-title'>
					{icon}
					<h2>{title}</h2>
				</div>
				<div className='card-actions'>
					{onDelete && <PopconfirmFaTrashIcon onConfirm={onDelete} />}
					{collapsible && <ArrowIcon onClick={onCollapseToggle} className={classNames('collapse-icon', { collapsed: !showContent })} />}
				</div>
			</div>
			{(showContent || !collapsible) && <div className={classNames('card-content')}>{children}</div>}
		</AutoAnimate>
	);
};
