import React from 'react';
import { observer, Provider } from 'mobx-react';
import { Column } from '@smartplatform/ui';
import { Table } from 'components/table-new';
import RegistryStore from './store';
import { Filters } from './filters';
import { getStatusColor } from 'fires/helpers';
import store from 'client/store';
import { SCROLL_PART_SIZE } from "client/constants";
import t from 'i18n';
import './style.scss';
@observer
export default class Registry extends React.Component {
	constructor(props) {
		super(props);
		this.store = new RegistryStore();
	}

	renderStatusColor = (record) => <span className='status-color' style={{ borderColor: getStatusColor(record) }} />;

	render() {
		const { query, onQueryUpdate, onRowClick, getInstance, currentFires } = this.store;
		const { count, area, forestArea, nonForestArea } = currentFires;
		const tableProps = {
			model: store.model.ViewFire,
			query,
			onQueryUpdate,
			filters: true,
			onRowClick,
			className: 'registry-table',
			getInstance,
			infinityScroll: true,
			infinityScrollPartSize: SCROLL_PART_SIZE,
		};
		const colorColumn = <Column computed={this.renderStatusColor} className='color-column' width={1} />;
		const current = `Действует пожаров: ${count || 0} - количество | ${area || 0} - площадь общая, га | ${forestArea || 0} - площадь лесная, га | ${nonForestArea || 0} - площадь нелесная, га.`;
		return (
			<Provider store={this.store}>
				<div className='fire-registry'>
					<div className='top'>
						<h2>{t('fire.registry.title')}</h2>
						<Filters />
					</div>
					<div className='bottom'>
						{this.store.query && (
							<Table {...tableProps}>
								{colorColumn}
								{this.store.renderFields.map((col) => {
									return <Column {...col} label={t(col.label)} key={col.label} />;
								})}
							</Table>
						)}
					</div>
					<div className='mt-2 font-weight-bold'>{current}</div>
				</div>
			</Provider>
		);
	}
}
