import { io } from 'socket.io-client';

export class SocketStore {
	reconnectionDelay = 10000;

	constructor(store) {
		this.store = store;
		this.socket = new io({
			reconnectionDelay: this.reconnectionDelay,
			reconnectionDelayMax: this.reconnectionDelay,
		});
	}

	setReconnectionDelay = (value) => {
		this.reconnectionDelay = value;
		this.socket.disconnect();
		this.socket = new io({
			reconnectionDelay: this.reconnectionDelay,
			reconnectionDelayMax: this.reconnectionDelay,
		});
		this.socket.connect();
	};

	setSocketParams = (params = {}) => {
		this.socket.disconnect();
		this.socket = new io(params);
		this.socket.connect();
	};
}
