import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@smartplatform/ui';
import './style.scss';
import classNames from 'classnames';

// проп "children" по идее делает то же самое что и "text", но лучше с точки зрения паттерна композиции

export const IconButton = ({ faIcon, icon, children, text, disabled, className, ...restProps }) => {
	if (faIcon) {
		icon = <FontAwesomeIcon icon={faIcon} />;
	}

	const _className = classNames('icon-btn', className);
	return (
		<Button className={_className} variant='primary' disabled={disabled} {...restProps}>
			{icon}
			{text}
			{children}
		</Button>
	);
};

IconButton.propTypes = {
	onClick: PropTypes.func,
	disabled: PropTypes.bool,
	faIcon: PropTypes.object,
	icon: PropTypes.object,
	model: PropTypes.string,
	size: PropTypes.oneOfType([PropTypes.oneOf(['small', 'default', 'large', 'sm', 'md', 'lg']), PropTypes.number]),
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
