import { endOfYear, startOfYear } from 'date-fns';
import { observable } from 'mobx';

export default class riverInformationFormStore {

	@observable year;
	onYearChange = (year) => this.year = year;

	get storeParams() {

		const { year } = this;
		const date = new Date(year, 1, 1);
		const startDate = startOfYear(date);
		const endDate = endOfYear(date);

		return {
			reportCode: 'riverInformationForm',
			reportParams: {startDate, endDate, year },
			reportModel: 'DeliveryReport'
		};
	}
}
