import React from 'react';
import { observer, Provider } from 'mobx-react';
import { observable } from 'mobx';
import { Loader, Tabs, Tab } from '@smartplatform/ui';
import MainInfo from './main';
import Personnel from './personnel';
import Vehicles from './vehicles';
import Edit from './edit';
import FireDepStore from './store';
import t from 'i18n';
import { HasManyList } from 'components';
import { WarehouseRoute } from './warehouse';
import { Schedule } from './schedule';
import Sentries from './sentries';
import './style.scss';
import { Facilities } from './facilities';

@observer
export default class EditFireDep extends React.Component {
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.init();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.match.params.id !== this.props.match.params.id) {
			this.init();
		}
	}

	init = async () => {
		this.store = new FireDepStore(this.props.match.params.id);
		this.store.path = this.props.path;
	};

	render() {
		if (this.store.isLoading) return <Loader />;
		const { id, fireDep, canEdit, isNew } = this.store;
		const path = this.props.match.url;

		const callSingsProps = { relation: 'callSigns', record: fireDep, exact: true };

		const tabs = isNew
			? [{ title: t('fireDep.edit'), path: `/techfires/firedep/create`, render: (props) => <Edit {...props} /> }]
			: [
					{ title: t('fireDep.mainInfo'), path, render: () => <MainInfo />, exact: true },
					{ title: t('fireDep.personnel'), path: `${path}/personnel`, render: (props) => <Personnel {...props} /> },
					{ title: t('fireDep.vehicles'), path: `${path}/vehicles`, render: (props) => <Vehicles {...props} /> },
					{ title: t('fireDep.callSigns'), path: `${path}/callSigns`, render: (props) => <HasManyList {...props} {...callSingsProps} /> },
					{ title: t('fireDep.edit'), path: `${path}/edit`, render: (props) => <Edit {...props} /> },
					{ title: t('fireDep.warehouse'), path: `${path}/warehouse`, render: () => <WarehouseRoute fireDep={fireDep} id={id} /> },
					{ title: t('fireDep.sentries'), path: `${path}/sentries`, render: (props) => <Sentries {...props} /> },
					{ title: t('techFire.schedule'), path: `${path}/schedule`, render: (props) => <Schedule fireDepId={id} {...props} /> },
					{ title: t('fireDep.facilities'), path: `${path}/facilities`, render: (props) => <Facilities fireDepId={id} {...props} /> },
			  ];

		return (
			<div className='firedep'>
				<h2>{fireDep.name}</h2>
				<Provider fireDepStore={this.store}>
					<Tabs>
						{tabs.map((tab) => (
							<Tab {...tab} key={tab.title} />
						))}
					</Tabs>
				</Provider>
			</div>
		);
	}
}
