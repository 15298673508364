import { defaultsDeep } from 'lodash';
import { toJS } from 'mobx';
import store from 'client/store';
import { startOfMonth } from 'date-fns';

const layers = {
	store: 'emergency',
	counties: {
		show: false,
	},
	municipality: {
		show: false,
	},
	forestryQuarters: {
		show: false,
		minimized: false,
		colorful: false,
		opacity: 0.5,
	},
	operationalManagement: {
		show: true,
	},
	fireDep: {
		show: false,
	},
	minerals: {
		show: false,
	},
	fires: {
		noPopupLink: true, // линк на карточку в попапе
		show: true,
		minimized: false,
		startDate: startOfMonth(new Date()),
		endDate: new Date(),
		status: {
			intensifies: true, // Усиливается
			continues: true, // Продолжается
			weakens: true, // Ослабевает
			notspreading: true, // Не распространяется
			localized: true, // Локализован
			resumed: true, // Возобновился
			extinguished: false, // Ликвидирован
			abandoned: false, // Прекращено тушение по решению КЧС
		},
		service: {
			serviced: true, // Обслуживаемые
			unattended: true, // Необслуживаемые
		},
	},
	heatPoints: {
		show: false,
		minimized: false,
		period: 0,
		useMVT: false,
		region: 14,
	},
	genShtab: {
		show: false,
	},
	weather: {
		show: false,
		minimized: false,
		layer: 'pm25',
	},
	wind: {
		show: false,
		minimized: false,
		fadeOpacity: 0.995,
		speedFactor: 5,
		particleLife: 128,
		opacity: 0.8,
		webgl: true,
	},
};

const defaultConfig = {
	reserves: {},
	plans: {},
	tileSource: '2gis',
	filtersMaximized: false,
	startDate: null,
	layers,
	dynamicLayers: [],
	planDynamicLayers: [],
	cityLayers: [],
};

export default function initLocal() {
	store.local.emergency = defaultsDeep(toJS(store.local.emergency), defaultConfig);
	store.local.save();
}
