import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import t from 'i18n';
import { FilterLayout } from 'components';
import { Checkbox } from '@smartplatform/ui';
import { observable } from 'mobx';

@observer
export default class BlockedRoadFilter extends React.Component {
	@observable willClose = null;
	@observable close = null;

	static propTypes = {
		layer: PropTypes.object.isRequired,
	};

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		const { willClose, close } = this.props.layer.settings.filter;
		this.willClose = willClose;
		this.close = close;
	};

	onChange = (field) => (value) => {
		this[field] = value;
		this.props.layer.onLocalStoreChange(field, value);
	};

	render() {
		return (
			<FilterLayout title={t('techFire.blockedRoad')} {...this.props}>
				<div><Checkbox value={this.close} onChange={this.onChange('close')} label={t('techFireBlockedRoad.plural')} /></div>
				<div><Checkbox value={this.willClose} onChange={this.onChange('willClose')} label={t('techFireBlockedRoad.willClose')} /></div>
			</FilterLayout>
		);
	}
}
