import React from 'react';
import { Tab, Tabs } from '@smartplatform/ui';
import AddressList from './addresses-list';
import ObjectsList from './objects-list';
import addressStore from './store';
import { Provider } from 'mobx-react';
import t from 'i18n';

import './style.scss';

export default () => (
	<div className='address-registry'>
		<Provider addressStore={addressStore}>
			<Tabs>
				<Tab path={`/techfires/addresses-objects`} title={t('byObjects')} render={(routeProps) => <ObjectsList {...routeProps} />} />
				<Tab path={`/techfires/addresses`} title={t('byAddress')} render={(routeProps) => <AddressList {...routeProps} />} />
			</Tabs>
		</Provider>
	</div>
);
