import React from 'react';
import FireDepList from '../dispatchers/FireDepList';

export default class ShiftsReserve extends React.Component {
	render() {
		return (
			<>
				<FireDepList {...this.props} withoutTakingShift />
			</>
		);
	}
}
