import React from 'react';
import MessageIcon from 'img/icons/techfires/card/message-circle.svg';
import { inject, observer } from 'mobx-react';
import { FieldBasedCard } from '../../../../components';

@inject('fireStore')
@observer
class ResultInquiry extends React.Component {
	render() {
		const { fireStore } = this.props;
		const { fire } = fireStore;

		const rows = [
			[
				{ property: 'causeText', label: 'techFire.causes', type: 'Input' },
				{ property: 'culprit', label: 'techFire.culprit', type: 'Input' },
				{ property: 'damage', label: 'techFire.damageText', type: 'Input' },
				{
					property: 'damageNumber',
					label: 'techFire.damage',
					type: 'NumberInput',
					props: { decimalScale: 2, decimalMark: ',', positiveOnly: true },
				},
			],
		];

		return (
			<FieldBasedCard
				title='techFire.resultInquiry'
				icon={MessageIcon}
				fields={[fire.causeText, fire.culprit, fire.damage, fire.damageNumber]}
				rows={rows}
				statusKey='resultInquiry'
				fireStore={fireStore}
				isCard={true}
			/>
		);
	}
}

export default ResultInquiry;
