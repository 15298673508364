import { observable, isArrayLike } from 'mobx';
import difference from 'lodash/difference';
import store from 'client/store';
import { path } from '..';
import { EXCLUDED_STATUSES } from './constants';
import { COLUMNS } from './fields';
import debounce from 'lodash/debounce';
import t from 'i18n';
import { defaultLocalStorageConfig } from '../localStore';
import { endOfDay, startOfDay } from 'date-fns';

export default class RegistryStore {
	@observable query = null;
	@observable status = null;
	@observable statuses = [];
	@observable activeFires = [];
	@observable search = '';
	@observable querySearch = '';
	@observable showFieldsPopup = false;
	@observable fields = Object.keys(COLUMNS);
	@observable visibleFields = this.fields.splice(0);
	@observable invisibleFields = [];
	@observable order = 'fireNumber desc';

	@observable dateRangeValues = {
		period: null,
		periodDate: null,
		startDate: null,
		endDate: null,
	};

	constructor() {
		this.doSearch = debounce(this.doSearch, 500, { leading: false, trailing: true });
		this.init();
	}

	init = async () => {
		await this.loadLocalStorage();
		await this.getActiveFires();
		this.compileQuery();
	};

	getActiveFires = async () => {
		const start = { gte: startOfDay(new Date()) };
		const end = { lte: endOfDay(new Date()) };
		this.activeFires = await store.model.ViewFire.find({
			where: {
				and: [
					{ detectDate: end },
					{ detectDate: end ? undefined : start },
					{ or: [{ and: [{ statusCode: { nin: EXCLUDED_STATUSES } }] }, { and: [{ date: start }, { date: end }] }] },
				],
			},
			fields: ['id', 'area', 'nonForestArea', 'forestArea'],
		});
	};

	doSearch = () => {
		this.querySearch = this.search;
		this.compileQuery();
		this.saveLocalStorage();
	};

	onSearch = (e) => {
		this.page = 1;
		this.search = e.target.value;
		this.doSearch();
	};

	onPeriodChange = (start, end) => {
		// console.log('onPeriodChange', start, end);
		this.compileQuery();
	};

	get filters() {
		const fields = this.visibleFields.flatMap((field) => (field === 'note' ? ['decisionDate', 'protocolNumber'] : field));
		const filter = { where: { and: [] }, fields: ['id', 'statusColor', ...fields], order: 'detectDate desc' };
		const { startDate, endDate } = this.dateRangeValues;
		const start = startDate ? { gte: startDate } : undefined;
		const end = endDate ? { lte: endDate } : undefined;
		if (start || end) {
			filter.where.and.push(
				...[
					//* только пожары с датой обнаружения не больше конца периода
					{ detectDate: end },

					//* начало периода устанавливаем только если нет конца периода(чтобы запросить все пожары от начала периода).
					//* если установлены обе даты , то ориентируемся на фильтрацию по or
					{ detectDate: end ? undefined : start },

					//* все активные или обновленные(ликвидированные) за период
					{ or: [{ and: [{ statusCode: { nin: EXCLUDED_STATUSES } }] }, { and: [{ date: start }, { date: end }] }] },
				]
			);
		}

		if (this.statuses.length > 0) {
			filter.where.and.push({ statusId: { inq: this.statuses.map((s) => s.id) } });
		}

		if (this.querySearch.length) {
			filter.search = this.querySearch;
		}

		return { filter };
	}

	compileQuery = () => {
		this.query = { ...this.filters.filter };
	};
	onQueryUpdate = ({ _totalCount, ...query }) => {
		this.query = query;
	};

	get renderFields() {
		return this.visibleFields.filter((field) => !!COLUMNS[field]).map((field) => COLUMNS[field]);
	}

	onStatusToggle = (statuses) => {
		this.statuses = statuses;
		this.compileQuery();
		this.saveLocalStorage();
	};

	reload = () => {
		this.saveLocalStorage();
		this.compileQuery();
	};

	cleanFields = (boolean = true) => (this.fields = COLUMNS.map((col) => ({ label: col.label, checked: boolean })));

	getInstance = (instance) => (this.tableInstance = instance);

	cleanFilters = () => {
		// TODO: доделать сброс всех фильтров в таблице
		// if (this.tableInstance) {
		// 	this.tableInstance.reset();
		// }

		const { page, status, statuses, querySearch, endDate, startDate, period } = defaultLocalStorageConfig.registry;
		this.page = page;
		this.search = querySearch;
		this.querySearch = querySearch;
		this.dateRangeValues.startDate = startDate;
		this.dateRangeValues.endDate = endDate;
		this.dateRangeValues.period = period;
		this.status = status;
		this.statuses = statuses;
		this.saveLocalStorage();
		this.compileQuery();
	};

	onRowClick = (record) => store.route.push({ path: `${path}/registry/${record.id}` });

	//* FIELDS
	toggleFieldsPopup = (boolean) => (this.showFieldsPopup = boolean);

	downloadLO1 = async () => {
		const { content, filename } = await store.model.FireReport.getReport({
			reportCode: 'LO1Section1',
			reportParams: {
				date1: this.dateStore.startDate,
				date2: this.dateStore.endDate,
				statusId: this.status ? this.status.id : undefined,
			},
		});

		const downloadLink = document.createElement('a');
		downloadLink.href = 'data:application/vnd.ms-excel, ' + encodeURIComponent(content);
		downloadLink.download = `${filename}.xls`;
		downloadLink.click();
	};

	get registyDownloadUrl() {
		const esc = encodeURIComponent;
		const path = '/api/ViewFires/exportToExcel';
		const cols = this.renderFields.reduce((acc, value) => {
			if (!value.relation) {
				acc[value.property] = t(value.label);
			} else if (value.relation) {
				acc[value.relation] = {
					name: t(value.label),
				};
			}
			return acc;
		}, {});
		const params = {
			filters: this.filters,
			date: this.currentDate,
			cols,
		};
		const query = '?params=' + esc(JSON.stringify(params));
		return path + query;
	}

	//local storage
	saveLocalStorage = () => {
		store.local.fires.registry = {
			page: this.page,
			querySearch: this.querySearch,
			period: this.dateRangeValues.period,
			periodDate: this.dateRangeValues.periodDate,
			startDate: this.dateRangeValues.startDate,
			endDate: this.dateRangeValues.endDate,
			status: this.status,
			statuses: isArrayLike(this.statuses) ? this.statuses.map((s) => s.id) : [],
			visibleFields: this.visibleFields,
		};
		store.local.save();
	};

	loadLocalStorage = async () => {
		const { registry } = store.local.fires;
		if (Object.keys(registry).length > 0) {
			const { page, querySearch, period, periodDate, startDate, endDate, status, statuses, visibleFields } = registry;
			this.dateRangeValues.period = period;
			this.dateRangeValues.periodDate = periodDate && new Date(periodDate);
			this.dateRangeValues.startDate = startDate && new Date(startDate);
			this.dateRangeValues.endDate = endDate && new Date(endDate);
			this.status = status;
			if (statuses?.length) {
				this.statuses = await store.model.FireStatus.find({ where: { id: { inq: statuses } }, fields: ['id', 'name', 'color'] });
			}
			this.page = parseInt(page);
			this.search = querySearch;
			this.querySearch = querySearch;
			if (visibleFields?.length) {
				this.visibleFields = visibleFields;
			}
			this.fields = Object.keys(COLUMNS);
			this.invisibleFields = difference(this.fields, this.visibleFields);
		}
	};

	get currentFires() {
		const fires = this.activeFires;

		if (!fires) {
			return {};
		}

		let results = { totalCount: fires.totalCount, count: fires.length, area: 0, forestArea: 0, nonForestArea: 0 };
		for (const fire of fires) {
			results.area += fire.area;
			results.forestArea += fire.forestArea;
			results.nonForestArea += fire.nonForestArea;
		}

		return results;
	}
}
