import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { ForestFireMoveList } from './list/ForestFireMoveList';
import { ForestFireMoveEdit } from './edit/ForestFireMoveEdit';

export default ({ match }) => {
	const { path } = match;
	return (
		<Switch>
			<Route path={path} exact render={(props) => <ForestFireMoveList {...props} path={path} />} />
			<Route path={`${path}/:id`} render={(props) => <ForestFireMoveEdit {...props} path={path} />} />
		</Switch>
	);
};
