import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import classNames from 'classnames';
import './style.scss';

@observer
export default class OrganizationsTreePopup extends React.Component {
	@observable showTreeValue = [];
	@observable isLoading = true;

	constructor(props) {
		super(props);
	}

	onTitleClick = (record) => () => {
		let { selected, context } = this.props;

		const addChildren = async (org) => {
			const children = await org.children();
			for (const child of children) {
				if (!selected.find((x) => x.name === child.name)) {
					selected.push(child);
					context.form.addManyToMany('techFireOrganization', child.id);
				}
				await addChildren(child);
			}
		};

		if (!selected.find((r) => r.id === record.id)) {
			selected.push(record);
			context.form.addManyToMany('techFireOrganization', record.id);
			addChildren(record, { delete: true });
		} else {
			selected.splice(
				selected.findIndex((i) => {
					return i.id === record.id;
				}),
				1
			);
			context.form.removeManyToMany('techFireOrganization', record.id);
		}
	};

	render() {
		const { allRecords, level = 0, parentId = null, selected } = this.props;
		const records = allRecords.filter((record) => record.parentId === parentId);
		return (
			<div className={classNames('org-tree')}>
				{records.map((record) => {
					return (
						<React.Fragment key={record.id}>
							<div
								className={classNames('title', { selected: selected.find((r) => r.id === record.id), 'hide-org-tree': this.showTreeValue.includes(record.id) })}
								onClick={this.onTitleClick(record)}
							>
								{[...new Array(level)].map((v, i) => (
									<span key={i} className='pad' />
								))}
								<div className='icon-title'>
									{record.name}
								</div>
							</div>
							{!this.showTreeValue.includes(record.id) && <OrganizationsTreePopup {...this.props} parentId={record.id} level={level + 1} />}
						</React.Fragment>
					);
				})}
			</div>
		);
	}
}
