import { observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { UP } from "client/constants";

import store from "client/store";

export class editModeStore {
	@observable isEditMode = false;
	@observable isNewGroup = false;
	@observable newGroupName = '';
	@observable popup = null;
	@observable structureModels = null;
	startPopup = null;
	defaultModels = null;
	resultModels = null;

	constructor({ defaultModels }) {
		this.defaultModels = defaultModels;
		this.init();
	}

	addGroup = () => this.isNewGroup = !this.isNewGroup;

	onChangeGroupName = (value) => this.newGroupName = value;

	saveNewGroup = () => {
		this.isNewGroup = !this.isNewGroup;
		this.structureModels.groups.unshift({ title: this.newGroupName, id: uuidv4() });
		this.sortedResultModels();
		this.save();
	};

	onEditModel = (model) => () => {
		this.popup = this.structureModels.models.find((structureModel) => structureModel.modelName === model.modelName);
		this.startPopup = Object.assign({}, this.popup);
	};

	onClosePopup = () => {
		this.structureModels.models.map((model) => {
			if (model.modelName === this.startPopup.modelName) {
				model.id = this.startPopup.id;
				model.priority = this.startPopup.priority;
			}
			return model;
		})

		this.structureModels.models
			.filter((model) => model.id === this.startPopup.id)
			.sort((a, b) => (a.priority || 0) - (b.priority || 0))
			.map((model, index) => {
				model.priority = index;
				return model;
			});

		this.popup = null;
		this.sortedResultModels();
	}

	onChangeGroup = (popup) => (value) => {
		this.structureModels.models
			.filter((model) => model.id === popup.id && !(model.modelName === popup.modelName))
			.sort((a, b) => (a.priority || 0) - (b.priority || 0))
			.map((model, index) => {
				model.priority = index;
				return model;
			});

		this.structureModels.models.map((model) => {
			if (model.modelName === popup.modelName) {
				model.priority = Math.max(...this.structureModels.models.filter((model) => model.id === value).map((model) => model.priority)) + 1;
				model.id = value;
			}
			return model;
		})

		this.sortedResultModels();
	}

	checkNonGroupedModel = ({ structure, defaultModels }) => {
		const allModels = defaultModels.flatMap((group) => {
			if (group.modelName) {
				return [group];
			}
			return group.items;
		});

		const structureModels = structure.models;

		const newModels = allModels.filter((model) => !structureModels.find((structureModel) => structureModel.modelName === model.modelName))

		if (newModels.length) {
			newModels.forEach((model) => {
				structure.models.push({ modelName: model.modelName, priority: model.priority, id: null });
			})
		}

		return structure;
	}

	getStructureFromDefault = (models) => {
		if (!models?.length) return null;

		const structure = {
			models: [],
			groups: [],
		};

		for (let group of models) {
			if(group.title) {
				if (!group.items?.length) break;
				const id = uuidv4();

				structure.groups.push({ title: group.title, id });
				group.items.forEach((model, index) => {
					structure.models.push({ modelName: model.modelName, id, priority: model.priority || index });
				});
			} else {
				structure.models.push({ modelName: group.modelName, id: null, priority: group.priority });
			}
		}

		return structure;
	}

	getModelsFromStructure = ({ structure, defaultModels }) => {
		if (!structure || !defaultModels) return []
		const allModels = defaultModels.flatMap((group) => {
			if (group.modelName) {
				return [group];
			}
			return group.items;
		});

		const resultModels = [];

		if (structure.models.find((model) => model.id === null) &&
			!structure.groups.find((group) => group.id === null)) {
			structure.groups.push({ id: null, title: 'Не сгруппировано' })
		}

		structure.groups.forEach((group) => {
			const structureModels = structure.models.filter((model) => model.id === group.id);
			const items = allModels
				.map((model) => {
					const findModel = structureModels.find((structModel) => structModel.modelName === model.modelName);
					if (findModel) {
						return ({ ...model, priority: findModel.priority });
					}
				})
				.filter((model) => model);

			resultModels.push(
				{
					title: group.title,
					type: 'submenu',
					items,
				}
			)
		})

		return resultModels;
	}

	sortedResultModels = () => {
		this.resultModels = this.getModelsFromStructure({ structure: this.structureModels, defaultModels: this.defaultModels })
		this.resultModels.forEach((group) => {
			group.items.sort((a, b) => (a.priority || 0) - (b.priority || 0));
		})
	}

	save = async () => {
		const record = await store.model.Setting.find({ where: { code: 'dictionaries' } });
		const settings = record[0];
		settings.value = JSON.stringify(this.structureModels);
		await settings.save();
		this.popup = null;
	}

	init = async () => {
		const record = await store.model.Setting.find({ where: { code: 'dictionaries' } });
		const settings = record[0];

		if (!settings) {
			this.structureModels = this.getStructureFromDefault(this.defaultModels)
			const newSettings = new store.model.Setting({ code: 'dictionaries', value: JSON.stringify(this.structureModels) });
			this.resultModels = this.defaultModels;
			await newSettings.save();
		} else {
			this.structureModels = this.checkNonGroupedModel({ structure: JSON.parse(settings.value), defaultModels: this.defaultModels });
			this.sortedResultModels();
		}
	};

	changeFieldPosition = (model, pos) => {
		const element = this.structureModels.models.find((structureModel) => structureModel.modelName === model.modelName);
		const toPriority = pos === UP ? model.priority - 1 : model.priority + 1;
		const elementTo = this.structureModels.models.find((structureModel) => structureModel.id === model.id && structureModel.priority === toPriority);

		elementTo.priority = model.priority;
		element.priority = toPriority;

		this.sortedResultModels();
		this.save();
	};

	editModeChange = () => this.isEditMode = !this.isEditMode;
}
