import React from 'react';
import { inject, observer } from 'mobx-react';
import { Select, RecordSelect } from '@smartplatform/ui';
import { MONTHES } from 'fires/helpers';

import { getYearArray } from 'client/tools';
import store from 'client/store';
import t from 'i18n';

@inject('report')
@observer
export class Filters extends React.Component {
	render() {
		const { report } = this.props;

		return (
			<div className='filters'>
				<div className='row'>
					<div className='col-3'>
						<div className='form-field'>
							<label>{t('aviaDep.title')}</label>
							<RecordSelect
								noTotal
								maxItems={10}
								itemsPerPage={200}
								model={store.model.AviaDep}
								value={report.aviaDep}
								onChange={report.onAviaDepChange}
								property='name'
								className='drop-down-md'
								filter={{ fields: ['id', 'name'] }}
							/>
						</div>
					</div>
				</div>
				<div className='row'>
					<div className='col-3'>
						<div className='form-field'>
							<label>{t('fireReport.year')}</label>
							<Select noTotal isRequired items={getYearArray()} value={report.year} onChange={report.onYearChange} />
						</div>
					</div>
					<div className='col-3'>
						<div className='form-field'>
							<label>{t('fireReport.month')}</label>
							<Select noTotal maxItems={10} itemsPerPage={12} isRequired items={MONTHES} value={report.month} onChange={report.onMonthChange} />
						</div>
					</div>
				</div>
			</div>
		);
	}
}
