import { observable } from "mobx";
import t from 'i18n';

export const REPORT_TYPES = [
	{ label: t('report.NumberOfFiresForTheFireSeason'), value: 'DistributionOfIndicatorsByDatesOfTheFireHazardPeriod' },
	{ label: t('report.TotalFireAreaFireHazardPeriod'), value: 'TotalFireAreaFireHazardPeriod' },
	{ label: t('report.ForestAndNonForestAreasFires'), value: 'ForestAndNonForestAreasFires' },
	{ label: t('report.KppForTheFireHazardPeriod'), value: 'KppForTheFireHazardPeriod' },
	{ label: t('report.KpoForTheFireHazardPeriod'), value: 'KpoForTheFireHazardPeriod' },
];

export default class DistributionOfIndicatorsByDatesOfTheFireHazardPeriodStore {
	@observable startDate = null;
	@observable endDate = null;
	@observable report = REPORT_TYPES[0].value;

	onStartDateChange = (startDate) => this.startDate = startDate;

	onEndDateChange = (endDate) => this.endDate = endDate;

	onReportChange = (report) => this.report = report;

	get storeParams() {
		const { startDate, endDate, report } = this;
		return {
			reportCode: report,
			reportParams: { startDate, endDate, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone },
		};
	}
}
