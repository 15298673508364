import { observable } from 'mobx';

export default class ListOfFlightStaffStore {
	@observable year = new Date().getFullYear();

	onYearChange = (year) => (this.year = year);

	get storeParams() {
		const { year } = this
		return {
			reportCode: 'ListOfFlightStaff',
			isHTML: true,
			reportParams: { year, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone },
		};
	}
}
