import React from 'react';
import { observer } from 'mobx-react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Profile } from 'components';
import store from 'client/store';
import t from 'i18n';
import loadable from '@loadable/component';
import {ADMIN_MODELS, ADMIN_MODELS_PERMAFROST} from './adminModels';
const DictionariesPage = loadable(() => import('components/dictionaries/dictionaries-page'));
const Audit = loadable(() => import('components/audit'));

@observer
export class ModuleWrapper extends React.Component {
	componentDidMount() {
		document.title = this.props.module.name;
		store.module = this.props.module;
	}

	componentWillUnmount() {
		document.title = t('title');
		store.module = null;
	}

	render() {
		const { children, className, path, redirectPath } = this.props;
		const adminModels = path === '/permafrost' ? ADMIN_MODELS_PERMAFROST : ADMIN_MODELS;

		return (
			<div className={className}>
				<Switch>
					{redirectPath && (
						<Route path={path} exact>
							<Redirect to={redirectPath} />
						</Route>
					)}
					<Route path={`${path}/profile`} component={Profile} />
					{store.isAdmin && <Route path={`${path}/journal`} component={Audit} />}
					{store.isAdmin && (
						<Route
							path={`${path}/admin`}
							render={(props) => (
								<div className='administration-page	'>
									<DictionariesPage {...props} models={adminModels} title={t('administration')} />
								</div>
							)}
						/>
					)}
					<Route> {children}</Route>
				</Switch>
			</div>
		);
	}
}
