import React from 'react';
import Root from './Root';
import t from 'i18n';
import store from 'client/store';
import RegistryIcon from 'img/icons/sidebar/shared/registry.svg';
import MapIcon from 'img/icons/sidebar/shared/map.svg';
import FiresIcon from 'img/icons/menu/fires.svg';
import RaidsIcon from 'img/icons/sidebar/fires/raids.svg';
import DictionaryIcon from 'img/icons/sidebar/shared/dictionary.svg';
import ForceAvailabilityIcon from 'img/icons/sidebar/fires/force-availability.svg';
import PlanIcon from 'img/icons/sidebar/fires/plan.svg';
import ReportIcon from 'img/icons/sidebar/shared/report.svg';
import DashboardIcon from 'img/icons/sidebar/shared/dashboard.svg';
import RequestLog from 'img/icons/sidebar/shared/request-log.svg';
import EmergencyIcon from 'img/icons/sidebar/fires/emergency.svg';
import TransportData from 'img/icons/sidebar/shared/transport-data.svg';
import IsdmIcon from 'img/icons/sidebar/fires/isdm.svg';
import AdminIcon from 'img/icons/sidebar/shared/admin.svg';
import JourneyIcon from 'img/icons/sidebar/shared/journey.svg';
import tMap from '@smartplatform/map/i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';

export const path = '/fires';

export default {
	name: t('fire.plural'),
	code: 'fires',
	icon: <FiresIcon />,
	path,
	component: Root,
	access: () => store.model.Fire && store.model.Fire.INFO.READ,
	menu: [
		{ title: t('fireDashboard.title'), path: `${path}/dashboard`, icon: <DashboardIcon />, exact: true },
		{ title: t('fire.map'), path: `${path}/map`, icon: <MapIcon />, exact: true },
		{ title: t('fire.registry.title'), path: `${path}/registry`, icon: <RegistryIcon /> },
		{ title: t('airRaid.plural'), path: `${path}/raids`, icon: <RaidsIcon /> },
		{ title: t('firePlan.plural'), path: `${path}/plans`, icon: <PlanIcon />, visible: () => store.isAdmin || store.model.user.forestryId },
		{ title: t('forceAvailability.plural'), path: `${path}/force-availability`, icon: <ForceAvailabilityIcon /> },
		{ title: t('emergencySituation.plural'), path: `${path}/emergency`, icon: <EmergencyIcon /> },
		{ title: t('requestLog.plural'), path: `${path}/request-log`, icon: <RequestLog /> },
		{ title: t('report.plural'), path: `${path}/reports`, icon: <ReportIcon /> },
		{ title: t('transportData.title'), path: `${path}/transport-data`, icon: <TransportData /> },
		{ title: t('dictionaries.plural'), path: `${path}/dictionaries`, icon: <DictionaryIcon /> },
		{ title: t('isdm.title'), path: `${path}/isdm`, icon: <IsdmIcon /> },
		{ title: t('audit.title'), path: `${path}/journal`, icon: <JourneyIcon />, visible: () => store.isAdmin },
		{ title: t('admin.titleShort'), path: `${path}/admin`, icon: <AdminIcon />, visible: () => store.isAdmin },
		{ title: tMap('map.layer.plural'), path: `/layers`, icon: <FontAwesomeIcon icon={faLayerGroup} style={{ fontSize: 26 }} /> },
	],
};
