import { observable } from "mobx";
import { endOfMonth, startOfMonth } from "date-fns";

export default class ReportOnTheDetectionAndSuppressionForestFiresStore {
	@observable year = new Date().getFullYear();
	@observable month = new Date().getMonth() + 1;
	@observable ownership = null;

	onMonthChange = (month) => (this.month = month);
	onYearChange = (year) => (this.year = year);
	onOwnershipChange = (ownership) => (this.ownership = ownership);

	get storeParams() {
		const { year, month, ownership  } = this;
		const date = new Date(year, month - 1, 1);

		const startDate = startOfMonth(date);
		const endDate = endOfMonth(date);
		return {
			reportCode: 'ReportOnTheDetectionAndSuppressionForestFires',
			reportParams: { year, month, startDate, endDate, ownership, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone },
			isHTML: true,
		};
	}
}
