import React from 'react';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsAlt } from '@fortawesome/free-solid-svg-icons';
import FlussonicMsePlayer from '@flussonic/flussonic-mse-player';

import { Loader } from '@smartplatform/ui';
import t from 'i18n';
import './style.scss';

@observer
export default class CameraPopup extends React.Component {
	@observable isLoading = true;
	@observable error = null;

	componentDidMount() {
		document.addEventListener('keydown', this.onKeyDown);
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.onKeyDown);
	}

	maximized = false;

	onContainerMount = (el) => (this.container = el);

	onContentMount = (el) => (this.content = el);

	onVideoMount = (el) => {
		this.video = el;
		if (el) {
			this.player = new FlussonicMsePlayer(el, this.props.record.url, {
				onError: this.onError,
				onStartStalling: this.onStartStalling,
				onEndStalling: this.onEndStalling,
			});
			this.player.play();
		}
	};

	// onStartStalling = () => this.isLoading = true;
	onEndStalling = () => (this.isLoading = false);

	@action onError = () => {
		this.error = t('camera.error');
		this.isLoading = false;
	};

	switch = () => {
		this.maximized = !this.maximized;
		if (this.maximized) {
			console.log('max');
			this.maximize();
		} else {
			this.minimize();
			console.log('min');
		}
	};

	maximize = () => {
		if (!this.content) return;
		this.bigContainer = document.createElement('div');
		this.bigContainer.className = 'maximized-camera';
		const btn = this.content.getElementsByClassName('switch-btn')[0];
		btn.addEventListener('click', this.switch);
		btn.innerHTML = t('camera.minimize');
		const mainLayout = document.getElementsByClassName('main-layout')[0];
		mainLayout.appendChild(this.bigContainer);
		this.bigContainer.appendChild(this.content);
	};

	minimize = () => {
		if (!this.content || !this.container || !this.bigContainer) return;
		const btn = this.content.getElementsByClassName('switch-btn')[0];
		btn.innerHTML = t('camera.maximize');
		btn.removeEventListener('click', this.switch);
		this.container.appendChild(this.content);
		const mainLayout = document.getElementsByClassName('main-layout')[0];
		mainLayout.removeChild(this.bigContainer);
	};

	onKeyDown = (e) => {
		if (this.maximized && e.keyCode === 27) {
			this.switch();
		}
	};

	render() {
		const { record } = this.props;

		let content = <video ref={this.onVideoMount} />;

		if (this.error) content = <div className='error'>{this.error}</div>;

		return (
			<div className='camera-popup' ref={this.onContainerMount}>
				<div className='video-container' ref={this.onContentMount}>
					<div className='controls'>
						<div className='left'>
							{this.isLoading ? <Loader size={12} /> : <span className={'status-point' + (this.error ? ' error' : '')} />} {record.name}
						</div>
						<div className='right'>
							<div onClick={this.switch} className='switch-btn'>
								{t('camera.maximize')}
							</div>
						</div>
					</div>
					<div className='content'>{content}</div>
				</div>
			</div>
		);
	}
}
