import React from 'react';
import { inject, observer } from 'mobx-react';
import { DatePicker, RecordSelect, Select } from '@smartplatform/ui';

import store from 'client/store';
import t from 'i18n';

@inject('report')
@observer
export class Filters extends React.Component {
	render() {
		const { report } = this.props;

		const groups = [
			{ value: 'byArea', label: t('fireReport.byArea') },
			{ value: 'byExtinguishingTime', label: t('fireReport.byExtinguishingTime') },
		];

		return (
			<div className='filters'>
				<div className='row'>
					<div className='col-3'>
						<div className='form-field'>
							<label>{t('date')}</label>
							<DatePicker showTimeSelect={false} showClearButton={false} value={report.date} onChange={report.onDateChange} />
						</div>
					</div>
					<div className='col-3'>
						<div className='form-field'>
							<label>{t('fireReport.group')}</label>
							<Select noTotal maxItems={10} itemsPerPage={1000} noSearch isRequired items={groups} value={report.group} onChange={report.onGroupChange} />
						</div>
					</div>
					<div className='col-3'>
						<div className='form-field'>
							<label>{t('forestOwnership.title')}</label>
							<RecordSelect
								noTotal
								maxItems={10}
								itemsPerPage={200}
								model={store.model.ForestOwnership}
								value={report.ownership}
								onChange={report.onOwnershipChange}
								property='name'
								className='drop-down-md'
								filter={{ fields: ['id', 'name'] }}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
