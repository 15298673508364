import { observable } from "mobx";

export default class AirRaidExtinguishForestFiresStore {
	@observable startDate = null;
	@observable endDate = null;
	@observable aviaDep = null;

	onStartDateChange = (startDate) => this.startDate = startDate;

	onEndDateChange = (endDate) => this.endDate = endDate;

	onAviaDepChange = (aviaDep) => this.aviaDep = aviaDep;

	get storeParams() {
		const { startDate, endDate, aviaDep} = this;
		return {
			reportCode: 'AirRaidExtinguishForestFires',
			reportParams: { startDate, endDate, aviaDep, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone },
			isHTML: true,
		};
	}
}
