export const fio = (record) => {
	if (!record) return '';
	return record.lastName || record.firstName || record.middleName
		? `${record.lastName || ''} ${record.firstName || ''} ${record.middleName || ''}`
		: record.username ?? '';
};
export const fioShort = (record) => {
	if (!record) return '';
	return record.lastName || record.firstName || record.middleName
		? `${record.lastName} ${record.firstName ? record.firstName.substr(0, 1) + '.' : ''} ${record.middleName ? record.middleName.substr(0, 1) + '.' : ''}`
		: record.username;
};
