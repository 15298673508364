import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { observer } from 'mobx-react';
import List from '../List';
import Shift from '../Shift';
import FireDepShift from '../../single/FireDepShift';
import Edit from './Edit';

@observer
export default class MainDispatch extends React.Component {
	
	constructor(props) {
		super(props);
	}
	
	render() {
		return <div>
			<Switch>
				<Route path="/techfires/shifts" exact render={props => <List isMain {...props} />} />
				<Route path="/techfires/shifts/create" render={props => <Edit {...props} />} />
				<Route path="/techfires/shifts/:id" exact render={props => <Shift isMain {...props} />} />
				<Route path="/techfires/shifts/:id/edit" render={props => <Edit {...props} />} />
				<Route path="/techfires/shifts/:id/firedep/:fireDepId" render={props => <FireDepShift isMain {...props} />} />
			</Switch>
			{/*<pre>{JSON.stringify(this.props.match, null, 2)}</pre>*/}
		</div>;
	}
}
