import React from 'react';
import './renderFireName.scss';

export const renderFireName = (fire) => {
	let { statusColor, status, county, countyName } = fire;
	statusColor = statusColor || status?.color;
	countyName = countyName || county?.name;
	const style = statusColor ? { borderColor: statusColor } : undefined;
	return (
		<div className='render-fire-name' style={style}>
			<strong className='fire-number'>#{fire.fireNumber}</strong> {countyName && <span>{countyName}</span>}
		</div>
	);
};
