import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { FilterLayout } from '..';
import t from 'i18n';
import './style.scss';
import { Checkbox, RecordSelect } from '@smartplatform/ui';
import appStore from 'client/store';

const FIELDS = ['ptpCount', 'ktpCount', 'overnightStay'];

@observer
export default class AddressFilter extends React.Component {
	static propTypes = {
		layer: PropTypes.object,
	};

	render() {
		const { layer } = this.props;
		const { onToggle } = layer;
		const { fields, ranks } = layer.settings.filter;
		const showRankValue =
			ranks.length > 0 ? t('techFireAddress.selectedRanks') + ': ' + ranks.length : <span className='placeholder'>{t('techFireAddress.selectRank')}</span>;

		return (
			<FilterLayout layer={layer} title={t('techFireAddress.registry')} className='address-layer-filter'>
				<RecordSelect
					model={appStore.model.TechFireRank}
					computed={(rank) => rank.name}
					value={ranks}
					onChange={(rank) => onToggle(rank)}
					showValue={showRankValue}
					width={250}
				/>
				{FIELDS.map((field) => (
					<Checkbox key={field} value={fields[field]} onChange={(v) => onToggle(v, field)} label={t(`techFireAddress.${field}`)} />
				))}
			</FilterLayout>
		);
	}
}
