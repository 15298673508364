import { observable } from 'mobx';
import store from 'client/store';
import debounce from 'lodash/debounce';
import { TUBES_INCLUDE } from '../constants';

export default class TemperatureTubeStore {
	@observable query = null;
	@observable search = '';
	@observable querySearch = '';

	@observable order = 'id desc';
	@observable page = 1;
	@observable totalCount = 0;
	@observable perPage = 15;

	constructor() {
		this.doSearch = debounce(this.doSearch, 500, { leading: false, trailing: true });
		this.init();
	}

	init = async () => {
		this.compileQuery();
	};

	doSearch = () => {
		this.querySearch = this.search;
		this.compileQuery();
	};

	onSearch = (e) => {
		this.page = 1;
		this.search = e.target.value;
		this.doSearch();
	};

	compileQuery = () => {
		const { querySearch } = this;
		const query = {
			order: this.order,
			limit: this.perPage,
			skip: (this.page - 1) * this.perPage,
			...TUBES_INCLUDE,
		};
		if (querySearch.length) query.search = querySearch;
		if (this.query?.where) query.where = this.query.where;
		this.query = query;
	};

	onQueryUpdate = (query) => {
		this.query = query;
		if (query._totalCount) this.totalCount = query._totalCount;
		this.order = query.order;
	};

	onChange = (props) => (value) => {
		this.page = 1;
		this[props] = value;
		this.compileQuery();
	};

	onRowClick = (record) => store.route.push({ path: `${store.route.path}/${record.id}` });
}
