import React from 'react';
import { Field } from '@smartplatform/ui';
import t from 'i18n';
import { observer } from 'mobx-react';
import './style.scss';
import { guardHeadFireDepItem } from '../../modules/techfires/helpers';
import { formatDate } from '../../tools';
import { LabelField } from '../fields';

@observer
export default class ObjectInspector extends React.Component {
	constructor(props) {
		super(props);
	}
	fullName = (personnel) => {
		if (personnel) {
			return (
				<div>
					{personnel.lastName} {personnel.firstName} {personnel.middleName}
				</div>
			);
		}
	};

	personnelPosition = (personnel) => {
		if (personnel.position) {
			return <div>{personnel.position.name}</div>;
		}
	};

	headOrgName = (personnel) => {
		if (personnel.fireDep && personnel.fireDep.headOrg) {
			return <div>{personnel.fireDep.headOrg.name}</div>;
		}
	};

	render() {
		const { object, inspectorEdit, inspectorCard } = this.props;
		const { manualFill, personnelFireDep, lastInspectionDate, inspectionDate } = object;
		return (
			<>
				{inspectorEdit && (
					<>
						<Field property='lastInspectionDate' label={t('techFireAddress.lastInspectionDate')} />
						<Field property='manualFill' label={t('techFireAddress.manualFill')} className='manual-fill-checkbox' />
						{manualFill && (
							<>
								<Field property='nameInspector' label={t('techFireAddress.inspectorFullName')} />
								<Field property='positionInspector' label={t('techFireAddress.inspectorPosition')} />
								<Field property='organizationInspector' label={t('techFireAddress.inspectorOrganization')} />
							</>
						)}
						{!manualFill && (
							<>
								<Field relation='personnelFireDep' label={t('techFireAddress.inspectorFullName')} computed={guardHeadFireDepItem} />
								<Field relation='personnelFireDep' label={t('techFireAddress.inspectorPosition')} computed={this.personnelPosition} />
								<Field relation='personnelFireDep' label={t('techFireAddress.inspectorOrganization')} computed={this.headOrgName} />
							</>
						)}
						<Field property='inspectionDate' label={t('techFireAddress.inspectionDate')} />
						<Field property='commentInspector' label={t('techFireAddress.commentInspector')} />
					</>
				)}
				{inspectorCard && (
					<div className='form-fields'>
						<>
							<LabelField label={t('techFireAddress.lastInspectionDate')} children={formatDate(lastInspectionDate) || '-'} />
							{!manualFill && personnelFireDep && (
								<>
									<LabelField label={t('techFireAddress.inspectorFullName')} children={this.fullName(personnelFireDep) || '-'} />
									<LabelField label={t('techFireAddress.inspectorPosition')} children={(personnelFireDep.position && personnelFireDep.position.name) || '-'} />
									<LabelField
										label={t('techFireAddress.inspectorOrganization')}
										children={(personnelFireDep.fireDep && personnelFireDep.fireDep.headOrg.name) || '-'}
									/>
								</>
							)}
							{manualFill && object && (
								<>
									<LabelField label={t('techFireAddress.inspectorFullName')} children={object.nameInspector || '-'} />
									<LabelField label={t('techFireAddress.inspectorPosition')} children={object.positionInspector || '-'} />
									<LabelField label={t('techFireAddress.inspectorOrganization')} children={object.organizationInspector || '-'} />
								</>
							)}
							<LabelField label={t('techFireAddress.inspectionDate')} children={formatDate(inspectionDate) || '-'} />
							<LabelField label={t('techFireAddress.commentInspector')} children={object.commentInspector || '-'} />
						</>
					</div>
				)}
			</>
		);
	}
}
