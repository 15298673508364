import React from 'react';
import { Provider } from 'mobx-react';
import { ObjectsList } from '../../../address-registry/objects-list';
import addressStore from '../../../address-registry/store';
import { observer } from 'mobx-react';

@observer
export default class Facilities extends React.Component {
	render() {
		const { fireDepId } = this.props;
		return (
			<Provider addressStore={addressStore}>
				<ObjectsList queryFireDep={{ fireDepId: fireDepId }} />
			</Provider>
		);
	}
}
