import React from 'react';
import { Card } from 'components';
import t from 'i18n';
import { NumberInput } from '@smartplatform/ui';
import Xarrow from 'react-xarrows';
import { inject, observer } from 'mobx-react';
import MarkerPinIcon from 'img/icons/fires/card/marker-pin-04.svg';

@inject('card')
@observer
export class Area extends React.Component {
	render() {
		const { card } = this.props;
		const { fire, disabled } = card;
		return (
			<Card title={t('fire.areaTitle')} className='area' icon={<MarkerPinIcon />}>
				<div className='squares-block'>
					{[
						'protectiveArea',
						'nonCoverArea',
						'activeBurningArea',
						'operationalArea',
						'forestArea',
						'reserveArea',
						'coverArea',
						'area',
						'upperArea',
						'nonForestArea',
						'soilArea',
					].map((label, i) => (
						<div className={`form-field box${i}`} key={label}>
							<label>{t(`fire.${label}Short`)}</label>
							<NumberInput
								disabled={disabled}
								positiveOnly
								decimalScale={2}
								id={`box${i}`}
								value={fire[label] || null}
								className={i === 6 && card.boxColorByGroup === 's1' ? 'box-color-blue' : i === 6 && card.boxColorByGroup === 's2' ? 'box-color-orange' : ''}
								onChange={card.calcManualArea(label)}
							/>
						</div>
					))}
				</div>

				<div className='connection-lines'>
					{[
						[7, 4],
						[7, 9],
						[4, 1],
						[4, 6],
						[6, 0],
						[6, 3],
						[6, 5],
						[6, 8],
						[6, 10],
					].map((el, index) => (
						<Xarrow key={index} start={'box' + el[0]} end={'box' + el[1]} showHead={false} path={'grid'} color={'#37b813'} />
					))}
				</div>
			</Card>
		);
	}
}
