export const PERSONNEL_SCOPE = {
	include: [
		{ relation: 'section', scope: { fields: ['id', 'name'] } },
		{ relation: 'position', scope: { fields: ['id', 'name'] } },
		{ relation: 'rank', scope: { fields: ['id', 'name'] } },
		{
			relation: 'vehicle',
			scope: {
				include: [
					{
						relation: 'type',
						scope: {
							include: ['vehicleCode'],
						},
					},
					'callSign'
				],
			},
		},
		{ relation: 'gasMask', scope: { fields: ['id', 'name'] } },
		{ relation: 'status', scope: { fields: ['id', 'name'] } },
	],
	order: 'lastName asc, firstName asc, middleName asc',
};
