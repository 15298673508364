import React from 'react';
import { inject, observer } from 'mobx-react';

import { NumberValue } from '@smartplatform/ui';
import t from 'i18n';

const LOADING = <>&nbsp;</>;

@inject('dashboardStore')
@observer
export default class Daily extends React.Component {
	render() {
		const { dashboardStore, type } = this.props;

		let data = {};
		if (dashboardStore.data && dashboardStore.data.daily) data = dashboardStore.data.daily[type];

		const {
			dailyExtinguishedCount,
			dailyExtinguishedArea,
			dailyDetectedCount,
			dailyDetectedArea,
			dailyLocalizedCount,
			dailyLocalizedArea,
			peopleCount,
			vehicleCount,
		} = data;

		return (
			<div className='daily card-content'>
				<div className='daily-row'>
					<div className='title'>{t('fireDashboard.dailyExtinguished')}</div>
					<div className='number-column green'>
						<label>{t('countShort')}:</label>
						{dailyExtinguishedCount !== undefined ? <NumberValue value={dailyExtinguishedCount} /> : LOADING}
					</div>
					<div className='number-column green'>
						<label>{t('fireDashboard.area')}:</label>
						{dailyExtinguishedArea !== undefined ? <NumberValue value={dailyExtinguishedArea} /> : LOADING}
					</div>
				</div>

				<div className='daily-row'>
					<div className='title'>{t('fireDashboard.dailyDetected')}</div>
					<div className='number-column red'>
						<label>{t('countShort')}:</label>
						{dailyDetectedCount !== undefined ? <NumberValue value={dailyDetectedCount} /> : LOADING}
					</div>
					<div className='number-column red'>
						<label>{t('fireDashboard.area')}:</label>
						{dailyDetectedArea !== undefined ? <NumberValue value={dailyDetectedArea} /> : LOADING}
					</div>
				</div>

				<div className='daily-row'>
					<div className='title'>{t('fireDashboard.dailyLocalized')}</div>
					<div className='number-column blue'>
						<label>{t('countShort')}:</label>
						{dailyLocalizedCount !== undefined ? <NumberValue value={dailyLocalizedCount} /> : LOADING}
					</div>
					<div className='number-column blue'>
						<label>{t('fireDashboard.area')}:</label>
						{dailyLocalizedArea !== undefined ? <NumberValue value={dailyLocalizedArea} /> : LOADING}
					</div>
				</div>

				<div className='daily-row'>
					<div className='title'>{t('fireDashboard.dailyUnderway')}</div>
					<div className='number-column blue'>
						<label>{t('fireDashboard.personnel')}:</label>
						{peopleCount !== undefined ? <NumberValue value={peopleCount || 0} /> : LOADING}
					</div>
					<div className='number-column blue'>
						<label>{t('fireDashboard.vehicles')}:</label>
						{vehicleCount !== undefined ? <NumberValue value={vehicleCount || 0} /> : LOADING}
					</div>
				</div>
			</div>
		);
	}
}
