import React from 'react';
import t from 'i18n';
import { Pager, Select } from '@smartplatform/ui';

export default class PagerWithPerPage extends React.Component {
	render() {
		const { page, perPage, totalCount, onChange, onRowClick } = this.props;

		return (
			<div className='d-flex align-items-center'>
				<Pager itemsPerPage={perPage} current={page} onChange={onChange('page')} totalCount={totalCount || 0} onRowClick={onRowClick} />
				<div className='d-flex align-items-center ml-4'>
					<div className='text-nowrap mr-2'>{t('countOnPage')}</div>
					<Select noTotal isRequired items={[10, 15, 20, 50, 100]} value={perPage} onChange={onChange('perPage')} />
				</div>
			</div>
		);
	}
}
