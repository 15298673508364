import {observable} from "mobx";

export default class InformationAboutIdentifyingPerpetratorsStore {
	@observable startDate = null;
	@observable endDate = null;
	@observable ownership = null;

	onStartDateChange = (startDate) => this.startDate = startDate;

	onEndDateChange = (endDate) => this.endDate = endDate;

	onOwnershipChange = (ownership) => this.ownership = ownership;

	get storeParams() {
		const { startDate, endDate, ownership } = this;
		return {
			reportCode: 'InformationAboutIdentifyingPerpetrators',
			reportParams: { startDate, endDate, ownership, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone },
			isHTML: true,
		};
	}
}
