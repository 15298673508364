import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { UniversalList, UniversalEdit } from 'components';

export const UnversalDictionaryRouter = (props) => {
	const Edit = props.editComponent || UniversalEdit;
	const List = props.listComponent || UniversalList;
	const path = props.path || props.match.path;
	return (
		<Switch>
			<Route path={`${path}/:id`} children={({ match }) => <Edit {...props} match={match} path={path} />} />
			<Route path={path} exact children={(match) => <List {...props} match={match} path={path} />} />
		</Switch>
	);
};
