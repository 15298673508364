/* syntax = "proto3";

message Point {
    float x = 1;
    float y = 2;
    uint32 d = 3;
}

message Points {
//    repeated Point points = 1 [packed=true];
    repeated float points = 1 [packed=true];
}
 */
'use strict'; // code generated by pbf v3.3.0

// Point ========================================

var Point = exports.Point = {};

Point.read = function (pbf, end) {
    return pbf.readFields(Point._readField, {x: 0, y: 0, d: 0}, end);
};
Point._readField = function (tag, obj, pbf) {
    if (tag === 1) obj.x = pbf.readFloat();
    else if (tag === 2) obj.y = pbf.readFloat();
    else if (tag === 3) obj.d = pbf.readVarint();
};
Point.write = function (obj, pbf) {
    if (obj.x) pbf.writeFloatField(1, obj.x);
    if (obj.y) pbf.writeFloatField(2, obj.y);
    if (obj.d) pbf.writeVarintField(3, obj.d);
};

// Points ========================================

var Points = exports.Points = {};

Points.read = function (pbf, end) {
    return pbf.readFields(Points._readField, {points: []}, end);
};
Points._readField = function (tag, obj, pbf) {
    if (tag === 1) pbf.readPackedFloat(obj.points);
};
Points.write = function (obj, pbf) {
    if (obj.points) pbf.writePackedFloat(1, obj.points);
};

