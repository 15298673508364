const TECHFIRE_ICON_NAMES = {
	1: 'grey',
	2: 'yellow',
	3: 'black',
	4: 'red',
};

export const getTechFireIconName = (rankLevel) => {
	return TECHFIRE_ICON_NAMES[rankLevel] || TECHFIRE_ICON_NAMES.default;
};
