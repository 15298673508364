import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faEdit } from '@fortawesome/free-solid-svg-icons';
import store from 'client/store';
import Objects from './Objects';
import FireDepIcon from 'img/icons/shared/fire.svg';
import { Button, Input } from '@smartplatform/ui';
import debounce from 'lodash/debounce';
import t from 'i18n';
import DeleteIcon from 'img/icons/shared/delete.svg';
import { shortenAddressName } from '../../tools';

const ADDRESS_MODELS = ['Address', 'ViewTechFireAddress'];

@observer
export default class AddressInfo extends React.Component {
	static propTypes = {
		address: function (props) {
			if (!props.address) {
				return new Error(`AddressInfo: missing "address" prop`);
			}
			if (!ADDRESS_MODELS.includes(props.address.constructor.name)) {
				return new Error(`AddressInfo: "address" prop must be one of types: ${ADDRESS_MODELS.join(', ')}`);
			}
		},
		value: PropTypes.object,
		onChange: PropTypes.func,
	};

	@observable fireDep = null;
	@observable isDeleting = false;
	@observable isEdit = false;
	@observable nearestAddress;

	constructor(props) {
		super(props);
		this.saveName = debounce(this.saveName, 500);
		this.init();
	}

	init = async () => {
		const { address } = this.props;

		const [lon, lat] = address.geo.coordinates;
		const nearest = await store.model.Address.findAddressesByGeo({
			lon,
			lat,
			count: 1,
		});
		this.nearestAddress = nearest[0]?.name;

		const res = await address.getFireDep();
		if (res.length > 0) this.fireDep = res[0];

		if (address.name === '') {
			this.onEdit();
		}
	};

	onChange = async (name) => {
		const { address } = this.props;
		address.name = name;
		this.saveName();
	};

	saveName = async () => {
		const { address } = this.props;
		await address.save();
	};

	onDelete = async () => {
		const { address, onClose } = this.props;
		await address.techFireAddress.destroy();
		await address.delete();
		if (onClose) {
			onClose();
		} else {
			store.route.history.goBack();
		}
	};

	startDelete = () => (this.isDeleting = true);
	cancelDelete = () => (this.isDeleting = false);

	onEdit = () => (this.isEdit = !this.isEdit);

	onObjectSelect = async (object) => {
		let fullObject = await store.model.TechFireAddress.findById(object.id, {
			include: [
				{ relation: 'fireDep', scope: { fields: ['id', 'name'] } },
				{ relation: 'address', scope: { fields: ['id', 'name', 'geo', 'typeid', 'fireDepId'] } },
				{ relation: 'rank', scope: { fields: ['id', 'name'] } },
			],
		});
		if (this.fireDep && fullObject.fireDepId !== this.fireDep.id) {
			fullObject.fireDepId = this.fireDep.id;
			fullObject.fireDep = this.fireDep;
			await fullObject.save();
		}
		this.props.onChange && this.props.onChange(fullObject);
	};

	render() {
		const { address, value } = this.props;
		if (!address) return null;

		return (
			<div className='address-info'>
				<div className='hdr'>
					<div className='name'>
						<FontAwesomeIcon icon={faHome} />
						{this.isEdit ? <Input value={address.name} onChange={this.onChange} /> : <p className='techfire-address-name'>{shortenAddressName(address.name)}</p>}
						<FontAwesomeIcon icon={faEdit} onClick={this.onEdit} className='cursor-pointer pl-2' />
						<div className='delete-address'>
							{this.isDeleting ? (
								<>
									{t('confirmDelete')}
									<Button size='sm' variant='danger' onClick={this.onDelete}>
										{t('yes')}
									</Button>
									<Button size='sm' onClick={this.cancelDelete}>
										{t('no')}
									</Button>
								</>
							) : (
								<DeleteIcon onClick={this.startDelete} />
							)}
						</div>
					</div>
					<div className='info'>
						<FireDepIcon width={16} height={16} /> {this.fireDep ? this.fireDep.name : '-'}
					</div>
				</div>
				{!address.name && this.nearestAddress && (
					<div className='hdr nearest-address'>
						<b>{t('nearestAddress')}:&nbsp;</b> <p>{this.nearestAddress}</p>
					</div>
				)}
				<Objects address={address} value={value} onObjectSelect={this.onObjectSelect} fireDep={this.fireDep} />
			</div>
		);
	}
}
