import React from 'react';
import MapIcon from 'img/icons/techfires/card/map.svg';
import { FieldBasedCard } from '../../../../components';
import t from 'i18n';
import { inject } from 'mobx-react';

@inject('fireStore')
export default class Distance extends React.Component {
	componentDidMount() {
		const { fire } = this.props.fireStore;
		if (fire && !fire.distanceProperty) fire.distanceProperty = t('km');
	}

	render() {
		const { fire } = this.props.fireStore;
		const rows = [
			[
				{ property: 'fireDepDistance', label: 'fireDep.distance', type: 'NumberInput', props: { decimalScale: 2, decimalMark: ',', positiveOnly: true } },
				{
					property: 'distanceProperty',
					label: 'fireDep.distanceProperty',
					type: 'Select',
					props: { value: fire.distanceProperty, items: [t('km'), t('metre')], onChange: this.props.fireStore.onChange('distanceProperty') },
				},
			],
			[{ property: 'empty', type: 'default' }],
		];

		const fields = [(fire) => fire.fireDepDistance, (fire) => fire.distanceProperty];

		return (
			<FieldBasedCard
				title='techFire.distance'
				icon={MapIcon}
				fields={fields}
				fireStore={this.props.fireStore}
				rows={rows}
				statusKey='distance'
				isCard={true}
			/>
		);
	}
}
