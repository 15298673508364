import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Loader, Form, Field, Row, DatePicker } from '@smartplatform/ui';
import t from 'i18n';
import store from 'client/store';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import GarrisonSelect from 'techfires/components/garrison-select/GarrisonSelect';

@observer
export default class Edit extends React.Component {
	@observable record = null;
	@observable error = null;
	@observable isLoading = true;
	@observable existsGarrisonIds = [];

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.isLoading = true;
		const id = this.props.match.params.id;
		if (id) {
			try {
				this.record = await store.model.TechFireShift.findById(id);
			} catch (e) {
				this.error = e.message;
			}
		} else {
			this.record = new store.model.TechFireShift();
			this.record.date = new Date();
		}
		this.getExistsGarrisonsIds(new Date(this.record.date));
	};

	getExistsGarrisonsIds = async (date) => {
		const where = { date: { between: [startOfDay(date), endOfDay(date)] } };
		if (this.record.id) where.id = { neq: this.record.id };
		const shiftsByDate = await store.model.TechFireShift.find({
			where: { ...where },
			include: { relation: 'garrison', scope: { fields: ['id'] } },
		});
		const ids = shiftsByDate.reduce((acc, item) => {
			if (item.garrison) acc.push(item.garrison.id);
			return acc;
		}, []);
		this.existsGarrisonIds = ids;
		this.isLoading = false;
	};

	onChangeDate = (value) => {
		this.record.date = value;
		this.getExistsGarrisonsIds(value);
	};

	render() {
		if (this.isLoading) return <Loader />;
		if (this.error) return <div className='error'>{this.error}</div>;

		return (
			<div>
				<Form record={this.record} returnTo='/techfires/shifts' disableSave={!this.record.name || !this.record.date || !this.record.garrisonId}>
					<Row>
						<Field property='name' label={t('name')} isRequired />
						<Field property='date' label={t('date')} isRequired>
							<DatePicker onChange={this.onChangeDate} showClearButton={false} />
						</Field>
						<Field relation='garrison' property='name' label={t('garrison')} isRequired>
							<GarrisonSelect where={{ id: { nin: this.existsGarrisonIds } }} isRequired />
						</Field>
						<div />
					</Row>
				</Form>
			</div>
		);
	}
}
