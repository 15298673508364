import React from 'react';
import { inject, observer } from 'mobx-react';
import FileIcon from 'img/icons/techfires/card/file-text.svg';
import { FieldBasedCard } from '../../../../components';

@inject('fireStore')
@observer
class InfoAboutFire extends React.Component {
	render() {
		const { fireStore } = this.props;
		const { fire, onChange } = fireStore;

		const rows = [
			[
				{
					property: 'reportTime',
					label: 'techFire.messageFireDep',
					type: 'DatePicker',
					props: { showTimeSelect: true, showClearButton: false, onChange: onChange('reportTime') },
				},
				{
					property: 'detectTime',
					label: 'techFire.detectTime',
					type: 'DatePicker',
					props: { showTimeSelect: true, showClearButton: false, disabled: true },
				},
			],
			[
				{
					property: 'rank.name',
					label: 'techFireAddress.rank',
					type: 'LabelField',
					props: { value: fire.rank?.name, disabled: true },
				},
				{
					property: 'name',
					label: 'techFire.status',
					type: 'relation',
					relation: 'status',
				},
			],
		];

		return (
			<FieldBasedCard
				title='techFire.infoAboutFire'
				icon={FileIcon}
				fields={[fire.reportTime, fire.detectTime, fire.rank?.name, fire.status?.name]}
				rows={rows}
				statusKey='infoAboutFire'
				fireStore={fireStore}
				isCard={true}
			/>
		);
	}
}

export default InfoAboutFire;
