import React from 'react';
import t from 'i18n';
import { ReportStore } from '../store';
import { DatePicker, RecordSelect, Row } from '@smartplatform/ui';
import { ReportToolbar } from 'components';
import store from 'client/store';
import { observer } from 'mobx-react';

const CODE = 'InfoVisitsFireRescueUnits';
@observer
export class InfoVisitsFireRescueUnits extends React.Component {
	constructor(props) {
		super(props);
		this.store = new ReportStore(CODE, t('techFireReport.infoVisitsFireRescueUnits'));
	}

	onHeadOrgChange(value) {
		const { onHeadOrgChange, getHeadOrgIds, clearFireDeps } = this.store;
		onHeadOrgChange(value);
		this.headOrg = value;
		if (value) getHeadOrgIds();
		clearFireDeps();
	}

	renderFireDeps = (fireDep) => (
		<div>
			<span className='status-color' style={{ background: fireDep.color || '#808080' }} />
			<span>{t(fireDep.name)}</span>
		</div>
	);

	render() {
		const { endDate, startDate, headOrg, fireDeps, onEndDateChange, onStartDateChange, getFireDepIds, onFireDepToggle, error, name } = this.store;
		const showFireDepsValue =
			fireDeps.length > 0 ? t('fireDep.selected') + ': ' + fireDeps.length : <span className='hint'>{t('fireDep.selectPlaceholder')}</span>;
		return (
			<div className='info-visits-fire-rescue-units'>
				<h2 className='title'>{name}</h2>
				<div className='filter'>
					<div>
						<div className='form-field'>
							<label>{t('date1')}</label>
							<DatePicker value={startDate} onChange={onStartDateChange} showClearButton={false} />
						</div>
					</div>
					<div>
						<div className='form-field'>
							<label>{t('date2')}</label>
							<DatePicker value={endDate} onChange={onEndDateChange} showClearButton={false} />
						</div>
					</div>
				</div>
				<Row>
					<div className='form-field'>
						<label>{t('techFireOrganization.title')}</label>
						<RecordSelect
							noTotal
							model={store.model.TechFireOrganization}
							value={headOrg}
							property='name'
							className='drop-down-md'
							onChange={(value) => this.onHeadOrgChange(value)}
							filter={{ where: { parentId: null } }}
						/>
					</div>
					<div className='form-field'>
						<label>{t('fireDep.title')}</label>
						<RecordSelect
							model={store.model.FireDep}
							value={fireDeps}
							onChange={onFireDepToggle}
							computed={this.renderFireDeps}
							showValue={showFireDepsValue}
							className='drop-down-md'
							filter={{ fields: ['id', 'name', 'code'], where: { headOrgId: { inq: getFireDepIds(headOrg) } } }}
						/>
					</div>
				</Row>
				<ReportToolbar {...this.store.storeParams} />
				{error && <span className='error'>{error}</span>}
			</div>
		);
	}
}
