import React from 'react';
import Root from './Root';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import t from 'i18n';
import tMap from '@smartplatform/map/i18n';
import store from 'client/store';
import DeliveryIcon from 'img/icons/menu/delivery.svg';
import MapIcon from 'img/icons/sidebar/shared/map.svg';
import AdminIcon from 'img/icons/sidebar/shared/admin.svg';
import RequestIcon from 'img/icons/sidebar/delivery/request.svg';
import ReservesIcon from 'img/icons/sidebar/delivery/reserves.svg';
import ContragentsIcon from 'img/icons/sidebar/shared/contragents.svg';
import DictionaryIcon from 'img/icons/sidebar/shared/dictionary.svg';
import AnalyticsIcon from 'img/icons/sidebar/delivery/analytics.svg';
import TrackingIcon from 'img/icons/sidebar/delivery/tracking.svg';
import PlanIcon from 'img/icons/sidebar/delivery/plan.svg';
import JourneyIcon from 'img/icons/sidebar/shared/journey.svg';
import OperInfoIcon from 'img/icons/sidebar/delivery/oper-info.svg';
import ReportIcon from 'img/icons/sidebar/shared/report.svg';
import IncidentIcon from 'img/icons/sidebar/delivery/incidents.svg';

export const path = '/delivery';

export default {
	name: t('module.delivery'),
	code: 'delivery',
	icon: <DeliveryIcon />,
	path,
	component: Root,
	access: () => store.model.Request && store.model.Request.INFO.READ,
	menu: [
		{ title: t('delivery.map'), path: `${path}/map`, icon: <MapIcon />, exact: true },
		{ title: t('request.plural'), path: `${path}/requests`, icon: <RequestIcon /> },
		{ title: t('plan.plural'), path: `${path}/plans`, icon: <PlanIcon /> },
		{ title: t('operInfo.plural'), path: `${path}/oper-info`, icon: <OperInfoIcon /> },
		{ title: t('tracking.titleShort'), path: `${path}/tracking`, icon: <TrackingIcon /> },
		{ title: t('requestIncident.plural'), path: `${path}/incidents`, icon: <IncidentIcon /> },
		{ title: t('reserve.plural'), path: `${path}/reserves`, icon: <ReservesIcon /> },
		{ title: t('contragent.plural'), path: `${path}/contragents`, icon: <ContragentsIcon /> },
		{ title: t('analytics.title'), path: `${path}/analytics`, icon: <AnalyticsIcon /> },
		{ title: t('report.plural'), path: `${path}/reports`, icon: <ReportIcon /> },
		{ title: tMap('map.layer.plural'), path: `/layers`, icon: <FontAwesomeIcon icon={faLayerGroup} style={{ fontSize: 26 }} /> },
		{ title: t('dictionaries.plural'), path: `${path}/dictionaries`, icon: <DictionaryIcon /> },
		{ title: t('audit.title'), path: `${path}/journal`, icon: <JourneyIcon /> },
		{ title: t('admin.titleShort'), path: `${path}/admin`, icon: <AdminIcon /> },
	],
};
