import React from 'react';
import { observer, inject } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faArrowLeft, faExpand, faCompress } from '@fortawesome/free-solid-svg-icons';
import { Panel, HeatPointsFilter, WeatherFilter, WindFilter, ModelsFilter, modelsConfig, QuartersFilter } from 'components';
import store from 'client/store';
import '../../../map/filters/style.scss';

@inject('card')
@observer
export default class Filters extends React.Component {
	render() {
		const { card } = this.props;
		const { map } = card;

		if (!map.isInitialized) return null;

		const localSettings = store.local.fires.card;
		const settings = localSettings.layers;

		const additionalControls = (
			<div className={'map-additional-controls' + (localSettings.filtersMaximized ? ' shifted' : '')}>
				<div className='map-additional-btn' onClick={card.toggleMapMaximized}>
					<FontAwesomeIcon icon={card.mapMaximized ? faCompress : faExpand} />
				</div>
			</div>
		);

		return localSettings.filtersMaximized ? (
			<Panel left={-95} top={-70} width={290} bottom={70} height='auto' scrollable={false} wrapperClassName='fires-filters-wrapper' className='fires-filters'>
				<div className='minimize-btn' onClick={() => map.switchFilters(false)}>
					<FontAwesomeIcon icon={faArrowLeft} />
				</div>
				{additionalControls}
				<HeatPointsFilter layer={map.heatPointsLayer} settings={settings.heatPoints} />
				<ModelsFilter layer={map.modelsLayer} settings={settings.models} modelsConfig={modelsConfig} />
				<QuartersFilter inCard layer={map.forestryQuartersLayer} settings={settings.forestryQuarters} />
				<WeatherFilter layer={map.weatherLayer} settings={settings.weather} />
				<WindFilter layer={map.windLayer} settings={settings.wind} />
			</Panel>
		) : (
			<>
				<div className='fires-filters-minimized' onClick={() => map.switchFilters(true)}>
					<FontAwesomeIcon icon={faFilter} />
				</div>
				{additionalControls}
			</>
		);
	}
}
