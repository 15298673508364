import fire_green from './fire_green.svg?url';
import fire_red from './fire_red.svg?url';
import fire_grey from './fire_grey.svg?url';
import fire_black from './fire_black.svg?url';
import fire_yellow from './fire_yellow.svg?url';
import t from 'i18n';

export default {
	red: { src: fire_red, label: t('fireStatus.codes.active') },
	yellow: { src: fire_yellow, label: t('fireStatus.codes.abandoned') },
	green: { src: fire_green, label: t('fireStatus.codes.localized') },
	black: { src: fire_black, label: t('fireStatus.codes.extinguished') },
	grey: { src: fire_grey, label: t('fireStatus.codes.zik') },
	default: fire_red,
}

