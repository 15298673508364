import { autorun, observable } from 'mobx';
import debounce from 'lodash/debounce';
import { startOfDay, endOfDay } from 'date-fns';
import { TECHFIRE_ADDRESS_LOCAL_STORAGE_FIELDS } from './constants';
import store from 'client/store';

const PER_PAGE = 10;

export default class addressStore {
	@observable rank = null;
	@observable ptp = null;
	@observable ktp = null;
	@observable overnightStay = null;
	@observable overduePTPCTP = null;
	@observable workingOutPTPCTP = null;
	@observable date0 = null;
	@observable date1 = null;
	@observable date2 = null;
	@observable date3 = null;
	@observable order = null;
	@observable totalCount = null;
	@observable page = 1;
	@observable addressName = null;
	@observable name = null;
	@observable description = null;
	@observable objectsCount = null;

	@observable objectsCountSearch = null;
	@observable addressSearch = null;
	@observable nameSearch = null;
	@observable descriptionSearch = null;
	@observable garrison = null;

	constructor() {
		this.doSearch = debounce(this.doSearch, 500, { leading: false, trailing: true });
		this.init();
	}

	init = () => {
		this.loadLocalStorage();
		autorun(() => {
			this.saveToLocalStorage();
		});
		this.getFilters();
	};

	getFilters = () => {
		const filters = {
			where: {
				and: [],
			},
		};
		if (this.rank) filters.where.and.push({ rank: this.rank.name });
		if (this.name) filters.where.and.push({ name: { ilike: `%${this.name}%` } });
		if (this.addressName) filters.where.and.push({ addressName: { ilike: `%${this.addressName}%` } });
		if (this.description) filters.where.and.push({ description: { ilike: `%${this.description}%` } });
		if (this.objectsCount) filters.where.and.push({ objectsCount: { ilike: `%${this.objectsCount}%` } });
		if (this.ptp) filters.where.and.push({ ptp: { neq: null } });
		if (this.ktp) filters.where.and.push({ ktp: { neq: null } });
		if (this.overnightStay) filters.where.and.push({ overnightStay: true });
		if (this.overduePTPCTP) filters.where.and.push({ inspectionDate: { lt: startOfDay(new Date()) } });
		if (this.date0) filters.where.and.push({ inspectionDate: { gte: startOfDay(this.date0) } });
		if (this.date1) filters.where.and.push({ inspectionDate: { lte: endOfDay(this.date1) } });
		if (this.workingOutPTPCTP) filters.where.and.push({ inspectionDateCorrect: { lt: startOfDay(new Date()) } });
		if (this.date2) filters.where.and.push({ inspectionDateCorrect: { gte: startOfDay(this.date2) } });
		if (this.date3) filters.where.and.push({ inspectionDateCorrect: { lte: endOfDay(this.date3) } });
		if (this.garrison) filters.where.and.push({ garrisonId: this.garrison.id });

		filters.order = this.order;
		filters.skip = (this.page - 1) * PER_PAGE;
		filters.limit = PER_PAGE;

		return filters;
	};

	compileQuery = () => {
		this.query = { ...this.filters.filter };
	};

	onChange = (prop) => (value) => {
		this.page = 1;
		this[prop] = value;
		this.saveToLocalStorage();
		this.getFilters();
	};

	doSearch = (prop, searchField) => {
		this[prop] = this[searchField];
		this.saveToLocalStorage();
		this.getFilters();
	};

	onSearch = (searchField, prop) => (value) => {
		this.page = 1;
		this[searchField] = value;
		this.doSearch(prop, searchField);
	};

	onQueryUpdate = (query) => {
		if (query._totalCount) this.totalCount = query._totalCount;
		this.order = query.order;
	};

	resetFilters = () => {
		TECHFIRE_ADDRESS_LOCAL_STORAGE_FIELDS.forEach((field) => {
			this[field] = null;
		});
		this.page = 1;
		this.saveToLocalStorage();
		this.getFilters();
	};

	// LocalStorage
	localStorage = store.local.techfires.addressObjects;
	saveToLocalStorage = () => {
		TECHFIRE_ADDRESS_LOCAL_STORAGE_FIELDS.forEach((field) => (this.localStorage[field] = this[field]));
		store.local.save();
	};
	loadLocalStorage = () => {
		const storageKeys = Object.keys(this.localStorage);
		if (storageKeys.length)
			storageKeys.forEach((key) => {
				this[key] = this.localStorage[key];
			});
	};
}
