import React from 'react';
import { inject, observer } from 'mobx-react';

@inject('report')
@observer
export class Filters extends React.Component {
	render() {
		return (
			<div className='filters'>

			</div>
		);
	}
}
