import { observer } from 'mobx-react';
import React from 'react';
import t from 'i18n';
import { Column } from '@smartplatform/ui';
import { Table } from 'components/table-new';
import store from 'client/store';
import TemperatureTubeStore from './store';
import { Toolbar } from 'components';
import PagerWithPerPage from '../components/pager/PagerWithPerPage';
import {exportReportHTMLByClassname} from "client/tools";

@observer
export default class List extends React.Component {
	constructor(props) {
		super(props);
		this.store = new TemperatureTubeStore();
	}

	onCreate = () => store.route.push({ path: `${this.props.match.path}/create` });

	onRowClick = (record) => store.route.push({ path: `${this.props.match.path}/${record.id}` });

	render() {
		const { page, perPage, totalCount, onChange, query, onQueryUpdate, onRowClick, search, onSearch } = this.store;
		const tableProps = {
			model: store.model.PermafrostTemperatureTube,
			query,
			onQueryUpdate,
			filters: true,
			onRowClick,
			skeletonRow: true,
			infinityScrollPartSize: perPage
		};
		return (
			<div>
				<div className='top'>
					<h2>{t('permafrost.tubeMenu')}</h2>
					<Toolbar>
						<Toolbar.AddButton />
						<Toolbar.SearchIconInput value={search} onChange={onSearch} />
						<Toolbar.ExportButton onClick={() => exportReportHTMLByClassname({ className: 'sp-table', name: t('permafrost.tubeMenu') })} />
					</Toolbar>
				</div>
				<div>
					<PagerWithPerPage perPage={perPage} page={page} onChange={onChange} onRowClick={onRowClick} totalCount={totalCount} />
					<div className='overflow-auto'>
						<Table {...tableProps}>
							<Column property='id' label={t('id')} width={50} />
							<Column relation='object' computed={(object) => object.address?.name || 'Без адреса'} label={t('permafrost.buildings')} width={600} />
							<Column property='number' label={t('permafrost.number')} />
							<Column relation='type' property='name' label={t('type')} />
							<Column relation='maxDepth' property='name' label={t('permafrost.temperatureTube.maxDepth')} />
							<Column relation='status' property='name' label={t('status')} />
							<Column property='pile' label={t('permafrost.temperatureTube.pile')} />
						</Table>
					</div>
				</div>
			</div>
		);
	}
}
