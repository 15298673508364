import React from 'react';
import ClockPlusIcon from 'img/icons/fires/dashboard/clock-plus.svg';
import { Card } from 'components';
import { DatePicker, Loader } from '@smartplatform/ui';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';

@inject('store')
@observer
export default class CountFiresByStatusTable extends React.Component {
	constructor(props) {
		super(props);
		this.store = props.store;
		this.store.fetchRecordsFireByPeriod();
	}

	@observable loading;

	onChange = async (prop, value) => {
		this.loading = true;
		this.store[prop] = value;
		await this.store.fetchRecordsFireByPeriod();
		this.loading = false;
	};

	render() {
		const { dataYear, dataPreviousYear } = this.store;

		return (
			<Card icon={<ClockPlusIcon />} title='Количество пожаров за сезон' className='count-fires-table'>
				<div className='filter-wrapper'>
					<div className='filter-item'>
						<label>Дата начала</label>
						<DatePicker value={this.store.startDate} onChange={(value) => this.onChange('startDate', value)} showClearButton={false} />
					</div>
					<div className='filter-item'>
						<label>Дата конца</label>
						<DatePicker value={this.store.endDate} onChange={(value) => this.onChange('endDate', value)} showClearButton={false} />
					</div>
				</div>
				{this.loading ? (
					<Loader />
				) : !dataYear.length || !dataPreviousYear.length ? (
					'Нет данных'
				) : (
					<table className='dashboard-table'>
						<thead>
							<tr>
								<th className='head'></th>
								<th className='head' width='33%'>
									<span>Текущий год</span>
								</th>
								<th className='head' width='33%'>
									<span>Аналогичный период прошлого года</span>
								</th>
							</tr>
						</thead>
						<tbody>
							{dataYear.map((record, index) => {
								return (
									<tr key={index}>
										<td className='head'>{record.statusName}</td>
										<td className='value'>{record.count}</td>
										<td className='value'>{dataPreviousYear[index].count}</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				)}
			</Card>
		);
	}
}
