import { observer } from 'mobx-react';
import React from 'react';
import t from 'i18n';
import { Column } from '@smartplatform/ui';
import { Table } from 'components/table-new';
import store from 'client/store';
import LevelingMarksStore from './store';
import { Toolbar } from 'components';
import PagerWithPerPage from '../../components/pager/PagerWithPerPage';
import { fio } from 'client/tools';

@observer
export default class List extends React.Component {
	constructor(props) {
		super(props);
		this.store = new LevelingMarksStore();
	}

	onRowClick = (record) => store.route.push({ path: `${this.props.match.path}/${record.id}` });

	render() {
		const { page, perPage, totalCount, onChange, query, onQueryUpdate, onRowClick, search, onSearch, measurementCount } = this.store;
		const tableProps = {
			model: store.model.ViewPermafrostLevelingMark,
			query,
			onQueryUpdate,
			filters: true,
			onRowClick,
			skeletonRow: true,
			infinityScrollPartSize: perPage,
		};
		return (
			<div>
				<div className='top'>
					<Toolbar>
						<Toolbar.SearchIconInput value={search} onChange={onSearch} />
						<span className='measurements-count'>
							{t('permafrost.measurementsCount')} {measurementCount}
						</span>
					</Toolbar>
				</div>
				<div>
					<PagerWithPerPage perPage={perPage} page={page} onChange={onChange} onRowClick={onRowClick} totalCount={totalCount} />
					<div className='overflow-auto'>
						<Table {...tableProps}>
							<Column property='isApproved' label={t('permafrost.approved')} width={100} />
							<Column property='addressName' label={t('permafrost.buildings')} width={600} />
							<Column property='id' label={t('id')} width={50} />
							<Column property='number' label={t('permafrost.number')} width={100} />
							<Column property='typeName' label={t('type')} width={100} />
							<Column property='statusName' label={t('status')} width={100} />
							<Column property='heightSystemName' label={t('permafrost.levelingMarks.heightSystem')} width={100} />
							<Column property='mark' label={t('permafrost.mark')} width={100} />
							<Column property='date' label={t('date')} width={120} />
							<Column relation='owner' computed={(owner) => fio(owner)} label={t('user.title')} width={200} />
						</Table>
					</div>
				</div>
			</div>
		);
	}
}
