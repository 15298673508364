import React from 'react';
import { inject, observer } from 'mobx-react';
import { Input, NumberInput, Row } from '@smartplatform/ui';
import t from 'i18n';
import ThermometerIcon from 'img/icons/fires/card/thermometer.svg';
import { Card, LabelField } from 'components';

@inject('card')
@observer
export class Meteo extends React.Component {
	render() {
		const { card, disabled } = this.props;
		return (
			<Card title={t('fire.meteo')} icon={<ThermometerIcon />}>
				<Row className='card-row'>
					{['KPOdetected', 'KPOliquidation'].map((label) => (
						<LabelField label={t('fire.' + label)} key={label} text={card.fire[label] || t('field.empty')} />
					))}
					<LabelField label={t('fire.frontalPrecipitation')}>
						<Input value={card.fire.frontalPrecipitation} onChange={card.onChange('frontalPrecipitation')} disabled={disabled} />
					</LabelField>
				</Row>
				<Row>
					<LabelField label={t('fire.windSpeed')}>
						<NumberInput possitiveOnly decimalScale={2} value={card.fire.windSpeed} onChange={card.onChange('windSpeed')} disabled={disabled} />
					</LabelField>
					<div />
					<div />
				</Row>
			</Card>
		);
	}
}
