import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import Dropzone from 'react-dropzone';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FormContext, Button, Popconfirm } from '@smartplatform/ui';
import t from 'i18n';

@observer
export class _FileInput extends React.Component {
	static propTypes = {
		value: PropTypes.any,
		accept: PropTypes.object,
		property: PropTypes.string,
		label: PropTypes.string,
		onDelete: PropTypes.func,
		onChange: PropTypes.func,
	};

	static defaultProps = {
		accept: '',
		type: 'file',
		__isInput: true,
	};

	@observable url = null;
	@observable fileName = null;
	@observable accepted = null;
	@observable rejected = null;
	@observable currentValue = null;

	constructor(props) {
		super(props);
		this.currentValue = props.value;
		this.property = props.property || props.__data.property;
		this.getUrl();
	}

	componentDidUpdate(prevProps) {
		// console.log('componentDidUpdate', this.props.value);
		if (prevProps.value !== this.props.value) {
			// console.log('new value', this.props.value.constructor === File);
			if (this.props.value.constructor !== File) {
				this.currentValue = this.props.value;
				this.accepted = null;
				this.rejected = null;
				this.getUrl();
			} else {
			}
		}
	}

	getUrl() {
		const { record } = this.props;
		this.url = record[this.property] ? record.downloadFile(this.property) : null;
	}

	onDrop = (accepted, rejected) => {
		this.url = null;
		if (accepted.length > 0) {
			this.accepted = accepted.map((f, i) => (
				<div key={i}>
					{t('file.willBeUploaded')}: {f.name}
				</div>
			));
			this.handleFile(accepted[0]);
		} else {
			this.accepted = null;
		}

		if (rejected.length > 0) {
			this.rejected = rejected.map((f, i) => (
				<div key={i}>
					{t('file.wrongType')}: {f.name}
				</div>
			));
		} else {
			this.rejected = null;
		}
	};

	handleFile(file) {
		if (this.props.form && this.props.form.setFile) {
			this.props.form.setFile({
				file,
				property: this.property,
				label: this.props.label,
			});
		}
		if (this.props.onChange) this.props.onChange(file);
	}

	deleteFile = () => {
		const { record, property } = this.props;
		record[property] = null;
		record.deleteFile(property);
		this.url = null;
		record.save().then(() => {
			if (this.props.onDelete) this.props.onDelete();
			if (this.props.onChange) this.props.onChange(null);
		});
	};

	render() {
		const { record, disabled } = this.props;

		return (
			<div className={'file-input ' + (this.props.className || '')}>
				<div className='file-attach'>
					{!disabled && (
						<Dropzone accept={this.props.accept} onDrop={this.onDrop} className='drop-zone' multiple={false}>
							{({ getRootProps, getInputProps }) => (
								<div {...getRootProps()}>
									<input {...getInputProps()} />
									<Button variant='primary'>{t('file.uploadFile')}</Button>
								</div>
							)}
						</Dropzone>
					)}
					{this.url && (
						<div className='uploaded-file'>
							{typeof record[this.property] === 'string' && (
								<a target='_blank' href={this.url}>
									{record[this.property]}
								</a>
							)}
							{!disabled && (
								<Popconfirm className='delete-btn' content={t('delete') + '?'} onConfirm={this.deleteFile}>
									<FontAwesomeIcon icon={faTrash} />
								</Popconfirm>
							)}
						</div>
					)}
					{!this.url && this.accepted && <div className='accepted'>{this.accepted}</div>}
					{!this.url && this.rejected && <div className='rejected'>{this.rejected}</div>}
				</div>
			</div>
		);
	}
}

export const FileInput = (props) => <FormContext.Consumer>{(inject) => <_FileInput {...props} {...inject} />}</FormContext.Consumer>;
