export const ADMIN_MODELS = [
	{
		modelName: 'ViewUser',
		listProperties: ['id', 'username', 'fullName', 'email', 'organizationName', 'lastActivity'],
	},
	{
		modelName: 'Role',
		listProperties: ['id', 'name', 'description'],
		editProperties: ['name', 'description'],
	},
];

export const ADMIN_MODELS_PERMAFROST = [
	{
		modelName: 'ViewUser',
		listProperties: ['id', 'username', 'fullName', 'email', 'permafrostOrganizationName', 'lastActivity'],
	},
	{
		modelName: 'Role',
		listProperties: ['id', 'name', 'description'],
		editProperties: ['name', 'description'],
	},
];
