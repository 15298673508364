import React from 'react';
import { inject, observer } from 'mobx-react';
import {DatePicker, RecordSelect, Select} from '@smartplatform/ui';

import t from 'i18n';

@inject('report')
@observer
export class Filters extends React.Component {
	render() {
		const { report } = this.props;

		return (
			<div className='filters'>
				<div className='row'>
					<div className='col-3'>
						<div className='form-field'>
							<label>{t('date')}</label>
							<DatePicker
								showTimeSelect={false}
								showClearButton={false}
								value={report.date}
								onChange={report.onDateChange}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
