import React from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import store from 'client/store';
import { Input, Row, StringValue, Form } from '@smartplatform/ui';
import { Card } from 'components';
import { renderStatus } from 'client/tools';
import { ALARM_TYPE_ADPI, ALARM_TYPE_112, ALARM_TYPE_PHONE, ALARM_TYPE_MANUAL } from 'techfires/constants';
import t from 'i18n';
import UserIcon from 'img/icons/techfires/card/user.svg';
import AlarmAdpi from './Adpi';
import Alarm112 from './112';
import AlarmPhone from './Phone';
import AlarmManual from './Manual';
import FieldChecker from '../../../../../helpers/fieldChecker';

@inject('fireStore')
@observer
export default class InfoAboutPerson extends React.Component {
	handleStatusChange = (areFieldsFilled) => {
		this.props.fireStore.updateFieldStatuses('infoAboutPerson', areFieldsFilled);
	};

	render() {
		const { alarmMessage } = this.props.fireStore;

		if (!alarmMessage)
			return (
				<Card title={t('techFire.infoAboutPerson')} icon={<UserIcon />} collapsible>
					<div>Нету сообщения о пожаре</div>
				</Card>
			);
		const alarmType = alarmMessage && alarmMessage.source?.code;

		return (
			<FieldChecker
				fields={[alarmMessage?.reportingPersonPhone, alarmMessage?.reportingPersonName, alarmMessage?.reportingPersonAddress, alarmMessage?.information]}
				onStatusChange={this.handleStatusChange}
			>
				{(areFieldsFilled) => (
					<Card title={t('techFire.infoAboutPerson')} icon={<UserIcon />} isValid={areFieldsFilled} collapsible>
						<Form record={alarmMessage} className='alarm-message' noSave noDelete stay>
							<Row className='card-row'>
								<div className='form-field'>
									<label>{t('techFireReportSource.title')}</label>
									<Input value={alarmMessage?.source?.description} disabled />
								</div>
								<div className='form-field'>
									<label>{t('techFireReportSource.title')}</label>
									<StringValue value={renderStatus(alarmMessage.status)} />
								</div>
								<div />
							</Row>
							{alarmType === ALARM_TYPE_ADPI && <AlarmAdpi alarmMessage={alarmMessage} />}
							{alarmType === ALARM_TYPE_112 && <Alarm112 alarmMessage={alarmMessage} />}
							{alarmType === ALARM_TYPE_PHONE && <AlarmPhone record={alarmMessage} canEdit />}
							{alarmType === ALARM_TYPE_MANUAL && <AlarmManual record={alarmMessage} canEdit />}
						</Form>
					</Card>
				)}
			</FieldChecker>
		);
	}
}
