import React from 'react';
import t from 'i18n';
import { ReportStore } from '../store';
import { DatePicker } from '@smartplatform/ui';
import { ReportToolbar } from 'components';
import { observer } from 'mobx-react';

const CODE = 'InfoAboutFireProtectionUnits';
@observer
export class InfoAboutFireProtectionUnits extends React.Component {
	constructor(props) {
		super(props);
		this.store = new ReportStore(CODE, t('techFireReport.infoAboutFireProtectionUnits'));
	}
	render() {
		const { endDate, startDate, onEndDateChange, onStartDateChange, error, name } = this.store;
		return (
			<div className='info-fire-protection-units'>
				<h2 className='title'>{name}</h2>
				<div className='filter'>
					<div>
						<div className='form-field'>
							<label>{t('date1')}</label>
							<DatePicker value={startDate} onChange={onStartDateChange} showClearButton={false} />
						</div>
					</div>
					<div>
						<div className='form-field'>
							<label>{t('date2')}</label>
							<DatePicker value={endDate} onChange={onEndDateChange} showClearButton={false} />
						</div>
					</div>
				</div>
				<ReportToolbar {...this.store.storeParams} />
				{error && <span className='error'>{error}</span>}
			</div>
		);
	}
}
