import { observable } from 'mobx';
import proj4 from 'proj4';

import { geoJsonToHex, hexToGeoJson } from 'client/tools';
import store from 'client/store';

export default class HeatPointSelect {
	@observable isLoading = false;
	@observable isSelecting = false;

	constructor(card) {
		this.card = card;
	}

	onSelectElementMount = (el) => {
		if (!el) return;
		el.style = '';
		const rect = el.getBoundingClientRect();
		this.parentX = rect.left;
		this.parentY = rect.top;
	};

	onSelectionMount = (el) => {
		this.selectionElememt = el;
	};

	getRect = () => {
		const left = this.endX > this.startX ? this.startX : this.endX;
		const top = this.endY > this.startY ? this.startY : this.endY;
		const width = Math.abs(this.endX - this.startX);
		const height = Math.abs(this.endY - this.startY);
		return { left, top, width, height };
	};

	onMouseDown = (e) => {
		if (!this.selectionElememt) return;
		this.isSelecting = true;
		this.startX = e.clientX - this.parentX;
		this.startY = e.clientY - this.parentY;
	};

	onMouseMove = (e) => {
		if (!this.isSelecting || !this.selectionElememt) return;
		this.endX = e.clientX - this.parentX;
		this.endY = e.clientY - this.parentY;

		const { left, top, width, height } = this.getRect();
		this.selectionElememt.style = `left: ${left}px; top: ${top}px; width: ${width}px; height: ${height}px;`;
	};

	onMouseUp = async () => {
		if (!this.selectionElememt) return;
		this.isSelecting = false;
		this.selectionElememt.style = `left: 0px; top: 0px; width: 0px; height: 0px;`;
		const { left, top, width, height } = this.getRect();
		const map = this.card.map.mapStore.map;
		const [x0, y1] = map.getCoordinateFromPixel([left, top]);
		const [x1, y0] = map.getCoordinateFromPixel([left + width, top + height]);
		const lonLat0 = proj4('EPSG:3857', 'EPSG:4326', [x0, y0]);
		const lonLat1 = proj4('EPSG:3857', 'EPSG:4326', [x1, y1]);
		console.log([lonLat0[0], lonLat0[1], lonLat1[0], lonLat1[1]]);
		await this.getHeatPointsPolygon([lonLat0[0], lonLat0[1], lonLat1[0], lonLat1[1]]);
		// this.card.map.toggleSelectMode(null);
	};

	getHeatPointsPolygon = async (coords) => {
		console.log('getHeatPointsPolygon', this.card.map.selectMode, coords);

		const [x0, y0, x1, y1] = coords;
		const currentGeo = geoJsonToHex(this.card.fire.geo);

		const res = await store.model.FireData.getHeatPointsPolygon({
			x0,
			y0,
			x1,
			y1,
			geo: currentGeo,
			mode: this.card.map.selectMode,
			radius: store.local.fires.card.layers.heatPoints.selectRadius || 500,
			// date0: new Date(this.card.fireLogs[0].date),
			// date1: new Date(this.card.fire.date),
			date0: this.card.map.heatPointsStartDate,
			date1: this.card.map.heatPointsEndDate,
		});

		if (res.empty) {
			return;
		}

		let geo = hexToGeoJson(res.geo);
		if (geo && geo.coordinates && geo.coordinates.length === 0) geo = null;
		console.log('geo', geo);

		this.card.onChange('geo')(geo);
		this.card.onChange('area')(res.area || 0);
		this.card.onChange('edge')(res.edge || 0);
		this.card.onChange('protectiveArea')(res.protectiveArea || 0);
		this.card.onChange('reserveArea')(res.reserveArea || 0);
		this.card.onChange('operationalArea')(res.operationalArea || 0);
		this.card.onChange('forestArea')(res.forestArea || 0);
		this.card.onChange('nonForestArea')(res.nonForestArea || 0);

		let forestCategory = null;
		const forestCategories = await store.model.ForestCategory.find();
		if (res.protectiveArea > res.reserveArea && res.protectiveArea > res.operationalArea) {
			forestCategory = forestCategories.find((r) => r.code === '1');
		} else if (res.reserveArea > res.protectiveArea && res.reserveArea > res.operationalArea) {
			forestCategory = forestCategories.find((r) => r.code === '2');
		} else if (res.operationalArea > res.protectiveArea && res.operationalArea > res.reserveArea) {
			forestCategory = forestCategories.find((r) => r.code === '3');
		}
		if ((this.card.fire.forestCategoryId || null) !== (forestCategory ? forestCategory.id : null)) {
			this.card.onChange('forestCategory')(forestCategory);
		}

		this.card.map.addGeo();
	};
}
