import React from 'react';
import { inject, observer } from 'mobx-react';
import ExtinguishingIcon from 'img/icons/fires/dashboard/extinguishing.svg';
import { Card } from 'components';
import t from 'i18n';

@inject('dashboardStore')
@observer
export default class FirePlanExecution extends React.Component {
	render() {
		const { firePlanExecution } = this.props.dashboardStore.data;
		if (!firePlanExecution) return;

		return (
			<Card icon={<ExtinguishingIcon />} title={t('fireDashboard.firePlanExecution')} className='season'>
				<table className='dashboard-table'>
					<thead>
						<tr>
							<th width='50%'>{t('fireDashboard.municipality')}</th>
							<th width='33%'>{t('fireDashboard.firePlanExecutionPercent')}</th>
						</tr>
					</thead>
					<tbody>
						{firePlanExecution.map((plan) => (
							<tr key={plan.forestryName}>
								<td className='category'>{plan.forestryName}</td>
								<td className='number' title={`${plan.peopleCount}/${plan.planPeopleCount}`}>
									{plan.executionPercent}%
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</Card>
		);
	}
}
