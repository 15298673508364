import React from 'react';
import { observer } from 'mobx-react';
import { Sound } from './Sound';
import { Notification } from './Notification';
import store from 'client/store';
import './style.scss';

@observer
export class Notifications extends React.Component {
	render() {
		const { notifications } = store.ui;
		if (!notifications.length) return null;
		const isAlarm = !!notifications.find((i) => i.isAlarm);
		return (
			<div className='notifications'>
				{notifications.map((notification) => (
					<Notification {...notification} key={notification.id} />
				))}
				{isAlarm && <Sound />}
			</div>
		);
	}
}
