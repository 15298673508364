import React from 'react';
import ClockPlusIcon from 'img/icons/fires/dashboard/clock-plus.svg';
import { Card } from 'components';
import { inject, observer } from 'mobx-react';

@inject('store')
@observer
export default class EquipmentByFDTable extends React.Component {
	constructor(props) {
		super(props);
		this.store = props.store;
		this.store.fetchDataVehicleByFD();
	}

	render() {
		const { EquipmentByFD } = this.store;

		return (
			<Card icon={<ClockPlusIcon />} title='Техника' className='equipment-by-fd'>
				{!EquipmentByFD.length ? (
					'Нет данных'
				) : (
					<table className='dashboard-table'>
						<thead>
							<tr>
								<th className='head'>Гос. номер</th>
								<th className='head' width='33%'>
									Тип
								</th>
								<th className='head' width='33%'>
									Статус
								</th>
							</tr>
						</thead>
						<tbody>
							{EquipmentByFD.map((record, index) => {
								return (
									<>
										<tr>
											<td colSpan='3' className='head'>
												{record.name}
											</td>
										</tr>
										{record.items.map((equipment) => {
											return (
												<tr>
													<td className='value'>{equipment.number}</td>
													<td className='value'>{equipment.type}</td>
													<td className='value'>{equipment.status}</td>
												</tr>
											);
										})}
									</>
								);
							})}
						</tbody>
					</table>
				)}
			</Card>
		);
	}
}
