import { observable } from 'mobx';
import { endOfMonth, startOfMonth } from 'date-fns';
import t from 'i18n';

export const REPORT_TYPES = [
	{ label: t('report.summary'), value: 'ExtinguishedFiresSummary' },
	{ label: t('report.detailed'), value: 'ExtinguishedFiresDetailed' },
];

export default class FireReportStore {
	@observable reportType = REPORT_TYPES[0];
	@observable year = new Date().getFullYear();
	@observable month = new Date().getMonth() + 1;

	get storeParams() {
		const date = new Date(this.year, this.month - 1, 1);
		const reportCode = this.reportType.value || this.reportType;
		const startDate = startOfMonth(date);
		const endDate = endOfMonth(date);

		return {
			reportCode: reportCode,
			reportParams: { startDate, endDate, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone },
			isHTML: true,
		};
	}

	onMonthChange = (m) => (this.month = m);
	onYearChange = (y) => (this.year = y);
	onReportTypeChange = (type) => (this.reportType = type);
}
