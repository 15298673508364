import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

import { Popconfirm } from '@smartplatform/ui';
import t from 'i18n';
import './style.scss';

const DeleteButton = (props) => {
	const { children, confirmMessage = t('delete'), onConfirm, portalClassName } = props;

	return (
		<Popconfirm className='delete-btn' content={confirmMessage} onConfirm={onConfirm} portalClassName={portalClassName}>
			<FontAwesomeIcon icon={faTrash} /> {children}
		</Popconfirm>
	);
};

DeleteButton.propTypes = {
	onConfirm: PropTypes.func.isRequired,
	confirmMessage: PropTypes.any,
	portalClassName: PropTypes.string,
};

export default DeleteButton;
