import React from 'react';
import { inject, observer } from 'mobx-react';
import { Column, Loader, Table } from '@smartplatform/ui';
import t from 'i18n';
import { formatDate } from 'client/tools';
import { Card } from 'components';
import ShieldTickIcon from 'img/icons/fires/card/shield-tick.svg';

@inject('card')
@observer
export default class List extends React.Component {
	constructor(props) {
		super(props);
		this.records = this.props.card.airJobs;
	}

	renderDepartureDate = (raid) => formatDate(raid.departureDate, 'dd.MM.yyyy HH:mm') || '-';
	renderAircraftType = (raid) => (raid.aircraft && raid.aircraft.type && raid.aircraft.type.name) || '-';
	renderAircraftNumber = (raid) => (raid.aircraft && raid.aircraft.number) || '-';

	render() {
		if (this.isLoading)
			return (
				<div className='loader'>
					<Loader />
				</div>
			);
		if (this.error) return <div className='error'>{this.error}</div>;

		return (
			<Card className='raids' title={t('airRaid.plural')} icon={<ShieldTickIcon />}>
				{this.records.length ? (
					<Table rows={this.records} clickable={false}>
						<Column relation='raid' computed={this.renderDepartureDate} label={t('airRaid.departureDate')} className='text-center' />
						<Column relation='raid' computed={this.renderAircraftType} label={t('aircraft.title')} className='text-center' />
						<Column relation='raid' computed={this.renderAircraftNumber} label={t('aircraft.number')} className='text-center' />
						<Column property='duration' label={t('airJob.duration')} className='text-center' />
					</Table>
				) : (
					<span>{t('fire.noAirRaids')}</span>
				)}
			</Card>
		);
	}
}
