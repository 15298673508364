import alarmSound from 'client/sounds/alarm.mp3';
import React, { useEffect } from 'react';

export const Sound = React.memo((sound) => {
	useEffect(() => {
		const sound = new Audio(sound || alarmSound);
		sound.play();
		return () => sound.pause();
	}, []);
	return null;
});
