import { observable } from "mobx";
import t from 'i18n';

export const REPORT_TYPES = [
	{ label: t('report.NumberOfFiresByDepartments'), value: 'NumberOfFiresByDepartments' },
	{ label: t('report.TotalAreaByDepartments'), value: 'TotalAreaByDepartments' },
	{ label: t('report.ForestAndNonForestAreaByDepartments'), value: 'ForestAndNonForestAreaByDepartments' },
	{ label: t('report.KpoByDepartments'), value: 'KpoByDepartments' },
	{ label: t('report.KppByDepartments'), value: 'KppByDepartments' },
];

export default class DistributionOfIndicatorsByDepartmentsStore {
	@observable startDate = null;
	@observable endDate = null;
	@observable report = REPORT_TYPES[0].value;

	onStartDateChange = (startDate) => this.startDate = startDate;

	onEndDateChange = (endDate) => this.endDate = endDate;

	onReportChange = (report) => this.report = report;

	get storeParams() {
		const { startDate, endDate, report } = this;
		return {
			reportCode: report,
			reportParams: { startDate, endDate, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone },
		};
	}
}
