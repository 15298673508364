import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import { Tabs, Tab } from '@smartplatform/ui';
import Personnel from './tabs/Personnel';
import Vehicles from './tabs/Vehicles';
import t from 'i18n';

@observer
export default class SentryPersonnelAndVehicles extends React.Component {
	
	@observable sentry = null;
	@observable tab = 'personnel';
	
	setTab = (tab) => this.tab = tab;

	render() {
		if (!this.props.shift) return null;
		
		return <div>
			<Tabs type="manual">
				<Tab title={t('fireDep.personnel')} initial onClick={() => this.setTab('personnel')} />
				<Tab title={t('fireDep.vehicles')} onClick={() => this.setTab('vehicles')} />
			</Tabs>
			{this.tab === 'vehicles' ?
				<Vehicles record={this.props.shift} />
				:
				<Personnel record={this.props.shift} />
			}
		</div>;
	}
}
