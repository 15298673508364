import React from 'react';
import { CancelButton as CancelButton_ } from 'components';
import { ControlsContext } from './Controls';
import t from 'i18n';

export const CancelButton = (props) => {
	const context = { ...React.useContext(ControlsContext), ...props };
	const { form, cancelTitle = t('back'), onCancel, noCancel } = context;

	if (noCancel) return null;

	const onBack = async () => {
		form?.back();
		onCancel?.();
	};

	const buttonProps = { onClick: onBack };

	return <CancelButton_ {...buttonProps} text={cancelTitle} />;
};
