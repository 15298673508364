import React from 'react';
import { inject, observer } from 'mobx-react';
import t from 'i18n';
import { Button } from '@smartplatform/ui';
import './style.scss';
import { Controls } from 'components';
import { formatDate } from '../../../../../../tools';

@inject('fireStore')
@observer
export default class Actionbar extends React.Component {
	constructor(props) {
		super(props);
		this.techFireId = this.props.fireStore.id;
	}

	handleSave = async () => {
		const { onSave, beforeSave } = this.props.fireStore;
		await beforeSave();
		await onSave();
	};
	render() {
		const { fire, goBack } = this.props.fireStore;
		return (
			<div className='techfire-actionbar'>
				<div className='back-button'>
					<Button variant='primary' onClick={goBack}>
						{t('backToList')}
					</Button>
				</div>
				<div className='title-save-cancel-button'>
					<div className='techfireid-createdAt'>
						<h1>{fire.id ? t('techFire.title') + ` #${fire.id}` : t('techFire.create')}</h1>
						<div>{fire.id && t('createdAt') + `: ${formatDate(fire.createdAt, 'dd.MM.yyyy HH:mm')}`}</div>
					</div>
					<Controls>
						<Controls.SaveButton onSave={this.handleSave} saveTitle={t('saveChanges')} />
						{this.techFireId && <Controls.CancelButton onCancel={goBack} cancelTitle={t('cancel')} />}
					</Controls>
				</div>
			</div>
		);
	}
}
