import React from 'react';
import { inject, observer } from 'mobx-react';
import { NumberInput, RecordSelect, Row } from '@smartplatform/ui';
import t from 'i18n';
import LineChartDownIcon from 'img/icons/fires/card/line-chart-down.svg';
import { Card, LabelField } from 'components';
import store from 'client/store';

@inject('card')
@observer
export class FlameProperties extends React.Component {
	render() {
		const { card, disabled } = this.props;
		const { fire, onChange } = card;
		const selectProps = { noTotal: true, maxItems: 20, itemsPerPage: 1000, filter: { fields: ['id', 'name'] }, property: 'name', disabled };
		const { model } = store;
		return (
			<Card title={t('fire.combustionCharacteristic')} icon={<LineChartDownIcon />}>
				<Row className='card-row'>
					<LabelField label={t('fire.combustionType')}>
						<RecordSelect {...selectProps} model={model.CombustionType} value={fire.combustionType} onChange={onChange('combustionType')} />
					</LabelField>
					<LabelField label={t('fire.combustionIntensity')}>
						<RecordSelect {...selectProps} model={model.CombustionIntensity} value={fire.combustionIntensity} onChange={onChange('combustionIntensity')} />
					</LabelField>
				</Row>
				<Row className='card-row'>
					<Row className='card-row'>
						<LabelField label={t('fire.combustionPattern')}>
							<RecordSelect {...selectProps} model={model.CombustionPattern} value={fire.combustionPattern} onChange={onChange('combustionPattern')} />
						</LabelField>
						<LabelField label={t('fire.edge')}>
							<NumberInput value={fire.edge} onChange={onChange('edge')} />
						</LabelField>
					</Row>
				</Row>
			</Card>
		);
	}
}
