import { observer, inject } from 'mobx-react';
import React from 'react';
import t from 'i18n';
import { Column, Pager, RecordSelect, Select, Table } from '@smartplatform/ui';
import { Toolbar } from 'components';
import store from 'client/store';
import PersonnelListStore from './store';
import { fio } from 'client/tools';
import { fireDepVehicleItem } from 'techfires/helpers';
import './personnelList.scss';

const SELECT_WIDTH = 220;
@inject('fireDepStore')
@observer
export class PersonnelList extends React.Component {
	constructor(props) {
		super(props);
		this.store = new PersonnelListStore(props.match.path, this.props.fireDepStore.fireDep.id);
	}

	componentDidMount() {
		this.store.setPerPage();
	}

	renderSentryName = (record) => {
		if (record.name) return record.name;
		if (record.globalSentry) return record.globalSentry.name;
		return '-';
	};

	render() {
		const { error, tableProps, create, onChange, totalCount, page, perPage, onSearch, search } = this.store;
		if (error) return <div className='error'>{error}</div>;
		const { onChangeFilters, rank, position, status, person, fireDepSentry, statuses, onStatusSelect } = this.store;

		const fireDepId = this.props.fireDepStore.fireDep.id;

		return (
			<div className='personnel-list'>
				<div className='filters'>
					<Toolbar className='d-flex'>
						<Toolbar.AddButton onClick={create} />
						<Toolbar.SearchIconInput value={search} onChange={onSearch} />
					</Toolbar>
					<RecordSelect
						model={store.model.FireDepRank}
						value={rank}
						onChange={onChangeFilters('rank')}
						property='name'
						width={SELECT_WIDTH}
						placeholder={t('fireDepRank.chooseRank')}
					/>
					<RecordSelect
						model={store.model.FireDepPosition}
						value={position}
						onChange={onChangeFilters('position')}
						property='name'
						width={SELECT_WIDTH}
						placeholder={t('fireDepPosition.choosePosition')}
					/>
					<Select
						items={statuses}
						value={status}
						computed={(r) => r.name}
						onChange={onChangeFilters('status')}
						width={SELECT_WIDTH}
						placeholder={t('fireDepPersonnelStatus.chooseStatus')}
					/>
					<RecordSelect
						model={store.model.ViewFireDepPersonnel}
						value={person}
						onChange={onChangeFilters('person')}
						width={SELECT_WIDTH}
						computed={({ lastName, firstName, middleName }) => <>{`${lastName || ''} ${firstName || ''} ${middleName || ''}`}</>}
						filter={{ where: { fireDepId, fireDepShiftId: null } }}
						placeholder={t('fireDepPersonnel.choseName')}
					/>
					<RecordSelect
						model={store.model.FireDepSentry}
						value={fireDepSentry}
						width={SELECT_WIDTH}
						filter={{
							where: { fireDepId },
							include: [{ relation: 'globalSentry', scope: { fields: ['id', 'name'] } }, 'personnel'],
						}}
						computed={this.renderSentryName}
						onChange={onChangeFilters('fireDepSentry')}
						placeholder={t('fireDepSentry.select')}
					/>
				</div>
				<Pager current={page} onChange={onChange('page')} totalCount={totalCount || 0} itemsPerPage={perPage} />
				<div className='mt-2' id='table'>
					<Table {...tableProps}>
						<Column property='lastName' computed={fio} label={t('fio')} />
						<Column property='statusName' label={t('person.status')}>
							{({ record }) => (
								<Select
									items={statuses}
									value={record.status}
									computed={(r) => r.name}
									onChange={(status) => onStatusSelect(status, record)}
									disabled={record.statusId === this.props.fireDepStore.personnelFFStatus?.id}
								/>
							)}
						</Column>
						<Column property='positionName' label={t('fireDepPosition.title')} />
						<Column property='rankName' label={t('fireDepRank.title')} />
						<Column relation='gasMask' property='name' label={t('gasMask.title')} />
						<Column relation='vehicle' computed={fireDepVehicleItem} label={t('fireDepVehicle.title')} />
						<Column property='dayShift' label={t('fireDepPersonnel.dayShift')} width={100} />
						<Column property='responsibleForCalling' label={t('fireDepPersonnel.responsibleForCalling')} width={100} />
					</Table>
				</div>
			</div>
		);
	}
}
