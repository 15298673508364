import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import store from 'client/store';
import t from 'i18n';
import { Loader } from '@smartplatform/ui';
import './style.scss';
import { lowerFirstLetter } from 'client/tools';
import { TextField } from 'fires/components';

const MODEL_NAME = 'FireDep';

@observer
export default class CountyPopup extends React.Component {
	@observable fetchedData = null;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.fetchedData = await store.model[MODEL_NAME].findById(this.props.record.id, { include: ['city', 'county'] });
	};

	gotoFireDep = (e) => {
		e.preventDefault();
		store.route.push({ path: `/techfires/${lowerFirstLetter(MODEL_NAME)}/${this.props.record.id}` });
	};

	render() {
		const { record, noPopupLink } = this.props;

		return (
			<div className='firedep-popup'>
				<div>
					{record.name}{' '}
					{!noPopupLink && (
						<a style={{ cursor: 'pointer' }} onClick={this.go}>
							#{record.id}
						</a>
					)}
				</div>
				<div className='firedep-data'>
					{!this.fetchedData && <Loader size={18} />}
					{this.fetchedData && (
						<div>
							<TextField label={t('city.title')} value={this.fetchedData.city?.name || '-'} />
							<TextField label={t('county.title')} value={this.fetchedData.county?.name || '-'} />
							<TextField label={t('fireDep.address')} value={this.fetchedData?.address || '-'} />
							<TextField label={t('fireDep.phone')} value={this.fetchedData?.phone || '-'} />
						</div>
					)}
				</div>
			</div>
		);
	}
}
