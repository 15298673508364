import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { geoJSON, drawGeoMarker } from '@smartplatform/map/client';
import { Fill, Icon, Stroke, Style } from 'ol/style';
import store from 'client/store';
import t from 'i18n';
import React from 'react';
import shipIconUrl from 'img/icons/shared/shipMarker.svg?url';
import { observable } from 'mobx';
import { ShipPopup } from './ShipPopup';
import { ShipTrackLayer } from './ShipTrackLayer';
import { Feature } from 'ol';
import { getCenter } from 'ol/extent';
export class ShipsLayer {
	@observable shipToTrack = null;
	@observable type = null;
	@observable isFavourite = false;

	constructor(mapStore, settings = {}) {
		this.mapStore = mapStore;
		this.settings = settings;
		this.source = new VectorSource();
		this.layer = new VectorLayer({
			format: geoJSON,
			source: this.source,
		});
		this.layer.setZIndex(10);
		this.mapStore.addLayer(this.layer);
	}

	init = async ({ shipId }) => {
		this.trackLayer = new ShipTrackLayer(this.mapStore, { onClose: this.hideTrack });
		if (Number.isInteger(shipId)) {
			try {
				const shipToTrack = await store.model.ViewShip.findById(shipId);
				shipToTrack && this.showTrack(shipToTrack);
			} catch (e) {
				console.error(e);
			}
		}
		this.setVisible();
	};

	onChange = (field) => (value) => {
		this[field] = value;
		this.load();
	};

	load = async () => {
		this.isLoading = true;
		const where = { geo: { neq: null }, trackCount: { gte: 1 } };
		if (this.shipToTrack) {
			where.id = { neq: this.shipToTrack.id };
		}
		if (this.type) {
			where.typeId = this.type.id;
		}

		if (this.isFavourite) {
			where.isFavourite = true;
		}

		const ships = await store.model.ViewShip.find({ where });
		const features = [];

		for (const ship of ships) {
			const { geo, course, name, nameLat } = ship;

			const shipFeature = new Feature({
				isMarker: true,
				geometry: geoJSON.readGeometry(geo),
				title: t('ship.title') + ` ${name || nameLat}`,
				record: ship,
				render: this.renderPopup,
			});

			shipFeature.setStyle(
				new Style({
					image: new Icon({
						src: shipIconUrl,
						rotateWithView: true,
						scale: 0.8,
						rotation: (Math.PI / 180) * Number(course),
						anchor: [5, 12.5],
						anchorXUnits: 'pixels',
						anchorYUnits: 'pixels',
					}),
				})
			);

			features.push(shipFeature);
		}

		this.source.clear();
		this.source.addFeatures(features);
		this.isLoading = false;
	};

	setVisible = () => {
		if (!this.isInitialized && this.settings.show) {
			this.load();
			this.isInitialized = true;
		}
		this.layer.setVisible(this.settings.show);
	};

	renderPopup = (data) => <ShipPopup record={data} showTrack={this.showTrack} />;

	showTrack = (ship) => {
		this.mapStore.updatePopup(null);
		this.shipToTrack = ship;
		this.source.removeFeature(ship.geo);
		if (!this.trackLayer.isInitialized) {
			this.trackLayer.initLayer();
		}
		this.trackLayer.setVisible(true);
		this.trackLayer.updateShip(ship);
		this.trackLayer.updateLayer();

		this.mapStore.fitGeo(ship.geo, this.mapStore.map.getView().getZoom());
	};

	hideTrack = () => {
		this.shipToTrack = null;
		this.load();
		this.trackLayer.setVisible(false);
	};
}
