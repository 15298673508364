import React from 'react';
import { observer } from 'mobx-react';

import FireDep from './FireDep';
import { AutoAnimate } from 'components';

@observer
export default class Vehicles extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { fireDeps } = this.props.store;

		return (
			<AutoAnimate>
				{Object.keys(fireDeps).map((fireDepId) => (
					<FireDep key={fireDepId} store={this.props.store} fireDepObj={fireDeps[fireDepId]} />
				))}
			</AutoAnimate>
		);
	}
}
