import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import { Field, Form, Row, Checkbox } from '@smartplatform/ui';
import { fio } from 'client/tools';
import { PERSONNEL_SCOPE } from './constants';
import { fireDepVehicleItem } from 'techfires/helpers';
import store from 'client/store';
import t from 'i18n';
import './style.scss';

@observer
export default class CallsPersonnel extends React.Component {
	@observable person = null;
	@observable responsibleForCalling = null;
	@observable callEditAllowed = null;

	constructor(props) {
		super(props);
		this.fireDepStore = props.fireDepStore;
		this.init();
	}

	init = async () => {
		const { id } = this.props.match.params;
		if (id) {
			this.person = await store.model.FireDepPersonnel.findById(id, {
				...PERSONNEL_SCOPE
			});
		} else {
			this.person = new store.model.FireDepPersonnel({ fireDepId });
		}
		this.responsibleForCalling = this.person.responsibleForCalling;
		this.callEditAllowed = store.model.roles.filter(role => ['admin', 'tfires_main'].includes(role.name)).length > 0;
	};

	onChangeResponsibleForCalling = (value) => {
		this.responsibleForCalling = value;
	}

	render() {
		if (!this.person) return '...';

		return (
			<div className='personnel'>
				<Form record={this.person} returnTo={`/techfires/calls`} noDelete>
					<div className='personnel-basic'>
						<h1>{t('fireDepPersonnel.basic')}</h1>
						<Row>
							<Field property='lastName' label={t('lastName')} />
							<Field property='firstName' label={t('firstName')} />
							<Field property='middleName' label={t('middleName')} />
						</Row>
						<Row>
							<Field relation='section' property='name' label={t('fireDepSection.title')} />
							<Field relation='position' property='name' label={t('fireDepPosition.title')} />
							<Field relation='rank' property='name' label={t('fireDepRank.title')} />
						</Row>
						<Row>
							<Field relation='status' property='name' label={t('personnelStatus.title')} />
							<Field property='dayShift' label={t('fireDepPersonnel.dayShift')} />
						</Row>
						<Row>
							{(this.responsibleForCalling && (
								<Field relation='techFireRank' property='name' label={t('techFire.rank')} disabled={!this.callEditAllowed} />
							)) || <div />}
							<Field property='responsibleForCalling' label={t('fireDepPersonnel.responsibleForCalling')} disabled={!this.callEditAllowed}>
								<Checkbox value={this.responsibleForCalling} onChange={this.onChangeResponsibleForCalling} disabled={!this.callEditAllowed} />
							</Field>
						</Row>
					</div>
					<h1>{t('fireDepVehicle.contacts')}</h1>
					<Row>
						<Field property='phone' label={t('phone')} />
						<Field property='address' label={t('address.title')} />
					</Row>
				</Form>
			</div>
		);
	}
}
