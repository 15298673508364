import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import store from 'client/store';
import t from 'i18n';
import { Loader } from '@smartplatform/ui';
import { TextField } from 'fires/components';
import { formatDate } from 'client/tools';
import './style.scss';

const MODEL_NAME = 'ViewPermafrostTemperatureTube';

@observer
export default class PermafrostTemperatureTubePopup extends React.Component {
	@observable fetchedData = null;
	@observable depths = null;
	@observable depthValues = [];
	@observable date = null;

	constructor(props) {
		super(props);
		this.init();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.record.id !== this.props.record.id) {
			this.init();
		}
	}

	init = async () => {
		this.fetchedData = await store.model[MODEL_NAME].findById(this.props.record.temperaturetubeid, { include: ['depths'] });
		this.depths = this.fetchedData.depths();

		const measurementsid = this.props.isDate ? this.props.record.measurementsid : this.fetchedData.measurementsId;
		const measurements = await store.model.PermafrostTemperatureTubeMeasurements.findById(measurementsid, { include: ['depths'] });
		this.depthValues = measurements.depths().map((x) => x.depthValue);
		this.date = measurements.date;
	};

	go = (e) => {
		e.preventDefault();
		store.route.push({ path: `/permafrost/temperature-tubes/${this.props.record.temperaturetubeid}` });
	};

	render() {
		const { record, noPopupLink } = this.props;

		return (
			<div className='temperature-tube-layer-popup'>
				<div>
					{!noPopupLink && (
						<h1>
							{t('permafrost.temperatureTube.title')}:{' '}
							<a style={{ cursor: 'pointer' }} onClick={this.go}>
								#{record.temperaturetubeid}
							</a>
						</h1>
					)}
				</div>
				<div>
					{!this.fetchedData && <Loader size={18} />}
					{this.fetchedData && (
						<div className='d-flex'>
							<div className='w-50'>
								<TextField label={t('permafrost.number')} value={this.fetchedData.number || '-'} />
								<TextField label={t('permafrost.objects.name')} value={this.fetchedData.addressName || '-'} />
							</div>
							<div className='w-50'>
								<TextField label={t('permafrost.temperatureTube.lastDate')} value={formatDate(this.date)} />
								{this.depths?.map((x, i) => (
									<div className='layer-popup-measurements'>
										<span>{x.depth} м</span>
										<span className='measurements-line'></span>
										<span>{this.depthValues[i] || '-'}</span>
									</div>
								))}
							</div>
						</div>
					)}
				</div>
			</div>
		);
	}
}
