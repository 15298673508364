export const MENU_ITEMS = [
	{
		title: 'Отчётность по лесным пожарам, детальная',
		type: 'submenu',
		items: [
			'ListOfForestFires', // ok
			'FiresOnCountyAndDate',
			'FireCard',
			'FiresOnDate',
			'EliminatedFiresFoundInTheTotalArea', // ok
			'InformationAboutForestFiresForDate', // ok
			'InformationAboutTheEliminationOfForestFire', // ok
			'FireAct',
			'ReportActiveForestFires', // ok
			'CumulativeFires',
			'InformationAboutActiveForestFires', // ok
			'InformationAboutActiveForestFiresForDate',

			// этих нету в пми и тз
			'ExtinguishedFires',
			'DetailedFireSituation',
			'FireRegistryOnDate',
		],
	},
	{
		title: 'Отчётность по лесным пожарам, сводная',
		type: 'submenu',
		items: [
			'InformationAboutForestFiresAccordingClassificationOfForestFundLands', // ok
			'InformationAboutForestFiresTakingIntoAccountPurposeForests', // ok
			'ForestryHarm',
			'PeoplesAndVehiclesByDate',
			'DetectionMethods',
			'CumulativeIndicators', // ok
			'CumulativeSummaryForestFires', // ok
			'ReportOnTheDetectionAndSuppressionForestFires', // ok
			'FireAreaCount',
			'ReportOnForestFiresInTheTerritory', // ok
			'InformationAboutIdentifyingPerpetrators', // ok
			'FireByCauseAndWorker',

			// этих нету в пми
			'FireByArea',
			'FireByMonitoringZone',
		],
	},
	{
		title: 'Отчётность по крупным лесным пожарам',
		type: 'submenu',
		items: [
			'BigForestFire',
			'InformationAboutLargeForestFires', // ok
			'InformationAboutLargeForestFiresForDate', // ok
		],
	},
	{
		title: 'Сведения о пожарной опасности в лесах и лесных пожарах',
		type: 'submenu',
		items: [
			'LO1Section1',
			'LO1Section2',
			'LO1Section3',
			'LO1Section4',
			'LO2',
			'LO3',
			'OperationalSituationInTerritory', // ok
		],
	},
	{
		title: 'Отчётность по налётам воздушных судов (ВС)',
		type: 'submenu',
		items: [
			'DepartmentWorkRecordCard', // ok
			'AirRaidExtinguishForestFires', // ok
			'FlightTimeControl', // ok
			'InformationOnTheOperationOfAircraftForMonitoring', // ok
		],
	},
	{
		title: 'Специальная отчётность',
		type: 'submenu',
		items: [
			'RegisterEliminatedForestFires', // ok
			'InformationAboutWorkOfForestFireFormations', // ok
			////////////// - Список пожаров
			'ExtinguishingVehiclesAndPeoples',
			'SummaryFirePlan',

			// этих нету по пми
			'ExtinguishingCosts',
			'DistributionFiresByLiquidationAreaAndExtinguishingTime', // ok
		],
	},
	{
		title: 'Отчётность по чрезвычайным ситуациям и угрозам',
		type: 'submenu',
		items: ['CityThreat'],
	},
	{
		title: 'Обращения граждан',
		type: 'submenu',
		items: [
			'RequestLog',
			'ReportOnWorkWithCitizens', //
		],
	},
	{
		title: 'Отчётность по метеоинформации',
		type: 'submenu',
		items: [
			'KpoMonthlyReport', //
			'FireDangerIndicators', // ok
			'FireDangerDiary', //
		],
	},
	{
		title: 'Инфографика',
		type: 'submenu',
		items: [
			'DistributionOfIndicatorsByDatesOfTheFireHazardPeriod', // ok
			'DistributionOfTheNumberOfFiresByTheirCauses', // ok
			'DistributionOfIndicatorsByDepartments', // ok
			'FireSituationAnalysis', // ok
		],
	},
	{
		title: 'Годовой отчёт',
		type: 'submenu',
		items: [
			'Avia1',
			'Avia2_1',
			'Avia2_2',
			'InformationAboutTheWorkOfGroundFireDepartments', //
			'Avia4',
			'AviaDepByCause',
			'InformationOnTheDistributionOfTheFireSeasonByClass', //
			'Avia7',
			'InformationAboutTheWorkParachuteAndAirborneFireService', //
			'JobDetailsAircraftForTheProtectionOfForests', //
			'ListOfFlightStaff', //
			'Avia15',
			'Avia15_1',
			'KeyIndicatorsForAviationProtectionOfForests', //
		],
	}
];
