import React from 'react';
import { inject, observer } from 'mobx-react';
import {DatePicker, Select} from "@smartplatform/ui";

import t from 'i18n';
import { REPORT_TYPES } from "../DistributionOfIndicatorsByDepartments/store";

@inject('report')
@observer
export class Filters extends React.Component {
	render() {
		const { report } = this.props;

		return (
			<div className='filters'>
				<div className='row'>
					<div className='col-3'>
						<div className='form-field'>
							<label>{t('date1')}</label>
							<DatePicker
								showTimeSelect={false}
								showClearButton={false}
								value={report.startDate}
								onChange={report.onStartDateChange}
							/>
						</div>
					</div>

					<div className='col-3'>
						<div className='form-field'>
							<label>{t('date2')}</label>
							<DatePicker
								showTimeSelect={false}
								showClearButton={false}
								value={report.endDate}
								onChange={report.onEndDateChange}
							/>
						</div>
					</div>

					<div className='col-3'>
						<div className='form-field'>
							<label>{t('report.type')}</label>
							<Select
								noTotal
								maxItems={10}
								itemsPerPage={1000}
								noSearch
								isRequired
								items={REPORT_TYPES}
								value={report.report}
								onChange={report.onReportChange}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
