import { observable } from 'mobx';
import store from 'client/store';

export default class SettingStore {
	@observable garrison = null;
	@observable noteSettings = {
		headNote: '',
		responsiblePerson: [{ position: '', fio: '' }],
		dutyShift: [],
	};
	@observable originalSettings = {};
	@observable updatedSettingsKeys = [];
	@observable isLoading = true;

	propsField = {
		headNote: {
			type: 'string',
			props: {},
		},
		dutyShift: {
			type: 'arrayObjects',
			props: {
				emptyItem: {
					position: '',
					fio: '',
				},
				oneObject: false,
			},
		},
		responsiblePerson: {
			type: 'arrayObjects',
			props: {
				emptyItem: {
					position: '',
					fio: '',
				},
				oneObject: true,
			},
		},
	};

	constructor() {
		this.fetchSetting();
	}

	setDefaultValue = () => {
		this.noteSettings = {
			headNote: '',
			responsiblePerson: [{ position: '', fio: '' }],
			dutyShift: [],
		};
	};

	fetchSetting = async () => {
		this.setDefaultValue();

		const settings = await store.model.Setting.find({
			where: { code: { inq: Object.keys(this.propsField).map((key) => this.generateCode(key)) } },
		});

		for (const setting of settings) {
			const key = setting.code.split('.').at(-1);
			this.setConfigByModel(key, setting);
		}
		this.dropOriginalSettings();
		this.isLoading = false;
	};

	dropOriginalSettings = () => {
		this.originalSettings = JSON.parse(JSON.stringify(this.noteSettings));
		this.updatedSettingsKeys = [];
	};

	setConfigByModel = (key, setting) => {
		switch (this.propsField?.[key].type) {
			case 'string':
				this.noteSettings[key] = String(setting.value);
				break;
			case 'arrayObjects':
				this.noteSettings[key] = JSON.parse(setting.value);
				break;
			default:
				this.noteSettings[key] = setting.value ?? '';
				break;
		}
	};

	generateCode = (property) => `noteSettings.${this.garrison?.code}.${property}`;

	setUpdatedSettingsKeys = () => {
		this.updatedSettingsKeys = Object.keys(this.originalSettings).filter((key) => {
			if (typeof this.originalSettings[key] === 'object') {
				return this.originalSettings[key].filter(
					(arrayItem, index) => Object.keys(arrayItem).filter((arrayItemKey) => arrayItem[arrayItemKey] !== this.noteSettings[key][index][arrayItemKey]).length
				).length;
			} else {
				return this.noteSettings[key] !== this.originalSettings[key];
			}
		});
	};

	onChange = (props) => (value) => {
		this.noteSettings[props] = value;
		this.setUpdatedSettingsKeys();
	};

	onGarrisonChange = (value) => {
		this.garrison = value;
		this.fetchSetting();
	};

	onSave = async () => {
		try {
			for (const key of this.updatedSettingsKeys) {
				const settingItem =
					(
						await store.model.Setting.find({
							where: { code: this.generateCode(key) },
						})
					)?.[0] || new store.model.Setting({ code: this.generateCode(key) });
				if (this.propsField[key].type === 'arrayObjects') {
					settingItem.value = this.noteSettings[key].length ? JSON.stringify(this.noteSettings[key]) : null;
				} else {
					settingItem.value = this.noteSettings[key] === '' ? null : this.noteSettings[key];
				}
				await settingItem.save();
			}
			this.dropOriginalSettings();
		} catch (e) {
			console.log(e);
		}
	};

	addArrayItem = (prop) => {
		this.noteSettings[prop].push(this.propsField[prop].props.emptyItem);
		this.originalSettings[prop].push(this.propsField[prop].props.emptyItem);
		//this.setUpdatedSettingsKeys();
		if (!this.updatedSettingsKeys.includes(prop)) this.updatedSettingsKeys.push(prop);
	};

	onArrayItemChange = (props) => {
		const { prop, key, value, index } = props;
		this.noteSettings[prop][index][key] = value;
		this.setUpdatedSettingsKeys();
	};

	deleteArrayItem = (prop, index) => {
		this.noteSettings[prop].splice(index, 1);
		this.originalSettings[prop].splice(index, 1);
		//this.setUpdatedSettingsKeys();
		if (!this.updatedSettingsKeys.includes(prop)) this.updatedSettingsKeys.push(prop);
	};
}
