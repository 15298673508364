import React from 'react';
import { observer } from 'mobx-react';
import { Button, Checkbox, Column, DatePicker, Input, Row, Table } from '@smartplatform/ui';
import store from 'client/store';
import { observable } from 'mobx';
import t from 'i18n';
import AddIcon from 'img/add-btn.svg';
import EditIcon from 'img/icons/shared/edit.svg';
import DeleteIcon from 'img/icons/shared/delete.svg';
import { MARKS_INCLUDE } from '../../constants';
import { fio, formatDate } from 'client/tools';
import { Attachments, LabelField } from 'components';

@observer
export default class Edit extends React.Component {
	@observable record = null;
	@observable isLoading = true;
	@observable measurements = [];
	@observable editRows = [];

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.id = parseInt(this.props.match.params.id);
		if (this.id) {
			this.record = await store.model.PermafrostLevelingMark.findById(this.id, {
				include: [...MARKS_INCLUDE.include, { relation: 'measurements', scope: { include: ['attachments'] } }, 'owner'],
			});
			this.measurements = this.record.measurements();
		}

		this.isLoading = false;
	};

	onCreate = async () => {
		const measurement = new store.model.PermafrostLevelingMarkMeasurements({ levelingMarkId: this.record.id });
		await measurement.save();
		this.measurements.push(measurement);
	};

	onEdit = async ({ record, row }) => {
		if (!this.editRows.includes(row)) {
			this.editRows.push(row);
		} else {
			await record.save();
			this.editRows.splice(this.editRows.indexOf(row), 1);
		}
	};

	onDelete = async ({ record, row }) => {
		await record.delete();
		this.measurements.splice(row, 1);
	};

	onChange = (rec, field) => (value) => {
		this.measurements[rec.row][field] = value;
	};

	onChangeWithSave = (rec, field) => async (value) => {
		this.measurements[rec.row][field] = value;
		await this.measurements[rec.row].save();
	};

	back = () => {
		const { path } = this.props;
		store.route.push({ path });
	};

	render() {
		if (this.isLoading) return null;
		const { owner, object, number, status } = this.record;

		const disableForUser = store.model.roles.find((role) => ['user_permafrost'].includes(role.name))

		return (
			<div className='measurements-edit'>
				<div className='tubes-header'>
					<h2>{t('permafrost.edit')}</h2>
					<Button variant='primary' onClick={this.back}>
						{t('backToList')}
					</Button>
				</div>
				<div className='tubes-info'>
					<LabelField label={t('permafrost.buildings')}>{object?.address?.name || 'Без адреса'}</LabelField>
					<Row>
						<LabelField label={t('permafrost.marksFull')}>{number || '-'}</LabelField>
						<LabelField label={t('status')}>{status?.name || '-'}</LabelField>
						<LabelField label={t('user.title')}>{fio(owner)}</LabelField>
						<div></div>
					</Row>
				</div>

				<Table rows={this.measurements}>
					<Column label={t('permafrost.approved')} property='isApproved'>
						{(rec) => (this.editRows.includes(rec.row) ? null : <Checkbox value={rec.record.isApproved} onChange={this.onChangeWithSave(rec, 'isApproved')} disabled={disableForUser} />)}
					</Column>
					<Column label={t('date')} property='date'>
						{(rec) =>
							this.editRows.includes(rec.row) ? <DatePicker value={rec.record.date} onChange={this.onChange(rec, 'date')} /> : formatDate(rec.record.date)
						}
					</Column>
					<Column label={t('permafrost.mark')} property='mark'>
						{(rec) => (this.editRows.includes(rec.row) ? <Input value={rec.record.mark} onChange={this.onChange(rec, 'mark')} /> : rec.record.mark)}
					</Column>
					<Column label={t('file.title')} className='measurements-attachments'>
						{(rec) =>
							this.editRows.includes(rec.row) ? (
								<Attachments multiple={false} record={rec.record} canUpload={true} />
							) : (
								<Attachments multiple={false} record={rec.record} canUpload={false} noDelete={true} />
							)
						}
					</Column>
					<Column width={40}>{(rec) => (rec.record.isApproved ? null : <EditIcon className='cursor-pointer' onClick={() => this.onEdit(rec)} />)}</Column>
					<Column width={40}>{(rec) => <DeleteIcon className='cursor-pointer' onClick={() => this.onDelete(rec)} />}</Column>
				</Table>
				<div className='create-menu'>
					<AddIcon className='cursor-pointer' onClick={this.onCreate} />
				</div>
			</div>
		);
	}
}
