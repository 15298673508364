import React from 'react';
import { inject, observer } from 'mobx-react';
import { Form } from 'components';
import './style.scss';
import {
	AboutObject,
	InfoAboutFire,
	WayBill,
	InfoAboutPerson,
	Distance,
	FirstUnit,
	SituationToFirstDivision,
	TimeFirstSupply,
	Effects,
	Casualties,
	ResultInquiry,
	WorkFireDep,
	Trunks,
	Chiefs,
	InteractionService,
	Rescued,
	Evacuated,
	AddInfo,
	FireReport,
} from '../info-components';
import { Loader } from '@smartplatform/ui';

@inject('fireStore')
@observer
export default class Main extends React.Component {
	constructor(props) {
		super(props);
		this.store = this.props.fireStore;
	}
	render() {
		const { fire, isLoading } = this.props.fireStore;
		if (isLoading) return <Loader />;
		const { path } = this.props;
		return (
			<div className='techfire-card-main'>
				<Form record={fire} noSave noCancel noDelete>
					<div className='card-main-info'>
						<div id='aboutObject'>
							<AboutObject />
						</div>
						<div id='wayBill'>
							<WayBill path={path} />
						</div>
						<div id='infoAboutFire'>
							<InfoAboutFire />
						</div>
						<div id='infoAboutPerson'>
							<InfoAboutPerson />
						</div>
						<div id='distance'>
							<Distance />
						</div>
						<div id='firstUnit'>
							<FirstUnit />
						</div>
						<div id='situationToFirstDivision'>
							<SituationToFirstDivision />
						</div>
						<div id='timeFirstSupplyLocalizationTermination'>
							<TimeFirstSupply />
						</div>
						<div id='effects'>
							<Effects />
						</div>
						<div id='infoAffected'>
							<Casualties />
						</div>
						<div id='resultInquiry'>
							<ResultInquiry />
						</div>
						<div id='workFireDep'>
							<WorkFireDep />
						</div>
						<div id='trunks'>
							<Trunks />
						</div>
						<div id='chief'>
							<Chiefs />
						</div>
						<div id='organizations'>
							<InteractionService />
						</div>
						<div id='rescued'>
							<Rescued />
						</div>
						<div id='evacuated'>
							<Evacuated />
						</div>
						<div id='addInfo'>
							<AddInfo />
						</div>
						<div id='firereport'>
							<FireReport />
						</div>
					</div>
				</Form>
			</div>
		);
	}
}
