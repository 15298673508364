import t from 'i18n';

const renderName = (r) => r.name;

export const DICTIONARIES_MODELS = [
	{
		title: t('dictionaries.group.norm'),
		items: [
			{ modelName: 'ForestUseType' },
			{ modelName: 'CombustionType' },
			{ modelName: 'AirJobType' },
			{ modelName: 'StaffPosition' },
			{ modelName: 'MonitoringZone' },
			{ modelName: 'ForestCategory' },
			{ modelName: 'LandCategory' },
			{ modelName: 'Breed' },
			{ modelName: 'NotLandingReason' },
			{ modelName: 'NotInspectionReason' },
			{ modelName: 'FireStatus' },
			{ modelName: 'FireCause' },
			{ modelName: 'Cover' },
			{ modelName: 'DeterminingMethod' },
		],
	},
	{
		title: t('dictionaries.group.weather'),
		items: [
			{
				modelName: 'WeatherScaleValue',
			},
			{
				modelName: 'WeatherStation',
				editProperties: ['name', 'code', 'latitude', 'longitude', 'activity', 'geo'],
				editRelations: [{ relation: 'value', property: 'name' }],
			},
		],
	},
	{
		title: t('dictionaries.group.region'),
		items: [
			{ modelName: 'FightFireForceKind' },
			{
				modelName: 'AviaDep',
				listProperties: ['code', 'name', 'shortName', 'area', 'active', 'codeForYasen'],
				listRelations: [{ relation: 'location', property: 'name', properties: ['name'] }],
				editProperties: ['code', 'name', 'shortName', 'area', 'active', 'codeForYasen'],
				editRelations: [{ relation: 'location', computed: renderName, properties: ['name'] }],
			},
			{
				modelName: 'Forestry',
				listRelations: [{ relation: 'forestOwnership', property: 'name', properties: ['name'] }],
				editRelations: [{ relation: 'forestOwnership', computed: renderName, properties: ['name'] }],
			},
			{ modelName: 'Tenant' },
			{
				modelName: 'CrewType',
				listProperties: ['name', 'shortName'],
				editProperties: ['name', 'shortName'],
				listRelations: [{ relation: 'forceKind', property: 'shortName' }],
				editRelations: [{ relation: 'forceKind', property: 'shortName' }],
			},
			{
				modelName: 'ExtinguishingAgentsType',
				listProperties: ['name', 'shortName'],
				editProperties: ['name', 'shortName'],
				listRelations: [{ relation: 'forceKind', property: 'shortName' }],
				editRelations: [{ relation: 'forceKind', property: 'shortName' }],
			},
			{
				modelName: 'City',
				listProperties: ['okato', 'name', 'population', 'area', 'fireDanger', 'codeForYasen'],
				listRelations: [{ relation: 'county', property: 'name', properties: ['name'] }],
				editProperties: ['okato', 'name', 'population', 'area', 'fireDanger', 'geo', 'codeForYasen'],
				editRelations: [{ relation: 'county', computed: renderName, properties: ['name'] }],
			},
			{ modelName: 'DiscoveryMessageType' },
			{ modelName: 'FireFightingFormation' },
		],
	},

	{
		title: t('dictionaries.group.other'),
		items: [
			{ modelName: 'Camera', listProperties: ['url', 'name'], editProperties: ['url', 'name', 'geo'] },
			{ modelName: 'ForestryQuarter' },
			{ modelName: 'ForestryPrecinct' },
			{ modelName: 'Unit' },

			{ modelName: 'NotifyRecipient' },
			{ modelName: 'NotifyRecipientGroup' },
			{ modelName: 'FireReport' },
			{ modelName: 'Airfield' },
			{ modelName: 'County' },
			{ modelName: 'TechnoZone' },
			{ modelName: 'PaymentGroup' },
			{ modelName: 'EmergencySituationType' },
			{ modelName: 'ForestOwnership' },
			{ modelName: 'CombustionIntensity' },
			{ modelName: 'CombustionPattern' },
			{ modelName: 'VehicleType' },
			{ modelName: 'IsdmFire' },
			{ modelName: 'ExtinguishingAgentsGroup' },
			{ modelName: 'ActivityType' },
		],
	},
	{
		title: t('dictionaries.group.air'),
		items: [
			{ modelName: 'AircraftType' },
			{
				modelName: 'Aircraft',
				listRelations: [
					{ relation: 'type', computed: renderName, properties: ['name'] },
					{ relation: 'contract', computed: (r) => r.number, properties: ['active'] },
				],
				editProperties: ['number'],
				editRelations: [
					{ relation: 'type', computed: renderName, properties: ['name'] },
					{ relation: 'contract', computed: (r) => r.number, properties: ['active'] },
				],
			},
			{ modelName: 'AircraftKind' },
			{ modelName: 'Staff' },
			{
				modelName: 'Organization',
				listProperties: ['name', 'contractor'],
				listRelations: [
					{ relation: 'type', property: 'name' },
					{ relation: 'activityType', property: 'name' },
				],
				editProperties: ['name', 'contractor'],
				editRelations: [
					{ relation: 'type', property: 'name' },
					{ relation: 'activityType', property: 'name' },
				],
			},
			{
				modelName: 'Contract',
				listProperties: ['number', 'date', 'term', 'active', 'description'],
				listRelations: [
					{ relation: 'organization', property: 'name' },
					{ relation: 'aircraftType', property: 'name' },
					{ relation: 'aircraft', property: 'number' },
				],
				editProperties: ['number', 'date', 'term', 'active', 'description'],
				editRelations: [
					{ relation: 'organization', property: 'name' },
					{ relation: 'aircraftType', property: 'name' },
					{ relation: 'aircraft', property: 'number' },
				],
			},
		],
	},
];

export const EXCLUDED_PROPERTIES = ['id', 'createdAt', 'updatedAt', 'externalCode'];
