import React from 'react';
import Vector from 'ol/source/Vector';
import WebGLPointsLayer from 'ol/layer/WebGLPoints';
import { endOfDay, startOfDay } from 'date-fns';
import store from 'client/store';
import { observable } from 'mobx';
import Feature from 'ol/Feature';
import { geoJSON } from '@smartplatform/map/client';
import { FIELDS_FIRE_DATA } from 'components/common-layers/heat-points/new-version/constants';
import HeatPointPopup from 'components/common-layers/heat-points/HeatPointPopup';
import t from 'i18n';

const FILTER_FIELDS = ['start', 'end', 'show', 'dataSource', 'region'];

export default class HeadPointsLayerWebglPoints {
	@observable isLoading = false;
	@observable vectorSource;
	@observable features = [];

	@observable start = null;
	@observable end = null;
	@observable show = false;
	@observable region = false;
	@observable dataSource = null;
	@observable isInitialization = false;
	@observable regionRecord = null;

	initialized = false;
	layer = null;

	constructor(mapStore, settings = {}) {
		this.mapStore = mapStore;
		this.settings = settings;
		this.initFilter();
		this.initDefaultValues();
		this.init();
	}

	initDefaultValues = async () => {
		if (!this.start) this.start = new Date(startOfDay(new Date()));
		if (!this.end) this.end = new Date(endOfDay(new Date()));
		if (!this.dataSource) this.dataSource = (await store.model.FireDataSource.findById(3)) || null;
	};

	init = async () => {
		if (!this.settings.show) return false;

		this.regionRecord = (await store.model.Region.find({ where: { code: 14 }, fields: ['id'] }))?.[0] || null;
		if (!this.dataSource) this.dataSource = (await store.model.FireDataSource.findById(3)) || null;

		this.vectorSource = new Vector({});
		this.layer = new WebGLPointsLayer({
			source: this.vectorSource,
			style: {
				'circle-radius': 3,
				'circle-fill-color': ['match', ['get', 'colorPoint'], 'today', '#d00', 'yesterday', '#f80', '#aaa'],
				'circle-sort-key': ['match', ['get', 'colorPoint'], 'today', 3, 'yesterday', 2, 1],
			},
		});

		this.layer.setZIndex(5);
		this.mapStore.addLayer(this.layer);
		this.isInitialization = true;
		await this.fetchRecords();
		this.setVisible(true);
	};

	renderPopup = (record) => <HeatPointPopup record={record} />;

	fetchRecords = async () => {
		this.isLoading = true;
		const records = await store.model.FireData.find({ where: this.filters, fields: FIELDS_FIRE_DATA });
		const features = [];
		for (const record of records) {
			const geometry = geoJSON.readGeometry(record.geo);

			let color = 'other';
			if (new Date(record.date).toDateString() === new Date().toDateString()) color = 'today';
			if (new Date(record.date).toDateString() === new Date(new Date().setDate(new Date().getDate() - 1)).toDateString()) color = 'yesterday';

			const [lon, lat] = record.geo?.coordinates;
			const _feature = new Feature({
				geometry: geometry,
				record: {
					lon,
					lat,
					start: this.start.toISOString(),
					end: this.end.toISOString(),
					dataSourceId: this.dataSource.id || 3,
				},
				colorPoint: color,
				title: t('heatPoint.title'),
				render: this.renderPopup,
			});
			features.push(_feature);
		}
		this.features = features;
		this.updateLayer();
		this.isLoading = false;
	};

	initFilter = async () => {
		for (const prop in this.settings) {
			if (FILTER_FIELDS.includes(prop)) {
				if (['start', 'end'].includes(prop)) {
					this[prop] = new Date(this.settings[prop]);
				} else {
					this[prop] = this.settings[prop];
				}
			}
		}
	};

	get filters() {
		const where = { and: [] };
		if (this.start) where.and.push({ date: { gte: this.start } });
		if (this.end) where.and.push({ date: { lte: this.end } });
		if (this.dataSource) where.and.push({ sourceId: this.dataSource.id });
		if (!this.region && this.regionRecord) where.and.push({ regionId: this.regionRecord.id });
		return where.and.length ? where : {};
	}

	updateLayer = (features) => {
		this.vectorSource.clear();
		this.vectorSource.addFeatures(features ? features : this.features);
	};

	remove = () => {
		if (this.mapStore && this.layer) {
			this.layer.cleanUp();
			this.mapStore.removeLayer(this.layer);
			this.layer = null;
		}
	};

	onChangeDate = (prop) => (value) => {
		if (prop === 'start') this[prop] = new Date(startOfDay(value));
		if (prop === 'end') this[prop] = new Date(endOfDay(value));
		this.settings[prop] = this[prop];
		store.local.save();
		this.fetchRecords();
	};

	onChange = (prop) => (value) => {
		this[prop] = value;
		this.settings[prop] = this[prop];
		store.local.save();
		this.fetchRecords();
	};

	setVisible = (value) => {
		this.layer?.setVisible && this.layer.setVisible(value);
	};
}
