import marker_blue from './marker_blue.svg?url';
import marker_red from './marker_red.svg?url';
import marker_green from './marker_green.svg?url';
import t from 'i18n';

export default {
	red: { src: marker_red, label: t('techFire.firesPlanVisits.PTZ') },
	green: { src: marker_green, label: t('techFire.firesPlanVisits.PTU') },
	blue: { src: marker_blue, label: ` ${t('techFire.firesPlanVisits.otherTypesVisits')}` },
	default: marker_blue,
};
