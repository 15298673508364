import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Loader, Form, Field, Row } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';
import { GeoEdit } from 'components';
import { Fill, Icon, Stroke, Style } from 'ol/style';
import marker from 'img/icons/marker.svg?url';
import { blockedRoadLineColor } from '../../helper';

const getStyle = ({ strokeColor }) => {
	const fill = new Fill({ color: 'rgba(0, 80, 255, 0.3)' });
	const stroke = new Stroke({ color: strokeColor ?? 'rgba(0, 80, 255, 0.6)', width: 6 });

	return new Style({ fill, stroke, image: new Icon({ src: marker, anchor: [10, 27], anchorXUnits: 'pixels', anchorYUnits: 'pixels' }) });
};

@observer
export default class Edit extends React.Component {
	@observable record = null;

	constructor(props) {
		super(props);
		this.init();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.match.params.id !== this.props.match.params.id) {
			this.init();
		}
	}

	init = async () => {
		const id = parseInt(this.props.match.params.id);
		if (id) {
			this.record = await store.model.TechFireBlockedRoad.findById(id);
		} else {
			this.record = new store.model.TechFireBlockedRoad();
		}
	};

	redirectBack = () => store.route.push({ path: this.props.path });

	getLineColor = () => {
		return getStyle({ strokeColor: blockedRoadLineColor(this.record) });
	};

	render() {
		if (!this.record) return <Loader />;

		return (
			<>
				<h1>
					{t('techFireBlockedRoad.title')} {this.record.id ? `#${this.record.id}` : ''}
				</h1>
				<Form record={this.record} path={this.props.path} stay onSave={this.redirectBack} onDelete={this.redirectBack}>
					<Row>
						<Field property='geo' label={t('geo')}>
							<GeoEdit defaultStyle={this.getLineColor()} />
						</Field>
					</Row>
					<Row>
						<Field property='dateClose' label={t('techFireBlockedRoad.dateClose')} />
						<Field property='dateOpen' label={t('techFireBlockedRoad.dateOpen')} />
						<div />
						<div />
					</Row>
					<Row>
						<Field property='reason' label={t('techFireBlockedRoad.reason')} />
						<Field property='info' label={t('techFireBlockedRoad.info')} />
						<div />
						<div />
					</Row>
				</Form>
			</>
		);
	}
}
