import React from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import { RecordSelect, DatePicker, Checkbox } from '@smartplatform/ui';
import FilterLayout from '../../../FilterLayout';
import store from 'client/store';
import t from 'i18n';

@observer
export default class NewHeatPointsFilter extends React.Component {
	@observable changed = false;
	@observable count = null;

	constructor(props) {
		super(props);
		this.layer = props.layer;
	}

	onToggle = async (value) => {
		this.layer.settings.show = value;
		store.local.save();
		if (!this.layer.isInitialization && value) await this.layer.init();
		this.layer.setVisible(value);
	};

	render() {
		const { layer } = this.props;

		return (
			<FilterLayout layer={layer} title={t('heatPoint.newPlural')} isLoading={layer.isLoading} onToggle={this.onToggle}>
				<div className='mb-2'>
					<label>{t('heatPoint.source')}</label>
					<RecordSelect
						noTotal
						model={store.model.FireDataSource}
						property='name'
						value={layer.dataSource}
						onChange={layer.onChange('dataSource')}
						width={200}
						position='bottomLeft'
						isRequired
						noSearch
						disabled={!layer.settings.show}
					/>
				</div>
				<div className='mb-2 heat-points-period'>
					<label>{t('heatPoint.period')}</label>
					<DatePicker
						value={layer.start}
						onChange={layer.onChangeDate('start')}
						showClearButton={false}
						isRequired
						className='start'
						disabled={!layer.settings.show}
					/>
					<DatePicker value={layer.end} onChange={layer.onChangeDate('end')} showClearButton={false} isRequired disabled={!layer.settings.show} />
				</div>
				<div className='mt-1'>
					<Checkbox checked={layer.region} onChange={layer.onChange('region')} label={t('heatPoint.eurasia')} disabled={!layer.settings.show} />
				</div>
				<div>
					<span className='hp-legend today' /> {t('heatPoint.today') + ' '}
					<span className='hp-legend yesterday' /> {t('heatPoint.yesterday') + ' '}
					<span className='hp-legend earlier' /> {t('heatPoint.earlier')}
				</div>
				<div>
					{t('heatPoint.plural')}: <strong>{layer.features.length}</strong>
				</div>
			</FilterLayout>
		);
	}
}
