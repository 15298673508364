import { Column, Pager, RecordSelect, Table } from '@smartplatform/ui';
import React from 'react';
import t from 'i18n';
import store from 'client/store';
import { fio } from 'client/tools';
import { ListStore, Toolbar } from 'components';
import { observer } from 'mobx-react';
import { autorun, observable } from 'mobx';
import './style.scss';
import GarrisonSelect from 'techfires/components/garrison-select/GarrisonSelect';
import { FIREDEP_PERSONNEL_LOCAL_STORAGE_FIELDS } from './constants';

const FILTER_INCLUDE = ['position', 'rank', 'vehicle', 'status', 'section', 'gasMask', 'fireDep'];

@observer
export default class List extends React.Component {
	@observable position = null;
	@observable rank = null;
	@observable vehicle = null;
	@observable status = null;
	@observable section = null;
	@observable gasMask = null;
	@observable fireDep = null;
	@observable headOrg = null;
	@observable filter = {};
	@observable garrison = null;

	constructor(props) {
		super(props);
		this.store = new ListStore({ path: props.path, fields: { include: FILTER_INCLUDE }, model: store.model[props.modelName] });
		this.loadLocalStorage();

		autorun(() => {
			this.saveToLocalStorage();
		});
		this.updateFilter();
	}

	componentDidMount() {
		this.store.setPerPage();
	}

	onChange = (prop) => (value) => {
		this[prop] = value;
		this.updateFilter();
		this.saveToLocalStorage();
	};

	updateFilter = async () => {
		const filter = { where: {}, ...this.props.filter };
		filter.where.and = [];
		if (this.position) {
			filter.where.and.push({ positionId: this.position.id });
		} else if (this.rank) {
			filter.where.and.push({ rankId: this.rank.id });
		} else if (this.vehicle) {
			filter.where.and.push({ vehicleId: this.vehicle.id });
		} else if (this.status) {
			filter.where.and.push({ statusId: this.status.id });
		} else if (this.section) {
			filter.where.and.push({ sectionId: this.section.id });
		} else if (this.gasMask) {
			filter.where.and.push({ gasMaskId: this.gasMask.id });
		} else if (this.fireDep) {
			filter.where.and.push({ fireDepId: this.fireDep.id });
		} else if (this.headOrg) {
			filter.where.and.push({ headOrgId: this.headOrg.id });
		} else if (this.garrison) {
			const fireDepIds = (await store.model.ViewFireDep.find({ where: { garrisonId: this.garrison.id }, fields: ['id'] })).map((r) => r.id);
			const organizationIds = (await store.model.ViewTechFireOrganization.find({ where: { garrisonId: this.garrison.id }, fields: ['id'] })).map((r) => r.id);
			filter.where.and.push({ or: [{ fireDepId: { inq: fireDepIds } }, { headOrgId: { inq: organizationIds } }] });
		}
		filter.include = FILTER_INCLUDE;
		this.filter = filter;
		this.store.updateFilters(this.filter);
	};

		//localstorage;
		localStorage = store.local.fireDepPersonnel;
		saveToLocalStorage = () => {
			FIREDEP_PERSONNEL_LOCAL_STORAGE_FIELDS.forEach((field) => (this.localStorage[field] = this[field]));
			store.local.save();
		};
	
		loadLocalStorage = () => {
			const storageKeys = Object.keys(this.localStorage);
			if (storageKeys.length)
				storageKeys.forEach((key) => {
					this[key] = this.localStorage[key];
				});
		};

	render() {
		const { tableProps, totalCount, perPage, page, onChange, search, onSearch, create } = this.store;

		return (
			<>
				<Toolbar>
					<Toolbar.AddButton onClick={create} />
					<Toolbar.SearchIconInput value={search} onChange={onSearch} />
				</Toolbar>
				<div className='filterSelectsPersonnel'>
					<div className='selectBox'>
						<RecordSelect
							model={store.model.FireDep}
							property='name'
							value={this.fireDep}
							onChange={this.onChange('fireDep')}
							width={150}
							placeholder={t('fireDep.title')}
						/>
					</div>
					<div className='selectBox'>
						<RecordSelect
							model={store.model.TechFireOrganization}
							property='name'
							value={this.headOrg}
							onChange={this.onChange('headOrg')}
							width={150}
							placeholder={t('techFireOrganization.title')}
						/>
					</div>
					<div className='selectBox'>
						<RecordSelect
							model={store.model.FireDepPosition}
							property='name'
							value={this.position}
							onChange={this.onChange('position')}
							width={150}
							placeholder={t('fireDepPosition.title')}
						/>
					</div>
					<div className='selectBox'>
						<RecordSelect
							model={store.model.FireDepRank}
							property='name'
							value={this.rank}
							onChange={this.onChange('rank')}
							width={150}
							placeholder={t('fireDepRank.title')}
						/>
					</div>
					<div className='selectBox'>
						<RecordSelect
							model={store.model.FireDepVehicle}
							property='stateNumber'
							value={this.vehicle}
							onChange={this.onChange('vehicle')}
							filter={{ where: { fireDepShiftId: null } }}
							width={150}
							placeholder={t('fireDepVehicle.title')}
						/>
					</div>
					<div className='selectBox'>
						<RecordSelect
							model={store.model.FireDepPersonnelStatus}
							property='name'
							value={this.status}
							onChange={this.onChange('status')}
							width={150}
							placeholder={t('person.status')}
						/>
					</div>
					<div className='selectBox'>
						<RecordSelect
							model={store.model.FireDepSection}
							property='name'
							value={this.section}
							onChange={this.onChange('section')}
							width={150}
							placeholder={t('person.section')}
						/>
					</div>
					<div className='selectBox'>
						<RecordSelect
							model={store.model.GasMask}
							property='name'
							value={this.gasMask}
							onChange={this.onChange('gasMask')}
							width={150}
							placeholder={t('person.gasMask')}
						/>
					</div>
					<div className='selectBox'>
						<GarrisonSelect value={this.garrison} onChange={this.onChange('garrison')} width={200} />
					</div>
				</div>
				<Pager current={page} onChange={onChange('page')} totalCount={totalCount || 0} itemsPerPage={perPage} />
				<Table {...tableProps}>
					<Column property='id' label={t('id')} width={80} />
					<Column property='responsibleForCalling' label={t('fireDepPersonnel.responsibleForCalling')} width={80} />
					<Column computed={fio} label={t('person.fullName')} width={200} />
					{/* <Column property='lastName' label={t('person.lastName')} />
					<Column property='firstName' label={t('person.firstName')} />
					<Column property='middleName' label={t('person.middleName')} /> */}
					<Column property='phone' label={t('person.phone')} width={120} />
					<Column property='address' label={t('person.address')} width={120} />
					<Column relation='fireDep' property='name' label={t('fireDep.title')} width={120} />
					<Column relation='headOrg' property='name' label={t('techFireOrganization.title')} width={120} />
					<Column relation='position' property='name' label={t('fireDepPosition.title')} width={130} />
					<Column relation='rank' property='name' label={t('fireDepRank.title')} width={120} />
					<Column relation='vehicle' property='stateNumber' label={t('fireDepVehicle.title')} width={80} />
					<Column relation='status' property='name' label={t('person.status')} width={130} />
					<Column relation='section' property='name' label={t('person.section')} />
					<Column relation='gasMask' property='name' label={t('person.gasMask')} />
					<Column property='dayShift' label={t('fireDepPersonnel.dayShift')} />
					{/* <Column relation='user' computed={fio} label={t('person.fio')} /> */}
				</Table>
			</>
		);
	}
}
