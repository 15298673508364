import React from 'react';
import { observer, Provider } from 'mobx-react';
import { observable } from 'mobx';
import { Tabs, Tab } from '@smartplatform/ui';
import PlanVisitInfo from './planVisitInfo';
import { List as WayBills } from './wayBills';
import FirePlanVisitStore from './store';
import t from 'i18n';
import './style.scss';

@observer
export default class FirePlanVisit extends React.Component {
	@observable wayBillTab = false;

	constructor(props) {
		super(props);
		const { id } = props.match.params;
		this.requiredFields = ['objectId', 'dateOfDeparture'];
		this.store = new FirePlanVisitStore(id);
		this.store.path = props.path;
	}

	componentDidUpdate(prevProps) {
		if (this.props.match.params.id !== prevProps.match.params.id) {
			this.store = new FirePlanVisitStore(this.props.match.params.id);
		}
		this.wayBillTab = this.props.location.pathname.includes('waybills');
	}

	render() {
		const { firePlanVisit } = this.store;
		const { url } = this.props.match;
		if (!firePlanVisit || !firePlanVisit.id) return '...';

		return (
			<div className='techfire-single'>
				<h1>{t('techFire.firesPlanVisits.title') + ` #${firePlanVisit.id}`}</h1>

				<Provider store={this.store}>
					<Tabs>
						<Tab path={url} exact title={t('techFire.firesPlanVisits.title')} render={(routeProps) => <PlanVisitInfo {...routeProps} />} />
						<Tab
							path={`${url}/waybills`}
							title={t('techFireWayBill.plural')}
							render={(routeProps) => <WayBills {...routeProps} firePlanVisitId={firePlanVisit.id} />}
						/>
					</Tabs>
				</Provider>
			</div>
		);
	}
}
