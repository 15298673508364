import React from 'react';
import { observer } from 'mobx-react';
import { Field, Form } from '@smartplatform/ui';
import store from 'client/store';
import { observable } from 'mobx';
import GeoEdit from '../../../components/geo-edit/GeoEdit';
import t from 'i18n';
import { Attachments, AddressSearch, LabelField, ReportToolbar } from 'components';
import { OBJECT_INCLUDE } from '../constants';

@observer
export default class Edit extends React.Component {
	@observable record = null;
	@observable isLoading = true;
	@observable fitGeo = null;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.id = parseInt(this.props.match.params.id);
		if (this.id) {
			this.record = await store.model.PermafrostObject.findById(this.id, OBJECT_INCLUDE);
			this.fitGeo = this.record.address?.geo;
		} else {
			this.record = new store.model.PermafrostObject({ minTemperature: 0, maxTemperature: -20 });
		}
		this.isLoading = false;
	};

	onRowClick = (record) => store.route.push({ path: `${store.route.path}/${record.id}` });

	onAddressChange = (address) => {
		this.record.address = address;
		if (address) this.fitGeo = address.geo;
	};

	get reportParams() {
		const { id } = this.record;
		return {
			reportCode: 'TemperatureTubesMeasurements',
			reportParams: { objectId: id },
			reportModel: 'PermafrostReport',
		};
	}

	render() {
		if (this.isLoading) return null;
		const { path } = this.props;

		return (
			<>
				{this.record.id && <ReportToolbar {...this.reportParams} showPreview={false} showPrint={false} />}
				<Form record={this.record} className='w-50' returnTo={path}>
					<Field property='geo' label={t('geo')}>
						<GeoEdit fitGeo={this.fitGeo} params={{ defaultTileSource: '2gis' }} isFiltering />
					</Field>
					<h4>{t('permafrost.objects.address')}</h4>
					<Field property='cadastralNumber' label={t('permafrost.objects.cadastralNumber')} />
					<LabelField label={t('permafrost.objects.address')}>
						<AddressSearch value={this.record.address} onChange={this.onAddressChange} />
					</LabelField>
					<Field relation='administrativeRegion' property='name' label={t('permafrost.objects.administrativeRegion')} />
					<Field relation='county' property='name' label={t('permafrost.objects.county')} />
					<Field relation='city' property='name' label={t('permafrost.objects.city')} />
					<Field relation='quarter' property='name' label={t('permafrost.objects.quarter')} />
					<Field property='street' label={t('permafrost.objects.street')} />
					<Field property='house' label={t('permafrost.objects.house')} />
					<Field property='building' label={t('permafrost.objects.building')} />
					<h4>{t('permafrost.objects.objectInfo')}</h4>
					<Field property='name' label={t('permafrost.objects.name')} />
					<Field property='caseNumber' label={t('permafrost.objects.caseNumber')} />
					<Field property='OMKNumber' label={t('permafrost.objects.OMKNumber')} />
					<Field property='location' label={t('permafrost.objects.location')} />
					<Field relation='builder' property='name' label={t('permafrost.objects.builder')} />
					<Field relation='customer' property='name' label={t('permafrost.objects.customer')} />
					<Field relation='contractor' property='name' label={t('permafrost.objects.contractor')} />
					<Field relation='designer' property='name' label={t('permafrost.objects.designer')} />
					<Field relation='operatingOrganization' property='name' label={t('permafrost.objects.operatingOrganization')} />
					<Field property='entryDate' label={t('permafrost.objects.entryDate')} />
					<Field property='constructionStartDate' label={t('permafrost.objects.constructionStartDate')} />
					<Field relation='constructionStage' property='name' label={t('permafrost.objects.constructionStage')} />
					<Field relation='houseStatus' property='name' label={t('permafrost.objects.houseStatus')} />
					<h4>{t('permafrost.objects.addInfo')}</h4>
					<Field relation='inspector' property='name' label={t('permafrost.objects.inspector')} />
					<Field property='floorNumber' label={t('permafrost.objects.floorNumber')} />
					<Field property='note' label={t('permafrost.objects.note')} />
					<Field property='additionalInfo' label={t('permafrost.objects.additionalInfo')} />
					<Field relation='energyEfficiencyClass' property='name' label={t('permafrost.objects.energyEfficiencyClass')} />
					<Field property='coolingElementsNumber' label={t('permafrost.objects.coolingElementsNumber')} />
					<Field relation='foundationType' property='name' label={t('permafrost.objects.foundationType')} />
					<Field relation='constructive' property='name' label={t('permafrost.objects.constructive')} />
					<Field property='designTemperature' label={t('permafrost.objects.designTemperature')} />
					<Field property='permissibleDraft' label={t('permafrost.objects.permissibleDraft')} />
					<Field property='minTemperature' label={t('permafrost.objects.minTemperature')} />
					<Field property='maxTemperature' label={t('permafrost.objects.maxTemperature')} />
					<Attachments record={this.record} canUpload={true} withFormContext={!this.record.id} />
				</Form>
			</>
		);
	}
}
