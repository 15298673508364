import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { RecordSelect } from '@smartplatform/ui';
import store from 'client/store';
import { SaveButton, AddButton, ReportToolbar } from 'components';
import Vehicles from './Vehicles';
import WayBillStore from './store';
import t from 'i18n';
import './style.scss';

@observer
export default class WayBillEdit extends React.Component {
	@observable store = null;

	constructor(props) {
		super(props);
		this.store = new WayBillStore(props);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.match.params.wayBillId !== prevProps.match.params.wayBillId) {
			this.store = new WayBillStore(this.props);
		}
	}

	render() {
		const { record, fireDeps, addedFireDep, selectFireDepToAdd, addFireDep, save, fireDepId, rank, onChange } = this.store;

		if (!record) return null;
		let vehiclesCount = 0;
		Object.values(fireDeps).forEach(({ selectedVehicles }) => (vehiclesCount += selectedVehicles.length));

		let fireDepsIds = Object.keys(fireDeps).map((v) => parseInt(v));
		const waybillFireDeps = Object.values(fireDeps)
			.filter((x) => x.vehicles.length)
			.map((x) => x.fireDep.name);
		return (
			<div className='waybill-single'>
				{record.id && <h1>{record?.rank?.name}</h1>}
				<div className='firedep-list'>
					{!record.id && (
						<div className='actions'>
							{!this.props.noSave && (
								<>
									<RecordSelect
										model={store.model.TechFireRank}
										property='name'
										value={rank}
										onChange={onChange('rank')}
										width={290}
										placeholder={t('techFireAddress.selectRank')}
										isRequired
									/>
									<SaveButton onClick={save} disabled={vehiclesCount === 0 || !rank} />
								</>
							)}
							<RecordSelect
								model={store.model.FireDep}
								filter={{
									where: { id: { nin: fireDepsIds } },
								}}
								property='name'
								value={addedFireDep}
								onChange={selectFireDepToAdd}
								width={120}
								disabled={this.isChecked}
							/>
							<AddButton variant='primary' disabled={!addedFireDep} onClick={() => addFireDep(addedFireDep)}>
								{t('techFireWayBill.addFireDep')}
							</AddButton>
						</div>
					)}
					{this.store.errors && (
						<div className='errors'>
							{this.store.errors.map((e, i) => (
								<div key={i}>{e}</div>
							))}
						</div>
					)}
					<Vehicles store={this.store} />
				</div>
				{record.id && (
					<div className='techfires-toolbar'>
						<ReportToolbar reportCode='Waybill' reportParams={{ id: fireDepId, waybillFireDeps }} showPreview={false} showPrint={false} />
					</div>
				)}
			</div>
		);
	}
}
