import React from 'react';
import { inject, observer } from 'mobx-react';
import AlarmIcon from 'img/icons/techfires/card/alarm.svg';
import { FieldBasedCard } from '../../../../components';

@inject('fireStore')
@observer
class FirstUnit extends React.Component {
	render() {
		const { fireStore } = this.props;
		const { fire } = fireStore;

		const rows = [
			[
				{
					property: 'arrivalTime',
					label: 'techFire.time',
					type: 'DatePicker',
					props: { showTimeSelect: true },
				},
				{
					property: 'violation',
					label: 'Нарушение тех. регламента',
					type: 'Input',
				},
				{
					property: 'empty',
					type: 'default',
				},
			],
		];

		return (
			<FieldBasedCard
				title='techFire.firstUnit'
				icon={AlarmIcon}
				fields={[fire.arrivalTime]}
				rows={rows}
				statusKey='firstUnit'
				fireStore={fireStore}
				isCard={true}
			/>
		);
	}
}

export default FirstUnit;
