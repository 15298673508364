import { fio } from 'client/tools';
import { renderStatus } from 'client/tools/helpers';
import { TechFireAddressSearch } from 'components/techfire-address-search';
import React from 'react';
import { fixVehicleType } from './helpers';
import { ObjectLog } from './registry-object-adpi-log/ObjectLog';
import t from 'i18n';
import { AdpiDevice, FireDepPersonnel, FireDepVehicle, WaterSupply, BlockedRoad } from './dictionaries';
import { getPrefixByCountyName } from 'client/tools/getPrefixByCountyName';
import store from 'client/store';

export const ALARM_TYPE_ADPI = 'ADPI';
export const ALARM_TYPE_112 = '112';
export const ALARM_TYPE_MANUAL = 'MANUAL';
export const ALARM_TYPE_PHONE = 'PHONE';

export const DICTIONARIES_MODELS = [
	{
		title: t('techFire.fires.title'),
		type: 'submenu',
		items: [
			{ modelName: 'FireAlarmMessageSource' },
			{ modelName: 'FireAlarmMessageStatus' },
			{ modelName: 'TechFireReportSource' },
			{ modelName: 'TechFireCauseType' },
			{ modelName: 'TechFireVisitType' },
			{ modelName: 'TechFireVisitSubType', listRelations: [{ relation: 'parent', property: 'name' }] },
		],
	},
	{
		title: t('fireDep.personnel'),
		type: 'submenu',
		items: [
			{ modelName: 'FireDepSection' },
			{ modelName: 'FireDepRank' },
			{
				modelName: 'FireDepPosition',
				listRelations: [{ relation: 'positionCode', property: 'name' }],
			},
			{ modelName: 'FireDepPositionCode' },
			{ modelName: 'FireDepPersonnelStatus' },
		],
	},
	{
		title: t('fireDep.plural'),
		type: 'submenu',
		items: [
			{ modelName: 'TechFireOrganization', editRelations: [{ relation: 'parent', property: 'name', excludeRecordId: true }] },
			{ modelName: 'GasMask' },
			{ modelName: 'TechFireSentry' },
			{ modelName: 'FireDepType' },
		],
	},
	{
		title: t('fireDep.vehicles'),
		type: 'submenu',
		items: [
			{ modelName: 'FireDepVehicleBrand' },
			{ modelName: 'FireDepVehicleStatus' },
			{ modelName: 'FireDepVehicleFuel' },
			{
				modelName: 'FireDepVehicleType',
				onEdit: fixVehicleType,
				listRelations: [
					{ relation: 'specialType', property: 'name' },
					{ relation: 'vehicleCode', property: 'name' },
				],
			},
			{ modelName: 'FireDepVehicleTypeCode' },
			{ modelName: 'FireDepVehicleCallSign', listRelations: [{ relation: 'fireDep', property: 'name' }] },
			{ modelName: 'Pump' },
			{ modelName: 'FoamCapacity' },
			{ modelName: 'FireDepSpecialVehicleType' },
			{ modelName: 'FireDepPowderCapacity' },
			{ modelName: 'ProtectiveSuit' },
			{ modelName: 'TechFireFoam' },
			{ modelName: 'HydraulicTool' },
			{ modelName: 'CisternBulk' },
		],
	},
	{
		title: t('techFireAddress.objects'),
		type: 'submenu',
		items: [{ modelName: 'TechFireRank' }, { modelName: 'TechFireCategory' }, { modelName: 'TechFireObjectType' }],
	},
	{
		title: t('trunk.title'),
		type: 'submenu',
		items: [
			{ modelName: 'TechFireTrunkView' },
			{
				modelName: 'TechFireTrunkType',
				listRelations: [{ relation: 'view', property: 'name' }],
			},
		],
	},
	{
		title: t('system112'),
		type: 'submenu',
		items: [{ modelName: 'TechFireEmergencyCode' }, { modelName: 'FireDepStatus' }, { modelName: 'TechFireCallStatus' }],
	},
	{
		title: t('fireAlarm.title'),
		type: 'submenu',
		items: [
			{ modelName: 'FireAlarmOrganizationAdpiService' },
			{ modelName: 'AdpiDeviceStatus' },
			{ modelName: 'AdpiDeviceModel' },
			{ modelName: 'AdpiDeviceType' },
		],
	},
	{
		title: t('waterSupply.plural'),
		type: 'submenu',
		items: [{ modelName: 'WaterSupplyType' }, { modelName: 'WaterSupplyDivision' }],
	},
	{
		title: t('report.plural'),
		type: 'submenu',
		items: [{ modelName: 'TechFireReport', listProperties: ['name', 'code', 'shortName'], editProperties: ['name', 'code', 'shortName'] }],
	},
	{
		title: t('techFire.organizations'),
		type: 'submenu',
		items: [{ modelName: 'TechFireInteractionServiceType', listProperties: ['id', 'name'], editProperties: ['id', 'name'] }],
	},
];

export const REGISTRY_MODELS = [
	{
		title: t('fire.registry.title'),
		type: 'submenu',
		items: [
			{
				modelName: 'AdpiDevice',
				Component: ObjectLog,
				listRelations: [
					{ relation: 'status', computed: renderStatus, properties: ['name', 'color'] },
					{ relation: 'type', property: 'name' },
					{ relation: 'organization', property: 'name' },
					{ relation: 'model', property: 'name' },
				],
				editRelations: [
					{ relation: 'status', computed: renderStatus, properties: ['name', 'color'] },
					{ relation: 'type', property: 'name' },
					{ relation: 'address', children: <TechFireAddressSearch /> },
					{ relation: 'organization', property: 'name' },
					{ relation: 'model', property: 'name' },
				],
				editComponent: AdpiDevice.Edit,
				listComponent: AdpiDevice.List,
			},
			{
				modelName: 'WaterSupply',
				listProperties: ['name', 'number', 'createdAt', 'updatedAt'],
				listRelations: [
					{ relation: 'division', property: 'name' },
					{ relation: 'type', property: 'name' },
				],
				editComponent: WaterSupply.Edit,
				listComponent: WaterSupply.List,
			},
			{
				modelName: 'TechFireBlockedRoad',
				listModelName: 'ViewTechFireBlockedRoad',
				listProperties: ['dateClose', 'dateOpen', 'reason', 'info', 'createdAt', 'updatedAt'],
				editComponent: BlockedRoad.Edit,
				listComponent: BlockedRoad.List,
			},
		],
	},
	{
		title: t('fireDep.title'),
		type: 'submenu',
		items: [
			{
				modelName: 'FireDepPersonnel',
				//При создании смены создается копия FireDepPersonnel с id смены. Чтобы показывать только личный состав пожарной части показываем запись без id смены
				filter: { where: { fireDepShiftId: null } },
				listProperties: ['lastName', 'firstName', 'middleName', 'dayShift', 'phone', 'address', 'createdAt', 'updatedAt'],
				listRelations: [
					{ relation: 'section', property: 'name' },
					{ relation: 'position', property: 'name' },
					{ relation: 'rank', property: 'name' },
					{ relation: 'status', property: 'name' },
					{ relation: 'vehicle', property: 'stateNumber' },
					{ relation: 'headOrg', property: 'name' },
					{ relation: 'gasMask', property: 'name' },
					{ relation: 'user', computed: fio, properties: ['name'] },
				],
				editProperties: ['lastName', 'firstName', 'middleName', 'dayShift', 'phone', 'address', 'createdAt', 'updatedAt'],
				editRelations: [
					{ relation: 'section', property: 'name' },
					{ relation: 'position', property: 'name' },
					{ relation: 'rank', property: 'name' },
					{ relation: 'status', property: 'name' },
					{ relation: 'vehicle', property: 'stateNumber' },
					{ relation: 'headOrg', property: 'name' },
					{ relation: 'gasMask', property: 'name' },
					{ relation: 'user', computed: fio, properties: ['name'] },
				],
				editComponent: FireDepPersonnel.Edit,
				listComponent: FireDepPersonnel.List,
			},
			{
				modelName: 'FireDepVehicle',
				//При создании смены создается копия FireDepVehicle с id смены. Чтобы показывать только технику пожарной части показываем запись без id смены
				filter: { where: { fireDepShiftId: null } },
				listProperties: ['year', 'stateNumber', 'passport', 'description', 'actualFoamValue', 'endDate', 'actualFuelValue', 'createdAt', 'updatedAt'],
				listRelations: [
					{ relation: 'brand', property: 'name' },
					{ relation: 'cisternBulk', property: 'volume' },
					{ relation: 'type', property: 'name' },
					{ relation: 'pump', property: 'capacity' },
					{ relation: 'callSign', property: 'name' },
					{ relation: 'status', property: 'name' },
					{ relation: 'foamCapacity', property: 'capacity' },
					{ relation: 'fuelValue', property: 'value' },
				],
				editProperties: ['year', 'stateNumber', 'passport', 'description', 'actualFoamValue', 'endDate', 'actualFuelValue', 'createdAt', 'updatedAt'],
				editRelations: [
					{ relation: 'brand', property: 'name' },
					{ relation: 'cisternBulk', property: 'volume' },
					{ relation: 'type', property: 'name' },
					{ relation: 'pump', property: 'capacity' },
					{ relation: 'callSign', property: 'name' },
					{ relation: 'status', property: 'name' },
					{ relation: 'foamCapacity', property: 'capacity' },
					{ relation: 'fuelValue', property: 'value' },
				],
				editComponent: FireDepVehicle.Edit,
				listComponent: FireDepVehicle.List,
			},
		],
	},
];
