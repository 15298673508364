import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { MemberAvatar } from './MemberAvatar';
import './style.scss';
import { fioShort, fio } from 'client/tools';

@observer
export class MembersAvatars extends React.Component {
	static propTypes = {
		members: PropTypes.array.isRequired,
		size: PropTypes.number,
		avatarsCount: PropTypes.number,
	};

	static defaultProps = {
		avatarsCount: 3,
		size: 18,
	};

	@observable popup = LOADING;

	constructor(props) {
		super(props);
	}

	render() {
		const { members, size, avatarsCount, full } = this.props;
		const avatars = members.slice(0, avatarsCount);
		const diff = members.length - avatars.length;
		const fioFunc = full ? fio : fioShort;
		const marginLeft = -size / 3;
		const avatarsWidth = size + (avatarsCount - 1) * marginLeft * (size - 1);
		return (
			<div className='members-avatars'>
				<div className='avatars' style={{ minWidth: avatarsWidth + 'px' }}>
					{avatars.map((member, i) => member && <MemberAvatar key={member.id} member={member} size={size} style={i > 0 ? { marginLeft } : {}} />)}
				</div>
				<span className='more'>{diff ? `+${diff}` : members.map((member) => member && <div key={member.id}>{fioFunc(member)}</div>)}</span>
			</div>
		);
	}
}
