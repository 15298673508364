import { observer } from 'mobx-react';
import React from 'react';
import t from 'i18n';
import { Column } from '@smartplatform/ui';
import { Table } from 'components/table-new';
import store from 'client/store';
import ObjectsStore from './store';
import { Toolbar } from 'components';
import PagerWithPerPage from '../components/pager/PagerWithPerPage';
import {exportReportHTMLByClassname} from "client/tools";

@observer
export default class List extends React.Component {
	constructor(props) {
		super(props);
		this.store = new ObjectsStore();
	}

	render() {
		const { page, perPage, totalCount, onChange, query, onQueryUpdate, onRowClick, search, onSearch } = this.store;
		const tableProps = {
			model: store.model.PermafrostObject,
			query,
			onQueryUpdate,
			filters: true,
			onRowClick,
			skeletonRow: true,
			infinityScrollPartSize: perPage
		};
		return (
			<div>
				<div className='top'>
					<h2>{t('permafrost.objectsMenu')}</h2>
					<Toolbar>
						<Toolbar.AddButton />
						<Toolbar.SearchIconInput value={search} onChange={onSearch} />
						<Toolbar.ExportButton onClick={() => exportReportHTMLByClassname({ className: 'sp-table', name: t('permafrost.objectsMenu') })} />
					</Toolbar>
				</div>
				<div>
					<PagerWithPerPage perPage={perPage} page={page} onChange={onChange} onRowClick={onRowClick} totalCount={totalCount} />
					<div className='overflow-auto'>
						<Table {...tableProps}>
							<Column property='id' label={t('id')} width={80} />
							<Column property='name' label={t('permafrost.objects.name')} width={750} />
							<Column relation='address' property='name' label={t('permafrost.objects.address')} width={600} />
							<Column relation='administrativeRegion' property='name' label={t('permafrost.objects.administrativeRegion')} width={220} />
							<Column relation='county' property='name' label={t('permafrost.objects.county')} width={220} />
							<Column relation='city' property='name' label={t('permafrost.objects.city')} width={220} />
							<Column relation='quarter' property='name' label={t('permafrost.objects.quarter')} width={220} />
							<Column property='street' label={t('permafrost.objects.street')} width={220} />
							<Column property='house' label={t('permafrost.objects.house')} width={220} />
							<Column property='building' label={t('permafrost.objects.building')} width={220} />
							<Column property='cadastralNumber' label={t('permafrost.objects.cadastralNumber')} width={220} />
							<Column property='caseNumber' label={t('permafrost.objects.caseNumber')} width={220} />
							<Column property='OMKNumber' label={t('permafrost.objects.OMKNumber')} width={220} />
							<Column property='location' label={t('permafrost.objects.location')} width={220} />
							<Column relation='builder' property='name' label={t('permafrost.objects.builder')} width={220} />
							<Column relation='customer' property='name' label={t('permafrost.objects.customer')} width={220} />
							<Column relation='contractor' property='name' label={t('permafrost.objects.contractor')} width={220} />
							<Column relation='designer' property='name' label={t('permafrost.objects.designer')} width={220} />
							<Column relation='operatingOrganization' property='name' label={t('permafrost.objects.operatingOrganization')} width={220} />
							<Column property='entryDate' label={t('permafrost.objects.entryDate')} width={220} />
							<Column property='constructionStartDate' label={t('permafrost.objects.constructionStartDate')} width={220} />
							<Column relation='constructionStage' property='name' label={t('permafrost.objects.constructionStage')} width={220} />
							<Column relation='houseStatus' property='name' label={t('permafrost.objects.houseStatus')} width={220} />
							<Column relation='inspector' property='name' label={t('permafrost.objects.inspector')} width={220} />
							<Column property='floorNumber' label={t('permafrost.objects.floorNumber')} width={220} />
							<Column property='note' label={t('permafrost.objects.note')} width={220} />
							<Column property='additionalInfo' label={t('permafrost.objects.additionalInfo')} width={220} />
							<Column relation='energyEfficiencyClass' property='name' label={t('permafrost.objects.energyEfficiencyClass')} width={220} />
							<Column property='coolingElementsNumber' label={t('permafrost.objects.coolingElementsNumber')} width={220} />
							<Column relation='foundationType' property='name' label={t('permafrost.objects.foundationType')} width={220} />
							<Column relation='constructive' property='name' label={t('permafrost.objects.constructive')} width={220} />
							<Column property='designTemperature' label={t('permafrost.objects.designTemperature')} width={220} />
							<Column property='permissibleDraft' label={t('permafrost.objects.permissibleDraft')} width={220} />
							<Column property='minTemperature' label={t('permafrost.objects.minTemperature')} width={220} />
							<Column property='maxTemperature' label={t('permafrost.objects.maxTemperature')} width={220} />
						</Table>
					</div>
				</div>
			</div>
		);
	}
}
