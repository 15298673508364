import React from 'react';
import { observer, Provider } from 'mobx-react';
import { Field, Loader, RelationSelect } from '@smartplatform/ui';
import { ReserveStore } from './store';
import { Form } from 'components';

import t from 'i18n';
import './style.scss';

@observer
export class SinglePage extends React.Component {
	constructor(props) {
		super(props);
		this.store = new ReserveStore();
		this.store.id = parseInt(this.props.match.params.id);
		this.store.init();
	}

	render() {
		const { isLoading, record, getFactClass } = this.store;
		if (isLoading) return <Loader size={18} />;

		return (
			<Provider store={this.store} >
				<div className='reserve'>
					<div className='header'>
						<div>
							<h2>{record.name}</h2>
						</div>
					</div>
					<Form record={record} stay noDelete noSave noCancel >
						<div className='main-info'>
							<div>
								<Field
									property='name'
									label={t('emergencyReserves.name')}
									disabled
								/>
							</div>
							<div>
								<Field label={t('emergencyReserves.category')} relation='category' property='name' disabled>
									<RelationSelect isRequired/>
								</Field>
								<Field label={t('emergencyReserves.unit')} relation='unit' property='name' disabled>
									<RelationSelect isRequired/>
								</Field>
							</div>
							<div>
								<Field label={t('emergencyReserves.city')} relation='city' property='name' disabled>
									<RelationSelect />
								</Field>
							</div>
							<div>
								<Field label={t('emergencyReserves.county')} relation='county' property='name' disabled>
									<RelationSelect />
								</Field>
							</div>
							<div>
								<Field
									property='fact'
									className={getFactClass()}
									label={t('emergencyReserves.fact')}
									disabled
								/>
								<Field
									property='norma'
									label={t('emergencyReserves.norma')}
									disabled
								/>
							</div>
						</div>
					</Form>
				</div>
			</Provider>
		);
	}
}
