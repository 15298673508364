import React from 'react';
import { observer } from 'mobx-react';
import { Route } from 'react-router-dom';
import store from 'client/store';
import module from './index';
import { Contragents, ModuleWrapper, UnversalDictionaryRouter } from 'components';
import Map from './map';
import initLocal from './localStore';
import { Municipalities } from './municipalities';
import { DynamicLayers } from '@smartplatform/map/client';
import { Reserves } from './reserves';
import { CITY_DICTIONARY, DICTIONARIES_MODELS } from './constants';
import { DictionariesWrapper } from './dictionaries/dictionaries-wrapper/DictionariesWrapper';
// const Dictionaries = loadable(() => import('components/dictionaries/dictionaries-page'));
import loadable from '@loadable/component';
const Audit = loadable(() => import('components/audit'));
const Plans = loadable(() => import('./plans'));
const OperationalManagement = loadable(() => import('./operational-management'));
import { CityEdit } from './dictionaries/city/Edit';

@observer
export default class Root extends React.Component {
	constructor(props) {
		super(props);
		initLocal();
	}

	render() {
		const { path } = this.props.match;
		return (
			<ModuleWrapper className='emergency-module' module={module} path={path} redirectPath={`${path}/map`}>
				<Route path={`${path}/map`} component={Map} />
				<Route path={`${path}/layers`} render={(routeProps) => <DynamicLayers {...routeProps} store={store} />} />
				<Route>
					<div className='emergency-page'>
						{store.model.user.cityId && (
							<Route path={`${path}/homecity`} render={(routeProps) => <CityEdit cityId={store.model.user.cityId} {...routeProps} />} />
						)}
						{store.model.user.countyId && (
							<Route
								path={`${path}/homecounty`}
								render={(routeProps) => (
									<UnversalDictionaryRouter {...{ ...CITY_DICTIONARY, listWhere: { countyId: store.model.user.countyId } }} {...routeProps} />
								)}
							/>
						)}
						<Route path={`${path}/plans`} component={Plans} />
						<Route path={`${path}/municipalities`} component={Municipalities} />
						<Route path={`${path}/reserves`} component={Reserves} />
						<Route path={`${path}/contragents`} component={Contragents} />
						<Route path={`${path}/dictionaries`} render={(props) => <DictionariesWrapper defaultModels={DICTIONARIES_MODELS} props={props} />} />
						<Route path={`${path}/journal`} component={Audit}></Route>
						<Route path={`${path}/operational-management`} component={OperationalManagement}></Route>
					</div>
				</Route>
			</ModuleWrapper>
		);
	}
}
