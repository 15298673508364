import React, { useMemo } from 'react';
import store from 'client/store';

export const withModuleWrapper = (ModuleComponent, options, componentProps = {}) => {
	class WrappedComponent extends React.Component {
		componentDidMount = () => {
			store.currentModule = options.moduleCode;
		};
		componentWillUnmount = () => {
			store.currentModule = null;
		};

		render() {
			return <ModuleComponent {...this.props} {...componentProps} />;
		}
	}

	// Мемоизация обернутого компонента
	return useMemo(() => WrappedComponent, []);
};
