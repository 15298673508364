import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Popover } from '@smartplatform/ui';
import t from 'i18n';

export class Popconfirm extends React.Component {
	static propTypes = {
		content: PropTypes.any.isRequired,
		title: PropTypes.any,
		position: PropTypes.oneOf([
			'topLeft',
			'top',
			'topRight',
			'leftTop',
			'left',
			'leftBottom',
			'rightTop',
			'right',
			'rightBottom',
			'bottomLeft',
			'bottom',
			'bottomRight',
		]),
		offset: PropTypes.number,
		delay: PropTypes.number,
		startOffset: PropTypes.number,
		className: PropTypes.string,
		contentClassName: PropTypes.string,
		style: PropTypes.object,
		onConfirm: PropTypes.func.isRequired,
		onActivate: PropTypes.func,
		confirmBtn: PropTypes.any,
		cancelBtn: PropTypes.any,
		rejectBtn: PropTypes.any,
		errorMsg: PropTypes.any,
		disabled: PropTypes.bool,
	};

	static defaultProps = {
		confirmBtn: t('ok'),
		cancelBtn: t('cancel'),
		rejectBtn: t('ok'),
		errorMsg: t('error'),
	};

	constructor(props) {
		super(props);
		this.state = { rejected: null };
	}

	onConfirm = async () => {
		try {
			const res = await this.props.onConfirm();
			if (res !== undefined && (res === false || res.error)) {
				let msg = this.props.errorMsg;
				if (res.error) msg = res.error;
				this.setState({ rejected: msg });
			}
			if (this.popover) this.popover.close();
		} catch (e) {
			console.error('Popconfirm:', e);
			this.setState({ rejected: e.message });
		}
	};

	getInstance = (instance) => (this.popover = instance);

	close = () => {
		// this.setState({ rejected: null });
		this.popover && this.popover.close();
	};

	onClose = () => this.setState({ rejected: null });

	render() {
		const { confirmBtn, cancelBtn, rejectBtn, content, onConfirm, contentClassName, submit, disabled, ...sendProps } = this.props;

		const _contentClassName = classNames('sp-popconfirm', contentClassName);

		const contentDiv =
			typeof content === 'string' ? (
				<div className={_contentClassName} dangerouslySetInnerHTML={{ __html: content.replace(/\\n/g, '<br />') }} />
			) : (
				<div className={_contentClassName}>{content}</div>
			);

		const popContent = !this.state.rejected ? (
			<>
				{contentDiv}
				{!disabled && (
					<div className='sp-popconfirm-buttons'>
						<Button size='small' variant='primary' onClick={this.onConfirm}>
							{confirmBtn}
						</Button>
						<Button size='small' onClick={this.close}>
							{cancelBtn}
						</Button>
					</div>
				)}
			</>
		) : (
			<>
				{this.state.rejected}
				<div className='sp-popconfirm-buttons'>
					<Button size='small' onClick={this.close}>
						{rejectBtn}
					</Button>
				</div>
			</>
		);

		return (
			<Popover
				{...sendProps}
				contentClassName={_contentClassName}
				content={popContent}
				trigger={disabled ? 'manual' : 'click'}
				onClose={this.onClose}
				getInstance={this.getInstance}
			>
				{this.props.children}
			</Popover>
		);
	}
}
