import React from 'react';
import { inject, observer } from 'mobx-react';
import { DatePicker, Input, NumberInput, Row } from '@smartplatform/ui';
import t from 'i18n';
import File from 'img/icons/fires/card/file.svg';
import { Card, LabelField } from 'components';

@inject('card')
@observer
export class Requisites extends React.Component {
	render() {
		const { card, disabled } = this.props;
		const { fire, onChange } = card;
		return (
			<Card title={t('fire.decisionDetailsOpb')} icon={<File />}>
				<Row className='card-row'>
					<LabelField label={t('fire.date')}>
						<DatePicker value={fire.decisionDate} onChange={onChange('decisionDate')} showTimeSelect showClearButton={false} disabled={disabled} />
					</LabelField>
					<LabelField label={t('fire.protocolNumber')}>
						<NumberInput value={fire.protocolNumber} onChange={onChange('protocolNumber')} disabled={disabled} />
					</LabelField>
				</Row>
				<LabelField label={t('fire.description')}>
					<textarea rows={5} value={fire.decisionDescription || ''} onChange={onChange('decisionDescription')} />
				</LabelField>
			</Card>
		);
	}
}
