import React from 'react';
import { Field } from '@smartplatform/ui';
import t from 'i18n';
import { observer } from 'mobx-react';
import './style.scss';
import { guardHeadFireDepItem } from '../../modules/techfires/helpers';
import { formatDate } from '../../tools';
import { LabelField } from '../fields';
@observer
export default class ObjectInspectorCorrection extends React.Component {
	constructor(props) {
		super(props);
	}
	fullName = (personnel) => {
		if (personnel) {
			return (
				<div>
					{personnel.lastName} {personnel.firstName} {personnel.middleName}
				</div>
			);
		}
	};

	personnelPosition = (personnel) => {
		if (personnel.position) {
			return <div>{personnel.position.name}</div>;
		}
	};

	headOrgName = (personnel) => {
		if (personnel.fireDep && personnel.fireDep.headOrg) {
			return <div>{personnel.fireDep.headOrg.name}</div>;
		}
	};

	render() {
		const { object, inspectorEdit, inspectorCard } = this.props;
		const { manualFillCorrect, personnelFireDepCorrect, lastInspectionDateCorrect, inspectionDateCorrect } = object;
		return (
			<>
				{inspectorEdit && (
					<>
						<Field property='lastInspectionDateCorrect' label={t('techFireAddress.lastInspectionDateCorrect')} />
						<Field property='manualFillCorrect' label={t('techFireAddress.manualFill')} className='manual-fill-checkbox' />
						{manualFillCorrect && (
							<>
								<Field property='nameInspectorCorrect' label={t('techFireAddress.inspectorFullName')} />
								<Field property='positionInspectorCorrect' label={t('techFireAddress.inspectorPosition')} />
								<Field property='organizationInspectorCorrect' label={t('techFireAddress.inspectorOrganization')} />
							</>
						)}
						{!manualFillCorrect && (
							<>
								<Field relation='personnelFireDepCorrect' label={t('techFireAddress.inspectorFullName')} computed={guardHeadFireDepItem} />
								<Field relation='personnelFireDepCorrect' label={t('techFireAddress.inspectorPosition')} computed={this.personnelPosition} />
								<Field relation='personnelFireDepCorrect' label={t('techFireAddress.inspectorOrganization')} computed={this.headOrgName} />
							</>
						)}
						<Field property='inspectionDateCorrect' label={t('techFireAddress.inspectionDateCorrect')} />
						<Field property='commentInspectorCorrect' label={t('techFireAddress.commentInspector')} />
					</>
				)}
				{inspectorCard && (
					<div className='form-fields'>
						<>
							<LabelField label={t('techFireAddress.inspectionDateCorrect')} children={formatDate(lastInspectionDateCorrect) || '-'} />
							{!manualFillCorrect && personnelFireDepCorrect && (
								<>
									<LabelField label={t('techFireAddress.inspectorFullName')} children={this.fullName(personnelFireDepCorrect) || '-'} />
									<LabelField
										label={t('techFireAddress.inspectorPosition')}
										children={(personnelFireDepCorrect.position && personnelFireDepCorrect.position.name) || '-'}
									/>
									<LabelField
										label={t('techFireAddress.inspectorOrganization')}
										children={
											(personnelFireDepCorrect.fireDep && personnelFireDepCorrect.fireDep.headOrg && personnelFireDepCorrect.fireDep.headOrg.name) || '-'
										}
									/>
								</>
							)}
							{manualFillCorrect && object && (
								<>
									<LabelField label={t('techFireAddress.inspectorFullName')} children={object.nameInspectorCorrect || '-'} />
									<LabelField label={t('techFireAddress.inspectorPosition')} children={object.positionInspectorCorrect || '-'} />
									<LabelField label={t('techFireAddress.inspectorOrganization')} children={object.organizationInspectorCorrect || '-'} />
								</>
							)}
							<LabelField label={t('techFireAddress.inspectionDateCorrect')} children={formatDate(inspectionDateCorrect) || '-'} />
							<LabelField label={t('techFireAddress.commentInspector')} children={object.commentInspectorCorrect || '-'} />
						</>
					</div>
				)}
			</>
		);
	}
}
