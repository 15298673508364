import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { ReportStore } from '../store';
import t from 'i18n';
import './style.scss';
import { Row } from '@smartplatform/ui';
import { SaveButton, ReportToolbar } from 'components';
import store from 'client/store';
import { exportReport } from 'client/tools';

const CODE = 'FaultyTech';

const FIELDS = ['header', 'totalPSO', 'totalPSO', 'totalGarrison', 'sign1', 'sign2'];

@observer
export class TechFaulty extends React.Component {
	@observable fields = {
		header: '',
		dateReport: new Date(),
		totalPSO: '',
		totalGarrison: '',
		sign1: '',
		sign2: '',
	};

	constructor(props) {
		super(props);
		this.store = new ReportStore(CODE, t('techFireReport.techfaulty'), false);
		this.init();
	}

	init = async () => {
		await this.store.init();
		FIELDS.forEach((field) => (this.fields[field] = this.store.report[field] ?? ''));
	};

	onSubmit = (event) => {
		event.preventDefault();
		this.exportTechFaulty(this.fields);
	};

	onDateChange = (name) => (date) => (this.fields[name] = date);

	exportTechFaulty = async (params) => {
		const { content, filename } = await store.model.TechFireReport.getReport({
			reportCode: this.store.code,
			reportParams: params,
		});

		exportReport({ filename, content });
	};

	saveReport = async () => {
		FIELDS.forEach((field) => (this.store.report[field] = this.fields[field]));
		await this.store.report.save();
	};

	onChange = (name) => (e) => (this.fields[name] = e.target.value);

	renderInput = (name, tag = 'input', props) => {
		props = { name, autoComplete: name, value: this.fields[name], onChange: this.onChange(name), ...props };
		if (tag === 'textarea') return <textarea {...props} />;
		if (tag === 'input') return <input {...props} />;
	};

	render() {
		const { error, name } = this.store;
		const disabled = !!['header'].find((field) => !this.fields[field]);

		return (
			<form onSubmit={this.onSubmit} className='techfaulty'>
				<h2>{name}</h2>
				<div>
					<Row>
						<div className='form-field'>
							<label>Итого по 5 ПСО ФПС ГПС ГУ МЧС России по Республике Саха (Якутия)</label>
							{this.renderInput('totalPSO')}
						</div>
						<div />
					</Row>
					<Row>
						<div className='form-field'>
							<label>Итого по Якутскому пожарно-спасательному гарнизону</label>
							{this.renderInput('totalGarrison')}
						</div>
						<div />
					</Row>
					<Row>
						{['header', 'sign1', 'sign2'].map((field) => (
							<div className='form-field' key={field}>
								<label>{t('techFireReport.' + field)}</label>
								{this.renderInput(field, 'textarea', { rows: 5 })}
							</div>
						))}
					</Row>
				</div>
				<div>
					<SaveButton text={t('techFireReport.saveTemplate')} onClick={this.saveReport} className='mr-2' disabled={disabled} />
					<ReportToolbar reportCode={CODE} reportParams={this.fields} showPreview={false} />
				</div>
				{error && <span className='error'>{error}</span>}
			</form>
		);
	}
}
