import React from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import store from 'client/store';
import t from 'i18n';
import { Button, NumberInput, RecordSelect, Row, Field, Input, Popconfirm, RelationSelect } from '@smartplatform/ui';
import { AddButton } from 'components';

@observer
export class Suits extends React.Component {
	@observable suits = [];
	@observable isAdding = false;
	@observable changedValuesId = new Set();

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.suits = await this.props.vehicle.protectiveSuits.find({
			include: 'suit',
			order: 'id asc',
		});
	};

	onChange = (prop, record) => async (value) => {
		if (record.id) this.changedValuesId.add(record.id);

		if (prop === 'name') {
			record.suitId = value.id;
			record.suit = value;
		} else if (prop === 'amount') {
			record.amount = value;
		}
	};

	onSave = async (record, type) => {
		await record.save();
		this.changedValuesId.delete(record.id);
		if (type === 'add') this.isAdding = false;
	};

	cancelAdding = () => {
		this.isAdding = false;
		this.suits.shift();
	};

	onDelete = async (record, row) => {
		const index = this.suits.findIndex((hTool) => hTool.id === record.id);
		this.suits.splice(index, 1);
		await record.delete();
	};

	onAddVehicleTool = () => {
		this.isAdding = true;
		this.suits.unshift(new store.model.FireDepVehicleSuit({ vehicleId: this.props.vehicle.id }));
	};

	renderActions = (record) => {
		return record.id ? (
			<>
				<Button onClick={() => this.onSave(record)} variant='primary' disabled={!this.changedValuesId.has(record.id)}>
					{t('save')}
				</Button>
				<Popconfirm content={t('delete')} onConfirm={() => this.onDelete(record)}>
					<Button>{t('delete')}</Button>
				</Popconfirm>
			</>
		) : (
			<>
				<Button onClick={() => this.onSave(record, 'add')} variant='primary' disabled={!record.suitId || !record.amount}>
					{t('add')}
				</Button>
				<Button onClick={this.cancelAdding}>{t('cancel')}</Button>
			</>
		);
	};

	render() {
		return (
			<>
				<h1 className='title-name'>{t('fireDepVehicleSuit.title')}</h1>
				<div className='vehicle-addition suits'>
					<AddButton text={t('fireDepVehicleSuit.add')} onClick={this.onAddVehicleTool} disabled={this.isAdding} />
					{!!this.suits.length && (
						<div className='addition-items-block'>
							{this.suits.map((suit) => (
								<Row key={suit.id || 'new'}>
									<div className='form-field'>
										<RecordSelect model={store.model.ProtectiveSuit} property='name' value={suit.suit} onChange={this.onChange('name', suit)} isRequired />
									</div>
									<div className='form-field'>
										<NumberInput value={suit.amount} onChange={this.onChange('amount', suit)} />
									</div>
									{this.renderActions(suit)}
								</Row>
							))}
						</div>
					)}
				</div>
			</>
		);
	}
}
