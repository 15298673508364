import React from 'react';
import { ExportButton } from 'components';
import { Button, Loader } from '@smartplatform/ui';
import t from 'i18n';
import './style.scss';
import { inject, observer } from 'mobx-react';

@inject('store')
@observer
export default class Actionbar extends React.Component {
	render() {
		const { isHTML, disableGenerate, generateReport, showPrint, store } = this.props;
		const { downloadReport, downloadReportHTML, printReport, url, isLoading, report } = store;
		const download = isHTML ? downloadReportHTML : downloadReport;
		return (
			<div className='action-bar'>
				<Button variant='primary' onClick={generateReport} disabled={isLoading || disableGenerate}>
					{t('generate')}
					{isLoading && <Loader size={12} />}
				</Button>
				<ExportButton disabled={isLoading || !report} onClick={download} text={t('download')} />
				{!isHTML && showPrint && (
					<Button variant='primary' onClick={printReport} disabled={isLoading || !url}>
						{t('print')}
					</Button>
				)}
			</div>
		);
	}
}
