import t from 'i18n';
import { lowerFirstLetter } from 'client/tools/helpers';

//* по умолчанию функция вернет t('modelname.property'), даже если перевод там не прописан, но в таком случае попробует постучать в в t('property') и если там перевод есть, то вернет его
export const getLabelName = (property, modelName) => {
	const string = `${lowerFirstLetter(modelName)}.${property}`;
	let labelName = t(string);
	// если ничего не вернуло или та же строка , попробуем взять из корня, так же проверим что вернет перевод
	if ([undefined, string].includes(labelName) && ![undefined, property].includes(t(property))) {
		labelName = t(property);
	}
	return labelName;
};
