import React from 'react';
import { inject, observer } from 'mobx-react';
import { Select } from '@smartplatform/ui';

import t from 'i18n';
import { getYearArray } from '../../../../../../tools';

@inject('report')
@observer
export class Filters extends React.Component {
	render() {
		const { report } = this.props;

		return (
			<div className='filters'>
				<div className='row'>
					<div className='col-3'>
						<div className='form-field'>
							<label>{t('fireReport.year')}</label>
							<Select noTotal isRequired items={getYearArray()} value={report.year} onChange={report.onYearChange} />
						</div>
					</div>
				</div>
			</div>
		);
	}
}
