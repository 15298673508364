import { observable } from "mobx";
import { endOfMonth, startOfMonth } from "date-fns";

export default class KeyIndicatorsForAviationProtectionOfForestsStore {
	@observable year = new Date().getFullYear();
	@observable aviaDep = null;

	onYearChange = (year) => (this.year = year);

	onAviaDepChange = (aviaDep) => this.aviaDep = aviaDep;

	get storeParams() {
		const { year, aviaDep } = this;
		const date1 = new Date(year, 1, 1);
		const date2 = new Date(year, 12, 1);

		const startDate = startOfMonth(date1);
		const endDate = endOfMonth(date2);

		return {
			reportCode: 'KeyIndicatorsForAviationProtectionOfForests',
			reportParams: { year, startDate, endDate, aviaDep, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone },
		};
	}
}
