import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import { Button } from '@smartplatform/ui';
import Object from './Object';
import { shortenAddressName } from 'client/tools';
import store from 'client/store';
import t from 'i18n';
import './style.scss';

@observer
export default class Objects extends React.Component {
	@observable objects = [];
	@observable objectCategory = [];
	@observable selectedObject = null;
	@observable newObject = null;
	@observable editedObject = null;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.objects = await store.model.TechFireAddress.find({
			where: { addressId: this.props.address.id },
			include: [
				{ relation: 'rank', scope: { fields: ['id', 'name'] } },
				{ relation: 'category', scope: { fields: ['id', 'name'] } },
				{ relation: 'fireDep', scope: { fields: ['id', 'name'] } },
				{ relation: 'address', scope: { fields: ['id', 'name', 'geo'] } },
				{
					relation: 'personnelFireDep',
					scope: { fields: ['id', 'lastName', 'firstName', 'middleName'], relation: 'fireDep', scope: { fields: ['id'], relation: 'headOrg' } },
				},
			],
			order: 'id asc',
		});
		for (let object of this.objects) {
			if (!object.fireDepId) {
				object.fireDepId = this.props.address.fireDepId;
				await object.save();
			}
		}
		this.selectFirst();
		if (this.objects.length === 0) this.add(null, true);
	};

	add = async (e, immediatelySave = false) => {
		console.log('add: immediatelySave', immediatelySave);
		if (!immediatelySave) {
			this.editedObject =
				this.newObject =
				this.selectedObject =
					new store.model.TechFireAddress({
						addressId: this.props.address.id,
						fireDepId: this.props.address.fireDepId,
					});
		} else {
			const newObject = new store.model.TechFireAddress({
				name: `Здание ${shortenAddressName(this.props.address.name)}`,
				addressId: this.props.address.id,
				fireDepId: this.props.address.fireDepId,
			});
			await newObject.save();
			this.selectedObject = newObject;
		}
		this.objects.splice(0, 0, this.selectedObject);
	};

	onCancel = (isNew) => {
		const index = this.objects.findIndex((object) => object === this.newObject);
		if (index !== -1) this.objects.splice(index, 1);
		this.newObject = null;
		this.editedObject = null;
		if (isNew) this.selectFirst();
	};

	selectFirst = () => {
		const existingObjects = this.objects.filter((object) => !!object.id);
		this.selectedObject = existingObjects.length > 0 ? existingObjects[0] : null;
		this.objectCategory = this.selectedObject?.category();
		// if (this.selectedObject && this.props.onObjectSelect) {
		// 	this.props.onObjectSelect(this.selectedObject);
		// }
	};

	select = (record) => {
		this.selectedObject = record;
	};

	onEdit = (record) => {
		this.editedObject = record;
	};

	onSave = async (record, isNew) => {
		console.log('onSave', record, 'isNew', isNew);
		if (isNew) {
			this.newObject = null;
		}
		this.objectCategory = await record.category();
		this.selectedObject = record;
		this.editedObject = null;
	};

	onDelete = () => {
		this.editedObject = null;
		this.init();
	};

	onCreateFire = (record) => {
		store.route.push({ path: `/techfires/fires/tech-fires/create`, params: { techFire: record.id } });
	};

	render() {
		const { value, onObjectSelect, fireDep } = this.props;

		return (
			<div className='address-objects'>
				<div className='sidebar'>
					<div className='sidebar-hdr'>{t('techFireAddress.objects')}</div>
					<div className={'list' + (this.editedObject ? ' locked' : '')}>
						{this.objects.length > 0 ? (
							this.objects.map((object) => {
								const isSelected = this.selectedObject && object.id === this.selectedObject.id;
								const isSelectedValue = value && value.id === object.id;
								return (
									<div key={object.id || 'new'} className={'object-btn' + (isSelected ? ' selected' : '')} onClick={() => this.select(object)}>
										{onObjectSelect && <span className='is-selected'>{isSelectedValue && <FontAwesomeIcon icon={faCheck} />}</span>}
										{object.id ? object.name : <>{t('techFireAddress.newObject')}</>}
									</div>
								);
							})
						) : (
							<div className='empty'>{t('techFireAddress.noObjects')}</div>
						)}
					</div>
					<div className='actions'>
						<Button variant='primary' onClick={this.add} disabled={!!this.newObject || !!this.editedObject}>
							{t('add')}
						</Button>
						{/*{this.newObject && <Button onClick={this.cancel}>{t('cancel')}</Button>}*/}
					</div>
				</div>
				<div className='content'>
					{this.selectedObject && (
						<Object
							key={this.selectedObject.id || 'new'}
							object={this.selectedObject}
							category={this.objectCategory}
							onSave={this.onSave}
							onEdit={this.onEdit}
							onCancel={this.onCancel}
							onDelete={this.onDelete}
							onCreateFire={this.onCreateFire}
							value={value}
							fireDep={fireDep}
							onObjectSelect={onObjectSelect}
						/>
					)}
				</div>
			</div>
		);
	}
}
