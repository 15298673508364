import React from 'react';
import { inject, observer } from 'mobx-react';
import { format } from 'date-fns';
import { getStatusColor } from '../../helpers';
import { RowAddButton } from 'fires/components';
import { observable, runInAction } from 'mobx';
import t from 'i18n';
import { Popup } from '@smartplatform/ui';
import store from 'client/store';
import { Controls, PopconfirmFaTimesIcon, AutoAnimate } from 'components';
import { FIRE_INCLUDE } from '../constants';
import './style.scss';

@inject('card')
@observer
export class Sidebar extends React.Component {
	@observable showAddLogPopup = false;
	@observable showWarningPopup = false;

	constructor(props) {
		super(props);
		this.card = this.props.card;
		this.fireLogs = this.card.fireLogs;
	}

	changeLog = (log) => !this.checkChanged(() => this.changeLog(log)) && this.card.setFireLog(log);

	addLog = async () => {
		if (!this.checkChanged(this.addLog)) {
			const values = this.card.getFireCopyValues(this.lastLog);

			if (this.lastLog.status?.code === 'extinguished') {
				const resumedStatus = await store.model.FireStatus.find({
					where: { code: 'resumed' },
					fields: ['id'],
					limit: 1,
				});
				console.assert(resumedStatus[0], 'no resumed status in firestatus model Sidebar.js ~ line 40');
				values.statusId = resumedStatus[0]?.id || values.statusId;
			} else if (this.fireLogs.length === 1) {
				const resumedStatus = await store.model.FireStatus.find({
					where: { code: 'continues' },
					fields: ['id'],
					limit: 1,
				});
				console.assert(resumedStatus[0], 'no continues status in firestatus model Sidebar.js ~ line 48');
				values.statusId = resumedStatus[0]?.id || values.statusId;
			}

			let newLog = new store.model.FireLog({
				...values,
				fireId: this.lastLog.fireId,
				date: new Date(),
			});
			await newLog.save();
			newLog = await store.model.FireLog.findById(newLog.id, {
				include: FIRE_INCLUDE,
			});
			runInAction(() => {
				this.fireLogs.push(newLog);
				this.card.setFireLog(newLog);
			});
		}
	};

	deleteLog = async (log) => {
		const deleteLogId = log.id;
		const isSelectedFire = deleteLogId === this.card.fire.id;
		const isLastFireLog = this._fireLogs[this._fireLogs.length - 1]?.id === deleteLogId;
		await log.delete();
		const fireLogs = this.fireLogs.filter((log) => log.id !== deleteLogId);
		//* если удаляем последний файр лог , то надо триггернуть сейв предпоследнего , чтобы обновить Fire
		if (isLastFireLog) {
			const lastFireLogAfterDelete = fireLogs[fireLogs.length - 1];
			await lastFireLogAfterDelete.save();
		}
		runInAction(() => {
			this.fireLogs.length > 1 && this.fireLogs.replace(fireLogs);
			if (isSelectedFire) {
				this.card.setFireLog(this._fireLogs[this._fireLogs.length - 1]);
			}
		});
	};

	checkChanged = (fn) => {
		if (this.card.changed) {
			this.toggleWarningPopup(true);
			this.onConfirmFn = fn;
			return true;
		}
	};

	onConfirmAction = async () => {
		await this.card.dropChanges();
		this.closeWarningPopup();
		this.onConfirmFn();
		this.onConfirmFn = undefined;
	};

	get _fireLogs() {
		return this.fireLogs.sort((a, b) => new Date(a.date) - new Date(b.date));
	}

	toggleWarningPopup = (boolean) => (this.showWarningPopup = boolean);
	closeWarningPopup = () => this.toggleWarningPopup(false);
	renderDate = (log) => <div key={log.id}>{format(new Date(log.date), 'yyyy/MM/dd HH:mm')}</div>;

	render() {
		const fire = this.card.fire;
		let title = !fire.id ? (
			t('fire.new')
		) : (
			<>
				<span className='id'>#{fire.fireNumber}</span>
				<span className='county'>{fire.county?.name}</span>
			</>
		);

		this.lastLog = this._fireLogs[this._fireLogs.length - 1];

		return (
			<div className={'fire-sidebar' + (this.card.mapMaximized ? ' map-maximized' : '')}>
				<div className='title'>{title}</div>
				<AutoAnimate className='list'>
					{!this.isLoading &&
						this._fireLogs.map((log) => {
							const color = getStatusColor(log);
							return (
								<div key={log.id} className={'item' + (fire.id === log.id ? ' active' : '')} onClick={() => this.changeLog(log)} style={{ borderColor: color }}>
									<div className='date'>{this.renderDate(log)}</div>
									{this.fireLogs.length > 1 && !this.card.disabled && <PopconfirmFaTimesIcon onConfirm={() => this.deleteLog(log)} />}
								</div>
							);
						})}
				</AutoAnimate>
				<RowAddButton onClick={this.addLog} disabled={this.card.disabled || !fire.id} />
				{this.showWarningPopup && (
					<Popup onClose={this.closeWarningPopup} width={400} className='changed-fire-warning-popup fires-popup'>
						<h2>{t('dynamic.popup.message')}</h2>
						<Controls onSave={this.onConfirmAction} onCancel={this.onConfirmAction} saveTitle={t('accept')}>
							<Controls.SaveButton />
							<Controls.CancelButton />
						</Controls>
					</Popup>
				)}
			</div>
		);
	}
}
