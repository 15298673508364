export const FIREDEP_WATER_LOCAL_STORAGE_FIELDS = [
	'search',
	'garrison',
	'name',
	'type',
	'working',
	'overdueInspectionFilter',
  'dateLastCheck',
  'scheduledInspectionDate',
  'inspectionDateFrom',
  'inspectionDateTo',
];