import React from 'react';
import { inject, observer } from 'mobx-react';
import { RecordSelect, Select } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';
import { fireName } from 'fires/helpers';
import { getYearArray } from 'client/tools';

@inject('report')
@observer
export class Filters extends React.Component {
	render() {
		const className = 'form-field';
		const { report } = this.props;

		return (
			<div className='filters'>
				<div className='row'>
					<div className='col-3'>
						<div className={className}>
							<label>{t('fireReport.year')}</label>
							<Select noTotal noSearch isRequired items={getYearArray()} value={report.year} onChange={report.onYearChange} />
						</div>
						<div className={className}>
							<label>{t('forestry.title')}</label>
							<RecordSelect
								noTotal
								maxItems={10}
								itemsPerPage={200}
								model={store.model.Forestry}
								value={report.forestry}
								onChange={report.onForestryChange}
								property='name'
								className='drop-down-md'
								filter={{ fields: ['id', 'name'] }}
							/>
						</div>
					</div>
					<div className='col-3'>
						<div className={className}>
							<label>{t('forestOwnership.title')}</label>
							<RecordSelect
								noTotal
								maxItems={10}
								itemsPerPage={200}
								model={store.model.ForestOwnership}
								value={report.ownership}
								onChange={report.onOwnershipChange}
								property='name'
								className='drop-down-md'
								filter={{ fields: ['id', 'name'] }}
							/>
						</div>
						<div className={className}>
							<label>{t('fire.title')}</label>
							<RecordSelect
								model={store.model.Fire}
								value={report.fire}
								onChange={report.onFireChange}
								filter={{
									where: {
										// обнаруны между 1 января 00:00  указанного года и 1 января 0:00 следующего
										createdAt: {
											between: [new Date(parseInt(report.year), 0, 1), new Date(parseInt(report.year) + 1, 0, 1)],
										},
										forestryId: report.forestry?.id,
										forestOwnershipId: report.ownership?.id,
									},
									include: ['county'],
									fields: ['countyId', 'id', 'fireNumber'],
									order: 'fireNumber desc',
								}}
								computed={fireName}
								className='drop-down-md'
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
