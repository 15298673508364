import React from 'react';
import { MainInfo } from './MainInfo';
import { People } from './people';
import { Vehicles } from './vehicles';
import './style.scss';
import { inject } from 'mobx-react';

@inject('card')
export class Resources extends React.Component {
	render() {
		return (
			<div className='resources'>
				<div className='left' key={this.props.card.fire.id}>
					<People />
					<Vehicles />
				</div>
				<div className='right'>
					<MainInfo />
				</div>
			</div>
		);
	}
}
