import React from 'react';
import { inject, observer } from 'mobx-react';
import ExtinguishingIcon from 'img/icons/fires/dashboard/extinguishing.svg';
import { Card } from 'components';
import t from 'i18n';
import { NumberValue } from '@smartplatform/ui';

const LOADING = <>&nbsp;</>;

@inject('dashboardStore')
@observer
export default class Extinguishing extends React.Component {
	render() {
		const { dashboardStore } = this.props;
		const { current, lastYear } = dashboardStore?.data.extinguishing || {};

		return (
			<Card icon={<ExtinguishingIcon />} title={t('fireDashboard.extinguishing')} className='season'>
				<table className='dashboard-table'>
					<thead>
						<tr>
							<th />
							<th width='33%'>{t('fireDashboard.currentYear')}</th>
							<th width='33%'>{t('fireDashboard.lastYear')}</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td className='category'>{t('fireDashboard.extinguishingDaily')}</td>
							<td className='number'>{current !== undefined ? <NumberValue value={current.oneDayFraction?.toFixed(1) || 0} /> : LOADING}</td>
							<td className='number'>{lastYear !== undefined ? <NumberValue value={lastYear.oneDayFraction?.toFixed(1) || 0} /> : LOADING}</td>
						</tr>
						<tr>
							<td className='category'>{t('fireDashboard.extinguishingDuration')}</td>
							<td className='number'>{current !== undefined ? <NumberValue value={current.avgExtinguishTime?.toFixed(1) || 0} /> : LOADING}</td>
							<td className='number'>{lastYear !== undefined ? <NumberValue value={lastYear.avgExtinguishTime?.toFixed(1) || 0} /> : LOADING}</td>
						</tr>
					</tbody>
				</table>
			</Card>
		);
	}
}
