import { observable } from "mobx";

export default class InformationAboutWorkOfForestFireFormationsStore {
	@observable startDate = null;
	@observable endDate = null;

	onStartDateChange = (startDate) => this.startDate = startDate;

	onEndDateChange = (endDate) => this.endDate = endDate;

	get storeParams() {
		const { startDate, endDate} = this;
		return {
			reportCode: 'InformationAboutWorkOfForestFireFormations',
			reportParams: { startDate, endDate, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone },
			isHTML: true,
		};
	}
}
