import React from 'react';
import { observable } from 'mobx';
import { RecordSelect, Checkbox } from '@smartplatform/ui';
import icons from 'img/icons/techfires/map';
import { observer } from 'mobx-react';
import store from 'client/store';
import { FilterLayout } from 'components';
import t from 'i18n';
import './style.scss';

@observer
export default class TechFiresFilter extends React.Component {
	@observable ranks = [];
	@observable isLoading = true;
	@observable garrisonIds = null;
	@observable headOrg = {};

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.ranks = await store.model.TechFireRank.find({ order: 'id asc ' });
		this.isLoading = false;
	};

	onChange = async (rankCode, value) => {
		const { settings } = this.props.layer;
		settings.ranks[rankCode] = value;
		this.reload();
	};

	reload = async () => {
		this.isLoading = true;
		store.local.save();
		await this.props.layer.load();
		this.isLoading = false;
	};

	render() {
		const { settings } = this.props.layer;

		const legend = (
			<div className='legend'>
				{Object.keys(icons).map(
					(key) =>
						typeof icons[key] === 'object' && (
							<div className='d-flex' key={key}>
								<span>
									<img src={icons[key]?.src} alt='' height={16} />
								</span>
								<span>{icons[key].label}</span>
							</div>
						)
				)}
			</div>
		);

		const { layer } = this.props;
		const { onToggle } = layer;
		const { fireDeps, garrisons } = layer.settings.filters;

		const showFireDep = fireDeps.length > 0 ? t('fireDep.selected') + ': ' + fireDeps.length : <span className='placeholder'>{t('fireDep.select')}</span>;
		const showGarrison =
			garrisons.length > 0 ? t('techFire.selectedGarrisons') + ': ' + garrisons.length : <span className='placeholder'>{t('techFire.selectGarrison')}</span>;

		return (
			<FilterLayout layer={layer} title={t('techFire.plural')} isLoading={this.isLoading} legend={legend} className='techfire-filters'>
				{this.ranks.map((rank) => (
					<div key={rank.id}>
						<Checkbox
							value={settings.ranks[rank.level]}
							onChange={(v) => this.onChange(rank.level, v)}
							label={t('techFireRank.codes.' + rank.level)}
							disabled={!settings.show}
						/>
					</div>
				))}
				<div>
					<label>{t('garrison')}</label>
					<RecordSelect
						model={store.model.TechFireOrganization}
						computed={(organization) => organization.name}
						value={garrisons}
						onChange={(garrison) => onToggle(garrison, 'garrison')}
						showValue={showGarrison}
						filter={{ where: { isGarrison: true } }}
						disabled={!settings.show}
						width={250}
					/>
				</div>
				<div>
					<label>{t('techFire.fireDep')}</label>
					<RecordSelect
						model={store.model.ViewFireDep}
						computed={(fireDep) => fireDep.name}
						value={fireDeps}
						onChange={(fireDep) => onToggle(fireDep, 'fireDep')}
						showValue={showFireDep}
						filter={{ where: { garrisonId: { inq: garrisons.map((garrison) => garrison.id) } } }}
						disabled={!settings.show}
						width={250}
					/>
				</div>
			</FilterLayout>
		);
	}
}
