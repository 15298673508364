import React from 'react';
import { DragAndDropField, CloseButton } from 'components';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import './imageInput.scss';
@observer
export class ImageInput extends React.Component {
	@observable imageUrl = null;

	constructor(props) {
		super(props);
		this.props = props;
		if (props.__data) {
			this.record = props.__data.record;
			this.property = props.__data.property;
			this.form = props.__data.form;
		} else {
			this.record = props.record;
			this.property = props.property;
		}
		this.imageUrl = this.record[this.property] ? this.record.downloadFile(this.property) : null;
	}

	static defaultProps = {
		accept: { 'image/*': ['.jpeg', '.jpg', '.png', '.webp'] },
		height: 300,
		maxSize: 1024 * 1024 * 5,
	};

	onDrop = (files) => {
		const file = files[0];
		if (!file) return;

		this.form && this.form.setFile({ file, property: this.property, label: this.props.label });
		const reader = new FileReader();
		reader.onload = (e) => (this.imageUrl = e.target.result);
		reader.readAsDataURL(file);
	};

	onDelete = (e) => {
		e.preventDefault();
		this.imageUrl = null;
		this.record[this.property] = null;
	};

	render() {
		const { width, height } = this.props;

		return this.imageUrl ? (
			<div className='image-input' style={{ width, height }}>
				<img src={this.imageUrl} width={width} height={height} alt={this.record[this.property]} />
				<CloseButton className='clear' onClick={this.onDelete}></CloseButton>
			</div>
		) : (
			<DragAndDropField
				accept={this.props.accept}
				onDrop={this.onDrop}
				multiple={false}
				disabled={this.props.disabled}
				className='image-input'
				width={width}
				maxSize={this.props.maxSize}
				height={height}
			/>
		);
	}
}
