import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Panel } from 'components';
import appealsStore, { EVENTS } from '../store';
import store from 'client/store';
import t from 'i18n';
import './style.scss';

import facebookIcon from 'img/social/facebook.svg?url';
import instagramIcon from 'img/social/instagram.svg?url';
import okIcon from 'img/social/ok.svg?url';
// import telegramIcon from 'img/social/telegram.svg?url';
import twitterIcon from 'img/social/twitter.svg?url';
import vkIcon from 'img/social/vk.svg?url';

const icons = {
	'instagram.com': instagramIcon,
	'facebook.com': facebookIcon,
	'twitter.com': twitterIcon,
	'ok.ru': okIcon,
	'vk.com': vkIcon,
}

@observer
export default class Sources extends React.Component {

	@observable records = [];
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.init();
	}

	componentDidMount() {
		appealsStore.subscribe(EVENTS.RELOAD_ALL, this.init);
		appealsStore.subscribe(EVENTS.RELOAD_CATEGORIES, this.init);
	}

	componentWillUnmount() {
		appealsStore.unsubscribe(EVENTS.RELOAD_ALL, this.init);
		appealsStore.unsubscribe(EVENTS.RELOAD_CATEGORIES, this.init);
	}

	init = async () => {
		this.records = await store.model.AppealSource.getCounts({
			startDate: appealsStore.startDate,
			endDate: appealsStore.endDate,
			parentId: appealsStore.category ? appealsStore.category.id : null,
		});
		this.isLoading = false;
	};

	render() {
		if (this.isLoading) return null;
		return <Panel left={950} top={store.ui.sideBarBtnSize + 345} width={240} height={200} header={t(`stats.sources`)} className="sources-stats">
			{this.records
				.map(source => {
					const color = appealsStore.categoryColor(source);
					return <div key={source.id} className="item">
						<div className="icon">{icons[source.code] ? <img src={icons[source.code]} alt="" /> : <span />}</div>
						<div className="name">{source.name}</div>
						<div className="count">{source.count}</div>
					</div>;
			})}
		</Panel>
	}

}
