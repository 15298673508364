import React from 'react';
import { observer } from 'mobx-react';
import { Field, Form, RelationSelect } from '@smartplatform/ui';
import store from 'client/store';
import { observable } from 'mobx';
import GeoEdit from '../../../components/geo-edit/GeoEdit';
import t from 'i18n';
import { Attachments } from 'components';
import { MARKS_INCLUDE } from '../constants';

@observer
export default class Edit extends React.Component {
	@observable record = null;
	@observable isLoading = true;
	@observable otherGeometry = null;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.id = parseInt(this.props.match.params.id);
		if (this.id) {
			this.record = await store.model.PermafrostLevelingMark.findById(this.id, MARKS_INCLUDE);
			this.otherGeometry = this.record.object?.geo ?? null;
		} else {
			this.record = new store.model.PermafrostLevelingMark();
		}
		this.isLoading = false;
	};

	onRowClick = (record) => store.route.push({ path: `${store.route.path}/${record.id}` });

	onChangeObject = (object) => {
		this.otherGeometry = object?.geo || null;
	};

	render() {
		if (this.isLoading) return null;
		const { path } = this.props;

		return (
			<>
				<Form record={this.record} className='temperature-tube-edit' onSave={this.onSave} returnTo={path}>
					<Field property='geo' label={t('geo')}>
						<GeoEdit otherGeometry={this.otherGeometry} params={{ defaultTileSource: '2gis', zoom: 18 }} isFiltering />
					</Field>
					<Field relation='object' computed={(object) => object.address?.name || 'Без адреса'} label={t('permafrost.buildings')}>
						<RelationSelect onChange={this.onChangeObject} />
					</Field>
					<Field property='number' label={t('permafrost.number')} />
					<Field relation='type' property='name' label={t('type')} />
					<Field relation='status' property='name' label={t('status')} />
					<Field relation='heightSystem' property='name' label={t('permafrost.levelingMarks.heightSystem')} />
					<Attachments record={this.record} canUpload={true} withFormContext={!this.record.id} />
				</Form>
			</>
		);
	}
}
