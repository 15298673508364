import React from 'react';
import { inject, observer } from 'mobx-react';
import { Toolbar as BaseToolbar } from 'components';
import { RecordSelect } from '@smartplatform/ui';
import store from 'client/store';

@inject('store')
@observer
export class Toolbar extends React.Component {
	render() {
		const { onSearch, search, city, county, onChange } = this.props.store;

		return (
			<div className='storage-control-toolbar'>
				<BaseToolbar>
					<BaseToolbar.SearchIconInput value={search} onChange={onSearch} />
					<RecordSelect width={330} value={county} model={store.model.County} property='name' onChange={onChange('county')} />
					<RecordSelect width={330} value={city} model={store.model.City} filter={{ where: { countyId: county?.id } }} property='name' onChange={onChange('city')} />
				</BaseToolbar>
			</div>
		);
	}
}
