import React from 'react';
import { observer } from 'mobx-react';
import { observable } from "mobx";
import { ReportToolbar } from 'components';
import { ReportStore } from '../store';
import t from 'i18n';
import { DatePicker, RecordSelect, Row } from "@smartplatform/ui";
import store from "client/store";

const CODE = 'SummaryTableOfWorkFire';

@observer
export class SummaryTableOfWorkFire extends React.Component {
	@observable organization = null;

	constructor(props) {
		super(props);
		this.store = new ReportStore(CODE, t('techFireReport.summaryTableOfWorkFire'));
	}

	onOrgChange = (record) => {
		this.organization = record;
	}

	renderFireDeps = (fireDep) => (
		<div>
			<span className='status-color' style={{ background: fireDep.color || '#808080' }} />
			<span>{t(fireDep.name)}</span>
		</div>
	);

	render() {
		const { endDate, startDate, onEndDateChange, onStartDateChange, fireDeps, onFireDepToggle, getFireDepIds, name } = this.store;
		const { organization, onOrgChange } = this;
		const showfireDepsValue = fireDeps.length > 0 ? t('fireDep.selected') + ': ' + fireDeps.length : <span className='hint'>{t('fireDep.selectPlaceholder')}</span>;

		return (
			<div className='summary-table-of-work-fire'>
				<h2>{name}</h2>
				<Row>
					<div className='form-field'>
						<label>{t('date1')}</label>
						<DatePicker value={startDate} onChange={onStartDateChange} showClearButton={false} />
					</div>
					<div className='form-field'>
						<label>{t('date2')}</label>
						<DatePicker value={endDate} onChange={onEndDateChange} showClearButton={false} />
					</div>
				</Row>
				<Row>
					<div className='form-field'>
						<label>{t('techFireOrganization.title')}</label>
						<RecordSelect
							noTotal
							model={store.model.TechFireOrganization}
							value={organization}
							property='name'
							className='drop-down-md'
							onChange={onOrgChange}
							filter={{ where: { parentId: null } }}
						/>
					</div>
					<div className='form-field'>
						<label>{t('fireDep.title')}</label>
						<RecordSelect
							model={store.model.FireDep}
							value={fireDeps}
							onChange={onFireDepToggle}
							computed={this.renderFireDeps}
							showValue={showfireDepsValue}
							className='statuses-select'
							filter={{ fields: ['id', 'name'], where: { headOrgId: { inq: getFireDepIds(organization) } } }}
							noSearch
						/>
					</div>
				</Row>

				<ReportToolbar {...this.store.storeParams} />
			</div>
		);
	}
}
