import React from 'react';
import Root from './Root';
import t from 'i18n';
import tMap from '@smartplatform/map/i18n';
import store from 'client/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerGroup, faBoxesStacked } from '@fortawesome/free-solid-svg-icons';
import EmergencyIcon from 'img/icons/menu/emergency.svg';
import JourneyIcon from 'img/icons/sidebar/shared/journey.svg';
import PlanIcon from 'img/icons/sidebar/emergency/plan.svg';
import DictionaryIcon from 'img/icons/sidebar/shared/dictionary.svg';
import MapIcon from 'img/icons/sidebar/shared/map.svg';
import AdminIcon from 'img/icons/sidebar/shared/admin.svg';
import ContragentsIcon from 'img/icons/sidebar/shared/contragents.svg';
import AddressIcon from 'img/icons/sidebar/shared/address.svg';

export const path = '/emergency';

export default {
	name: t('module.emergency'),
	code: 'emergency',
	icon: <EmergencyIcon />,
	path,
	component: Root,
	access: () => store.model.Municipality && store.model.Municipality.INFO.WRITE,
	menu: [
		{ title: t('city.my'), path: `${path}/homecity`, icon: <AddressIcon />, visible: () => Boolean(store.model.user.cityId) },
		{ title: t('county.my'), path: `${path}/homecounty`, icon: <AddressIcon />, visible: () => Boolean(store.model.user.countyId) },
		{ title: t('emergency.map'), path: `${path}/map`, icon: <MapIcon />, exact: true },
		{ title: t('emergencySituationPlan.plural'), path: `${path}/plans`, icon: <EmergencyIcon /> },
		{ title: t('emergency.registry'), path: `${path}/municipalities`, icon: <PlanIcon /> },
		{
			title: t('operationalManagement.title_short'),
			path: `${path}/operational-management`,
			icon: <PlanIcon />,
			visible: () => store.model.OperationalManagement?.INFO.READ,
		},
		{ title: t('emergency.reserves'), path: `${path}/reserves`, icon: <FontAwesomeIcon icon={faBoxesStacked} style={{ fontSize: 26 }} /> },
		{ title: t('contragent.plural'), path: `${path}/contragents`, icon: <ContragentsIcon /> },
		{ title: t('audit.title'), path: `${path}/journal`, icon: <JourneyIcon /> },
		{ title: tMap('map.layer.plural'), path: `/layers`, icon: <FontAwesomeIcon icon={faLayerGroup} style={{ fontSize: 26 }} /> },
		{ title: t('dictionaries.plural'), path: `${path}/dictionaries`, icon: <DictionaryIcon /> },
		{ title: t('admin.titleShort'), path: `${path}/admin`, icon: <AdminIcon /> },
	].filter(Boolean),
};
