import React from 'react';
import { observer } from 'mobx-react';
import {Column, Field, Form, Input, Loader, RelationSelect, Table} from '@smartplatform/ui';
import store from 'client/store';
import { observable } from 'mobx';
import GeoEdit from '../../../components/geo-edit/GeoEdit';
import t from 'i18n';
import {Actionbar, Attachments} from 'components';
import AddIcon from 'client/img/add-btn.svg';
import EditIcon from 'client/img/icons/shared/edit.svg';
import DeleteIcon from 'client/img/icons/shared/delete.svg';
import './style.scss';
import { TUBES_INCLUDE } from '../constants';

@observer
export default class Edit extends React.Component {
	@observable record = null;
	@observable isLoading = true;
	@observable isSaving = false;
	@observable depths = [];
	@observable editRows = [];
	@observable deleteDepthIds = [];
	@observable otherGeometry = null;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.id = parseInt(this.props.match.params.id);
		if (this.id) {
			this.record = await store.model.PermafrostTemperatureTube.findById(this.id, TUBES_INCLUDE);
			this.depths = this.record.depths();
			this.otherGeometry = this.record.object?.geo ?? null;
		} else {
			this.record = new store.model.PermafrostTemperatureTube();
		}
		this.isLoading = false;
	};

	onRowClick = (record) => store.route.push({ path: `${store.route.path}/${record.id}` });

	onCreate = async () => {
		this.depths.push(new store.model.PermafrostTemperatureTubeDepths());
	};

	onEdit = ({ row }) => {
		if (!this.editRows.includes(row)) {
			this.editRows.push(row);
		} else {
			this.editRows.splice(this.editRows.indexOf(row), 1);
		}
	};

	onDelete = ({ record, row }) => {
		this.depths.splice(row, 1);
		if (record?.id) {
			this.deleteDepthIds.push(record.id);
		}
	};

	onChange = (rec) => (value) => {
		this.depths[rec.row].depth = value;
	};

	onSave = async () => {
		const { path } = this.props;
		this.isSaving = true;
		await this.record.save();
		for (const id of this.deleteDepthIds) {
			await this.record.depths.destroyById(id);
		}
		for (const depth of this.depths) {
			if (!depth.temperatureTubeId) {
				depth.temperatureTubeId = this.record.id;
			}
			await depth.save();
		}
		store.route.push({ path });
	};

	onChangeObject = (object) => {
		this.otherGeometry = object?.geo || null;
	};

	render() {
		if (this.isLoading) return null;
		const { path } = this.props;

		const controls = (
			<Actionbar>
				<Actionbar.SaveButton onSave={this.onSave} disabled={this.isSaving	} />
				{this.isNew ? null : <Actionbar.DeleteButton onDelete={() => store.route.push({ path })} />}
				{this.isSaving && <Loader/>}
			</Actionbar>
		);

		return (
			<>
				<Form record={this.record} className='temperature-tube-edit' controls={controls} disabled={this.isSaving} noSave noDelete stay>
					<Field property='geo' label={t('geo')}>
						<GeoEdit otherGeometry={this.otherGeometry} params={{ defaultTileSource: '2gis', zoom: 18 }} isFiltering />
					</Field>
					<Field relation='object' computed={(object) => object.address?.name || 'Без адреса'} label={t('permafrost.buildings')}>
						<RelationSelect onChange={this.onChangeObject} />
					</Field>
					<Field property='number' label={t('permafrost.number')} />
					<Field property='sts' label={t('permafrost.sts')} />
					<Field relation='type' property='name' label={t('type')} />
					<Field relation='maxDepth' property='name' label={t('permafrost.temperatureTube.maxDepth')} />
					<Field relation='status' property='name' label={t('status')} />
					<Field property='pile' label={t('permafrost.temperatureTube.pile')} />
					<Attachments record={this.record} canUpload={true} withFormContext={!this.record.id} />
					<Table rows={this.depths}>
						<Column>{(rec) => <div>Глубина {rec.row + 1}</div>}</Column>
						<Column property='depth' label={t('permafrost.depth')}>
							{(rec) => (this.editRows.includes(rec.row) ? <Input type='number' value={rec.record.depth} onChange={this.onChange(rec)} /> : rec.record.depth)}
						</Column>
						<Column width={40}>{(rec) => <EditIcon className='cursor-pointer' onClick={() => this.onEdit(rec)} />}</Column>
						<Column width={40}>{(rec) => <DeleteIcon className='cursor-pointer' onClick={() => this.onDelete(rec)} />}</Column>
					</Table>
					<div className='create-menu'>
						<AddIcon className='cursor-pointer' onClick={this.onCreate} />
					</div>
				</Form>
			</>
		);
	}
}
