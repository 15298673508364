import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import t from 'i18n';
import { FilterLayout } from 'components';
import IconSrc from 'img/icons/permafrost/tubeMap.svg';
import { DatePicker, Input } from '@smartplatform/ui';
import { observable } from 'mobx';

@observer
export default class PermafrostTemperatureTubeFilter extends React.Component {
	@observable id = null;
	@observable number = null;
	@observable depth = null;
	@observable startDate = null;
	@observable endDate = null;

	static propTypes = {
		layer: PropTypes.object.isRequired,
	};

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		const { id, number, depth, startDate, endDate } = this.props.layer.settings.filter;
		this.id = id;
		this.number = number;
		this.depth = depth;
		this.startDate = startDate;
		this.endDate = endDate;
	};

	onChange = (field) => (value) => {
		this[field] = value;
		this.props.layer.onLocalStoreChange(field, value);
	};

	render() {
		return (
			<FilterLayout
				title={
					<span>
						<IconSrc className='mr-1 ml-1' />
						{t('permafrost.tubeMenu')}
					</span>
				}
				{...this.props}
			>
				{' '}
				<h4>{t('id')}</h4>
				<Input value={this.id} onChange={this.onChange('id')} placeholder={t('id')} />
				<h4>{t('permafrost.number')}</h4>
				<Input value={this.number} onChange={this.onChange('number')} placeholder={t('permafrost.number')} />
				<h4>{t('permafrost.depth')}</h4>
				<Input value={this.depth} onChange={this.onChange('depth')} placeholder={t('permafrost.depth')} type='number' />
				<h4>{t('date')}</h4>
				<DatePicker placeholder={t('period.start')} onChange={this.onChange('startDate')} value={this.startDate} />
				<DatePicker placeholder={t('period.end')} onChange={this.onChange('endDate')} value={this.endDate} />
			</FilterLayout>
		);
	}
}
