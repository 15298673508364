import './style.scss';
import React from 'react';
import { Popconfirm } from 'components';
import { IconButton } from './IconButton';

export const PopconfirmButton = ({ content, onConfirm, children, disabled, ...buttonProps }) => {
	if (!children) children = <IconButton {...buttonProps} disabled={disabled} />;
	return (
		<Popconfirm content={content} onConfirm={onConfirm} disabled={disabled}>
			{children}
		</Popconfirm>
	);
};
