import { observable } from 'mobx';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { geoJSON } from '@smartplatform/map/client';
import { Icon, Style } from 'ol/style';
import { Feature } from 'ol';
import store from 'client/store';
import markerIcon from 'img/icons/shared/cityMarker.svg?url';
export class CityStore {
	@observable isLoading = true;
	@observable record;
	userCityId = store.model.user.cityId;
	userCountyId = store.model.user.countyId;
	model = store.model.City;
	@observable lon = null;
	@observable lat = null;
	id;
	path;

	constructor({ id, path }) {
		this.id = parseInt(id);
		this.path = path;
		this.init();
	}

	init = async () => {
		this.record = this.id ? await this.model.findById(this.id) : new this.model();
		this.updateLocationFields();
		this.isLoading = false;
	};

	onChangeCoords = (prop) => (value) => {
		this[prop] = value;

		if (this.lat && this.lon) {
			this.record.geo = { type: 'Point', coordinates: [this.lon, this.lat] };
		}

		this.fitGeo();
		this.source.clear();
		this.setCityMarker();
	};

	updateLocationFields = () => {
		if (this.record) {
			const [lon, lat] = this.record.geo.coordinates;
			this.lon = lon;
			this.lat = lat;
		}
	};

	onAction = () => store.route.push({ path: this.path });

	onMapInit = (mapStore) => {
		this.mapStore = mapStore;
		this.map = mapStore.map;
		this.source = new VectorSource();
		this.layer = new VectorLayer({
			format: geoJSON,
			source: this.source,
		});

		mapStore.addLayer(this.layer);
		this.fitGeo();
		this.setCityMarker();
	};

	fitGeo = () => {
		const { geo } = this.record;
		if (geo) {
			this.mapStore.fitGeo(geo, 12);
		}
	};

	setCityMarker = () => {
		const { geo } = this.record;
		const cityMarker = new Feature({ isMarker: true, geometry: geoJSON.readGeometry(geo) });
		cityMarker.setStyle(new Style({ image: new Icon({ src: markerIcon, anchor: [9, 31], anchorXUnits: 'pixels', anchorYUnits: 'pixels' }) }));
		this.source.addFeature(cityMarker);
	};

	onLayerToggle = (layers) => {
		store.local.emergency.cityLayers = layers;
		store.local.save();
	};
}
