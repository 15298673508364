import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { TechFiresList } from './list/TechFiresList';
import TechFireCard from './techfire-card/TechFireCard';
import CreateTechFire from './techfire-card/create/Create';

export const TechFires = ({ match }) => {
	const { path } = match;

	return (
		<Switch>
			<Route path={path} exact render={(props) => <TechFiresList {...props} path={path} />} />
			<Route path={`${path}/create`} exact render={(props) => <CreateTechFire {...props} path={path} />} />
			<Route path={`${path}/:id`} render={(props) => <TechFireCard {...props} path={path} />} />
		</Switch>
	);
};
