import React from 'react';
import Chart from 'chart.js';
import { Card } from 'components';
import LineChartIcon from 'img/icons/fires/dashboard/line-chart.svg';
import { inject, observer } from 'mobx-react';

@inject('store')
@observer
export default class CountFiresByStatus extends React.Component {
	constructor(props) {
		super(props);
		this.store = props.store;
		this.store.fetchDataDay();
	}

	render() {
		const { dataDay } = this.store;
		const onChart = (el) => {
			if (!dataDay.length) return;

			if (!el) {
				if (this.Chart) this.Chart.destroy();
				return;
			}

			const style = {
				borderWidth: 0,
				pointRadius: 0.5,
				barPercentage: 0.9,
				categoryPercentage: 1.0,
				// barThickness: 40,
			};

			const dataChart = {
				labels: dataDay.map((status) => ''),
				datasets: [
					{
						label: '',
						data: dataDay.map((status) => status.count),
						backgroundColor: dataDay.map((status) => status.color),
						borderColor: dataDay.map((status) => status.color),
						...style,
					},
				],
			};

			el.parentNode.scrollLeft = el.scrollWidth;

			this.Chart = new Chart(el, {
				type: 'bar',
				data: dataChart,
				options: {
					tooltips: {
						enabled: false,
					},
					hover: {
						mode: null,
					},
					responsive: true,
					maintainAspectRatio: false,
					legend: {
						display: false,
					},
					scales: {
						yAxes: [
							{
								ticks: {
									min: 0,
								},
							},
						],
					},
				},
			});
		};

		return (
			<Card icon={<LineChartIcon />} title='Количество пожаров' className='chart-count-fires'>
				{!dataDay.length ? (
					'Нет данных'
				) : (
					<>
						<canvas ref={onChart} width={640} height={300} />
						<div className='legend'>
							{dataDay.map((status, index) => {
								return (
									<div className='item-legend' key={index}>
										<span className='color' style={{ backgroundColor: status.color }} />
										{status.statusName}
									</div>
								);
							})}
						</div>
					</>
				)}
			</Card>
		);
	}
}
