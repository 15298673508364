import React from 'react';
import { observer } from 'mobx-react';
import { Form, Row, Field, Button, DatePicker, RelationSelect, Loader } from '@smartplatform/ui';
import { Map } from '@smartplatform/map/client';
import store from 'client/store';
import { TechFireAddressSearch } from 'components';
// import { AlarmAdpi, Alarm112 } from '..';
import TechFireCreationStore from './store';
import { ALARM_TYPE_ADPI, ALARM_TYPE_112 } from 'techfires/constants';
import t from 'i18n';
import './style.scss';
import { getPrefixByCountyName } from 'client/tools/getPrefixByCountyName';
import { WayBillEdit } from '../info-components/waybills';
import AlarmAdpi from '../info-components/about-person/Adpi';
import Alarm112 from '../info-components/about-person/112';

@observer
export default class CreateTechFire extends React.Component {
	constructor(props) {
		super(props);
		this.store = new TechFireCreationStore(props.path);
	}

	render() {
		const { fire, onObjectChange, onObjectReset, onMapInit, wayBillStore, getWayBillInstance, alarmType, alarmMessage, isLoading, mapInitialized } = this.store;

		if (isLoading) return <Loader />;
		if (!fire) return null;

		let fireDeps = [];
		if (wayBillStore) fireDeps = wayBillStore.fireDeps;
		let vehiclesCount = 0;
		Object.values(fireDeps).forEach(({ selectedVehicles }) => (vehiclesCount += selectedVehicles.length));
		// const isEmpty = (obj) => Object.keys(obj).length === 0;
		// const canSave = !!fire.objectId && !isEmpty(fireDeps);
		const disableManualFields = alarmType === ALARM_TYPE_ADPI || alarmType === ALARM_TYPE_112;
		return (
			<div className='techfire-create'>
				<h1>{t('fireAlarmMessage.title')}</h1>
				<div className='actions'>
					<Button onClick={() => this.store.save(false)} disabled={!fire.fireDepId} variant='primary'>
						{t('create')}
					</Button>
				</div>
				<div className='form-and-waybill'>
					<div className='edit'>
						<Form record={fire} noSave noDelete>
							<div className='form-field'>
								<label>{t('techFireAddress.title')}</label>
								<TechFireAddressSearch
									value={fire.object}
									onChange={onObjectChange}
									onReset={onObjectReset}
									prefix={getPrefixByCountyName(store.userCounty?.name)}
								/>
							</div>
							<Field property='additionalInfo' label={t('fireAlarm.info')} />
							<Row>
								<Field relation='rank' property='name' label={t('techFireAddress.rank')}>
									<RelationSelect showValue={fire.rank ? fire.rank.name : '-'} isRequired onChange={this.store.onChange('rank')} />
								</Field>
								<Field relation='fireDep' property='name' label={t('techFireAddress.fireDep')} isRequired>
									<RelationSelect showValue={fire.fireDep ? fire.fireDep.name : '-'} onChange={this.store.onFireDepChange} isRequired />
								</Field>
								<Field relation='garrison' property='name' label={t('garrison')} disabled />
							</Row>
							<Row>
								<Field relation='object' property='ptp' label={t('techFireAddress.ptp')} disabled />
								<Field relation='object' property='ktp' label={t('techFireAddress.ktp')} disabled />
							</Row>
							{/* <Row>
								<Field property='detectTime' label={t('techFireWayBill.detectTime')} disabled>
									<DatePicker showTimeSelect onChange={this.store.onChange('detectTime')} showClearButton={false} />
								</Field>
								<Field property='reportTime' label={t('techFireWayBill.reportTime')}>
									<DatePicker showTimeSelect onChange={this.store.onChange('reportTime')} showClearButton={false} />
								</Field>
								<div />
							</Row> */}
							<Row>
								<Field relation='source' property='description' label={t('techFireReportSource.title')} disabled={disableManualFields}>
									<RelationSelect value={fire.source} isRequired />
								</Field>
								<div />
								<div />
							</Row>
							{alarmMessage && alarmType === ALARM_TYPE_ADPI && <AlarmAdpi alarmMessage={alarmMessage} />}
							{alarmMessage && alarmType === ALARM_TYPE_112 && <Alarm112 alarmMessage={alarmMessage} />}
							{/*{fire.source?.code === ALARM_TYPE_PHONE && <AlarmPhone record={fire} canEdit />}*/}
							{/*{fire.source?.code === ALARM_TYPE_MANUAL && <AlarmManual record={fire} canEdit />}*/}
							<Map onInit={onMapInit} params={store.config.map} className='techfire-create-map' />
						</Form>
					</div>
					<div className='create-waybill'>{mapInitialized && <WayBillEdit {...this.props} onInit={getWayBillInstance} noSave />}</div>
				</div>
			</div>
		);
	}
}
