import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCircle } from '@fortawesome/free-solid-svg-icons';

import { Column, DateValue, Loader, Table } from '@smartplatform/ui';
import { fio } from 'client/tools';
import store from 'client/store';
import t from 'i18n';

@observer
export default class FireDepList extends React.Component {
	@observable records = [];
	@observable dispatcher = null;
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.records = await store.model.ViewFireDep.find({
			where: { garrisonId: this.props.shift.garrisonId },
			fields: ['id', 'name', 'garrisonId'],
			include: [
				{
					relation: 'shifts',
					scope: {
						limit: 1,
						where: {
							shiftId: this.props.match.params.id,
							isReserve: this.props.withoutTakingShift || null,
						},
						include: [
							{
								relation: 'dispatcher',
								scope: {
									include: [
										{ relation: 'section', scope: { fields: ['id', 'name'] } },
										{ relation: 'position', scope: { fields: ['id', 'name'] } },
									],
								},
							},
							{
								relation: 'substituteDispatcher',
								scope: {
									include: [
										{ relation: 'section', scope: { fields: ['id', 'name'] } },
										{ relation: 'position', scope: { fields: ['id', 'name'] } },
									],
								},
							},
						],
					},
				},
			],
			order: 'id asc',
		});

		const personnel = await store.model.FireDepPersonnel.find({
			where: { userId: store.model.user.id },
			include: [{ relation: 'fireDep', scope: { fields: ['id', 'name'] } }],
			limit: 1,
		});
		this.dispatcher = personnel[0] || null;
		this.isLoading = false;
	};

	rowClassName = (record) => {
		if (!this.props.isMain) {
			if (this.dispatcher && this.dispatcher.fireDepId === record.id) {
				return '';
			}
			return 'disabled';
		}
		return '';
	};

	onRowClick = (record) => {
		const withoutTakingShift = this.props.withoutTakingShift;
		const recordChoice = withoutTakingShift ? '/reserve' : '';
		store.route.push({ path: `/techfires/shifts/${this.props.match.params.id}/firedep/${record.id}${recordChoice}` });
		if (withoutTakingShift) {
			store.route.params = 'reserve';
		}
	};

	renderStatus = ({ record }) => {
		const checked = record.shifts().length > 0;
		return <FontAwesomeIcon icon={checked ? faCheck : faCircle} className={checked ? 'on-shift' : 'not-on-shift'} />;
	};

	renderStatusReserve = ({ record }) => {
		const checked = record.shifts().length > 0;
		return <FontAwesomeIcon icon={checked ? faCheck : faCircle} className={checked ? 'on-shift' : 'not-on-shift'} />;
	};

	renderShift =
		({ property, relation, computed }) =>
		({ record }) => {
			const shift = record.shifts().length > 0 ? record.shifts()[0] : null;
			if (!shift) return '-';
			if (relation) {
				if (computed) return computed(shift[relation]);
				return shift[relation][property];
			}
			if (computed) return computed(shift);
			return shift[property];
		};

	renderDispatcher =
		(field) =>
		({ record }) => {
			const shift = record.shifts().length > 0 ? record.shifts()[0] : null;
			if (!shift) return '-';

			const { dispatcher, substituteDispatcher } = shift;
			let recordDispatcher = null;
			if (dispatcher) recordDispatcher = dispatcher;
			if (substituteDispatcher) recordDispatcher = substituteDispatcher;
			if (!recordDispatcher) return '-';
			const { section, position } = recordDispatcher;

			switch (field) {
				case 'dispatcher': {
					return fio(recordDispatcher);
				}
				case 'section': {
					return section ? section.name : '-';
				}
				case 'position': {
					return position ? position.name : '-';
				}
				default: {
					return '-';
				}
			}
		};
	renderShiftDate = (shift) => (shift ? <DateValue value={shift.date} /> : '-');

	render() {
		if (this.isLoading) return <Loader />;
		const { withoutTakingShift } = this.props;
		const columnStatus = !withoutTakingShift ? (
			<Column label={t('status')} className='text-center' width={50}>
				{this.renderStatus}
			</Column>
		) : (
			<Column label={t('statusReserve')} className='text-center' width={50}>
				{this.renderStatusReserve}
			</Column>
		);

		return (
			<div className='firedep-list'>
				<Table rows={this.records} rowClassName={this.rowClassName} onRowClick={this.onRowClick}>
					{columnStatus}
					<Column property='name' label={t('name')} width={140} />
					<Column label={t('fireDepShift.date')} width={140}>
						{this.renderShift({ computed: this.renderShiftDate })}
					</Column>
					<Column label={t('fireDepShift.dispatcher')}>{this.renderDispatcher('dispatcher')}</Column>
					<Column label={t('fireDepSection.title')}>{this.renderDispatcher('section')}</Column>
					<Column label={t('fireDepPosition.title')}>{this.renderDispatcher('position')}</Column>
				</Table>
			</div>
		);
	}
}
