import { renderStatus } from 'client/tools/helpers';
import { CityEdit } from './dictionaries/city/Edit';
import { renderCityWithCounty } from '../../tools/helpers/renders/renderCityWithCounty';
const renderName = (r) => r.name;

export const CITY_DICTIONARY = {
	modelName: 'City',
	listProperties: ['name', 'population', 'createdAt', 'updatedAt'],
	listRelations: [
		{ relation: 'county', property: 'name' },
		{ relation: 'municipality', property: 'name' },
		{ relation: 'sufficiencyStatus', computed: renderStatus, properties: ['color', 'name'] },
	],
	editComponent: CityEdit,
};

export const DICTIONARIES_MODELS = [
	// ЧС
	{
		title: 'Источники воды',
		type: 'submenu',
		items: [{ modelName: 'WaterSupplyType' }, { modelName: 'WaterSupplyDivision' }],
	},
	{
		title: 'Пожарные части',
		type: 'submenu',
		items: [{ modelName: 'TechFireOrganization' }, { modelName: 'GasMask' }, { modelName: 'TechFireSentry' }],
	},
	{
		title: 'Техника',
		type: 'submenu',
		items: [
			{ modelName: 'FireDepVehicleBrand' },
			{ modelName: 'FireDepVehicleStatus' },
			{ modelName: 'FireDepVehicleFuel' },
			{
				modelName: 'FireDepVehicleType',
				listRelations: [
					{ relation: 'specialType', property: 'name' },
					{ relation: 'vehicleCode', property: 'name' },
				],
			},
			{ modelName: 'FireDepVehicleTypeCode' },
			{ modelName: 'FireDepVehicleCallSign', listRelations: [{ relation: 'fireDep', property: 'name' }] },
			{ modelName: 'Pump' },
			{ modelName: 'FoamCapacity' },
			{ modelName: 'FireDepSpecialVehicleType' },
			{ modelName: 'FireDepPowderCapacity' },
			{ modelName: 'ProtectiveSuit' },
			{ modelName: 'TechFireFoam' },
			{ modelName: 'HydraulicTool' },
			{ modelName: 'CisternBulk' },
		],
	},
	{
		title: 'Личный состав',
		type: 'submenu',
		items: [
			{ modelName: 'FireDepSection' },
			{ modelName: 'FireDepRank' },
			{
				modelName: 'FireDepPosition',
				listRelations: [{ relation: 'positionCode', property: 'name' }],
			},
			{ modelName: 'FireDepPositionCode' },
			{ modelName: 'FireDepPersonnelStatus' },
		],
	},
	//
	{
		title: 'Статусы',
		type: 'submenu',
		items: [
			{ modelName: 'MunicipalSufficiencyStatus' },
			{ modelName: 'MunicipalityStatus' },
			{ modelName: 'EmergencySituationPlanStatus' },
			{ modelName: 'EmergencySituationPlanMemberStatus' },
			{
				modelName: 'EmergencySituationPlanVehicleType',
				listProperties: ['name'],
				editProperties: ['name'],
			},
		],
	},
	{
		title: 'Оперативное управление ЧС',
		type: 'submenu',
		items: [
			{ modelName: 'OperationalManagementStatus' },
			{ modelName: 'OperationalManagementClassifier' },
			{
				modelName: 'EmergencyPerson',
				editRelations: [{ relation: 'cities', property: ['name'], filter: { include: 'county' }, computed: renderCityWithCounty }],
			},
			{
				modelName: 'EmergencyOrganization',
				editRelations: [
					{ relation: 'cities', property: ['name'], filter: { include: 'county' }, computed: renderCityWithCounty },
					{ relation: 'riskCategories', property: ['name'] },
				],
			},
		],
	},
	{
		title: 'Планирование ЧС',
		type: 'submenu',
		items: [
			{ modelName: 'RegionLevel' },
			{ modelName: 'EmergencySituationPlanMemberRole' },
			{ modelName: 'EmergencyRiskCategory' },
			{ modelName: 'EmergencySituationPlanResourceTier' },
			{ modelName: 'EmergencyDivisionGroup' },
			{
				modelName: 'EmergencyDivision',
				listRelations: [{ relation: 'group', property: 'name' }],
			},
			{
				modelName: 'WaterTransportType',
				listProperties: ['name'],
				editProperties: ['name'],
			},
		],
	},
	{
		title: 'Справочные данные слоев',
		type: 'submenu',
		items: [
			{
				modelName: 'MapLayerHelipad',
				listProperties: ['nas_punkt', 'population'],
				disabled: true,
				excludeRelations: ['group', 'layer'],
			},
			{
				modelName: 'MapLayerRoad',
				listProperties: ['name'],
				disabled: true,
				excludeRelations: ['group', 'layer'],
			},
			{
				modelName: 'RoadObject',
				listProperties: ['name'],
				editProperties: ['name', 'geo'],
			},
			{
				modelName: 'MapLayerRailway',
				listProperties: ['name', 'type'],
				disabled: true,
				excludeRelations: ['group', 'layer'],
			},
			{
				modelName: 'Airfield',
				listProperties: ['name'],
				editProperties: ['name', 'geo'],
			},
			{
				modelName: 'Aircraft',
				listProperties: ['number'],
				editProperties: ['number'],
				editRelations: [
					{ relation: 'type', property: 'name' },
					{ relation: 'contract', property: 'number' },
				],
			},
			{
				modelName: 'MapLayerPier',
				listProperties: ['name'],
				disabled: true,
				excludeRelations: ['group', 'layer'],
			},
			{
				modelName: 'MapLayerHydraulicStructure',
				listProperties: ['name'],
				disabled: true,
				excludeRelations: ['group', 'layer'],
			},
			{
				modelName: 'MapLayerGasPipeline',
				listProperties: ['name'],
				disabled: true,
				excludeRelations: ['group', 'layer'],
			},
			{
				modelName: 'MapLayerOilPipeline',
				listProperties: ['name'],
				disabled: true,
				excludeRelations: ['group', 'layer'],
			},
			{
				modelName: 'MapLayerRASCO', // есть только на проде
				listProperties: ['settlement', 'address', 'building', 'type'],
				disabled: true,
				excludeRelations: ['group', 'layer'],
			},
		],
	},
	{
		title: 'Резервы',
		type: 'submenu',
		items: [
			{
				modelName: 'NomenclatureCategory',
				listRelations: [{ relation: 'parent', property: 'name' }],
				editRelations: [{ relation: 'parent', property: 'name', filter: { where: { parentId: null } } }],
			},
			{ modelName: 'EmergencyNomenclature' },
			{ modelName: 'Unit' },
		],
	},
	//Пожарка
	{
		title: 'Справочники метеостанции',
		items: [
			{
				modelName: 'WeatherScaleValue',
			},
			{
				modelName: 'WeatherStation',
				editProperties: ['name', 'code', 'latitude', 'longitude', 'activity', 'geo'],
				editRelations: [{ relation: 'value', property: 'name' }],
			},
		],
	},
	{
		title: 'Региональные справочники',
		items: [
			{
				modelName: 'County',
				listProperties: ['name', 'population'],
				editProperties: ['name', 'population', 'geo'],
				disabled: true,
			},
			{
				modelName: 'AviaDep',
				listProperties: ['code', 'name', 'shortName', 'area', 'active', 'codeForYasen'],
				listRelations: [{ relation: 'location', property: 'name', properties: ['name'] }],
				editProperties: ['code', 'name', 'shortName', 'area', 'active', 'codeForYasen'],
				editRelations: [{ relation: 'location', computed: renderName, properties: ['name'] }],
			},
			{
				modelName: 'Forestry',
				listRelations: [{ relation: 'forestOwnership', property: 'name', properties: ['name'] }],
				editRelations: [{ relation: 'forestOwnership', computed: renderName, properties: ['name'] }],
			},
			{ modelName: 'Tenant' },
			{ modelName: 'CrewType' },
			{ modelName: 'ExtinguishingAgentsType', editRelations: [] },
			{ ...CITY_DICTIONARY },
			{ modelName: 'DiscoveryMessageType' },
			{ modelName: 'FireFightingFormation' },
		],
	},

	{
		title: 'Прочие',
		items: [
			{ modelName: 'Camera', listProperties: ['url', 'name'], editProperties: ['url', 'name', 'geo'] },
			{ modelName: 'ForestryQuarter' },
			{ modelName: 'ForestryPrecinct' },
			{ modelName: 'AircraftType' },
			{ modelName: 'NotifyRecipient' },
			{ modelName: 'NotifyRecipientGroup' },
			{ modelName: 'FireReport' },
			{ modelName: 'Airfield' },
			{ modelName: 'TechnoZone' },
			{ modelName: 'PaymentGroup' },
			{ modelName: 'EmergencySituationType' },
			{ modelName: 'ForestOwnership' },
			{ modelName: 'CombustionIntensity' },
			{ modelName: 'CombustionPattern' },
			{
				modelName: 'Aircraft',
				editProperties: ['number'],
				editRelations: [
					{ relation: 'type', computed: renderName, properties: ['name'] },
					{ relation: 'contract', computed: (r) => r.number, properties: ['active'], disabled: true },
				],
			},
			{ modelName: 'VehicleType' },
			{ modelName: 'IsdmFire' },
			{ modelName: 'ExtinguishingAgentsGroup' },
			{ modelName: 'ActivityType' },
		],
	},
	{
		title: 'Справочники налета воздушных судов',
		items: [
			{ modelName: 'AircraftKind' },
			{ modelName: 'Staff' },
			{
				modelName: 'Organization',
				listProperties: ['name', 'contractor'],
				listRelations: [
					{ relation: 'type', property: 'name' },
					{ relation: 'activityType', property: 'name' },
				],
				editProperties: ['name', 'contractor'],
				editRelations: [
					{ relation: 'type', property: 'name' },
					{ relation: 'activityType', property: 'name' },
				],
			},
			{
				modelName: 'Contract',
				listProperties: ['number', 'date', 'term', 'active', 'description'],
				listRelations: [
					{ relation: 'organization', property: 'name' },
					{ relation: 'aircraftType', property: 'name' },
					{ relation: 'aircraft', property: 'number' },
				],
				editProperties: ['number', 'date', 'term', 'active', 'description'],
				editRelations: [
					{ relation: 'organization', property: 'name' },
					{ relation: 'aircraftType', property: 'name' },
					{ relation: 'aircraft', property: 'number' },
				],
			},
		],
	},
];

/*
Добавить в ЧС

+ Районы/улусы, населенные пункты
+ Службы
+ Вертолетные площадки
+ Автомобильные дороги
- Объекты на автомобильных дорогах ?
- Техника ?
+ Железные дороги
- Воздушные судна
- Типы судов
- Причалы
- Гидротехнические сооружения - нет объектов
- Магистральные газопроводы
- Магистральные нефтепроводы
- Система оповещения
*/
