import store from 'client/store';

export default class PlanVisitCreationStore {
	constructor() {
		this.firePlanVisit = new store.model.TechFireVisit();
		this.firePlanVisit.garrisonId = store.model.user.garrisonId;
	}

	requiredFieldsFilled = (requiredFields) => {
		return requiredFields.every((item) => this.firePlanVisit[item] != null);
	};

	saveFire = async () => {
		// try {
		// 	const obj = await store.model.TechFireAddress.findById(this.firePlanVisit.objectId);
		// 	if (obj) {
		// 		this.firePlanVisit.fireDepId = obj.fireDepId;
		// 	}
		// } catch {};
		await this.firePlanVisit.save();
		store.route.push({ path: `${this.path}/${this.firePlanVisit.id}` });
	};

	saveRelocationOfTechnique = async (selectedVehicles) => {
		await this.firePlanVisit.save();

		for (let vehicleId of selectedVehicles) {
			await store.model.TechFireUnitMovement.create({
				vehicleId: vehicleId,
				fireDepFromId: this.firePlanVisit.fireDepFromId,
				techFireVisitId: this.firePlanVisit.id,
				returnedBack: false,
			});
			await store.model.FireDepVehicle.updateAll({ id: vehicleId }, { fireDepId: this.firePlanVisit.fireDepToId });
		}

		store.route.push({ path: `${this.path}/${this.firePlanVisit.id}` });
	};

	cancelFire = () => {
		store.route.push({ path: this.path });
	};

	onChange = (prop) => (value) => {
		this.firePlanVisit[prop] = value;
	};

	onObjectChange = async (object) => {
		this.errors = [];
		this.firePlanVisit.object = object;
		this.firePlanVisit.fireDep = null;
		this.fireDeps = {};
		if (object?.address) {
			const fireDep = (await object.address.getFireDep())[0];
			if (fireDep) this.firePlanVisit.fireDep = fireDep;
		}
	};

	onObjectReset = () => {
		this.firePlanVisit.object = null;
	};
}
