import {addDays, endOfDay, isWithinInterval, startOfDay} from 'date-fns';

export const getDateForShift = (date) => {
	let start = startOfDay(date);
	let end = endOfDay(date);
	if (new Date().getHours() < 8) {
		start = addDays(start, -1);
		end = addDays(end, -1);
	}
	return { start, end };
};

export const blockedRoadLineColor = (record) => {
	const currentDate = new Date();
	const dateClose = record.dateClose ? new Date(record.dateClose) : null;
	const dateOpen = record.dateOpen ? new Date(record.dateOpen) : null;

	let strokeColor = 'rgba(0, 80, 255, 0.6)';
	if (currentDate < dateClose) {
		strokeColor = 'rgb(128, 128, 128, 0.6)';
	} else if (dateClose && !dateOpen && currentDate > dateClose) {
		strokeColor = 'rgb(255, 0, 0, 0.6)';
	} else if (dateClose && dateOpen && isWithinInterval(currentDate, { start: dateClose, end: dateOpen })) {
		strokeColor = 'rgb(255, 0, 0, 0.6)';
	}

	return strokeColor;
};
