import React from 'react';
import { inject, observer } from 'mobx-react';
import { Column, Table, Pager } from '@smartplatform/ui';
import { shortenAddressName } from 'client/tools';
import { Filters } from '../filters';
import store from 'client/store';
import t from 'i18n';
import addressStore from '../store';

@inject('addressStore')
@observer
export class ObjectsList extends React.Component {
	constructor(props) {
		super(props);
		this.store = new addressStore();
		this.store.init();
	}

	onRowClick = (record) => store.route.push({ path: `/techfires/addresses/${record.addressId}` });

	shortAddress = (record) => shortenAddressName(record.addressName);

	render() {
		const { getFilters, onQueryUpdate, totalCount, onChange, page } = this.store;
		const filters = getFilters();
		const { queryFireDep } = this.props;
		if (queryFireDep) {
			filters.where.and.push(queryFireDep);
		}
		return (
			<div className='address-list'>
				<Filters noObjectCount store={this.store} />
				<Pager current={page} onChange={onChange('page')} totalCount={totalCount || 0} />
				<Table query={filters} onQueryUpdate={onQueryUpdate} model={store.model.ViewTechFireObject} onRowClick={this.onRowClick}>
					<Column property='name' label={t('name')} />
					<Column property='rank' label={t('techFireAddress.rank')} />
					<Column property='ptp' label={t('techFireAddress.ptp')} />
					<Column property='ktp' label={t('techFireAddress.ktp')} />
					<Column computed={this.shortAddress} property='addressName' label={t('address.title')} />
					<Column property='description' label={t('techFireAddress.description')} />
					<Column property='overnightStay' label={t('techFireAddress.overnightStayPeople')} />
				</Table>
			</div>
		);
	}
}
