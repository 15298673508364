import React from 'react';
import { observer, inject } from 'mobx-react';
import UsersIcon from 'img/icons/techfires/card/users.svg';
import { FieldBasedCard } from '../../../../components';

@inject('fireStore')
@observer
class Rescued extends React.Component {
	totalRescuedPeople = () => {
		const { fire } = this.props.fireStore;
		const { rescuedAdult, rescuedChildren } = fire;
		fire.rescuedPeople = parseInt(rescuedAdult || 0) + parseInt(rescuedChildren || 0);
	};

	render() {
		const { fireStore } = this.props;
		const { fire } = fireStore;

		const rows = [
			[
				{
					property: 'rescuedPeople',
					label: 'techFire.savedPeople',
					type: 'NumberInput',
					disabled: true,
				},
				{ property: 'rescuedAdult', label: 'techFire.savedAdult', type: 'NumberInput', props: { onChange: this.totalRescuedPeople } },
				{
					property: 'rescuedChildren',
					label: 'techFire.savedChildren',
					type: 'NumberInput',
					props: { onChange: this.totalRescuedPeople },
				},
				{ property: 'savedLivestock', label: 'techFire.savedLivestock', type: 'NumberInput' },
			],
		];

		return (
			<FieldBasedCard
				title='techFire.rescued'
				icon={UsersIcon}
				fields={[fire.rescuedAdult, fire.rescuedChildren, fire.savedLivestock]}
				rows={rows}
				statusKey='rescued'
				fireStore={fireStore}
				isCard={true}
			/>
		);
	}
}

export default Rescued;
