import React from 'react';
import { Checkbox, Popup } from '@smartplatform/ui';
import { inject, observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { COLUMNS } from 'fires/registry/fields';
import { Controls, AutoAnimate } from 'components';
import t from 'i18n';
import { observable } from 'mobx';
import { DOWN, UP } from 'client/constants';

@inject('store')
@observer
export class FieldsEditPopup extends React.Component {
	@observable visibleFields;
	@observable invisibleFields;

	constructor(props) {
		super(props);
		this.visibleFields = [...props.store.visibleFields];
		this.invisibleFields = [...props.store.invisibleFields];
	}

	onClose = () => this.props.store.toggleFieldsPopup(false);

	onFieldVisibleChange = (index, checked) => {
		if (checked) {
			this.visibleFields.push(this.invisibleFields[index]);
			this.invisibleFields.splice(index, 1);
		} else {
			this.invisibleFields.push(this.visibleFields[index]);
			this.visibleFields.splice(index, 1);
		}
	};

	changeFieldPosition = (index, pos) => {
		const element = this.visibleFields[index];
		const toIndex = pos === UP ? index - 1 : index + 1;
		this.visibleFields.splice(index, 1);
		this.visibleFields.splice(toIndex, 0, element);
	};

	onSave = () => {
		this.props.store.visibleFields = [...this.visibleFields];
		this.props.store.invisibleFields = [...this.invisibleFields];
		this.props.store.saveLocalStorage();
		this.props.store.compileQuery();
		this.onClose();
	};

	onCheckAll = () => {
		this.visibleFields = Object.keys(COLUMNS);
		this.invisibleFields = [];
	};

	onUncheckAll = () => {
		this.invisibleFields = Object.keys(COLUMNS);
		this.visibleFields = [];
	};

	render() {
		const store = this.props.store;
		const { onClose, onSave, onCheckAll, onUncheckAll, visibleFields, invisibleFields, onFieldVisibleChange, changeFieldPosition } = this;

		return (
			<Popup onClose={onClose} width={300} className='fires-popup registry-field-edit-popup'>
				<AutoAnimate>
					{visibleFields
						.filter((field) => !!COLUMNS[field])
						.map((item, index) => (
							<div key={item} className='popup-registry-filter'>
								<Checkbox value={true} onChange={() => onFieldVisibleChange(index, false)} label={t(COLUMNS[item].label)} />
								<div className='order'>
									<FontAwesomeIcon
										icon={faChevronUp}
										className={`order-btn ${index === 0 && 'order-btn-disabled'}`}
										onClick={() => changeFieldPosition(index, UP)}
									/>
									<FontAwesomeIcon
										icon={faChevronDown}
										className={`order-btn ${index === store.visibleFields.length - 1 && 'order-btn-disabled'}`}
										onClick={() => changeFieldPosition(index, DOWN)}
									/>
								</div>
							</div>
						))}
					{invisibleFields
						.filter((field) => !!COLUMNS[field])
						.map((item, index) => (
							<div key={index} className='popup-registry-filter'>
								<Checkbox value={false} onChange={() => onFieldVisibleChange(index, true)} label={t(COLUMNS[item].label)} />
							</div>
						))}
				</AutoAnimate>
				<div className='controls'>
					<div className='top'>
						<Controls.CleanButton onClean={onCheckAll} cleanTitle={t('checkAll')} />
						<Controls.CleanButton onClean={onUncheckAll} cleanTitle={t('uncheckAll')} />
					</div>
					<div className='bottom'>
						<Controls.SaveButton onSave={onSave} saveTitle={t('accept')} disableSave={!this.visibleFields.length} />
						<Controls.CancelButton onCancel={onClose} />
					</div>
				</div>
			</Popup>
		);
	}
}
