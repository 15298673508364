import React from 'react';
import { Route, Switch } from 'react-router-dom';

import List from './List';
import Edit from './Edit';

export default () => (
	<Switch>
		<Route path="/techfires/calls" exact render={routeProps => <List {...routeProps} />} />
		<Route path="/techfires/calls/:id" render={routeProps => <Edit {...routeProps} />} />
	</Switch>
);
