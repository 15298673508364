import { observable } from "mobx";

export default class DistributionFiresByLiquidationAreaAndExtinguishingTimeStore {
	@observable date = null;
	@observable group = null;
	@observable ownership = null;

	onDateChange = (date) => this.date = date;

	onGroupChange = (group) => this.group = group;

	onOwnershipChange = (ownership) => this.ownership = ownership;

	get storeParams() {
		const { date, group, ownership} = this;
		return {
			reportCode: 'DistributionFiresByLiquidationAreaAndExtinguishingTime',
			reportParams: { date, group, ownership, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone },
			isHTML: true,
		};
	}
}
