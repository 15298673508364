import initLocal from './localStore';
import store from 'client/store';
import { DANGER, WARNING } from 'client/constants';
import t from 'i18n';
import React from 'react';
import InfoIcon from 'img/icons/shared/info.svg';
import { observable } from 'mobx';

class TechFiresStore {
	@observable userGarrisons = [];
	@observable isInitialized = false;
	init = () => {
		initLocal();
		this.subscribeFireAlarmMessage();
		this.subscribeTechFireWayBill();
		this.fetchUserGarrison();
	};

	fetchUserGarrison = async () => {
		this.isInitialized = false;
		this.userGarrisons = await store.model.user.getGarrisons();
		this.isInitialized = true;
	};

	subscribeFireAlarmMessage = () => {
		store.socketStore.socket.on('FireAlarmMessage.id', ({ id, sourceCode, fireId }) => {
			const map = {
				ADPI: { message: t('fireAlarmMessage.newAdpiAlarm'), type: WARNING },
				112: { message: t('fireAlarmMessage.new112Alarm'), type: DANGER },
			};
			const onConfirm = () => store.route.push({ path: `/techfires/fires/tech-fires/create`, params: { notifier: id } });
			const message = (
				<div className='d-flex align-items-center'>
					<div className='mr-2'>
						<InfoIcon />
					</div>
					<div>{map[sourceCode].message}</div>
				</div>
			);
			store.ui.setNotification({ message, type: map[sourceCode].type, onConfirm, isAlarm: true });
		});
	};

	subscribeTechFireWayBill = () => {
		store.socketStore.socket.on('TechFireWayBillFireDep.id', (record) => {
			const { fireId, wayBillId, id } = record;
			const message = (
				<div className='d-flex align-items-center'>
					<div className='mr-2'>
						<InfoIcon />
					</div>
					<div>{t('fireAlarmMessage.call')}</div>
				</div>
			);
			const onConfirm = async () => {
				await store.model.TechFireWayBillFireDep.patchOrCreate({ id, acceptDate: new Date(), acceptUserId: store.model.user.id });
				store.route.push({ path: `/techfires/fires/tech-fires/${fireId}/waybills/${wayBillId}` });
			};
			store.ui.setNotification({ message, isAlarm: true, onConfirm });
		});
	};
}

export default new TechFiresStore();
