import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import store from 'client/store';
import t from 'i18n';
import PlanStore from './store';
import { Row, Button, RecordSelect, Input, Select } from '@smartplatform/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { DeleteButton } from 'components';
import { getYearArray } from 'client/tools';
import './style.scss';

@observer
export default class Plan extends React.Component {
	constructor(props) {
		super(props);
		const { id } = this.props.match.params;
		this.store = new PlanStore(id);
		this.store.path = props.path;
	}

	render() {
		const { plan, isLoading, subTypes, type, total, garrison, savePlan, cancelPlan, onChange, onChangeType, onChangeSubAmount, onChangeGarrison, deletePlan } = this.store;
		if (isLoading) return '...';

		this.hasId = plan.id ? true : false;
		this.disabledSave = !plan.year || !type;

		return (
			<>
				<Button
					onClick={() => {
						store.route.push({ path: this.props.path });
					}}
				>
					<FontAwesomeIcon icon={faArrowLeft} />
				</Button>
				<Row>
					<div className='form-field'>
						<label className='required'>{t('garrison')}</label>
						<RecordSelect
							model={store.model.TechFireOrganization}
							property='name'
							label={t('garrison')}
							disabled={!store.isAdmin}
							value={garrison}
							isRequired
							filter={{ where: { isGarrison: true } }}
							onChange={(value) => onChangeGarrison(plan)(value)}
						/>
					</div>
					<div className='form-field'>
						<label className='required'>{t('techFire.plans.year')}</label>
						<Select
							noTotal
							maxItems={10}
							itemsPerPage={1000}
							noSearch
							isRequired
							items={getYearArray()}
							value={plan.year}
							onChange={(value) => onChange('year')(value)}
						/>
					</div>
				</Row>
				<Row>
					<div className='form-field'>
						<label>{t('techFire.plans.visitType')}</label>
						<RecordSelect
							model={store.model.TechFireVisitType}
							property='name'
							value={type}
							filter={{ where: { or: [{ code: 'PTZ' }, { code: 'PTU' }] } }}
							onChange={(value) => onChangeType(plan)(value)}
							disabled={this.hasId}
						/>
					</div>
					<div className='form-field'>
						<label>{t('techFire.plans.amount')}</label>
						<Input type='number' value={total} disabled />
					</div>
				</Row>
				{subTypes.length > 0 && (
					<>
						{subTypes.map((subType) => {
							return (
								<Row key={subType.subTypeId + subType.subTypeCode}>
									<div className='form-field'>
										<Input value={subType.subTypeName} disabled />
									</div>
									<div className='form-field'>
										<Input type='number' value={subType.subTypeAmount} onChange={(value) => onChangeSubAmount(subType)(value)} />
									</div>
								</Row>
							);
						})}
					</>
				)}
				<div className='main-actions'>
					<Button className='button' onClick={savePlan} variant='primary' disabled={this.disabledSave}>
						{t('save')}
					</Button>
					<Button className='button' onClick={cancelPlan}>
						{t('cancel')}
					</Button>
					{this.hasId && <DeleteButton onConfirm={deletePlan} />}
				</div>
			</>
		);
	}
}
