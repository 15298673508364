import { observable } from 'mobx';
import initLocal from './localStore';
import store from 'client/store';

class DeliveryStore {
	@observable isInitialized = false;
	periodData;

	init = async (options) => {
		initLocal();
		this.periodData = await store.model.RequestAvailablePeriod.getPeriodData();
		this.isInitialized = true;
	};
}

export default new DeliveryStore();
