import React from 'react';
import { Fill, Stroke, Style, Icon, Circle as CircleStyle } from 'ol/style';
import { asArray } from 'ol/color';
import { ColorUtils } from '@smartplatform/ui';

import ForestryQuarterPopup from './ForestryQuarterPopup';
import icon from 'client/img/icons/airfield.svg?url';
import t from 'i18n';

const color = '#0af';

export default {
	modelName: 'ForestryQuarter',
	title: t('forestryQuarter.title'),
	filterTitle: t('forestryQuarter.plural'),
	showInFilters: false,
	style: new Style({
		fill: new Fill({ color: asArray([...ColorUtils.hexToArray(color).slice(0, 3), 0.5]) }),
		stroke: new Stroke({ color, width: 1 }),
		image: new Icon({ src: icon, anchor: [9, 31], anchorXUnits: 'pixels', anchorYUnits: 'pixels' }),
	}),
	icon,
	priority: 3,
	renderPopup: record => <ForestryQuarterPopup key={record.id} record={record} />,
};
