import React from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import { Table, Column } from '@smartplatform/ui';
import { fireDepVehicleItem } from 'techfires/helpers';
import t from 'i18n';
import { Toolbar } from 'components';

@inject('sentryStore') @observer
export default class Personnel extends React.Component {
	@observable search = '';

	constructor(props) {
		super(props);
		this.store = props.sentryStore;
	}

	onSearch = (e) => {
		this.search = e.target.value;
	};

	render() {
		const { fireDep, renderChecked } = this.store;

		let personnel = fireDep.personnel();

		personnel = personnel.filter((p) => {
			const { lastName, firstName, middleName } = p;
			return [lastName, firstName, middleName].some((word) => word?.toLowerCase().includes(this.search.toLowerCase()));
		});

		return <div>
			<div className='mb-3'>
				<Toolbar>
					<Toolbar.SearchIconInput value={this.search} onChange={this.onSearch} />
				</Toolbar>
			</div>
			<Table rows={personnel}>
				<Column label={<FontAwesomeIcon icon={faCheck} />} width={30}>{renderChecked('personnel')}</Column>
				<Column property="id" label={t('id')} width={50} />
				<Column property="lastName" label={t('lastName')} />
				<Column property="firstName" label={t('firstName')} />
				<Column property="middleName" label={t('middleName')} />
				<Column relation="status" property="name" label={t('fireDepPersonnel.status')} />
				<Column relation="vehicle" computed={fireDepVehicleItem} width={260} label={t('fireDepVehicle.title')} />
			</Table>
		</div>;
	}
}
