// TechFire rank color
const DEFAULT_COLOR = '#808080';
export const getRankColor = (record = {}) => {
	return record.rank?.color || DEFAULT_COLOR;
};

// Visits type color
const colorPTZ = '#ef4444';
const colorPTU = '#33ce65';
const colorOtherTypes = '#00AAFF';
export const getTypeColor = (record = {}) => {
	if (record.visitCode === 'PTZ') {
		return colorPTZ;
	} else if (record.visitCode === 'PTU') {
		return colorPTU;
	} else {
		return colorOtherTypes;
	}
};
