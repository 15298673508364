import React from 'react';
import { Tab, Tabs } from '@smartplatform/ui';
import t from 'i18n';
import { Redirect, Route } from 'react-router-dom';
import Note from 'techfires/note/main/Note';
import SettingForm from 'techfires/note/template-setting/SettingForm';

export default ({ match }) => {
	const { path } = match;
	return (
		<>
			<Route path={path} exact>
				<Redirect to={`${path}/drill-note`} />
			</Route>
			<Tabs>
				<Tab path={`${path}/drill-note`} title={t('fireDepNote.title')} render={(props) => <Note {...props} />} />
				<Tab path={`${path}/template-setting`} title={t('settings')} render={(props) => <SettingForm {...props} />} />
			</Tabs>
		</>
	);
};
