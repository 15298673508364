import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import t from 'i18n';
import { FilterLayout } from 'components';
import IconSrc from 'img/icons/permafrost/objectMap.svg';
import { Input, RecordSelect } from '@smartplatform/ui';
import store from 'client/store';
import { observable } from 'mobx';

@observer
export default class PermafrostObjectFilter extends React.Component {
	@observable quarterId = null;
	@observable operatingOrganizationId = null;
	@observable name = null;

	static propTypes = {
		layer: PropTypes.object.isRequired,
	};

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		const { quarterId, operatingOrganizationId, name } = this.props.layer.settings.filter;
		this.quarterId = quarterId ? await store.model.PermafrostObjectQuarter.findById(quarterId, { fields: ['id', 'name'] }) : null;
		this.operatingOrganizationId = quarterId ? await store.model.PermafrostOrganization.findById(operatingOrganizationId, { fields: ['id', 'name'] }) : null;
		this.name = name;
	};

	onChange = (field) => (value) => {
		this[field] = value;
		this.props.layer.onLocalStoreChange(field, value);
	};

	render() {
		return (
			<FilterLayout
				title={
					<span>
						<IconSrc className='mr-1 ml-1' />
						{t('permafrost.objectsMenu')}
					</span>
				}
				{...this.props}
			>
				<h4>{t('permafrost.objects.quarter')}</h4>
				<RecordSelect
					model={store.model.PermafrostObjectQuarter}
					filter={{ fields: ['id', 'name'] }}
					onChange={this.onChange('quarterId')}
					value={this.quarterId}
					property='name'
					empty={t('all')}
				/>
				<h4>{t('permafrost.objects.operatingOrganization')}</h4>
				<RecordSelect
					model={store.model.PermafrostOrganization}
					filter={{ fields: ['id', 'name'] }}
					onChange={this.onChange('operatingOrganizationId')}
					value={this.operatingOrganizationId}
					property='name'
					empty={t('all')}
				/>
				<h4>{t('permafrost.objects.name')}</h4>
				<Input value={this.name} onChange={this.onChange('name')} placeholder={t('permafrost.objects.name')} />
			</FilterLayout>
		);
	}
}
