import { observable } from 'mobx';
import store from 'client/store';
import debounce from 'lodash/debounce';

export default class TemperatureTubeStore {
	@observable query = null;
	@observable search = '';
	@observable querySearch = '';
	@observable maxTempColumn = 0;
	@observable depths = [];
	@observable measurementCount = 0;

	@observable order = 'id desc';
	@observable page = 1;
	@observable totalCount = 0;
	@observable perPage = 15;

	constructor() {
		this.doSearch = debounce(this.doSearch, 500, { leading: false, trailing: true });
		this.init();
	}

	init = async () => {
		this.compileQuery();
		this.measurementCount = (await store.model.PermafrostTemperatureTubeMeasurements.find({ fields: ['id'], limit: 1 })).totalCount;
	};

	doSearch = () => {
		this.querySearch = this.search;
		this.compileQuery();
	};

	onSearch = (e) => {
		this.page = 1;
		this.search = e.target.value;
		this.doSearch();
	};

	compileQuery = () => {
		const { querySearch } = this;
		const query = {
			order: this.order,
			limit: this.perPage,
			skip: (this.page - 1) * this.perPage,
			include: [{ relation: 'owner', scope: { fields: ['id', 'lastName', 'firstName', 'middleName'] } }, 'depths'],
		};
		if (querySearch.length) query.search = querySearch;
		if (this.query?.where) query.where = this.query.where;
		this.query = query;
	};

	onLoadEnd = async (records) => {
		this.maxTempColumn = 0;
		if (records.length) {
			const measurementsIds = records.map((x) => x.measurementsId);
			this.depths = await store.model.ViewPermafrostTemperatureTube.getDepthValues({ measurementsIds });
			this.maxTempColumn = Math.max(...this.depths.map((x) => x.depthvalues?.length ?? 0));
		}
	};

	onQueryUpdate = (query) => {
		this.query = query;
		this.totalCount = query._totalCount;
		this.order = query.order;
	};

	onChange = (props) => (value) => {
		this.page = 1;
		this[props] = value;
		this.compileQuery();
	};

	onRowClick = (record) => store.route.push({ path: `${store.route.path}/${record.id}` });
}
