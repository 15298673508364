import React from 'react';
import { inject, observer } from 'mobx-react';
import { DatePicker, Row } from '@smartplatform/ui';
import t from 'i18n';
import CalendarIcon from 'img/icons/fires/card/calendar.svg';
import { Card, LabelField } from 'components';

@inject('card')
@observer
export class Dates extends React.Component {
	render() {
		const { card, disabled } = this.props;
		const dateProps = { showTimeSelect: true, disabled, showClearButton: false };
		return (
			<Card title={t('date')} icon={<CalendarIcon />}>
				<Row className='card-row'>
					<LabelField label={t('fire.detectDate')}>
						<DatePicker value={card.fire.detectDate} onChange={card.onChange('detectDate')} autoSwitch={false} {...dateProps} />
					</LabelField>
					<LabelField label={t('fire.reportDate')}>
						<DatePicker value={card.fire.reportDate} onChange={card.onChange('reportDate')} {...dateProps} />
					</LabelField>
					<LabelField label={t('fire.startDate')}>
						<DatePicker value={card.fire.startDate} onChange={card.onChange('startDate')} {...dateProps} />
					</LabelField>
					<div />
				</Row>
			</Card>
		);
	}
}
