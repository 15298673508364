import React from 'react';
import { inject, observer } from 'mobx-react';
import { Select } from '@smartplatform/ui';
import { getYearArray } from 'client/tools';

import t from 'i18n';
import { REPORT_TYPES } from './store';

@inject('report')
@observer
export class Filters extends React.Component {
	render() {
		const { report } = this.props;

		return (
			<div className='filters'>
				<div className='row'>
					<div className='col-3'>
						<div className='form-field'>
							<label>{t('fireReport.year')}</label>
							<Select noTotal isRequired items={getYearArray()} value={report.year} onChange={report.onYearChange} />
						</div>
					</div>
					<div className='col-3'>
						<div className='form-field'>
							<label>{t('report.type')}</label>
							<Select
								noTotal
								maxItems={10}
								itemsPerPage={1000}
								noSearch
								isRequired
								items={REPORT_TYPES}
								value={report.reportType}
								onChange={report.onReportTypeChange}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
