export const TECHFIRE_ADDRESS_LOCAL_STORAGE_FIELDS = [
	'name',
	'addressName',
	'descriptionSearch',
	'addressSearch',
	'objectsCount',
	'description',
	'ktp',
	'ptp',
	'overnightStay',
	'overduePTPCTP',
	'workingOutPTPCTP',
	'date0',
	'date1',
	'date2',
	'date3',
	'description',
	'nameSearch',
	'rank',
	'objectsCountSearch',
];
