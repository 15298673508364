import store from 'client/store';
import { ERROR, SUCCESS, WARNING } from './constants';
import React from 'react';
import t from 'i18n';

export const triggerSaveNotification = () => store.ui.setNotification({ message: t('record.saved'), type: SUCCESS });
export const triggerDeleteNotification = () => store.ui.setNotification({ message: t('record.deleted'), type: WARNING });
export const triggerErrorNotification = (errorMessage) =>
	store.ui.setNotification({
		message: (
			<div>
				{t('error')}: {errorMessage}
			</div>
		),
		type: ERROR,
	});
export const triggerWarningNotification = (warningMessage) =>
	store.ui.setNotification({
		message: <div>{warningMessage}</div>,
		type: WARNING,
	});
