import { CleanButton as _CleanButton } from 'components';
import { ControlsContext } from './Controls';
import { cleanInputsValues } from 'client/tools';
import React from 'react';
import t from 'i18n';

export const CleanButton = (props) => {
	const context = { ...React.useContext(ControlsContext), ...props };
	const { form, cleanTitle, onClean, noClean, formElementRef } = context;
	if (noClean) return null;

	const _onClean = async () => {
		if (form) {
			await form.reset();
		}

		if (formElementRef) {
			cleanInputsValues({ formElement: formElementRef.current });
		}
		onClean?.();
	};

	return <_CleanButton onClick={_onClean} text={cleanTitle || t('clean')} />;
};
