import React from 'react';
import { Field, Row } from '@smartplatform/ui';
import t from '../../../i18n';
import { formatDate } from '../../tools';
import { LabelField } from '../fields';

export default class ObjectOvernightStay extends React.Component {
	render() {
		const { overnightCard, overnightEdit, object, totalPeopleOvernightStay } = this.props;
		return (
			<>
				{overnightCard && (
					<div className='form-fields'>
						<div className='form-field'>
							<label>{t('techFireAddress.status')}</label>
							{t('techFireAddress.overnightStayPeople')}
						</div>
						<LabelField label={t('techFireAddress.organizationName')} children={object.organizationName || '-'} />
						<LabelField label={t('techFireAddress.contactInfo')} children={object.contactInfo || '-'} />
						<LabelField label={t('techFireAddress.totalPeople')} children={object.totalPeople || '-'} />
						<Row>
							<LabelField label={t('techFireAddress.adults')} children={object.adults || '-'} />
							<LabelField label={t('techFireAddress.children')} children={object.children || '-'} />
						</Row>
						<LabelField label={t('techFireAddress.unableMoveIndependently')} children={object.unableMoveIndependently || '-'} />
						<Row>
							<LabelField label={t('techFireAddress.courtship')} children={object.courtship || '-'} />
							<LabelField label={t('techFireAddress.personnel')} children={object.personnel || '-'} />
						</Row>
						<LabelField label={t('techFireAddress.whoTransmittedMessage')} children={object.whoTransmittedMessage || '-'} />
						<LabelField label={t('techFireAddress.actualDate')} children={formatDate(object.actualDate, 'dd.MM.yyyy') || '-'} />
					</div>
				)}
				{overnightEdit && (
					<div className='overnight-stay-people-fields'>
						<Field property='organizationName' label={t('techFireAddress.organizationName')} />
						<Field property='contactInfo' label={t('techFireAddress.contactInfo')} />
						<Field property='totalPeople' label={t('techFireAddress.totalPeople')} computed={totalPeopleOvernightStay} disabled />
						<Row>
							<Field property='adults' label={t('techFireAddress.adults')} />
							<Field property='children' label={t('techFireAddress.children')} />
						</Row>
						<Field property='unableMoveIndependently' label={t('techFireAddress.unableMoveIndependently')} />
						<Row>
							<Field property='courtship' label={t('techFireAddress.courtship')} />
							<Field property='personnel' label={t('techFireAddress.personnel')} />
						</Row>
						<Field property='whoTransmittedMessage' label={t('techFireAddress.whoTransmittedMessage')} />
						<Row>
							<Field property='actualDate' label={t('techFireAddress.actualDate')} />
							<div />
						</Row>
					</div>
				)}
			</>
		);
	}
}
