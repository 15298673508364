import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { ColorUtils, Loader } from '@smartplatform/ui';
import store from 'client/store';
import appealsStore, { EVENTS } from '../store';
import Legend from './Legend';

@observer
export default class Category extends React.Component {

	@observable categories = [];
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.init();
	}

	componentDidMount() {
		appealsStore.subscribe(EVENTS.RELOAD_ALL, this.reload);
	}

	componentWillUnmount() {
		appealsStore.unsubscribe(EVENTS.RELOAD_ALL, this.reload);
	}

	init = async () => {
		this.categories = await store.model.ViewAppealCategory.getFiltered({
			startDate: appealsStore.startDate,
			endDate: appealsStore.endDate,
			parentId: this.props.category.id,
			systemId: appealsStore.system.id,
		});
		this.isLoading = false;
	};

	getData = () => {
		const sum = this.categories.map(c => parseInt(c.appealscount)).reduce((a, b) => a + b, 0);

		const datasets = [
			{
				data: this.categories.map(category => category.appealscount),
				label: this.props.category.name,
				borderWidth: this.categories.map((category, i) => {
					const percent = category.appealscount * 100 / sum;
					return percent < 3 ? percent / 10 : 1;
				}),
				borderColor: '#fff',
				backgroundColor: this.categories.map(appealsStore.categoryColor),
				hoverBackgroundColor: '#0af',
				hoverBorderWidth: 3,
			},
		];

		const labels = this.categories.map(category => category.name);

		return { datasets, labels };
	};

	reload = async () => {
		if (this.chart) {
			await this.init();
			const { datasets, labels } = this.getData();
			this.chart.data.datasets = datasets;
			this.chart.data.labels = labels;
			this.chart.update();
		}
	};

	onMount = el => {
		this.el = el;
		if (el) {
			const { datasets, labels } = this.getData();

			this.chart = new Chart(el, {
				type: 'doughnut',
				data: { labels, datasets },
				options: {
					title: false,
					responsive: true,
					legend: false,
					cutoutPercentage: 33,
					animation: {
						duration: 0
					},
				},
			});
		}
	}

	render() {
		if (this.isLoading) return <Loader />;

		const { width, height } = this.props;
		return <>
			<canvas style={{ margin: '10px 0' }} width={width} height={height} ref={this.onMount} />
			<Legend data={this.categories.map(c => ({ id: c.id, name: c.name, count: c.appealscount }))} />
		</>;
	}

}
