import React from 'react';
import { ReportStore } from 'techfires/reports/store';
import t from 'i18n';
import { DatePicker } from '@smartplatform/ui';
import { ReportToolbar } from 'components';
import { observer } from 'mobx-react';

const CODE = 'InfoWorkFireProtectionUnitsAnalysis';

@observer
export class InfoWorkFireProtectionUnitsAnalysis extends React.Component{
	constructor(props) {
		super(props);
		this.store = new ReportStore(CODE, t('techFireReport.infoWorkFireProtectionUnitsAnalysis'));
	}

	render(){
		const { endDate, startDate, onEndDateChange, onStartDateChange, name } = this.store;

		return (
			<div className='info-work-fire-protection-units-analysis'>
				<h2>{name}</h2>
				<div className='filter'>
					<div className='form-field'>
						<label>{t('date1')}</label>
						<DatePicker value={startDate} onChange={onStartDateChange} showClearButton={false} />
					</div>
					<div className='form-field'>
						<label>{t('date2')}</label>
						<DatePicker value={endDate} onChange={onEndDateChange} showClearButton={false} />
					</div>
				</div>
				<ReportToolbar {...this.store.storeParams} />
			</div>
		);

	}
}