import { defaultsDeep } from 'lodash';
import { toJS } from 'mobx';
import store from 'client/store';

const layers = {
	permafrostObject: {
		show: false,
		minimized: false,
		filter: {
			quarterId: null,
			operatingOrganizationId: null,
			name: null,
		},
	},
	permafrostTemperatureTube: {
		show: false,
		minimized: false,
		filter: {
			number: null,
			id: null,
			depth: null,
			startDate: null,
			endDate: null,
		},
	},
	permafrostLevelingMark: {
		show: false,
		minimized: false,
		filter: {
			number: null,
			id: null,
			startDate: null,
			endDate: null,
		},
	},
};

const defaultConfig = {
	tileSource: '2gis',
	filtersMaximized: false,
	layers,
	dynamicLayers: [],
};

export default function initLocal() {
	store.local.permafrost = defaultsDeep(toJS(store.local.permafrost), defaultConfig);
	store.local.save();
}
