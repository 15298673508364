import { observable } from 'mobx';
import store from 'client/store';
import { MODEL_NAME, RESERVES_INCLUDE } from '../constants';

export class ReserveStore {
	@observable record = null;
	@observable isLoading = true;
	id;

	constructor() {}
	get isNew() {
		return !this.id;
	}

	init = async () => {
		await this.fetchRecord();
	};

	getFactClass = () => {
		const { fact, norma } = this.record;
		const dangerStyle = 'danger';
		const normalStyle = 'normal';

		if (isFinite(fact) && isFinite(norma)) {
			if (fact < norma) return dangerStyle;
			if (fact >= norma) return normalStyle;
		}
		return '';
	}

	fetchRecord = async () => {
		this.isLoading = true;
		if (this.id) {
			this.record = await store.model[MODEL_NAME].findById(this.id, RESERVES_INCLUDE);
		} else this.createNewRecord();
		this.isLoading = false;
	};
	onChange = (prop) => (value) => {
		this.record[prop] = value;
		this.record.nomenclature = null;
	};
}
