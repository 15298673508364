import React from 'react';
import { inject, observer } from 'mobx-react';
import { DatePicker, RecordSelect, Select } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';
import { GROUPS } from './store';

@inject('report')
@observer
export class Filters extends React.Component {
	render() {
		const className = 'form-field';
		const { report } = this.props;

		return (
			<div className='filters'>
				<div className='row'>
					<div className='col-3'>
						<div className={className}>
							<label>{t('date')}</label>
							<DatePicker value={report.date} onChange={report.onDateChange} showTimeSelect={false} showClearButton={false} />
						</div>
					</div>
					<div className='col-3'>
						<div className={className}>
							<label>{t('forestOwnership.title')}</label>
							<RecordSelect
								noTotal
								maxItems={10}
								itemsPerPage={200}
								model={store.model.ForestOwnership}
								value={report.ownership}
								onChange={report.onOwnershipChange}
								property='name'
								className='drop-down-md'
							/>
						</div>
					</div>
					<div className='col-3'>
						<div className={className}>
							<label>{t('fireReport.groupBy')}</label>
							<Select noTotal maxItems={10} itemsPerPage={1000} noSearch isRequired items={GROUPS} value={report.group} onChange={report.onGroupChange} />
						</div>
					</div>
				</div>
			</div>
		);
	}
}
