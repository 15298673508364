import { observable } from "mobx";
import {endOfMonth, startOfMonth} from "date-fns";

export default class InformationAboutTheWorkOfGroundFireDepartmentsStore {
	@observable year = new Date().getFullYear();

	onYearChange = (year) => (this.year = year);

	get storeParams() {
		const { year } = this;
		const date1 = new Date(year, 1, 1);
		const date2 = new Date(year, 12, 1);

		const startDate = startOfMonth(date1);
		const endDate = endOfMonth(date2);

		return {
			reportCode: 'InformationAboutTheWorkOfGroundFireDepartments',
			reportParams: { year, startDate, endDate, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone },
			isHTML: true,
		};
	}
}
