import React from 'react';
import { observer } from 'mobx-react';
import { TextField } from 'fires/components';
import t from 'i18n';
import { observable } from 'mobx';
import store from 'client/store';

@observer
export class OperationalManagementPopup extends React.Component {
	@observable operationalManagement;
	@observable object;

	componentDidMount = async () => {
		this.object = await store.model.OperationalManagementObject.findById(this.props.record.id, {
			include: [{ relation: 'operationalManagement' }],
		});

		this.operationalManagement = this.object.operationalManagement;
	};

	go = () => store.route.push({ path: `/emergency/operational-management/${this.operationalManagement?.id}` });

	render() {
		const { noPopupLink } = this.props;
		if (!this.operationalManagement) return;
		return (
			<div>
				<div className='title'>
					<h2>
						{t(`operationalManagement.title`)}:{' '}
						{noPopupLink ? (
							<span>#{this.operationalManagement.id}</span>
						) : (
							<a style={{ cursor: 'pointer' }} onClick={this.go}>
								#{this.operationalManagement.id}
							</a>
						)}
					</h2>
				</div>

				<TextField label={t('operationalManagement.name')} value={this.operationalManagement.name} />
				<TextField label={t('operationalManagementObject.name')} value={this.object.name} />
			</div>
		);
	}
}
