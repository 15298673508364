import React from 'react';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { DynamicLayers } from '@smartplatform/map/client';
import store from 'client/store';
import t from '@smartplatform/ui/src/i18n';
import tMap from '@smartplatform/map/i18n';
import DictionaryIcon from 'img/icons/sidebar/shared/dictionary.svg';

const isActive = (path) => path !== '/layers/full';

@observer
export default class MapLayers extends React.Component {
	componentDidMount() {
		store.module = {
			path: '/layers',
			menu: [
				{ title: tMap('map.layer.plural'), path: `/layers`, icon: <FontAwesomeIcon icon={faLayerGroup} style={{ fontSize: 26 }} />, isActive },
				{ title: t('map.fullTable'), path: `/layers/full`, icon: <DictionaryIcon /> },
			],
		};
	}

	render() {
		return (
			<div className='full-container'>
				<DynamicLayers {...this.props} store={store} />
			</div>
		);
	}
}
