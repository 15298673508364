import React from 'react';
import { Card } from 'components';
import { NumberInput } from '@smartplatform/ui';
import Xarrow from 'react-xarrows';
import t from 'i18n';
import { inject, observer } from 'mobx-react';
import MarkerPinIcon from 'img/icons/fires/card/marker-pin-04.svg';

@inject('card')
@observer
export class AreaTenant extends React.Component {
	render() {
		const { card } = this.props;
		const { fire, disabled } = card;
		return (
			<Card title={t('fire.leasedArea')} className='area-tenant' icon={<MarkerPinIcon />}>
				<div className='squares-block'>
					{['upperArea', 'coverArea', 'soilArea', 'forestArea', 'area', 'nonCoverArea', 'nonForestArea'].map((label, i) => (
						<div className={`form-field ar-box${i}`} key={label}>
							<label>{t(`fire.${label}Short`)}</label>
							<NumberInput
								positiveOnly
								decimalScale={2}
								id={`ar-box${i}`}
								value={fire[label + 'Ar'] || null}
								onChange={card.calcManualAreaAr(label + 'Ar')}
								disabled={disabled}
							/>
						</div>
					))}
				</div>

				<div className='connection-lines'>
					{[
						[0, 1],
						[2, 1],
						[1, 3],
						[5, 3],
						[6, 4],
						[3, 4],
					].map((el, index) => (
						<Xarrow key={index} start={'ar-box' + el[0]} end={'ar-box' + el[1]} showHead={false} path={'grid'} color={'#37b813'} />
					))}
				</div>
			</Card>
		);
	}
}
