import React from 'react';
import t from 'i18n';
import Root from './Root';
import store from 'client/store';
import CovidIcon from 'img/icons/menu/covid.svg';
import DictionaryIcon from 'img/icons/sidebar/shared/dictionary.svg';
import MapIcon from 'img/icons/sidebar/shared/map.svg';
import RegistryIcon from 'img/icons/sidebar/shared/registry.svg';
import AdminIcon from 'img/icons/sidebar/shared/admin.svg';

export default {
	name: t('module.covid'),
	code: 'covid',
	icon: <CovidIcon />,
	path: '/covid',
	component: Root,
	access: () => store.model.Case && store.model.Case.INFO.WRITE,
	menu: [
		{ title: 'Карта', path: '/covid', icon: <MapIcon />, exact: true },
		{ title: 'Реестр', path: '/covid/cases', icon: <RegistryIcon /> },
		{ title: t('dictionaries.plural'), path: `/covid/dictionaries`, icon: <DictionaryIcon /> },
		{ title: t('admin.titleShort'), path: `/appeals/admin`, icon: <AdminIcon /> },
	],
};
