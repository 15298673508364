import { observable } from "mobx";
import { endOfMonth, startOfMonth } from "date-fns";
import t from 'i18n';

export const REPORT_TYPES = [
	{ label: t('report.first'), value: 'first' },
	{ label: t('report.last'), value: 'last' },
];


export default class KeyIndicatorsForAviationProtectionOfForestsStore {
	@observable reportType = REPORT_TYPES[0].value;
	@observable year = new Date().getFullYear();

	onYearChange = (year) => (this.year = year);
	onReportTypeChange = (type) => (this.reportType = type);

	get storeParams() {
		const { year, reportType } = this;
		const date1 = new Date(year, 1, 1);
		const date2 = new Date(year, 12, 1);

		const startDate = startOfMonth(date1);
		const endDate = endOfMonth(date2);

		return {
			reportCode: 'InformationAboutTheWorkParachuteAndAirborneFireService',
			reportParams: { year, startDate, endDate, reportType, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone },
			isHTML: true,
		};
	}
}
