import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import store from 'client/store';
import t from 'i18n';
import { Loader, Row } from '@smartplatform/ui';
import { TextField } from 'fires/components';

const MODEL_NAME = 'PermafrostObject';

@observer
export default class PermafrostObjectPopup extends React.Component {
	@observable fetchedData = null;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.fetchedData = await store.model[MODEL_NAME].findById(this.props.record.id, { include: ['address', 'city', 'quarter'] });
	};

	go = (e) => {
		e.preventDefault();
		store.route.push({ path: `/permafrost/objects/${this.props.record.id}` });
	};

	render() {
		const { record, noPopupLink } = this.props;

		return (
			<div>
				<div>
					{!noPopupLink && (
						<h1>
							{t('permafrost.buildings')}:{' '}
							<a style={{ cursor: 'pointer' }} onClick={this.go}>
								#{record.id}
							</a>
						</h1>
					)}
				</div>
				<div>
					{!this.fetchedData && <Loader size={18} />}
					{this.fetchedData && (
						<div>
							<Row>
								<TextField label={t('permafrost.objects.name')} value={this.fetchedData.name || '-'} />
								<TextField label={t('city.title')} value={this.fetchedData.city?.name || '-'} />
							</Row>
							<Row>
								<TextField label={t('permafrost.objects.quarter')} value={this.fetchedData.quarter?.name || '-'} />
								<TextField label={t('permafrost.objects.street')} value={this.fetchedData.street || '-'} />
							</Row>
							<TextField label={t('permafrost.objects.house')} value={this.fetchedData.house || '-'} />
						</div>
					)}
				</div>
			</div>
		);
	}
}
