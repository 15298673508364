import React from 'react';
import './style.scss';

export const renderStatus = (status) => {
	if (!status) return null;
	const color = status?.color || '#FFC400';

	return (
		<span>
			<span style={{ backgroundColor: color, width: 10, height: 10, display: 'inline-block', marginRight: 5, borderRadius: '50%' }} />
			{status?.name}
		</span>
	);
};
