import { observable } from 'mobx';
import { exportReport, exportReportHTML } from 'client/tools';
import store from 'client/store';
import { SUCCESS } from '../../constants';

const REPORT_MODEL = {
	fires: 'FireReport',
	techfires: 'TechFireReport',
};

export default class ReportToolbarStore {
	@observable isLoading = false;
	@observable report = null;
	@observable isFoundData = null;
	@observable url = false;

	generateReport = async (props) => {
		this.isLoading = true;
		const { isHTML, reportCode, reportParams, reportModel, showPrint } = props;
		const model = reportModel || REPORT_MODEL[store.module.code];
		this.report = await store.model[model].getReport({
			reportCode,
			reportParams,
		});
		this.isFoundData = this.report?.isFoundData;

		if (!isHTML && showPrint) await this.getPreview(props);
		this.isLoading = false;
	};

	getPreview = async (props) => {
		const { reportModel } = props;
		const model = reportModel || REPORT_MODEL[store.module.code];
		const attachmentId = await store.model[model].getPreview(this.report);

		this.url = `${window.location.origin}/api/containers/attachments/download/${attachmentId}-filename`;
	};

	printReport = async () => {
		window.open(`https://docs.google.com/viewerng/viewer?url=${this.url}`);
	};

	downloadReport = async () => {
		const { filename, content } = this.report;
		exportReport({ filename, content });
	};

	downloadReportHTML = () => {
		const { filename } = this.report;
		exportReportHTML({
			id: 'report-table',
			name: filename,
		});
	};
}
