import t from "../../../../i18n";

export const MENU_ITEMS = ['METEO', 'ISDM1T', 'ISDM1', 'ISDM8', 'ISDM9'];
export const GROUP_MODE_ITEMS = [
    {
        label: t('isdm.groupAirDepartments' ),
        value: 'ao'
    },
    {
        label: t('isdm.groupAdministrativeRegions' ),
        value: 'rn'
    },
];
