import React from 'react';
import { observer } from 'mobx-react';
import { Map, Popup } from '@smartplatform/map/client';
import store from 'client/store';
import Filters from './filters';
import map from './store';
import { MainPopup, WeatherScale } from 'components';
import AddressTools from './address-tools';

@observer
export default class PermafrostMap extends React.Component {
	onLayerToggle = (layers) => {
		store.local.permafrost.dynamicLayers = layers;
		store.local.save();
	};

	render() {
		const { popup, mapInitialized, onMapInit, layers, onTileSourceChange } = map;

		const visibleLayers = store.local.permafrost.dynamicLayers;

		const mapParams = {
			...store.config.map,
			onTileSourceChange,
			defaultTileSource: store.local.permafrost.tileSource,
		};

		const mapProps = {
			className: 'permafrost-map full-map',
			onInit: onMapInit,
			params: mapParams,
			modelStore: store.model,
			visibleLayers,
			onLayerToggle: this.onLayerToggle,
		};

		return (
			<Map {...mapProps}>
				{popup && (
					<Popup {...popup} width={popup.width || 550}>
						<MainPopup {...popup} />
					</Popup>
				)}
				{mapInitialized && (
					<>
						<Filters />
						<AddressTools />
						{layers.weather && <WeatherScale onMount={layers.weather.onWeatherScaleMount} hidden={!store.local.permafrost.layers.weather.show} />}
					</>
				)}
			</Map>
		);
	}
}
