import React from 'react';
import { observer } from 'mobx-react';
import { CountiesFilter, WindFilter, ShipsFilter, GenShtabFilter, WeatherFilter, AutoAnimate, FiresFilter, FireDepFilter, IncidentFilter, TrackingFilter } from 'components';
import store from 'client/store';
import map from '../store';
import './style.scss';
import { Toolbar as BaseToolbar } from 'components';

@observer
export default class Filters extends React.Component {
	render() {
		const localSettings = store.local.delivery;
		const settings = localSettings.layers;

		return (
			<AutoAnimate className='delivery-filters-wrapper'>
				{localSettings.filtersMaximized && (
					<div className='delivery-filters'>
						<TrackingFilter layer={map.layers.trackingLayer} settings={settings.tracking} />
						<IncidentFilter layer={map.layers.incidentLayer} settings={settings.incidents} />
						<FireDepFilter layer={map.layers.fireDepLayer} settings={settings.fireDep} />
						<FiresFilter layer={map.layers.firesLayer} settings={settings.fires} />
						<CountiesFilter layer={map.layers.counties} settings={settings.counties} />
						<GenShtabFilter layer={map.layers.genShtab} settings={settings.genShtab} />
						<WeatherFilter layer={map.layers.weather} settings={settings.weather} />
						<WindFilter layer={map.layers.wind} settings={settings.wind} />
						<ShipsFilter layer={map.layers.ships} settings={settings.ships} />
					</div>
				)}
				<BaseToolbar>
					<BaseToolbar.FilterButton showFilters={localSettings.filtersMaximized} onClick={() => map.switchFilters(!localSettings.filtersMaximized)} />
				</BaseToolbar>
			</AutoAnimate>
		);
	}
}
