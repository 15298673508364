import { DeleteButton as _DeleteButton } from 'components';
import React from 'react';
import { ControlsContext } from './Controls';
import t from 'i18n';

export const DeleteButton = (props) => {
	const context = { ...React.useContext(ControlsContext), ...props };
	const { form, deleteTitle, disableDelete, onDelete, noDelete } = context;

	if (noDelete) return null;

	const _onDelete = async () => {
		form?.delete();
		onDelete?.();
	};

	return <_DeleteButton onConfirm={_onDelete} text={deleteTitle || t('delete')} disabled={disableDelete} />;
};
