import React from 'react';
import { observable } from 'mobx';
import t from 'i18n';
import { Row } from '@smartplatform/ui';
import { observer, inject } from 'mobx-react';
import store from 'client/store';
import { ReportToolbar, SaveButton, Card } from 'components';
import FileIcon from 'img/icons/techfires/card/file-text.svg';
import './style.scss';
import { replaceDecimal } from 'client/tools';

@inject('fireStore')
@observer
export default class FireReport extends React.Component {
	@observable fireReport = {
		header: '',
		sourceFirefightingDrain: '',
		fireInfo: '',
		stewCount: '',
		securityData: '',
		postSign: '',
		sign: '',
	};
	@observable techFire = null;
	@observable isLoading = false;

	constructor(props) {
		super(props);
		this.init(props);
	}

	init = async () => {
		const { id } = this.props.fireStore;
		this.techFire = await store.model.TechFire.findById(id);

		Object.keys(this.fireReport).forEach((el) => (this.fireReport[el] = this.techFire[el] ?? ''));
		this.props.fireStore.fire.fireDepDistance = replaceDecimal(this.props.fireStore.fire.fireDepDistance, ',');
		this.props.fireStore.fire.damageNumber = replaceDecimal(this.props.fireStore.fire.damageNumber, ',');
	};

	saveReport = async () => {
		Object.keys(this.fireReport).forEach((el) => (this.techFire[el] = this.fireReport[el] ?? ''));
		await this.techFire.save();
	};

	onChange = (name) => (arg) => (this.fireReport[name] = arg.constructor.name === 'Date' ? arg : arg.target.value);

	renderInput = (name) => <textarea name={name} autoComplete={name} value={this.fireReport[name]} onChange={this.onChange(name)} />;

	render() {
		return (
			<Card title={t('techFire.firereport')} icon={<FileIcon />} collapsible>
				<form>
					<div className='digitalNoteFree_box'>
						<Row>
							<div className='form-field'>
								<label>{t('fireReport.header')}</label>
								{this.renderInput('header')}
							</div>
						</Row>
						<Row>
							<div className='form-field'>
								<label>{t('fireReport.sourceFirefightingDrain')}</label>
								{this.renderInput('sourceFirefightingDrain')}
							</div>
						</Row>
						<Row>
							<div className='form-field'>
								<label>{t('fireReport.fireInfo')}</label>
								{this.renderInput('fireInfo')}
							</div>
						</Row>
						<Row>
							<div className='form-field'>
								<label>{t('fireReport.stewCount')}</label>
								{this.renderInput('stewCount')}
							</div>
						</Row>
						<Row>
							<div className='form-field'>
								<label>{t('fireReport.securityData')}</label>
								{this.renderInput('securityData')}
							</div>
						</Row>
						<Row>
							<div className='form-field'>
								<label>{t('fireReport.postSign')}</label>
								{this.renderInput('postSign')}
							</div>
						</Row>
						<Row>
							<div className='form-field'>
								<label>{t('fireReport.sign')}</label>
								{this.renderInput('sign')}
							</div>
						</Row>
					</div>
					<div className='techfire-report-button'>
						<SaveButton text={t('techFireReport.saveTemplate')} onClick={this.saveReport} className='techfire-report-save-button' />
						<ReportToolbar reportCode='FireReport' reportParams={{ id: this.props.fireStore.id, fields: this.fireReport }} showPreview={false} />
					</div>
				</form>
			</Card>
		);
	}
}
