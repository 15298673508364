import React from 'react';
import './style.scss';
import { MENU_ITEMS } from '../constants';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import CheckGreenIcon from 'img/icons/techfires/card/check-green.svg';
import CheckGrayIcon from 'img/icons/techfires/card/check-gray.svg';

@inject('fireStore')
@observer
export default class Sidebar extends React.Component {
	@observable activeItem = null;

	constructor(props) {
		super(props);
	}

	handleClick = (value) => {
		this.activeItem = value;
		const element = document.getElementById(value);
		if (element) {
			element.scrollIntoView({ behavior: 'smooth' });
		}
	};

	render() {
		const { fireStore } = this.props;
		const { fieldStatuses } = fireStore;
		return (
			<div className='techfire-sidebar'>
				{MENU_ITEMS.map(({ label, value }) => (
					<div className={`sidebar-item ${this.activeItem === value ? 'active' : ''}`} key={value} onClick={() => this.handleClick(value)}>
						{label}
						<div>{fieldStatuses[value] ? <CheckGreenIcon className='check-icon' /> : <CheckGrayIcon className='check-icon' />}</div>
					</div>
				))}
			</div>
		);
	}
}
