import React from 'react';
import { Tab, Tabs } from '@smartplatform/ui';
import t from 'i18n';
import TemperatureTubesList from './temperature-tubes';
import LevelingMarksList from './leveling-marks';
import { Redirect, Route } from 'react-router-dom';
import './style.scss'

export default (props) => {
	const { match } = props;
	const { path } = match;
	return (
		<div className='measurements-edit'>
			<h2>{t('permafrost.measurementsMenu')}</h2>
			<Tabs>
				<Tab title={t('permafrost.tubeMenu')} path={`${path}/temperature-tubes`} render={(props) => <TemperatureTubesList {...props} />} />
				<Tab title={t('permafrost.marksFull')} path={`${path}/leveling-marks`} render={(props) => <LevelingMarksList {...props} />} />
			</Tabs>
			<Route path={path} exact>
				<Redirect to={`${path}/temperature-tubes`} />
			</Route>
		</div>
	);
};
