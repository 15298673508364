import React from 'react';
import { observer, inject } from 'mobx-react';
import { action, observable, autorun } from 'mobx';
import { differenceInDays, startOfDay, endOfDay, addDays, subDays, isBefore } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleRight, faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';

import { RecordSelect, Button, DatePicker, Checkbox } from '@smartplatform/ui';
import FilterLayout from '../FilterLayout';
import { Hint } from 'components';
import store from 'client/store';
import t from 'i18n';

const MAX_PERIOD = 365; // максимальный период в днях

@observer
export default class HeatPointsFilter extends React.Component {
	@observable changed = false;
	@observable count = null;

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		if (this.props.layer.settings.show) {
			this.getCount();
		}
	}

	setPeriod = (period) => {
		// console.log('setPeriod', period);
		this.props.layer.settings.period = period;
		store.local.save();
		this.props.layer.update();
	};

	@action setStartDate = (date) => {
		if (date === null) {
			return;
		}
		const { layer } = this.props;
		layer.start = startOfDay(new Date(date));
		this.changed = true;
		if (isBefore(layer.end, layer.start)) {
			layer.end = endOfDay(layer.start);
		} else {
			const dif = differenceInDays(layer.end, layer.start);
			if (dif > MAX_PERIOD) {
				layer.end = endOfDay(addDays(layer.start, MAX_PERIOD));
			}
		}
	};

	@action setEndDate = (date) => {
		if (date === null) {
			return;
		}
		const { layer } = this.props;
		layer.end = endOfDay(new Date(date));
		this.changed = true;
		if (isBefore(layer.end, layer.start)) {
			layer.start = startOfDay(layer.end);
		} else {
			const dif = differenceInDays(layer.end, layer.start);
			if (dif > MAX_PERIOD) {
				layer.start = startOfDay(subDays(layer.end, MAX_PERIOD));
			}
		}
	};

	update = () => {
		this.changed = false;
		this.props.onLoadStart && this.props.onLoadStart();
		this.getCount();
		this.props.layer.update();
	};

	prev = () => {
		const { layer } = this.props;
		layer.start = subDays(layer.start, 1);
		layer.end = subDays(layer.end, 1);
		this.props.onLoadStart && this.props.onLoadStart();
		this.getCount();
		layer.update();
	};

	next = () => {
		const { layer } = this.props;
		layer.start = addDays(layer.start, 1);
		layer.end = addDays(layer.end, 1);
		this.props.onLoadStart && this.props.onLoadStart();
		this.getCount();
		layer.update();
	};

	getCount = async () => {
		const { layer } = this.props;
		const dataSourceId = layer.dataSource ? layer.dataSource.id : 3;
		const regionId = layer.settings.region || undefined;
		this.count = await store.model.FireData.getCount({
			dataSourceId,
			regionId,
			start: layer.start,
			end: layer.end,
		});
	};

	setHeatPointsRegion = (value) => {
		const regionId = value ? null : 1;
		this.props.layer.settings.region = regionId;
		this.props.layer.setRegion(regionId);
		console.log('setHeatPointsRegion', value, regionId);
		this.getCount();
	};

	render() {
		const { layer } = this.props;

		return (
			<FilterLayout layer={layer} title={t('heatPoint.plural')} isLoading={layer.isLoading}>
				<div className='mb-2'>
					<label>{t('heatPoint.source')}</label>
					<RecordSelect
						noTotal
						model={store.model.FireDataSource}
						property='name'
						value={layer.dataSource}
						onChange={layer.switchSource}
						width={200}
						position='bottomLeft'
						isRequired
						noSearch
						disabled={!layer.settings.show}
					/>
				</div>
				<div className='mb-2 heat-points-period'>
					<label>{t('heatPoint.period')}</label>
					<DatePicker value={layer.start} onChange={this.setStartDate} showClearButton={false} isRequired className='start' />
					<DatePicker value={layer.end} onChange={this.setEndDate} showClearButton={false} isRequired />
					<Hint text={t('heatPoint.periodHint')} />
				</div>
				<div className='mt-1'>
					<Button onClick={this.update} disabled={!this.changed || layer.isLoading} size='sm' variant='primary'>
						{t('heatPoint.reload')}
					</Button>
					<Button className='arr-btn' onClick={this.prev} disabled={this.changed || layer.isLoading} variant='link'>
						<FontAwesomeIcon icon={faArrowAltCircleLeft} />
					</Button>
					<Button className='arr-btn' onClick={this.next} disabled={this.changed || layer.isLoading} variant='link'>
						<FontAwesomeIcon icon={faArrowAltCircleRight} />
					</Button>{' '}
					<Checkbox checked={layer.settings.region === null} onChange={this.setHeatPointsRegion} label={t('heatPoint.eurasia')} />
				</div>
				<div>
					<span className='hp-legend today' /> {t('heatPoint.today') + ' '}
					<span className='hp-legend yesterday' /> {t('heatPoint.yesterday') + ' '}
					<span className='hp-legend earlier' /> {t('heatPoint.earlier')}
				</div>
				{/*
			<div>
				<Checkbox
					value={store.local.fires.layers.heatPoints.useMVT}
					onChange={fireStore.setHeatPointsVersion}
					label="MVT-версия (тест)"
				/>
			</div>
*/}
				<div>
					{t('heatPoint.plural')}: <>{layer.settings.show ? <strong>{this.count === null ? '-' : this.count}</strong> : t('off')}</>
				</div>
			</FilterLayout>
		);
	}
}
