import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Row, Table, Column, Tabs, Tab, Button } from '@smartplatform/ui';
import store from 'client/store';
import { LabelField, FavouriteButton, CancelButton, NavigationButton } from 'components';
import t from 'i18n';
import { format } from 'date-fns';
import './shipPopup.scss';

@observer
export class ShipPopup extends React.Component {
	@observable record = null;
	@observable trackCount = 0;
	@observable tab = 'mainInfo';

	setTab = (tab) => (this.tab = tab);

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.record !== this.props.record) {
			this.init();
		}
	}

	componentDidMount = async () => {
		this.init();
	};

	init = async () => {
		this.record = await store.model.ViewShip.findById(this.props.record.id, {
			include: [
				{ relation: 'type', scope: { fields: ['id', 'name'] } },

				{
					relation: 'ladingBills',
					scope: {
						order: 'id desc',
						include: [
							{ relation: 'request', scope: { fields: ['id', 'name'] } },
							{ relation: 'destinationCity', scope: { fields: ['id', 'name'] } },
						],
					},
				},
			],
		});
	};

	toggleFavourite = async () => {
		this.record.isFavourite = !this.record.isFavourite;
		const record = new store.model.Ship({ id: this.record.id, isFavourite: this.record.isFavourite });
		await record.save();
	};

	onClose = () => {
		this.props.onClose(this.record);
	};

	render() {
		if (!this.record) return null;
		const ladingBills = this.record.ladingBills?.() || [];
		const imageSrc = this.record.image ? this.record.downloadFile('image') + '?image=[{"resize":{"width":400}}]' : null;

		const showTrackButton = !this.props.isTrackPopup && this.record.trackCount > 0;
		return (
			<div className='ship-popup'>
				{imageSrc ? <img alt='ship' src={imageSrc} className='image' /> : <div className='no-image'>{t('noImage')}</div>}
				<Tabs type='manual'>
					<Tab title={t('mainInfo')} onClick={() => this.setTab('mainInfo')} />
					<Tab title={t('ship.ladingBillTab')} onClick={() => this.setTab('ladingBill')} disabled={ladingBills.length === 0} />
				</Tabs>
				<div className='ship-tab-content'>
					{this.tab === 'mainInfo' && (
						<>
							<Row>
								<LabelField label={t('name')} children={this.record.name ?? '-'} />
								<LabelField label={t('nameLat')} children={this.record.nameLat ?? '-'} />
							</Row>
							<Row>
								<LabelField label={t('ship.callSign')} children={this.record.callSign ?? '-'} />
								<LabelField label={t('ship.type')} children={this.record.type?.name ?? '-'} />
							</Row>
							<Row>
								<LabelField label={t('ship.imo')} children={this.record.imo ?? '-'} />
								<LabelField label={t('ship.mmsi')} children={this.record.mmsi ?? '-'} />
							</Row>
							<Row>
								<LabelField label={t('ship.marineReg')} children={this.record.marineReg ?? '-'} />
								<LabelField label={t('ship.riverReg')} children={this.record.riverReg ?? '-'} />
							</Row>
							<Row>
								<LabelField label={t('speed')} children={this.record.speed ?? '-'} />
								<LabelField label={t('course')} children={this.record.course ?? '-'} />
							</Row>
							<Row>
								<LabelField
									label={t('ship.receiveDate')}
									children={this.record.receiveDate ? format(new Date(this.record.receiveDate), 'dd.MM.yyyy HH:mm') : '-'}
								/>
								<LabelField label={t('ship.source')} children={this.record.source ?? '-'} />
							</Row>

							<Row>
								<LabelField label={t('ship.distanceDay')} children={this.record.distanceDay ?? '-'} />
								<LabelField label={t('ship.distanceMonth')} children={this.record.distanceMonth ?? '-'} />
							</Row>

							<Row>
								<LabelField label={t('ship.avgSpeedMonth')} children={this.record.avgSpeedMonth ?? '-'} />
								<LabelField label={t('ship.avgMovementSpeedMonth')} children={this.record.avgMovementSpeedMonth ?? '-'} />
							</Row>

							<Row>
								<LabelField label={t('ship.movementDays')} children={this.record.movementDays ?? '-'} />
								<LabelField label={t('ship.movementDays')} children={this.record.movementDays ?? '-'} />
							</Row>
							<Row>
								<LabelField label={t('ship.destination')} children={this.record.destination ?? '-'} />
								<div />
							</Row>
						</>
					)}
					{this.tab === 'ladingBill' && (
						<>
							<h3 style={{ marginBottom: 10 }}>{t('ladingBill.plural')}</h3>
							<Table rows={ladingBills} onRowClick={({ id }) => store.route.push({ path: `/delivery/tracking/tracking/${id}` })}>
								<Column property='number' label={t('ladingBill.number')} />
								<Column relation='request' property='id' label={t('request.planNumber')} />
								<Column property='trackNumber' label={t('request.trackNumber')} />
							</Table>
						</>
					)}
				</div>
				<div className='controls'>
					<Row>
						<FavouriteButton isFavourite={this.record.isFavourite} onClick={this.toggleFavourite} />
						{showTrackButton ? (
							<NavigationButton onClick={() => this.props.showTrack(this.record)} text={t('ship.route')} />
						) : this.props.isTrackPopup ? (
							<CancelButton text={t('close')} onClick={this.onClose} />
						) : (
							<div />
						)}
					</Row>
				</div>
			</div>
		);
	}
}
