import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { SuggestInput } from '@smartplatform/ui';
import store from '../../store';
import './style.scss';

const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

@observer
export default class Labels extends React.Component {
	static propTypes = {
		record: PropTypes.object.isRequired,
		relation: PropTypes.string,
		placeholder: PropTypes.string,
		onChange: PropTypes.func, // для несохраненной записи, отправляет массив выбранных меток
	};

	static defaultProps = {
		relation: 'labels',
		placeholder: 'Название тега',
	};

	@observable labels = [];
	@observable suggestions = [];

	refs = [];

	constructor(props) {
		super(props);
		this.init();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.input) this.input.focus();
	}

	init = async () => {
		if (this.props.record.id) {
			this.labels = await this.props.record[this.props.relation].find({ order: 'id asc' });
		}
	};

	inputRef = (el) => (this.input = el);

	onFetchSuggestions = async (value) => {
		// console.log('onFetchSuggestions', value, value.length);
		if (value.length === 0) {
			this.suggestions = [];
		} else {
			this.suggestions = await store.model.Label.find({
				where: { name: { ilike: `${value}%` } },
				order: 'name asc',
			});
		}
	};

	renderSuggestion = (label) => label.name;

	onSubmit = async (label) => {
		console.log('onSubmit', label);
		await this.addLabel(label);
	};

	onRawSubmit = async (value) => {
		console.log('onRawSubmit', value);
		const existing = await store.model.Label.find({
			where: { name: { ilike: value } },
			fields: ['id', 'name'],
		});
		if (existing.length > 0) {
			await this.addLabel(existing[0]);
			return existing[0];
		} else {
			const label = new store.model.Label();
			label.name = capitalize(value);
			await label.save();
			await this.addLabel(label);
			return label;
		}
	};

	addLabel = async (label) => {
		if (!this.props.record.id) {
			const existing = this.labels.find((l) => label.id === l.id);
			if (existing) {
				this.hilightAlreadyAdded(label);
			} else {
				this.labels.push(label);
			}
			this.props.onChange && this.props.onChange(this.labels);
			return;
		}
		const existing = await this.props.record[this.props.relation].find({ where: { id: label.id } });
		// console.log('existing', existing);
		if (existing.length === 0) {
			await this.props.record[this.props.relation].add(label.id);
			await this.init();
		} else {
			this.hilightAlreadyAdded(label);
		}
	};

	removeLabel = async (label) => {
		if (!this.props.record.id) {
			const index = this.labels.findIndex((l) => label.id === l.id);
			if (index !== -1) {
				this.labels.splice(index, 1);
				this.props.onChange && this.props.onChange(this.labels);
			}
			return;
		}
		await this.props.record[this.props.relation].remove(label.id);
		await this.init();
	};

	hilightAlreadyAdded = (label) => {
		if (this.refs[label.id] && this.refs[label.id].current) {
			const el = this.refs[label.id].current;
			requestAnimationFrame(() => {
				el.classList.add('existing-in');
				requestAnimationFrame(() => {
					el.classList.remove('existing-in');
					el.classList.add('existing-out');
					setTimeout(() => {
						el.classList.remove('existing-out');
					}, 2000);
				});
			});
		}
	};

	render() {
		this.refs = [];

		return (
			<div className='labels-edit'>
				{this.labels.map((label) => {
					this.refs[label.id] = React.createRef();
					return (
						<span key={label.id} className='label-item' ref={this.refs[label.id]}>
							{label.name}
							<span className='remove' onClick={() => this.removeLabel(label)}>
								<FontAwesomeIcon icon={faTimes} />
							</span>
						</span>
					);
				})}
				<SuggestInput
					suggestions={this.suggestions}
					placeholder={this.props.placeholder}
					renderSuggestion={this.renderSuggestion}
					toString={this.renderSuggestion}
					onFetchSuggestions={this.onFetchSuggestions}
					onSubmit={this.onSubmit}
					onRawSubmit={this.onRawSubmit}
					inputRef={this.inputRef}
					clearOnSubmit
				/>
			</div>
		);
	}
}
