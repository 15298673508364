import React, { Component } from 'react';
import t from 'i18n';

export default class FireObjectInfo extends Component {
	constructor(props) {
		super(props);
	}
	render() {
		const { fire } = this.props;
		return (
			<div className='fire-object-info'>
				{fire.object ? (
					<div>
						{t('techFireAddress.title')}: {fire.object?.address.name}
					</div>
				) : (
					<div>
						{t('fireAlarm.objectBuildingManualEntry')}: {fire.additionalInfo}
					</div>
				)}
				{fire.object && (
					<>
						{fire.object.ptp && (
							<div>
								{t('techFireAddress.ptp')}: {fire.object.ptp}
							</div>
						)}
						{fire.object.ktp && (
							<div>
								{t('techFireAddress.ktp')}: {fire.object.ktp}
							</div>
						)}
					</>
				)}
				{fire.description ? (
					<div>
						{t('techFireAddress.description')}: {fire.description}
					</div>
				) : (
					fire.object && (
						<div>
							{t('techFireAddress.description')}: {fire.object.description}
						</div>
					)
				)}
			</div>
		);
	}
}
