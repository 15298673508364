import { observable } from 'mobx';
import store from 'client/store';
import { FIRE_ALARM_MESSAGE_INCLUDE } from './constants';
import debounce from 'lodash/debounce';

export default class FireAlarmMessageStore {
	@observable search = '';
	@observable querySearch = '';
	@observable order = 'id desc';
	@observable showFilters = false;
	@observable page = 1;
	@observable totalCount = 0;
	@observable perPage;
	@observable garrison = null;
	path;

	constructor() {
		this.doSearch = debounce(this.doSearch, 500, { leading: false, trailing: true });
	}

	doSearch = () => (this.querySearch = this.search);

	onSearch = (e) => {
		this.page = 1;
		this.search = e.target.value;
		this.doSearch();
	};
	onChange = (prop) => (value) => {
		this.page = 1;
		this[prop] = value;
	};

	onQueryUpdate = async (query) => {
		if (Number.isInteger(query._totalCount)) this.totalCount = query._totalCount;
		this.order = query.order;
	};

	get query() {
		const { querySearch } = this;
		const filter = {
			where: { and: [] },
			order: this.order,
			limit: this.perPage,
			skip: (this.page - 1) * this.perPage,
			...FIRE_ALARM_MESSAGE_INCLUDE,
		};

		if (querySearch.length) filter.search = querySearch;
		if (this.garrison) filter.where.and.push({ garrisonId: this.garrison.id });
		if (!filter.where.and.length) delete filter.where;
		return filter;
	}

	create = () => store.route.push({ path: `${this.path}/create` });
	onRowClick = (record) => store.route.push({ path: `${this.path}/${record.id}` });
}
