import React from 'react';
import { observer } from 'mobx-react';

import { Map, Popup } from '@smartplatform/map/client';
import store from 'client/store';
import Filters from './filters';
import emergencyMap from './store';
import { WeatherScale } from 'components';
import MainPopup from './MainPopup';
import { Checkbox } from '@smartplatform/ui';

@observer
export default class EmergencyMap extends React.Component {
	onLayerToggle = (layers) => {
		store.local.emergency.dynamicLayers = layers;
		store.local.save();
	};

	onObjectEdit = (object) => {
		store.route.push({ path: `/layers/${object.layerId}/objects/${object.id}` });
	};

	render() {
		const { popup, mapInitialized, onTileSourceChange } = emergencyMap;
		const visibleLayers = store.local.emergency.dynamicLayers;

		const mapParams = {
			...store.config.map,
			onTileSourceChange,
			defaultTileSource: store.local.emergency.tileSource,
		};

		const mapProps = {
			className: 'emergency-map full-map',
			onInit: emergencyMap.onMapInit,
			params: mapParams,
			modelStore: store.model,
			visibleLayers,
			onLayerToggle: this.onLayerToggle,
			onObjectEdit: this.onObjectEdit,
		};

		return (
			<Map {...mapProps}>
				{popup && (
					<Popup {...popup} width={popup.width || 550}>
						<MainPopup {...popup} />
					</Popup>
				)}
				{mapInitialized && (
					<>
						<Filters />
						<WeatherScale onMount={emergencyMap.weatherLayer.onWeatherScaleMount} hidden={!store.local.emergency.layers.weather.show} />
					</>
				)}
			</Map>
		);
	}
}
