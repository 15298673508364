import { observable } from 'mobx';
import { DASHBOARD_SECTIONS } from './sections';
import { startOfDay, endOfDay, subDays, addDays, isToday, setHours } from 'date-fns';
import store from 'client/store';
import { exportReport } from 'client/tools';
import t from 'i18n';
import { formatDate } from 'client/tools';

export const TIME_ITEMS = [
	{ value: '0', label: '00:00' },
	{ value: '8', label: '8:00' },
	{ value: '12', label: '12:00' },
	{ value: 'currentTime', label: t('fireDashboard.currentTime') },
];

export default class DashboardStore {
	@observable section = DASHBOARD_SECTIONS[0];
	@observable date = new Date();
	@observable exportDate;
	@observable isLoading = true;
	@observable citiesLoading = true;
	@observable data = {};
	@observable citiesData = {};
	@observable report = null;
	@observable showEmptySections = false;
	@observable time = 'currentTime';

	onShowEmptySectionsChange = async () => {
		this.showEmptySections = !this.showEmptySections;
		this.report = null;
		await this.getReportData();
	};

	onTimeChange = (value) => {
		if (value === 'currentTime') {
			this.date = endOfDay(new Date());
		}
		this.time = value;
		this.updateData();
	};

	onSectionChange = (section) => (this.section = DASHBOARD_SECTIONS.find((i) => i.value === section));

	get dateWithTime() {
		if (this.time === 'currentTime') return new Date();
		return setHours(startOfDay(this.date), +this.time);
	}

	//data requests
	getDashboardData = async () => {
		if (Object.keys(this.data).length === 0 || this.data.date !== this.dateWithTime) {
			this.isLoading = true;
			this.data = await store.model.Fire.getDashboardData({
				date: this.dateWithTime,
				startOfToday: startOfDay(this.date),
				tzOffset: this.date.getTimezoneOffset(),
				daysCount: 7,
				radius1: store.local.fires.citiesTest ? 20 : undefined,
				radius2: store.local.fires.citiesTest ? 50 : undefined,
			});
			this.data.date = this.date;
			this.isLoading = false;
		}
	};

	getDashboardCitiesData = async () => {
		if (Object.keys(this.citiesData).length === 0 || this.data.date !== this.dateWithTime) {
			this.citiesLoading = true;
			this.citiesData = await store.model.Fire.getDashboardCitiesData({
				date: this.dateWithTime,
				startOfToday: startOfDay(this.date),
				tzOffset: this.date.getTimezoneOffset(),
				daysCount: 7,
				radius1: store.local.fires.citiesTest ? 20 : undefined,
				radius2: store.local.fires.citiesTest ? 50 : undefined,
			});
			this.citiesLoading = false;
		}
	};

	getReportData = async () => {
		if (!this.report || this.report.date !== this.dateWithTime) {
			this.isLoading = true;
			this.report = await store.model.FireReport.getReport({
				reportCode: 'FiresOnCountyAndDate',
				reportParams: {
					withCoordinates: true,
					endDate: this.dateWithTime,
					showEmptySections: this.showEmptySections,
				},
			});
			this.report.date = this.date;
			this.isLoading = false;
		}
	};

	prev = () => this.onDateChange(subDays(this.date, 1));
	next = () => this.onDateChange(addDays(this.date, 1));

	onDateChange = (date) => {
		if (!date) this.date = new Date();
		if (this.time === 'currentTime' && !isToday(date)) {
			this.time = '0';
		}
		this.date = date;
		this.updateData();
	};

	updateData = () => {
		if (this.section.value === 1) {
			this.getDashboardData();
			this.getDashboardCitiesData();
		} else if (this.section.value === 2) {
			this.getReportData();
		}
	};

	onExportPageAsExcel = async () => {
		const report = await store.model.FireReport.getReport({
			reportCode: 'DashboardInExcel',
			reportParams: {
				date: this.dateWithTime,
				citiesData: this.citiesData,
				dashboardData: this.data,
				timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
			},
		});
		exportReport(report);
	};
}
