import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import store from 'client/store';
import t from 'i18n';
import { Loader, Row } from '@smartplatform/ui';
import { TextField } from 'fires/components';
import {formatDate} from "client/tools";

const MODEL_NAME = 'TechFireBlockedRoad';

@observer
export default class BlockedRoadPopup extends React.Component {
	@observable fetchedData = null;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.fetchedData = await store.model[MODEL_NAME].findById(this.props.record.id);
	};

	go = (e) => {
		e.preventDefault();
		store.route.push({ path: `/techfires/registry/techFireBlockedRoad/${this.props.record.id}` });
	};

	render() {
		const { record, noPopupLink } = this.props;

		return (
			<div>
				<div>
					{!noPopupLink && (
						<h1>
							{t('techFireBlockedRoad.title')}:{' '}
							<a style={{ cursor: 'pointer' }} onClick={this.go}>
								#{record.id}
							</a>
						</h1>
					)}
				</div>
				<div>
					{!this.fetchedData && <Loader size={18} />}
					{this.fetchedData && (
						<div>
							<Row>
								<TextField label={t('techFireBlockedRoad.dateClose')} value={formatDate(this.fetchedData.dateClose) || '-'} />
								<TextField label={t('techFireBlockedRoad.dateOpen')} value={formatDate(this.fetchedData.dateOpen) || '-'} />
							</Row>
							<Row>
								<TextField label={t('techFireBlockedRoad.reason')} value={this.fetchedData.reason || '-'} />
								<TextField label={t('techFireBlockedRoad.info')} value={this.fetchedData.info || '-'} />
							</Row>
						</div>
					)}
				</div>
			</div>
		);
	}
}
