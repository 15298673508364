import React from 'react';
import './style.scss';
import CountFiresByStatus from './CountFiresByStatus';
import CountEquipmentByType from './CountEquipmentByType';
import CountEquipmentByStatus from './CountEquipmentByStatus';
import CountFiresByStatusTable from 'techfires/dashboard/main/operation/CountFiresByStatusTable';
import EquipmentByFDTable from 'techfires/dashboard/main/operation/EquipmentByFDTable';
import { inject, observer } from 'mobx-react';
import { Loader } from '@smartplatform/ui';

@inject('store')
@observer
export default class OperationReport extends React.Component {
	constructor(props) {
		super(props);
		this.store = props.store;
	}

	render() {
		const { isLoading } = this.store;
		if (isLoading) return <Loader size={20} />;
		return (
			<div className='techfires-dashboard-items'>
				<div className='left'>
					<CountFiresByStatus />
					<CountEquipmentByStatus />
					<CountEquipmentByType />
				</div>
				<div className='right'>
					<CountFiresByStatusTable />
					<EquipmentByFDTable />
				</div>
			</div>
		);
	}
}
