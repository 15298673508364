import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { PlanVisitsList } from './list/PlanVisitsList';
import FirePlanVisit, { CreateFirePlanVisit } from './edit';

export default ({ match }) => {
	const { path } = match;

	return (
		<div className='techfires-registry'>
			<Switch>
				<Route path={path} exact render={({ match }) => <PlanVisitsList match={match} path={path} />} />
				<Route path={`${path}/create`} exact render={(routeProps) => <CreateFirePlanVisit {...routeProps} path={path} />} />
				<Route path={`${path}/:id`} render={(routeProps) => <FirePlanVisit {...routeProps} path={path} />} />
			</Switch>
		</div>
	);
};
