import React from 'react';
import { observer, Provider } from 'mobx-react';
import { observable } from 'mobx';

import { getRoleNames } from 'client/tools';
import store from 'client/store';
import MainDispatchShifts from './dispatchers/main-dispatcher';
import FireDepShifts from './dispatchers/firedep-dispatcher';
import t from 'i18n';
import './style.scss';
import ShiftStore from './store';

@observer
export default class Shifts extends React.Component {
	@observable canCreate = false;
	@observable isloading = true;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.store = new ShiftStore();
		const roleNames = await getRoleNames();
		console.log('roleNames', roleNames);
		this.canCreate = roleNames.includes('tfires_main') || store.model.TechFireShift.INFO.WRITE;
		this.isloading = false;
	};

	render() {
		if (this.isloading) return null;

		return (
			<Provider store={this.store}>
				<div className='techfire-shifts'>
					<div className='title'>
						<h1>{t('fireDepShift.plural')}</h1>
						{this.canCreate && <span className='main-dispatch'>{t('fireDepShift.mainDispatch')}</span>}
					</div>
					{this.canCreate ? <MainDispatchShifts /> : <FireDepShifts />}
				</div>
			</Provider>
		);
	}
}
