import store from 'client/store';
import { getPeopleValues, getVehiclesValues, calcSameRecordsValues } from 'client/tools/helpers/fires';
import { AIR_JOBS_INCLUDE, PEOPLE_INCLUDE, VEHICLES_GROUPS, VEHICLE_INCLUDE, FOREST_FIRE_MOVEMENT_INCLUDE, FIRE_DEP_PERSONNEL_INCLUDE } from './constants';

//! поменять модель
const MODEL = 'ForestFireMovement';

const getRecordById = async (id) => await store.model[MODEL].findById(id, FOREST_FIRE_MOVEMENT_INCLUDE);
const getNewRecord = () => new store.model[MODEL]({ departureDate: new Date(), garrisonId: store.model.user.garrisonId });

const getFireById = async (id) => {
	const fire = await store.model.ViewFire.findById(id, {
		include: [
			...AIR_JOBS_INCLUDE,
			{
				relation: 'logs',
				scope: {
					fields: ['id', 'area', 'statusId'],
					order: 'date asc',
					include: [PEOPLE_INCLUDE, VEHICLE_INCLUDE, { relation: 'status', scope: { fields: ['id', 'code'] } }],
				},
			},
		],
	});

	const fireLogs = fire.logs();
	const people = getPeopleValues(fireLogs);
	const vehicles = getVehiclesValues(fireLogs);
	fire.jobsSum = fire.jobs();
	fire.peopleSum = calcSameRecordsValues(people, 'crewTypeId', 'current');
	fire.vehiclesSum = calcSameRecordsValues(vehicles, 'typeId', 'current').filter((i) => VEHICLES_GROUPS.includes(i.typeCode));

	return fire;
};

export const getFFStatuses = async () => {
	const [personnelStatus, vehicleStatus] = await Promise.all([
		store.model.FireDepPersonnelStatus.find({ where: { code: 'FF' } }),
		store.model.FireDepVehicleStatus.find({ where: { code: 'FF' } }),
	]);

	return [personnelStatus[0], vehicleStatus[0]];
};

const getFireDepById = async (id) => await store.model.FireDep.findById(id, FIRE_DEP_PERSONNEL_INCLUDE);
export const API = { getNewRecord, getRecordById, getFireById, getFireDepById, getFFStatuses };
