import React from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Field, Popup, Row, NumberInput, RecordSelect } from '@smartplatform/ui';
import t from 'i18n';
import store from 'client/store';
import { Form } from 'components';
import { ALREADY_EXIST, REQUIRED_CREWTYPE, REQUIRED_EXTINGUISHING_AGENTS_TYPE } from '../../constants';

@inject('card')
@observer
export default class VehiclePopup extends React.Component {
	@observable record = null;
	@observable availableFormations = null;

	constructor(props) {
		super(props);
		this.record = this.props.record;
		this.isNew = !this.record.id;
		this.records = !this.isNew ? this.props.records.filter((people) => people.id !== this.record.id) : this.props.records;
		if (!this.isNew) {
			this.getRecordRelationData();
		}
	}

	//* init
	getRecordRelationData = async () => {
		this.record.formation = await this.record.formation;
		this.record.crewType = await this.record.crewType;
		this.record.type = await this.record.type;
		this.availableFormations = (await this.record.crewType?.formations()) || [];
	};

	//* render
	renderFormation = (record) => record?.formationShortName;
	renderCrewType = (record) => record?.crewTypeShortName;
	renderType = (record) => record?.typeShortName;

	//* onchange
	onCrewTypeChange = (crewType) => {
		this.record.crewType = crewType;
		if (crewType) {
			this.availableFormations = crewType.formations();
			this.record.formation = this.availableFormations.length > 0 ? this.availableFormations[0] : null;
		}
	};
	onFormationChange = (formation) => (this.record.formation = formation);
	onTypeChange = (type) => (this.record.type = type);

	//* validation
	getValidationError() {
		if (!this.record.type) return REQUIRED_EXTINGUISHING_AGENTS_TYPE;
		if (!this.record.crewTypeId) return REQUIRED_CREWTYPE;
		const alreadyExist = this.records.find(
			(vehicle) =>
				vehicle.crewTypeId === this.record.crewType?.id && vehicle.formationId === this.record.formation?.id && vehicle.typeId === this.record.type?.id
		);
		if (alreadyExist) return ALREADY_EXIST;
	}

	render() {
		if (this.isLoading) return null;
		const title = this.props.record.id ? t('editRecord') : t('addRecord');
		this.validationError = this.getValidationError();
		const controls = <>{this.validationError && <span className='form-validation-error'>{t('validation.' + this.validationError)}</span>}</>;
		const { onClose, card } = this.props;
		const { disabled } = card;

		return (
			<Popup onClose={this.props.onClose} className='edit-dynamic-vehicle-popup fires-popup' width={600}>
				<h2>{title}</h2>
				<Form
					disabled={disabled}
					record={this.record}
					onSave={onClose}
					disableSave={!!this.validationError}
					onCancel={onClose}
					stay
					controls={controls}
					noClean
					onDelete={onClose}
				>
					{!this.record.crewTypeShortName ? (
						<Row>
							<div className='form-field'>
								<label>{t('extinguishingAgentsType.title_short')}</label>
								<RecordSelect
									noTotal
									maxItems={10}
									itemsPerPage={1000}
									model={store.model.ExtinguishingAgentsType}
									property='name'
									value={this.record.type}
									onChange={this.onTypeChange}
									isRequired
									noSearch
									disabled={!this.isNew}
								/>
							</div>
							<div className='form-field'>
								<label>{t('crewType.title_short')}</label>
								<RecordSelect
									noTotal
									maxItems={10}
									itemsPerPage={100}
									model={store.model.CrewType}
									property='shortName'
									value={this.record.crewType}
									onChange={this.onCrewTypeChange}
									filter={{ include: 'formations' }}
									isRequired
									noSearch
									disabled={!this.isNew}
								/>
							</div>
							<div className='form-field'>
								<label>{t('fireFightingFormation.title_short')}</label>
								<RecordSelect
									noTotal
									maxItems={10}
									itemsPerPage={100}
									model={store.model.FireFightingFormation}
									property='shortName'
									value={this.record.formation}
									onChange={this.onFormationChange}
									isRequired
									noSearch
									disabled={!this.record.crewType || !this.isNew}
									filter={{
										where: {
											id: this.availableFormations ? { inq: this.availableFormations.map((f) => f.id) } : null,
										},
									}}
								/>
							</div>
						</Row>
					) : (
						<Row>
							<Field computed={this.renderType} label={t('extinguishingAgentsType.title_short')} disabled />
							<Field computed={this.renderCrewType} label={t('crewType.title_short')} disabled />
							<Field computed={this.renderFormation} label={t('fireFightingFormation.title_short')} disabled />
						</Row>
					)}
					<Row>
						<Field property='current' label={t('fireFightingVehicle.current_short')} />
						<Field property='required' label={t('fireFightingVehicle.required')}>
							<NumberInput integerOnly positiveOnly />
						</Field>
						<Field property='plan' label={t('fireFightingVehicle.plan')}>
							<NumberInput integerOnly positiveOnly />
						</Field>
					</Row>
				</Form>
			</Popup>
		);
	}
}
