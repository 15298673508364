const getNameShortName = (relationName) => ({ relation: relationName, scope: { fields: ['name', 'shortName', 'id', 'code'] } });
export const VEHICLES_GROUPS = ['TR', 'BUL', 'TV', 'BA', 'PM', 'MLPK', 'TP'];

export const PEOPLE_INCLUDE = {
	relation: 'people',
	scope: {
		include: ['formation', 'crewType'].map((i) => getNameShortName(i)),
	},
	order: 'createdAt desc',
};
export const VEHICLE_INCLUDE = {
	relation: 'vehicles',
	scope: {
		include: ['type', 'formation', 'crewType'].map((i) => getNameShortName(i)),
	},
	order: 'createdAt desc',
};

const RAID_INCLUDE = [
	{
		relation: 'raid',
		scope: {
			include: [
				{
					relation: 'aircraft',
					scope: {
						include: ['type'],
					},
					fields: ['id', 'typeId'],
				},
			],
			fields: ['id', 'aircraftId'],
		},
	},
];

export const AIR_JOBS_INCLUDE = [
	{
		relation: 'jobs',
		scope: {
			order: 'createdAt desc',
			include: RAID_INCLUDE,
			fields: ['id', 'duration', 'raidId', 'createdAt'],
		},
	},
];

export const FIRE_DEP_PERSONNEL_INCLUDE = {
	include: [
		{
			relation: 'personnel',
			scope: {
				include: [
					'fireDep',
					'position',
					'status',
					{ relation: 'vehicle', scope: { include: ['status', 'callSign', { relation: 'type', scope: { include: ['vehicleCode'] } }] } },
				],
				where: {
					fireDepShiftId: null,
				},
			},
		},
	],
};
export const FOREST_FIRE_MOVEMENT_INCLUDE = {
	include: [
		{ relation: 'personnelLogs', scope: { include: ['position', 'vehicle', 'fireDep'], order: 'fireDepId asc' } },
		{
			relation: 'fireDeps',
			scope: { ...FIRE_DEP_PERSONNEL_INCLUDE },
		},
		'fire',
		{ relation: 'personnel', scope: { fields: ['id'], where: { fireDepShiftId: null } } },
	],
};
