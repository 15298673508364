export const RECORD_INCLUDE = {
	include: [
		{
			relation: 'fireDeps_thr',
			scope: {
				include: [
					{ relation: 'fireDep', scope: { fields: ['id', 'name'], include: [{ relation: 'vehicles', scope: { fields: ['id', 'stateNumber'] } }] } },
					{ relation: 'acceptUser', scope: { fields: ['id', 'lastName', 'firstName', 'middleName', 'username'] } },
				],
			},
		},
		{
			relation: 'vehicles',
			scope: {
				include: [
					{ relation: 'callSign' },
					{ relation: 'fireDep', scope: { fields: ['id', 'name'] } },
					{ relation: 'brand', scope: { fields: ['id', 'name'] } },
					{
						relation: 'cisternBulk',
						scope: {
							fields: ['id', 'volume'],
						},
					},
					{
						relation: 'type',
						scope: {
							fields: ['id', 'name', 'specialTypeId'],
							include: [{ relation: 'specialType', scope: { fields: ['id', 'name'] } }],
						},
					},
				],
			},
		},
		{ relation: 'rank' },
	],
};

export const VEHICLES_NO_CONSID_SHIFT_INCLUDE = {
	include: [
		{
			relation: 'status',
			scope: {
				fields: ['id', 'code'],
			},
		},
		{
			relation: 'type',
			scope: {
				fields: ['id', 'name'],
			},
		},
		{ relation: 'cisternBulk', scope: { fields: ['id', 'volume'] } },
		{ relation: 'callSign', scope: { fields: ['id', 'name'] } },
	],
};
