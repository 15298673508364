import React from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import { Button } from '@smartplatform/ui';
import t from 'i18n';
import store from 'client/store';
import EntityForm from './entity-form/EntityForm';
import EntitySingle from './entity-single/EntitySingle';

@inject('fireStore')
@observer
export default class EntityManager extends React.Component {
	@observable items = [];
	@observable addingItem = false;
	@observable editingItem = null;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		const { modelName, fire } = this.props;
		const where = fire ? { fireId: fire.id } : { fireId: this.props.fireStore.fire.id };
		this.items = await store.model[modelName].find({
			where,
			order: 'id asc',
		});
	};

	addItem = () => {
		this.addingItem = true;
		this.editingItem = null;
	};

	onEdit = (record) => (this.editingItem = record);

	onUpdate = () => {
		const { calculateBarrelsCount } = this.props.fireStore;
		this.onClose();
		this.init();
		if (this.props.modelName === 'TechFireTrunk') {
			calculateBarrelsCount();
		}
	};

	onClose = () => {
		this.addingItem = false;
		this.editingItem = null;
	};

	render() {
		const { buttonLabel, fire, fields, modelName } = this.props;

		return (
			<div>
				<div>
					{this.items.map((item) => {
						const isEdited = this.editingItem && this.editingItem.id === item.id;
						return isEdited ? (
							<EntityForm key={item.id} record={item} modelName={modelName} fire={fire} fields={fields} onClose={this.onClose} onUpdate={this.onUpdate} />
						) : (
							<EntitySingle onEdit={this.onEdit} key={item.id} fields={fields} modelName={modelName} record={item} onUpdate={this.onUpdate} />
						);
					})}
				</div>
				{this.addingItem ? (
					<EntityForm key='new' onClose={this.onClose} modelName={modelName} fields={fields} fire={fire} onUpdate={this.onUpdate} />
				) : (
					<div className='form-field'>
						<Button onClick={this.addItem} variant='primary'>
							{t(buttonLabel)}
						</Button>
					</div>
				)}
			</div>
		);
	}
}
