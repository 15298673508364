import { Geometry } from 'wkx';
import t from 'i18n';

export const distance = (distance, units = false) =>
	(typeof distance === 'number' && distance > 0 ? (distance / 1000).toFixed(1) : '') + (distance > 0 && units ? ` ${t('km')}` : '');

export const azimuth = (azimuth) => (typeof azimuth === 'number' ? `${Math.round(azimuth)}\u00B0` : '');
export const area = (area, units = false) => (typeof area === 'number' && area > 0 ? +area.toFixed(2) : 0) + (units ? ` ${t('ga')}` : '');
export const edge = (edge, units = false) => (typeof edge === 'number' && edge > 0 ? +edge.toFixed(2) : 0) + (units ? ` ${t('km')}` : '');

export const geoJsonToHex = (geoJson) => {
	if (!geoJson) return null;

	let hex = null;
	try {
		hex = Geometry.parseGeoJSON(geoJson).toEwkb().toString('hex');
	} catch (e) {
		console.warn('Invalid geoJSON:', geoJson, 'returning null...');
	}
	return hex;
};

export const hexToGeoJson = (hex) => {
	if (!hex) return null;

	let geoJson = null;
	try {
		geoJson = Geometry.parse(Buffer.from(hex, 'hex')).toGeoJSON();
	} catch (e) {
		console.warn('Invalid hex string:', hex, 'returning null...');
	}
	return geoJson;
};
