import { defaultsDeep } from 'lodash';
import { toJS } from 'mobx';
import store from 'client/store';
import { startOfDay } from 'date-fns';
import { endOfDay } from 'date-fns';

const layers = {
	store: 'techfires',
	counties: {
		show: false,
	},
	municipality: {
		show: false,
		minimized: false,
	},
	minerals: {
		show: false,
	},
	techfires: {
		show: false,
		minimized: false,
		filters: {
			fireDeps: [],
			garrisons: [],
		},
		ranks: {
			1: true, // 1 ранг пожара
			2: true, // 1 СБИС
			3: true, // 2 ранг пожара
			4: true, // 3 ранг пожара
		},
	},
	departures: {
		show: false,
		minimized: false,
		filters: {
			fireDeps: [],
			garrisons: [],
			visitTypes: [],
			dateOfDepartureStart: startOfDay(new Date()).setHours(8, 0, 0, 0),
			dateOfDepartureEnd: endOfDay(new Date()),
		},
	},
	heatPoints: {
		show: false,
		minimized: false,
		period: 0,
		useMVT: false,
		region: 14,
	},
	genShtab: {
		show: false,
	},
	weather: {
		show: false,
		minimized: false,
		layer: 'pm25',
	},
	wind: {
		show: false,
		minimized: false,
		fadeOpacity: 0.995,
		speedFactor: 5,
		particleLife: 128,
		opacity: 0.8,
		webgl: true,
	},
	fireDep: {
		show: false,
		countyId: 1,
	},
	adpi: {
		show: false,
		minimized: false,
		filter: {
			selectedStatuses: [],
			organizationId: null,
		},
	},
	address: {
		show: false,
		minimized: false,
		filter: {
			fields: {
				ptpCount: false,
				ktpCount: false,
				overnightStay: false,
			},
			ranks: [],
		},
	},
	waterSupply: {
		show: false,
		minimized: false,
		filter: {
			division: null,
			selectedTypes: [],
			working: null,
		},
	},
	blockedRoad: {
		show: false,
		minimized: false,
		filter: {
			willClose: null,
			сlose: null,
		},
	},
};

const defaultConfig = {
	tileSource: '2gis',
	filtersMaximized: false,
	layers,
	dynamicLayers: [],
	addressObjects: {},
};

export default function initLocal() {
	store.local.techfires = defaultsDeep(toJS(store.local.techfires), defaultConfig);
	store.local.save();
}
