import React from 'react';
import { inject, observer } from 'mobx-react';
import { Checkbox, Input, NumberInput, RecordSelect, Row } from '@smartplatform/ui';
import FileCheckIcon from 'img/icons/fires/card/file-check.svg';
import { Card, LabelField } from 'components';
import store from 'client/store';
import t from 'i18n';

@inject('card')
@observer
export class MainInfo extends React.Component {
	render() {
		const { card, disabled } = this.props;
		const { fire, onChange } = card;
		const selectProps = { noTotal: true, maxItems: 20, itemsPerPage: 1000, filter: { fields: ['id', 'name'] }, property: 'name', disabled };
		const numberInputProps = { positiveOnly: true, disabled };
		const { model } = store;
		return (
			<Card title={t('mainInfo')} icon={<FileCheckIcon />}>
				<Row className='card-row'>
					<LabelField label={t('fire.fireManager')}>
						<Input disabled={disabled} value={fire.fireManager} onChange={onChange('fireManager')} />
					</LabelField>
					<LabelField label={t('fire.jumpings')}>
						<NumberInput {...numberInputProps} alue={fire.jumpings} onChange={onChange('jumpings')} integerOnly />
					</LabelField>
					<LabelField label={t('fire.descents')}>
						<NumberInput {...numberInputProps} alue={fire.descents} onChange={onChange('descents')} integerOnly />
					</LabelField>
				</Row>

				<Row className='card-row'>
					<LabelField label={t('fire.delivered')}>
						<NumberInput {...numberInputProps} value={fire.delivered} onChange={onChange('delivered')} decimalScale={2} />
					</LabelField>
					<LabelField label={t('fire.deliveredAvia')}>
						<NumberInput {...numberInputProps} value={fire.deliveredAvia} onChange={onChange('deliveredAvia')} decimalScale={2} />
					</LabelField>
				</Row>

				<Row className='card-row'>
					<LabelField label={t('fire.notLandingReason')}>
						<RecordSelect
							{...selectProps}
							value={fire.notLandingReason}
							onChange={onChange('notLandingReason')}
							model={model.NotLandingReason}
							property='shortName'
							filter={{ fields: ['id', 'shortName'] }}
						/>
					</LabelField>
					<LabelField label={t('fire.notInspectionReason')}>
						<RecordSelect {...selectProps} value={fire.notInspectionReason} onChange={onChange('notInspectionReason')} model={model.NotInspectionReason} position={'bottomRight'}/>
					</LabelField>
				</Row>
				<Row className='card-row'>
					<LabelField label={t('fire.threat')}>
						<Checkbox disabled={disabled} value={fire.threat} onChange={onChange('threat')} />
					</LabelField>
				</Row>
				<Row className='card-row'>
					<LabelField label={t('fire.description')}>
						<textarea disabled={disabled} rows={5} value={card.fire.dynamicDescription || ''} onChange={onChange('dynamicDescription')} />
					</LabelField>
				</Row>
			</Card>
		);
	}
}
