import { inject, observer } from 'mobx-react';
import React from 'react';
import { Field, MaskedInput, Row } from '@smartplatform/ui';
import t from 'i18n';
import './style.scss';

@inject('store')
@observer
export class MainInfo extends React.Component {
	render() {
		return (
			<div className='main'>
				<div className='top'>
					<Field label={t('type')} relation='type' property='name' />
					<Field label={t('shortName')} property='shortName' />
				</div>
				<div className='center'>
					<Field label={t('region.title')} relation='region' property='name' />
					<Field label={t('county.title')} relation='county' property='name' />
					<Field label={t('city.title')} relation='city' property='name' />
				</div>
				<div className='bottom'>
					<div className='left'>
						<div className='title'>{t('organization.info')}</div>
						<Field label={t('name')} property='name' />
						<Field label={t('organization.inn')} property='inn' />
						<Field label={t('organization.kpp')} property='kpp' />
						<Field label={t('organization.address')} property='address' />
						<Field label={t('organization.factAddress')} property='factAddress' />
						<div className='contacts'>
							<Field label={t('phone')} property='phone'>
								<MaskedInput mask='+7 (999) 999-99-99' />
							</Field>
							<Field label={t('email.title')} property='email' />
						</div>
						<Field label={t('organization.comment')} property='comment' />
					</div>
					<div className='right'>
						<div className='title'>{t('organization.secondaryInfo')}</div>
						<Field label={t('organization.okato')} property='okato' />
						<Field label={t('organization.oktmo')} property='oktmo' />
						<Field label={t('organization.okved')} property='okved' />
						<Field label={t('organization.ogrn')} property='ogrn' />
						<Field label={t('organization.okpo')} property='okpo' />
						<div className='title'>{t('organization.headInfo')}</div>
						<Field label={t('organization.headName')} property='headName' />
						<Field label={t('organization.headPosition')} property='headPosition' />
					</div>
				</div>
			</div>
		);
	}
}
