import React from 'react';
import { observer } from 'mobx-react';
import { Row } from '@smartplatform/ui';
import { TextField } from 'fires/components';
import t from 'i18n';
import { observable } from 'mobx';
import store from 'client/store';
import { lowerFirstLetter } from 'client/tools';
import { formatDate } from 'client/tools';

const MODEL_NAME = 'TechFire';

@observer
export default class TechFirePopup extends React.Component {
	@observable record = null;
	@observable isLoading = true;
	@observable uniqueFierDepsName = null;
	@observable addressName = null;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		const id = this.props.record.techFireId;
		const record = await store.model[MODEL_NAME].findById(id, {
			include: [
				'rank',
				'fireDep',
				{ relation: 'wayBills', scope: { include: [{ relation: 'fireDeps_thr' }] } },
				{ relation: 'object', scope: { include: [{ relation: 'address', fields: ['id', 'name'] }] } },
			],
			where: { terminationFireTime: null, rankId: { neq: null } },
			order: 'id desc',
		});

		if (!record) return null;
		this.record = record;

		const wayBills = await store.model.TechFireWayBill.find({ where: { fireId: id } });
		const techFireWayBillFireDep = await store.model.TechFireWayBillFireDep.find({
			where: { wayBillId: { inq: wayBills.map((wayBill) => wayBill.id) } },
			include: ['fireDep'],
		});

		this.uniqueFierDepsName = [...new Set(techFireWayBillFireDep.map((techFireWayBill) => techFireWayBill?.fireDep.name))];
		this.addressName = this.record?.object?.address.name;
		this.isLoading = false;
	};

	go = () => store.route.push({ path: `/techfires/fires/tech-fires/${this.record.id}` });

	render() {
		if (this.isLoading) return null;
		const model = lowerFirstLetter(MODEL_NAME);
		return (
			<div className='map-techfire-popup'>
				<div className='title'>
					<h2>
						{t(`${model}.title`)}:{' '}
						<a style={{ cursor: 'pointer' }} onClick={this.go}>
							#{this.record?.id}
						</a>
					</h2>
				</div>
				<Row>
					<TextField label={t(`${model}.firesPlanVisits.address`)} value={this.addressName} />
					<TextField label={t(`${model}.firesPlanVisits.addressManual`)} value={this.record?.additionalInfo} />
				</Row>
				<Row>
					<TextField label={t(`${model}.rank`)} value={this.record?.rank?.name} />
					<TextField label={t(`${model}.fireDep`)} value={this.record?.fireDep?.name} />
				</Row>
				<Row>
					<TextField label={t(`${model}.status`)} value={this.record?.status?.name} />
					<TextField label={t(`${model}.messageFireDep`)} value={formatDate(this.record?.reportTime, 'dd.MM.yyyy HH:mm:ss')} />
				</Row>
				<Row>
					<TextField label={t(`${model}.firstUnit`)} value={formatDate(this.record?.arrivalTime, 'dd.MM.yyyy HH:mm:ss')} />
					<TextField label={t(`${model}.localizationTime`)} value={formatDate(this.record?.localizationTime, 'dd.MM.yyyy HH:mm:ss')} />
				</Row>
				<Row>
					<TextField label={t(`${model}.listPCHOnFire`)} value={this.uniqueFierDepsName.join(', ')} />
					<div />
				</Row>
			</div>
		);
	}
}
