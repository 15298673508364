import { Icon, Style } from 'ol/style';
import transporterIconGeo from 'img/icons/transporter.svg?url';
import { drawGeoMarker, geoJSON } from '@smartplatform/map/client';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';

import store from 'client/store';
import t from 'i18n';
import { TrackingPopup } from './TrackingPopup';
import React from 'react';

export class TrackingLayer {
	constructor(mapStore, settings = {}) {
		this.mapStore = mapStore;
		this.settings = settings;
	}
	init = async () => {
		this.layer = new VectorLayer({
			format: geoJSON,
			source: new VectorSource(),
		});
		this.layer.setZIndex(15);
		this.setVisible();
		this.mapStore.addLayer(this.layer);

		this.load();
	};

	load = async () => {
		let tracks = await store.model.ViewRequestTrack.find({
			where: {
				and: [
					{
						status: {
							inq: [
								...Object.entries(this.settings.status)
									.filter(([key, status]) => status)
									.map(([key, status]) => key),
							],
						},
					},
					{ geo: { neq: null } },
					{ ladingbillid: { neq: null } },
					{ status: { neq: null } },
				],
			},
		});

		const features = [];
		for (let track of tracks) {
			const { geo } = track;

			const marker = drawGeoMarker(geo, {
				data: {
					title: t('tracking.titleShort'),
					record: track,
					render: this.renderPopup,
				},
				style: new Style({ image: new Icon({ src: transporterIconGeo, anchor: [10, 31], anchorXUnits: 'pixels', anchorYUnits: 'pixels', scale: 0.8 }) }),
			});

			features.push(...marker);
		}

		this.layer.getSource().clear();
		this.layer.getSource().addFeatures(features);
	};

	renderPopup = (record) => {
		return <TrackingPopup record={record} />;
	};

	setVisible = () => this.layer.setVisible(this.settings.show);
}
