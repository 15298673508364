import { observable } from 'mobx';
import store from 'client/store';
import { ALARM_TYPE_PHONE, ALARM_TYPE_MANUAL } from 'techfires/constants';
import { drawGeoMarker, geoJSON } from '@smartplatform/map/client';
import { Icon, Style } from 'ol/style';
import markerIconGeo from 'img/icons/marker.svg?url';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { getDateForShift } from 'techfires/helper';
import { AddressLayer, WaterSupplyLayer } from 'components';

const UPDATE_FIELDS = ['detectTime', 'reportTime'];

export default class TechFireCreationStore {
	@observable fire = null;
	@observable alarmMessage = null;
	@observable alarmType = null;
	@observable wayBill = null;
	@observable addedFireDep = null;
	@observable mapInitialized = false;
	@observable isSaving = false;
	@observable isLoading = false;
	@observable firstRank = null;
	@observable userCity = 'Якутск';
	@observable techFireId = null;

	// карта ПЧ, ключ - id ПЧ, свойство - объект вида { fireDep, vehicles, selectedVehicles }
	// vehicles - редактируемый массив
	@observable fireDeps = {};

	@observable vehicles = [];
	@observable errors = [];

	constructor(path) {
		this.fire = new store.model.TechFire();
		this.fire.fireDate = new Date();
		this.path = path;
		this.alarmId = store.route.params ? parseInt(store.route.params.notifier) || null : null;
		this.techFireId = store.route.params ? parseInt(store.route.params.techFire) || null : null;
		this.init();
	}

	init = async () => {
		this.isLoading = true;
		if (this.alarmId) {
			try {
				const existingFires = await store.model.TechFire.find({
					where: { alarmMessageId: this.alarmId },
					fields: ['id'],
					order: 'id desc',
				});
				if (existingFires.length > 0) {
					store.route.push({ path: `${this.path}/${existingFires[0].id}`, params: {} });
				}

				const alarmMessage = await store.model.FireAlarmMessage.findById(this.alarmId, {
					include: [
						'source',
						{
							relation: 'adpiDevice',
							scope: {
								include: ['organization'],
							},
						},
						{
							relation: 'object',
							scope: {
								include: ['address', 'rank', { relation: 'fireDep', scope: { fields: ['id', 'name'] } }],
							},
						},
						'status',
					],
				});

				this.alarmType = alarmMessage.source.code;

				this.fire.alarmMessage = alarmMessage;
				this.fire.detectTime = new Date(alarmMessage.date) - 60000;
				this.fire.fireDate = this.fire.reportTime = alarmMessage.date;
				this.fire.source = alarmMessage.source;
				this.fire.status = alarmMessage.status;
				this.alarmMessage = alarmMessage;
			} catch (e) {
				console.warn(e);
			}
		} else {
			const sources = await store.model.FireAlarmMessageSource.find({ where: { code: ALARM_TYPE_PHONE } });
			this.fire.detectTime = new Date() - 60000;
			this.fire.fireDate = this.fire.reportTime = new Date();
			this.fire.source = sources[0] || null;
		}
		this.firstRank = await store.model.TechFireRank.find({ where: { name: '1 РАНГ ПОЖАРА' } });
		this.fire.rank = this.firstRank[0] || null;
		this.fire.garrison = await store.model.user.garrison;
		await this.getUserCity();
		this.isLoading = false;
	};

	getUserCity = async () => {
		try {
			const userOrganization = await store.model.user.organization;
			const userCity = await userOrganization.city;
			this.userCity = userCity.name;
		} catch (e) {}
	};

	getWayBillInstance = async (wayBillStore) => {
		this.wayBillStore = wayBillStore;

		if (this.alarmMessage?.object) {
			await this.onObjectChange(this.alarmMessage.object);
		}
		if (this.techFireId) {
			try {
				const techFire = await store.model.TechFireAddress.findById(this.techFireId, { include: ['address', 'fireDep', 'rank'] });
				this.onObjectChange(techFire);
			} catch (e) {
				console.warn(e);
			}
		}
	};

	onMapInit = (mapStore) => {
		this.mapStore = mapStore;
		this.layer = new VectorLayer({
			format: geoJSON,
			source: new VectorSource(),
		});
		this.mapStore.addLayer(this.layer);
		this.waterSupplyLayer = new WaterSupplyLayer(mapStore, {
			show: true,
			filter: {
				selectedTypes: [],
			},
		});
		this.addressLayer = new AddressLayer(mapStore, {
			show: true,
			filter: {
				fields: {
					overnightStay: true,
				},
				ranks: [],
			},
		});
		this.waterSupplyLayer.init(mapStore);
		this.addressLayer.init(mapStore);
		this.mapInitialized = true;
		if (this.fire.object) {
			this.drawObjectOnMap();
		}
	};

	drawObjectOnMap = () => {
		const { geo } = this.fire.object.address;
		const marker = drawGeoMarker(geo, {
			style: new Style({
				image: new Icon({
					src: markerIconGeo,
					anchor: [9, 45],
					anchorXUnits: 'pixels',
					anchorYUnits: 'pixels',
				}),
			}),
		});
		this.layer.getSource().clear();
		this.layer.getSource().addFeatures([...marker]);
		this.mapStore.fitGeo(geo, { maxZoom: 18, padding: [50, 50, 50, 50] });
	};

	save = async () => {
		this.isSaving = true;
		const { start, end } = getDateForShift(this.fire.detectTime);

		const globalShifts = await store.model.TechFireShift.find({
			where: {
				and: [{ date: { gte: start } }, { date: { lte: end } }],
			},
			order: 'date desc',
		});

		console.log('waybill', wayBill);

		this.fire.globalShift = globalShifts.length > 0 ? globalShifts[0] : null;
		console.log('globalShift', this.fire.globalShift);

		await this.fire.save();

		if (this.fire.source?.code === ALARM_TYPE_PHONE || this.fire.source?.code === ALARM_TYPE_MANUAL) {
			const alarmMessage = new store.model.FireAlarmMessage();
			alarmMessage.source = this.fire.source;
			alarmMessage.object = this.fire.object;
			alarmMessage.status = this.fire.status;
			alarmMessage.reportingPersonName = this.fire.reportingPersonName;
			alarmMessage.reportingPersonPhone = this.fire.reportingPersonPhone;
			alarmMessage.reportingPersonAddress = this.fire.reportingPersonAddress;
			await alarmMessage.save();
			this.fire.alarmMessage = alarmMessage;
			await this.fire.save();
		}

		this.wayBillStore.rank = this.fire.rank;
		const wayBill = this.wayBillStore.record;
		wayBill.fireId = this.fire.id;
		wayBill.detectTime = this.fire.detectTime;
		wayBill.reportTime = this.fire.reportTime;
		await this.wayBillStore.save();

		store.route.push({ path: `${this.path}/${this.fire.id}`, params: {} });
	};

	// TODO: export waybill
	export = () => {
		console.log('TODO: export waybill');
	};

	onObjectChange = async (object) => {
		this.errors = [];
		console.log('onObjectChange', object, object?.rank, object?.address);
		this.fire.object = object;
		this.fire.fireDep = null;
		this.fireDeps = {};
		if (object?.address) {
			if (object.rank) {
				this.fire.rank = object.rank;
			}
			if (this.mapInitialized) {
				this.drawObjectOnMap();
			}

			const fireDep = (await object.address.getFireDep())[0];
			if (fireDep) {
				await this.onFireDepChange(fireDep);
			}
		}
	};

	onFireDepChange = async (fireDep) => {
		this.fire.fireDep = fireDep;

		if (fireDep) {
			this.wayBillStore.addFireDeps(fireDep, this.fire.rank);

			const isBlockedRoadOnFireDep = await store.model.TechFireBlockedRoad.isBlockedRoadOnFireDep({ fireDepId: fireDep.id });

			isBlockedRoadOnFireDep &&
				store.ui.setNotification({
					message: 'Внимание. В районе выезда имеются перекрытые участки дорог!',
					type: 'WARNING',
					isAlarm: false,
				});
		}
	};

	onObjectReset = () => {
		this.fire.object = null;
		this.layer.getSource().clear();
		this.wayBillStore.fireDeps = {};
		this.fire.rank = this.firstRank[0] || null;
	};

	onChange = (field) => (value) => {
		if (UPDATE_FIELDS.includes(field)) {
			this.fireDeps = {};
			if (this.fire.object) this.wayBillStore.addFireDep(this.fire.object.fireDep);
			if (field === 'reportTime') this.fire.detectTime = this.fire.reportTime - 60000;
		}
		if (field === 'rank' && this.fire.object?.fireDep) {
			this.wayBillStore.addFireDeps(this.fire.object.fireDep, this.fire.rank);
			this.onFireDepChange(this.fire.object.fireDep);
		}
	};
}
