import React from 'react';
import Chart from 'chart.js';
import { Card } from 'components';
import LineChartIcon from 'img/icons/fires/dashboard/line-chart.svg';
import { Loader, Select } from '@smartplatform/ui';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';

@inject('store')
@observer
export default class CountEquipmentByStatus extends React.Component {
	@observable loading;

	constructor(props) {
		super(props);
		this.store = props.store;
		this.store.fetchRecordsEquipmentByStatus();
	}

	onChange = async (value) => {
		this.loading = true;
		this.store.ChartEquipmentStatusFD = value;
		await this.store.fetchRecordsEquipmentByStatus();
		this.loading = false;
	};

	render() {
		const { fireDepartments, ChartEquipmentStatusFD, recordsEquipmentByStatus } = this.store;

		const onChart = (el) => {
			if (!recordsEquipmentByStatus.length) return null;

			if (!el) {
				if (this.Chart) this.Chart.destroy();
				return;
			}

			const style = {
				borderWidth: 0,
				pointRadius: 0.5,
				barPercentage: 0.9,
				categoryPercentage: 1.0,
				// barThickness: 40,
			};
			const dataChart = {
				labels: recordsEquipmentByStatus.map((equipment) => ''),
				datasets: [
					{
						label: '',
						data: recordsEquipmentByStatus.map((equipment) => equipment.count),
						backgroundColor: recordsEquipmentByStatus.map((equipment) => equipment.color),
						borderColor: recordsEquipmentByStatus.map((equipment) => equipment.color),
						...style,
					},
				],
			};

			el.parentNode.scrollLeft = el.scrollWidth;

			this.Chart = new Chart(el, {
				type: 'bar',
				data: dataChart,
				options: {
					tooltips: {
						enabled: false,
					},
					hover: {
						mode: null,
					},
					responsive: true,
					maintainAspectRatio: false,
					legend: {
						display: false,
					},
					scales: {
						xAxes: [
							{
								ticks: {
									beginAtZero: true,
								},
							},
						],
						yAxes: [
							{
								ticks: {
									beginAtZero: true,
								},
							},
						],
					},
				},
			});
		};

		return (
			<Card icon={<LineChartIcon />} title='Количество техники в ПЧ' className='chart-count-equipment'>
				<div className='filter-wrapper'>
					<div className='filter-item'>
						<label>Пожарная часть</label>
						<Select
							className='w-auto mr-2'
							noTotal
							maxItems={10}
							itemsPerPage={1000}
							noSearch
							isRequired
							items={fireDepartments}
							value={ChartEquipmentStatusFD}
							onChange={(fireDepartment) => this.onChange(fireDepartment)}
							width={200}
						/>
					</div>
				</div>
				{this.loading ? (
					<Loader />
				) : !recordsEquipmentByStatus.length ? (
					'Нет данных'
				) : (
					<>
						<canvas ref={onChart} width={640} height={100} />
						<div className='legend'>
							{recordsEquipmentByStatus.map((equipment, index) => {
								return (
									<div className='item-legend' key={index}>
										<span className='color' style={{ backgroundColor: equipment.color }} />
										{equipment.statusName}
									</div>
								);
							})}
						</div>
					</>
				)}
			</Card>
		);
	}
}
