import React from 'react';
import { Style, Icon } from 'ol/style';
import VectorTileLayer from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';
import { createXYZ } from 'ol/tilegrid';
import { get as getProj } from 'ol/proj';
import MVT from 'ol/format/MVT';
import store from 'client/store';
import HydrantsPopup from './Popup';
import t from 'i18n';
import connected_svg from './svg/connected.svg?url';
import not_connected_svg from './svg/not-connected.svg?url';
import connection_lost_svg from './svg/connection-lost.svg?url';
import { CONNECTED, NOT_CONNECTED, CONNECTION_LOST } from 'client/constants';

const statusSvgMap = {
	[CONNECTED]: connected_svg,
	[NOT_CONNECTED]: not_connected_svg,
	[CONNECTION_LOST]: connection_lost_svg,
};

const TILE_SIZE = 512;

export default class AdpiLayer {
	constructor(mapStore, settings = {}) {
		this.mapStore = mapStore;
		this.settings = settings;
	}

	init = async () => {
		this.source = new VectorTileSource({
			format: new MVT(),
			tileGrid: createXYZ({
				extent: getProj('EPSG:3857').getExtent(),
				maxZoom: 18,
				tileSize: TILE_SIZE,
			}),
			tileUrlFunction: this.tileUrlFunction,
			tileLoadFunction: this.tileLoadFunction(this.renderPopup),
		});

		this.layer = new VectorTileLayer({
			source: this.source,
			style: this.getStyle,
		});

		this.layer.setZIndex(3);
		this.setVisible();
		this.mapStore.addLayer(this.layer);
	};

	getStyle = (feature) => {
		const { record } = feature.getProperties();
		return new Style({
			image: new Icon({
				src: statusSvgMap[record.adpistatuscode] || not_connected_svg,
				scale: 0.7,
				anchor: [17.5, 30],
				anchorXUnits: 'pixels',
				anchorYUnits: 'pixels',
			}),
		});
	};

	tileUrlFunction = (coords) => {
		const { selectedStatuses, organizationId } = this.settings.filter;
		let filter = '&filter=';
		if (selectedStatuses.length) filter += ` statusId in (${selectedStatuses})`;
		if (organizationId) filter += ` and organizationId=${organizationId}`;
		return `/api/mvt?model=ViewFireAlarmObject&x=${coords[1]}&y=${coords[2]}&z=${coords[0]}${filter}&noCache=1&columns=id,adpiStatusCode&buffer=${TILE_SIZE}`;
	};

	tileLoadFunction = (render) => (tile, url) => {
		tile.setLoader(function (extent, resolution, projection) {
			fetch(url).then(function (response) {
				response.arrayBuffer().then(function (data) {
					const format = tile.getFormat(); // ol/format/MVT configured as source format
					const renderFeatures = format.readFeatures(data, {
						extent: extent,
						featureProjection: projection,
					});
					renderFeatures.forEach((f) => {
						const record = f.getProperties();
						f.properties_ = {
							title: t('adpiDevice.title') + ' №' + record.id,
							record,
							render,
						};
					});
					tile.setFeatures(renderFeatures);
				});
			});
		});
	};

	onStatusToggle = (id) => {
		const { selectedStatuses } = this.settings.filter;
		const index = selectedStatuses.findIndex((i) => i === id);
		~index ? selectedStatuses.splice(index, 1) : selectedStatuses.push(id);
		store.local.save();
		this.source.refresh();
	};

	onOrgChange = (record) => {
		this.settings.filter.organizationId = record ? record.id : null;
		store.local.save();
		this.source.refresh();
	};

	setVisible = () => this.layer.setVisible(this.settings.show);
	renderPopup = (record) => <HydrantsPopup record={record} />;
}
