import React from 'react';
import { observer } from 'mobx-react';
import { Loader, Row } from '@smartplatform/ui';
import { TextField } from 'fires/components';
import { observable } from 'mobx';

import store from 'client/store';
import t from 'i18n';

@observer
export class TrackingPopup extends React.Component {
	@observable ladingbill = null;
	@observable supplies = null;

	go = () => store.route.push({ path: `/delivery/tracking/${this.ladingbill?.id}` });

	componentDidMount = async () => {
		this.ladingbill = await this.props.record.ladingbill;
		this.supplies = await this.ladingbill.supplies();
	};

	render() {
		return (
			<div className='map-fire-alarm-object-popup'>
				<div className='title'>
					<h2>
						{t(`tracking.title`)}:{' '}
						<a style={{ cursor: 'pointer' }} onClick={this.go}>
							#{this.ladingbill?.id}
						</a>
						{!this.ladingbill && <Loader size={14} />}
					</h2>
				</div>
				<Row>
					<TextField label={t('tracking.trackNumber')} value={this.ladingbill?.trackNumber} />
				</Row>
				<Row>
					<TextField label={t('tracking.nomenclatureNames')} value={this.supplies?.map((supplie) => supplie.nomenclatureCode)?.join(', ')} />
				</Row>
			</div>
		);
	}
}
