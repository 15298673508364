import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Loader, Form, Field } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';
import { Attachments } from 'components';

@observer
export default class Edit extends React.Component {
	@observable record = null;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		const id = parseInt(this.props.match.params.id);
		if (id) {
			this.record = await store.model.PermafrostOrganization.findById(id, {
				include: ['attachments', 'users'],
			});
		} else {
			this.record = new store.model.PermafrostOrganization();
		}
	};

	redirectBack = () => store.route.push({ path: this.props.path });

	render() {
		if (!this.record) return <Loader />;

		return (
			<>
				<h1>
					{t('permafrostOrganization.title')} {this.record.id && `#${this.record.id}`}
				</h1>
				<Form record={this.record} className='w-50' stay onSave={this.redirectBack} onDelete={this.redirectBack} disableDelete={this.record.users().length} disableSave={!this.record.name}>
					<Field property='name' label={t('name')} />
					<Field property='contacts' label={t('contacts')} />
					<Field property='approval' label={t('permafrostOrganization.approval')} />
					<Attachments record={this.record} canUpload={true} withFormContext={!this.record.id} />
				</Form>
			</>
		);
	}
}
