import { observer } from 'mobx-react';
import React from 'react';
import t from 'i18n';
import { Column } from '@smartplatform/ui';
import { Table } from 'components/table-new';
import store from 'client/store';
import TemperatureTubeStore from './store';
import {ReportToolbar, Toolbar} from 'components';
import PagerWithPerPage from '../../components/pager/PagerWithPerPage';
import { fio, formatDate } from 'client/tools';
import './style.scss'

const FIRST_DEPTH_COLUMN = 9;

@observer
export default class List extends React.Component {
	constructor(props) {
		super(props);
		this.store = new TemperatureTubeStore();
	}

	onRowClick = (record) => store.route.push({ path: `${this.props.match.path}/${record.id}` });

	paintRedColor = (rec, index, depthIndex) => {
		const { depths } = this.store;
		const { minTemperature, maxTemperature } = rec.record;
		if (minTemperature == null || maxTemperature == null || !depths[rec.row]?.depthvalues?.length || rec.record.depths()[depthIndex]?.depth < 3) {
			return '';
		}
		const depth = depths[rec.row]?.depthvalues[index];
		return depth < maxTemperature || depth > minTemperature ? 'color-red font-weight-bold' : '';
	};

	render() {
		const { page, perPage, totalCount, onChange, query, onQueryUpdate, onRowClick, search, onSearch, maxTempColumn, onLoadEnd, measurementCount, depths } =
			this.store;
		const tableProps = {
			model: store.model.ViewPermafrostTemperatureTube,
			query,
			onQueryUpdate,
			filters: true,
			onRowClick,
			onLoadEnd,
			skeletonRow: true,
			infinityScrollPartSize: perPage,
		};
		const tempColumn = new Array(maxTempColumn).fill(1);

		return (
			<div className='measurements-tube-list'>
				<div className='top'>
					<Toolbar>
						<Toolbar.SearchIconInput value={search} onChange={onSearch} />
						<span className='measurements-count'>
							{t('permafrost.measurementsCount')} {measurementCount}
						</span>
						<ReportToolbar reportCode={'TemperatureTubesMeasurements'} reportModel={'PermafrostReport'} showPreview={false} showPrint={false} />
					</Toolbar>
				</div>
				<div>
					<PagerWithPerPage perPage={perPage} page={page} onChange={onChange} onRowClick={onRowClick} totalCount={totalCount} />
					<div className='overflow-auto'>
						<Table {...tableProps}>
							<Column property='isApproved' label={t('permafrost.approved')} width={100} />
							<Column property='objectName' label={t('permafrost.objects.name')} width={600} />
							<Column property='addressName' label={t('permafrost.buildings')} width={600} />
							<Column property='id' label={t('id')} width={50} />
							<Column property='number' label={t('permafrost.number')} width={100} />
							<Column property='maxDepthName' label={t('permafrost.temperatureTube.maxDepth')} width={150} />
							<Column property='statusName' label={t('status')} width={100} />
							<Column label={t('date')} width={120}>
								{(rec) => <span>{depths.length ? formatDate(depths[rec.row]?.date) : '-'}</span>}
							</Column>
							<Column relation='owner' computed={(owner) => fio(owner)} label={t('user.title')} width={200} />
							{tempColumn.map((x, i) => (
								<Column label={t(`Темп ${i + 1}`)} key={i} width={80}>
									{(rec) => (
										<span className={`${this.paintRedColor(rec, rec.column - FIRST_DEPTH_COLUMN, i)}`}>
											{depths.length && depths[rec.row]?.depthvalues?.length ? depths[rec.row]?.depthvalues[rec.column - FIRST_DEPTH_COLUMN] : null}
										</span>
									)}
								</Column>
							))}
						</Table>
					</div>
				</div>
			</div>
		);
	}
}
