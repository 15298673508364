import { observable } from 'mobx';
import { ListStore } from 'components';
import store from 'client/store';
import { PERSONNEL_LIST_INCLUDE } from './constants';

export default class PersonnelListStore extends ListStore {
	@observable fireDepSentry = null;
	@observable rank = null;
	@observable position = null;
	@observable status = null;
	@observable person = null;
	@observable statuses = [];

	constructor(path, fireDepId) {
		super({
			path,
			filters: { ...PERSONNEL_LIST_INCLUDE, where: { fireDepShiftId: null, fireDepId } },
			model: store.model.ViewFireDepPersonnel,
		});
		this.init();
	}

	init = async () => {
		this.statuses = await store.model.FireDepPersonnelStatus.find({ where: { code: { neq: 'FF' } }, fields: ['id', 'name', 'code'] });
	};

	onChangeFilters = (prop) => (value) => {
		this[prop] = value;
		this.changeFilter();
	};

	changeFilter = () => {
		let where = { and: [] };
		if (this.rank) where.and.push({ rankId: this.rank.id });
		if (this.position) where.and.push({ positionId: this.position.id });
		if (this.status) where.and.push({ statusId: this.status.id });
		if (this.person) {
			where.and.push({ lastName: this.person.lastName });
			where.and.push({ firstName: this.person.firstName });
			where.and.push({ middleName: this.person.middleName });
		}
		if (this.fireDepSentry?.personnel()) {
			where.and.push({ id: { inq: this.fireDepSentry.personnel().map((i) => i.id) } });
		}

		where = { ...this.filters.where, ...where };
		this.updateFilters({ ...this.filters, where });
	};

	onStatusSelect = async (status, record) => {
		const personnel = await store.model.FireDepPersonnel.findById(record.id, { PERSONNEL_LIST_INCLUDE });
		personnel.statusId = status?.id || null;
		personnel.statusCode = status?.code || null;
		personnel.statusName = status?.name || null;
		await personnel.save();
		this.reload();
	};
}
