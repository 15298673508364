import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Loader, Table, Column, DateValue, DatePicker } from '@smartplatform/ui';
import { fio } from 'client/tools';
import store from 'client/store';
import t from 'i18n';
import { isRadiotelephonist } from 'techfires/shifts/constants';
import { Toolbar } from 'components';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import GarrisonSelect from 'techfires/components/garrison-select/GarrisonSelect';
import '../style.scss';

@observer
export default class List extends React.Component {
	@observable records = [];
	@observable isLoading = true;
	@observable date = null;
	@observable garrison = null;

	constructor(props) {
		super(props);
		this.init();
	}

	onChange = (prop) => (value) => {
		if (prop) {
			this[prop] = value;
			this.init();
		}
	};

	get whereFilter() {
		const where = { and: [] };
		if (this.date) {
			where.and.push({ date: { between: [startOfDay(new Date(this.date)), endOfDay(new Date(this.date))] } });
		}
		if (this.garrison) where.and.push({ garrisonId: this.garrison.id });
		return where;
	}

	init = async () => {
		this.records = await store.model.TechFireShift.find({
			where: this.whereFilter,
			include: [{ relation: 'owner' }, { relation: 'garrison', scope: { fields: ['name'] } }],
			order: 'id desc',
		});
		this.isLoading = false;
	};

	create = () => store.route.push({ path: `/techfires/shifts/create` });

	go = (record) => store.route.push({ path: `/techfires/shifts/${record.id}` });

	renderDispatcher = ({ record }) => fio(record.owner);

	render() {
		if (this.isLoading) return <Loader />;

		const { isMain } = this.props;

		return (
			<div className='list'>
				<Toolbar classNames='actions'>
					{isMain && !isRadiotelephonist() && <Toolbar.AddButton onClick={this.create} />}
					<DatePicker onChange={this.onChange('date')} value={this.date} placeholder={t('date')} />
					<GarrisonSelect value={this.garrison} onChange={this.onChange('garrison')} width={200} />
				</Toolbar>

				<Table rows={this.records} onRowClick={this.go}>
					<Column property='id' label={t('id')} width={50} />
					<Column property='date' label={t('date')} width={100}>
						<DateValue format='dd.MM.yyyy' />
					</Column>
					<Column property='name' label={t('name')} />
					<Column relation='garrison' property='name' label={t('garrison')} width={180} className='text-center' />
					<Column label={t('fireDepShift.dispatcher')}>{this.renderDispatcher}</Column>
				</Table>
			</div>
		);
	}
}
