import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observable } from 'mobx';
import { MenuItem } from './MenuItem';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import store from 'client/store';
import { AutoAnimate } from '../auto-animate/AutoAnimate';

@observer
export class SubMenu extends Component {
	@observable isHidden = !this.isActive;

	static propTypes = {
		title: PropTypes.string,
		items: PropTypes.array,
	};

	changeVisibility = () => (this.isHidden = !this.isHidden);

	get isActive() {
		const currentRoute = store.route.path + '/';
		return !!this.props.items?.find(({ path: itemPath }) => currentRoute.includes(itemPath + '/'));
	}

	render() {
		const { title, items } = this.props;

		return (
			<div className={classNames('submenu', { hidden: this.isHidden })}>
				<div className={classNames('title', { 'admin-active': this.isActive })} onClick={this.changeVisibility}>
					<FontAwesomeIcon icon={faChevronDown} />
					<span>{title}</span>
				</div>
				<AutoAnimate>
					{!!items?.length && !this.isHidden && (
						<div className='items'>
							{items.map((item, index) => (
								<MenuItem {...item} key={title + index} />
							))}
						</div>
					)}
				</AutoAnimate>
			</div>
		);
	}
}
