import React from 'react';
import { observer, Provider } from 'mobx-react';
import TechFireStore from './store';
import './style.scss';
import Actionbar from './actionbar/Actionbar';
import Sidebar from './sidebar/Sidebar';
import Main from './main/Main';
import { Loader } from '@smartplatform/ui';

@observer
export default class TechFireCard extends React.Component {
	constructor(props) {
		super(props);
		const { id } = props.match.params;
		this.store = new TechFireStore(id);
		this.store.path = props.path;
	}

	componentDidUpdate(prevProps) {
		if (this.props.match.params.id !== prevProps.match.params.id) {
			this.store = new TechFireStore(this.props.match.params.id);
		}
	}
	back = () => this.props.history.goBack();

	render() {
		const { fire, isLoading } = this.store;
		if (isLoading) return <Loader />;
		const path = this.props.path + `/${fire.id}`;

		return (
			<Provider fireStore={this.store}>
				<Actionbar />
				<div className='techfire-card-page'>
					<Sidebar />
					<Main path={path} />
				</div>
			</Provider>
		);
	}
}
