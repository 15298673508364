import React from 'react';
import t from 'i18n';
import { PermafrostOrganization } from './dictionaries';

export const DICTIONARIES_MODELS = [
	{
		title: t('permafrost.objectsMenu'),
		items: [
			{ modelName: 'PermafrostObjectAdministrativeRegion' },
			{ modelName: 'PermafrostObjectConstructionStage' },
			{ modelName: 'PermafrostObjectConstructive' },
			{ modelName: 'PermafrostObjectEnergyEfficiencyClass' },
			{ modelName: 'PermafrostObjectFoundationType' },
			{ modelName: 'PermafrostObjectHouseStatus' },
			{ modelName: 'PermafrostObjectInspector' },
			{ modelName: 'PermafrostObjectQuarter' },
		],
	},
	{
		title: t('permafrost.tubeMenu'),
		items: [
			{ modelName: 'PermafrostTemperatureTubeStatus' },
			{ modelName: 'PermafrostTemperatureTubeType' },
			{ modelName: 'PermafrostTemperatureTubeMaxDepth' },
		],
	},
	{
		title: t('permafrost.marksMenu'),
		items: [{ modelName: 'PermafrostLevelingMarkStatus' }, { modelName: 'PermafrostLevelingMarkType' }, { modelName: 'PermafrostLevelingMarkHeightSystem' }],
	},
	{
		title: t('permafrostOrganization.plural'),
		items: [{ modelName: 'PermafrostOrganization', editComponent: PermafrostOrganization.Edit, listComponent: PermafrostOrganization.List }],
	},
];

export const OBJECT_INCLUDE = {
	include: [
		'administrativeRegion',
		'builder',
		'constructionStage',
		'constructive',
		'contractor',
		'customer',
		'designer',
		'energyEfficiencyClass',
		'foundationType',
		'houseStatus',
		'inspector',
		'operatingOrganization',
		'quarter',
		'county',
		'city',
		'address',
	],
};
export const TUBES_INCLUDE = { include: [{ relation: 'object', scope: { include: ['address'] } }, 'status', 'type', 'maxDepth', 'depths'] };
export const MARKS_INCLUDE = { include: ['object', 'status', 'type', 'heightSystem'] };
