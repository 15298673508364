import React from 'react';
import { inject, observer } from 'mobx-react';
import EmergencySituationIcon from 'img/icons/fires/dashboard/emergency-situation.svg';
import { Card } from 'components';
import t from 'i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const EMERGENCY_TYPE = ['CHS', 'OPR'];

@inject('dashboardStore')
@observer
export default class EmergencySituation extends React.Component {
	render() {
		const { dashboardStore } = this.props;
		const { data } = dashboardStore || {};
		const { emergencySituation } = data || {};

		let content;
		if (emergencySituation && emergencySituation.CHS.length === 0 && emergencySituation.OPR.length === 0) {
			content = (
				<>
					<FontAwesomeIcon icon={faCheckCircle} className='ok-icon' /> {t('fireDashboard.noEmergencySituation')}
				</>
			);
		} else {
			content = (
				<table className='dashboard-table'>
					<thead>
						<tr>
							<th>Территории действия режимов</th>
							<th width='33%'>№ акта введения</th>
							<th width='33%'>Район</th>
						</tr>
					</thead>
					<tbody>
						{emergencySituation &&
							EMERGENCY_TYPE.map((type) =>
								emergencySituation[type].map((record, i) => (
									<tr key={i}>
										{i === 0 ? <td>{t(`fireDashboard.${type}`)}</td> : <td />}
										<td className='text-dark'>{record.startact}</td>
										{!record.countyId ? <td className='text-dark'>На всей территории республики</td> : <td className='text-dark'>{record.countyName}</td>}
									</tr>
								))
							)}
					</tbody>
				</table>
			);
		}

		return (
			<Card icon={<EmergencySituationIcon />} title={t('fireDashboard.emergencySituation')} className='emergency-situation'>
				{content}
			</Card>
		);
	}
}
