import React from 'react';
import t from 'i18n';
export { getStatusColor } from './getStatusColor';
export { getVehiclesValues } from './getVehiclesValues';
export { getPeopleValues } from './getPeopleValues';
export { calcSameRecordsValues } from './calcSameRecordsValues';
export { calcRecordsField } from './calcRecordsField';
export { getSeparatedGeoValues } from './getSeparatedGeoValues';
export * from './getMostRepeatedElement';

export const fireName = (fire) => (
	<>
		<strong>#{fire.fireNumber}</strong> {fire.county ? fire.county.name : ''}
	</>
);
export const cityName = (city) => (
	<>
		<span>{city.name} </span>
		{city.county?.name ? (
			<em>
				<b>{city.county.name}</b>
			</em>
		) : (
			''
		)}
	</>
);
export const colorName = (record, size = 10, margin = 5) => (
	<>
		{record?.color && (
			<span style={{ background: record.color, width: size, height: size, borderRadius: 50, marginRight: margin, display: 'inline-block' }}></span>
		)}
		{record?.name && <span>{record.name}</span>}
	</>
);

// открытие таблиц в экселе
export const downloadReport = ({ id, name }) => {
	const htmlTable = document.getElementById(id);
	const html = htmlTable.outerHTML;
	const downloadLink = document.createElement('a');
	downloadLink.href = 'data:application/vnd.ms-excel,\uFEFF ' + encodeURIComponent(html);
	downloadLink.download = `${name}.xls`;
	downloadLink.click();
};

export const MONTHES = [
	{ value: 1, label: t('month.january') },
	{ value: 2, label: t('month.february') },
	{ value: 3, label: t('month.march') },
	{ value: 4, label: t('month.april') },
	{ value: 5, label: t('month.may') },
	{ value: 6, label: t('month.june') },
	{ value: 7, label: t('month.july') },
	{ value: 8, label: t('month.august') },
	{ value: 9, label: t('month.september') },
	{ value: 10, label: t('month.october') },
	{ value: 11, label: t('month.november') },
	{ value: 12, label: t('month.december') },
];

export const getMonthLabel = (value) => MONTHES.find((month) => month.value === value).label;
