import React from 'react';
import { observer, inject } from 'mobx-react';
import store from 'client/store';
import ChiefIcon from 'img/icons/techfires/card/user-check.svg';
import { EntityManager } from '../../../../components';
import { FieldBasedCard } from '../../../../components';

@inject('fireStore')
@observer
export default class Chiefs extends React.Component {
	handleStatusChange = (areFieldsFilled) => {
		this.props.fireStore.updateFieldStatuses('chief', areFieldsFilled);
	};

	render() {
		const { fire } = this.props.fireStore;
		const fields = [{ type: 'select', property: 'chief', options: { model: store.model.FireDepPersonnel } }];

		return (
			<FieldBasedCard title='fireDep.chief' icon={ChiefIcon} fields={[fire?.chiefs().length > 0]} rows={[]} statusKey='chief' fireStore={this.props.fireStore}>
				<EntityManager modelName='TechFirePersonnelChief' buttonLabel='techFireChief.add' fire={fire} fields={fields} fireStore={this.props.fireStore} />
			</FieldBasedCard>
		);
	}
}
