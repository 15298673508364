import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Popover } from '@smartplatform/ui';
import './style.scss';

export const Hint = ({ text = '', position = 'top', size = 'xs', offset = 0, trigger = 'click', className }) =>
	text && (
		<Popover content={text} className='hint-component' portalClassName='hint-component-popup' offset={offset} position={position} trigger={trigger}>
			<FontAwesomeIcon icon={faInfoCircle} size={size} className={className} />
		</Popover>
	);

Hint.propTypes = {
	size: PropTypes.oneOf(['xs', 'lg', 'sm', '1x', '2x', '3x', '4x', '5x', '6x', '7x', '8x', '9x', '10x']),
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	position: PropTypes.string,
	offset: PropTypes.number,
	trigger: PropTypes.string,
	className: PropTypes.string,
};

export default Hint;
