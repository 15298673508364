import React from 'react';
import { inject, observer } from 'mobx-react';
import { DatePicker, RecordSelect } from '@smartplatform/ui';

import store from 'client/store';
import t from 'i18n';

@inject('report')
@observer
export class Filters extends React.Component {
	render() {
		const { report } = this.props;

		return (
			<div className='filters'>
				<div className='row'>
					{/*<div className='col-3'>*/}
					{/*	<div className='form-field'>*/}
					{/*		<label>{t('date1')}</label>*/}
					{/*		<DatePicker showTimeSelect={false} showClearButton={false} value={report.startDate} onChange={report.onStartDateChange} />*/}
					{/*	</div>*/}
					{/*</div>*/}

					{/*<div className='col-3'>*/}
					{/*	<div className='form-field'>*/}
					{/*		<label>{t('date2')}</label>*/}
					{/*		<DatePicker showTimeSelect={false} showClearButton={false} value={report.endDate} onChange={report.onEndDateChange} />*/}
					{/*	</div>*/}
					{/*</div>*/}
				</div>
			</div>
		);
	}
}
