import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Switch, Redirect, Route } from 'react-router-dom';
import { Tabs, Tab, Loader } from '@smartplatform/ui';
import WayBillEdit from './Edit';
import store from 'client/store';
import t from 'i18n';
import './style.scss';

@observer
export class List extends React.Component {
	@observable records = [];
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.isLoading = true;
		this.records = await store.model.TechFireWayBill.find({ where: { planVisitId: this.props.firePlanVisitId }, order: 'id asc' });
		this.isLoading = false;
	};

	onSave = async (record) => {
		await this.init();
		store.route.push({ path: `${this.props.match.url}/${record.id}` });
	};

	render() {
		if (this.isLoading) return <Loader />;
		const { url } = this.props.match;

		return (
			<div className='techfire-waybills'>
				<Tabs>
					<Route path={url} exact>
						<Redirect to={url + `/${this.records[0]?.id || 'create'}`} />
					</Route>
					{this.records.map((record, i) => {
						const props = { key: record.id, path: url + `/${record.id}`, title: `${t('techFireWayBill.title')} #${i + 1}` };
						return <Tab {...props} />;
					})}
					<Tab path={url + '/create'} title={t('add')} />
					<Switch>
						<Route
							path={`${url}/create`}
							render={(routeProps) => <WayBillEdit onSave={this.onSave} {...routeProps} firePlanVisitId={this.props.firePlanVisitId} />}
						/>
						<Route path={`${url}/:wayBillId`} render={(routeProps) => <WayBillEdit {...routeProps} firePlanVisitId={this.props.firePlanVisitId} />} />
					</Switch>
				</Tabs>
			</div>
		);
	}
}
