import React from 'react';
import { Fill, Stroke, Style, Icon, Circle as CircleStyle } from 'ol/style';
import { asArray } from 'ol/color';
import { ColorUtils } from '@smartplatform/ui';

import TechnoZonePopup from './TechnoZonePopup';
import icon from 'client/img/icons/technozone.svg?url';
import t from 'i18n';

const color = '#f40';
const strokeColor = '#000';

export default {
	modelName: 'TechnoZone',
	title: t('technoZone.title'),
	filterTitle: t('technoZone.plural'),
	showInFilters: true,
	style: new Style({
		fill: new Fill({ color: asArray([...ColorUtils.hexToArray(color), 0.5]) }),
		stroke: new Stroke({ color: asArray([...ColorUtils.hexToArray(strokeColor), 0.5]), width: 1 }),
		image: new Icon({ src: icon, anchor: [9, 31], anchorXUnits: 'pixels', anchorYUnits: 'pixels' }),
	}),
	icon,
	priority: 3,
	renderPopup: record => <TechnoZonePopup key={record.id} record={record} />,
};
