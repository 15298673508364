import React from 'react';
import { observer } from 'mobx-react';
import { Route } from 'react-router-dom';
import store from 'client/store';
import module from './index';
import { ModuleWrapper } from 'components';
import Map from './map';
import { DynamicLayers } from '@smartplatform/map/client';
import initLocal from './localStore';
import { DICTIONARIES_MODELS } from './constants';
import loadable from '@loadable/component';
const DictionariesPage = loadable(() => import('components/dictionaries/dictionaries-page'));
import Tube from './temperature-tubes';
import Marks from './leveling-marks';
import Objects from './objects';
import Measurements from './measurements';

@observer
export default class Root extends React.Component {
	constructor(props) {
		super(props);
		initLocal();
	}

	render() {
		const { path } = this.props.match;
		return (
			<ModuleWrapper className='permafrost-module' module={module} path={path} redirectPath={`${path}/map`}>
				<Route path={`${path}/map`} component={Map} />
				<Route path={`${path}/layers`} render={(routeProps) => <DynamicLayers {...routeProps} store={store} />} />
				<div className='permafrost-page'>
					<Route path={`${path}/objects`} component={Objects} />
					<Route path={`${path}/temperature-tubes`} component={Tube} />
					<Route path={`${path}/leveling-marks`} component={Marks} />
					<Route path={`${path}/measurements`} component={Measurements} />
					<Route>
						<div className='permafrost-page'>
							<Route path={`${path}/dictionaries`} render={(props) => <DictionariesPage models={DICTIONARIES_MODELS} {...props} />} />
						</div>
					</Route>
				</div>
			</ModuleWrapper>
		);
	}
}
