import React from 'react';
import { observer } from 'mobx-react';

@observer
class FieldChecker extends React.Component {
	componentDidMount() {
		this.notifyStatusChange();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.fields !== this.props.fields) {
			this.notifyStatusChange();
		}
	}

	notifyStatusChange = () => {
		const { fields, onStatusChange } = this.props;
		const allFieldsFilled = this.areFieldsFilled(fields);
		onStatusChange(allFieldsFilled);
	};

	areFieldsFilled = (fields) => {
		return fields.every((field) => this.isFieldFilled(field));
	};

	isFieldFilled = (field) => {
		if (typeof field === 'boolean') {
			return field;
		}
		return field != null && field !== '' && field !== false;
	};

	render() {
		const { children, fields } = this.props;
		const allFieldsFilled = this.areFieldsFilled(fields);
		return children(allFieldsFilled);
	}
}
export default FieldChecker;
