import React from 'react';
import { observer } from 'mobx-react';
import { Map, Popup } from '@smartplatform/map/client';
import store from 'client/store';
import Filters from './filters';
import map from './store';
import { MainPopup, WeatherScale } from 'components';
import ExtraLayers from './ExtraLayers';

@observer
export default class DeliveryMap extends React.Component {
	constructor(props) {
		super(props);
		const { pathname } = props.location;
		const split = pathname.split('/');
		// ex delivery/map/ship/:shipId
		if (split.at(-2) === 'ship') {
			map.shipId = +split.at(-1);
		}
	}
	onLayerToggle = (layers) => {
		store.local.delivery.dynamicLayers = layers;
		store.local.save();
	};

	render() {
		const { popup, mapInitialized, onMapInit, layers, onTileSourceChange } = map;

		const visibleLayers = store.local.delivery.dynamicLayers;

		const mapParams = {
			...store.config.map,
			onTileSourceChange,
			defaultTileSource: store.local.delivery.tileSource,
		};

		const mapProps = {
			className: 'delivery-map full-map',
			onInit: onMapInit,
			params: mapParams,
			modelStore: store.model,
			visibleLayers,
			onLayerToggle: this.onLayerToggle,
			extraLayers: <ExtraLayers modelsLayer={layers.modelsLayer} />,
		};

		const trackLayer = layers.ships?.trackLayer;
		const TrackTimeLineComponent = trackLayer?.timeLineComponent;
		const TrackShipPopup = trackLayer?.popupComponent;
		return (
			<Map {...mapProps}>
				{popup && (
					<Popup {...popup} width={popup.width || 550}>
						<MainPopup {...popup} />
					</Popup>
				)}
				{mapInitialized && (
					<>
						<Filters />
						{layers.weather && <WeatherScale onMount={layers.weather.onWeatherScaleMount} hidden={!store.local.delivery.layers.weather.show} />}
						{layers.ships?.shipToTrack && !trackLayer.isLoading && <TrackTimeLineComponent />}
						{layers.ships?.shipToTrack && <TrackShipPopup />}
					</>
				)}
			</Map>
		);
	}
}
