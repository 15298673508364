import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import icons from 'img/icons/fires/map';
import { Checkbox, DatePicker } from '@smartplatform/ui';
import { FilterLayout } from 'components';
import store from 'client/store';
import t from 'i18n';

@observer
export class FiresFilter extends React.Component {
	@observable statuses = [];
	@observable services = [];
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.statuses = await store.model.FireStatus.find({ order: 'id asc ' });
		this.services = [{ code: 'serviced' }, { code: 'unattended' }];
		this.isLoading = false;
	};

	onToggle = (value) => {
		this.props.layer.setVisible();
		if (value) this.props.layer.load();
	};

	onChange = async (statusCode, value) => {
		const { settings } = this.props;
		settings.status[statusCode] = value;
		this.reload();
	};

	onChangeService = async (serviceCode, value) => {
		const { settings } = this.props;
		settings.service[serviceCode] = value;
		this.reload();
	};

	onStartDateChange = (date) => {
		const { settings } = this.props;
		settings.startDate = date;
		this.reload();
	};

	onEndDateChange = (date) => {
		const { settings } = this.props;
		settings.endDate = date;
		this.reload();
	};

	reload = async () => {
		this.isLoading = true;
		store.local.save();
		await this.props.layer.load();
		this.isLoading = false;
	};

	render() {
		const { store: storeName, settings } = this.props;

		const legend = (
			<div className='legend'>
				{Object.keys(icons).map(
					(key) =>
						typeof icons[key] === 'object' && (
							<div className='d-flex' key={key}>
								<span>
									<img src={icons[key]?.src} alt='' height={16} />
								</span>
								<span>{icons[key].label}</span>
							</div>
						)
				)}
			</div>
		);

		return (
			<FilterLayout layer={this.props.layer} title={t('fire.plural')} isLoading={this.isLoading} legend={legend}>
				{this.statuses.map((status) => (
					<div key={status.id}>
						<Checkbox
							value={settings.status[status.code]}
							onChange={(v) => this.onChange(status.code, v)}
							label={t('fireStatus.codes.' + status.code)}
							disabled={!settings.show}
						/>
					</div>
				))}
				<div className='mb-2 heat-points-period'>
					<label>{t('heatPoint.period')}</label>
					<DatePicker onChange={this.onStartDateChange} value={settings.startDate} className='start' disabled={!settings.show} />
					<DatePicker onChange={this.onEndDateChange} value={settings.endDate} className='start' disabled={!settings.show} maxDate={new Date()} />
				</div>
				{this.services.map((service) => (
					<div key={service.code}>
						<Checkbox
							value={settings.service[service.code]}
							onChange={(s) => this.onChangeService(service.code, s)}
							label={t('fireStatus.codes.' + service.code)}
							disabled={!settings.show}
						/>
					</div>
				))}
			</FilterLayout>
		);
	}
}
