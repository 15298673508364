import { observable } from "mobx";

export default class InformationOnTheOperationOfAircraftForMonitoringStore {
	@observable date = null;

	onDateChange = (date) => this.date = date;

	get storeParams() {
		const { date } = this

		return {
			reportCode: 'InformationOnTheOperationOfAircraftForMonitoring',
			reportParams: { date, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone },
			isHTML: true,
		};
	}
}
