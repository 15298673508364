import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { addDays } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

import { Button, DatePicker, SlideInput } from '@smartplatform/ui';
import { Panel } from 'components';
import { formatDate } from 'client/tools';
import './style.scss';

@observer
export default class Timeline extends React.Component {
	@observable hour = 0;

	constructor(props) {
		super(props);
		const date = new Date(this.props.fireStore.date);
		this.hour = date.getHours();
	}

	onMount = (el) => {
		if (el) {
			el.addEventListener('mouseenter', this.onMouseEnter);
		}
	};

	onMouseEnter = () => {
		if (this.props.fireStore.weatherTip) this.props.fireStore.weatherTip.hide();
	};

	onTimeSelect = (value) => {
		// console.log('onTimeSelect', value);
		let date = new Date(this.props.fireStore.date);
		if (value === 24) {
			this.hour = 0;
			date = addDays(date, 1);
			date.setHours(0);
		} else {
			this.hour = value;
			date.setHours(value);
		}
		this.props.fireStore.changeDate(date);
	};

	render() {
		const { fireStore } = this.props;

		return (
			<div className='fires-timeline' ref={this.onMount}>
				<DatePicker value={fireStore.date} onChange={fireStore.changeDate} className='timeline-datepicker' offset={10}>
					<span className='day-shift' onClick={fireStore.prevDate}>
						<FontAwesomeIcon icon={faArrowLeft} />
					</span>
					{formatDate(fireStore.date, 'dd.MM.yyyy HH:mm')}
					<span className='day-shift' onClick={fireStore.nextDate}>
						<FontAwesomeIcon icon={faArrowRight} />
					</span>
				</DatePicker>
				<SlideInput value={this.hour} min={0} max={23} step={1} className='timeline-timeselect' onChange={this.onTimeSelect} showLabels />
			</div>
		);
	}
}
