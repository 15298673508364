import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { FilterLayout } from 'components';
import IconSrc from 'img/icons/permafrost/markMap.svg';

import t from 'i18n';

@observer
export default class PermafrostLevelingMarkFilter extends React.Component {
	@observable id = null;

	static propTypes = {
		layer: PropTypes.object.isRequired,
	};

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		const { id } = this.props.layer.settings.filter;
		this.id = id;
	};

	onChange = (field) => (value) => {
		this[field] = value;
		this.props.layer.onLocalStoreChange(field, value);
	};

	render() {
		return (
			<FilterLayout
				title={
					<span>
						<IconSrc className='ml-1' />
						{t('permafrost.marksMenu')}
					</span>
				}
				{...this.props}
			>
			</FilterLayout>
		);
	}
}
