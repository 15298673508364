import React from 'react';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import t from 'i18n';
import { triggerErrorNotification } from 'components';
import { observer } from 'mobx-react';
import classNames from 'classnames';

@observer
export class ImportBtn extends React.Component {
	constructor(props) {
		super(props);
	}

	onAttach = async (e) => {
		const files = e.target.files;
		try {
			if (!files.length) throw t('importFile.error.fileNotFound');
			const validFiles = this.props.mimeTypes?.length ? [...files].filter((file) => this.props.mimeTypes.includes(file.type)) : files;
			if (this.props.onAttach) this.props.onAttach(validFiles);
			// Очищаем значение поля input после выбора файла
			e.target.value = null;
		} catch (message) {
			triggerErrorNotification(message);
		}
	};

	render() {
		const { mimeTypes, disabled } = this.props;
		const fileInputProps = {};
		if (mimeTypes?.length) fileInputProps.accept = mimeTypes.join(',');
		return (
			<div className='import-btn'>
				<label>
					<input type='file' onChange={this.onAttach} {...fileInputProps} />
					<div className='wrapper-import-btn sp-btn sp-btn-primary icon-btn' disabled={disabled || false}>
						<FontAwesomeIcon icon={faUpload} />
						<span>{t('upload')}</span>
					</div>
				</label>
			</div>
		);
	}
}
