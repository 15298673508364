import React from 'react';
import { inject, observer } from 'mobx-react';
import { Table, Column, RelationSelect } from '@smartplatform/ui';
import t from 'i18n';
import { fireDepSpecialTypeItem } from 'techfires/helpers';
import { observable } from 'mobx';

@inject('store')
@observer
export default class Vehicles extends React.Component {
	// @observable vehicles = null;

	render() {
		const { record, edit, store } = this.props;
		let { vehicles, onChange } = store;
		console.log(vehicles)
		return (
			<div>
				<Table rows={vehicles}>
					<Column property='stateNumber' label={t('vehicle.stateNumber')} />
					<Column relation='brand' property='name' label={t('fireDepVehicle.brand')} />
					<Column relation='status' property='name' label={t('fireDepVehicle.status')} />
					<Column relation='type' property='name' label={t('fireDepVehicle.type')} />
					<Column relation='type' computed={fireDepSpecialTypeItem} label={t('fireDepVehicle.specialType')} />
					<Column relation='pump' property='capacity' label={t('pump.title')} />
					<Column property='year' label={t('vehicle.year')} />
					<Column property='passport' label={t('vehicle.passport')} />
					<Column relation='cisternBulk' property='volume' label={t('vehicle.capacity')} />
					<Column relation='callSign' property='name' label={t('vehicle.callSign')}>
						{edit &&
							((row) => (
								<RelationSelect
									record={row.record}
									relation='callSign'
									property='name'
									onChange={(newData) => onChange(newData, row, 'callSign')}
									filter={{ where: { fireDepId: record.fireDepId } }}
									showValue={row.record.callSign ? row.record.callSign.name : row.record.newCallSignName}
								/>
							))}
					</Column>
				</Table>
			</div>
		);
	}
}
