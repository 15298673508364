import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { fioShort, formatDate } from 'client/tools';
import { Table, Column, Checkbox } from '@smartplatform/ui';
import { Card, AutoAnimate } from 'components';
import t from 'i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import FireDepIcon from 'img/icons/sidebar/techfires/fireDep.svg';

@observer
export default class FireDep extends React.Component {
	@observable isChecked = false;

	constructor(props) {
		super(props);
	}

	renderFoam = ({ record }) => {
		const currentFoam = record.foam().length > 0 ? record.foam()[0] : null;
		return currentFoam?.volume || '-';
	};

	onVehicleToggle = (vehicle) => (value) => {
		const { selectedVehicles } = this.props.fireDepObj;
		const index = selectedVehicles.findIndex((vehicleId) => vehicleId === vehicle.id);
		if (value) {
			if (index === -1) selectedVehicles.push(vehicle.id);
		} else {
			if (index !== -1) selectedVehicles.splice(index, 1);
		}
	};

	onVehicleNoConsidShiftToggle = async () => {
		const { store, fireDepObj } = this.props;
		this.isChecked = !this.isChecked;
		await store.addVehiclesNoConsidShift(fireDepObj.fireDep, this.isChecked);
		fireDepObj.selectedVehicles = [];
	};

	renderCheckbox = ({ record }) => {
		const { selectedVehicles } = this.props.fireDepObj;
		const checked = selectedVehicles.includes(record.id);
		return <Checkbox checked={checked} onChange={this.onVehicleToggle(record)} disabled={!!this.props.store.record.id} />;
	};

	render() {
		const { fireDepObj, store } = this.props;
		const { vehiclesNoConsidShift, id, fireDepShifts } = store;
		const { fireDep, errors, vehicles, selectedVehicles, acceptInfo = {} } = fireDepObj;
		const vehiclesChoice = this.isChecked ? vehiclesNoConsidShift[fireDep.id] || [] : vehicles;
		const isAccepted = !!acceptInfo.date && !!acceptInfo.user;
		const title = (
			<div className='title'>
				<div className='name'>{fireDep.name}</div>
				{isAccepted && (
					<div className='accepted'>
						<FontAwesomeIcon icon={faCheck} /> {fioShort(acceptInfo.user)} {formatDate(acceptInfo.date)}
					</div>
				)}
				{selectedVehicles.length === 0 && <span className='reason'>{t('techFireWayBill.noVehicles')}</span>}
			</div>
		);
		return (
			<AutoAnimate>
				<Card title={title} onDelete={store.record.id ? () => store.removeFireDep(fireDep) : undefined} className='firedep-card'>
					{errors.length > 0 && (
						<div className='errors'>
							{errors.map((e, i) => (
								<div key={i}>{e}</div>
							))}
						</div>
					)}
					<Checkbox
						value={this.isChecked}
						className='vehicle-no-consid-checkbox'
						onChange={this.onVehicleNoConsidShiftToggle}
						label={t('techFireWayBill.noConsidShift')}
						disabled={!!id}
					/>
					{vehiclesChoice.length > 0 && (
						<div className='vehicles'>
							<Table rows={vehiclesChoice}>
								<Column width={30}>{this.renderCheckbox}</Column>
								<Column relation='type' property='name' label={t('fireDepVehicle.type')} />
								<Column relation='cisternBulk' property='volume' label={t('fireDepVehicle.cisternBulk')} />
								<Column property='actualFoamValue' label={t('fireDepVehicle.foam')} />
								<Column relation='callSign' property='name' label={t('fireDepVehicle.callSign')} />
							</Table>
						</div>
					)}
				</Card>
			</AutoAnimate>
		);
	}
}
