import React from 'react';
import FireIcon from 'img/icons/techfires/card/fire.svg';
import { FieldBasedCard } from '../../../../components';

export default class SituationToFirstDivision extends React.Component {
	render() {
		const rows = [[{ property: 'situation', placeholder: 'techFire.subscribeSituation', type: 'textarea', props: { rows: 3 } }]];
		const fields = [(fire) => fire.situation];

		return (
			<FieldBasedCard
				title='techFire.situationToFirstDivision'
				icon={FireIcon}
				fields={fields}
				rows={rows}
				statusKey='situationToFirstDivision'
				isCard={true}
			/>
		);
	}
}
