import { observable } from 'mobx';
import { LOCAL_STORAGE_FIELDS, RESERVES_INCLUDE, MODEL_NAME } from './constants';
import { PER_PAGE } from 'client/constants';
import { ListStore } from "components";

import store from 'client/store';

export class ReserveStore extends ListStore {
	@observable categories = null;
	@observable city = null;
	@observable county = null;
    @observable selectedCategories = [];
    @observable selectedSubCategories = [];
    @observable openedCategories = [];

    constructor(path) {
        super({ path, filters: RESERVES_INCLUDE, model: store.model[MODEL_NAME] });
        this.localStorageData = store.local.emergency.reserves;
        this.init();
    }
	init = async () => {
        await this.fetchCategories();
        this.loadLocalStorage();
        this.isLoading = false;
    }

    fetchCategories = async () => {
        this.categories = await store.model.NomenclatureCategory.find({
            include: ['children'],
            fields: ['id', 'name'],
            where: { parentId: null }
        });
    };

    onSelect = (category, array) => {
        const index = this[array].findIndex((value) => value === category.id);

        if (array === 'selectedCategories') {
            index !== -1 ? this.selectedCategories.splice(index, 1) : this.selectedCategories.push(category.id);
            this.onSelectParentCategory({ category, isSelectedParent: index === -1 });
        } else {
            index !== -1 ? this[array].splice(index, 1) : this[array].push(category.id);
        }
        this.saveToLocalStorage();
    };

    onSelectParentCategory = ({ category, isSelectedParent }) => {
        category.children().forEach((child) => {
            const index = this.selectedSubCategories.findIndex((value) => value === child.id);
            const isSubSelected = index !== -1;

            if (isSelectedParent) {
                !isSubSelected && this.selectedSubCategories.push(child.id);
            } else {
                isSubSelected && this.selectedSubCategories.splice(index, 1);
            }
        });
    };

    saveToLocalStorage = () => {
        LOCAL_STORAGE_FIELDS.forEach((field) => (store.local.emergency.reserves[field] = this[field]));
        store.local.save();
    };

    loadLocalStorage = async () => {
        const keys = Object.keys(this.localStorageData) || [];
        keys.forEach((key) => {
            if (this.localStorageData[key]) {
                this[key] = this.localStorageData[key];
            }
        });
    };

    onChange = (prop) => async (value) => {
        this[prop] = value;
        if (prop === 'city') this.county = await value?.county;
        this.saveToLocalStorage();
    }

    get filter() {
        const { querySearch, selectedSubCategories, selectedCategories, city, county } = this;
        const filter = {
            where: { and: [] },
            order: this.order,
            limit: PER_PAGE,
            skip: (this.page - 1) * PER_PAGE,
            ...RESERVES_INCLUDE,
        };

        filter.where.and.push({ or: [] });
        if (selectedCategories.length) filter.where.and[0].or.push({ categoryId: { inq: [...selectedCategories] } });
        if (selectedSubCategories.length) filter.where.and[0].or.push({ categoryId: { inq: [...selectedSubCategories] } });
        if (city) filter.where.and.push({ cityId: city.id });
        if (county) filter.where.and.push({ countyId: county.id });
        if (querySearch.length) filter.search = querySearch;
        if (!filter.where.and.length) delete filter.where;

        console.log(filter.where.and);

        return filter;
    }

}
