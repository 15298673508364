export const VEHICLE_INCLUDE = {
	include: [
		{ relation: 'brand', scope: { fields: ['id', 'name'] } },
		{ relation: 'cisternBulk', scope: { fields: ['id', 'volume'] } },
		{ relation: 'foamCapacity', scope: { fields: ['id', 'capacity'] } },
		{ relation: 'pump', scope: { fields: ['id', 'capacity'] } },
		{ relation: 'status', scope: { fields: ['id', 'name'] } },
		{ relation: 'callSign', scope: { fields: ['id', 'name'] } },
		{
			relation: 'type',
			scope: {
				fields: ['id', 'name', 'specialTypeId'],
				include: [{ relation: 'specialType', scope: { fields: ['id', 'name'] } }],
			},
		},
	],
};
