import React, { useContext } from 'react';
import List from 'client/img/icons/mode-list.svg';
import Kanban from 'client/img/icons/mode-kanban.svg';
import './style.scss';
import { LIST, KANBAN } from 'client/constants';
const ModeContext = React.createContext({});

export const Mode = ({ mode, onChange, children }) => {
	return (
		<ModeContext.Provider value={{ mode, onChange }}>
			<div className='modes'>{children}</div>
		</ModeContext.Provider>
	);
};

const makeToolbarIcon = (Component) => () => {
	const { mode, onChange } = useContext(ModeContext);
	const componentModeName = Component.displayName;
	return <Component className={mode === componentModeName ? 'active' : ''} onClick={onChange(componentModeName)} />;
};

List.displayName = LIST;
Kanban.displayName = KANBAN;

Mode.List = makeToolbarIcon(List);
Mode.Kanban = makeToolbarIcon(Kanban);
