import hydrantBlue from './hydrant-blue.svg?url';
import hydrantRed from './hydrant-red.svg?url';
import defaultIcon from './icon.svg?url';
import prorubRedIcon from './prorub-red.svg?url';
import prorubBlueIcon from './prorub-blue.svg?url';
import gusakBlueIcon from './gusak-blue.svg?url';
import gusakRedIcon from './gusak-red.svg?url';
import rezervuarBlue from './rezervuar-blue.svg?url';
import rezervuarRed from './rezervuar-red.svg?url';

export const ICON_TYPE_MAP = {
	PR: ({ working }) => (working ? rezervuarBlue : rezervuarRed),
	ICEHOLE: ({ working }) => (working ? prorubBlueIcon : prorubRedIcon),
	PG: ({ working }) => (working ? hydrantBlue : hydrantRed),
	GSK: ({ working }) => (working ? gusakBlueIcon : gusakRedIcon),
	undefined: () => defaultIcon,
};
