import React from 'react';
import { observer } from 'mobx-react';
import store from 'client/store';
import map from '../store';
import './style.scss';
import { Toolbar as BaseToolbar } from 'components';
import { PermafrostObjectFilter } from './layers/permafrostObject';
import { PermafrostTemperatureTubeFilter } from './layers/permafrostTemperatureTube';
import { PermafrostLevelingMarkFilter } from './layers/permafrostLevelingMark';

@observer
export default class Filters extends React.Component {
	render() {
		const localSettings = store.local.permafrost;
		const settings = localSettings.layers;

		return (
			<div className='permafrost-filters-wrapper'>
				{localSettings.filtersMaximized && (
					<div className='permafrost-filters'>
						<PermafrostObjectFilter layer={map.layers.permafrostObjectLayer} settings={settings.permafrostObject} />
						<PermafrostTemperatureTubeFilter layer={map.layers.permafrostTemperatureTubeLayer} settings={settings.permafrostTemperatureTube} />
						<PermafrostLevelingMarkFilter layer={map.layers.permafrostLevelingMarkLayerLayer} settings={settings.permafrostLevelingMarkLayer} />
					</div>
				)}
				<BaseToolbar>
					<BaseToolbar.FilterButton showFilters={localSettings.filtersMaximized} onClick={() => map.switchFilters(!localSettings.filtersMaximized)} />
				</BaseToolbar>
			</div>
		);
	}
}
