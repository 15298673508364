import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { ReportToolbar, SaveButton } from 'components';
import { ReportStore } from '../store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import t from 'i18n';
import './style.scss';
import store from 'client/store';
import { DOWN, UP } from 'client/constants';
import { RecordSelect } from '@smartplatform/ui';

const CODE = 'Fuel';

@observer
export class Fuel extends React.Component {
	@observable fireDeps = [];
	@observable orderChanged = false;

	constructor(props) {
		super(props);
		this.store = new ReportStore(CODE, t('techFireReport.fuel'));
		this.init();
	}

	init = async () => {
		await this.store.init();
		const { getSortedFireDeps, getFireDepsByGarrisonId, headOrg, report, garrisonId } = this.store;
		if (!headOrg || !garrisonId) return null;
		this.fireDeps = await getSortedFireDeps(report.fireDepsIds);
		if (report.headOrgId !== garrisonId) {
			await getFireDepsByGarrisonId(headOrg.id);
			this.fireDeps = this.store.fireDeps;
		}
		this.store.fireDeps = this.fireDeps;
	};

	moveField = (index, direction) => {
		const { fireDeps } = this.store;
		const newIndex = direction === UP ? index - 1 : index + 1;
		if (newIndex >= 0 && newIndex < fireDeps.length) {
			const movedItem = fireDeps.splice(index, 1)[0];
			fireDeps.splice(newIndex, 0, movedItem);
			this.fireDeps = fireDeps;
			this.orderChanged = true;
		}
	};

	saveReport = async () => {
		const { fireDeps, headOrg, report } = this.store;
		report.fireDepsIds = fireDeps.map((fireDep) => fireDep.id);
		report.headOrgId = headOrg.id;
		await report.save();
		this.orderChanged = false;
	};

	onHeadOrgChange = async (value) => {
		const { onHeadOrgChange, getHeadOrgIds, clearFireDeps } = this.store;
		onHeadOrgChange(value);
		this.headOrg = value;
		if (value) getHeadOrgIds();
		clearFireDeps();
		await this.store.getFireDepsByGarrisonId(value.id);
		this.fireDeps = this.store.fireDeps;
	};

	render() {
		const { error, name, headOrg, storeParams, isLoading } = this.store;
		if (isLoading) return null;
		const totalCountFireDeps = this.fireDeps && this.fireDeps.length;
		const fireDepsNameList =
			this.fireDeps &&
			this.fireDeps?.map((fireDep, index) => {
				return (
					<div key={fireDep.id} className={`fuel-report-list firedep-${fireDep.id}`}>
						{fireDep.name}
						&nbsp;
						<div className='fuel-arrows'>
							<FontAwesomeIcon icon={faCaretUp} onClick={() => this.moveField(index, UP)} className='cursor-pointer' />
							<FontAwesomeIcon icon={faCaretDown} onClick={() => this.moveField(index, DOWN)} className='cursor-pointer' />
						</div>
					</div>
				);
			});
		return (
			<div className='fuel-report'>
				<div>
					<h2 className='title'>{name}</h2>
					<div className='fuel-report-count'>
						<p>{t('techFireReport.theOrderFireDeps')}</p>
						<p>
							{t('fireDep.totalCountFireDeps')}
							{totalCountFireDeps}
						</p>
					</div>
					<div className='firedep-name-list'>{fireDepsNameList}</div>
				</div>
				<div>
					<div className='form-field'>
						<label>{t('techFireOrganization.title')}</label>
						<RecordSelect
							model={store.model.TechFireOrganization}
							value={headOrg}
							disabled={!store.isAdmin}
							property='name'
							className='drop-down-md'
							onChange={(value) => this.onHeadOrgChange(value)}
							filter={{ where: { isGarrison: true } }}
						/>
					</div>
					<SaveButton text={t('techFireReport.saveTemplate')} onClick={this.saveReport} className='mr-2' disabled={!this.orderChanged} />
					<ReportToolbar {...storeParams} />
				</div>
				{error && <span className='error'>{error}</span>}
			</div>
		);
	}
}
