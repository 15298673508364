import React from 'react';
import './style.scss';
import { fio } from '../../../tools';

export const fireDepVehicleItem = (vehicle) => {
	if (!vehicle) return '-';
	return (
		<div className='label-start'>
			{/*<div className="vehicle-number">{vehicle.stateNumber || '---'}<div className="flag"><div/><div/><div/></div></div>*/}
			{vehicle.type && <div className='vehicle-type'>{vehicle.type?.vehicleCode?.name ?? 'Прочее'}</div>}
			{vehicle.callSign && <div className='call-sign'>{vehicle.callSign.name}</div>}
			<div className='state-number'>{vehicle.stateNumber || '---'}</div>
		</div>
	);
};

export const fireDepVehicleTypeItem = (record) => {
	return (
		<div className='label-end'>
			<strong>{record.name}</strong>
			<em>{record.specialType?.name || '-'}</em>
		</div>
	);
};

export const fireDepSpecialTypeItem = (record) => {
	return record.specialType?.name || '-';
};

export const guardHeadFireDepItem = (record) => {
	return (
		<div className='label-end'>
			<strong>{fio(record)}</strong>
			<em>{record.fireDep?.name || '-'}</em>
		</div>
	);
};

export const positionNoteName = (position) => {
	if (!position) return '-';
	return (
		<div className='label-start'>
			{/*<div className="vehicle-number">{vehicle.stateNumber || '---'}<div className="flag"><div/><div/><div/></div></div>*/}
			{position.positionCode && <div className='vehicle-type'>{position.positionCode.name}</div>}
			{position.name && <div className='call-sign'>{position.name}</div>}
			{/*<div className='state-number'>{personnel.stateNumber || '---'}</div>*/}
		</div>
	);
};
