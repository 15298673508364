import React from 'react';
import { DatePicker, Row, RecordSelect } from '@smartplatform/ui';
import { endOfYear } from 'date-fns';
import { observer } from 'mobx-react';
import { ReportToolbar, BoxLabel } from 'components';
import { ReportStore } from '../store';
import t from 'i18n';
import store from 'client/store';

const CODE = 'NumberOfEventsHeld';

@observer
export class NumberOfEventsHeld extends React.Component {
	constructor(props) {
		super(props);
		this.store = new ReportStore(CODE, t('techFireReport.numberOfEventsHeld'));
	}

	renderFireDeps = (fireDep) => (
		<div>
			<span className='status-color' style={{ background: fireDep.color || '#808080' }} />
			<span>{t(fireDep.name)}</span>
		</div>
	);

	onHeadOrgChange(value) {
		const { onHeadOrgChange, getHeadOrgIds, clearFireDeps } = this.store;
		onHeadOrgChange(value);
		this.headOrg = value;
		if (value) getHeadOrgIds();
		clearFireDeps();
	}

	renderLabelItem = (label, closeCallback) => <BoxLabel label={label.name} closeCallback={closeCallback} />;

	render() {
		const { endDate, startDate, headOrg, fireDeps, onEndDateChange, onStartDateChangeWithinYear, error, name, isLoading, getFireDepIds, onFireDepToggle } = this.store;
		if (isLoading) return null;

		const showfireDepsValue = fireDeps.length > 0 ? t('fireDep.selected') + ': ' + fireDeps.length : <span className='hint'>{t('fireDep.selectPlaceholder')}</span>;

		return (
			<div>
				<h2 className='title'>{name}</h2>
				<div className='filter'>
					<div>
						<div className='form-field'>
							<label>{t('date1')}</label>
							<DatePicker 
								value={startDate} 
								onChange={onStartDateChangeWithinYear} 
								showClearButton={false}
							/>
						</div>
					</div>
					<div>
						<div className='form-field'>
							<label>{t('date2')}</label>
							<DatePicker 
								value={endDate} 
								onChange={onEndDateChange} 
								showClearButton={false} 
								minDate={startDate ? startDate : undefined}
								maxDate={endOfYear(startDate)}
							/>
						</div>
					</div>
				</div>
				<Row>
					<div className='form-field'>
						<label>{t('techFireOrganization.title')}</label>
						<RecordSelect
							model={store.model.TechFireOrganization}
							value={headOrg}
							disabled={!store.isAdmin}
							property='name'
							className='drop-down-md'
							onChange={(value) => this.onHeadOrgChange(value)}
							filter={{ where: {isGarrison: true}}}
						/>
					</div>
					<div className='form-field'>
						<label>{`${t('fireDep.plural')} (если пусто,то по всем ПЧ организации)`}</label>
						<RecordSelect
							model={store.model.FireDep}
							value={fireDeps}
							onChange={onFireDepToggle}
							computed={this.renderFireDeps}
							showValue={showfireDepsValue}
							className='statuses-select'
							filter={{ fields: ['id', 'name'], where: { headOrgId: { inq: getFireDepIds(headOrg) } } }}
							noSearch
						/>
					</div>
				</Row>
				<ReportToolbar {...this.store.storeParams} />
				{error && <span className='error'>{error}</span>}
			</div>
		);
	}
}
