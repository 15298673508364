import React from 'react';
import { observer } from 'mobx-react';
import { Row } from '@smartplatform/ui';
import { TextField } from 'fires/components';
import t from 'i18n';
import { observable } from 'mobx';
import store from 'client/store';
import { lowerFirstLetter } from 'client/tools';
import './style.scss';

const MODEL_NAME = 'TechFireAddress';
@observer
export default class AddressPopup extends React.Component {
	@observable record = null;
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.record = await store.model[MODEL_NAME].findById(this.props.record.id, { include: ['rank'] });
		this.isLoading = false;
	};

	go = () => store.route.push({ path: `/techfires/addresses/${this.record.addressId}` });

	render() {
		if (this.isLoading) return null;
		const model = lowerFirstLetter(MODEL_NAME);
		return (
			<div className='map-fire-address-popup'>
				<div className='title'>
					<h2>
						{t(`${model}.title`)}:{' '}
						<a style={{ cursor: 'pointer' }} onClick={this.go}>
							#{this.record.id}
						</a>
					</h2>
				</div>
				{this.record.overnightStay && (
					<div className='overnight-stay-people'>
						<span></span>
						{t('techFireAddress.overnightStayPeople')} ({t('techFireAddress.total')} {this.record.totalPeople})
					</div>
				)}
				<Row>
					<TextField label={t(`${model}.name`)} value={this.record.name} />
					<TextField label={t(`${model}.description`)} value={this.record.description} />
				</Row>
				<Row>
					<TextField label={t(`${model}.ptp`)} value={this.record.ptp} />
					<TextField label={t(`${model}.ktp`)} value={this.record.ktp} />
				</Row>
				<Row>
					<TextField label={t(`${model}.rank`)} value={this.record.rank?.name} />
				</Row>
			</div>
		);
	}
}
