import React from 'react';
import { Column, Table } from '@smartplatform/ui';
import { Toolbar } from './toolbar';
import { observer, Provider } from 'mobx-react';
import { CategoryFilter } from './CategoryFilter';
import { ReserveStore } from './store';
import './style.scss';
import t from 'i18n';

@observer
export class ReservesList extends React.Component {
	constructor(props) {
		super(props);
		this.store = new ReserveStore();
		this.store.path = props.path;
	}

	render() {
		const { onRowClick, onQueryUpdate, filter, isLoading, model } = this.store;
		if (isLoading) return null;

		return (
			<Provider store={this.store}>
				<div className='emergency-reserves'>
					<h2>{t('reserve.title_full')}</h2>
					<Toolbar />
					<div className='content'>
						<CategoryFilter />
						<div className='table'>
							<Table model={model} query={filter} onQueryUpdate={onQueryUpdate} onRowClick={onRowClick}>
								<Column property='name' label={t('emergencyReserves.name')} />
								<Column relation='nomenclature' property='name' label={t('emergencyReserves.nomenclature')} />
								<Column property='fact' label={t('emergencyReserves.fact')} />
								<Column property='norma' label={t('emergencyReserves.norma')} />
								<Column relation='county' property='name' label={t('emergencyReserves.county')} />
								<Column relation='city' property='name' label={t('emergencyReserves.city')} />
							</Table>
						</div>
					</div>
				</div>
			</Provider>
		);
	}
}
