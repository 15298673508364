import { observable } from 'mobx';
import store from 'client/store';

export default class VehicleStore {
	@observable vehicle = null;
	@observable isLoading = true;
	@observable error = null;

	init = async () => {
		this.error = null;
		this.isLoading = true;
		console.log(this.id, 'this.id');
		if (this.id) {
			try {
				this.vehicle = await store.model.FireDepVehicle.findById(this.id, {
					include: ['brand', 'type', 'pump', 'callSign', 'cisternBulk', 'fuelValue', 'powderCapacity', 'status'],
				});
			} catch (e) {
				this.error = e.message;
			}
		} else {
			this.vehicle = new store.model.FireDepVehicle();
		}
		this.isLoading = false;
	};
}
