import React from 'react';
import { Toolbar } from 'components';
import GarrisonSelect from 'techfires/components/garrison-select/GarrisonSelect';
import { inject, observer } from 'mobx-react';

@inject('store')
@observer
export default class DashboardToolbar extends React.Component {
	constructor(props) {
		super(props);
		this.store = props.store;
	}

	render() {
		const { garrison, onChange } = this.store;
		return (
			<Toolbar classNames='tfires-dashboard-toolbar'>
				<GarrisonSelect value={garrison} onChange={onChange('garrison')} width={200} />
			</Toolbar>
		);
	}
}
