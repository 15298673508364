import { observable } from "mobx";
import { endOfYear, startOfYear } from "date-fns";

export default class ratioToStore {
	@observable year1 = null;
	@observable year2 = null;

	onYear1Change = (year) => this.year1 = year;

	onYear2Change = (year) => this.year2 = year;

	get storeParams() {
		const { year1, year2 } = this;
		const date1 = new Date(year1, 1, 1);
		const date2 = new Date(year2, 1, 1);
		const startDate1 = startOfYear(date1);
		const endDate1 = endOfYear(date1);
		const startDate2 = startOfYear(date2);
		const endDate2 = endOfYear(date2);

		return {
			reportCode: 'ratioTo',
			reportParams: { startDate1, endDate1, startDate2, endDate2, year1, year2 },
			reportModel: 'DeliveryReport'
		};
	}
}
