import React from 'react';
import '../style.scss';
import { observer, Provider } from 'mobx-react';
import DashboardStore from 'techfires/dashboard/store';
import StatisticsToolbar from 'techfires/dashboard/statistics/toolbar/StatisticsToolbar';
import StatisticsReport from './operation/StatisticsReport';
import t from '@smartplatform/map/i18n';

@observer
export default class Statistics extends React.Component {
	constructor(props) {
		super(props);
    this.store = DashboardStore;
		this.store.init();
	}

	render() {
		return (
			<Provider store={this.store}>
				<div className='techfires-page-dashboard'>
          <StatisticsToolbar />
          <StatisticsReport />
				</div>
			</Provider>
		);
	}
}