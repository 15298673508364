import React from 'react';
import { inject, observer } from 'mobx-react';
import { Button, NumberInput, RecordSelect, Row } from '@smartplatform/ui';
import FileCheckIcon from 'img/icons/fires/card/file-check.svg';
import { Card, LabelField, Hint } from 'components';
import { renderStatus } from 'client/tools';
import store from 'client/store';
import t from 'i18n';

@inject('card')
@observer
export class MainInfo extends React.Component {
	onForestryChange = async (forestry) => {
		this.props.card.onChange('forestry')(forestry);
		this.props.card.onChange('forestryPrecinct')(null);
	};

	render() {
		const { card, disabled } = this.props;
		const { fire, onChange, getRequiredClassName } = card;

		const selectProps = { noTotal: true, maxItems: 20, itemsPerPage: 1000, filter: { fields: ['id', 'name'] }, property: 'name', disabled };

		return (
			<Card title={t('mainInfo')} icon={<FileCheckIcon />}>
				<Row className='card-row'>
					<LabelField label={t('aviaDep.title')} isRequired>
						<RecordSelect
							{...selectProps}
							isRequired
							model={store.model.AviaDep}
							value={fire.aviaDep}
							onChange={card.onAviaDepChange}
							{...getRequiredClassName(fire.aviaDep)}
						/>
					</LabelField>
					<LabelField label={t('forestry.title')} isRequired>
						<RecordSelect
							{...selectProps}
							isRequired
							model={store.model.Forestry}
							value={fire.forestry}
							onChange={this.onForestryChange}
							{...getRequiredClassName(fire.forestry)}
						/>
					</LabelField>

					<LabelField label={t('forestryPrecinct.title')}>
						<RecordSelect
							{...selectProps}
							model={store.model.ForestryPrecinct}
							value={fire.forestryPrecinct}
							onChange={onChange('forestryPrecinct')}
							disabled={disabled || !fire.forestryId}
							filter={{ where: { forestryId: fire.forestryId }, ...selectProps.filter }}
						/>
					</LabelField>

					<LabelField label={t('forestOwnership.plural')} isRequired>
						<RecordSelect
							isRequired
							{...selectProps}
							model={store.model.ForestOwnership}
							value={fire.forestOwnership}
							onChange={onChange('forestOwnership')}
							{...getRequiredClassName(fire.forestOwnership)}
						/>
					</LabelField>
				</Row>
				<Row className='card-row'>
					<LabelField label={t('monitoringZone.title')} isRequired>
						<RecordSelect
							isRequired
							{...selectProps}
							model={store.model.MonitoringZone}
							value={fire.zone}
							onChange={onChange('zone')}
							computed={renderStatus}
							filter={{ fields: ['id', 'name', 'color'] }}
							{...getRequiredClassName(fire.zone)}
						/>
					</LabelField>
					<LabelField label={t('county.title')} isRequired>
						<RecordSelect
							isRequired
							{...selectProps}
							model={store.model.County}
							value={fire.county}
							onChange={onChange('county')}
							property='name'
							filter={{ fields: ['id', 'name'] }}
							{...getRequiredClassName(fire.county)}
						/>
					</LabelField>
					<LabelField label={t('fire.allotmentNumber')}>
						<NumberInput value={fire.allotmentNumber} onChange={onChange('allotmentNumber')} positiveOnly />
					</LabelField>
					<LabelField
						label={t('forestryQuarter.short')}
						text={fire.quartersCodes.length ? fire.quartersCodes.join(', ') : t('field.empty')}
					/>

					{/* <LabelField
						label={
							<span>
								{t('fire.updateMainInfo')}
								<Hint text={t('fire.updateMainInfoHint')} trigger='hover' size='1x' offset={5} position='top' className='ml-1' />
							</span>
						}
					>
						<Button onClick={card.updateMainInfo} variant='primary'>
							{t('fill')}
						</Button>
					</LabelField> */}
				</Row>
			</Card>
		);
	}
}
