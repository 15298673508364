import React from 'react';
import { inject, observer } from 'mobx-react';
import t from 'i18n';
import { Input, NumberInput, Row } from '@smartplatform/ui';
import { Card, LabelField } from 'components';
import InfoCircleIcon from 'img/icons/fires/card/info-circle.svg';

@inject('card')
@observer
export class Costs extends React.Component {
	render() {
		const { card } = this.props;
		const { fire, disabled, onChange } = card;
		return (
			<Card title={t('fire.extinguishingCosts')} icon={<InfoCircleIcon />}>
				<Row className='card-row'>
					{['extinguishingCostsSubject', 'rentBC', 'totalCost'].map((label) => (
						<LabelField label={t(`fire.${label}`)} key={label}>
							<Input value={fire[label]} onChange={onChange(label)} positiveOnly disabled={disabled} />
						</LabelField>
					))}
					<div />
				</Row>
				<table className='forestry-table'>
					<thead>
						<tr>
							<th>Наименование показателя</th>
							<th width={110}>Объем, куб.м</th>
							<th width={110}>Площадь, га</th>
							<th width={110}>Сумма, тыс.</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>{t(`fire.woodLoss`)}</td>
							<td>
								<NumberInput value={fire.lossOfWoodM3} onChange={onChange('lossOfWoodM3')} />
							</td>
							<td></td>
							<td>
								<NumberInput value={fire.lossOfWoodRub} onChange={onChange('lossOfWoodRub')} />
							</td>
						</tr>
						<tr>
							<td>{t(`fire.youngWoodLoss`)}</td>
							<td></td>
							<td>
								<NumberInput value={fire.youngForestLossHa} onChange={onChange('youngForestLossHa')} />
							</td>
							<td>
								<NumberInput value={fire.youngForestLossRub} onChange={onChange('youngForestLossRub')} />
							</td>
						</tr>
						<tr>
							<td>{t(`fire.burnedWood`)}</td>
							<td></td>
							<td></td>
							<td>
								<NumberInput value={fire.productLossRub} onChange={onChange('productLossRub')} />
							</td>
						</tr>
						<tr>
							<td>{t(`fire.burnedHarvestedWood`)}</td>
							<td>
								<NumberInput value={fire.includingHarvestedWoodM3} onChange={onChange('includingHarvestedWoodM3')} />
							</td>
							<td></td>
							<td>
								<NumberInput value={fire.includingHarvestedWoodRub} onChange={onChange('includingHarvestedWoodRub')} />
							</td>
						</tr>
					</tbody>
				</table>
			</Card>
		);
	}
}
