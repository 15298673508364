import { observable } from "mobx";
import { startOfYear, endOfYear } from "date-fns";

export default class ListOfForestFiresStore {
	@observable year = new Date().getFullYear();
	@observable forestry = null;

	onYearChange = (year) => (this.year = year);

	onForestryChange = (forestry) => (this.forestry = forestry);

	get storeParams() {
		const { year, forestry } = this;
		const date = new Date(year, 1, 1);

		const startDate = startOfYear(date);
		const endDate = endOfYear(date);
		return {
			reportCode: 'ListOfForestFires',
			reportParams: { year, forestry, startDate, endDate, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone },
			isHTML: true,
		};
	}
}
