import React from 'react';
import { observer, inject } from 'mobx-react';
import store from 'client/store';
import { FieldBasedCard } from '../../../../components';
import { EntityManager } from '../../../../components/entity-manager';
import PhoneIcon from 'img/icons/techfires/card/phone.svg';

@inject('fireStore')
@observer
class InteractionService extends React.Component {
	handleStatusChange = (areFieldsFilled) => {
		this.props.fireStore.updateFieldStatuses('organizations', areFieldsFilled);
	};

	render() {
		const { fireStore } = this.props;
		const { fire } = fireStore;

		const fields = [
			{ type: 'select', property: 'type', options: { model: store.model.TechFireInteractionServiceType, property: 'name' } },
			{
				type: 'input',
				property: 'division',
				options: { model: store.model.TechFireInteractionService, property: 'division' },
			},
		];

		const cardContent = (
			<EntityManager modelName='TechFireInteractionService' buttonLabel='techFire.serviceAdd' fire={fire} fields={fields} fireStore={fireStore} />
		);

		return (
			<FieldBasedCard
				title='techFire.organizations'
				icon={PhoneIcon}
				fields={[fire?.service().length > 0]}
				rows={[]}
				statusKey='organizations'
				fireStore={fireStore}
			>
				{cardContent}
			</FieldBasedCard>
		);
	}
}

export default InteractionService;
