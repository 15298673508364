import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { PlanList } from './list/PlanList';
import Plan from './edit';

export default ({ match }) => {
	const { path } = match;

	return (
		<div>
			<Switch>
				<Route path={path} exact render={({ match }) => <PlanList match={match} path={path} />} />
				<Route path={`${path}/create`} exact render={(routeProps) => <Plan {...routeProps} path={path} />} />
				<Route path={`${path}/:id`} render={(routeProps) => <Plan {...routeProps} path={path} />} />
			</Switch>
		</div>
	);
};