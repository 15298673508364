import React from 'react';
import TechFirePopup from './TechFirePopup';
import t from 'i18n';
import { observable } from 'mobx';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Fill, Stroke, Style, Icon, Circle as CircleStyle, Text } from 'ol/style';
import { asArray } from 'ol/color';
import { ColorUtils } from '@smartplatform/ui';
import { geoJSON, drawGeoMarker } from '@smartplatform/map/client';
import icons from 'img/icons/techfires/map';
import store from 'client/store';
import { getTechFireIconName } from './getTechFireIconName';
import { getRankColor } from '../../../modules/techfires/helpers/getColor';

export default class TechFiresLayer {
	@observable techFire = [];
	@observable garrisonIds = [];
	@observable fireDepIds = [];

	constructor(mapStore, settings = {}) {
		this.mapStore = mapStore;
		this.settings = settings;
	}

	init = async () => {
		this.layer = new VectorLayer({
			format: geoJSON,
			source: new VectorSource(),
		});
		this.layer.setZIndex(15);
		this.setVisible();
		this.mapStore.addLayer(this.layer);

		this.load();
	};

	load = async () => {
		const { settings } = this;
		const { filters } = settings;
		const { garrisons, fireDeps } = filters;
		let techFires = [];
		const rankCodes = Object.keys(this.settings.ranks)
			.filter((rankCode) => !!this.settings.ranks[rankCode])
			.map((rankCode) => parseInt(rankCode, 10));

		const extractIds = (array) => {
			return array.map((item) => item.id);
		};

		if (garrisons.length > 0 || fireDeps.length > 0) {
			this.garrisonIds = extractIds(garrisons);
			this.fireDepIds = extractIds(fireDeps);
		}

		if (settings.show) {
			const where = {
				and: [
					{ geo: { neq: null } },
					{ rankId: { neq: null } },
					{ techFireId: { neq: null } },
					{ terminationFireTime: null },
					{
						or: rankCodes.map((rankCode) => ({ rankId: { inq: [rankCode] } })),
					},
					{ or: this.garrisonIds.map((garrisonId) => ({ garrisonId: { inq: [garrisonId] } })) },
					{ or: this.fireDepIds.map((fireDepId) => ({ fireDepId: { inq: [fireDepId] } })) },
				],
			};

			techFires = await store.model.ViewTechFireLayer.find({
				where,
				fields: ['id', 'geo', 'rankId', 'techFireId', 'terminationFireTime', 'garrisonId', 'fireDepId'],
				include: [{ relation: 'rank', scope: { fields: ['id', 'name', 'level', 'color'] } }],
			});
		}

		const features = [];
		for (let techFire of techFires) {
			const { geo, techFireId } = techFire;
			const color = getRankColor(techFire);
			const rankLevel = techFire.rank.level;
			const iconSrc = icons[getTechFireIconName(rankLevel)]?.src || icons.default;
			const { r, g, b } = ColorUtils.hexToRgb(color);
			let styleParams = {
				fill: new Fill({ color: asArray([r, g, b, 0.4]) }),
				stroke: new Stroke({ color, width: 1 }),
			};
			let pointStyle;
			if (geo.type === 'Point') pointStyle = new Style({ image: new CircleStyle({ radius: 10, ...styleParams }) });

			const marker = drawGeoMarker(geo, {
				data: {
					title: t('techFire.title'),
					record: techFire,
					render: this.renderPopup,
				},
				style: new Style({
					...styleParams,
					image: new Icon({
						src: iconSrc,
					}),
					text: new Text({
						textAlign: 'left',
						font: '7px/14px sans-serif',
						text: this.settings.customText ? this.settings.customText(techFire) : `${techFireId || ''}`,
						stroke: new Stroke({
							color: '#FFFF',
							width: 2,
						}),
						offsetX: 6,
						offsetY: -12,
						scale: 1.5,
					}),
				}),
				onlyMarker: store.isPublic,
			});

			features.push(...marker);
		}

		this.layer.getSource().clear();
		this.layer.getSource().addFeatures(features);
	};
	onToggle = async (value, type) => {
		const { filters } = this.settings;
		if (type === 'garrison') {
			filters.garrisons = value;
		}
		if (type === 'fireDep') {
			filters.fireDeps = value;
		}

		if (filters.garrisons.length === 0) {
			this.garrisonIds = [];
			this.fireDepIds = [];
			filters.fireDeps = [];
			filters.garrisons = [];
		}
		store.local.save();
		this.load();
	};

	setVisible = () => {
		this.layer.setVisible(this.settings.show);
		this.load();
	};

	renderPopup = (record) => <TechFirePopup record={record} />;
}
