import React from 'react';
import { Fill, Stroke, Style, Icon, Circle as CircleStyle, Text } from 'ol/style';
import { asArray } from 'ol/color';
import { ColorUtils } from '@smartplatform/ui';

import CityPopup from './CityPopup';
import icon from 'client/img/icons/city.svg?url';
import iconGeo from 'client/img/icons/city-geo.svg?url';
import t from 'i18n';

const color = '#0af';

export default {
	modelName: 'City',
	title: t('city.title'),
	filterTitle: t('city.plural'),
	showInFilters: true,
	filter: {
		fields: ['id', 'geo', 'name'],
	},
	style: function (feature, resolution) {
		const record = feature.getProperties().record;
		const type = feature.getGeometry().getType();
		// console.log('resolution', resolution);
		let text;
		if (type === 'Point' || type === 'MultiPoint') {
			const alpha = 0.7;//resolution > 600 ? 0.7 : 0.2;
			text = new Text({
				textAlign: 'center',
				textBaseline: 'middle',
				font: '11px/14px sans-serif',
				offsetX: 0,
				offsetY: -15,
				text: record.name,
				fill: new Fill({ color: asArray([ 0,0,0, alpha]) }),
				stroke: new Stroke({ color: asArray([ 255,255,255, 0.5]), width: 1 }),
			});
		}
		return new Style({
			fill: new Fill({ color: asArray([...ColorUtils.hexToArray(color).slice(0, 3), 0.3]) }),
			stroke: new Stroke({ color, width: 2 }),
			image: new Icon({ src: icon, anchor: [4, 4], anchorXUnits: 'pixels', anchorYUnits: 'pixels' }),
			text,
		});
	},
	icon,
	pointGeoField: 'pointGeo',
	priority: 2,
	renderPopup: record => <CityPopup key={record.id} record={record} />,
};
