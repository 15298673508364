import React from 'react';
import { Switch, Route } from 'react-router-dom';
import List from './List';
import Edit from './Edit';

export default (props) => {
	const { match } = props;
	const { path } = match;
	return (
		<Switch>
			<Route path={`${path}`} exact render={(routeProps) => <List {...routeProps} />} />
			<Route path={`${path}/:id`} render={(routeProps) => <Edit {...routeProps} path={path} />} />
		</Switch>
	);
};
