import React from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import { Button, Input, DatePicker, NumberInput, Checkbox, RecordSelect, Row } from '@smartplatform/ui';
import t from 'i18n';
import store from 'client/store';
import { guardHeadFireDepItem } from '../../../helpers';

@inject('fireStore')
@observer
export default class EntityForm extends React.Component {
	@observable record = null;

	constructor(props) {
		super(props);
		const { modelName, fire, fireStore } = this.props;
		const where = fire ? { fireId: fire.id } : { fireId: fireStore.fire.id };
		this.record = props.record || new store.model[modelName](where);
	}

	onChange = (property) => (value) => {
		this.record[property] = value;
	};

	save = async () => {
		await this.record.save();
		this.props.onUpdate && this.props.onUpdate();
	};

	cancel = () => {
		this.props.onClose && this.props.onClose();
	};

	renderField = (field) => {
		const { type, label, property, options } = field;
		const filter = {
			where: {
				rtp: true,
				endDate: { gt: new Date() },
			},
		};
		if (this.record?.length) filter.where.id = { nin: this.record?.map((chief) => chief.chiefId) };
		switch (type) {
			case 'input':
				return (
					<div className='form-field'>
						<label>{t(label)}</label>
						<Input value={this.record[property]} onChange={this.onChange(property)} />
					</div>
				);
			case 'number':
				return (
					<div className='form-field'>
						<label>{t(label)}</label>
						<NumberInput value={this.record[property]} onChange={this.onChange(property)} {...options} />
					</div>
				);
			case 'date':
				return (
					<div className='form-field'>
						<label>{t(label)}</label>
						<DatePicker value={this.record[property]} onChange={this.onChange(property)} {...options} />
					</div>
				);
			case 'checkbox':
				return (
					<div className='form-field'>
						<label>{t(label)}</label>
						<Checkbox value={this.record[property]} onChange={this.onChange(property)} />
					</div>
				);
			case 'select':
				const selectProps = property === 'chief' ? { computed: guardHeadFireDepItem } : { property: options.property };
				return (
					<div className='form-field'>
						<label>{t(label)}</label>
						<RecordSelect model={options.model} value={this.record[property]} onChange={this.onChange(property)} filter={filter} {...selectProps} />
					</div>
				);
			default:
				return null;
		}
	};

	render() {
		const { fields, modelName } = this.props;

		return (
			<div>
				{modelName !== 'TechFireCasualty' ? (
					<Row>
						{fields.map(this.renderField)}
						<div className='form-field'>
							<Button onClick={this.save} variant='primary'>
								{t('save')}
							</Button>
							<Button onClick={this.cancel}>{t('cancel')}</Button>
						</div>
					</Row>
				) : (
					<>
						{fields.map(this.renderField)}
						<div className='form-field'>
							<Button onClick={this.save} variant='primary'>
								{t('save')}
							</Button>
							<Button onClick={this.cancel}>{t('cancel')}</Button>
						</div>
					</>
				)}
			</div>
		);
	}
}
