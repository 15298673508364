import * as flags from 'country-flag-icons/react/3x2';

export const renderCountry = (country, options = {}) => {
	if (!country) return;
	const { nameField = 'nicename' } = options;
	const Flag = flags[country.iso2];
	return (
		<div className='render-country' style={{ display: 'flex', alignItems: 'center', gap: '10px' }} title={country.name}>
			<Flag width={20} height={20} />
			{country[nameField]}
		</div>
	);
};
