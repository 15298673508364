import React from 'react';
import { inject, observer } from 'mobx-react';
import { FireDepCard } from './FireDepCard';
import { AutoAnimate, AddButtonSecondary } from 'components';
import store from 'client/store';
import { RecordSelect } from '@smartplatform/ui';
import t from 'i18n';
import './fireDeps.scss';

@inject('store')
@observer
export class FireDeps extends React.Component {
	render() {
		const { fireDeps, addFireDep, onChange, onAddFireDep, record, editFireDepId } = this.props.store;
		return (
			<div className='firedeps-section'>
				{!record.returnDate && (
					<div className='add-firedep'>
						<RecordSelect
							model={store.model.FireDep}
							width={200}
							placeholder={t('fireDep.selectPlaceholder')}
							property='name'
							maxItems={15}
							value={addFireDep}
							itemsPerPage={100}
							noTotal
							onChange={onChange('addFireDep')}
							filter={{ fields: ['id', 'name'], where: { id: { nin: fireDeps.map((i) => i.id) } } }}
						/>
						<AddButtonSecondary text={t('fireDep.add')} disabled={!addFireDep} onClick={onAddFireDep} />
					</div>
				)}

				<AutoAnimate className='firedeps-list'>
					{fireDeps.map((fireDep, index) => {
						const isEditable = !record.returnDate && fireDep.id === editFireDepId;
						return <FireDepCard key={fireDep.id} fireDep={fireDep} index={index} isEditable={isEditable} />;
					})}
				</AutoAnimate>
			</div>
		);
	}
}
