import React from 'react';
import { Loader } from '@smartplatform/ui';
import t from 'i18n';
import { observer, Provider } from 'mobx-react';
import waterSupplyStore from './store';
import WaterSupply from './WaterSupply';

@observer
export default class Edit extends React.Component {
	constructor(props) {
		super(props);
		this.card = new waterSupplyStore();
		this.card.id = parseInt(this.props.match.params.id);

		this.init();
	}

	init = async () => {
		await this.card.init();
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.match.params.id !== this.props.match.params.id) {
			this.card.id = parseInt(this.props.match.params.id);
			this.card.init();
		}
	}

	render() {
		const { path } = this.props;
		const card = this.card;
		const header = card.id ? t('waterSupply.title') : t('waterSupply.add');

		let content;

		if (card.isLoading) content = <Loader />;
		else if (card.error) content = card.error;
		else content = <WaterSupply path={path} />;

		return (
			<Provider card={card}>
				<div className='city-wrapper'>
					<div className='city-edit'>
						<div className='city-header'>{header}</div>
						<div className='city-content'>{content}</div>
					</div>
				</div>
			</Provider>
		);
	}
}
