import React from 'react';
import { inject, observer } from 'mobx-react';
import { Table, Column, RelationSelect, Checkbox } from '@smartplatform/ui';
import { fireDepVehicleItem } from 'techfires/helpers';
import t from 'i18n';
import { Toolbar } from 'components';
import { observable } from 'mobx';
import { positionNoteName } from '../../../../helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import store from 'client/store';
import { fio } from 'client/tools';

@inject('store')
@observer
export default class Personnel extends React.Component {
	@observable search = '';
	@observable statusId = null;
	@observable record = null;
	@observable showAll = false;

	constructor(props) {
		super(props);
		this.store = props.store;
		this.init();
	}

	init = async () => {
		const status = await store.model.FireDepVehicleStatus.find({ where: { code: this.props.reserve ? 'RESERV27' : 'CC4' } });
		this.statusId = status[0] && status[0].id;
	};

	onSearch = (e) => {
		this.search = e.target.value;
	};

	isSelected = (row) => {
		if (!this.props.edit) {
			return true;
		}
		return row.record.selected;
	};

	togglePerson = (row) => {
		row.record.selected = !row.record.selected;
		this.forceUpdate();
		this.store.updateVehicles();
	};

	render() {
		if (!this.statusId) return null;

		const { record, edit } = this.props;
		const { fireDepId } = record;
		let { personnel, onChange, onQueryUpdate } = this.store;

		let tablePerson;

		if (edit) {
			personnel = personnel.filter((p) => {
				return fio(p).toLowerCase().indexOf(this.search.toLowerCase()) !== -1 && !p.fireDepShiftId;
			});

			if (!this.showAll) {
				personnel = personnel.filter((p) => p.selected === true && !p.fireDepShiftId);
			}

			tablePerson = personnel;
		} else {
			tablePerson = personnel.filter((p) => {
				return fio(p).toLowerCase().indexOf(this.search.toLowerCase()) !== -1 && p.fireDepShiftId === record.id;
			});
		}

		return (
			<div>
				<div className='mb-3'>
					<Toolbar>
						<Toolbar.SearchIconInput value={this.search} onChange={this.onSearch} />
						{edit && (
							<Checkbox value={this.showAll} onChange={() => (this.showAll = !this.showAll)} label={'Показать всех'} className='d-flex align-items-center' />
						)}
					</Toolbar>
				</div>
				<Table rows={tablePerson} query={{}} onQueryUpdate={onQueryUpdate}>
					<Column label={<FontAwesomeIcon icon={faCheck} />} width={30}>
						{(row) => <Checkbox checked={this.isSelected(row)} onChange={() => this.togglePerson(row)} disabled={!edit} />}
					</Column>
					<Column property='fio' label={t('fio')} />
					<Column relation='position' property='name' label={t('fireDepPosition.title')}>
						{edit &&
							((row) => (
								<RelationSelect
									record={row.record}
									relation='position'
									computed={positionNoteName}
									onChange={(newData) => onChange(newData, row, 'position')}
								/>
							))}
					</Column>
					<Column relation='status' property='name' label={t('fireDepPersonnel.status')}>
						{edit &&
							((row) => <RelationSelect record={row.record} relation='status' property='name' onChange={(newData) => onChange(newData, row, 'status')} />)}
					</Column>
					<Column relation='vehicle' computed={fireDepVehicleItem} width={260} label={t('fireDepVehicle.title')}>
						{edit &&
							((row) => (
								<RelationSelect
									record={row.record}
									relation='vehicle'
									computed={fireDepVehicleItem}
									onChange={(newData = null) => onChange(newData, row, 'vehicle')}
									filter={{ where: { fireDepId, fireDepShiftId: null, vehicleStatusId: this.statusId } }}
								/>
							))}
					</Column>
				</Table>
			</div>
		);
	}
}
