import React from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import t from 'i18n';
import { Field, Form, Row, Checkbox } from '@smartplatform/ui';
import store from 'client/store';
import GeoEdit from 'components/geo-edit/GeoEdit';

@inject('card')
@observer
export default class WaterSupply extends React.Component {
	@observable manualInspector = false;

	constructor(props) {
		super(props);
		this.path = this.props.path;
		this.id = this.props.card.waterSupply.id;
		this.manualInspector = this.props.card.waterSupply.inspectorFullName ? true : false;
	}

	redirectBack = () => store.route.push({ path: this.path });

	onChangeManualInspector = () => {	
		this.manualInspector = !this.manualInspector;	
		const card = this.props.card;
		if (!this.manualInspector) {
			card.waterSupply.inspectorFullName = null;
			card.waterSupply.inspectorOrganization = null;
			card.waterSupply.inspectorPosition = null;
		}
	};

	headOrgName = (personnel) => {
		if (personnel.headOrg) {
			return <div>{personnel.headOrg.name}</div>;
		}
	};

	personnelPosition = (personnel) => {
		if (personnel.position) {
			return <div>{personnel.position.name}</div>;
		}
	};

	render() {
		const { card } = this.props;

		return (
			<div className='personnel'>
				<Form record={card.waterSupply} path={this.path} stay onDelete={this.redirectBack} onSave={this.redirectBack}>
					<Row>
						<Field property='name' label={t('waterSupply.name')} />
						<Field property='number' label={t('waterSupply.number')} />
						<Field property='typeName' label={t('waterSupply.typeName')} />
					</Row>
					<Row>
						<Field property='intake' label={t('waterSupply.intake')} />
						<Field property='ownership' label={t('waterSupply.ownership')} />
						<Field property='reference' label={t('waterSupply.reference')} />
					</Row>
					<Row>
						<Field computed={() => card.fireDepName} label={t('waterSupply.depName')} disabled />
						<Field property='defectCause' label={t('waterSupply.defectCause')} />
						<Field property='working' label={t('waterSupply.working')} />
					</Row>
					<Row>
						<Field property='comment' label={t('waterSupply.comment')} />
						<Field property='volume' label={t('waterSupply.volume')} />
						<Field relation='division' property='name' label={t('waterSupply.division')} />
					</Row>
					<Row>
						<Field relation='address' property='name' label={t('waterSupply.address')} />
						<Field relation='type' property='name' label={t('waterSupply.type')} />
						<Field property='addition' label={t('waterSupply.addition')} />
					</Row>
					<Row>
						<Field property='dateLastCheck' label={t('waterSupply.dateLastCheck')} />
						<Field property='createdAt' label={t('createdAt')} disabled />
						<Field property='updatedAt' label={t('updatedAt')} disabled />
					</Row>
					<Row>
						<Field relation='fireDepPersonnel' property='lastName' label={t('waterSupply.fireDepPersonnel')} disabled={this.manualInspector} />
						<Field relation='fireDepPersonnel' computed={this.personnelPosition} label={t('waterSupply.inspectorPosition')} disabled />
						<Field relation='fireDepPersonnel' computed={this.headOrgName} label={t('waterSupply.inspectorOrganization')} disabled />
						<div className='form-field'>
							<label>{t('waterSupply.manualInspector')}</label>
							<Checkbox value={this.manualInspector} onChange={this.onChangeManualInspector} />
						</div>
					</Row>
					{this.manualInspector &&
						<Row>
							<Field property='inspectorFullName' label={t('waterSupply.inspectorFullName')} />
							<Field property='inspectorPosition' label={t('waterSupply.inspectorPosition')} />
							<Field property='inspectorOrganization' label={t('waterSupply.inspectorOrganization')} />
						</Row>
					}
					<Row>
						<Field property='identifiedFault' label={t('waterSupply.identifiedFault')} />
						<Field property='remediationOrganization' label={t('waterSupply.remediationOrganization')} />
						<Field property='scheduledInspectionDate' label={t('waterSupply.scheduledInspectionDate')} />
					</Row>
					<Field property='geo' label={t('coordinates')}>
						<GeoEdit />
					</Field>
				</Form>
			</div>
		);
	}
}
