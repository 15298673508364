import React from 'react';
import PropTypes from "prop-types";
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

import { Popover, Trigger } from '@smartplatform/ui';
import { AddressSearch, AddressInfo } from "components";
import store from "client/store";
import t from 'i18n';
import './style.scss';
import { shortenAddressName } from "client/tools";

@observer
export default class TechFireAddressSearch extends React.Component {
	
	static propTypes = {
		/**
		 * Текущее значение, запись модели TechFireAddress или null.
		 * @type {(TechFireAddress|null)}
		 */
		value: PropTypes.object,
		
		/**
		 * Коллбэк при изменении значения.
		 * @param {(TechFireAddress|null)} newValue
		 */
		onChange: PropTypes.func,

		/**
		 * Коллбэк при начале поиска друого адреса.
		 */
		onReset: PropTypes.func,
		
		/**
		 * Префикс для поиска. По умолчанию "Якутск"
		 */
		prefix: PropTypes.string,
	};
	
	static defaultProps = {
		prefix: 'Респ Саха',
	};

	@observable address = null;
	@observable techFireAddress = null;
	
	constructor(props) {
		super(props);
		this.init();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if ((!this.props.value && prevProps.value) ||
			(this.props.value && !prevProps.value) ||
			(this.props.value && prevProps.value && this.props.value.id !== prevProps.value.id)) {
			this.init();
		}
	}
	
	init = async () => {
		if (this.props.value) {
			this.techFireAddress = await store.model.TechFireAddress.findById(this.props.value.id, {
				include: [
					{ relation: 'rank', scope: { fields: ['id', 'name'] }},
					{ relation: 'fireDep', scope: { fields: ['id', 'name'] }},
				],
			});
			this.address = this.techFireAddress ? await this.techFireAddress.address : null;
		}
	};
	
	onAddressClick = (address) => {
		if (this.triggerInstance) {
			this.triggerInstance.activate()
		}
	};
	
	onAddressChange = async (address) => {
		this.address = address;
		if (this.triggerInstance) {
			this.triggerInstance.activate();
		}
	};
	
	onChange = async (object) => {
		console.log('onChange', object, object.fireDep, object.address, object.rank)
		if (this.triggerInstance) {
			this.triggerInstance.close();
		}
		this.props.onChange && this.props.onChange(object);
	};
	
	getTiggerInstance = (instance) => this.triggerInstance = instance;
	
	searchOtherAddress = () => {
		this.address = null;
		this.techFireAddress = null;
		this.props.onReset && this.props.onReset();
		this.props.onChange && this.props.onChange(null);
	};
	
	renderValue = (address) => {
		const otherAddressBtn = <div className="other-address-btn" onClick={this.searchOtherAddress}>
			{t('techFireAddress.searchOtherAddress')}
		</div>;
		
		if (!this.techFireAddress) {
			return <>
				<FontAwesomeIcon icon={faHome} /> {shortenAddressName(address.name)}
				<div className="techfire-address-value">
					<div className="object-section">
						<div className="no-value">{t('techFireAddress.noValue')}</div>
					</div>
					{otherAddressBtn}
				</div>
			</>;
		}
		return <>
			<FontAwesomeIcon icon={faHome} /> {shortenAddressName(address.name)}
			<div className="techfire-address-value">
				<div className="object-section">
					<div className="rank">{this.techFireAddress.rank ? this.techFireAddress.rank.name : '?'}</div>
					{this.techFireAddress.name}
				</div>
				{otherAddressBtn}
			</div>
		</>;
	};

	render() {
		const { value } = this.props;
		
		const popup = this.address ?
			<div className="techfire-address-popup">
				<AddressInfo address={this.address} value={value} onChange={this.onChange} />
			</div>
			:
			null;
		
		return <Trigger popup={popup} trigger="manual" position="bottomLeft" className="techfire-address-select" getInstance={this.getTiggerInstance}>
			<AddressSearch
				value={this.address}
				onChange={this.onAddressChange}
				prefix={this.props.prefix}
				noImmediateSearch={!!this.address}
				onValueClick={this.onAddressClick}
				renderValue={this.renderValue}
			/>
		</Trigger>;
	}
}
